import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import type { ShipperMember } from '_shared_/user'

interface State {
  data: ShipperMember[]
  member: ShipperMember
  meta: {
    isLoading: boolean
    errors?: any
    isSuccessful?: boolean
    isResendingInvite: boolean
    isUpdatingMember: boolean
  }
}

const initialState: State = {
  data: [] as ShipperMember[],
  member: {} as ShipperMember,
  meta: {
    isLoading: false,
    errors: null,
    isSuccessful: false,
    isResendingInvite: false,
    isUpdatingMember: false,
  },
}

export const slice = createSlice({
  name: 'shipperMembers',
  initialState,
  reducers: {
    loadingStart(state) {
      state.meta.isLoading = true
    },
    loadingEnd(state) {
      state.meta.isLoading = false
    },
    resendShipperMemberInviteStart(state) {
      state.meta.isResendingInvite = true
    },
    resendShipperMemberInviteEnd(state) {
      state.meta.isResendingInvite = false
    },
    updateShipperMemberList(state, action: PayloadAction<ShipperMember[]>) {
      state.data = action.payload
    },
    updateShipperMember(state, action: PayloadAction<Partial<ShipperMember>>) {
      state.member = { ...state.member, ...action.payload }
    },
    createShipperMemberStart(state) {
      state.meta.errors = null
      state.meta.isSuccessful = false
    },
    createShipperMemberFailed(state, action: PayloadAction<any>) {
      state.meta.errors = action.payload
      state.meta.isSuccessful = false
    },
    createShipperMemberSuccess(state) {
      state.meta.errors = null
      state.meta.isSuccessful = true
    },
    createShipperMember(state, action: PayloadAction<ShipperMember>) {
      state.data = [...state.data, action.payload]
    },
    removeShipperMemberFromShipperMemberList(
      state: State,
      action: PayloadAction<ShipperMember>
    ) {
      state.data = state.data.filter(
        (member) => member.uuid !== action.payload.uuid
      )
    },
    updateMemberStart(state) {
      state.meta.isUpdatingMember = true
    },
    updateMemberEnd(state) {
      state.meta.isUpdatingMember = false
    },
    updateSingleShipperMemberFromList(
      state: State,
      action: PayloadAction<ShipperMember>
    ) {
      const index = state.data.findIndex(
        (member) => member.uuid === action.payload.uuid
      )

      if (index !== -1) {
        state.data[index] = action.payload
      }
    },
  },
})

export default slice.reducer
