import type { Dispatch, SetStateAction } from 'react'

import BaseSideBar from 'components/SideBar'
import RecommendedCarriers from 'rfp/rfp-details/components/recommended-carriers'
import RoutingGuide from 'rfp/rfp-details/components/routing-guide'
import type { RequestForProposal } from 'rfp/rfp.types'
import { RFP_STATE } from 'utils/constants'

type modalProps = {
  modalState: boolean
  closeModal: () => void
  openModal: () => void
  toggleModal: () => void
  setModalState: Dispatch<SetStateAction<boolean>>
}

interface SideBarProps {
  readonly routingGuideSidebar: modalProps
  readonly findCarriersSidebar: modalProps
  readonly rfp: RequestForProposal
  readonly selectedLane: Lane | undefined
  readonly isLoadingCarrierInvites: boolean
  readonly invites: NewCarrierInvite[]
  readonly resetParamsAndRefetchQueries: () => void
  readonly isLoadingRecommendedCarriers: boolean
  readonly recommendedCarriersData: RecommendedCarrier[] | undefined
  readonly carriersRecommendationsError: boolean
  readonly refetchCarrierInvites: () => Promise<unknown>
}

const SideBar = ({
  routingGuideSidebar,
  findCarriersSidebar,
  rfp,
  selectedLane,
  isLoadingCarrierInvites,
  invites,
  resetParamsAndRefetchQueries,
  isLoadingRecommendedCarriers,
  recommendedCarriersData,
  carriersRecommendationsError,
  refetchCarrierInvites,
}: SideBarProps) => {
  return (
    <>
      <BaseSideBar showToggle={false} isOpen={routingGuideSidebar.modalState}>
        {rfp.state !== RFP_STATE.DRAFT &&
          selectedLane &&
          !isLoadingCarrierInvites && (
            <RoutingGuide
              lane={selectedLane}
              carrierInvites={invites}
              onClose={routingGuideSidebar.closeModal}
              rfp={rfp}
              awardScenariosCallback={resetParamsAndRefetchQueries}
            />
          )}
      </BaseSideBar>
      <BaseSideBar showToggle={false} isOpen={findCarriersSidebar.modalState}>
        {selectedLane && (
          <RecommendedCarriers
            rfp={rfp}
            onClose={findCarriersSidebar.closeModal}
            isLoadingRecommendedCarriers={isLoadingRecommendedCarriers}
            data={recommendedCarriersData}
            notFoundCarriersRecommendations={carriersRecommendationsError}
            refetchCarrierInvites={refetchCarrierInvites}
          />
        )}
      </BaseSideBar>
    </>
  )
}

export default SideBar
