import { Dialog, Text } from '@loadsmart/loadsmart-ui'

import { RFP_STATE } from 'utils/constants'
import { plural } from 'utils/strings'

const InviteAllFilteredDialog = ({
  isOpen,
  onClose,
  onConfirm,
  rfpState,
  isInvitingAll,
  carriersCount,
}: {
  readonly isOpen: boolean
  readonly onClose: () => void
  readonly onConfirm: () => void
  readonly rfpState: RfpState
  readonly isInvitingAll: boolean
  readonly carriersCount: number
}) => {
  return (
    <Dialog open={isOpen} scale="small" onOverlayClick={onClose}>
      <Dialog.Header>
        {rfpState === RFP_STATE.DRAFT
          ? 'Add all carriers to bid'
          : 'Invite all carriers to bid'}
      </Dialog.Header>
      <Dialog.Body>
        <Text variant="body" color="color-neutral-darker">
          You are about to {rfpState === RFP_STATE.DRAFT ? 'add' : 'invite'}{' '}
          {`${carriersCount} ${plural('carrier', carriersCount)}`} to bid on
          this RFP.
        </Text>
        <br />
        <br />
        <Text variant="body" color="color-neutral-darker">
          Would you like to proceed?
        </Text>
        <br />
        <br />
        <Text variant="body" color="color-neutral-darker">
          Please notice that the carrier
          <br /> selection is currently based on the
          <br /> filters you applied.
        </Text>
      </Dialog.Body>
      <Dialog.ActionConfirm onConfirm={onConfirm} loading={isInvitingAll}>
        Yes, {rfpState === RFP_STATE.DRAFT ? 'add' : 'invite'} all carriers to
        bid
      </Dialog.ActionConfirm>
      <Dialog.ActionCancel onCancel={onClose}>Cancel</Dialog.ActionCancel>
    </Dialog>
  )
}

export default InviteAllFilteredDialog
