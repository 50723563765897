import Empty from 'assets/icons/empty.svg'

import { EmptyStateSvg, SubTitle, Title, Wrapper } from './styles'

export default function EmptyState({
  title,
  subTitle,
}: {
  readonly title?: string
  readonly subTitle?: string
}) {
  return (
    <Wrapper>
      <EmptyStateSvg
        src={Empty}
        alt="emptyState"
        data-testid="empty-state-icon"
      />
      {title && <Title>{title}</Title>}
      {subTitle && <SubTitle>{subTitle}</SubTitle>}
    </Wrapper>
  )
}
