import styled from 'styled-components'

export const WrapperV2 = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 60px 30px 60px;
  width: calc(100% - 120px);
`
export const TextWrapper = styled.div`
  margin: 0 -20px 10px -20px;
  width: calc(100% + 40px);

  .overallSubtitle {
    text-align: center;
  }

  .overallValue {
    line-height: 1rem;
  }
`

export const BarV2 = styled.div`
  background: ${({ theme }) => theme.colors.neutralLighter};
  border-radius: 4px;
  height: 6px;
  position: relative;
  width: 100%;

  .lowest,
  .average,
  .highest {
    position: absolute;
    width: 2px;
    height: 12px;
    bottom: 0;
  }

  .lowest {
    background: ${({ theme }) => theme.colors.success};
    left: 6px;
  }

  .average {
    background: ${({ theme }) => theme.colors.warning};
    left: calc(50% - 1px);
  }

  .highest {
    background: ${({ theme }) => theme.colors.dangerDark};
    right: 6px;
  }
`
