import { LoadingDots } from '@loadsmart/loadsmart-ui'
import { useCallback, useEffect, useRef, useState } from 'react'
import type { ChangeEvent } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Radio } from 'components/Input'
import { ActionLinkSmall } from 'components/SideBar/styles'
import { RateType } from 'features/laneProposals/reducer'
import { patch } from 'services/lane'
import { theme } from 'styles/theme'
import formatCurrency from 'utils/formatCurrency'

import { ActionWrapperV2, AmountV2, InputV2, WrapperV2 } from './styles'

export function TargetRate({
  lane,
  isSelected = false,
  onChange,
}: {
  readonly lane: Lane
  readonly isSelected: boolean
  readonly onChange: (a: RateType) => void
}) {
  const { rfpId } = useParams<{ rfpId: string }>()
  const laneIdRef = useRef(lane?.id)
  const [value, setValue] = useState(lane?.target_rate)
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const queryClient = useQueryClient()

  const { mutate: updateTargetRate, isLoading } = useMutation({
    mutationFn: patch,
    onSuccess(newLane) {
      toast.success('Target rate saved')
      queryClient.refetchQueries({ queryKey: ['retrieveLaneProposal'] })
      setValue(newLane?.target_rate)
    },
    onSettled() {
      setIsEditing(false)
    },
  })

  const action = useCallback(() => {
    if (isEditing) {
      updateTargetRate({
        rfpId: +rfpId,
        laneId: lane.id,
        lane: { target_rate: value },
      })
    } else {
      setIsEditing(true)
    }
  }, [isEditing, updateTargetRate, rfpId, lane.id, value])

  const handleChange = useCallback(
    ({ currentTarget: { value: newValue } }: ChangeEvent<HTMLInputElement>) => {
      const cleanedValue = newValue.toString().replace(/[^\d.]/g, '')
      setValue(Number(cleanedValue))
    },
    []
  )

  useEffect(() => {
    if (laneIdRef.current !== lane?.id) {
      laneIdRef.current = lane?.id
      setValue(lane?.target_rate)
    }
  }, [lane])

  const formattedValue = value ? formatCurrency(value) : '$ -'

  const actionText = isEditing ? 'Save' : 'Edit'

  return (
    <WrapperV2 aria-label="Target Rate">
      <Radio
        color={theme.colors.text}
        name="rateType"
        scheme="light"
        scale="small"
        value={RateType.USER}
        checked={isSelected}
        onChange={() => onChange(RateType.USER)}
      >
        Target Rate
      </Radio>
      <ActionWrapperV2>
        <ActionLinkSmall onClick={action}>
          {isLoading ? (
            <div className="flex alig-center justify-center">
              <LoadingDots />
            </div>
          ) : (
            actionText
          )}
        </ActionLinkSmall>
        {isEditing ? (
          <InputV2
            aria-label="Target Rate"
            name="targetRate"
            onChange={handleChange}
            value={value}
          />
        ) : (
          <AmountV2>{formattedValue}</AmountV2>
        )}
      </ActionWrapperV2>
    </WrapperV2>
  )
}
