import { IconHourglass, IconRetry } from '@loadsmart/icons'
import { Tooltip } from '@loadsmart/loadsmart-ui'
import styled from 'styled-components'

export const RankHeader = styled.div`
  align-items: center;
  display: inline-flex;
  padding-left: 16px;
`
export const RankCell = styled(RankHeader)`
  display: flex;
  padding-left: 4px;
`
export const CarrierHeader = styled.div`
  align-items: center;
  display: inline-flex;
  padding-left: 16px;
`
export const CarrierCell = styled(CarrierHeader)`
  display: flex;
`
export const LSIcon = styled.object`
  height: 22px;
  width: 101px;
`
export const PrimaryBackupButtonsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-items: space-between;
`
export const TooltipTextContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-items: center;
`
export const EmptyStateWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 40px 0;
  width: 100%;
`
export const IconHourglassStyled = styled(IconHourglass)`
  margin-right: 8px;
  min-width: ${(props) => `${props.width}px`};
`
export const IconRefreshStyled = styled(IconRetry)`
  margin-right: 8px;
  min-width: ${(props) => `${props.width}px`};
`
export const Capacity = styled.span<{ highlight: boolean }>`
  ${({ highlight, theme }) =>
    highlight &&
    `
    color: ${theme.colors.warningDark};
  `}
`
export const RatePercent = styled.span<{ color: string }>`
  color: ${({ color }) => color};
  font-weight: bold;
  white-space: nowrap;
`
export const RotatedImage = styled.img`
  transform: rotate(180deg);
`
export const IconPlaceholder = styled.div`
  height: 20px;
  width: 20px;
`
export const RankedAwardIcon = styled.img<{ disabled?: boolean }>`
  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.25;
  `}
  margin-bottom: 2px;
  padding: 6px 12px 6px 4px;
`

export const RankedAwardTooltip = styled(Tooltip)`
  cursor: pointer;
  display: flex;
`
