import { Button, Header } from '@loadsmart/miranda-react'

type Props = {
  readonly pageAction: string
  readonly handleCreateRFP: () => void
  readonly handleCancel: () => void
  readonly isCreationEnabled: boolean
}

export default function RFPFormHeader({
  pageAction,
  handleCreateRFP,
  handleCancel,
  isCreationEnabled,
}: Props) {
  return (
    <Header>
      <Header.Content>
        <Header.Title>{`${pageAction} RFP`}</Header.Title>
      </Header.Content>
      <Header.Actions>
        <Button variant="tertiary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          disabled={!isCreationEnabled}
          variant="primary"
          onClick={handleCreateRFP}
        >
          {`${pageAction} RFP`}
        </Button>
      </Header.Actions>
    </Header>
  )
}
