import { Layout } from '@loadsmart/loadsmart-ui'
import type { Dispatch, SetStateAction, MouseEvent } from 'react'
import type { UseTableRowProps } from 'react-table'

import type { TableData } from 'components/LaneManagement/LanesTableV2/types'
import { getLaneStatus } from 'components/LaneManagement/LanesTableV2/utils'
import LinkButton from 'components/LinkButton'
import RankedProposalBadge from 'rfp/components/RankedProposalBadge'
import {
  getDestOrDeliveryColumn,
  getLaneIdColumn,
  getMileageColumn,
  getOriginOrPickupColumn,
  getVolumeColumn,
  getModeAndEquipmentColumn,
} from 'rfp/components/table/columns'
import IncumbentTooltip from 'rfp/rfp-details/award-scenarios/IncumbentTooltip'
import { TableHeaderDropdown } from 'rfp/rfp-details/award-scenarios/TableHeaderDropdown'
import LaneStatusIndicator from 'rfp/rfp-details/components/lane-status-indicator'
import MarketBenchmarkTooltip from 'rfp/rfp-details/components/live-market-rate/MarketBenchmarkTooltip'
import MultiCurrencyValue from 'rfp/rfp-details/components/MultiCurrencyValue'
import type { RequestForProposal } from 'rfp/rfp.types'
import analytics, {
  AnalyticsEvent,
  AnalyticsEventTrigger,
} from 'utils/analytics'
import { BID_TYPE } from 'utils/constants'
import { isValidLane } from 'utils/lanes'

type TableRow = UseTableRowProps<TableData>

const optionsDict: Record<string, string> = {
  last_award: 'LAST AWARD',
  target_rate: 'TARGET RATE',
  market_rate: 'MARKET BENCHMARK',
  contract_rate: 'SHORT CONTR.',
  lowest_bid: 'LOWEST BID',
  average_bid: 'AVG. BID',
  highest_bid: 'HIGHEST BID',
}

export default function makeAddLanesColumns({
  laneIdMaxLength,
  rateOption,
  availableRateOptions,
  displayMultiCurrency,
  rfp,
  showRecommendedCarriersSidebar,
  setRadioOptionTwo,
}: {
  laneIdMaxLength: number
  rateOption: string
  availableRateOptions: {
    value: string
    label: string
  }[]
  displayMultiCurrency: boolean
  rfp: RequestForProposal
  showRecommendedCarriersSidebar: (lane: Lane) => void
  setRadioOptionTwo: Dispatch<SetStateAction<string>>
}) {
  return [
    {
      Header: '',
      id: 'state',
      className: 'text-center small',
      accessor: (lane: TableData) => getLaneStatus(lane, rfp.state),
      Cell: function CellLaneState({
        row,
      }: Readonly<{
        row: TableRow
      }>) {
        const lane = row.original as Lane
        if (isValidLane(lane)) {
          return (
            <RankedProposalBadge
              isRanked={
                !!lane?.awarded_proposals && lane.awarded_proposals.length > 0
              }
            />
          )
        }
        return (
          <LaneStatusIndicator
            lane={lane}
            rfpState={rfp.state as RfpState}
            isSpotMode={rfp.capacity_time_period === 'spot'}
          />
        )
      },
    },
    getLaneIdColumn(laneIdMaxLength),
    getOriginOrPickupColumn(),
    getDestOrDeliveryColumn(),
    getMileageColumn(rfp.distance_type),
    getModeAndEquipmentColumn(),
    getVolumeColumn(),
    {
      Header: 'INCUMBENT',
      id: 'incumbent',
      accessor: '',
      className: 'text-left medium',
      Cell: function CellIncumbent({ row }: { readonly row: any }) {
        const { incumbent_carriers_awards, incumbent_carriers_names } =
          row.original

        if (
          !incumbent_carriers_awards ||
          !incumbent_carriers_names ||
          incumbent_carriers_awards?.length === 0 ||
          incumbent_carriers_names?.length === 0
        ) {
          return (
            <Layout.Group
              justify="flex-start"
              align="center"
              space="s"
              style={{ flexWrap: 'nowrap', whiteSpace: 'nowrap' }}
            >
              No Carriers
            </Layout.Group>
          )
        }

        if (incumbent_carriers_names.length === 1) {
          return (
            <Layout.Group
              justify="flex-start"
              align="center"
              space="s"
              style={{ flexWrap: 'nowrap', whiteSpace: 'nowrap' }}
            >
              {incumbent_carriers_names[0]}
            </Layout.Group>
          )
        }

        const incumbents = incumbent_carriers_names.map(
          (name: string, index: number) => ({
            name,
            award: incumbent_carriers_awards[index],
          })
        )

        return (
          <Layout.Group
            justify="flex-start"
            align="center"
            space="s"
            style={{ flexWrap: 'nowrap', whiteSpace: 'nowrap' }}
          >
            Split Primary
            <IncumbentTooltip carriers={incumbents} />
          </Layout.Group>
        )
      },
    },
    {
      Header: (
        <Layout.Group space="xs" style={{ flexWrap: 'nowrap' }}>
          {optionsDict[rateOption]}
          {rateOption === 'market_rate' && <MarketBenchmarkTooltip />}
          <TableHeaderDropdown
            options={availableRateOptions}
            defaultValue={rateOption}
            setValue={setRadioOptionTwo}
            disabled={availableRateOptions.length <= 1}
          />
        </Layout.Group>
      ),
      id: rateOption,
      accessor: rateOption,
      className: 'text-left medium',
      Cell: function CellFlatRate({ row }: { readonly row: any }) {
        const valueField = displayMultiCurrency
          ? `converted_${rateOption}`
          : rateOption

        return (
          <MultiCurrencyValue
            value={row.original[valueField]}
            rfpCurrency={rfp.currency}
            nonConvertedValue={row.original[rateOption]}
          />
        )
      },
    },
    {
      Header: '',
      id: 'actions',
      className: 'flex flex-right',
      style: {
        justifyContent: 'flex-end',
        display: 'flex',
      },
      Cell: function CellActions({ row }: { readonly row: any }) {
        const onFindCarrierButtonClick = (
          event: MouseEvent<HTMLButtonElement>
        ) => {
          event.stopPropagation()
          showRecommendedCarriersSidebar(row.original)
          analytics.track(
            AnalyticsEvent.LaneActionsDropdownFindCarriersClick,
            AnalyticsEventTrigger.click,
            {
              bid_type: BID_TYPE.RANK,
              rfp_state: rfp.state,
            }
          )
        }

        return (
          <LinkButton onClick={onFindCarrierButtonClick}>
            Find carrier
          </LinkButton>
        )
      },
    },
  ]
}
