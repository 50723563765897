import { Dialog, Text } from '@loadsmart/loadsmart-ui'

export default function InfoDialog({
  isOpen,
  onClose,
  setDontShowAgain,
}: {
  readonly isOpen: boolean
  readonly onClose: () => void
  readonly setDontShowAgain: () => void
}) {
  return (
    <Dialog open={isOpen} scale="small" onOverlayClick={onClose}>
      <Dialog.Close onClick={onClose} />
      <Dialog.Header>Add New Facility</Dialog.Header>
      <Dialog.Body>
        <Text variant="body" color="color-neutral-dark">
          Now you can add facilities to your lanes. Download the new template
          and start to use it.
        </Text>
      </Dialog.Body>
      <Dialog.ActionConfirm onConfirm={onClose}>Ok</Dialog.ActionConfirm>
      <Dialog.ActionCancel
        onCancel={() => {
          setDontShowAgain()
          onClose()
        }}
      >
        Don&apos;t show this again
      </Dialog.ActionCancel>
    </Dialog>
  )
}
