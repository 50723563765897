import { IconMarkersPin } from '@loadsmart/icons'
import type { SelectOptionProps } from '@loadsmart/loadsmart-ui'
import {
  Text,
  Select,
  HighlightMatch,
  useSelect,
  Layout,
} from '@loadsmart/loadsmart-ui'
import { Icon } from '@loadsmart/miranda-react'
import styled from 'styled-components'

import OpenDockIcon from 'assets/imgs/opendock-icon.png'

const FacilityName = styled(Text)`
  max-width: 345px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export default function StopSelectOption({ value }: SelectOptionProps) {
  const select = useSelect()
  const option = select.getOption(value) as LocationOptionForFacility
  const query = select.query
  const { label, uuid, address, warehouse_uuid } = option

  return (
    <Select.Option value={value}>
      <Layout.Group space="s" style={{ overflow: 'ellipsis' }}>
        {uuid ? (
          label && (
            <>
              <Icon
                name="warehouse"
                color="color-primary-100"
                style={{ paddingTop: '2px' }}
              />
              <Layout.Stack space="2xs">
                <Layout.Group space="xs" align="center">
                  <FacilityName variant="body-bold">
                    <HighlightMatch text={`${label}`} match={query} />
                    &nbsp;
                  </FacilityName>
                  {warehouse_uuid && (
                    <img src={OpenDockIcon} height={12} alt="OpenDock" />
                  )}
                </Layout.Group>
                <Text variant="caption">{`${address}`}</Text>
              </Layout.Stack>
            </>
          )
        ) : (
          <>
            <IconMarkersPin
              width={17}
              height={17}
              title={null}
              aria-label={null}
            />
            {address && <HighlightMatch text={address} match={query} />}
          </>
        )}
      </Layout.Group>
    </Select.Option>
  )
}
