import { getToken } from '@loadsmart/loadsmart-ui/dist/theming'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import * as Sentry from '@sentry/react'
import { FulfillmentsRoutes } from 'fulfillments/FulfillmentsRoutes'
import { PayablesRoutes } from 'payables/PayablesRoutes'
import { lazy, Suspense, useEffect } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'

import { GlobalSpinner } from '_shared_/components/GlobalSpinner'
import OnboardDialog from '_shared_/components/onboard-dialog'
import { userUtils } from '_shared_/user'
import { useCurrentUser } from '_shared_/user/useCurrentUser'
import Delighted from 'components/Delighted'
import Page, { LoggedOutPage } from 'components/Page'
import PrivateRoute from 'components/PrivateRoute'
import { useSettings } from 'contexts/settings'
import { ShipmentsProvider } from 'contexts/shipments'
import { SpotQuoteProvider } from 'contexts/spotQuotes'
import { OrdersRoutes } from 'orders/OrdersRoutes'
import { RfpRoutes } from 'rfp/RfpRoutes'
import * as ErrorScreens from 'screens/ErrorScreens'
import { theme } from 'styles/theme'
import analytics, {
  AnalyticsEvent,
  AnalyticsEventTrigger,
} from 'utils/analytics'
import { DATA_INSIGHTS_URL, USER_CATEGORIES, USER_TYPE } from 'utils/constants'

import LoginThroughBIP from '../auth/LoginThroughBIP'
import LoginThroughBIPCallback from '../auth/LoginThroughBIPCallback'
import { AppRoutes } from './AppRoutes'
import { Home } from './Home'

const ShipperRFPList = lazy(() => import('../rfp/rfp-list'))
const ShipperSettings = lazy(() => import('../screens/Shipper/Settings'))
const Login = lazy(() => import('../screens/Login'))
const SignUp = lazy(() => import('../screens/SignUp'))
const SignUpRevamped = lazy(() => import('../screens/SignUpRevamped'))
const EmailVerification = lazy(() => import('../screens/EmailVerification'))
const ForgotPassword = lazy(() => import('../screens/ForgotPassword'))
const ResetPassword = lazy(() => import('../screens/ResetPassword'))
const CompleteRegistration = lazy(
  () => import('../screens/CompleteRegistration')
)
const PendingActivation = lazy(() => import('../screens/PendingActivation'))

const PlanOrders = lazy(() => import('../screens/Orders/Plan'))
const SuppliersList = lazy(() => import('../suppliers/list'))

const FreightWaves = lazy(() => import('../freightwaves/freightwaves-list'))

const Chat = lazy(() => import('../components/Chat'))

const CarrierManagement = lazy(
  () => import('../screens/Shipper/CarrierManagement')
)
const Lanes = lazy(() => import('../lanes/lane-list'))
const ShipmentsList = lazy(
  () => import('../screens/Shipper/Shipments/ShipmentList')
)
const AutoTenderRules = lazy(
  () => import('shipments/auto-tender/AutoTenderRulesPage')
)

const NewShipment = lazy(
  () => import('../screens/Shipper/Shipments/NewShipment')
)
const ShipmentDetails = lazy(
  () => import('../screens/Shipper/Shipments/Details/ShipmentDetailsPage')
)

const ShipmentEditPage = lazy(
  () => import('../screens/Shipper/Shipments/edit/ShipmentEditPage')
)

const ShipmentTracking = lazy(
  () => import('../screens/Shipper/ShipmentTracking')
)
const NewQuote = lazy(() => import('../screens/NewQuote'))
const QuoteList = lazy(() => import('../screens/Quotes/List'))
const QuoteDetails = lazy(() => import('../screens/Quotes/Details'))
const Checkout = lazy(() => import('../screens/Shipper/Checkout/CheckoutPage'))
const ContractsList = lazy(() => import('../contracts/contracts-list'))
const ContractDetails = lazy(() => import('../contracts/contract-details'))
const NewContract = lazy(() => import('../contracts/contract-add'))
const EditContract = lazy(() => import('../contracts/contract-edit'))
const FacilityManagement = lazy(
  () => import('../screens/Shipper/FacilityManagement')
)
const DataInsightsIframePage = lazy(
  () => import('../screens/Shipper/DataInsights/DataInsightsIframePage')
)
const InsightsPage = lazy(() => import('../screens/Insights/InsightsPage'))
const BulkInvite = lazy(() => import('../screens/Quotes/BulkInvite'))
const BulkQuotesFromShipments = lazy(
  () => import('../screens/Quotes/BulkQuotesFromShipments')
)
const CarrierChat = lazy(
  () => import('../packages/chat/src/components/CarrierChat')
)

export function shouldEnableChat(zendeskWidgetChangesEnabled: boolean) {
  return !zendeskWidgetChangesEnabled && userUtils.isLoggedIn
}

export function shouldEnableInsightsAI(enableInsightsAI: boolean) {
  return enableInsightsAI && userUtils.isUserType([USER_TYPE.SG])
}

export function shouldEnableAutoTenderRules(
  rbtSettingsEnabled: boolean,
  isPaidUser: boolean
) {
  return (
    rbtSettingsEnabled &&
    isPaidUser &&
    userUtils.isUserType([USER_TYPE.SG, USER_TYPE.FBM])
  )
}

export function shouldEnableFacilityManagement(
  facilityManagementEnabled: boolean
) {
  return (
    facilityManagementEnabled &&
    userUtils.isUserType([USER_TYPE.SG, USER_TYPE.FBM])
  )
}

export function shouldEnablePriceMonitor(): boolean {
  return userUtils.isUserType([USER_TYPE.SG])
}

export function Router() {
  const { user } = useCurrentUser()
  const { pathname, search } = useLocation()

  useEffect(() => {
    analytics.track(
      AnalyticsEvent.ShipperGuidePageViews,
      AnalyticsEventTrigger.view,
      { url: pathname }
    )
  }, [pathname])

  const {
    isLoading,
    values: [
      facilityManagementEnabled,
      rbtSettingsEnabled,
      zendeskWidgetChangesEnabled,
      planOrdersPageEnabled,
      suppliersPageEnabled,
      enableSignupRevamped,
      enableInsightsAI,
      enableOnboardDialog,
    ],
  } = useSettings([
    'flags.ENABLE_FACILITY_MANAGEMENT',
    'flags.ENABLE_RBT_SETTINGS',
    'flags.ENABLE_ZENDESK_WIDGET_CHANGES',
    'flags.ENABLE_PLAN_ORDERS_PAGE',
    'flags.ENABLE_SUPPLIERS_PAGE',
    'flags.ENABLE_SIGNUP_REVAMPED',
    'flags.ENABLE_FREIGHT_INSIGHTS_AI',
    'flags.ENABLE_ONBOARD_DIALOG',
  ])

  const isPaidUser = user?.user_category === USER_CATEGORIES.PAID_SHIPPER

  const getSignupComponent = () => {
    return enableSignupRevamped ? <SignUpRevamped /> : <SignUp />
  }

  if (isLoading) {
    return null
  }

  const showOnboardDialog =
    userUtils.isLoggedIn && enableOnboardDialog && !userUtils.isSupplierUser

  return (
    <Sentry.ErrorBoundary fallback={<ErrorScreens.NetworkError />}>
      <Suspense fallback={<GlobalSpinner />}>
        {shouldEnableChat(zendeskWidgetChangesEnabled) && <Chat />}
        {userUtils.isLoggedIn && <CarrierChat />}
        {showOnboardDialog && <OnboardDialog />}
        <Switch>
          <Redirect
            from="/:url*(/+)"
            to={`${pathname.slice(0, -1)}${search}`}
          />
          <PrivateRoute
            exact
            path={AppRoutes.RFP}
            enabled={userUtils.isUserType([USER_TYPE.SG])}
          >
            <Page>
              <Delighted />
              <ShipperRFPList />
            </Page>
          </PrivateRoute>
          <PrivateRoute
            path={AppRoutes.RFP}
            enabled={userUtils.isUserType([USER_TYPE.SG])}
            render={(props) => (
              <Page>
                <RfpRoutes {...props} />
              </Page>
            )}
          />
          <PrivateRoute path={AppRoutes.ShipperSettings}>
            <Page padding={getToken('space-none')}>
              <ShipperSettings />
            </Page>
          </PrivateRoute>
          <PrivateRoute
            exact
            path={AppRoutes.Carriers}
            enabled={userUtils.isUserType([USER_TYPE.SG])}
          >
            <Page>
              <Delighted />
              <CarrierManagement />
            </Page>
          </PrivateRoute>
          <PrivateRoute
            exact
            path={AppRoutes.Lanes}
            enabled={userUtils.isUserType([USER_TYPE.SG])}
          >
            <Page>
              <Delighted />
              <Lanes />
            </Page>
          </PrivateRoute>
          <PrivateRoute
            exact
            path={AppRoutes.ShipperReports}
            enabled={userUtils.isUserType([USER_TYPE.SG])}
          >
            <Page padding="0">
              <DataInsightsIframePage
                url={`${DATA_INSIGHTS_URL}/shipper-guide/?redirectTo=report`}
                title="Reports"
              />
            </Page>
          </PrivateRoute>
          <PrivateRoute
            exact
            path={AppRoutes.Insights}
            enabled={shouldEnableInsightsAI(enableInsightsAI)}
          >
            <Page padding="0">
              <InsightsPage />
            </Page>
          </PrivateRoute>
          <PrivateRoute
            exact
            path={AppRoutes.NewQuote}
            enabled={userUtils.isUserType([USER_TYPE.SG, USER_TYPE.FBM])}
          >
            <Page color={theme.colors.whiteBroken} padding="0">
              <NewQuote />
            </Page>
          </PrivateRoute>
          <PrivateRoute
            exact
            path={AppRoutes.QuoteDetails}
            enabled={userUtils.isUserType([USER_TYPE.SG, USER_TYPE.FBM])}
          >
            <Page padding="0">
              <QuoteDetails />
            </Page>
          </PrivateRoute>
          <PrivateRoute
            exact
            path={AppRoutes.Checkout}
            enabled={userUtils.isUserType([USER_TYPE.SG, USER_TYPE.FBM])}
          >
            <Page color={theme.colors.neutralLight} padding="2em">
              <Checkout />
            </Page>
          </PrivateRoute>
          <PrivateRoute
            exact
            path={AppRoutes.Quotes}
            enabled={userUtils.isUserType([USER_TYPE.SG, USER_TYPE.FBM])}
          >
            <Page>
              <Delighted />
              <SpotQuoteProvider>
                <QuoteList />
              </SpotQuoteProvider>
            </Page>
          </PrivateRoute>
          <PrivateRoute
            exact
            path={AppRoutes.BulkInvite}
            enabled={userUtils.isUserType([USER_TYPE.SG, USER_TYPE.FBM])}
          >
            <Page color={theme.colors.whiteBroken} padding="0">
              <BulkInvite />
            </Page>
          </PrivateRoute>
          <PrivateRoute
            exact
            path={AppRoutes.BulkQuotesFromShipments}
            enabled={userUtils.isUserType([USER_TYPE.SG, USER_TYPE.FBM])}
          >
            <Page>
              <BulkQuotesFromShipments />
            </Page>
          </PrivateRoute>
          <PrivateRoute
            exact
            path={AppRoutes.Shipments}
            enabled={userUtils.isUserType([
              USER_TYPE.SG,
              USER_TYPE.FBM,
              USER_TYPE.MTC,
            ])}
          >
            <ShipmentsProvider>
              <Page padding="0">
                <Delighted />
                <ShipmentsList />
              </Page>
            </ShipmentsProvider>
          </PrivateRoute>
          <PrivateRoute
            exact
            path={AppRoutes.AutoTenderRules}
            enabled={shouldEnableAutoTenderRules(
              rbtSettingsEnabled,
              isPaidUser
            )}
          >
            <Page padding="0" color={toCSSValue('color-background-secondary')}>
              <AutoTenderRules />
            </Page>
          </PrivateRoute>
          <PrivateRoute
            exact
            path={AppRoutes.NewShipment}
            enabled={userUtils.isUserType([USER_TYPE.SG, USER_TYPE.FBM])}
          >
            <Page>
              <NewShipment />
            </Page>
          </PrivateRoute>
          <PrivateRoute
            exact
            path={AppRoutes.EditShipment}
            enabled={userUtils.isUserType([USER_TYPE.SG])}
          >
            <Page color={toCSSValue('color-background-secondary')}>
              <ShipmentEditPage />
            </Page>
          </PrivateRoute>
          <PrivateRoute
            path={AppRoutes.ShipmentDetails}
            enabled={userUtils.isUserType([
              USER_TYPE.SG,
              USER_TYPE.FBM,
              USER_TYPE.MTC,
            ])}
          >
            <ShipmentsProvider>
              <Page color={toCSSValue('color-background-secondary')}>
                <ShipmentDetails />
              </Page>
            </ShipmentsProvider>
          </PrivateRoute>
          <PrivateRoute
            exact
            path={AppRoutes.FacilityManagement}
            enabled={shouldEnableFacilityManagement(facilityManagementEnabled)}
          >
            <Page>
              <FacilityManagement />
            </Page>
          </PrivateRoute>
          <PrivateRoute
            exact
            path={AppRoutes.Contracts}
            enabled={userUtils.isUserType([USER_TYPE.SG])}
          >
            <Page>
              <ContractsList />
            </Page>
          </PrivateRoute>
          <PrivateRoute
            exact
            path={AppRoutes.NewContract}
            enabled={userUtils.isUserType([USER_TYPE.SG])}
          >
            <Page>
              <NewContract />
            </Page>
          </PrivateRoute>
          <PrivateRoute
            exact
            path={AppRoutes.EditContract}
            enabled={userUtils.isUserType([USER_TYPE.SG])}
          >
            <Page>
              <EditContract />
            </Page>
          </PrivateRoute>
          <PrivateRoute
            exact
            path={AppRoutes.ContractDetails}
            enabled={userUtils.isUserType([USER_TYPE.SG])}
          >
            <Page>
              <ContractDetails />
            </Page>
          </PrivateRoute>
          <PrivateRoute
            path={AppRoutes.Orders}
            enabled={userUtils.isUserType([USER_TYPE.SUPPLIER, USER_TYPE.SG])}
            render={(props) => (
              <Page padding="0">
                <OrdersRoutes {...props} />
              </Page>
            )}
          />
          <PrivateRoute
            path={AppRoutes.Payables}
            enabled={userUtils.isUserType([USER_TYPE.SG])}
            render={(props) => (
              <Page padding="0">
                <PayablesRoutes {...props} />
              </Page>
            )}
          />
          <PrivateRoute
            path={AppRoutes.PlanOrders}
            enabled={planOrdersPageEnabled}
          >
            <Page>
              <PlanOrders />
            </Page>
          </PrivateRoute>
          <PrivateRoute
            path={AppRoutes.SuppliersList}
            enabled={suppliersPageEnabled}
          >
            <Page padding="0">
              <SuppliersList />
            </Page>
          </PrivateRoute>
          <PrivateRoute
            path={AppRoutes.Fulfillments}
            enabled={userUtils.isUserType([USER_TYPE.SUPPLIER, USER_TYPE.SG])}
            render={(props) => (
              <Page padding="0">
                <FulfillmentsRoutes {...props} />
              </Page>
            )}
          />
          <PrivateRoute
            exact
            path={AppRoutes.PriceMonitor}
            enabled={shouldEnablePriceMonitor()}
          >
            <Page padding="0">
              <DataInsightsIframePage
                url={`${DATA_INSIGHTS_URL}/shipper-guide/?redirectTo=monitor`}
                title="Price Monitor"
              />
            </Page>
          </PrivateRoute>
          <PrivateRoute
            exact
            path={AppRoutes.FreightWaves}
            enabled={userUtils.isUserType([USER_TYPE.SG])}
          >
            <Page>
              <FreightWaves />
            </Page>
          </PrivateRoute>
          <PrivateRoute exact path="/">
            <Home />
          </PrivateRoute>
          <Route exact path="/shipment-tracking/:shipmentId">
            <ShipmentTracking />
          </Route>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/oauth2-login">
            <LoginThroughBIP homePage="/" />
          </Route>
          <Route exact path="/oauth2-login-callback">
            <LoginThroughBIPCallback homePage="/" />
          </Route>
          <Route exact path="/fbm-login">
            <Login />
          </Route>
          <Route exact path="/signup">
            {getSignupComponent()}
          </Route>
          <Route exact path="/fbm-signup">
            <SignUp />
          </Route>
          <Route exact path="/email_verification/:token">
            <LoggedOutPage color={theme.colors.background}>
              <EmailVerification />
            </LoggedOutPage>
          </Route>
          <Route exact path="/email_verification">
            <LoggedOutPage color={theme.colors.background}>
              <EmailVerification />
            </LoggedOutPage>
          </Route>
          <Route exact path="/forgot_password">
            <LoggedOutPage color={theme.colors.background}>
              <ForgotPassword />
            </LoggedOutPage>
          </Route>
          <Route exact path="/reset_password/:token">
            <LoggedOutPage color={theme.colors.background}>
              <ResetPassword />
            </LoggedOutPage>
          </Route>
          <Route exact path="/complete_registration/:token">
            <LoggedOutPage color={theme.colors.background}>
              <CompleteRegistration />
            </LoggedOutPage>
          </Route>
          <Route exact path="/pending_activation">
            <PendingActivation />
          </Route>
          <Route path="*">
            <ErrorScreens.NotFound />
          </Route>
        </Switch>
      </Suspense>
    </Sentry.ErrorBoundary>
  )
}
