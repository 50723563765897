import { IconCheck } from '@loadsmart/icons'
import { Card, Text, Layout, Button } from '@loadsmart/loadsmart-ui'
import * as Sentry from '@sentry/react'
import { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'

import type { RequestForProposal } from 'rfp/rfp.types'
import { inviteRecommendedCarrier } from 'services/recommendedCarriers'
import analytics, {
  AnalyticsEvent,
  AnalyticsEventTrigger,
} from 'utils/analytics'
import formatCurrency from 'utils/formatCurrency'

import { CarrierName } from '../styles'

const parseValue = (value: string | null | undefined) => {
  if (value === null || value === undefined || value === '') {
    return '-'
  }

  return parseInt(value, 10)
}

export default function RecommendedCarrierCard({
  rfp,
  recommendation,
  refetchCarrierInvites,
}: {
  readonly rfp: RequestForProposal
  readonly recommendation: RecommendedCarrier
  readonly refetchCarrierInvites: () => Promise<unknown>
}) {
  const queryClient = useQueryClient()
  const [carrierIsInvitedToBid, setCarrierIsInvitedToBid] =
    useState<boolean>(false)
  const {
    mutate: inviteRecommendedMutation,
    isLoading: isInvitingRecommended,
  } = useMutation({
    mutationFn: inviteRecommendedCarrier,
    onSuccess() {
      setCarrierIsInvitedToBid(true)
      refetchCarrierInvites()
      queryClient.refetchQueries({ queryKey: ['carriersFilter'] })
    },
    onError(error) {
      Sentry.captureException(error)
    },
  })

  return (
    <Card>
      <Layout.Stack space="none">
        <Layout.Box textAlign="left" style={{ padding: '24px 30px' }}>
          <Layout.Group align="center" justify="space-between">
            <Layout.Stack>
              <CarrierName variant="heading-sm-bold">
                {recommendation.carrier_info.name}
              </CarrierName>
            </Layout.Stack>
            <Layout.Stack>
              <Button
                onClick={() => {
                  inviteRecommendedMutation({
                    rfp: rfp.id,
                    recommendation: recommendation.recommendation_id,
                  })
                  analytics.track(
                    AnalyticsEvent.RecommendedCarrierAddToBidClick,
                    AnalyticsEventTrigger.click,
                    {
                      rfp: rfp.id,
                      bid_type: rfp.bid_type,
                      rfp_state: rfp.state,
                    }
                  )
                }}
                scale="default"
                disabled={carrierIsInvitedToBid && true}
                variant={carrierIsInvitedToBid ? 'secondary' : 'primary'}
                leading={
                  carrierIsInvitedToBid && <IconCheck width={16} height={16} />
                }
                loading={isInvitingRecommended}
              >
                {carrierIsInvitedToBid ? 'Added to bid' : 'Add carrier to bid'}
              </Button>
            </Layout.Stack>
          </Layout.Group>
        </Layout.Box>
        <Card.Separator />
        <Layout.Box textAlign="left" style={{ padding: '24px 30px' }}>
          <Layout.Grid space="xl">
            <Layout.Group space="2xl">
              <Layout.Stack align="flex-start" space="2xs">
                <Text variant="chips-sm" color="color-neutral">
                  OTP
                </Text>
                <Text variant="body-bold">
                  {parseValue(recommendation.carrier_info.on_time_pickup_rate)}%
                </Text>
              </Layout.Stack>
              <Layout.Stack align="flex-start" space="2xs">
                <Text variant="chips-sm" color="color-neutral">
                  OTD
                </Text>
                <Text variant="body-bold">
                  {parseValue(
                    recommendation.carrier_info.on_time_delivery_rate
                  )}
                  %
                </Text>
              </Layout.Stack>
              <Layout.Stack align="flex-start" space="2xs">
                <Text variant="chips-sm" color="color-neutral">
                  DOT
                </Text>
                <Text variant="body-bold">
                  {recommendation.carrier_info.dot}
                </Text>
              </Layout.Stack>
              <Layout.Stack align="flex-start" space="2xs">
                <Text variant="chips-sm" color="color-neutral">
                  MC NUMBER
                </Text>
                <Text variant="body-bold">
                  {recommendation.carrier_info.mc}
                </Text>
              </Layout.Stack>
              <Layout.Stack align="flex-start" space="2xs">
                <Text variant="chips-sm" color="color-neutral">
                  TYPE
                </Text>
                <Text variant="body-bold">
                  {recommendation.carrier_info.entity}
                </Text>
              </Layout.Stack>
            </Layout.Group>
            <Layout.Group space="2xl">
              <Layout.Stack align="flex-start" space="2xs">
                <Text variant="chips-sm" color="color-neutral">
                  CARGO INSURANCE
                </Text>
                <Text variant="body-bold">
                  {formatCurrency(
                    recommendation.carrier_info.cargo_on_file || '-'
                  )}
                </Text>
              </Layout.Stack>
              <Layout.Stack align="flex-start" space="2xs">
                <Text variant="chips-sm" color="color-neutral">
                  BIPD INSURANCE
                </Text>
                <Text variant="body-bold">
                  {formatCurrency(
                    recommendation.carrier_info.bipd_on_file || '-'
                  )}
                </Text>
              </Layout.Stack>
            </Layout.Group>
          </Layout.Grid>
        </Layout.Box>
      </Layout.Stack>
    </Card>
  )
}
