import type { AxiosRequestConfig } from 'axios'

import type {
  ShipperMember,
  UserBusiness,
  UserData,
  UserRole,
} from '_shared_/user'
import httpClient from 'utils/httpClient'

export type ShipperUsersResponse = Array<{
  uuid: string
  user: {
    first_name: string
    last_name: string
    email: string
    is_active: boolean
  }
  role: UserRole
  business: UserBusiness
  shipper: number
  contact_name: string
  contact_email: string
  contact_phone: string | null
  save_contact_info: boolean
}>

export const SHIPPER_MEMBERS_ENDPOINT = '/shippers/users'
const ENDPOINT = SHIPPER_MEMBERS_ENDPOINT

export const list = async (
  options?: AxiosRequestConfig<ShipperUsersResponse>
) => {
  const { data } = await httpClient.get<ShipperUsersResponse>(ENDPOINT, options)
  return data
}

export const retrieve = async (
  uuid: UserData['user_uuid'],
  config?: AxiosRequestConfig
) => {
  const { data } = await httpClient.get(`${ENDPOINT}/${uuid}`, config)
  return data
}

export const partialUpdate = async (params: Partial<ShipperMember>) => {
  const { data } = await httpClient.patch(`${ENDPOINT}/${params.uuid}`, params)
  return data
}

export const deleteMember = async (params: Partial<ShipperMember>) => {
  const { data } = await httpClient.delete(`${ENDPOINT}/${params.uuid}`)
  return data
}

export const cancelInvite = async (params: Partial<ShipperMember>) => {
  const { data } = await httpClient.post(
    `${ENDPOINT}/${params.uuid}/cancel_invite`
  )
  return data
}

export const create = async (params: Partial<ShipperMember>) => {
  const { data } = await httpClient.post(ENDPOINT, params)
  return data
}

export const resendInvite = async (params: Partial<ShipperMember>) => {
  const { data } = await httpClient.post(
    `${ENDPOINT}/${params.uuid}/resend_invite`
  )
  return data
}
