import omit from 'utils/omit'

import type { CarrierFormValue, SelectOption } from './types'

type OptionsValue = string | null | undefined
type RelationshipStatusOptionsValue =
  | Carrier['relationship_status']
  | null
  | undefined

export const entityOptions = [
  { label: 'Broker', value: 'broker' },
  { label: 'Asset', value: 'asset' },
]

export const relationshipStatusOptions: Array<
  NonNullable<CarrierFormValue['relationship_status']>
> = [
  { label: 'In-network', value: 'in_network' },
  { label: 'Onboarding', value: 'onboarding' },
  { label: 'Out-of-network', value: 'out_of_network' },
]

const MAX_ALTERNATIVE_EMAILS = 5

const getEntityOption = (value: OptionsValue) => {
  if (!value) {
    return null
  }
  return entityOptions.find((option) => option.value === value) || null
}

export const getRelationshipStatusOption = (
  value: RelationshipStatusOptionsValue
) => {
  if (!value) {
    return undefined
  }

  return (
    relationshipStatusOptions.find((option) => option.value === value) ||
    undefined
  )
}

export const getInitialValuesFromAlice = (carrier: AliceCarrier) => {
  return {
    name: carrier.legal_name,
    dot: carrier.dot,
    mc: carrier.mc,
    scac: '',
    entity: getEntityOption(carrier.entity_broker ? 'broker' : 'asset'),
    email: '',
    phone: carrier.phone_number,
    hq_address: `${carrier.physical_street}, ${carrier.physical_city}, ${carrier.physical_state} ${carrier.physical_zip} ${carrier.physical_country}`,
    req_safety_passed: carrier.req_safety_passed
      ? 'ACCEPTABLE'
      : 'UNACCEPTABLE',
    cargo_on_file: carrier.cargo_on_file,
    bipd_on_file: carrier.bipd_on_file,
    notes: '',
    on_time_delivery_rate: carrier.on_time_delivery_rate,
    on_time_pickup_rate: carrier.on_time_pickup_rate,
    on_time_delivery_count: carrier.on_time_delivery_count,
    on_time_pickup_count: carrier.on_time_pickup_count,
    total_shipment_count: carrier.total_shipment_count,
  } as unknown as CarrierFormValue
}

const getAlternativeEmails = (carrier: Carrier) => {
  if (!carrier.alternative_emails || carrier.alternative_emails?.length === 0) {
    return [{ email: '', active: false }]
  }

  if (carrier.alternative_emails.length < MAX_ALTERNATIVE_EMAILS) {
    return [...carrier.alternative_emails, { email: '', active: false }]
  }

  return carrier.alternative_emails
}

const getCarrierDefaultValues = (carrier: Carrier) => {
  const { relationship_status, ...restCarrier } = carrier

  return {
    ...restCarrier,
    relationship_status,
    mc: carrier.mc || '',
    notes: carrier.notes || '',
    phone_numbers: carrier.phone_numbers?.[0] || '',
    scac: carrier.scac || '',
    bipd_on_file: carrier.bipd_on_file || '',
    cargo_on_file: carrier.cargo_on_file || '',
    req_safety_passed: carrier.req_safety_passed || '',
    hq_address: carrier.hq_address || '',
    tags: carrier.tags || [],
    alternative_emails: getAlternativeEmails(carrier),
    auto_accept_tender: Boolean(carrier.auto_accept_tender),
  }
}

export const objectToSelectedOptions = (
  values?: Record<string, boolean>,
  options?: SelectOption[]
): SelectOption[] => {
  if (!values || !options?.length) {
    return []
  }
  return Object.entries(values)
    .filter((entry) => entry[1])
    .map((entry) => options.find((option) => option.value === entry[0]))
    .filter((option) => !!option)
}

export const getInitialValues = (
  carrier?: Carrier | null,
  options?: {
    modeOptions?: SelectOption[]
    equipmentTypeOptions?: SelectOption[]
    operationRegionOptions?: SelectOption[]
  }
): CarrierFormValue => {
  const {
    modeOptions = [],
    equipmentTypeOptions = [],
    operationRegionOptions = [],
  } = options || {}

  if (carrier) {
    const {
      dot,
      email,
      mc,
      name,
      entity,
      notes,
      phone_numbers,
      scac,
      bipd_on_file,
      cargo_on_file,
      req_safety_passed,
      hq_address,
      tags,
      unsubscribed,
      modes,
      equipment_types,
      operation_regions,
      alternative_emails,
      relationship_status,
      auto_accept_tender,
    } = getCarrierDefaultValues(carrier)

    return {
      name,
      relationship_status: getRelationshipStatusOption(relationship_status),
      dot,
      mc,
      scac: scac?.toString(),
      modes: objectToSelectedOptions(modes, modeOptions),
      operation_regions: objectToSelectedOptions(
        operation_regions,
        operationRegionOptions
      ),
      equipment_types: objectToSelectedOptions(
        equipment_types,
        equipmentTypeOptions
      ),
      entity: getEntityOption(entity),
      email,
      phone: phone_numbers,
      req_safety_passed,
      hq_address,
      cargo_on_file: cargo_on_file?.toString(),
      bipd_on_file: bipd_on_file?.toString(),
      notes,
      tags,
      unsubscribed,
      alternative_emails,
      auto_accept_tender,
    }
  }

  return {
    relationship_status: getRelationshipStatusOption('in_network'),
    name: '',
    dot: '',
    mc: '',
    scac: '',
    entity: null,
    email: '',
    phone: '',
    cargo_on_file: '',
    bipd_on_file: '',
    notes: '',
    req_safety_passed: '',
    hq_address: '',
    modes: [],
    operation_regions: [],
    equipment_types: [],
    alternative_emails: [{ email: '', active: false }],
  }
}

const selectedOptionsToObject = (
  selectedOptions?: SelectOption[],
  options?: SelectOption[]
): Record<string, boolean> | undefined => {
  if (!selectedOptions?.length || !options?.length) {
    return undefined
  }
  return options.reduce((acc: Record<string, boolean>, item) => {
    acc[item.value] = selectedOptions.some(
      (chosenMode) => chosenMode.value === item.value
    )
    return acc
  }, {})
}

const cleanupPhone = (phone: string | null | undefined) => {
  if (!phone) {
    return ''
  }

  //keeping dashes and + sign
  return phone.replace(new RegExp(/(?!-|\+)\D/gi), '')
}

const removeEmptyAlternativeEmails = (formValue: CarrierFormValue) => {
  return formValue.alternative_emails.filter((email) => email.email !== '')
}

export const formatCarrierPayload = (
  values: CarrierFormValue,
  {
    selectedUuid,
    modeOptions,
    equipmentTypeOptions,
    operationRegionOptions,
  }: {
    selectedUuid: string | null
    modeOptions: SelectOption[]
    equipmentTypeOptions: SelectOption[]
    operationRegionOptions: SelectOption[]
  }
) => {
  const payload: Partial<Carrier> = {
    ...omit<typeof values, 'phone'>('phone', values),
    modes: selectedOptionsToObject(values.modes, modeOptions),
    equipment_types: selectedOptionsToObject(
      values.equipment_types,
      equipmentTypeOptions
    ),
    operation_regions: selectedOptionsToObject(
      values.operation_regions,
      operationRegionOptions
    ),
    phone_numbers: [cleanupPhone(values.phone)],
    bipd_on_file: values.bipd_on_file ? Number(values.bipd_on_file) : undefined,
    cargo_on_file: values.cargo_on_file
      ? Number(values.cargo_on_file)
      : undefined,
    scac: values.scac ? values.scac : undefined,
    entity: values.entity?.value || '',
    relationship_status: values.relationship_status?.value ?? undefined,
    auto_accept_tender: values.auto_accept_tender,
  }

  payload.alternative_emails = removeEmptyAlternativeEmails(values)

  return selectedUuid ? { ...payload, uuid: selectedUuid } : payload
}
