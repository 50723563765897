import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { calculateShipmentDimensions } from 'components/ShippingItemsManager'
import type {
  CalculableMeasurable,
  Measurable,
} from 'components/ShippingItemsManager'

export function shouldFetchLinearFeet(
  measurables: Array<Omit<Measurable, 'hazmat'>>
): measurables is CalculableMeasurable[] {
  if (measurables.length === 0) {
    return false
  }

  return measurables.every((measurable) => {
    return (
      Number(measurable.package_count) *
        Number(measurable.length) *
        Number(measurable.width) *
        Number(measurable.height) *
        Number(measurable.weight) >
      0
    )
  })
}

type UseLinearFeet = (
  items: Array<Omit<Measurable, 'hazmat'>>,
  /**
   * In case of being present, the total weight will be used to calculate the linear feet instead of the weight.
   */
  totalWeight?: number
) => number

export const useLinearFeet: UseLinearFeet = (items, totalWeight) => {
  const measurables = useMemo(
    () =>
      items.map((handlingUnit) => ({
        package_count: handlingUnit.package_count,
        length: handlingUnit.length,
        width: handlingUnit.width,
        height: handlingUnit.height,
        stackable: Boolean(handlingUnit.stackable),
        turnable: Boolean(handlingUnit.turnable),
        weight: totalWeight ?? handlingUnit.weight,
      })),
    [items, totalWeight]
  )

  const { data } = useQuery({
    queryKey: ['linearFeet', measurables] as const,
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: Infinity,
    queryFn: ({ signal }) => {
      return calculateShipmentDimensions(measurables, { signal })
    },
  })

  return data?.total_length_feet ?? 0
}
