import { IconQuestionCircle } from '@loadsmart/icons'
import { LoadingDots, Tooltip, TooltipPosition } from '@loadsmart/loadsmart-ui'
import { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'

import { Radio } from 'components/Input'
import { ActionLinkSmall } from 'components/SideBar/styles'
import { RateType } from 'features/laneProposals/reducer'
import { getLiveMarketRate } from 'services/benchmarks'
import { theme } from 'styles/theme'
import { getEquipmentLabel } from 'utils/equipmentType'
import formatCurrency from 'utils/formatCurrency'

import MarketBenchmarkTooltip from './MarketBenchmarkTooltip'
import { ActionWrapper, AmountV2, WrapperV2 } from './styles'

export default function LiveMarketRate({
  lane,
  isSelected = false,
  onChange,
}: {
  readonly lane: Lane
  readonly isSelected: boolean
  readonly onChange: (a: RateType) => void
}) {
  const isDisabled = lane.market_rate === null
  const [isLoading, setIsloading] = useState(false)
  const queryClient = useQueryClient()

  const { mutate: getLiveMarketRates } = useMutation({
    mutationFn: getLiveMarketRate,
    async onSuccess() {
      try {
        //@TODO: There is room to optimize this since we, for sure, are making some unnecessary requests
        await Promise.all([
          queryClient.refetchQueries({
            queryKey: ['deallocatedLanes'],
            exact: false,
          }),
          queryClient.refetchQueries({
            queryKey: ['allocatedLanes'],
            exact: false,
          }),
          queryClient.refetchQueries({
            queryKey: ['awardScenarios'],
            exact: false,
          }),
        ])
      } finally {
        setIsloading(false)
      }
    },
  })

  const handleUpdateLiveMarketRate = () => {
    getLiveMarketRates(lane.id)
    setIsloading(true)
  }

  return (
    <WrapperV2 aria-label="Market Benchmark">
      <Radio
        color={theme.colors.text}
        name="rateType"
        scheme="light"
        scale="small"
        value={RateType.LIVE}
        checked={isSelected}
        onChange={() => onChange(RateType.LIVE)}
        disabled={isDisabled}
      >
        Market Benchmark
      </Radio>

      {lane?.market_rate === null ? (
        <Tooltip
          position={TooltipPosition.Bottom}
          message={
            <span>
              No rate available for {getEquipmentLabel(lane?.equipment_type)}
            </span>
          }
        >
          <IconQuestionCircle
            width={16}
            height={16}
            fill={theme.colors.neutral}
            title={null}
          />
        </Tooltip>
      ) : (
        <MarketBenchmarkTooltip />
      )}
      <ActionWrapper>
        <ActionLinkSmall
          onClick={handleUpdateLiveMarketRate}
          data-testid="live_market_rate_update_button"
        >
          {isLoading ? (
            <div className="flex alig-center justify-center">
              <LoadingDots />
            </div>
          ) : (
            'Update'
          )}
        </ActionLinkSmall>
        <AmountV2 isSelected={false}>
          {lane?.market_rate ? formatCurrency(lane.market_rate) : '$ -'}
        </AmountV2>
      </ActionWrapper>
    </WrapperV2>
  )
}
