import type { Dispatch, SetStateAction } from 'react'

import {
  getBidOptionColumn,
  getProposalCountColumn,
} from 'rfp/components/table/columns'
import type { Currency } from 'rfp/rfp.types'

export default function makeLaneDetailsColumns({
  optionsDict,
  bidOption,
  BidOptions,
  setRadioOptionOne,
  displayMultiCurrency,
  rfpCurrency,
  publishedColumns,
}: {
  optionsDict: Record<string, string>
  bidOption: string
  BidOptions: {
    value: string
    label: string
  }[]
  setRadioOptionOne: Dispatch<SetStateAction<string>>
  displayMultiCurrency: boolean
  rfpCurrency: Currency
  publishedColumns: any
}) {
  return [
    getProposalCountColumn(),
    getBidOptionColumn(
      optionsDict,
      bidOption,
      BidOptions,
      setRadioOptionOne,
      displayMultiCurrency,
      rfpCurrency
    ),
    ...publishedColumns,
  ]
}
