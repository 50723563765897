import { SideNavigation } from '@loadsmart/loadsmart-ui'
import { useHistory } from 'react-router-dom'

import { userUtils } from '_shared_/user'
import { AppRoutes } from 'router/AppRoutes'
import { USER_TYPE } from 'utils/constants'

import { isActivePath, navbarLinkClicked } from './sidenav.utils'

export const ProcurementMenu = () => {
  const history = useHistory()

  if (userUtils.isUserType([USER_TYPE.SG])) {
    return (
      <SideNavigation.Menu label="Procurement">
        <SideNavigation.Menu.Item
          label="RFP Guide"
          active={isActivePath(AppRoutes.RFP)}
          url={AppRoutes.RFP}
          onClick={(event) => {
            event.preventDefault()
            navbarLinkClicked('Home', AppRoutes.RFP, history)
          }}
        />
        <SideNavigation.Menu.Item
          label="Contracts"
          active={isActivePath(AppRoutes.Contracts)}
          url={AppRoutes.Contracts}
          onClick={(event) => {
            event.preventDefault()
            navbarLinkClicked('Contracts', AppRoutes.Contracts, history)
          }}
        />
      </SideNavigation.Menu>
    )
  }

  return null
}
