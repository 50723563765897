import { MAX_FILE_SIZE_MB, MAX_NUMBER_OF_FILES } from 'utils/constants'

export function validateFileUpload(
  files: File[],
  maxFile = MAX_NUMBER_OF_FILES,
  maxSize = MAX_FILE_SIZE_MB
): string | null {
  if (Object.keys(files).length > maxFile) {
    return `Can't upload more than 3 files.`
  }
  const filesOverSize = files
    .filter((file) => {
      const sizeInMb = file.size / (1024 * 1024)
      return sizeInMb > maxSize
    })
    .map((file) => `${file.name}`)
    .join(', ')
  if (filesOverSize) {
    return 'Files over size limit (5mb): ' + filesOverSize + '.'
  }
  return null
}
