import type {
  Commodity,
  HandlingUnit,
  TransientHandlingUnit,
} from 'components/HandlingUnitsManager/HandlingUnits.types'
import type { Measurable, PackageType } from 'components/ShippingItemsManager'
import { createTransientStop } from 'components/StopsManager'
import type { Stop, TransientStop } from 'components/StopsManager'
import type {
  Order,
  OrderFulfillmentsListItemHandlingUnit,
  OrderItem,
} from 'orders/types'
import type { StopAppointment } from 'screens/Shipper/Shipments/Details/types'
import { createTransient } from 'utils/transient'
import type { Transient } from 'utils/transient'

export type FulfillmentStatus = 'pending' | 'closed'

export const FULFILLMENT_STATUS_LABEL: Record<FulfillmentStatus, string> = {
  pending: 'Pending',
  closed: 'Planned',
}

export function getFulfillmentStatusLabel(status: FulfillmentStatus) {
  return FULFILLMENT_STATUS_LABEL[status]
}

export function canCreateShipment(fulfillment: Fulfillment | ListFulfillment) {
  return fulfillment.status === 'pending'
}

export type BaseFulfillment = {
  customer_uuid?: string
  delivery_date: string | null
  delivery_facility_uuid: string
  delivery_contact_uuid: string | null
  delivery_instructions: string
  handling_units: HandlingUnit[]
  notes?: string
  pickup_facility_uuid: string
  pickup_contact_uuid: string | null
  pickup_instructions: string
  pickup_number?: string
  pickup_ready_date: string
  supplier_uuid?: string
}

export type FulfillmentWithoutOrderPayload = Omit<
  BaseFulfillment,
  'handling_units'
> & {
  handling_units: Array<{
    commodities: Array<
      Omit<Commodity, 'package_count' | 'weight'> & {
        shipped_count: Commodity['package_count'] | null
        shipped_weight: Commodity['weight']
      }
    >
    delivery_stop_index?: number | null
    package_count?: HandlingUnit['package_count']
    package_height: HandlingUnit['height']
    package_length: HandlingUnit['length']
    package_type: HandlingUnit['package_type']
    package_width: HandlingUnit['width']
    stackable: boolean
    total_density: number
    total_volume: number
    total_weight: number
    turnable: boolean
  }>
}

export type FulfillmentPayload = Omit<BaseFulfillment, 'handling_units'> & {
  handling_units: Array<{
    commodities: Array<{
      order_item_uuid: string
      shipped_count: Commodity['package_count']
      shipped_weight: Commodity['weight']
    }>
    package_count?: HandlingUnit['package_count']
    package_type: HandlingUnit['package_type']
    package_height: HandlingUnit['height']
    package_length: HandlingUnit['length']
    package_width: HandlingUnit['width']
    stackable: boolean
    turnable: boolean
  }>
}

export type FulfillmentHandlingUnitOrderItem = Pick<
  OrderItem,
  | 'uuid'
  | 'commodity'
  | 'custom_package_type'
  | 'item_description'
  | 'item_number'
  | 'line_number'
  | 'max_temperature_uom'
  | 'max_temperature'
  | 'package_count'
  | 'package_type'
  | 'total_weight'
  | 'freight_class'
  | 'nmfc_code'
> & {
  order: Pick<
    Order,
    | 'external_id'
    | 'po_number'
    | 'primary_ref'
    | 'so_number'
    | 'status'
    | 'uuid'
  >
  shipped_package_count: number
  shipped_package_weight: string | null
}

export type FulfillmentHandlingUnit = {
  uuid: string
  package_count: NonNullable<Measurable['package_count']>
  package_height: NonNullable<Measurable['height']>
  package_length: NonNullable<Measurable['length']>
  package_type: PackageType | null | ''
  package_width: NonNullable<Measurable['width']>
  stackable: NonNullable<Measurable['stackable']>
  turnable: NonNullable<Measurable['turnable']>
  order_items: FulfillmentHandlingUnitOrderItem[]
}

export type FulfillmentHandlingUnitTypes =
  | FulfillmentHandlingUnit
  | ListFulfillmentHandlingUnit
  | OrderFulfillmentsListItemHandlingUnit

export type FulfillmentPickupAppointment = StopAppointment & {
  stop: {
    date: string
  }
}

export type FulfillmentBaseFacility = {
  uuid: string
  name: string
  address: string
  city: string
  state: string
  zipcode: string
  country: string
  latitude?: string | null
  longitude?: string | null
}

export type FulfillmentBaseContact = {
  uuid: string
  name: string
  email: string
  phone: string
  extension: string
}

export interface Fulfillment extends Omit<BaseFulfillment, 'handling_units'> {
  created_at: string
  created_by_name: string
  customer: {
    uuid: string
    name: string
  }
  handling_units: FulfillmentHandlingUnit[]
  order_uuids: string[]
  ref_number: string
  shipment_uuid?: string
  status: FulfillmentStatus
  supplier: {
    uuid: string
    name: string
  }
  uuid: string
  pickup_contact?: FulfillmentBaseContact
  pickup_facility: FulfillmentBaseFacility & {
    latitude: string | null
    longitude: string | null
    warehouse_uuid: string | null
  }
  appointment?: FulfillmentPickupAppointment
  delivery_contact?: FulfillmentBaseContact
  delivery_facility: FulfillmentBaseFacility & {
    latitude: string | null
    longitude: string | null
  }
}

export type FulfillmentForm = BaseFulfillment & {
  owner?: 'us' | 'customer'
  order_identifier_type: 'po_number' | 'so_number' | 'orders'
  order_UUIDs: string[] | null
  po_numbers: string
  so_numbers: string
  stops: Stop[]
  handling_units: HandlingUnit[]
  supplier_name?: string
  customer_name?: string
}

export type FulfillmentFormTextFields = Pick<
  FulfillmentForm,
  | 'pickup_number'
  | 'po_numbers'
  | 'so_numbers'
  | 'pickup_instructions'
  | 'delivery_instructions'
  | 'notes'
>

export type TransientFulfillment = Transient<
  Omit<FulfillmentForm, 'handling_units' | 'stops'> & {
    handling_units: Array<TransientHandlingUnit>
    stops: Array<TransientStop>
  }
>

export function createTransientFulfillment(
  fulfillment?: Fulfillment
): TransientFulfillment {
  if (!fulfillment) {
    return createTransient({
      owner: 'us',
      order_identifier_type: 'po_number',
      order_UUIDs: null,
      po_numbers: '',
      so_numbers: '',
      pickup_facility_uuid: '',
      pickup_contact_uuid: '',
      pickup_instructions: '',
      delivery_instructions: '',
      pickup_number: '',
      pickup_ready_date: '',
      delivery_facility_uuid: '',
      delivery_contact_uuid: '',
      delivery_date: '',
      handling_units: [],
      stops: [
        createTransientStop({ stop_type: 'pickup' }),
        createTransientStop({ stop_type: 'delivery' }),
      ],
    })
  }

  return {
    ...createTransient(fulfillment),
    handling_units: [] as const,
    order_identifier_type: 'orders',
    order_UUIDs: fulfillment.order_uuids,
    so_numbers: '',
    po_numbers: '',
    stops: hydrateTransientFulfillmentStops({
      pickup: fulfillment.pickup_facility,
      pickupDate: fulfillment.pickup_ready_date,
      delivery: fulfillment.delivery_facility,
      deliveryDate: fulfillment.delivery_date,
    }),
    customer_uuid: fulfillment.customer.uuid,
    customer_name: fulfillment.customer.name,
    supplier_uuid: fulfillment.supplier.uuid,
    supplier_name: fulfillment.supplier.name,
  }
}

type CreateTransientFulfillmentStopParams = {
  pickup: Fulfillment['pickup_facility']
  pickupDate: Fulfillment['pickup_ready_date']
  delivery: Fulfillment['delivery_facility']
  deliveryDate: Fulfillment['delivery_date']
}

function hydrateTransientFulfillmentStops({
  pickup,
  pickupDate,
  delivery,
  deliveryDate,
}: CreateTransientFulfillmentStopParams): Transient<Stop>[] {
  return [
    createTransientStop({
      ...pickup,
      stop_type: 'pickup',
      date: pickupDate,
    }),
    createTransientStop({
      ...delivery,
      stop_type: 'delivery',
      date: deliveryDate,
    }),
  ]
}

export type ListFulfillmentOrderItem = Omit<
  FulfillmentHandlingUnitOrderItem,
  | 'custom_package_type'
  | 'item_description'
  | 'item_number'
  | 'package_count'
  | 'total_weight'
>

export type ListFulfillmentHandlingUnit = Omit<
  FulfillmentHandlingUnit,
  'order_items'
> & {
  order_items: ListFulfillmentOrderItem[]
}

export type ListFulfillmentFacility = Pick<
  FulfillmentBaseFacility,
  'uuid' | 'name' | 'city' | 'state' | 'latitude' | 'longitude'
>
export type ListFulfillmentContact = Pick<
  FulfillmentBaseContact,
  'name' | 'uuid'
>

export type ListFulfillment = {
  uuid: string
  pickup_number: string
  ref_number: string
  shipment_uuid: string
  status: FulfillmentStatus
  pickup_ready_date: string
  delivery_date: string | null
  notes: string
  customer: {
    uuid: string
    name: string
  }
  supplier: {
    uuid: string
    name: string
  }
  order_uuids: string[]
  pickup_facility: ListFulfillmentFacility
  pickup_contact: ListFulfillmentContact | null
  pickup_instructions: string
  delivery_facility: ListFulfillmentFacility
  delivery_contact: ListFulfillmentContact | null
  delivery_instructions: string
  handling_units: ListFulfillmentHandlingUnit[]
}

/**
 * Calculate the total density (weight / volume) for the given measurables.
 */
export function calculateTotalDensity(
  totalWeight: number,
  totalVolume: number
): number {
  if (
    !Number.isNaN(Number.parseFloat(String(totalWeight))) &&
    !Number.isNaN(totalVolume) &&
    totalVolume > 0
  ) {
    return totalWeight / totalVolume
  }

  return 0
}
