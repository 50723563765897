import { IconQuestionCircle } from '@loadsmart/icons'
import { Layout, Tooltip } from '@loadsmart/loadsmart-ui'

import { theme } from 'styles/theme'
import { numberFormatter } from 'utils/numbers'

import { TotalizerItem } from '.'

const AwardTotalizers = ({
  lanesCount = 0,
  totalizers,
  totalSpentHeader,
  totalTargetRateFooter,
  totalTargetRateHeader,
  totalSpentFooter,
}: {
  readonly lanesCount: number | undefined
  readonly totalizers: RFPTotalizers | undefined
  readonly totalTargetRateHeader: string
  readonly totalTargetRateFooter: string | undefined
  readonly totalSpentHeader: string
  readonly totalSpentFooter: string | undefined
}) => {
  const {
    lane_count_allocated = 0,
    total_volume = 0,
    total_volume_allocated = 0,
    total_carriers_awarded = 0,
    total_carriers_responded = 0,
    total_carriers_invited = 0,
    total_lanes_with_proposals_count = 0,
    total_lanes_without_proposals_count = 0,
  } = totalizers ?? {}

  return (
    <Layout.Group space="s">
      <TotalizerItem
        title="Total lanes"
        value={`${lanesCount}`}
        footer={`${numberFormatter(lane_count_allocated, 1)}% allocated`}
      />
      <TotalizerItem
        title="Lanes with bid"
        value={`${numberFormatter(total_lanes_with_proposals_count, 1)}`}
        footer={`${total_lanes_without_proposals_count} waiting for bid`}
      />
      <TotalizerItem
        title="Total volume"
        value={`${total_volume}`}
        footer={`${numberFormatter(total_volume_allocated, 1)}% allocated`}
      />
      <TotalizerItem
        title="Carriers awarded"
        value={`${total_carriers_awarded}`}
        footer={`${total_carriers_responded}/${total_carriers_invited} responded`}
      />
      <TotalizerItem
        title={
          <Layout.Group space="xs">
            Target Rate
            <Tooltip message="Sum of Target Rate for all lanes">
              <IconQuestionCircle
                height={12}
                width={12}
                fill={theme.colors.neutral}
              />
            </Tooltip>
          </Layout.Group>
        }
        value={totalTargetRateHeader}
        footer={totalTargetRateFooter}
      />
      <TotalizerItem
        title={
          <Layout.Group space="xs">
            Total spend
            <Tooltip message="Primary awards only">
              <IconQuestionCircle
                height={12}
                width={12}
                fill={theme.colors.neutral}
                title={null}
              />
            </Tooltip>
          </Layout.Group>
        }
        value={totalSpentHeader}
        footer={totalSpentFooter}
      />
    </Layout.Group>
  )
}

export default AwardTotalizers
