import { Layout } from '@loadsmart/loadsmart-ui'
import ContentLoader from 'react-content-loader'

import { theme } from 'styles/theme'

import { StyledTotalizerItem } from './styles'

const TotalizerSkeleton = ({ length = 4 }: { readonly length?: number }) => {
  const skeletonRange = Array(length)
    .fill(0)
    .map((_, index) => index)
  return (
    <Layout.Group space="s">
      {skeletonRange.map((value) => (
        <StyledTotalizerItem key={`totalizer-item-skeleton-${value}`}>
          <ContentLoader
            speed={1.2}
            width={60}
            height={60}
            viewBox="0 0 60 60"
            backgroundColor={theme.colors.backgroundMediumGray}
            foregroundColor={theme.colors.white}
          >
            <rect x="0" y="0" rx="6" ry="6" width="46" height="10" />
            <rect x="0" y="20" rx="6" ry="6" width="23" height="12" />
            <rect x="0" y="42" rx="6" ry="6" width="58" height="10" />
          </ContentLoader>
        </StyledTotalizerItem>
      ))}
    </Layout.Group>
  )
}

export default TotalizerSkeleton
