import { Layout, Text } from '@loadsmart/miranda-react'
import type { ColorToken } from '@loadsmart/miranda-react/dist/tokens'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import { AdvancedMarker, useAdvancedMarkerRef } from '@vis.gl/react-google-maps'
import { useCallback } from 'react'
import styled from 'styled-components'

import { Polyline } from 'components/Map'

import type { LineConfig, PinConfig } from './PlanFulfillmentsMap.types'

export type PinVariant = 'success' | 'accent' | 'default'

const PIN_VARIANT_BACKGROUND_MAP: Record<PinVariant, ColorToken> = {
  accent: 'color-accent-100',
  default: 'color-neutral-60',
  success: 'color-success-100',
}

const PIN_VARIANT_TEXT_COLOR: Record<PinVariant, ColorToken> = {
  accent: 'color-text-inverted',
  default: 'color-text-inverted',
  success: 'color-text-inverted',
}

const PinMarkerWrapper = styled.div`
  align-items: center;
  border-radius: ${toCSSValue('spacing-2')};
  display: flex;
  padding: ${toCSSValue('spacing-2')};
`
const PinArrow = styled.div`
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid transparent;
`

const PinIndex = styled.div`
  align-items: center;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  display: flex;
  height: 25px;
  justify-content: center;
  padding: ${toCSSValue('spacing-0-5')};
  width: 25px;
`

export interface PinMarkerProps extends PinConfig {
  readonly onPinClick?: (pinConfig: PinConfig) => void
  readonly variant?: PinVariant
}

export function PinMarker({
  index,
  onPinClick,
  variant = 'default',
  ...pinConfig
}: PinMarkerProps) {
  const [markerRef] = useAdvancedMarkerRef()

  const handleMarkerClick = useCallback(() => {
    if (onPinClick) {
      onPinClick(pinConfig)
    }
  }, [onPinClick, pinConfig])

  return (
    <AdvancedMarker
      ref={markerRef}
      position={pinConfig.position}
      onClick={handleMarkerClick}
      style={{ transform: 'translate(0, 0)' }}
    >
      <Layout.Stack align="center" gap="none">
        <PinMarkerWrapper
          data-testid="stop-marker-pin"
          style={{
            background: toCSSValue(PIN_VARIANT_BACKGROUND_MAP[variant]),
          }}
        >
          <Layout.Group
            align="center"
            gap="spacing-2"
            style={{ minWidth: index != null ? '110px' : '' }}
          >
            {index != null && (
              <PinIndex>
                <Text color="color-text-inverted" variant="body-sm-bold">
                  {index}
                </Text>
              </PinIndex>
            )}
            <Layout.Stack
              align={index == undefined ? 'center' : 'flex-start'}
              gap="spacing-0-5"
            >
              {pinConfig.title && (
                <Text
                  color={PIN_VARIANT_TEXT_COLOR[variant]}
                  style={{
                    textAlign: 'center',
                  }}
                  variant="body-sm-bold"
                >
                  {pinConfig.title}
                </Text>
              )}

              {pinConfig.subtitle && (
                <Text
                  color={PIN_VARIANT_TEXT_COLOR[variant]}
                  style={{
                    textAlign: 'center',
                  }}
                  variant="body-sm"
                >
                  {pinConfig.subtitle}
                </Text>
              )}
            </Layout.Stack>
          </Layout.Group>
        </PinMarkerWrapper>
        <PinArrow
          style={{
            borderTopColor: toCSSValue(PIN_VARIANT_BACKGROUND_MAP[variant]),
          }}
        />
      </Layout.Stack>
    </AdvancedMarker>
  )
}

export const DASHED_LINE_ICON = {
  icon: {
    path: 'M 0,-1 0,1',
    strokeOpacity: 0.8,
    scale: 2,
  },
  offset: '0',
  repeat: '10px',
}

export function getArrowIcon(color: string = '') {
  return {
    icon: {
      path: 'M 0,0 1.5,3 -1.5,3 0,0 z',
      fillColor: color,
      fillOpacity: 1.0,
      strokeColor: color,
      strokeWeight: 1,
    },
    offset: '50%',
  }
}

export function Line({
  color,
  dashed,
  startPosition,
  endPosition,
}: Readonly<Omit<LineConfig, 'id'>>) {
  if (!startPosition || !endPosition) {
    return null
  }

  const path = [startPosition, endPosition]
  const ARROW_ICON = getArrowIcon(color)

  if (dashed) {
    return (
      <Polyline
        icons={[ARROW_ICON, DASHED_LINE_ICON]}
        path={path}
        strokeColor={color}
        strokeWeight={4}
        strokeOpacity={0}
      />
    )
  }

  return (
    <Polyline
      icons={[ARROW_ICON]}
      path={path}
      strokeColor={color}
      strokeWeight={4}
      strokeOpacity={1}
    />
  )
}
