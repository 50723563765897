import { Card, Banner, LoadingDots } from '@loadsmart/loadsmart-ui'
import styled from 'styled-components'

import { UnstyledButton } from 'components/Button'

export const ActionFooter = styled.div`
  padding: 0 30px;
`
export const Arrow = styled.div<{ open: boolean }>`
  background: ${({ theme }) => theme.colors.backgroundSecondary};
  border-radius: 4px 0px 0px 4px;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  height: 56px;
  margin-top: 98px;
  padding: 20px 5px;
  text-align: center;

  ${({ open }) =>
    !open &&
    `
    svg {
      transform: rotate(180deg);
    }
  `}
`
export const CloseSidebarWrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutralLighter};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
`
export const ContentContainer = styled.div`
  box-shadow: ${({ theme }) => theme.shadows.shadow01};
  display: flex;
  height: 100vh;
  & > * {
    height: 100vh;
  }
`
export const ShowHideAction = styled(UnstyledButton)`
  color: ${({ theme }) => theme.colors.textAccent};
  font-weight: 700;
  margin: 0;
  padding: 0;
  text-decoration: underline;
`
export const ActionLink = styled(ShowHideAction)`
  text-align: left;
  width: 70px;
`
export const ActionLinkSmall = styled(ActionLink)`
  font-size: 12px;
`
export const SideBarContainer = styled.div`
  display: flex;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;
`
export const StyledBanner = styled(Banner)`
  margin: 24px 30px 0;
`
export const StyledBody = styled(Card.Body)`
  margin: 0;
  padding: 0;
`
export const StyledCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.colors.neutralLighter};
  margin-bottom: 10px;
  overflow: unset;
  padding: 30px 0;
`
export const StyledTitle = styled(Card.Title)`
  margin: 0;
  padding: 0 30px;
`
export const StyledSubTitle = styled(Card.Subtitle)`
  font-size: ${({ theme }) => theme.fontSize};
  margin: 10px 0 30px 0;
  padding: 0 30px;
`
export const StyledSeparator = styled(Card.Separator)`
  margin: 1em 0 30px 0;
`
export const TitleLoaderWrapper = styled(LoadingDots)`
  margin-left: 10px;
`
export const Title = styled.h2`
  align-items: center;
  display: flex;
  margin: 0;
  padding: 0;
`
export const TitleContainer = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.whiteBroken};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutralLighter};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 24px 10px 24px;
  padding: 30px 0;
`
