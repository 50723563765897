import { IconExport } from '@loadsmart/icons'
import { Button, Icon, Layout, Text, Tooltip } from '@loadsmart/miranda-react'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import { useFulfillmentHistoryContext } from 'fulfillments/components/FulfillmentHistory'
import { FulfillmentStatusTag } from 'fulfillments/components/FulfillmentStatusTag'
import { canCreateShipment } from 'fulfillments/domain/Fulfillment'
import type { ListFulfillment } from 'fulfillments/domain/Fulfillment'
import {
  formatFulfillmentDeliveryDate,
  formatFulfillmentHandlingUnitShippedWeight,
  formatFulfillmentHandlingUnitTitle,
  formatFulfillmentPickupReadyDate,
  formatFulfillmentStop,
  formatFulfillmentTotalPieceCount,
  formatFulfillmentWeight,
} from 'fulfillments/fulfillment-utils'
import { defaultTo, isEmpty } from 'lodash'
import { useCallback } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import type { CellProps } from 'react-table'

import TableActions from '_shared_/components/TableActions'
import { AppRoutes } from 'router/AppRoutes'
import analytics, {
  AnalyticsEvent,
  AnalyticsEventTrigger,
} from 'utils/analytics'
import { defaultEmptyString } from 'utils/strings'

export function CustomerCell({ row }: CellProps<ListFulfillment>) {
  const customer = defaultEmptyString(row.original.customer?.name, '-')

  return <Text>{customer}</Text>
}

export function StatusCell({ row }: CellProps<ListFulfillment>) {
  const shipment = row.original['shipment_uuid']
  const fulfillment = row.original

  return (
    <Layout.Group align="center" gap="spacing-1">
      <FulfillmentStatusTag fulfillment={fulfillment} />

      {!!shipment && !isEmpty(shipment) && (
        <Button
          href={generatePath(AppRoutes.ShipmentDetails, {
            id: shipment,
          })}
          size="small"
          variant="icon"
        >
          <IconExport
            color={toCSSValue('color-text-primary')}
            title="Go to shipment"
            width="12px"
          />
        </Button>
      )}
    </Layout.Group>
  )
}

export function OrdersCell({ row }: CellProps<ListFulfillment>) {
  const orders = defaultTo(row.original.order_uuids, [])

  return <Text>{orders.length}</Text>
}

export function OriginCell({ row }: CellProps<ListFulfillment>) {
  return (
    <Layout.Stack gap="none">
      {formatFulfillmentStop(row.original.pickup_facility, false)}
      <Text variant="body-sm" color="color-text-tertiary">
        {formatFulfillmentPickupReadyDate(row.original)}
      </Text>
    </Layout.Stack>
  )
}

export function DestinationCell({ row }: CellProps<ListFulfillment>) {
  return (
    <Layout.Stack gap="none">
      {formatFulfillmentStop(row.original.delivery_facility, false)}
      <Text variant="body-sm" color="color-text-tertiary">
        {formatFulfillmentDeliveryDate(row.original)}
      </Text>
    </Layout.Stack>
  )
}

export function HandlingUnitsCell({ row }: CellProps<ListFulfillment>) {
  const handlingUnits = defaultTo(row.original.handling_units, [])

  if (handlingUnits.length === 0) {
    return '-'
  }

  if (handlingUnits.length === 1) {
    return (
      <Layout.Stack gap="none">
        {formatFulfillmentHandlingUnitTitle(handlingUnits[0])}
        <Text variant="body-sm" color="color-text-tertiary">
          {formatFulfillmentHandlingUnitShippedWeight(handlingUnits[0])}
        </Text>
      </Layout.Stack>
    )
  }

  return (
    <Layout.Stack gap="none">
      <Tooltip
        message={
          <Layout.Stack gap="none">
            {handlingUnits.map((item) => (
              <Text key={item.uuid} color="color-text-inverted">
                {formatFulfillmentHandlingUnitTitle(handlingUnits[0])}
              </Text>
            ))}
          </Layout.Stack>
        }
        placement="top"
        trigger="hover"
        data-testid="handling-unit-tooltip"
      >
        <Text color="color-text-link" variant="body-md-bold-underline">
          {formatFulfillmentTotalPieceCount(row.original)}
        </Text>
      </Tooltip>
      <Text variant="body-sm" color="color-text-tertiary">
        {formatFulfillmentWeight(row.original)}
      </Text>
    </Layout.Stack>
  )
}

export function SupplierCell({ row }: CellProps<ListFulfillment>) {
  const supplier = defaultEmptyString(row.original.supplier?.name, '-')

  return <Text>{supplier}</Text>
}

export function NotesCell({ row }: CellProps<ListFulfillment>) {
  const notes = defaultEmptyString(row.original.notes, '')

  if (isEmpty(notes)) {
    return null
  }

  return (
    <Tooltip
      data-testid="fulfillment-notes-tooltip"
      message={notes}
      trigger="hover"
      placement="top-start"
    >
      <Icon name="comment" />
    </Tooltip>
  )
}

export function OpenHistoryAction({
  fulfillment,
}: {
  readonly fulfillment: ListFulfillment
}) {
  const { openDrawer } = useFulfillmentHistoryContext()

  const onClick = useCallback(() => {
    openDrawer(fulfillment.uuid)
  }, [openDrawer, fulfillment.uuid])

  return <TableActions.Item onClick={onClick}>View history</TableActions.Item>
}

export function CreateShipmentAction({
  fulfillment,
}: {
  readonly fulfillment: ListFulfillment
}) {
  const history = useHistory()

  const onClick = useCallback(() => {
    const params = {
      fulfillments: fulfillment.uuid,
      stop_type_0: 'pickup',
      stop_facility_0: fulfillment.pickup_facility.uuid,
      stop_type_1: 'delivery',
      stop_facility_1: fulfillment.delivery_facility.uuid,
    }
    analytics.track(
      AnalyticsEvent.FulfillmentsListCreateShipment,
      AnalyticsEventTrigger.click,
      {
        action: 'create shipment',
        fulfillment: fulfillment.uuid,
      }
    )
    history.push(
      generatePath(
        `${AppRoutes.NewShipment}?${new URLSearchParams(params).toString()}`
      )
    )
  }, [history, fulfillment])

  return (
    <TableActions.Item
      disabled={!canCreateShipment(fulfillment)}
      onClick={onClick}
      caption="Create a shipment for this fulfillment"
    >
      Create shipment...
    </TableActions.Item>
  )
}

export function ActionsCell({ row }: CellProps<ListFulfillment>) {
  return (
    <TableActions
      title="Fulfillment actions"
      data-testid={`fulfillment-actions-${row.index}`}
    >
      <OpenHistoryAction fulfillment={row.original} />
      <CreateShipmentAction fulfillment={row.original} />
    </TableActions>
  )
}
