import styled from 'styled-components'

export const RFPData = styled.div`
  flex-grow: 5;
`
export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

export const BannerWrapper = styled.div`
  margin-bottom: 10px;
`
