import { IconSearch, IconClose } from '@loadsmart/icons'
import { TextField } from '@loadsmart/loadsmart-ui'
import type { ChangeEvent } from 'react'
import styled from 'styled-components'

const TransparentButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0;
  outline: none;
  padding: 0;
`

const ClearSearchFieldButton = ({
  onClick,
}: {
  readonly onClick: () => void
}) => {
  return (
    <TransparentButton onClick={onClick}>
      <IconClose width={16} height={16} />
    </TransparentButton>
  )
}

interface ILaneSearchFieldProps {
  readonly laneSearchQuery: string
  readonly setLaneSearchQuery: (query: string) => void
}

const LaneSearchField = ({
  laneSearchQuery,
  setLaneSearchQuery,
}: ILaneSearchFieldProps) => {
  return (
    <TextField
      leading={<IconSearch width={16} height={16} />}
      trailing={
        laneSearchQuery !== '' ? (
          <ClearSearchFieldButton onClick={() => setLaneSearchQuery('')} />
        ) : null
      }
      scale="default"
      placeholder="Search"
      value={laneSearchQuery}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        setLaneSearchQuery(event.target.value)
      }}
    />
  )
}

export default LaneSearchField
