import type { Dispatch, SetStateAction } from 'react'

import {
  getActionColumn,
  getBidOptionColumn,
  getRateOptionColumn,
  getVSColumn,
} from 'rfp/components/table/columns'
import { getRankedCommonColumns } from 'rfp/components/table/tableUtils'
import type { RequestForProposal } from 'rfp/rfp.types'
import { numberFormatter } from 'utils/numbers'

const optionsDict: Record<string, string> = {
  last_award: 'LAST AWARD',
  target_rate: 'TARGET RATE',
  market_rate: 'MARKET BENCHMARK',
  contract_rate: 'SHORT CONTR.',
  lowest_bid: 'LOWEST BID',
  average_bid: 'AVG. BID',
  highest_bid: 'HIGHEST BID',
}

const BidOptions = [
  {
    value: 'lowest_bid',
    label: 'Lowest Bid',
  },
  {
    value: 'average_bid',
    label: 'Avg. Bid',
  },
  {
    value: 'highest_bid',
    label: 'Highest Bid',
  },
]

export default function makeLanesAllocatedColumns({
  laneIdMaxLength,
  rateOption,
  rfp,
  availableRateOptions,
  displayMultiCurrency,
  bidOption,
  setRadioOptionOne,
  setRadioOptionTwo,
}: {
  laneIdMaxLength: number
  rateOption: string
  rfp: RequestForProposal
  availableRateOptions: {
    value: string
    label: string
  }[]
  displayMultiCurrency: boolean
  bidOption: string
  setRadioOptionOne: Dispatch<SetStateAction<string>>
  setRadioOptionTwo: Dispatch<SetStateAction<string>>
}) {
  return [
    ...getRankedCommonColumns(laneIdMaxLength, rfp),
    {
      Header: 'BIDS RANKED',
      id: 'bids-ranked',
      className: 'text-left',
      accessor: ({
        awarded_proposals,
      }: {
        awarded_proposals: LaneProposal[]
      }) => numberFormatter(awarded_proposals?.length),
    },
    getBidOptionColumn(
      optionsDict,
      bidOption,
      BidOptions,
      setRadioOptionOne,
      displayMultiCurrency,
      rfp.currency
    ),
    getVSColumn(bidOption, rateOption),
    getRateOptionColumn(
      optionsDict,
      rateOption,
      availableRateOptions,
      setRadioOptionTwo,
      displayMultiCurrency,
      rfp.currency
    ),
    getActionColumn(rfp.state),
  ]
}
