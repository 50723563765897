import { useMemo } from 'react'

import { useWindowSize } from 'hooks/useWindowSize'
import {
  getDestOrDeliveryColumn,
  getLaneIdColumn,
  getLaneStatusColumn,
  getMileageColumn,
  getModeAndEquipmentColumn,
  getOriginOrPickupColumn,
  getVolumeColumn,
} from 'rfp/components/table/columns'
import type { RequestForProposal } from 'rfp/rfp.types'

export const getRankedCommonColumns = (
  laneIdMaxLength: number,
  rfp: RequestForProposal,
  showLaneStatus = true
) => {
  return [
    ...(showLaneStatus ? [getLaneStatusColumn(rfp)] : []),
    getLaneIdColumn(laneIdMaxLength),
    getOriginOrPickupColumn(),
    getDestOrDeliveryColumn(),
    getMileageColumn(rfp.distance_type),
    getModeAndEquipmentColumn(),
    getVolumeColumn(),
  ]
}

export const useLaneIdMaxLength = () => {
  const { windowWidth } = useWindowSize()

  const laneIdMaxLength = useMemo(() => {
    if (windowWidth < 1300) {
      return 8
    }
    if (windowWidth < 1400) {
      return 10
    }
    if (windowWidth < 1550) {
      return 12
    }
    if (windowWidth < 1700) {
      return 14
    }
    if (windowWidth < 1800) {
      return 16
    }
    return 20
  }, [windowWidth])

  return laneIdMaxLength
}

export const getTablePageOffset = (
  page: number,
  defaultOffset: number,
  limit = 50
) => (page === 0 ? defaultOffset : limit * page)

export const getTableSortDirection = (direction: string) =>
  direction === 'asc' ? 'desc' : 'asc'
