/**
 * All callbacks in the hooks in this file use the pattern described here: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 * They should all be replaced with useEvent once it is shipped.
 *
 * The idea is to make the callback mutable so that the effect below always uses the latest value
 * without adding the callback as a dependency and without requiring the consumer to useCallback
 */

import { useEffect, useRef } from 'react'

import PusherManager from 'services/pusher/manager'
import type { OnErrorCallback, OnReadyCallback } from 'services/pusher/types'

type SingleEventCallback<D> = (data: D) => void
type MultiEventCallback<D> = (event: string, data: D) => void

type OnMessageCallback<
  DataType,
  EventType extends string | string[],
> = EventType extends string
  ? SingleEventCallback<DataType>
  : MultiEventCallback<DataType>

interface UsePusherOptions<DataType, EventType extends string | string[]> {
  /**
   * The channel you want to connect/subscribe to
   */
  channel: string
  /**
   * The event(s) you want to listen for. You can pass a string or an array fo strings if you want to listen for multiple events
   */
  events: EventType
  /**
   * This callback is executed when the channel is successfully subscribed.
   * At this point, Pusher is connected and the channel is subscribed
   * When this runs, you are ready to receive events from the backend.
   */
  onReady?: OnReadyCallback
  /**
   * This callback is executed when there's any type of error in Pusher and events won't be received
   * It could be a configuration, connection or subscription error.
   *
   * A few examples:
   * - Configuration Error - Pusher Key is not available (Check your env variables)
   * - Connection Error - Exceeded connection limits
   * - Subscription Error - Auth failed. Maybe the name of the channel is incorrect? Or maybe the auth endpoint has errors.
   *
   * There's no need to report this error to Sentry. That already happens internally. Just use this to provide some kind of fallback like Polling
   */
  onError?: OnErrorCallback
  /**
   * The callback that is executed each time a new event is received.
   * The data will be passed as an argument.
   */
  onMessage: OnMessageCallback<DataType, EventType>
  /**
   * If this is false, the hook will not connect/subscribe to channels/events
   */
  enabled?: boolean
}

/**
 * This is the main hook and the main entrypoint to Pusher.
 * Whenever we need to listen for Pusher events, we use this hook in react
 * You specify the channel, the event/events you wan to listen for and you'll receive them
 * in the onMessage prop.
 *
 * It's encouraged to use the onReady and onError prop to know when Pusher is ready and when Pusher is not available so you can provide fallbacks.
 * Pusher can reconnect when it transitions to an error state. If you provide a fallback via onError, make sure to set the enabled prop of this hook to false
 * to avoid conflicts between your own fallback and Pusher once it comes back online.
 *
 * It uses our PusherManager under the hood which means it will connect to Pusher on a SharedWorker if available or on the main thread if not.
 *
 */
export function usePusher<DataType, EventType extends string | string[]>({
  channel,
  events,
  onReady,
  onError,
  onMessage,
  enabled = true,
}: UsePusherOptions<DataType, EventType>) {
  if (Array.isArray(events)) {
    const uniqueEvents = new Set(events)

    if (uniqueEvents.size !== events.length) {
      throw new Error('usePusher: duplicate events are not allowed')
    }
  }

  // Always use the latest callback wihout having to pass it as a dependency of the useEffect
  const savedOnMessageCallback =
    useRef<OnMessageCallback<DataType, EventType>>(onMessage)
  const savedOnReadyCallback = useRef<OnReadyCallback | undefined>(onReady)
  const savedOnErrorCallback = useRef<OnErrorCallback | undefined>(onError)

  useEffect(() => {
    savedOnMessageCallback.current = onMessage
  }, [onMessage])
  useEffect(() => {
    savedOnReadyCallback.current = onReady
  }, [onReady])
  useEffect(() => {
    savedOnErrorCallback.current = onError
  }, [onError])

  // Since the events prop can be an array,
  // we need to serialize it before we pass it as a dependency to the hook below.
  // This is to ensure the effect below doesn't rerun unnecessarily.
  // Normally this would not be recommended
  // but since the events array is simple, this should not cause any performance problems.
  const eventsJson = JSON.stringify(events)

  useEffect(() => {
    if (!enabled) {
      return undefined
    }

    const parsedEvents = JSON.parse(eventsJson) as string | string[]

    const pusher = new PusherManager()

    pusher.onReady = () => {
      savedOnReadyCallback.current?.({})
    }

    pusher.onError = (error) => {
      savedOnErrorCallback.current?.(error)
    }

    if (Array.isArray(parsedEvents)) {
      pusher.onMessage = (event, data) => {
        ;(savedOnMessageCallback.current as MultiEventCallback<DataType>)(
          event,
          data as DataType
        )
      }
    } else {
      pusher.onMessage = (_event, data) => {
        ;(savedOnMessageCallback.current as SingleEventCallback<DataType>)(
          data as DataType
        )
      }
    }

    pusher.subscribe(channel, parsedEvents)

    return () => {
      pusher.unsubscribe(channel, parsedEvents)
    }
  }, [channel, enabled, eventsJson])
}
