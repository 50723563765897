import { IconCheck } from '@loadsmart/icons'
import { Dialog, Layout, Text } from '@loadsmart/loadsmart-ui'

interface Props {
  readonly isOpen: boolean
  readonly onConfirm: () => void
  readonly onCancel: () => void
  readonly onOverlayClick: () => void
}

const SendAwardConfirmationDialog = ({
  isOpen,
  onConfirm,
  onCancel,
  onOverlayClick,
}: Props) => {
  return (
    <Dialog open={isOpen} scale="small" onOverlayClick={onOverlayClick}>
      <Dialog.Header>Send award</Dialog.Header>
      <Dialog.Body>
        <Layout.Stack space="m" align="center">
          <IconCheck width={48} heigh={48} />
          <Text variant="body" color="color-neutral-darker">
            The email for new awarded carriers{' '}
            <b>will be sent at the end of the current day</b> with the summary
            of all awarded lanes.
            <br />
            <br />
            This action will help to avoid spamming their email box.
          </Text>
        </Layout.Stack>
      </Dialog.Body>
      <Dialog.Actions>
        <Dialog.ActionConfirm onConfirm={onConfirm}>Ok</Dialog.ActionConfirm>
        <Dialog.ActionCancel onCancel={onCancel}>
          Don&apos;t Show Again
        </Dialog.ActionCancel>
      </Dialog.Actions>
    </Dialog>
  )
}

export default SendAwardConfirmationDialog
