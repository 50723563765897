import type { ReactNode } from 'react'

import AwardScenarios from 'rfp/rfp-details/award-scenarios'
import Rank from 'rfp/rfp-details/rank'
import type { RequestForProposal } from 'rfp/rfp.types'
import { BID_TYPE } from 'utils/constants'

import type { UploadLanesSuccessResponse } from './types'

type ScenariosProps = {
  readonly rfp: RequestForProposal
  readonly remainingRfpQuota: number
  readonly carrierInvitesMaxQuota: number
  readonly addLanesButton: ReactNode
  readonly importLanesButton: ReactNode
  readonly showLaneProposals: (lane: Lane) => void
  readonly importLanesButtonProps: {
    onClick: () => void
    disabled: boolean
    isUploading: boolean
  }
  readonly addLanesButtonProps: {
    onClick: () => void
    disabled: boolean
  }
  readonly laneCsvTemplateButtonProps: {
    onClick: () => void
    disabled: boolean
    isDownloading: boolean
  }
  readonly invites: NewCarrierInvite[]
  readonly refetchCarrierInvites: () => Promise<unknown>
  readonly isLoadingCarrierInvites: boolean
  readonly carrierInviteStatus: string
  readonly onSuccessUploadLane: (lanes: UploadLanesSuccessResponse) => void
  readonly onErrorUploadLane: (start?: number, errorType?: string) => void
}

export default function Scenarios({
  rfp,
  remainingRfpQuota,
  carrierInvitesMaxQuota,
  addLanesButton,
  importLanesButton,
  showLaneProposals,
  importLanesButtonProps,
  addLanesButtonProps,
  laneCsvTemplateButtonProps,
  invites,
  carrierInviteStatus,
  refetchCarrierInvites,
  isLoadingCarrierInvites,
  onErrorUploadLane,
  onSuccessUploadLane,
}: ScenariosProps) {
  const isRankedAward = rfp.bid_type === BID_TYPE.RANK

  const scenariosProps = {
    rfp,
    remainingRfpQuota,
    carrierInvitesMaxQuota,
    addLanesButton,
    importLanesButton,
    showLaneProposals,
    importLanesButtonProps,
    addLanesButtonProps,
    laneCsvTemplateButtonProps,
    invites,
    carrierInviteStatus,
    refetchCarrierInvites,
    isLoadingCarrierInvites,
    onSuccessUploadLane,
    onErrorUploadLane,
  }

  return isRankedAward ? (
    <Rank {...scenariosProps} />
  ) : (
    <AwardScenarios {...scenariosProps} />
  )
}
