import { Tooltip, TooltipPosition } from '@loadsmart/loadsmart-ui'
import type { ReactNode } from 'react'

import type { Currency } from 'rfp/rfp.types'
import { CURRENCY } from 'utils/constants'
import { getCurrencyPrefix, isMultiCurrency } from 'utils/currency'
import formatCurrency from 'utils/formatCurrency'

const StyleWrapper = ({
  children,
  boldStyle = true,
}: {
  readonly children: any
  readonly boldStyle: boolean
}) => {
  return !boldStyle ? (
    children
  ) : (
    <b style={{ whiteSpace: 'nowrap' }}>{children}</b>
  )
}

const DefaultEmptyValue = ({
  currency,
  boldStyle = true,
}: {
  readonly currency?: Currency
  readonly boldStyle: boolean
}) => {
  return (
    <StyleWrapper boldStyle={boldStyle}>
      {getCurrencyPrefix(currency) || '$ '}-
    </StyleWrapper>
  )
}

const TooltipWrapper = ({
  children,
  message,
  hideTooltip = false,
}: {
  readonly children: ReactNode
  readonly message?: string
  readonly hideTooltip: boolean
}) => {
  return hideTooltip ? (
    children
  ) : (
    <Tooltip message={message} position={TooltipPosition.Top}>
      {children}
    </Tooltip>
  )
}

const MultiCurrencyValue = ({
  value,
  nonConvertedValue,
  rfpCurrency,
  emptyValue,
  tooltipMessage,
  hideTooltip = false,
  disableMultiCurrency = false,
  boldStyle = true,
}: {
  readonly value?: any
  readonly nonConvertedValue?: any
  readonly rfpCurrency?: Currency
  readonly emptyValue?: any
  readonly tooltipMessage?: string
  readonly hideTooltip?: boolean
  readonly disableMultiCurrency?: boolean
  readonly boldStyle?: boolean
}) => {
  const currency = disableMultiCurrency ? CURRENCY.USD : rfpCurrency
  if (!value) {
    return emptyValue ? (
      emptyValue
    ) : (
      <DefaultEmptyValue currency={currency} boldStyle={boldStyle} />
    )
  }

  if (isMultiCurrency(currency)) {
    const prefix = getCurrencyPrefix(currency)
    const formattedCurrency = formatCurrency(value, true, false)

    return (
      <TooltipWrapper
        message={tooltipMessage || formatCurrency(nonConvertedValue)}
        hideTooltip={hideTooltip}
      >
        <StyleWrapper boldStyle={boldStyle}>
          {`${prefix}${formattedCurrency}`}
        </StyleWrapper>
      </TooltipWrapper>
    )
  }

  return (
    <StyleWrapper boldStyle={boldStyle}>{formatCurrency(value)}</StyleWrapper>
  )
}

export default MultiCurrencyValue
