import { Button, Header, Icon } from '@loadsmart/miranda-react'

export function PlanFulfillmentsPageHeader() {
  return (
    <Header>
      <Header.Content>
        <Header.Title>Plan shipment</Header.Title>
      </Header.Content>

      <Header.Actions>
        <Button variant="secondary">Reset plan</Button>
        <Button variant="primary">
          Review shipment
          <Icon name="arrow-right" color="color-text-inverted" />
        </Button>
      </Header.Actions>
    </Header>
  )
}
