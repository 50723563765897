import {
  IconClose,
  IconDownload,
  IconUpload,
  IconPaper,
  IconExport,
} from '@loadsmart/icons'
import {
  DragDropFileProvider,
  DragDropFile,
  Dialog,
  Layout,
  Text,
} from '@loadsmart/loadsmart-ui'
import { Button } from '@loadsmart/miranda-react'
import { useReducer } from 'react'

import {
  ErrorIcon,
  SuccessIcon,
  StyledSteps,
  StepContainer,
  WarningIcon,
} from 'components/ImportModal/styles'

import { RFP_LANES_IMPORT_HELP_URL } from './constants'
import type { State } from './state'
import { importReducer, initialState } from './state'
import { FileName, FileZone } from './styles'

function shouldPreventClosing(state: State, loading: boolean) {
  return state.step === 'FILE_PREVIEW' && loading
}

export function useImportState() {
  return useReducer(importReducer, initialState)
}

type Descriptors = {
  title: string
  steps: string[]
  resource: string
  hint: string
  helpLink?: string
  errorReportMessage: string
  successMessage: string
}

type ImportModalProps = {
  readonly descriptors: Descriptors
  readonly state: State
  readonly isOpen: boolean
  readonly onClose: () => void
  readonly onFileSelect: (file: File) => void
  readonly onDownloadTemplate: () => void
  readonly onUploadFile: (file: File) => void
  readonly onUploadNewFile: () => void
  readonly isUploading: boolean
}

export default function ImportModal({
  descriptors,
  state,
  isOpen,
  onClose,
  onFileSelect,
  onDownloadTemplate,
  onUploadFile,
  onUploadNewFile,
  isUploading,
}: ImportModalProps) {
  const { title, steps } = descriptors

  return (
    <Dialog
      open={isOpen}
      scale="large"
      data-testid="import-modal"
      onOverlayClick={() => {
        // Don't allow the modal to be closed if there's an import in progress
        if (shouldPreventClosing(state, isUploading)) {
          return
        }

        onClose()
      }}
    >
      <Dialog.Close
        onClick={() => onClose()}
        disabled={shouldPreventClosing(state, isUploading)}
      />
      <Dialog.Header>{title}</Dialog.Header>
      <Dialog.Body style={{ textAlign: 'left', margin: 0 }}>
        <StyledSteps
          current={`step-${steps.length - 1}`}
          id="import-steps"
          className="bg-neutral-white"
          steps={steps.map((step, index) => ({
            complete: true,
            id: `step-${index}`,
            label: step,
          }))}
          data-testid="import-steps"
        />
        {state.step === 'FILE_SELECTION' && (
          <FileSelection
            descriptors={descriptors}
            onFileSelect={onFileSelect}
            onDownloadTemplate={onDownloadTemplate}
          />
        )}
        {state.step === 'FILE_PREVIEW' && (
          <FilePreview
            file={state.file}
            loading={isUploading}
            onUpload={onUploadFile}
            onRemove={onUploadNewFile}
          />
        )}
        {state.step === 'FILE_ERROR' && (
          <FileError
            file={state.file}
            onUploadNewFile={onUploadNewFile}
            onDownloadTemplate={onDownloadTemplate}
            onCancel={onClose}
          />
        )}
        {state.step === 'LANES_LIMIT_REACHED' && (
          <MaxLanesReachedImportError file={state.file} onClose={onClose} />
        )}
        {state.step === 'UPLOAD_ERROR' && (
          <ImportError
            file={state.file}
            onUploadNewFile={onUploadNewFile}
            onClose={onClose}
          />
        )}
        {state.step === 'UPLOAD_TIMEOUT' && (
          <ImportTimeout file={state.file} onClose={onClose} />
        )}
        {state.step === 'UPLOAD_SUCCEEDED' && (
          <ImportResult
            file={state.file}
            onUploadNewFile={onUploadNewFile}
            onClose={onClose}
          />
        )}
      </Dialog.Body>
    </Dialog>
  )
}

type FileSelectionProps = {
  readonly descriptors: Descriptors
  readonly onFileSelect: (file: File) => void
  readonly onDownloadTemplate: () => void
}

function FileSelection({
  descriptors,
  onFileSelect,
  onDownloadTemplate,
}: FileSelectionProps) {
  const { hint } = descriptors

  return (
    <StepContainer>
      <DragDropFileProvider
        fileList={[]}
        onFilesAdded={(files) => {
          onFileSelect(files[0])
        }}
        onFileRemoved={() => undefined}
      >
        <DragDropFile.DropZone
          multiple={false}
          accept="text/csv"
          hint={hint}
          aria-label="File"
        />
      </DragDropFileProvider>
      <Layout.Group justify="space-between" align="center">
        <Button
          variant="tertiary"
          href={RFP_LANES_IMPORT_HELP_URL}
          target="_blank"
          style={{ width: 'fit-content' }}
        >
          Need help? Learn how to fill the template{' '}
          <IconExport width={16} height={16} />
        </Button>
        <Button
          variant="secondary"
          leading={<IconDownload width={16} height={16} />}
          onClick={() => onDownloadTemplate()}
        >
          Download template
        </Button>
      </Layout.Group>
    </StepContainer>
  )
}

type FilePreviewProps = {
  readonly file: File
  readonly loading: boolean
  readonly onUpload: (file: File) => void
  readonly onRemove: () => void
}

function FilePreview({ file, loading, onUpload, onRemove }: FilePreviewProps) {
  return (
    <StepContainer>
      <FileZone borderWidth="medium" borderColor="neutral-lighter">
        <Layout.Group align="center" justify="space-between">
          <Layout.Group space="s">
            <IconPaper width={20} height={20} />
            <FileName variant="heading-sm" color="color-neutral-dark">
              {file.name}
            </FileName>
          </Layout.Group>
          <Button
            leading={<IconClose width={12} height={12} />}
            variant="tertiary"
            size="small"
            onClick={() => onRemove()}
            disabled={loading}
          >
            Remove
          </Button>
        </Layout.Group>
      </FileZone>
      <Layout.Group justify="flex-end" align="center">
        <Button
          variant="primary"
          onClick={() => {
            onUpload(file)
          }}
          disabled={loading}
          loading={loading}
        >
          Upload file
        </Button>
      </Layout.Group>
    </StepContainer>
  )
}

type FileErrorProps = {
  readonly file: File
  readonly onUploadNewFile: () => void
  readonly onDownloadTemplate: () => void
  readonly onCancel: () => void
}

function FileError({
  file,
  onUploadNewFile,
  onDownloadTemplate,
  onCancel,
}: FileErrorProps) {
  return (
    <StepContainer>
      <Layout.Stack space="s">
        <FileZone borderWidth="medium" borderColor="neutral-lighter">
          <Layout.Stack space="m">
            <Layout.Group align="center" space="s">
              <ErrorIcon width={20} height={20} />{' '}
              <Text variant="heading-sm" color="color-neutral-dark">
                {file.name}
              </Text>
            </Layout.Group>
            <Text variant="heading-sm-bold" color="color-neutral-darker">
              Unsupported files
            </Text>
            <Text variant="caption" color="color-danger">
              This file format is not supported. Please add your lanes into the
              .csv template.
            </Text>
          </Layout.Stack>
        </FileZone>
      </Layout.Stack>
      <Layout.Group justify="flex-end" align="flex-end">
        <Button variant="tertiary" onClick={() => onCancel()}>
          Cancel
        </Button>
        <Button
          variant="secondary"
          leading={<IconDownload width={16} height={16} />}
          onClick={() => onDownloadTemplate()}
        >
          Download template
        </Button>
        <Button
          variant="primary"
          leading={<IconUpload width={16} height={16} />}
          onClick={() => onUploadNewFile()}
        >
          Upload new file
        </Button>
      </Layout.Group>
    </StepContainer>
  )
}

type ImportErrorProps = {
  readonly file: File
  readonly onUploadNewFile: () => void
  readonly onClose: () => void
}

function MaxLanesReachedImportError({
  file,
  onClose,
}: {
  readonly file: File
  readonly onClose: () => void
}) {
  return (
    <StepContainer>
      <Layout.Stack space="s">
        <FileZone borderWidth="medium" borderColor="neutral-lighter">
          <Layout.Stack space="m">
            <Layout.Group align="center" space="s">
              <ErrorIcon width={20} height={20} />{' '}
              <Text variant="heading-sm" color="color-neutral-dark">
                {file.name}
              </Text>
            </Layout.Group>
            <Text variant="heading-sm-bold" color="color-neutral-darker">
              The maximum number of lanes for this RFP was reached.
            </Text>
            <Text variant="body" color="color-danger">
              Please, keep the number of lanes to a maximum of 2000.
            </Text>
          </Layout.Stack>
        </FileZone>
      </Layout.Stack>
      <Layout.Group justify="flex-end" align="center">
        <Button variant="primary" onClick={() => onClose()}>
          Ok
        </Button>
      </Layout.Group>
    </StepContainer>
  )
}

function ImportError({ file, onUploadNewFile, onClose }: ImportErrorProps) {
  return (
    <StepContainer>
      <Layout.Stack space="s">
        <FileZone borderWidth="medium" borderColor="neutral-lighter">
          <Layout.Stack space="m">
            <Layout.Group align="center" space="s">
              <ErrorIcon width={20} height={20} />{' '}
              <Text variant="heading-sm" color="color-neutral-dark">
                {file.name}
              </Text>
            </Layout.Group>
            <Text variant="heading-sm-bold" color="color-neutral-darker">
              An error occurred
            </Text>
            <Text variant="body" color="color-danger">
              Please, try again upload the file.
            </Text>
          </Layout.Stack>
        </FileZone>
      </Layout.Stack>
      <Layout.Group justify="flex-end" align="center">
        <Button
          variant="secondary"
          leading={<IconUpload width={16} height={16} />}
          onClick={() => onUploadNewFile()}
        >
          Upload new file
        </Button>
        <Button variant="primary" onClick={() => onClose()}>
          Ok
        </Button>
      </Layout.Group>
    </StepContainer>
  )
}

type ImportTimeoutProps = {
  readonly file: File
  readonly onClose: () => void
}

function ImportTimeout({ file, onClose }: ImportTimeoutProps) {
  return (
    <StepContainer>
      <Layout.Stack space="s">
        <FileZone borderWidth="medium" borderColor="neutral-lighter">
          <Layout.Stack space="m">
            <Layout.Group align="center" space="s">
              <WarningIcon width={20} height={20} />{' '}
              <Text variant="heading-sm" color="color-neutral-dark">
                {file.name}
              </Text>
            </Layout.Group>
            <Text variant="heading-sm-bold" color="color-neutral-darker">
              Your file is being processed in the background due to its size.
              Please refresh in a few minutes.
            </Text>
          </Layout.Stack>
        </FileZone>
      </Layout.Stack>
      <Layout.Group justify="flex-end" align="center">
        <Button variant="primary" onClick={() => onClose()}>
          Ok
        </Button>
      </Layout.Group>
    </StepContainer>
  )
}

type ImportResultProps = {
  readonly file: File
  readonly onUploadNewFile: () => void
  readonly onClose: () => void
}

function ImportResult({ file, onUploadNewFile, onClose }: ImportResultProps) {
  return (
    <StepContainer>
      <Layout.Stack space="s">
        <FileZone borderWidth="medium" borderColor="neutral-lighter">
          <Layout.Group align="center" space="s">
            <SuccessIcon width={20} height={20} />
            <Text variant="heading-sm" color="color-neutral-dark">
              {file.name}
            </Text>
          </Layout.Group>
          <Layout.Stack space="s">
            <Text variant="body-bold" color="color-neutral-darker">
              Your files have been uploaded successfully
            </Text>
          </Layout.Stack>
        </FileZone>
      </Layout.Stack>
      <Layout.Group justify="flex-end" align="center">
        <Button
          variant="secondary"
          leading={<IconUpload width={16} height={16} />}
          onClick={() => onUploadNewFile()}
        >
          Upload new file
        </Button>
        <Layout.Group justify="flex-end" space="s">
          <Button variant="primary" onClick={() => onClose()}>
            OK
          </Button>
        </Layout.Group>
      </Layout.Group>
    </StepContainer>
  )
}
