import type { AxiosRequestConfig } from 'axios'

import httpClient from 'utils/httpClient'

export const getLiveMarketRate = async (
  laneId: number,
  config?: AxiosRequestConfig
) => {
  const { data } = await httpClient.get(
    `/benchmarks/live_market_rate/${laneId}`,
    config
  )
  return data
}
