import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import { ToastContainer } from 'react-toastify'
import styled from 'styled-components'

import { ToastIcon } from './ToastIcon'

export const Toast = styled(ToastContainer).attrs({
  icon: ToastIcon,
  theme: 'colored',
})`
  .Toastify__close-button {
    color: ${toCSSValue('color-neutral-100')};
  }

  --toastify-color-success: ${toCSSValue('color-success-20')};
  --toastify-text-color-success: ${toCSSValue('color-success-60')};
  --toastify-color-error: ${toCSSValue('color-danger-20')};
  --toastify-text-color-error: ${toCSSValue('color-text-error')};
  --toastify-color-info: ${toCSSValue('color-accent-20')};
  --toastify-text-color-info: ${toCSSValue('color-text-highlight')};
  --toastify-color-warning: ${toCSSValue('color-warning-20')};
  --toastify-text-color-warning: ${toCSSValue('color-warning-100')};

  .Toastify__toast-body {
    align-items: center;
    display: flex;
    font-size: 14px;
    font-weight: bold;
    justify-content: center;
    line-height: 1.5;
    text-align: center;
    width: 100%;
  }
`
