import type { Dispatch, SetStateAction } from 'react'

import { PriceChangeIndicator } from 'components/LaneManagement/LanesTableV3/styles'
import {
  getActionColumn,
  getAllocationPercentageColumn,
  getAwardScenariosModeAndEquipmentColumn,
  getDestOrDeliveryColumn,
  getLaneIdColumn,
  getOriginOrPickupColumn,
  getRateOptionColumn,
} from 'rfp/components/table/columns'
import MultiCurrencyValue from 'rfp/rfp-details/components/MultiCurrencyValue'
import type { Currency } from 'rfp/rfp.types'

import { PrimaryCell } from '../lanes-allocated-table/Cells'

const optionsDict: Record<string, string> = {
  last_award: 'LAST AWARD',
  target_rate: 'TARGET RATE',
  market_rate: 'MARKET BENCHMARK',
  contract_rate: 'SHORT CONTR.',
  lowest_bid: 'LOWEST BID',
  average_bid: 'AVG. BID',
  highest_bid: 'HIGHEST BID',
}

export default function makeColumns({
  laneIdMaxLength,
  rateOption,
  rfpState,
  rfpCurrency,
  availableRateOptions,
  displayMultiCurrency,
  setRadioOptionTwo,
}: {
  laneIdMaxLength: number
  rateOption: string
  rfpState: string
  rfpCurrency: Currency
  availableRateOptions: {
    value: string
    label: string
  }[]
  displayMultiCurrency: boolean
  setRadioOptionTwo: Dispatch<SetStateAction<string>>
}) {
  const columns = [
    getLaneIdColumn(laneIdMaxLength),
    getOriginOrPickupColumn(),
    getDestOrDeliveryColumn(),
    getAwardScenariosModeAndEquipmentColumn(),
    getAllocationPercentageColumn(),
    {
      Header: 'PRIMARY',
      id: 'primary',
      className: 'text-left absolute-content',
      accessor: 'primary_carriers',
      Cell: function CellPrimary({ value }: { readonly value: any }) {
        return <PrimaryCell value={value} rfpCurrency={rfpCurrency} />
      },
    },
    {
      Header: 'AWARD RATE',
      id: 'award_rate',
      className: 'text-left',
      accessor: 'award_rate',
      Cell: function CellAwardRate({ row }: { readonly row: any }) {
        const valueField = displayMultiCurrency
          ? 'converted_award_rate'
          : 'award_rate'

        return (
          <MultiCurrencyValue
            value={row.original[valueField]}
            nonConvertedValue={row.original['award_rate']}
            rfpCurrency={rfpCurrency}
            emptyValue="No Award"
          />
        )
      },
    },
    {
      Header: 'VS →',
      id: 'vs',
      className: 'text-left text-nowrap',
      accessor: '',
      Cell: function CellVersus({ row }: { readonly row: any }) {
        const awardRate = row.original.award_rate
        const versusRate = row.original[rateOption]
        const diff = awardRate - versusRate

        if (diff === 0) {
          return '0%'
        }

        const percentage =
          100 -
          (Math.min(awardRate, versusRate) * 100) /
            Math.max(awardRate, versusRate)

        return (
          <>
            <PriceChangeIndicator
              direction={diff < 0 ? 'positive' : 'negative'}
            />
            {percentage.toFixed(1)}%
          </>
        )
      },
    },
    getRateOptionColumn(
      optionsDict,
      rateOption,
      availableRateOptions,
      setRadioOptionTwo,
      displayMultiCurrency,
      rfpCurrency
    ),
    getActionColumn(rfpState),
  ]

  return columns
}
