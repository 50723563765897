import {
  Dialog,
  Layout,
  Text,
  Table,
  TooltipPosition,
} from '@loadsmart/loadsmart-ui'
import { getToken } from '@loadsmart/loadsmart-ui/dist/theming'
import styled from 'styled-components'

import StringLimiter from '_shared_/components/StringLimiter'
import { useSettings } from 'contexts/settings'
import type { Currency } from 'rfp/rfp.types'
import formatCurrency from 'utils/formatCurrency'

const StyledGroup = styled(Layout.Group)`
  margin: ${getToken('space-m')} 0;
`

const StyledTable = styled(Table)`
  white-space: normal;

  thead {
    display: block;
    max-width: 420px;
  }

  tr {
    display: flex;
    max-width: 420px;
    height: auto !important;
    padding: ${getToken('space-xs')};
  }

  td,
  th {
    align-items: center;
    display: flex;
    height: 100%;
  }
`
const DialogBody = styled(Dialog.Body)`
  max-height: ${getToken('modal-max-width')};
  overflow-y: auto;
`
interface Props {
  readonly carrierName: string
  readonly carrierAccessorials: AccessorialBid[]
  readonly volume?: string | number
  readonly isOpen: boolean
  readonly onClose: () => void
  readonly rfpCurrency: Currency
}

const AccessorialsDialog = ({
  carrierName,
  carrierAccessorials,
  volume,
  isOpen,
  onClose,
  rfpCurrency,
}: Props) => {
  const {
    values: [priceItemTypesEnabled],
  } = useSettings(['flags.ENABLE_PRICE_ITEM_TYPES'])

  const getAccessorialLabel = (
    extra_charge_type: {
      id: number
      label: string
    },
    price_item_type?: PriceItemType
  ) => {
    if (priceItemTypesEnabled && price_item_type) {
      return price_item_type.label
    }
    return extra_charge_type.label
  }

  return (
    <Dialog open={isOpen} scale="default" onOverlayClick={onClose}>
      <Dialog.Header>Accessorials</Dialog.Header>
      <DialogBody>
        <StyledGroup justify="space-between">
          <Layout.Stack align="flex-start" space="2xs">
            <Text variant="caption-bold" color="color-neutral">
              Carrier
            </Text>
            <Text variant="body-bold">{carrierName}</Text>
          </Layout.Stack>
          {volume && (
            <Layout.Stack
              align="flex-start"
              style={{ width: '100px' }}
              space="2xs"
            >
              <Text variant="caption-bold" color="color-neutral">
                Volume
              </Text>
              <Text variant="body-bold">{volume}</Text>
            </Layout.Stack>
          )}
        </StyledGroup>
        <StyledTable>
          <Table.Head>
            <Table.Row>
              <Table.HeadCell style={{ width: '30%' }}>
                <Text variant="caption-bold" color="color-neutral-dark">
                  Accessorial
                </Text>
              </Table.HeadCell>
              <Table.HeadCell style={{ width: '25%' }}>
                <Text variant="caption-bold" color="color-neutral-dark">
                  Rate
                </Text>
              </Table.HeadCell>
              <Table.HeadCell style={{ width: '45%' }}>
                <Text variant="caption-bold" color="color-neutral-dark">
                  Notes
                </Text>
              </Table.HeadCell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {carrierAccessorials.length > 0 ? (
              carrierAccessorials.map(
                ({ id, type, rate, notes, price_item_type }) => {
                  return (
                    <Table.Row key={id}>
                      <Table.Cell style={{ width: '30%' }}>
                        <StringLimiter
                          limiter={10}
                          value={
                            getAccessorialLabel(type, price_item_type) || '-'
                          }
                          position={TooltipPosition.Right}
                        />
                      </Table.Cell>
                      <Table.Cell style={{ width: '25%' }}>
                        <StringLimiter
                          limiter={10}
                          value={
                            rate
                              ? formatCurrency(rate, true, true, rfpCurrency)
                              : '-'
                          }
                          position={TooltipPosition.Right}
                        />
                      </Table.Cell>
                      <Table.Cell
                        style={{
                          width: '45%',
                          overflowWrap: 'break-word',
                          display: 'block',
                        }}
                      >
                        {notes || '-'}
                      </Table.Cell>
                    </Table.Row>
                  )
                }
              )
            ) : (
              <Table.Row>
                <Table.Cell style={{ width: '30%' }}>-</Table.Cell>
                <Table.Cell style={{ width: '25%' }}>-</Table.Cell>
                <Table.Cell style={{ width: '45%' }}>-</Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </StyledTable>
      </DialogBody>
      <Dialog.Actions direction="horizontal" scale="small">
        <Dialog.ActionCancel onCancel={onClose}>close</Dialog.ActionCancel>
      </Dialog.Actions>
    </Dialog>
  )
}

export default AccessorialsDialog
