import { DateFormatHelper, DateHelper } from '@loadsmart/loadsmart-ui'

import type { SettlementShipmentReportStatus } from './types'

export function getExportStatusCellVariant(
  status: SettlementShipmentReportStatus
) {
  switch (status) {
    case 'ready':
      return 'success'
    case 'generation_failed':
      return 'danger'
    default:
      return 'neutral'
  }
}

export function getExportStatusCellLabel(
  status: SettlementShipmentReportStatus
) {
  switch (status) {
    case 'ready':
      return 'Ready'
    case 'generation_failed':
      return 'Generation failed'
    default:
      return 'Generating...'
  }
}

export function getExportUserCellCreationDate(date: string) {
  return DateFormatHelper('MM/DD/YYYY hh:mm a').format(
    DateHelper(date, { normalize: false })
  )
}

export function getExportUserCellFullName(
  email?: string,
  firstName?: string,
  lastName?: string
) {
  if (firstName && lastName) {
    return [firstName, lastName].join(' ')
  }
  const partialName = [firstName, lastName].filter(Boolean).join('')

  return partialName.length ? partialName : (email ?? '-')
}

const currencyFormatter = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

export function getExportTotalAmount(amount: number | null) {
  return currencyFormatter.format(amount ?? 0)
}

export function getFileName(fileName: string | null) {
  return fileName ?? '-'
}
