import { IconCheck, IconClose } from '@loadsmart/icons'
import { Button } from '@loadsmart/miranda-react'
import moment from 'moment'
import { useState } from 'react'
import type { ReactNode, MouseEvent, Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'

import { RFP_STATE } from 'utils/constants'

const TransparentButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0;
  outline: none;
  padding: 0;
`

const FakeDisabledButton = ({
  onClick,
  children,
}: {
  readonly onClick: (event: MouseEvent) => void
  readonly children: ReactNode
}) => {
  return <TransparentButton onClick={onClick}>{children}</TransparentButton>
}

const HoverButton = ({
  onClick,
}: {
  readonly onClick: (event: MouseEvent) => void
}) => {
  const [showRemove, setShowRemove] = useState(false)
  return (
    <div
      onMouseEnter={() => setShowRemove(true)}
      onMouseLeave={() => setShowRemove(false)}
    >
      <Button
        leading={
          showRemove ? (
            <IconClose width={12} height={12} />
          ) : (
            <IconCheck width={12} height={12} />
          )
        }
        size="small"
        variant="tertiary"
        disabled={!showRemove}
        onClick={onClick}
      >
        {showRemove ? 'Remove from bid' : 'Added to bid'}
      </Button>
    </div>
  )
}

const ClosedButton = ({
  row,
  invite,
  rfpState,
  bidClosedDialog,
}: {
  readonly row: any
  readonly invite: any
  readonly rfpState: string
  readonly bidClosedDialog: {
    modalState: boolean
    closeModal: () => void
    openModal: () => void
    toggleModal: () => void
    setModalState: Dispatch<SetStateAction<boolean>>
  }
}) => {
  if (invite) {
    return (
      <Button
        leading={<IconCheck width={12} height={12} />}
        size="small"
        variant="tertiary"
        disabled
      >
        {rfpState === RFP_STATE.DRAFT ? 'Added to Bid' : 'Invited'}
      </Button>
    )
  }

  return (
    <FakeDisabledButton
      //@TODO: Verify if this onClick is reachable
      onClick={() => {
        if (!row.original.hasDisabledStyle) {
          bidClosedDialog.openModal()
        }
      }}
    >
      <Button size="small" variant="tertiary" disabled>
        {rfpState === RFP_STATE.DRAFT ? 'Add to Bid' : 'Invite Carrier'}
      </Button>
    </FakeDisabledButton>
  )
}

const DraftButton = ({
  row,
  invite,
  rfpId,
  removeCarriersFromBid,
  addCarriersToBid,
}: {
  readonly row: any
  readonly invite: any
  readonly rfpId: number
  readonly removeCarriersFromBid: any
  readonly addCarriersToBid: any
}) => {
  if (invite) {
    return (
      <HoverButton
        onClick={(event) => {
          event.stopPropagation()

          removeCarriersFromBid({
            rfpId,
            invitesId: [row.original.invite.id],
          })
        }}
      />
    )
  }
  return (
    <Button
      size="small"
      variant="tertiary"
      style={{ marginLeft: '20px' }}
      onClick={(event) => {
        event.stopPropagation()
        addCarriersToBid({
          rfpId,
          carriers: [row.original.id],
        })
      }}
      disabled={row.original.hasDisabledStyle}
    >
      Add to bid
    </Button>
  )
}

const PublishedButton = ({
  row,
  invite,
  rfpId,
  inviteCarrier,
}: {
  readonly row: any
  readonly invite: any
  readonly rfpId: number
  readonly inviteCarrier: any
}) => {
  if (invite) {
    return (
      <Button
        leading={<IconCheck width={12} height={12} />}
        size="small"
        variant="tertiary"
        disabled
      >
        Invited
      </Button>
    )
  }
  return (
    <Button
      size="small"
      variant="tertiary"
      onClick={(event) => {
        event.stopPropagation()

        inviteCarrier({
          rfpId,
          carriers: [row.original.id],
        })
      }}
      disabled={row.original.hasDisabledStyle}
    >
      Invite Carrier
    </Button>
  )
}

export const BidActions = ({
  row,
  rfpState,
  rfpRequestDeadline,
  bidClosedDialog,
  rfpId,
  removeCarriersFromBid,
  addCarriersToBid,
}: {
  readonly row: any
  readonly rfpState: string
  readonly rfpRequestDeadline: string
  readonly bidClosedDialog: {
    modalState: boolean
    closeModal: () => void
    openModal: () => void
    toggleModal: () => void
    setModalState: Dispatch<SetStateAction<boolean>>
  }
  readonly rfpId: number
  readonly removeCarriersFromBid: any
  readonly addCarriersToBid: any
}) => {
  const { invite } = row.original

  if (rfpState === RFP_STATE.CLOSED || moment(rfpRequestDeadline).isBefore()) {
    return (
      <ClosedButton
        row={row}
        invite={invite}
        rfpState={rfpState}
        bidClosedDialog={bidClosedDialog}
      />
    )
  }

  if (rfpState === RFP_STATE.DRAFT) {
    return (
      <DraftButton
        row={row}
        invite={invite}
        rfpId={rfpId}
        removeCarriersFromBid={removeCarriersFromBid}
        addCarriersToBid={addCarriersToBid}
      />
    )
  }

  if (rfpState === RFP_STATE.PUBLISHED) {
    return (
      <PublishedButton
        row={row}
        invite={invite}
        rfpId={rfpId}
        inviteCarrier={addCarriersToBid}
      />
    )
  }

  if (invite) {
    return (
      <Button
        leading={<IconCheck width={12} height={12} />}
        size="small"
        variant="tertiary"
        disabled
      >
        Invited
      </Button>
    )
  }

  // finalized and archived should show only the invited ones
  return null
}
