import mixpanel from 'mixpanel-browser'

import { formatUserForAnalytics } from '_shared_/user'
import type { UserData } from '_shared_/user'
import { MIXPANEL_TOKEN, MIXPANEL_TOKEN_V2 } from 'utils/constants'

declare module 'mixpanel-browser' {
  interface Mixpanel {
    v1: Mixpanel
    v2: Mixpanel
  }
}

export const setupMixpanel = () => {
  mixpanel.init(MIXPANEL_TOKEN, {}, 'v1')
  mixpanel.init(MIXPANEL_TOKEN_V2, {}, 'v2')
}

const setMixpanelUserV2 = (user: UserData): void => {
  const userData = {
    // User
    email: user.user_email,
    is_mt_user: user.is_managed_trans,
    is_test: user.user_is_test,
    name: user.user_name,
    role: user.user_role,
    signup_date: user.user_date_joined,

    // Shipper
    customer_name: user.shipper_company,
    customer_business: user.shipper?.business,
    customer_segment: user.shipper?.segment,
    customer_signup_date: user.shipper?.signed_up_at,
    customer_subscription: user.user_category,
  }

  mixpanel.v2.identify(user.user_email)
  mixpanel.v2.people.set(userData)
}

const setMixPanelUserV1 = (userData: UserData): void => {
  const formattedUserData = formatUserForAnalytics(userData)
  const {
    email,
    name,
    company,
    type,
    shipper_test,
    user_category,
    business_type,
  } = formattedUserData

  mixpanel.v1.identify(email)
  mixpanel.v1.register(userData)
  mixpanel.v1.people.set({
    id: email,
    $email: email,
    $name: name,
    company,
    roles: userData.user_role,
    type,
    shipper_test,
    user_category,
    business_type,
  })
}

export const setMixPanelUser = (userData: UserData): void => {
  setMixPanelUserV1(userData)
  setMixpanelUserV2(userData)
}

export const mixpanelReset = () => {
  mixpanel.v1.reset()
  mixpanel.v2.reset()
}
