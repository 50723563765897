import { Button, Icon } from '@loadsmart/miranda-react'
import styled from 'styled-components'

import type { SettlementShipmentReportStatus } from './types'

const StyledButton = styled(Button)`
  margin-top: 4px;
`

export const ExportDownloadButton = ({
  status,
  fileURL,
  fileName,
}: {
  readonly status: SettlementShipmentReportStatus
  readonly fileName: string | null
  readonly fileURL: string | null
}) => {
  function handleViewReport() {
    if (fileURL) {
      window.open(fileURL, '_blank')?.focus()
    }
  }

  return (
    <StyledButton
      aria-label={`View export ${((fileName ?? '') + ' ').replace(/\s\s/, ' ')}button`}
      variant="icon-secondary"
      onClick={handleViewReport}
      type="button"
      disabled={status !== 'ready'}
    >
      <Icon size="24" name="download" />
    </StyledButton>
  )
}
