import { Layout, Tag } from '@loadsmart/loadsmart-ui'
import type { Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'

import StringLimiter from '_shared_/components/StringLimiter'
import TableActions from '_shared_/components/TableActions'
import loadsmartLogo from 'assets/icons/loadsmart.svg'
import { copyToClipboard } from 'rfp/rfp-details/utils'
import analytics, {
  AnalyticsEvent,
  AnalyticsEventTrigger,
} from 'utils/analytics'
import { RFP_STATE } from 'utils/constants'
import { numberFormatter } from 'utils/numbers'
import { plural } from 'utils/strings'

import type { Carrier } from '../Carriers'
import { TableHeaderDropdown } from '../TableHeaderDropdown'
import { BidActions } from './Cells'
import { InviteStatusTag } from './InviteStatusTag'

const MINUTES_IN_A_DAY = 1440

const LSIcon = styled.object<{ disabled?: boolean }>`
  ${({ disabled }) => disabled && 'opacity: 0.5;'}
  height: 22px;
  width: 101px;
`

const optionsDict: Record<string, string> = {
  dot: 'DOT',
  mc: 'MC',
  scac: 'SCAC',
  email: 'EMAIL',
  percentage_invites_responded: 'RESPONSE',
  total_invites: 'INVITES',
  awarded_bids_rate: 'WIN RATE',
  average_response_time: 'AVG. BID TIME',
}

function getCarrierInfoOptions() {
  return [
    { value: 'dot', label: 'DOT' },
    { value: 'mc', label: 'MC' },
    { value: 'scac', label: 'SCAC' },
    {
      value: 'email',
      label: 'Primary email',
    },
  ]
}

const CarrierBidInfoOptionsWithAnalytics = [
  { value: 'percentage_invites_responded', label: 'Response' },
  { value: 'total_invites', label: 'Invites' },
  { value: 'awarded_bids_rate', label: 'Win rate' },
  { value: 'average_response_time', label: 'Avg. bid time' },
]

const getValueOrZero = (value?: number) => {
  if (!value) {
    return 0
  }
  return value
}

const getBidOptionOutput = (bidOption: string, value: number, row: any) => {
  if (bidOption === 'percentage_invites_responded') {
    return `${numberFormatter(value, 1)}%`
  }

  if (bidOption === 'total_invites') {
    return `${getValueOrZero(row.original.total_invites)}/${getValueOrZero(row.original.total_rfp_proposals_submitted)}`
  }

  if (bidOption === 'average_response_time') {
    if (value >= MINUTES_IN_A_DAY) {
      const valueToDays = Math.floor(getValueOrZero(value) / 60 / 24)
      return `${valueToDays} ${plural('day', valueToDays)}`
    }
    return '< 24 hours'
  }

  if (bidOption == 'awarded_bids_rate') {
    return `${numberFormatter(value, 0)}%`
  }

  return '-'
}

const CarrierCell = ({
  loadsmartDOT,
  nameMaxLength,
  row,
}: {
  readonly loadsmartDOT: string
  readonly nameMaxLength: number
  readonly row: any
}) => {
  const { name, dot } = row.original

  if (dot !== loadsmartDOT) {
    return <StringLimiter limiter={nameMaxLength} value={name} />
  }
  return (
    <LSIcon
      type="image/svg+xml"
      data={loadsmartLogo}
      data-testid="ls-logo"
      disabled={row.original.hasDisabledStyle}
    >
      {name}
    </LSIcon>
  )
}

const InviteStatusCell = ({
  rfpState,
  row,
}: {
  readonly rfpState: string
  readonly row: any
}) => {
  const invite = row.original.invite
  const unsubscribed = row.original.unsubscribed

  if (unsubscribed) {
    return (
      <Tag size="small" variant="default" style={{ whiteSpace: 'nowrap' }}>
        UNSUBSCRIBED
      </Tag>
    )
  }

  if (invite?.state === 'check_contact') {
    return (
      <Tag size="small" variant="default" style={{ whiteSpace: 'nowrap' }}>
        CHECK CONTACT
      </Tag>
    )
  }

  if (invite) {
    return (
      <InviteStatusTag
        rfpState={rfpState as RfpState}
        inviteStatus={invite.state}
      />
    )
  }

  return (
    <Tag
      size="small"
      variant="default"
      style={{ whiteSpace: 'nowrap' }}
      className={row.original.hasDisabledStyle ? 'disabled-opacity' : ''}
    >
      {rfpState === RFP_STATE.DRAFT ? 'NOT ADDED' : 'NOT INVITED'}
    </Tag>
  )
}

export default function makeColumns({
  loadsmartDOT,
  nameMaxLength,
  infoOption,
  setInfoOption,
  bidOption,
  setBidOption,
  rfpState,
  rfpRequestDeadline,
  bidClosedDialog,
  rfpId,
  removeCarriersFromBid,
  addCarriersToBid,
  openForEdition,
  setSelectedCarrier,
  resendInviteOpenModal,
}: {
  loadsmartDOT: any
  nameMaxLength: number
  infoOption: string
  setInfoOption: (value: string) => void
  bidOption: string
  setBidOption: (value: string) => void
  rfpState: string
  rfpRequestDeadline: string
  bidClosedDialog: {
    modalState: boolean
    closeModal: () => void
    openModal: () => void
    toggleModal: () => void
    setModalState: Dispatch<SetStateAction<boolean>>
  }
  rfpId: number
  removeCarriersFromBid: any
  addCarriersToBid: any
  openForEdition: (carrier?: globalThis.Carrier) => void
  setSelectedCarrier: (carrier: Carrier) => void
  resendInviteOpenModal: () => void
}) {
  return [
    {
      Header: 'CARRIER',
      id: 'name',
      accessor: 'name',
      className: 'text-left',
      Cell: function render({ row }: { row: any }) {
        return (
          <CarrierCell
            loadsmartDOT={loadsmartDOT}
            nameMaxLength={nameMaxLength}
            row={row}
          />
        )
      },
    },
    {
      Header: 'TYPE',
      id: 'entity',
      accessor: 'entity',
      className: 'text-left capitalize',
      Cell: function render({ value, row }: { value: any; row: any }) {
        const modifier = row.original.hasDisabledStyle ? 'attenuated' : ''
        return <span className={modifier}>{value}</span>
      },
    },
    {
      Header: (
        <Layout.Group space="xs" style={{ flexWrap: 'nowrap' }}>
          {optionsDict[infoOption]}
          <TableHeaderDropdown
            options={getCarrierInfoOptions()}
            defaultValue={infoOption}
            setValue={setInfoOption}
          />
        </Layout.Group>
      ),
      id: infoOption,
      accessor: infoOption,
      className: 'text-left',
      Cell: function CellInfoOption({
        value,
        row,
      }: {
        readonly value: unknown
        readonly row: any
      }) {
        const modifier = row.original.hasDisabledStyle ? 'attenuated' : ''
        return <span className={modifier}>{(value as string) ?? '-'}</span>
      },
    },
    {
      Header: (
        <Layout.Group space="xs" style={{ flexWrap: 'nowrap' }}>
          {optionsDict[bidOption]}
          <TableHeaderDropdown
            options={CarrierBidInfoOptionsWithAnalytics}
            defaultValue={bidOption}
            setValue={setBidOption}
          />
        </Layout.Group>
      ),
      id: bidOption,
      accessor: bidOption,
      className: 'text-left',
      Cell: function CellBidOption({
        value,
        row,
      }: {
        readonly value: number
        readonly row: any
      }) {
        const modifier = row.original.hasDisabledStyle ? 'attenuated' : ''
        return (
          <span className={modifier}>
            {getBidOptionOutput(bidOption, value, row)}
          </span>
        )
      },
    },
    {
      Header: 'STATUS',
      id: 'invite_status',
      accessor: 'invite',
      className: 'text-left medium',
      Cell: function render({ row }: { row: any }) {
        return <InviteStatusCell rfpState={rfpState} row={row} />
      },
    },
    {
      Header: '',
      id: 'bid_actions',
      accessor: 'invite',
      className: 'text-left buttons-cell',
      Cell: function bidActionsCell({ row }: { row: any }) {
        return (
          <BidActions
            row={row}
            rfpState={rfpState}
            rfpRequestDeadline={rfpRequestDeadline}
            bidClosedDialog={bidClosedDialog}
            rfpId={rfpId}
            removeCarriersFromBid={removeCarriersFromBid}
            addCarriersToBid={addCarriersToBid}
          />
        )
      },
    },
    {
      Header: '',
      id: 'carrier_actions',
      accessor: '',
      className: 'text-left actions-cell',
      Cell: function render({ row }: { row: any }) {
        const { invite } = row.original

        const canResendInvite =
          invite?.state === 'sent' || invite?.state === 'resent'

        const onClick = () => {
          setSelectedCarrier(row.original)
          resendInviteOpenModal()
        }

        return (
          <TableActions disabled={row.original.hasDisabledStyle}>
            {rfpState === RFP_STATE.PUBLISHED && (
              <TableActions.Item onClick={onClick} disabled={!canResendInvite}>
                Resend Invite To Bid
              </TableActions.Item>
            )}
            <TableActions.Item onClick={() => openForEdition(row.original)}>
              Carrier Info
            </TableActions.Item>
            {invite?.link && (
              <TableActions.Item
                onClick={() => {
                  copyToClipboard(invite.link)

                  analytics.track(
                    AnalyticsEvent.RFPCopyInviteLink,
                    AnalyticsEventTrigger.click,
                    {
                      source: 'table',
                    }
                  )
                }}
              >
                Copy Invite Link
              </TableActions.Item>
            )}
          </TableActions>
        )
      },
    },
  ]
}
