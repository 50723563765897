import * as Sentry from '@sentry/react'

import {
  EDITABLE_RFP_STATES,
  PUBLISHED_RFP_STATES,
  FINALIZED_RFP_STATES,
  RFP_STATE,
} from 'utils/constants'
import { isValidLane } from 'utils/lanes'

import type { TableData } from './types'

type AwardConfirmation = string | null | undefined

const checkPendingAwards = (awardsConfirmation: AwardConfirmation[]) =>
  awardsConfirmation.includes(null) &&
  (awardsConfirmation.includes('accepted') ||
    awardsConfirmation.includes('denied'))

const checkDeniedAwards = (awardsConfirmation: AwardConfirmation[]) =>
  awardsConfirmation.includes('denied') && !awardsConfirmation.includes(null)

const checkNullAwards = (awardsConfirmation: AwardConfirmation[]) =>
  awardsConfirmation?.length &&
  awardsConfirmation.every((item) => item === null)

const checkAcceptedAwards = (awardsConfirmation: AwardConfirmation[]) =>
  awardsConfirmation?.length &&
  awardsConfirmation.every((item) => item === 'accepted')

const getPublishedRFPLaneStatus = (
  lane: TableData,
  awardedCount: number,
  awardedVolume: number
) => {
  if ((lane.proposals_count ?? 0) < 1) {
    return 'waitingForBids'
  }

  if (awardedCount > 0) {
    const laneVolume = lane?.volume ?? 0
    if (awardedVolume < laneVolume) {
      return 'selectedCarriers'
    }
    return 'readyToAward'
  }

  if (lane.has_new_bids) {
    return 'bidsReceivedNew'
  }

  return 'bidsReceivedVisualized'
}

const getAwardingOrFinalizedRFPLaneStatus = (
  awardsConfirmation: AwardConfirmation[],
  awardedCount: number
) => {
  const hasAwardsPending = checkPendingAwards(awardsConfirmation)
  const hasDeniedAwards = checkDeniedAwards(awardsConfirmation)
  const allAwardsIsNull = checkNullAwards(awardsConfirmation)
  const allAwardsIsAccepted = checkAcceptedAwards(awardsConfirmation)

  if (awardedCount > 0 && allAwardsIsNull) {
    return 'awarded'
  }
  if (hasAwardsPending) {
    return 'hasAwardPending'
  }
  if (hasDeniedAwards) {
    return 'hasAwardDenied'
  }
  if (allAwardsIsAccepted) {
    return 'allAwardAccepted'
  }

  return 'noAwards'
}

const checkIfRFPIsInAwardingOrFinalizedState = (rfpState: string) =>
  FINALIZED_RFP_STATES.includes(rfpState ?? '') ||
  rfpState === RFP_STATE.AWARDING

export const getLaneStatus = (
  lane: TableData,
  state: string
): LaneStatus | null => {
  if (EDITABLE_RFP_STATES.includes(state ?? '')) {
    return isValidLane(lane) ? 'complete' : 'error'
  }

  const awardedCount = lane.awarded_proposals?.length ?? 0
  const awardedProposals = lane.awarded_proposals
  const allAwardConfirmation = awardedProposals.map((item) => {
    return item.award_confirmation
  })

  const awardedVolume = getAwardedVolume(lane.awarded_proposals ?? [])

  if (PUBLISHED_RFP_STATES.includes(state ?? '')) {
    return getPublishedRFPLaneStatus(lane, awardedCount, awardedVolume)
  }
  if (checkIfRFPIsInAwardingOrFinalizedState(state)) {
    return getAwardingOrFinalizedRFPLaneStatus(
      allAwardConfirmation,
      awardedCount
    )
  }

  Sentry.withScope((scope) => {
    scope.setExtra('lane', lane)
    scope.setExtra('state', state)
    scope.setExtra('awardedVolume', awardedVolume)
    Sentry.captureMessage('Unknown status for LaneStatus')
  })

  return null
}

function getAwardedVolume(awardedProposals: LaneProposal[]): number {
  return awardedProposals.reduce(
    (acc, proposal) => acc + proposal.awarded_volume,
    0
  )
}
