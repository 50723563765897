import { Layout, PageContent } from '@loadsmart/miranda-react'
import styled from 'styled-components'

import { WindowTitle } from '_shared_/components/WindowTitle'
import { MirandaPageGlobalStyle } from 'styles/global'

import { PlanFulfillmentsMap } from './components/PlanFulfillmentsMap'
import { PlanFulfillmentsPageHeader } from './components/PlanFulfillmentsPageHeader'
import { PlanFulfillmentsPlanPreview } from './components/PlanFulfillmentsPlanPreview'
import { PlanFulfillmentsContextProvider } from './PlanFulfillmentsContext'

export const FullHeightStack = styled(Layout.Stack)`
  height: calc(100% - 89px); /* page header height */
`

export function PlanFulfillmentsPage() {
  return (
    <>
      <WindowTitle title="Fulfillments | ShipperGuide" />
      <MirandaPageGlobalStyle />
      <PageContent>
        <PlanFulfillmentsPageHeader />
      </PageContent>

      {/** TODO: update with PageContent.Body once it has been modified to support full height */}
      <FullHeightStack>
        <PlanFulfillmentsContextProvider>
          <Layout.Group
            gap="spacing-14"
            style={{
              display: 'flex',
              flex: 1,
              minHeight: 0,
              overflow: 'hidden',
            }}
          >
            <Layout.Box
              style={{
                display: 'flex',
                height: '100%',
                minWidth: '750px',
                maxWidth: '850px',
                overflowY: 'auto',
              }}
            >
              <PlanFulfillmentsPlanPreview />
            </Layout.Box>

            <Layout.Box
              style={{
                display: 'flex',
                flex: 1,
                height: '100%',
              }}
            >
              <PlanFulfillmentsMap />
            </Layout.Box>
          </Layout.Group>
        </PlanFulfillmentsContextProvider>
      </FullHeightStack>
    </>
  )
}
