import { SideNavigation } from '@loadsmart/loadsmart-ui'
import { useHistory } from 'react-router-dom'

import { userUtils } from '_shared_/user'
import { useSettings } from 'contexts/settings'
import { AppRoutes } from 'router/AppRoutes'
import { USER_TYPE } from 'utils/constants'

import { isActivePath, navbarLinkClicked } from './sidenav.utils'

export const ManageMenu = () => {
  const history = useHistory()
  const {
    values: [facilityManagementEnabled, suppliersPageEnabled],
  } = useSettings([
    'flags.ENABLE_FACILITY_MANAGEMENT',
    'flags.ENABLE_SUPPLIERS_PAGE',
  ])

  if (userUtils.isUserType([USER_TYPE.SG, USER_TYPE.FBM])) {
    return (
      <SideNavigation.Menu label="Manage">
        {userUtils.isUserType([USER_TYPE.SG]) && (
          <>
            <SideNavigation.Menu.Item
              active={isActivePath(AppRoutes.Carriers)}
              label="Carriers"
              url={AppRoutes.Carriers}
              onClick={(event) => {
                event.preventDefault()
                navbarLinkClicked('Carriers', AppRoutes.Carriers, history)
              }}
            />
            <SideNavigation.Menu.Item
              active={isActivePath(AppRoutes.Lanes)}
              label="Lanes"
              url={AppRoutes.Lanes}
              onClick={(event) => {
                event.preventDefault()
                navbarLinkClicked('Lanes', AppRoutes.Lanes, history)
              }}
            />
          </>
        )}
        {facilityManagementEnabled && (
          <SideNavigation.Menu.Item
            active={isActivePath(AppRoutes.FacilityManagement)}
            label="Facilities"
            url={AppRoutes.FacilityManagement}
            onClick={(event) => {
              event.preventDefault()
              navbarLinkClicked(
                'Facilities',
                AppRoutes.FacilityManagement,
                history
              )
            }}
          />
        )}
        {suppliersPageEnabled && (
          <SideNavigation.Menu.Item
            active={isActivePath(AppRoutes.SuppliersList)}
            label="Suppliers"
            url={AppRoutes.SuppliersList}
            onClick={(event) => {
              event.preventDefault()
              navbarLinkClicked('Suppliers', AppRoutes.SuppliersList, history)
            }}
          />
        )}
      </SideNavigation.Menu>
    )
  }

  return null
}
