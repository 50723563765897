import { Layout, Text } from '@loadsmart/loadsmart-ui'
import { useMemo, useState } from 'react'
import type { Dispatch, SetStateAction } from 'react'
import ReactPaginate from 'react-paginate'
import type { UseQueryResult } from 'react-query'

import EmptyStateImage from 'assets/imgs/tracking-empty-state.svg'
import {
  Container,
  EmptyButtonsRow,
  EmptyLanesContainer,
  EmptyLanesImage,
  StyledText,
} from 'components/LaneManagement/LanesTableV3/styles'
import usePagination from 'hooks/usePagination'
import type { QueryParams } from 'rfp/components/table/laneParams'
import {
  getTablePageOffset,
  getTableSortDirection,
  useLaneIdMaxLength,
} from 'rfp/components/table/tableUtils'
import type { Currency } from 'rfp/rfp.types'
import { RFP_STATE } from 'utils/constants'
import { isMultiCurrency } from 'utils/currency'

import BaseTable from '../BaseTable'
import EmptyState from '../EmptyState'
import makeColumns from './makeColumns'

enum RadioOptions {
  LOWEST = 'lowest_bid',
  AVERAGE = 'average_bid',
  HIGHEST = 'highest_bid',
  LAST_AWARD = 'last_award',
  TARGET_RATE = 'target_rate',
  SPOT_MARKET = 'market_rate',
  SHORT_CONTRACT = 'contract_rate',
}

const RateOptions = [
  { value: 'market_rate', label: 'Market Benchmark' },
  { value: 'target_rate', label: 'Target Rate' },
  { value: 'last_award', label: 'Last Award' },
]

const MultiCurrencyRateOptions = RateOptions.filter(
  (option) => option.value === RadioOptions.LAST_AWARD
)

const LIMIT = 50

const defaultParams = {
  offset: 0,
  limit: LIMIT,
  sort: {
    column: 'lane_id',
    direction: 'asc',
  },
}

const NoMatchesEmptyState = () => {
  return (
    <EmptyState>
      <Layout.Stack space="s">
        <Text variant="heading-sm-bold" color="color-neutral-darkest">
          No Matches
        </Text>
        <Text variant="caption" color="color-neutral-darker">
          There are no available scenarios
          <br />
          based on the selected criteria.
        </Text>
      </Layout.Stack>
    </EmptyState>
  )
}

const initialRateOption = (displayMultiCurrency: boolean, rfpState: string) =>
  displayMultiCurrency || rfpState === RFP_STATE.DRAFT
    ? RadioOptions.LAST_AWARD
    : RadioOptions.SPOT_MARKET

interface AwardScenariosTableProps {
  readonly rfpState: string
  readonly rfpCurrency: Currency
  readonly addLaneButton: any
  readonly importLaneButton: any
  readonly onRowClick: (lane: Lane) => void
  readonly carrierFilter: string | null
  readonly typesFilter: string | null
  readonly backupFilter: string | null
  readonly setScenariosSelected: Dispatch<SetStateAction<any>>
  readonly query: UseQueryResult<PaginatedResult, unknown>
  readonly params: QueryParams
  readonly setParams: Dispatch<SetStateAction<QueryParams>>
  readonly allocatedLanesCount: number
  readonly deallocatedLanesCount: number
  readonly isUpdatingScenarios: boolean
}

const DefaultStateTitle = () => {
  return (
    <Text variant="heading-sm-bold" color="color-neutral-darkest">
      No lanes in Scenario yet
    </Text>
  )
}

const DefaultStateSubtitle = () => {
  return (
    <Text variant="caption" color="color-neutral-darker">
      Click on the Add rules button above to create award scenarios.
    </Text>
  )
}

export default function AwardScenariosTable({
  onRowClick,
  addLaneButton,
  importLaneButton,
  rfpState,
  rfpCurrency,
  carrierFilter,
  typesFilter,
  backupFilter,
  setScenariosSelected,
  query,
  params,
  setParams,
  allocatedLanesCount,
  deallocatedLanesCount,
  isUpdatingScenarios,
}: AwardScenariosTableProps) {
  const shouldRenderNoLanesEmptyState =
    allocatedLanesCount === 0 && deallocatedLanesCount === 0

  const shouldRenderDefaultState = useMemo(() => {
    return (
      carrierFilter === null && typesFilter == null && backupFilter === null
    )
  }, [carrierFilter, typesFilter, backupFilter])

  const isLoading = [
    query.isLoading,
    query.isFetching,
    isUpdatingScenarios,
  ].some((condition) => Boolean(condition) === true)

  const { pageCount, page } = usePagination(
    LIMIT,
    query.data?.count,
    params.page
  )

  const handlePageChange = (selectedItem: { selected: number }) => {
    setParams({
      ...params,
      page: selectedItem.selected,
      offset: getTablePageOffset(selectedItem.selected, defaultParams.offset),
    })
  }

  const changeSorting = (column: string) => {
    setParams({
      ...params,
      sort: {
        column,
        direction: getTableSortDirection(params.sort.direction),
      },
    })
  }

  const displayMultiCurrency = isMultiCurrency(rfpCurrency)

  const [rateOption, setRadioOptionTwo] = useState<string>(
    initialRateOption(displayMultiCurrency, rfpState)
  )

  const availableRateOptions = displayMultiCurrency
    ? MultiCurrencyRateOptions
    : RateOptions

  const laneIdMaxLength = useLaneIdMaxLength()

  const columns = useMemo(() => {
    return () =>
      makeColumns({
        laneIdMaxLength,
        rateOption,
        rfpState,
        rfpCurrency,
        availableRateOptions,
        displayMultiCurrency,
        setRadioOptionTwo,
      })
  }, [
    laneIdMaxLength,
    rateOption,
    rfpState,
    rfpCurrency,
    availableRateOptions,
    displayMultiCurrency,
  ])

  if (shouldRenderDefaultState) {
    return (
      <EmptyState>
        <Layout.Stack space="s">
          <DefaultStateTitle />
          <DefaultStateSubtitle />
        </Layout.Stack>
      </EmptyState>
    )
  }

  if (shouldRenderNoLanesEmptyState) {
    return (
      <EmptyLanesContainer border="v2">
        <EmptyLanesImage src={EmptyStateImage} />
        <Text variant="body-bold">Add Your Lanes</Text>
        <StyledText size="small">
          Add single lanes or import lanes in batch for this RFP.
        </StyledText>
        <EmptyButtonsRow>
          {addLaneButton}
          {importLaneButton}
        </EmptyButtonsRow>
      </EmptyLanesContainer>
    )
  }

  if (!query.isFetching && query.data?.results?.length === 0) {
    return <NoMatchesEmptyState />
  }

  return (
    <Container style={{ paddingBottom: '40px' }}>
      <BaseTable
        isLoading={isLoading}
        columns={columns}
        entries={query.data?.results ?? []}
        onRowClick={(_event, row) => onRowClick(row.original)}
        sortable
        changeSorting={changeSorting}
        sort={params.sort}
        updateSelection={setScenariosSelected}
      />
      {pageCount > 1 && (
        <ReactPaginate
          data-testid="pagination"
          previousLabel="Previous"
          nextLabel="Next"
          breakLabel="..."
          breakClassName="break-me"
          pageCount={pageCount}
          forcePage={page}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName="pagination"
          activeClassName="active"
        />
      )}
    </Container>
  )
}
