import { Tag } from '@loadsmart/loadsmart-ui'

import { RFP_STATE } from 'utils/constants'

export const InviteStatusTag = ({
  rfpState,
  inviteStatus,
}: {
  readonly rfpState: RfpState
  readonly inviteStatus: string
}) => {
  if (rfpState === RFP_STATE.DRAFT && inviteStatus === 'pending') {
    return (
      <Tag size="small" variant="success" style={{ whiteSpace: 'nowrap' }}>
        ADDED TO BID
      </Tag>
    )
  }

  if (inviteStatus === 'award_finished') {
    return (
      <Tag size="small" variant="accent" style={{ whiteSpace: 'nowrap' }}>
        AWARD REPLIED
      </Tag>
    )
  }

  if ([RFP_STATE.PUBLISHED, RFP_STATE.CLOSED].includes(rfpState)) {
    if (inviteStatus === 'submitted') {
      return (
        <Tag size="small" variant="success" style={{ whiteSpace: 'nowrap' }}>
          RATES SUBMITTED
        </Tag>
      )
    }

    if (inviteStatus.includes('sent')) {
      return (
        <Tag size="small" variant="warning" style={{ whiteSpace: 'nowrap' }}>
          {`EMAIL ${inviteStatus.toLocaleUpperCase()}`}
        </Tag>
      )
    }

    if (inviteStatus === 'accepted') {
      return (
        <Tag size="small" variant="accent" style={{ whiteSpace: 'nowrap' }}>
          ACCEPTED
        </Tag>
      )
    }

    // Sometimes we have an "invite" entry missing some data, so for RFP Published or Closed
    // the default state will be Email Sent.
    return (
      <Tag size="small" variant="warning" style={{ whiteSpace: 'nowrap' }}>
        EMAIL SENT
      </Tag>
    )
  }

  // These default cases are related to FINALIZED and ARCHIVED RFP states

  if (inviteStatus === 'submitted' || inviteStatus === 'finished') {
    return (
      <Tag size="small" variant="success" style={{ whiteSpace: 'nowrap' }}>
        RATES SUBMITTED
      </Tag>
    )
  }

  return (
    <Tag size="small" variant="default" style={{ whiteSpace: 'nowrap' }}>
      NO RESPONSE
    </Tag>
  )
}
