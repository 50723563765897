import { IconLogout } from '@loadsmart/icons'
import { Text } from '@loadsmart/miranda-react'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'

import { useAuth } from '_shared_/user/useAuth'

import { StyledDropdownItem } from './styles'

export function LogoutButton() {
  const { logout } = useAuth()

  return (
    <StyledDropdownItem
      leading={
        <IconLogout
          width={16}
          height={16}
          fill={toCSSValue('color-primary-60')}
        />
      }
      onClick={(event) => {
        event.preventDefault()
        logout()
      }}
    >
      <Text variant="body-md-bold">Logout</Text>
    </StyledDropdownItem>
  )
}
