import { Banner, Layout, Text } from '@loadsmart/miranda-react'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import { ControlPosition, MapControl } from '@vis.gl/react-google-maps'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import styled from 'styled-components'

import { FitBounds, MapV2 } from 'components/Map'
import { SHIPMENT_MAP_GOOGLE_MAP_ID } from 'utils/constants'

import { usePlanFulfillmentsContext } from '../PlanFulfillmentsContext'
import type {
  PinMarkerProps,
  PinVariant,
} from './PlanFulfillmentsMap.components'
import { Line, PinMarker } from './PlanFulfillmentsMap.components'
import type { StopMapData } from './PlanFulfillmentsMap.utils'
import {
  getBounds,
  getLanesMap,
  getStopsMap,
  mapLaneMapDataToLineConfig,
  mapStopMapDataToPinConfig,
} from './PlanFulfillmentsMap.utils'

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
`

const MapContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
`

export function getPinVariant(stop: StopMapData): PinVariant {
  if (!stop.plannedFulfillments.length) {
    return 'default'
  }
  if (stop.pendingFulfillments.length > 0) {
    return 'accent'
  }

  return 'success'
}

export function PlanFulfillmentsMap() {
  const { pendingFulfillments, selectedFulfillmentsUUIDs } =
    usePlanFulfillmentsContext()
  // List all stops from all available fulfillments
  const stopsMap = useMemo(
    () => getStopsMap(pendingFulfillments, selectedFulfillmentsUUIDs),
    [pendingFulfillments, selectedFulfillmentsUUIDs]
  )
  const pins = useMemo(
    () => Object.values(stopsMap).map(mapStopMapDataToPinConfig),
    [stopsMap]
  )
  const pinsProps: PinMarkerProps[] = useMemo(
    () =>
      pins.map((pin) => {
        const stop = stopsMap[pin.id]

        const onPinClick: PinMarkerProps['onPinClick'] = () => {
          // openStopOrdersDrawer(stop.facilityUUID)
        }

        const variant = getPinVariant(stop)

        return {
          ...pin,
          onPinClick,
          variant,
        }
      }),
    [pins, stopsMap]
  )

  // List all lanes from all available fulfillments and current planned stops
  const lanesMap = useMemo(
    () =>
      getLanesMap(pendingFulfillments, stopsMap, [], selectedFulfillmentsUUIDs),
    [pendingFulfillments, selectedFulfillmentsUUIDs, stopsMap]
  )
  const lines = useMemo(
    () => Object.values(lanesMap).map(mapLaneMapDataToLineConfig),
    [lanesMap]
  )

  const bounds = useMemo(() => getBounds(lines), [lines])
  const notPlottableFacilities = useMemo(
    () => pins.filter((pin) => isEmpty(pin.position)),
    [pins]
  )

  return (
    <Container>
      {notPlottableFacilities.length > 0 && (
        <Banner
          variant="warning"
          style={{ marginBottom: toCSSValue('spacing-4') }}
        >
          <Banner.Title>
            {notPlottableFacilities.length} facilities without latitude and
            longitude
          </Banner.Title>
          <Banner.Description>
            These stops were not plotted on the map
          </Banner.Description>
        </Banner>
      )}
      <MapContainer>
        <MapV2
          page="Plan fulfillments"
          fullscreenControl
          mapId={SHIPMENT_MAP_GOOGLE_MAP_ID}
          zoomControl
        >
          <MapControl position={ControlPosition.TOP_RIGHT}>
            <Layout.Box
              backgroundColor="color-background-primary"
              paddingX="spacing-6"
              paddingY="spacing-4"
              style={{
                marginRight: toCSSValue('spacing-2'),
                marginTop: toCSSValue('spacing-2'),
              }}
            >
              <Text color="color-text-secondary" variant="body-sm">
                Showing {pendingFulfillments?.length} pending fulfillments
              </Text>
            </Layout.Box>
          </MapControl>

          {lines.map(({ id, ...lane }) => (
            <Line key={id} {...lane} />
          ))}

          {pinsProps.map((pinProps) => (
            <PinMarker key={pinProps.id} {...pinProps} />
          ))}

          {bounds && <FitBounds path={bounds} control={false} />}
        </MapV2>
      </MapContainer>
    </Container>
  )
}
