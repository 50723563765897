import type { AppThunk } from 'app/store'
import { list } from 'services/laneProposal'

import type { RateType } from './reducer'
import { slice } from './reducer'

export const {
  fetchLaneProposalsStart,
  fetchLaneProposalsSuccess,
  fetchLaneProposalsFailed,
  updateRateTypeView,
} = slice.actions

export const fetchLaneProposals =
  (lane: number): AppThunk =>
  async (dispatch) => {
    dispatch(fetchLaneProposalsStart())
    try {
      const data = await list(lane)
      dispatch(fetchLaneProposalsSuccess(data))
    } catch {
      dispatch(fetchLaneProposalsFailed())
    }
  }

export const setRateTypeView =
  (rateType: RateType): AppThunk =>
  (dispatch) => {
    dispatch(updateRateTypeView(rateType))
  }
