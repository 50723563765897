import httpClient from 'utils/httpClient'

type ExtraStopsMileage = {
  city?: string
  state?: string
  zip_code?: string | null
}

type Location = {
  pickup_city?: string
  pickup_state?: string
  pickup_zip_code?: string | null
  delivery_city?: string
  delivery_state?: string
  delivery_zip_code?: string | null
  extra_stops?: ExtraStopsMileage[]
}

export const getMileage = async (params: Location) => {
  const { data } = await httpClient.post('/proposals/lane_mileage', params)
  return data
}
