import { IconQuestionCircle, IconTrash } from '@loadsmart/icons'
import { Select } from '@loadsmart/loadsmart-ui'
import {
  Tooltip,
  Card,
  Field,
  Layout,
  Button,
  Divider,
} from '@loadsmart/miranda-react'
import { Fragment, useMemo, useState } from 'react'
import type { FieldError } from 'react-hook-form'
import { Controller } from 'react-hook-form'

import { useRfpEquipmentSizes } from 'rfp/hooks/rfp'
import { RequiredErrorMessage } from 'rfp/rfp-form/componentUtils'
import { getFieldStatus } from 'rfp/rfp-form/utils'

import type {
  EquipmentSizeSelectComponentProps,
  EquipmentTypeSelectComponentProps,
  LanePreference,
  LanePreferenceListProps,
  ModeAndEquipmentProps,
  ModeSelectComponentProps,
} from './types'

type PreferenceErrors =
  | {
      equipment_type?: FieldError
      mode?: FieldError
      equipment_size?: FieldError
    }[]
  // Both the array can be undefined or have some undefined (empty) positions
  | undefined[]
  | undefined

const SubEquipmentTypeComponent = () => {
  return <Field style={{ gridColumn: 'span 3' }} />
}

const EquipmentSizeComponent = ({
  enableEquipmentSize,
  errors,
  index,
  handleEquipmentSizeselect,
  getPreferenceEquipmentSize,
  preference,
  equipmentSizeOptions,
}: EquipmentSizeSelectComponentProps) => {
  if (!enableEquipmentSize) {
    return null
  }

  const preferenceErrors = errors.preferences as PreferenceErrors

  return (
    <Field
      required
      style={{ gridColumn: 'span 3' }}
      status={getFieldStatus(errors.preferences)}
    >
      <Field.Label aria-label="laneEquipmentSize" id="laneEquipmentSize">
        Equipment length
      </Field.Label>
      <Select
        name={`equipment-size-${index}`}
        id={`preference-${index}-equipment-size`}
        onChange={(event) => handleEquipmentSizeselect(index, event)}
        value={getPreferenceEquipmentSize(preference)}
        options={equipmentSizeOptions}
        placeholder="Select an equipment size"
        aria-labelledby="equipmentSizeSelect"
        data-testid={`equipmentSizeSelect-${index}`}
        hideClear
      />
      <RequiredErrorMessage error={preferenceErrors?.[index]?.equipment_size} />
    </Field>
  )
}

const EquipmentTypeComponent = ({
  errors,
  index,
  handleEquipmentTypeselect,
  preference,
  equipmentOptions,
  getPreferenceEquipmentType,
}: EquipmentTypeSelectComponentProps) => {
  const isEquipmentSelectDisabled =
    !preference.mode || equipmentOptions.length === 0

  const preferenceErrors = errors.preferences as PreferenceErrors

  return (
    <Field
      required
      style={{ gridColumn: 'span 3' }}
      status={getFieldStatus(errors.preferences)}
    >
      <Field.Label aria-label="laneEquipment" id="laneEquipment">
        Equipment
      </Field.Label>
      <Select
        name={`equipment-${index}`}
        id={`preference-${index}-equipment`}
        onChange={(event) => handleEquipmentTypeselect(index, event)}
        value={getPreferenceEquipmentType(preference)}
        options={equipmentOptions}
        placeholder="Select an equipment type"
        aria-labelledby="equipmentSelect"
        data-testid={`equipmentSelect-${index}`}
        disabled={isEquipmentSelectDisabled}
        hideClear
      />
      <RequiredErrorMessage error={preferenceErrors?.[index]?.equipment_type} />
    </Field>
  )
}

const ModeComponent = ({
  errors,
  index,
  selectMode,
  getPreferenceMode,
  preference,
  modeOptions,
}: ModeSelectComponentProps) => {
  const preferenceErrors = errors.preferences as PreferenceErrors

  return (
    <Field
      required
      style={{ gridColumn: 'span 3' }}
      status={getFieldStatus(errors.preferences)}
    >
      <Field.Label aria-label="laneMode" id="laneMode">
        Mode
      </Field.Label>
      <Select
        name={`mode-${index}`}
        id={`preference-${index}-mode`}
        onChange={(event) => selectMode(index, event)}
        value={getPreferenceMode(preference)}
        disabled={!modeOptions || modeOptions.length === 0}
        options={modeOptions}
        placeholder="Select a mode"
        aria-labelledby="modeSelect"
        data-testid={`modeSelect-${index}`}
        hideClear
      />
      <RequiredErrorMessage error={preferenceErrors?.[index]?.mode} />
    </Field>
  )
}

const LanePreferenceList = ({
  onOptionRemove,
  setValue,
  errors,
  modeOptions,
  equipmentTypeOptions,
  preferences = [] as LanePreference[],
}: LanePreferenceListProps) => {
  const [selectedModes, setSelectedModes] = useState<{ [key: string]: string }>(
    {}
  )
  const { data: equipmentSizes = [] } = useRfpEquipmentSizes()

  const equipmentSizeOptions = useMemo(
    () =>
      equipmentSizes.map((option) => ({
        _type: option.value,
        label: option.value,
        value: option.value,
      })),
    [equipmentSizes]
  )

  const handleModeSelect = (index: number, event: any) => {
    const modeOption: SelectOption = event.target.value
    const mode = modeOption?.value as string
    setValue('preferences', [
      ...preferences.slice(0, index),
      { ...preferences[index], mode },
      ...preferences.slice(index + 1),
    ])
  }

  const handleEquipmentTypeselect = (index: number, event: any) => {
    const equipmentOption: SelectOption = event.target.value
    const equipmentType = equipmentOption?.value as string
    const mode = preferences[index].mode
    setValue('preferences', [
      ...preferences.slice(0, index),
      { mode, equipment_type: equipmentType },
      ...preferences.slice(index + 1),
    ])
    setValue('equipment_type', equipmentType)
  }

  const handleEquipmentSizeselect = (index: number, event: any) => {
    const equipmentSizeOption: SelectOption = event.target.value
    const equipmentSize = equipmentSizeOption?.value as string
    setValue('preferences', [
      ...preferences.slice(0, index),
      { ...preferences[index], equipment_size: equipmentSize },
      ...preferences.slice(index + 1),
    ])
    setValue('equipment_size', equipmentSize)
  }

  const removePreference = (index: number) => {
    onOptionRemove([
      ...preferences.slice(0, index),
      ...preferences.slice(index + 1),
    ])
  }

  const getPreferenceMode = (preference: LanePreference) => {
    const mode = modeOptions?.filter(
      (modeOption) => modeOption.value === preference.mode
    )[0]
    return mode as SelectOption
  }

  const getPreferenceEquipmentType = (preference: LanePreference) => {
    const equipmentType = equipmentTypeOptions[preference.mode]?.filter(
      (equipment) => {
        return equipment.value === preference.equipment_type
      }
    )[0]
    return equipmentType
  }

  const getPreferenceEquipmentSize = (preference: LanePreference) => {
    const equipmentSize = equipmentSizeOptions.filter((equipment) => {
      return equipment.value === preference.equipment_size
    })[0]
    return equipmentSize
  }

  if (preferences?.length === 0) {
    return null
  }

  return preferences?.map((preference: LanePreference, index: number) => {
    const preferenceKey = `preference-${preference.mode}-${preference.equipment_type}-${index}`

    const equipmentOptions = equipmentTypeOptions[preference.mode]

    const selectMode = (modeIndex: number, event: any) => {
      handleModeSelect(modeIndex, event)
      setSelectedModes({
        ...selectedModes,
        [modeIndex]: event.target.value,
      })
    }

    const enableEquipmentSize = (modeIndex: number) => {
      return (
        selectedModes[modeIndex] === 'DRAY' || preference.mode === 'drayage'
      )
    }

    return (
      <Fragment key={preferenceKey}>
        <Layout.Grid minColumnWidth="20px">
          <ModeComponent
            errors={errors}
            index={index}
            selectMode={selectMode}
            getPreferenceMode={getPreferenceMode}
            preference={preference}
            modeOptions={modeOptions}
          />
          <EquipmentTypeComponent
            errors={errors}
            index={index}
            handleEquipmentTypeselect={handleEquipmentTypeselect}
            preference={preference}
            getPreferenceEquipmentType={getPreferenceEquipmentType}
            equipmentOptions={equipmentOptions}
          />
          <Button
            disabled={preferences.length === 1}
            style={{
              alignSelf: 'end',
              flexWrap: 'nowrap',
              maxWidth: 'fit-content',
            }}
            data-testid={`delete-extra-stop-${index}`}
            variant="icon-secondary"
            onClick={() => removePreference(index)}
          >
            <IconTrash
              width={14}
              height={14}
              aria-label="lane-preference"
              title={null}
            />
          </Button>
        </Layout.Grid>
        <Layout.Grid minColumnWidth="20px">
          <EquipmentSizeComponent
            enableEquipmentSize={enableEquipmentSize(index)}
            errors={errors}
            index={index}
            handleEquipmentSizeselect={handleEquipmentSizeselect}
            getPreferenceEquipmentSize={getPreferenceEquipmentSize}
            preference={preference}
            equipmentSizeOptions={equipmentSizeOptions}
          />
          {/*
              The following components were added to keep the column's aspect ratio consistent.
              SubEquipmentTypeComponent is a placeholder component that will be implemented in a future story.
            */}
          <SubEquipmentTypeComponent />
          <span />
        </Layout.Grid>
        <Divider />
      </Fragment>
    )
  })
}

export default function ModeAndEquipmentsSection({
  control,
  setValue,
  errors,
  watchLanePreferences,
  modeOptions,
  equipmentTypeOptions,
}: ModeAndEquipmentProps) {
  const title = 'Mode and Equipment'

  const handleAddModeEquipment = () => {
    const preference = { mode: '', equipment_type: '' }
    setValue('preferences', [...watchLanePreferences, preference])
  }
  const handleRemoveModeEquipment = (newPreferences: LanePreference[]) => {
    setValue('preferences', newPreferences)
  }

  return (
    <Layout.Box paddingB="spacing-4" padding="none">
      <Card>
        <Card.Title>{title}</Card.Title>
        <Card.Divider />
        <Card.Body>
          <Layout.Stack gap="spacing-6">
            <Controller
              name="preferences"
              control={control}
              render={({ field }) => (
                <LanePreferenceList
                  preferences={field.value as LanePreference[]}
                  setValue={setValue}
                  onOptionRemove={handleRemoveModeEquipment}
                  errors={errors}
                  modeOptions={modeOptions}
                  equipmentTypeOptions={equipmentTypeOptions}
                />
              )}
            />
            <Tooltip
              style={{ width: 'fit-content' }}
              trigger="hover"
              message={
                <>
                  Add additional modes of
                  <div />
                  transportation if this lane involves
                  <div />
                  multiple transportation methods.
                </>
              }
              placement="top-start"
            >
              <Button
                disabled={watchLanePreferences.length === 2}
                style={{ width: 'fit-content' }}
                variant="tertiary"
                onClick={() => handleAddModeEquipment()}
              >
                Add mode
                <IconQuestionCircle
                  width={14}
                  height={14}
                  aria-label="extra-stops"
                  title={null}
                />
              </Button>
            </Tooltip>
          </Layout.Stack>
        </Card.Body>
      </Card>
    </Layout.Box>
  )
}
