import { IconExport } from '@loadsmart/icons'
import {
  EmptyState,
  Layout,
  Radio,
  Text,
  Tooltip,
} from '@loadsmart/miranda-react'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import { useGoToNewFulfillmentWithOrders } from 'fulfillments/hooks/navigation'
import { useIsFulfillmentsFlowEnabled } from 'fulfillments/hooks/useIsFulfillmentsFlowEnabled'
import { defaultTo, uniqBy } from 'lodash'
import { useCallback } from 'react'
import { generatePath, NavLink, useHistory } from 'react-router-dom'
import type { CellProps } from 'react-table'
import { useIsCurrentShipperASupplier } from 'suppliers/common/useIsCurrentShipperASupplier'

import TableActions from '_shared_/components/TableActions'
import NoResultsIllustration from 'assets/illustrations/no-results.svg'
import { useSettings } from 'contexts/settings'
import { useOrderHistoryContext } from 'orders/components/OrderHistory'
import { OrderStatusTag } from 'orders/components/OrderStatusTag'
import { canCreateFulfillmentFromOrder } from 'orders/domain/Order'
import {
  formatOrderDates,
  formatOrderItemDescription,
  formatOrderStop,
} from 'orders/orders.utils'
import type { ListOrder } from 'orders/types'
import { AppRoutes } from 'router/AppRoutes'
import analytics, {
  AnalyticsEvent,
  AnalyticsEventTrigger,
} from 'utils/analytics'

import { formatWeight } from '../../components/ShippingItemsManager/ShippingItems.utils'
import {
  isOrderDeletable,
  useDeleteOrderContext,
} from '../../screens/Orders/common/DeleteOrder'
import { isOrderPlannable } from '../../screens/Orders/common/utils'
import {
  isOrderAutoPlannable,
  useOneClickShipmentCreationContext,
} from '../../screens/Orders/OneClickConsolidation'
import { defaultEmptyString } from '../../utils/strings'
import { useOrdersListContext } from './ListOrders.context'
import type { IdentifierColumns } from './ListOrders.types'
import { ID_COLUMNS_LABEL } from './ListOrders.types'

export function SupplierCell({ row }: CellProps<ListOrder>) {
  return <Text>{defaultEmptyString(row.original.supplier?.name)}</Text>
}

export function CustomerCell({ row }: CellProps<ListOrder>) {
  return <Text>{defaultEmptyString(row.original.customer?.name)}</Text>
}

export function IdentifierHeaderCell() {
  const { identifier, onIdentifierChange } = useOrdersListContext()

  return (
    <Layout.Group gap="spacing-1" align="center" style={{ flexWrap: 'nowrap' }}>
      {ID_COLUMNS_LABEL[identifier].header}
      <TableActions align="start" title="Pick a column">
        {Object.entries(ID_COLUMNS_LABEL).map(([key, { option }]) => (
          <TableActions.Item
            key={key}
            onClick={() => onIdentifierChange(key as IdentifierColumns)}
          >
            <Radio defaultChecked={identifier === key} tabIndex={-1}>
              {option}
            </Radio>
          </TableActions.Item>
        ))}
      </TableActions>
    </Layout.Group>
  )
}

export function IdentifierCell({ row }: CellProps<ListOrder>) {
  const { identifier } = useOrdersListContext()

  return <Text>{defaultTo(row.original[identifier], '-')}</Text>
}

export function StatusCell({ row }: CellProps<ListOrder>) {
  const isSupplier = useIsCurrentShipperASupplier()
  const shipments = row.original['shipments']
  const status = row.original['status']

  return (
    <Layout.Group gap="spacing-1">
      <OrderStatusTag value={status} />

      {!isSupplier && shipments?.length > 0 && (
        <NavLink
          to={{
            pathname: generatePath(AppRoutes.ShipmentDetails, {
              id: shipments[0].uuid,
            }),
          }}
          style={{ textDecoration: 'none' }}
        >
          <IconExport
            color={toCSSValue('color-text-primary')}
            title="Go to shipment"
            width="12px"
          />
        </NavLink>
      )}
    </Layout.Group>
  )
}

export function FulfillmentsCell({ row }: CellProps<ListOrder>) {
  const fulfillments = defaultTo(row.original['fulfillments'], [])
  const fulfillmentsCount = uniqBy(fulfillments, (item) => item.uuid).length

  return <Text>{fulfillmentsCount}</Text>
}

function getLocationCell(type: 'pickup' | 'delivery') {
  return function LocationCell({ row }: CellProps<ListOrder>) {
    return (
      <Layout.Stack gap="none">
        {formatOrderStop(row.original, type, false)}
        <Text variant="body-sm" color="color-text-tertiary">
          {formatOrderDates(row.original, type)}
        </Text>
      </Layout.Stack>
    )
  }
}

export const PickupLocationCell = getLocationCell('pickup')
export const DeliveryLocationCell = getLocationCell('delivery')

export function CommodityCell({ row }: CellProps<ListOrder>) {
  const items = row.original['items']
  const totalWeight = row.original['total_weight']

  if (!items?.length) {
    return '-'
  }

  const defaultedTotalWeight = defaultTo(totalWeight, '0')
  const formattedTotalWeight = Number(defaultedTotalWeight)
    ? formatWeight(defaultedTotalWeight)
    : ''

  if (items.length > 1) {
    return (
      <Layout.Stack gap="none">
        <Tooltip
          message={
            <Layout.Stack gap="none">
              {items.map((item) => (
                <Text key={item.uuid} color="color-text-inverted">
                  {formatOrderItemDescription(item)}
                </Text>
              ))}
            </Layout.Stack>
          }
          placement="top"
          trigger="hover"
          data-testid="commodity-tooltip"
        >
          <Text color="color-text-link" variant="body-md-bold-underline">
            {items.length} commodities
          </Text>
        </Tooltip>
        <Text variant="body-sm" color="color-text-tertiary">
          {formattedTotalWeight}
        </Text>
      </Layout.Stack>
    )
  }

  const item = items[0]

  return (
    <Layout.Stack gap="none">
      {formatOrderItemDescription(item)}
      <Text variant="body-sm" color="color-text-tertiary">
        {formattedTotalWeight}
      </Text>
    </Layout.Stack>
  )
}

export interface OrdersTableEmptyStateProps {
  readonly count?: number
  readonly isLoading?: boolean
}

export function OrdersTableEmptyState({
  count,
  isLoading,
}: OrdersTableEmptyStateProps) {
  if (isLoading || (count && count > 0)) {
    return null
  }

  return (
    <Layout.Stack align="center" style={{ paddingTop: '96px' }}>
      <EmptyState
        data-testid="orders-list-empty-state"
        illustration={NoResultsIllustration}
        message="Change the terms of your search and try again"
        title="No orders found"
        variant="page"
      />
    </Layout.Stack>
  )
}

export function PlanAsShipmentAction({ order }: { readonly order: ListOrder }) {
  const {
    values: [isManualConsolidationEnabled, isPlanPageEnabled],
  } = useSettings([
    'flags.ENABLE_ORDERS_MANUAL_CONSOLIDATION',
    'flags.ENABLE_PLAN_ORDERS_PAGE',
  ])

  const history = useHistory()

  const isPlannable = isOrderPlannable(order)

  let planCaption = 'Create a new shipment including this order'

  if (!isPlannable) {
    planCaption = "Planned orders can't be used to create a new shipment"
  }

  const onClick = useCallback(() => {
    analytics.track(
      AnalyticsEvent.OrdersListLineDropdown,
      AnalyticsEventTrigger.click,
      {
        action: 'create shipment',
        order: order.uuid,
      }
    )
    history.push(
      generatePath(`${AppRoutes.NewShipment}?selected=:orderId`, {
        orderId: order.uuid,
      })
    )
  }, [history, order.uuid])

  if (!isManualConsolidationEnabled || !isPlanPageEnabled) {
    return null
  }

  return (
    <TableActions.Item
      caption={planCaption}
      disabled={!isPlannable}
      onClick={onClick}
    >
      Plan as shipment
    </TableActions.Item>
  )
}

export function AutoPlanAsShipmentAction({
  order,
}: {
  readonly order: ListOrder
}) {
  const { isOneClickShipmentCreationEnabled, openDialog } =
    useOneClickShipmentCreationContext()

  const isPlannable = isOrderPlannable(order)
  const isAutoPlannable = isOrderAutoPlannable(order)

  const onClick = useCallback(() => {
    analytics.track(
      AnalyticsEvent.OrdersListLineDropdown,
      AnalyticsEventTrigger.click,
      {
        action: 'auto create shipment',
        order: order.uuid,
      }
    )
    openDialog([order])
  }, [openDialog, order])

  if (!isOneClickShipmentCreationEnabled) {
    return null
  }

  let autoPlanCaption = 'Automatically create a shipment for this order'

  if (!isPlannable) {
    autoPlanCaption = "Planned orders can't be used to create a new shipment"
  } else if (!isAutoPlannable) {
    autoPlanCaption = 'Order incompatible with available shipment modes'
  }

  return (
    <TableActions.Item
      caption={autoPlanCaption}
      disabled={!isAutoPlannable}
      onClick={onClick}
    >
      Auto plan as shipment
    </TableActions.Item>
  )
}

export function OpenOrderHistoryAction({
  order,
}: {
  readonly order: ListOrder
}) {
  const { openDrawer } = useOrderHistoryContext()

  const onClick = useCallback(() => {
    openDrawer(order.uuid)
  }, [openDrawer, order.uuid])

  return <TableActions.Item onClick={onClick}>View history</TableActions.Item>
}

export function DeleteOrderAction({ order }: { readonly order: ListOrder }) {
  const { openDialog } = useDeleteOrderContext()
  const deletable = isOrderDeletable(order)

  const onClick = useCallback(() => {
    openDialog(order.uuid)
  }, [openDialog, order.uuid])

  return (
    <TableActions.Item
      onClick={onClick}
      disabled={!deletable}
      caption={
        deletable
          ? 'Permanently remove from the list'
          : "Planned orders can't be deleted"
      }
    >
      <Text color="color-text-error">Delete Order</Text>
    </TableActions.Item>
  )
}

export function CreateFulfillmentAction({
  order,
}: {
  readonly order: ListOrder
}) {
  const isFulfillmentsFlowEnabled = useIsFulfillmentsFlowEnabled()
  const goToNewFulfillment = useGoToNewFulfillmentWithOrders()
  const canCreateFulfillment = canCreateFulfillmentFromOrder(order)

  const onClick = useCallback(() => {
    goToNewFulfillment([order.uuid])
  }, [goToNewFulfillment, order.uuid])

  if (!isFulfillmentsFlowEnabled) {
    return null
  }

  return (
    <TableActions.Item
      caption={
        canCreateFulfillment
          ? 'Create a fulfillment for this order'
          : "Planned orders can't be included in fulfillments"
      }
      disabled={!canCreateFulfillment}
      onClick={onClick}
    >
      Create fulfillment...
    </TableActions.Item>
  )
}

export function ActionsCell({ row }: CellProps<ListOrder>) {
  const isFulfillmentsFlowEnabled = useIsFulfillmentsFlowEnabled()

  if (isFulfillmentsFlowEnabled) {
    return (
      <TableActions
        title="Order actions"
        data-testid={`order-actions-${row.index}`}
      >
        <CreateFulfillmentAction order={row.original} />
        <OpenOrderHistoryAction order={row.original} />
        <DeleteOrderAction order={row.original} />
      </TableActions>
    )
  }

  return (
    <TableActions
      title="Order actions"
      data-testid={`order-actions-${row.index}`}
    >
      <PlanAsShipmentAction order={row.original} />
      <AutoPlanAsShipmentAction order={row.original} />
      <OpenOrderHistoryAction order={row.original} />
      <DeleteOrderAction order={row.original} />
    </TableActions>
  )
}

export function SupplierActionsCell({ row }: CellProps<ListOrder>) {
  return (
    <TableActions
      title="Order actions"
      data-testid={`order-actions-${row.index}`}
    >
      <CreateFulfillmentAction order={row.original} />
    </TableActions>
  )
}
