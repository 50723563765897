import * as Yup from 'yup'

import type { LocationOrFacilityV2 } from '_shared_/components/form'
import { REQUIRED_FIELD_MESSAGE } from 'constants/errors'
import { isKilometers } from 'rfp/components/distanceType'

import type {
  AditionalStopProps,
  LaneFormData,
  LaneFormErrorsType,
} from './types'

const MAX_COORDINATE_LENGTH = 9
const ONE_MILE_IN_KILMETERS = 1.60934
const DEFAULT_STOPS_NUMBER = 2

export const LaneFormSchema = Yup.object({
  origin: Yup.object()
    .shape({
      origin_address: Yup.string(),
      origin_city: Yup.string(),
      origin_state: Yup.string(),
      origin_country: Yup.string(),
      origin_zip: Yup.string(),
      origin_facility: Yup.object()
        .shape({
          facility_uuid: Yup.string().nullable(),
          facility_name: Yup.string().nullable(),
          facility_address: Yup.string().nullable(),
        })
        .nullable(),
      origin_lat: Yup.string().nullable(),
      origin_lng: Yup.string().nullable(),
    })
    .test('origin', 'Origin is required', (value) => {
      if (!value) {
        return false
      }
      const originZip = !!value.origin_zip
      const originCityState = !!value.origin_city && !!value.origin_state
      const originFacility =
        !!value.origin_facility?.facility_address &&
        !!value.origin_facility?.facility_name
      return originZip || originCityState || originFacility || false
    })
    .typeError(REQUIRED_FIELD_MESSAGE)
    .required(),
  destination: Yup.object()
    .shape({
      dest_address: Yup.string(),
      dest_city: Yup.string(),
      dest_state: Yup.string(),
      dest_country: Yup.string(),
      dest_zip: Yup.string(),
      dest_facility: Yup.object().shape({
        facility_uuid: Yup.string().nullable(),
        facility_name: Yup.string().nullable(),
        facility_address: Yup.string().nullable(),
      }),
      dest_lat: Yup.string().nullable(),
      dest_lng: Yup.string().nullable(),
    })
    .test('destination', 'Destination is required', (value) => {
      if (!value) {
        return false
      }
      const destinationZip = !!value.dest_zip
      const destinationCityState = !!value.dest_city && !!value.dest_state
      const destinationFacility =
        !!value.dest_facility?.facility_address &&
        !!value.dest_facility?.facility_name
      return (
        destinationZip || destinationCityState || destinationFacility || false
      )
    })
    .typeError(REQUIRED_FIELD_MESSAGE)
    .required(),
  mileage: Yup.number()
    .nullable(true)
    .min(1)
    .integer('Must be an integer')
    .test('min', 'Mileage must be bigger than 0', (value) => {
      if (!value) {
        return false
      }
      return value > 0
    })
    .typeError(REQUIRED_FIELD_MESSAGE),
  mileage_source: Yup.string().nullable(),
  lane_id: Yup.string(),
  additional_stops: Yup.array().of(
    Yup.object().shape({
      city: Yup.string(),
      state: Yup.string(),
      country: Yup.string(),
      zipcode: Yup.string(),
      location: Yup.object().shape({
        lat: Yup.string().nullable(),
        lng: Yup.string().nullable(),
      }),
    })
  ),
  volume: Yup.number()
    .nullable(true)
    .min(1)
    .integer('Must be an integer')
    .test('min', 'Volume must be bigger than 0', (value) => {
      if (!value) {
        return false
      }
      return value > 0
    })
    .typeError(REQUIRED_FIELD_MESSAGE),
  notes: Yup.string().max(280),
  target_rate: Yup.number().nullable(),
  preferences: Yup.array()
    .of(
      Yup.object()
        .shape({
          mode: Yup.string().required().min(1),
          equipment_type: Yup.string().required().min(1),
          extra_fields: Yup.object().shape({}).nullable(),
          equipment_size: Yup.string().test(
            'required if mode is drayage',
            'Required',
            (value, context) => {
              if (context.parent.mode === 'drayage') {
                return value !== undefined
              }
              return true
            }
          ),
        })
        .required()
    )
    .required()
    .min(1),
  equipment_type: Yup.string().nullable(),
  equipment_size: Yup.string().nullable(),
  extra_fields: Yup.object().shape({}).nullable(),
})

const defaultOriginValues = {
  origin_address: '',
  origin_city: '',
  origin_state: '',
  origin_country: '',
  origin_zip: '',
  origin_facility: {
    facility_uuid: '',
    facility_name: '',
    facility_address: '',
  },
  origin_lat: '',
  origin_lng: '',
}

const defaultDestValues = {
  dest_address: '',
  dest_city: '',
  dest_state: '',
  dest_country: '',
  dest_zip: '',
  dest_facility: {
    facility_uuid: '',
    facility_name: '',
    facility_address: '',
  },
  dest_lat: '',
  dest_lng: '',
}

export const getRawInitialValues = () => {
  return {
    origin: defaultOriginValues,
    destination: defaultDestValues,
    lane_id: '',
    additional_stops: [],
    mileage: undefined,
    mileage_source: '',
    volume: undefined,
    notes: '',
    target_rate: undefined,
    preferences: [{ mode: '', equipment_type: '' }],
    equipment_type: '',
    equipment_size: '',
    extra_fields: {},
  }
}

const getOriginAddress = (lane: LaneStop) => {
  return `${lane.city}, ${lane.state} ${lane.zip_code}, ${lane.country}`
}

const getDestinationAddress = (lane: LaneStop) => {
  return `${lane.city}, ${lane.state} ${lane.zip_code}, ${lane.country}`
}

const loadAditionalStopsData = (stops: any) => {
  if (stops.length <= DEFAULT_STOPS_NUMBER) {
    return []
  }
  const aditionalStops: any[] = stops.slice(1, stops.length - 1)
  return aditionalStops.map((stop) => {
    return {
      address: stop.facility_address,
      city: stop.city,
      state: stop.state,
      country: stop.country,
      zipcode: stop.zipcode || stop.zip_code,
      lat: stop.latitude,
      lng: stop.longitude,
      uuid: stop.facility_uuid,
      name: stop.facility_name,
    }
  })
}

const getOringDatabaseValues = (lane: any) => {
  return {
    origin_address: lane.stops[0].address || getOriginAddress(lane.stops[0]),
    origin_city: lane.stops[0].city,
    origin_state: lane.stops[0].state,
    origin_country: lane.stops[0].country,
    origin_zip: lane.stops[0].zip_code,
    origin_facility: {
      facility_uuid: lane.stops[0].facility?.uuid || undefined,
      facility_name:
        lane.stops[0].facility?.name || lane.stops[0].facility_name,
      facility_address: lane.stops[0].facility?.address || undefined,
    },
    origin_lat: lane.stops[0].lat?.toString() || '',
    origin_lng: lane.stops[0].lng?.toString() || '',
  }
}

const getDestinationDatabaseValues = (lane: any) => {
  return {
    dest_address:
      lane.stops[lane.stops.length - 1].address ||
      getDestinationAddress(lane.stops[lane.stops.length - 1]),
    dest_city: lane.stops[lane.stops.length - 1].city,
    dest_state: lane.stops[lane.stops.length - 1].state,
    dest_country: lane.stops[lane.stops.length - 1].country,
    dest_zip: lane.stops[lane.stops.length - 1].zip_code,
    dest_facility: {
      facility_uuid:
        lane.stops[lane.stops.length - 1].facility?.uuid || undefined,
      facility_name:
        lane.stops[lane.stops.length - 1].facility?.name ||
        lane.stops[lane.stops.length - 1].facility_name,
      facility_address:
        lane.stops[lane.stops.length - 1].facility?.address || undefined,
    },
    dest_lat: lane.stops[lane.stops.length - 1].lat?.toString() || '',
    dest_lng: lane.stops[lane.stops.length - 1].lng?.toString() || '',
  }
}

const getMileage = (
  rfpDistanceType?: string,
  mileage?: number,
  mileage_kilometers?: number
) => {
  return rfpDistanceType && isKilometers(rfpDistanceType)
    ? mileage_kilometers
    : mileage
}

const getLanePreferences = (lane: Lane) => {
  return lane.preferences.map((preference) => ({
    mode: preference.mode,
    equipment_type: preference.equipment_type,
    equipment_size: preference.equipment_size || '',
    extra_fields: preference.extra_fields || {},
  }))
}

export const getDatabaseValues = (lane?: Lane, rfpDistanceType?: string) => {
  if (lane) {
    return {
      origin: getOringDatabaseValues(lane),
      destination: getDestinationDatabaseValues(lane),
      lane_id: lane.lane_id,
      additional_stops: loadAditionalStopsData(lane.stops) || [],
      mileage: getMileage(
        rfpDistanceType,
        lane.mileage,
        lane.mileage_kilometers
      ),
      mileage_source: lane.mileage_source,
      volume: lane.volume,
      notes: lane.notes || '',
      target_rate: lane.target_rate || null,
      preferences: getLanePreferences(lane),
      equipment_type: lane.equipment_type || '',
      equipment_size: lane.equipment_size || '',
      extra_fields: lane.extra_fields || {},
    }
  }

  return getRawInitialValues()
}

export const isOriginReady = (
  origin: Partial<LaneFormData['origin']>,
  errors: Partial<LaneFormErrorsType>
): boolean => {
  const originCityAndStateArePresent = origin.origin_city && origin.origin_state
  return !!(
    (originCityAndStateArePresent || origin.origin_zip) &&
    !errors.origin
  )
}

export const isDestReady = (
  destination: Partial<LaneFormData['destination']>,
  errors: Partial<LaneFormErrorsType>
): boolean => {
  const destinationCityAndStateArePresent =
    destination.dest_city && destination.dest_state

  return !!(
    (destinationCityAndStateArePresent || destination.dest_zip) &&
    !errors.destination
  )
}

export const canCalculateMileage = (
  mileage: number | undefined | null,
  mileage_source: string | undefined | null,
  origin: Partial<LaneFormData['origin']>,
  destination: Partial<LaneFormData['destination']>,
  errors: LaneFormErrorsType
) => {
  return !(
    (mileage && mileage_source === 'manual') ||
    !isOriginReady(origin, errors) ||
    !isDestReady(destination, errors)
  )
}

const removeEmptyStops = (stops: LocationOrFacilityV2[]) => {
  return stops.filter((stop) => {
    // Matching logic with backend implementation
    return (stop.city !== '' && stop.state !== '') || stop.zipcode !== ''
  })
}

export const getExtraStops = (extraStops: LocationOrFacilityV2[]) => {
  const filteredStops = removeEmptyStops(extraStops)

  if (filteredStops.length === 0) {
    return []
  }

  return filteredStops.map((stop) => ({
    city: stop.city,
    state: stop.state,
    zip_code: stop.zipcode,
  }))
}

const getLat = (
  stop: Partial<LaneFormData['origin']> & Partial<LaneFormData['destination']>
) => {
  return stop.origin_lat || stop.dest_lat
}

const getLng = (
  stop: Partial<LaneFormData['origin']> & Partial<LaneFormData['destination']>
) => {
  return stop.origin_lng || stop.dest_lng
}

const getAddress = (
  stop: Partial<LaneFormData['origin']> & Partial<LaneFormData['destination']>
) => {
  return stop.origin_address || stop.dest_address
}

const getCity = (
  stop: Partial<LaneFormData['origin']> & Partial<LaneFormData['destination']>
) => {
  return stop.origin_city || stop.dest_city
}

const getState = (
  stop: Partial<LaneFormData['origin']> & Partial<LaneFormData['destination']>
) => {
  return stop.origin_state || stop.dest_state
}

const getZipCode = (
  stop: Partial<LaneFormData['origin']> & Partial<LaneFormData['destination']>
) => {
  return stop.origin_zip || stop.dest_zip || null
}

const getCountry = (
  stop: Partial<LaneFormData['origin']> & Partial<LaneFormData['destination']>
) => {
  return stop.origin_country || stop.dest_country
}

const getFacility = (
  stop: Partial<LaneFormData['origin']> & Partial<LaneFormData['destination']>
) => {
  return {
    facility_uuid:
      stop.origin_facility?.facility_uuid ||
      stop.dest_facility?.facility_uuid ||
      null,
    facility_name:
      stop.origin_facility?.facility_name ||
      stop.dest_facility?.facility_name ||
      null,
    facility_address:
      stop.origin_facility?.facility_address ||
      stop.dest_facility?.facility_address ||
      null,
  }
}

const getStop = (
  stop: Partial<LaneFormData['origin']> & Partial<LaneFormData['destination']>
) => {
  const lat = getLat(stop)
  const long = getLng(stop)

  return {
    address: getAddress(stop),
    city: getCity(stop),
    state: getState(stop),
    zip_code: getZipCode(stop),
    country: getCountry(stop),
    latitude:
      lat && lat.toString().length > MAX_COORDINATE_LENGTH
        ? lat.toString().substring(0, MAX_COORDINATE_LENGTH)
        : lat,
    longitude:
      long && long.toString().length > MAX_COORDINATE_LENGTH
        ? long.toString().substring(0, MAX_COORDINATE_LENGTH)
        : long,
    ...getFacility(stop),
  }
}

const getMileageInKilometers = (mileage: number) => {
  return mileage * ONE_MILE_IN_KILMETERS
}

const parseAditionalStops = (stops: AditionalStopProps[]) => {
  return stops.map((stop) => {
    const lat = stop.location?.lat || stop.lat
    const long = stop.location?.lng || stop.lng

    return {
      address: stop.address,
      city: stop.city,
      state: stop.state,
      zip_code: stop.zipcode,
      country: stop.country,
      latitude:
        lat && lat.toString().length > MAX_COORDINATE_LENGTH
          ? lat.toString().substring(0, MAX_COORDINATE_LENGTH)
          : lat,
      longitude:
        long && long.toString().length > MAX_COORDINATE_LENGTH
          ? long.toString().substring(0, MAX_COORDINATE_LENGTH)
          : long,
      facility_uuid: stop.uuid,
      facility_name: stop.name,
      facility_address: stop.address,
    }
  })
}

export const parseLaneFormValuesToPayload = (data: LaneFormData) => {
  return {
    origin_address: data.origin.origin_address,
    origin_city: data.origin.origin_city,
    origin_state: data.origin.origin_state,
    origin_zip: data.origin.origin_zip,
    origin_country: data.origin.origin_country,
    origin_latitude: data.origin.origin_lat,
    origin_longitude: data.origin.origin_lng,
    origin_facility: getFacility(data.origin),
    dest_address: data.destination.dest_address,
    dest_city: data.destination.dest_city,
    dest_state: data.destination.dest_state,
    dest_zip: data.destination.dest_zip,
    dest_country: data.destination.dest_country,
    dest_latitude: data.destination.dest_lat,
    dest_longitude: data.destination.dest_lng,
    dest_facility: getFacility(data.destination),
    lane_id: data.lane_id,
    mileage: data.mileage,
    mileage_source: data.mileage_source || 'manual',
    volume: data.volume,
    equipment_size: data.equipment_size,
    equipment_type: data.equipment_type,
    notes: data.notes,
    target_rate: data.target_rate,
    extra_fields: data.extra_fields,
    additional_stops: data.additional_stops,
    delivery_city: data.destination.dest_city,
    delivery_country: data.destination.dest_country,
    delivery_latitude: data.destination.dest_lat,
    delivery_longitude: data.destination.dest_lng,
    delivery_state: data.destination.dest_state,
    delivery_zip_code: data.destination.dest_zip,
    mileage_kilometers: getMileageInKilometers(data.mileage),
    pickup_city: data.origin.origin_city,
    pickup_country: data.origin.origin_country,
    pickup_latitude: data.origin.origin_lat,
    pickup_longitude: data.origin.origin_lng,
    pickup_state: data.origin.origin_state,
    pickup_zip_code: data.origin.origin_zip,
    stops: [
      getStop(data.origin),
      ...parseAditionalStops(data.additional_stops),
      getStop(data.destination),
    ],
    preferences: data.preferences,
  }
}
