import { Themes } from '@loadsmart/loadsmart-ui/dist/theming'
import * as Sentry from '@sentry/react'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import { FeatureFlagsProvider } from '_shared_/feature-flag'
import { GuidedTourStylesheet } from '_shared_/guided-tour/useGuidedTour'
import { UserProvider } from '_shared_/user/UserProvider'
import BeamerLoader from 'components/BeamerLoader'
import { EventBasedContactsManagerDrawer } from 'components/ContactsDrawer/EventBasedContactsManagerDrawer'
import {
  EventBasedCreateFacilityDrawer,
  EventBasedFacilityManagerDrawer,
} from 'components/FacilityDrawer'
import Logout from 'components/Logout'
import { Toast } from 'components/ToastContainer'
import { SettingsProvider } from 'contexts/settings'
import { AppRoutes } from 'router/AppRoutes'
import { Router } from 'router/Router'
import { GlobalStyle } from 'styles/global'
import { MirandaCompatFix, MirandaOverrides, theme } from 'styles/theme'
import queryClient from 'utils/queryClient'

import '@loadsmart/miranda-react/dist/styles/variables.css'
import '@loadsmart/strangercodes/style.css'
import 'react-datepicker/dist/react-datepicker.min.css'
import 'react-toastify/dist/ReactToastify.css'
import 'driver.js/dist/driver.css'

function App() {
  return (
    <Sentry.ErrorBoundary fallback={<div>An error has occurred</div>}>
      <GuidedTourStylesheet />
      <BrowserRouter>
        <ThemeProvider
          theme={{
            ...Themes.Miranda,
            ...theme,
            ...MirandaCompatFix,
            ...MirandaOverrides,
          }}
        >
          <Toast />
          <GlobalStyle />
          <FeatureFlagsProvider>
            <QueryClientProvider client={queryClient}>
              <Switch>
                <Route exact path={AppRoutes.Logout}>
                  <Logout />
                </Route>
              </Switch>
              <UserProvider>
                <SettingsProvider>
                  <EventBasedCreateFacilityDrawer />
                  <EventBasedFacilityManagerDrawer />
                  <EventBasedContactsManagerDrawer />
                  <Router />
                  <ReactQueryDevtools initialIsOpen={false} />
                  <BeamerLoader />
                </SettingsProvider>
              </UserProvider>
            </QueryClientProvider>
          </FeatureFlagsProvider>
        </ThemeProvider>
      </BrowserRouter>
    </Sentry.ErrorBoundary>
  )
}

export default App
