import { Dialog } from '@loadsmart/loadsmart-ui'
import type { ChangeEvent } from 'react'
import { useCallback, useState } from 'react'

import { Subtitle, TextArea, RemainingChars } from './styles'

interface Props {
  readonly close: () => void
  readonly save: (note: string) => any
  readonly isOpen: boolean
}

export default function CounterOfferModal({ close, isOpen, save }: Props) {
  const [notes, setNotes] = useState('')
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setNotes(event.target.value)
    },
    [setNotes]
  )

  return (
    <Dialog open={isOpen} data-testid="counter-offer-modal">
      <Dialog.Header>Make Counter Offer</Dialog.Header>
      <Dialog.Body>
        <Subtitle>
          Write a short message to provide context on your counter offer.{' '}
          <b>Your carrier will be able to submit a new offer</b> even if the
          deadline is expired
        </Subtitle>
        <TextArea
          placeholder="I would like to make a counter offer."
          onChange={handleChange}
          value={notes}
          maxLength={150}
        />
        <RemainingChars>{150 - notes.length} characters left</RemainingChars>
      </Dialog.Body>
      <Dialog.ActionConfirm
        disabled={notes.length <= 0}
        onConfirm={() => save(notes)}
      >
        SEND COUNTER OFFER
      </Dialog.ActionConfirm>
      <Dialog.ActionCancel onCancel={close} />
    </Dialog>
  )
}
