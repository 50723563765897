import type { Dispatch, SetStateAction } from 'react'

import {
  getIncumbentCarrierColumn,
  getRateOptionColumn,
} from 'rfp/components/table/columns'
import type { Currency } from 'rfp/rfp.types'

export default function makeDraftColumns({
  optionsDict,
  rateOption,
  availableRateOptions,
  setRadioOptionTwo,
  displayMultiCurrency,
  rfpCurrency,
}: {
  optionsDict: Record<string, string>
  rateOption: string
  availableRateOptions: {
    value: string
    label: string
  }[]
  setRadioOptionTwo: Dispatch<SetStateAction<string>>
  displayMultiCurrency: boolean
  rfpCurrency: Currency
}) {
  return [
    getIncumbentCarrierColumn(),
    getRateOptionColumn(
      optionsDict,
      rateOption,
      availableRateOptions,
      setRadioOptionTwo,
      displayMultiCurrency,
      rfpCurrency
    ),
  ]
}
