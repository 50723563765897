import type { RouteComponentProps } from 'react-router-dom'
import { Route, Switch } from 'react-router-dom'

import ShipperRFPAdd from './rfp-add'
import ShipperRFPDetails from './rfp-details'
import ShipperRFPEdit from './rfp-edit'

export function RfpRoutes({ match }: Readonly<RouteComponentProps>) {
  const { path } = match

  return (
    <Switch>
      <Route path={`${path}/add`} exact component={ShipperRFPAdd} />
      <Route path={`${path}/:rfpId`} exact component={ShipperRFPDetails} />
      <Route
        path={`${path}/:rfpId/download_report`}
        exact
        component={ShipperRFPDetails}
      />
      <Route path={`${path}/:rfpId/edit`} exact component={ShipperRFPEdit} />
    </Switch>
  )
}
