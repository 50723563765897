import { Layout, Text, Tooltip, TooltipPosition } from '@loadsmart/loadsmart-ui'
import { useState } from 'react'

import StringLimiter from '_shared_/components/StringLimiter'
import LaneStatusTag from 'components/LaneStatusTag'
import {
  ActionFooter,
  ShowHideAction,
  StyledCard,
  StyledBody,
  StyledSeparator,
} from 'components/SideBar/styles'
import {
  getDistanceTypeLabel,
  getLaneMileage,
} from 'rfp/components/distanceType'
import type { RequestForProposal } from 'rfp/rfp.types'
import { formatAddress, getAdditionalStops } from 'utils/address'

import {
  InfoWrapper,
  InfoItem,
  Address,
  StyledTitle,
  StopBadge,
} from './styles'

const getLaneValues = (
  lane: Lane | undefined,
  laneOverview: LaneManagement | undefined,
  rfpDistanceType?: RequestForProposal['distance_type']
) => {
  const laneData = lane || laneOverview

  return {
    id: lane ? lane.id : 0,
    extraFields: lane?.extra_fields ? Object.values(lane.extra_fields) : [],
    laneData,
    mileage:
      (lane && rfpDistanceType
        ? getLaneMileage(rfpDistanceType, lane)
        : laneData?.mileage) ?? 0,
    equipmentType: laneData?.equipment_type ?? '',
    equipmentSize: laneData?.equipment_size ?? '',
  }
}

const LaneExtraInfo = ({
  lane,
  show,
  extraFields,
}: {
  readonly lane: Lane | undefined
  readonly show: boolean
  readonly extraFields: string[]
}) => {
  if (!(lane && show)) {
    return null
  }

  return (
    <StyledBody>
      <InfoItem style={{ padding: '0 30px' }}>
        <Text color="color-neutral" variant="chips-sm">
          NOTES
        </Text>
        <Text color="color-neutral-darker" variant="body-bold">
          {lane.notes || ''}
        </Text>
      </InfoItem>
      {extraFields.length > 0 &&
        Object.values(extraFields).find((field) => field !== '') && (
          <>
            <StyledSeparator />
            <StyledTitle>Additional Fields</StyledTitle>
            <InfoWrapper style={{ flexWrap: 'wrap' }}>
              {Object.entries(lane.extra_fields).map(([key, value]) => {
                return (
                  <InfoItem key={key} style={{ width: 'calc(50% - 40px)' }}>
                    <Text color="color-neutral" variant="chips-sm">
                      {key}
                    </Text>
                    <Text color="color-neutral-darker" variant="body-bold">
                      {value}
                    </Text>
                  </InfoItem>
                )
              })}
            </InfoWrapper>
          </>
        )}
    </StyledBody>
  )
}

const getOrigin = (laneData: Lane | LaneManagement) => {
  const [origin] = laneData.stops
  return formatAddress(origin.city, origin.state, origin.zip_code)
}

const getDestination = (laneData: Lane | LaneManagement) => {
  const [destination] = laneData.stops.slice(-1)
  return formatAddress(
    destination.city,
    destination.state,
    destination.zip_code
  )
}

const LaneOriginAndDestinationInfo = ({
  laneData,
  id,
}: {
  readonly laneData: Lane | LaneManagement
  readonly id: number
}) => {
  const origin = getOrigin(laneData)
  const destination = getDestination(laneData)
  const stops = getAdditionalStops(laneData.stops ?? [])

  return (
    <>
      <Text variant="heading-sm-bold">
        {origin}
        &nbsp;&nbsp;→&nbsp;&nbsp;
        {destination}
      </Text>
      {stops.length > 0 && (
        <Tooltip
          position={TooltipPosition.Bottom}
          message={stops.map((value: Address) => (
            <Address
              key={`routing-guide-stops-${value.city}-${value.state}-${value.zipcode}`}
            >
              {formatAddress(value.city, value.state, value.zipcode)}
            </Address>
          ))}
        >
          <StopBadge
            variant="secondary"
            size="normal"
            data-tip
            data-for={`additionalStopsTooltip${id}`}
          >
            <b>+{stops.length}</b>
          </StopBadge>
        </Tooltip>
      )}
    </>
  )
}

export default function LaneInfoV2({
  lane,
  laneOverview,
  rfpDistanceType,
}: {
  readonly lane?: Lane
  readonly laneOverview?: LaneManagement
  readonly rfpDistanceType?: RequestForProposal['distance_type']
}) {
  const [show, setShow] = useState<boolean>(false)

  const { id, extraFields, laneData, mileage } = getLaneValues(
    lane,
    laneOverview,
    rfpDistanceType
  )

  if (!laneData) {
    return null
  }

  return (
    <StyledCard data-testid="header">
      <StyledTitle>
        <Layout.Group space="m" align="center">
          <LaneOriginAndDestinationInfo id={id} laneData={laneData} />
          {laneOverview && <LaneStatusTag status={laneOverview.group_status} />}
        </Layout.Group>
      </StyledTitle>
      <InfoWrapper data-testid="lane-info-wrapper">
        <InfoItem withoutBottomMargin={!!laneOverview}>
          <Text color="color-neutral" variant="chips-sm">
            {lane && rfpDistanceType
              ? getDistanceTypeLabel(rfpDistanceType)
              : 'MILES'}
          </Text>
          <Text color="color-neutral-darker" variant="body-bold">
            {mileage}
          </Text>
        </InfoItem>
        {lane && (
          <>
            <InfoItem>
              <Text color="color-neutral" variant="chips-sm">
                VOLUME
              </Text>
              <Text color="color-neutral-darker" variant="body-bold">
                {lane.volume}
              </Text>
            </InfoItem>
            <InfoItem>
              <Text color="color-neutral" variant="chips-sm">
                LANE ID
              </Text>
              <Text color="color-neutral-darker" variant="body-bold">
                <StringLimiter value={lane.lane_id} limiter={18} />
              </Text>
            </InfoItem>
          </>
        )}
      </InfoWrapper>
      <LaneExtraInfo extraFields={extraFields} lane={lane} show={show} />
      {lane && (
        <ActionFooter>
          {show ? (
            <ShowHideAction onClick={() => setShow(!show)}>
              Show Less
            </ShowHideAction>
          ) : (
            <ShowHideAction onClick={() => setShow(!show)}>
              Show More
            </ShowHideAction>
          )}
        </ActionFooter>
      )}
    </StyledCard>
  )
}
