import { IconQuestionCircle } from '@loadsmart/icons'
import { Layout, Tooltip } from '@loadsmart/loadsmart-ui'
import { Button, Checkbox, Icon } from '@loadsmart/miranda-react'
import type { FieldArrayRenderProps } from 'formik'
import { FieldArray, getIn, useFormikContext } from 'formik'

import { InputGroup, Error } from '_shared_/components/form/styles'
import { Input, Label } from 'components/Input'
import { theme } from 'styles/theme'

import type { CarrierFormValue } from '../types'

const MIN_FIELD_QUANTITY = 1
const MAX_FIELD_QUANTITY = 5

function createNextFieldIfItsNotCreated(
  fieldsQuantity: number,
  index: number,
  arrayHelpers: FieldArrayRenderProps
) {
  if (
    /**
     * We should create the next input just once, so we should check the quantity
     */
    fieldsQuantity === index + 1 &&
    fieldsQuantity < MAX_FIELD_QUANTITY
  ) {
    arrayHelpers.push({
      email: '',
      active: false,
    })
  }
}

function removeFieldKeepingOne(
  quantityOfFields: number,
  index: number,
  arrayHelpers: FieldArrayRenderProps
) {
  // We should keep at least 1 input visible
  if (quantityOfFields > MIN_FIELD_QUANTITY) {
    arrayHelpers.remove(index)
  }
}

/**
 * We need this to use in the map below
 * and also we cannot create it on the fly (inside React render method)
 * because it would never match between re-renders.
 */
const StableKeys = [
  crypto.randomUUID(),
  crypto.randomUUID(),
  crypto.randomUUID(),
  crypto.randomUUID(),
  crypto.randomUUID(),
]

/**
 * Naming this component "Legacy..." just because
 * we can't use Miranda components to match the Form look and feel.
 */
const LegacyAlternativeEmailsField = () => {
  const { values, errors } = useFormikContext<CarrierFormValue>()

  return (
    <FieldArray
      name="alternative_emails"
      render={(arrayHelpers) => {
        return values.alternative_emails?.map((value, index) => {
          return (
            <InputGroup span={6} key={`input-group-${StableKeys[index]}`}>
              <Layout.Stack space="none">
                {index === 0 && (
                  <Label>
                    {[
                      <span key={`span-${StableKeys[index]}`}>
                        Additional emails
                        <Tooltip
                          message={
                            <>
                              Manage multiple carrier emails
                              <br /> and control which receive
                              <br /> notifications from ShipperGuide.
                            </>
                          }
                          style={{ marginLeft: 5 }}
                        >
                          <IconQuestionCircle
                            height={14}
                            width={14}
                            fill={theme.colors.neutral}
                            title={null}
                          />
                        </Tooltip>
                      </span>,
                    ]}
                  </Label>
                )}
                <Layout.Group align="center" space="l">
                  <Input
                    style={{
                      marginTop: '0',
                      width: 'auto',
                      flexGrow: '1',
                    }}
                    name={`alternative_emails.${index}`}
                    aria-label={`Additional email ${index}`}
                    value={value.email}
                    maxLength={50}
                    onChange={(event) => {
                      arrayHelpers.replace(index, {
                        email: event.target.value,
                        active:
                          event.target.value === '' ? false : value.active,
                      })

                      if (event.target.value !== '') {
                        createNextFieldIfItsNotCreated(
                          values.alternative_emails.length,
                          index,
                          arrayHelpers
                        )
                      } else {
                        removeFieldKeepingOne(
                          values.alternative_emails.length,
                          index,
                          arrayHelpers
                        )
                      }
                    }}
                  />
                  <Layout.Group align="center" space="2xs">
                    <Checkbox
                      checked={value.active}
                      aria-label={`Toggle send emails ${index}`}
                      onChange={() => {
                        arrayHelpers.replace(index, {
                          email: value.email,
                          active: !value.active,
                        })
                      }}
                    >
                      Send emails
                    </Checkbox>
                    <Tooltip
                      message={
                        <>
                          If checked, this email will receive
                          <br /> notifications from ShipperGuide.
                        </>
                      }
                    >
                      <Icon name="question" size="16px" />
                    </Tooltip>
                  </Layout.Group>
                  <Button
                    variant="icon-secondary"
                    size="small"
                    disabled={values.alternative_emails.length === 1}
                    aria-label={`Remove additional email ${index}`}
                    onClick={() => {
                      arrayHelpers.remove(index)
                    }}
                  >
                    <Icon
                      name="trash"
                      size="16px"
                      color={
                        values.alternative_emails.length === 1
                          ? 'color-text-disabled'
                          : 'color-text-primary'
                      }
                    />
                  </Button>
                </Layout.Group>
                <Error className="error-message">
                  {/* Riva found out that the regular ErrorMessage doesn't work here
                  because we are passing a custom validate to formik. See this
                  comment for more info https://github.com/loadsmart/rfp-web/pull/5421#discussion_r1660981156 */}
                  {getIn(errors, `alternative_emails[${index}].email`)}
                </Error>
              </Layout.Stack>
            </InputGroup>
          )
        })
      }}
    />
  )
}

export default LegacyAlternativeEmailsField
