import {
  STANDARD_PALLETS_LENGTH,
  STANDARD_PALLETS_WIDTH,
} from 'utils/constants'
import { getOptionFromMap } from 'utils/selectOption'

import {
  PACKAGE_TYPES_OPTIONS_MAP,
  FREIGHT_CLASSES_OPTIONS_MAP,
  COMMODITY_PACKAGE_TYPES_OPTIONS_MAP,
} from './ShippingItems.constants'
import type {
  Measurable,
  PackageType,
  TransientShippingItem,
} from './ShippingItems.types'

// for handling unit
export function getPackageTypeOption(packageType: PackageType | null | '') {
  return getOptionFromMap(packageType, PACKAGE_TYPES_OPTIONS_MAP)
}

// for commodity in each handling unit / hazmat commodities
export function getCommodityPackageTypeOption(
  packageType: PackageType | null | ''
) {
  return getOptionFromMap(packageType, COMMODITY_PACKAGE_TYPES_OPTIONS_MAP)
}

export function getFreightClassOption(freightClass: string | null) {
  if (!freightClass || !FREIGHT_CLASSES_OPTIONS_MAP.has(freightClass)) {
    return null
  }

  return FREIGHT_CLASSES_OPTIONS_MAP.get(freightClass)
}

export function hasStandardPalletDimmensions(
  item: Pick<TransientShippingItem, 'length' | 'width'>
): boolean {
  const { width, length } = item

  return (
    Number(width) === STANDARD_PALLETS_WIDTH &&
    Number(length) === STANDARD_PALLETS_LENGTH
  )
}

/**
 * For LTL shipments all handling units are expected to be under 96 inches (8ft) in length or width.
 *
 * @param dimension a number representing the length or width in inches
 * @returns whether the length or width is higher or equal than 96 inches
 */
export const isExcessiveDimension = (
  dimension: number | string | null | undefined
): boolean => {
  if (!dimension) {
    return false
  }

  const LTL_MAX_DIMENSION_IN_INCHES = 96
  const parsedDimension = Number.parseInt(String(dimension), 10)

  return parsedDimension >= LTL_MAX_DIMENSION_IN_INCHES
}

export const getHighestDimensionIndex = (
  items: Pick<Measurable, 'length' | 'width'>[]
): number | null => {
  let highestIndex: number | null = null
  let maxDimension = 0

  for (let index = 0; index < items.length; index++) {
    const item = items[index]
    const parsedLength = item.length
      ? Number.parseInt(String(item.length), 10)
      : 0
    const parsedWidth = item.width ? Number.parseInt(String(item.width), 10) : 0
    const currentMaxDimension = Math.max(parsedLength, parsedWidth)

    if (currentMaxDimension > maxDimension) {
      maxDimension = currentMaxDimension
      highestIndex = index
    }
  }

  return highestIndex
}

export const hasTheHighestExcessiveDimension = (
  items: Pick<Measurable, 'length' | 'width'>[],
  index: number
): boolean => {
  if (items.length === 0) {
    return false
  }

  const item = items[index]
  const parsedLength = item.length
    ? Number.parseInt(String(item.length), 10)
    : 0
  const parsedWidth = item.width ? Number.parseInt(String(item.width), 10) : 0
  const maxDimension = Math.max(parsedLength, parsedWidth)

  if (!isExcessiveDimension(maxDimension)) {
    return false
  }

  const highestDimensionIndex = getHighestDimensionIndex(items)

  return highestDimensionIndex === index
}

// TO-DO: remove these two functions below once ENABLE_EXCESSIVE_WIDTH_RULE is removed
export const getHighestLengthIndex = (
  items: Pick<Measurable, 'length'>[]
): number | null => {
  return items.reduce<number | null>((highestIndex, item, index) => {
    const parsedLength = item.length
      ? Number.parseInt(String(item.length), 10)
      : 0

    if (highestIndex === null) {
      return parsedLength > 0 ? index : null
    }

    const highestLength = items[highestIndex].length
    const parsedHighestLength = highestLength
      ? Number.parseInt(String(highestLength), 10)
      : 0

    if (parsedLength > parsedHighestLength) {
      return index
    }

    return highestIndex
  }, null)
}

export const hasTheHighestExcessiveLength = (
  items: Pick<Measurable, 'length'>[],
  index: number
) => {
  if (items.length === 0 || !isExcessiveDimension(items[index].length)) {
    return false
  }

  const highestLengthIndex = getHighestLengthIndex(items)

  return highestLengthIndex === index
}
