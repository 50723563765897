import styled from 'styled-components'

import BaseTextArea from 'components/Input/TextArea'

export const RemainingChars = styled.div`
  color: ${({ theme }) => theme.colors.neutralDark};
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 40px;
  text-align: left;
  width: 100%;
`

export const Subtitle = styled.h2`
  color: ${({ theme }) => theme.colors.black};
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
  text-align: left;
`

export const TextArea = styled(BaseTextArea)`
  box-shadow: ${({ theme }) => theme.shadows.standard};
  margin: 10px 0 15px 0;
  min-height: 100px;
  resize: none;
  width: 100%;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.neutralLighter};
    border-radius: 20px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.neutral};
    border-radius: 20px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.neutralDarker};
  }
`
