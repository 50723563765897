import type { MirandaChangeEvent } from '@loadsmart/miranda-react'
import { Field, TextArea } from '@loadsmart/miranda-react'
import type { TextArea as TestAreaWC } from '@loadsmart/miranda-wc'
import { useFulfillmentFormContext } from 'fulfillments/components/FulfillmentFormContext'
import { useFulfillmentField } from 'fulfillments/create/hooks/useFulfillmentField'
import { defaultTo } from 'lodash'

import { getTransientError } from 'utils/transient'

import { FormSection } from './FormSection'

export function FulfillmentNotes() {
  const { fulfillment, setPartialFulfillment } = useFulfillmentFormContext()
  const { fieldProps, hintProps } = useFulfillmentField({
    name: 'notes',
    hint: 'Convey additional information or instructions directly to the party executing the freight',
    meta: fulfillment._metadata,
  })

  const error = getTransientError(fulfillment, 'notes')

  function handleNotesChange(event: MirandaChangeEvent<TestAreaWC>) {
    setPartialFulfillment({ notes: event.target.value.trim() })
  }

  const value = defaultTo(fulfillment.notes, '')

  return (
    <FormSection id="fulfillment-notes" title="Notes" errors={Number(error)}>
      <Field {...fieldProps}>
        <TextArea
          aria-label="Notes"
          name="notes"
          placeholder="Enter notes..."
          onChange={handleNotesChange}
          value={value}
          style={{ width: '100%' }}
        />
        <Field.Hint {...hintProps} />
      </Field>
    </FormSection>
  )
}
