import { Layout, Text } from '@loadsmart/miranda-react'

import {
  getExportUserCellCreationDate,
  getExportUserCellFullName,
} from './presenters'

export const ExportUserCell = ({
  firstName,
  lastName,
  email,
  createdAt,
}: {
  readonly firstName?: string
  readonly lastName?: string
  readonly email?: string
  readonly createdAt: string
}) => {
  return (
    <Layout.Stack gap="spacing-0-5">
      <Text>{getExportUserCellFullName(email, firstName, lastName)}</Text>
      <Text color="color-text-tertiary" variant="body-sm">
        {getExportUserCellCreationDate(createdAt)}
      </Text>
    </Layout.Stack>
  )
}
