import { IconTrash } from '@loadsmart/icons'
import { Button, Layout, Text } from '@loadsmart/loadsmart-ui'
import { useMemo, useState } from 'react'
import type { Dispatch, SetStateAction } from 'react'
import ReactPaginate from 'react-paginate'
import type { UseQueryResult } from 'react-query'

import NoResultsIllustration from 'assets/illustrations/no-results.svg'
import {
  Container,
  EmptyLanesContainer,
  EmptyLanesImage,
} from 'components/LaneManagement/LanesTableV3/styles'
import usePagination from 'hooks/usePagination'
import {
  defaultLaneTableAwardedParams,
  LIMIT,
} from 'rfp/components/table/laneParams'
import type { LanesAwardedQueryParams } from 'rfp/components/table/laneParams'
import {
  getTablePageOffset,
  getTableSortDirection,
  useLaneIdMaxLength,
} from 'rfp/components/table/tableUtils'
import BaseTable from 'rfp/rfp-details/award-scenarios/BaseTable'
import EmptyState from 'rfp/rfp-details/award-scenarios/EmptyState'
import LaneSearchField from 'rfp/rfp-details/award-scenarios/LaneSearchField'
import {
  CenteredText,
  FilterWrapper,
} from 'rfp/rfp-details/award-scenarios/styles'
import type { RequestForProposal } from 'rfp/rfp.types'
import { theme } from 'styles/theme'
import { RFP_STATE } from 'utils/constants'
import { isMultiCurrency } from 'utils/currency'

import makeLanesAllocatedColumns from './makeLanesAllocatedColumns'

interface LanesAllocatedProps {
  readonly rfp: RequestForProposal
  readonly showLaneDetailsOrRoutingGuide: (lane: Lane) => void
  readonly rankedLanesQuery: UseQueryResult<PaginatedResult, unknown>
  readonly rankedLanesParams: LanesAwardedQueryParams
  readonly setRankedLanesParams: Dispatch<
    SetStateAction<LanesAwardedQueryParams>
  >
  readonly goToLanesInProgress: () => void
  readonly rankedLanesSearchQuery: string
  readonly setRankedLanesSearchQuery: Dispatch<SetStateAction<string>>
  readonly removeScenarios: (param: {
    rfpId: string | number
    laneIds: (string | number)[]
    updateAll: boolean
  }) => void
  readonly isRemovingScenarios: boolean
}

enum RadioOptions {
  LOWEST = 'lowest_bid',
  AVERAGE = 'average_bid',
  HIGHEST = 'highest_bid',
  LAST_AWARD = 'last_award',
  TARGET_RATE = 'target_rate',
  SPOT_MARKET = 'market_rate',
  SHORT_CONTRACT = 'contract_rate',
}

const RateOptions = [
  { value: 'market_rate', label: 'Market Benchmark' },
  { value: 'target_rate', label: 'Target Rate' },
  { value: 'last_award', label: 'Last Award' },
]

const MultiCurrencyRateOptions = RateOptions.filter(
  (option) => option.value === RadioOptions.LAST_AWARD
)

const NoLanesAllocatedEmptyState = ({
  changeTab,
}: {
  readonly changeTab: () => void
}) => {
  return (
    <EmptyState>
      <Layout.Stack space="s">
        <Text variant="heading-sm-bold" color="color-neutral-darkest">
          No Lanes Allocated
        </Text>
        <Text variant="caption" color="color-neutral-darker">
          Lanes with at least one primary
          <br />
          carrier selected will appear here.
        </Text>
      </Layout.Stack>
      <Button
        variant="primary"
        onClick={() => {
          changeTab()
        }}
        scale="small"
      >
        go to lanes in progress
      </Button>
    </EmptyState>
  )
}

const TableEmptyState = ({
  query,
  changeTab,
}: {
  readonly query: string
  readonly changeTab: () => void
}) => {
  return query === '' ? (
    <NoLanesAllocatedEmptyState changeTab={changeTab} />
  ) : (
    <EmptyLanesContainer border="v2">
      <EmptyLanesImage src={NoResultsIllustration} />
      <CenteredText variant="body-bold">
        Sorry. We couldn&apos;t find any
        <br />
        matching lanes with this status
      </CenteredText>
    </EmptyLanesContainer>
  )
}

const LanesAllocated = ({
  rfp,
  showLaneDetailsOrRoutingGuide,
  rankedLanesQuery,
  rankedLanesParams,
  setRankedLanesParams,
  goToLanesInProgress,
  rankedLanesSearchQuery,
  setRankedLanesSearchQuery,
  removeScenarios,
  isRemovingScenarios,
}: LanesAllocatedProps) => {
  const displayMultiCurrency = isMultiCurrency(rfp.currency)
  const [bidOption, setRadioOptionOne] = useState<string>(RadioOptions.LOWEST)

  const getInitialRateOption = () =>
    displayMultiCurrency || rfp.state === RFP_STATE.DRAFT
      ? RadioOptions.LAST_AWARD
      : RadioOptions.SPOT_MARKET

  const [rateOption, setRadioOptionTwo] = useState<string>(
    getInitialRateOption()
  )
  const availableRateOptions = displayMultiCurrency
    ? MultiCurrencyRateOptions
    : RateOptions
  const { pageCount, page } = usePagination(
    LIMIT,
    rankedLanesQuery.data?.count,
    rankedLanesParams.page
  )

  const handlePageChange = (selectedItem: { selected: number }) => {
    setRankedLanesParams({
      ...rankedLanesParams,
      page: selectedItem.selected,
      offset: getTablePageOffset(
        selectedItem.selected,
        defaultLaneTableAwardedParams.offset
      ),
    })
  }

  const isLoading =
    rankedLanesQuery.isLoading ||
    rankedLanesQuery.isFetching ||
    isRemovingScenarios

  const changeSorting = (column: string) => {
    setRankedLanesParams({
      ...rankedLanesParams,
      sort: {
        column,
        direction: getTableSortDirection(rankedLanesParams.sort.direction),
      },
    })
  }

  const laneIdMaxLength = useLaneIdMaxLength()

  const columns = useMemo(() => {
    return () =>
      makeLanesAllocatedColumns({
        laneIdMaxLength,
        rateOption,
        rfp,
        availableRateOptions,
        displayMultiCurrency,
        bidOption,
        setRadioOptionOne,
        setRadioOptionTwo,
      })
  }, [
    laneIdMaxLength,
    rateOption,
    rfp,
    availableRateOptions,
    displayMultiCurrency,
    bidOption,
  ])

  return (
    <Container style={{ paddingBottom: '40px' }}>
      <Layout.Stack>
        <FilterWrapper align="center" justify="space-between">
          <Text variant="heading-md-bold" color="color-neutral-darker">
            {`${rankedLanesQuery.data?.count ?? 0} lanes`}
          </Text>
          <Layout.Group space="s">
            <LaneSearchField
              laneSearchQuery={rankedLanesSearchQuery}
              setLaneSearchQuery={(query) => {
                setRankedLanesParams({
                  ...rankedLanesParams,
                  page: defaultLaneTableAwardedParams.page,
                  offset: defaultLaneTableAwardedParams.offset,
                })
                setRankedLanesSearchQuery(query)
              }}
            />
          </Layout.Group>
        </FilterWrapper>
        {!rankedLanesQuery.isFetching && rankedLanesQuery.data?.count === 0 ? (
          <TableEmptyState
            query={rankedLanesSearchQuery}
            changeTab={goToLanesInProgress}
          />
        ) : (
          <BaseTable
            isLoading={isLoading}
            columns={columns}
            entries={rankedLanesQuery.data?.results ?? []}
            onRowClick={(_event, row) =>
              showLaneDetailsOrRoutingGuide(row.original)
            }
            sortable
            changeSorting={changeSorting}
            sort={rankedLanesParams.sort}
            selectable
            selectConfig={{
              actions: [
                ({ selected }: Readonly<{ selected: any[] }>) => {
                  return (
                    <Button
                      variant="tertiary"
                      scale="small"
                      onClick={() =>
                        removeScenarios({
                          rfpId: rfp.id,
                          laneIds: selected,
                          updateAll: false,
                        })
                      }
                      leading={
                        <IconTrash
                          fill={theme.colors.neutral}
                          height={12}
                          width={12}
                        />
                      }
                    >
                      Deallocate
                    </Button>
                  )
                },
              ],
              accessor: 'id',
            }}
          />
        )}
      </Layout.Stack>
      {pageCount > 1 && (
        <ReactPaginate
          data-testid="pagination"
          previousLabel="Previous"
          nextLabel="Next"
          breakLabel="..."
          breakClassName="break-me"
          pageCount={pageCount}
          forcePage={page}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName="pagination"
          activeClassName="active"
        />
      )}
    </Container>
  )
}

export default LanesAllocated
