import { TopNavigation } from '@loadsmart/loadsmart-ui'
import { Layout } from '@loadsmart/miranda-react'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import type { UserData } from '_shared_/user'
import { useModal } from 'hooks/useModal'
import { useShipperLocations } from 'hooks/useQuery'
import useSelectItems from 'screens/Shipper/Shipments/ShipmentList/hooks/useSelectItems'
import { SELECTED_SHIPMENTS_STORAGE_KEY } from 'utils/constants'
import {
  getDefaultLocation,
  parseLocationList,
  changeLocation,
} from 'utils/shipperLocationManagement'

import { LogoutButton } from './LogoutButton'
import { StyledDropdownWrapper } from './styles'
import { SupervisorDropdown } from './SupervisorDropdown'

const getInitialLocation = (
  locationDict: ShipperLocationDict,
  locationUUID: string | null
): ShipperLocation | null => {
  if (locationUUID && locationDict[locationUUID]) {
    return {
      uuid: locationUUID,
      locationName: locationDict[locationUUID].location_name,
    }
  }

  return getDefaultLocation(locationDict)
}

const getLocationDetails = (
  locationDict: ShipperLocationDict | undefined,
  locationUUID: string | null
) => {
  if (!locationDict) {
    return {
      locationList: [],
      selectedLocation: null,
    }
  }

  const locationList = parseLocationList(locationDict)
  const selectedLocation = getInitialLocation(locationDict, locationUUID)

  return {
    locationList,
    selectedLocation,
  }
}

const sortAndFilterLocationList = (
  locationList: ShipperLocation[],
  selectedLocation: ShipperLocation | null
) => {
  if (!selectedLocation) {
    return locationList
  }

  const newList = [...locationList]

  newList.sort((a, b) => {
    if (a.locationName < b.locationName) {
      return -1
    }
    if (a.locationName > b.locationName) {
      return 1
    }
    return 0
  })

  return newList.filter((location) => location.uuid !== selectedLocation.uuid)
}

export const UserDropdown = ({
  user,
  locationUUID,
}: {
  readonly user: UserData
  readonly locationUUID: string | null
}) => {
  const { pathname } = useLocation()
  const { modalState, openModal, closeModal } = useModal()

  const {
    user_name: userName,
    user_email: userEmail,
    is_supervisor: isSupervisor,
  } = user

  const { data: locationDict } = useShipperLocations()
  const { clearSelection: clearShipmentListSelection } = useSelectItems(
    SELECTED_SHIPMENTS_STORAGE_KEY
  )

  const label = userName || userEmail

  const { locationList, selectedLocation } = useMemo(
    () => getLocationDetails(locationDict, locationUUID),
    [locationDict, locationUUID]
  )

  const filteredLocationList = useMemo(
    () => sortAndFilterLocationList(locationList, selectedLocation),
    [locationList, selectedLocation]
  )

  const onLocationSelect = (uuid: string) => {
    clearShipmentListSelection()
    changeLocation(pathname, uuid)
  }

  return isSupervisor ? (
    <SupervisorDropdown
      label={label}
      selectedLocation={selectedLocation}
      locationList={locationList}
      filteredLocationList={filteredLocationList}
      onLocationSelect={onLocationSelect}
      modalState={modalState}
      openModal={openModal}
      closeModal={closeModal}
    />
  ) : (
    <Layout.Box>
      <Layout.Group gap="spacing-6" align="center" justify="space-between">
        <StyledDropdownWrapper padding="none">
          <TopNavigation.Menu.Item label={label}>
            <LogoutButton />
          </TopNavigation.Menu.Item>
        </StyledDropdownWrapper>
      </Layout.Group>
    </Layout.Box>
  )
}
