import { Layout, Text } from '@loadsmart/loadsmart-ui'
import { getToken } from '@loadsmart/loadsmart-ui/dist/theming'
import styled from 'styled-components'

export const Container = styled(Layout.Stack)`
  background: ${({ theme }) => theme.colors.whiteBroken};
  width: 600px;
`
export const InnerContainerScroll = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: ${getToken('space-s')} ${getToken('space-l')};
`
export const CarrierName = styled(Text)`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 290px;
`
