import type { Dispatch, SetStateAction } from 'react'

import {
  getActionOrFindCarrierColumn,
  getBidOptionColumn,
  getDestOrDeliveryColumn,
  getLaneIdColumn,
  getLaneIndicatorColumn,
  getMileageColumn,
  getModeAndEquipmentColumn,
  getOriginOrPickupColumn,
  getProposalCountColumn,
  getRateOptionColumn,
  getVolumeColumn,
  getVSColumn,
} from 'rfp/components/table/columns'
import type { RequestForProposal } from 'rfp/rfp.types'
import { BID_TYPE } from 'utils/constants'

const optionsDict: Record<string, string> = {
  last_award: 'LAST AWARD',
  target_rate: 'TARGET RATE',
  market_rate: 'MARKET BENCHMARK',
  contract_rate: 'SHORT CONTR.',
  lowest_bid: 'LOWEST BID',
  average_bid: 'AVG. BID',
  highest_bid: 'HIGHEST BID',
}

const BidOptions = [
  {
    value: 'lowest_bid',
    label: 'Lowest Bid',
  },
  {
    value: 'average_bid',
    label: 'Avg. Bid',
  },
  {
    value: 'highest_bid',
    label: 'Highest Bid',
  },
]

export default function makeLanesColumns({
  laneIdMaxLength,
  bidOption,
  rateOption,
  availableRateOptions,
  displayMultiCurrency,
  rfp,
  showRecommendedCarriersSidebar,
  setRadioOptionOne,
  setRadioOptionTwo,
}: {
  laneIdMaxLength: number
  bidOption: string
  rateOption: string
  availableRateOptions: {
    value: string
    label: string
  }[]
  displayMultiCurrency: boolean
  rfp: RequestForProposal
  showRecommendedCarriersSidebar: (lane: Lane) => void
  setRadioOptionOne: Dispatch<SetStateAction<string>>
  setRadioOptionTwo: Dispatch<SetStateAction<string>>
}) {
  return [
    getLaneIndicatorColumn(
      rfp.state,
      true,
      rfp.capacity_time_period === 'spot'
    ),
    getLaneIdColumn(laneIdMaxLength),
    getOriginOrPickupColumn(),
    getDestOrDeliveryColumn(),
    getMileageColumn(rfp.distance_type),
    getModeAndEquipmentColumn(),
    getVolumeColumn(),
    getProposalCountColumn(),
    getBidOptionColumn(
      optionsDict,
      bidOption,
      BidOptions,
      setRadioOptionOne,
      displayMultiCurrency,
      rfp.currency
    ),
    getVSColumn(bidOption, rateOption),
    getRateOptionColumn(
      optionsDict,
      rateOption,
      availableRateOptions,
      setRadioOptionTwo,
      displayMultiCurrency,
      rfp.currency
    ),
    getActionOrFindCarrierColumn(
      rfp.state,
      showRecommendedCarriersSidebar,
      BID_TYPE.RANK
    ),
  ]
}
