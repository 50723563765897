import { Layout, Table, Text } from '@loadsmart/miranda-react'

import { formatOrderDates } from 'orders/orders.utils'
import type { ListOrder } from 'orders/types'

interface OrderTableRowProps {
  readonly order: ListOrder
}

const OrderTableRow = ({ order }: OrderTableRowProps) => {
  const formattedPickupDateWindow = formatOrderDates(order, 'pickup')
  const formattedDeliveryDateWindow = formatOrderDates(order, 'delivery')

  return (
    <Table.Row
      key={order.uuid}
      data-testid={`selected-orders-table-row-${order.uuid}`}
    >
      <Table.Cell>
        <Layout.Group align="center" gap="spacing-1" padding="none">
          <Text>{order.primary_ref}</Text>
        </Layout.Group>
      </Table.Cell>

      <Table.Cell>
        <Layout.Stack gap="none">
          {order.pickup_facility_name}
          <Text variant="body-sm" color="color-text-tertiary">
            {formattedPickupDateWindow}
          </Text>
        </Layout.Stack>
      </Table.Cell>

      <Table.Cell>
        <Layout.Stack gap="none">
          {order.delivery_facility_name}
          <Text variant="body-sm" color="color-text-tertiary">
            {formattedDeliveryDateWindow}
          </Text>
        </Layout.Stack>
      </Table.Cell>
    </Table.Row>
  )
}

export function OrderSelection({ orders }: { readonly orders: ListOrder[] }) {
  return (
    <Table data-testid="selected-orders-table">
      <Table.Head>
        <Table.Row>
          <Table.Cell>Primary Ref</Table.Cell>
          <Table.Cell>Origin</Table.Cell>
          <Table.Cell>Destination</Table.Cell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {orders.map((order) => (
          <OrderTableRow key={order.uuid} order={order} />
        ))}
      </Table.Body>
    </Table>
  )
}
