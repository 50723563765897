import { IconUpload, IconClip, IconClose } from '@loadsmart/icons'
import { Text, Layout, Button, Card } from '@loadsmart/miranda-react'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import { useRef, useState, useCallback, useMemo } from 'react'
import type { ChangeEvent, MouseEvent } from 'react'
import styled from 'styled-components'

import type { DocumentSectionProps } from './types'

const Input = styled.input`
  display: none;
`

export default function DocumentsSection({
  shouldRender,
  onChange,
  value,
  accept,
  isLoading,
  fileCount = 1,
  sizeLimitInMb,
  error,
  setError,
  isEditing = false,
  ...props
}: DocumentSectionProps) {
  const fileInput = useRef<HTMLInputElement>(null)
  const [inputValue, setInputValue] = useState<string[]>([])

  const onClick = useCallback(() => {
    if (fileInput.current === null) {
      return
    }
    fileInput.current.value = ''
    fileInput.current.click()
  }, [fileInput])

  const onChangeInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const uploaded = Array.from(event?.currentTarget?.files || [])
      event.preventDefault()
      event.stopPropagation()
      const duplicatedFile = value.filter(
        (file) => file.name === uploaded[0].name
      )
      if (duplicatedFile.length > 0) {
        setError('attachments', {
          type: 'required',
          message: `The file (${uploaded[0].name}) was already uploaded.`,
        })
        return
      }
      setInputValue([...inputValue, event.currentTarget.value])
      const files = [...value, ...uploaded].slice(0, fileCount)
      onChange(files)
    },
    [onChange, value, fileCount, inputValue]
  )

  const removeFile = useCallback(
    (index: number) => (event: MouseEvent) => {
      if (!event || !event.target) {
        return
      }

      event.stopPropagation()
      event.preventDefault()
      const newFiles = [...value]
      const removed = newFiles.splice(index, 1)
      onChange(newFiles)
      setInputValue(
        inputValue.filter((fileName) => !fileName.endsWith(removed?.[0]?.name))
      )
    },
    [onChange, value, inputValue]
  )

  const uploadInputAccept = accept ? accept.join(',') : undefined
  const isMultiple = fileCount > 1
  const memoizedInput = useMemo(() => {
    return (
      <Input
        {...props}
        onInput={onChangeInput}
        type="file"
        ref={fileInput}
        accept={uploadInputAccept}
        disabled={isLoading}
        multiple={isMultiple}
      />
    )
  }, [accept, fileCount, isLoading, onChangeInput, props])

  if (!shouldRender) {
    return null
  }

  return (
    <>
      {memoizedInput}
      <Card collapsible>
        <Card.Divider />
        <Card.Title>Documents</Card.Title>
        <Card.Body>
          <Layout.Stack gap="spacing-5">
            <Text as="p" variant="body-sm" color="color-text-tertiary">
              Attach necessary documents carriers need to evaluate the RFP. You
              can upload up to 3 documents, each with a maximum size of{' '}
              {sizeLimitInMb}MB.
            </Text>
            <Button
              tabIndex={0}
              variant="secondary"
              disabled={isLoading || isEditing || value?.length >= fileCount}
              leading={<IconUpload height={16} width={16} title={null} />}
              onClick={onClick}
              style={{ width: 'fit-content' }}
            >
              Upload file
            </Button>
            {error && <Text color="color-danger-60">{error.message}</Text>}
            {(value?.length || 0) > 0 && (
              <Layout.Stack
                gap="spacing-1"
                style={{ height: 'fit-content', width: '100%' }}
              >
                {Array.from(value || []).map((file: File, index: number) => (
                  <Layout.Box
                    key={file.name}
                    padding="spacing-1"
                    borderRadius="border-radius-s"
                    borderWidth="border-thin"
                    borderColor="color-background-tertiary"
                  >
                    <Layout.Group
                      style={{ alignItems: 'center' }}
                      justify="space-between"
                    >
                      <Layout.Group style={{ alignItems: 'center' }}>
                        <IconClip height={18} width={18} />
                        <Text>{file.name}</Text>
                      </Layout.Group>
                      <Button
                        variant="tertiary"
                        color="color-danger-60"
                        onClick={removeFile(index)}
                        data-testid={`remove-${file.name}`}
                        leading={
                          <IconClose
                            fill={toCSSValue('color-danger-60')}
                            height={18}
                            width={18}
                            title={null}
                          />
                        }
                        style={{ width: 'fit-content' }}
                      />
                    </Layout.Group>
                  </Layout.Box>
                ))}
              </Layout.Stack>
            )}
          </Layout.Stack>
        </Card.Body>
      </Card>
    </>
  )
}
