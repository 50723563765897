import { IconPlus, IconSettings } from '@loadsmart/icons'
import { Layout, Text, ToggleGroup } from '@loadsmart/loadsmart-ui'
import { getToken } from '@loadsmart/loadsmart-ui/dist/theming'
import { Button } from '@loadsmart/miranda-react'
import { useMemo, useState } from 'react'
import type { Dispatch, ReactNode, SetStateAction } from 'react'
import ReactPaginate from 'react-paginate'
import { useMutation } from 'react-query'
import type { UseQueryResult } from 'react-query'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import NoResultsIllustration from 'assets/illustrations/no-results.svg'
import EmptyStateImage from 'assets/imgs/tracking-empty-state.svg'
import {
  EmptyButtonsRow,
  EmptyLanesContainer,
  EmptyLanesImage,
  StyledText,
} from 'components/LaneManagement/LanesTableV3/styles'
import { useModal } from 'hooks/useModal'
import usePagination from 'hooks/usePagination'
import LaneFilters from 'rfp/components/lane-filters'
import type { QueryParams } from 'rfp/components/table/laneParams'
import {
  getTablePageOffset,
  getTableSortDirection,
  useLaneIdMaxLength,
} from 'rfp/components/table/tableUtils'
import { TagFilters } from 'rfp/components/table/Tags'
import { useTagFilters } from 'rfp/components/table/Tags/useTagFilters'
import type { UploadLanesSuccessResponse } from 'rfp/rfp-details/types'
import type { Currency, RequestForProposal } from 'rfp/rfp.types'
import { bulkDeleteLanes } from 'rfp/services/rfp'
import analytics, {
  AnalyticsEvent,
  AnalyticsEventTrigger,
} from 'utils/analytics'
import { RFP_STATE } from 'utils/constants'
import { isMultiCurrency } from 'utils/currency'

import BaseTable from '../BaseTable'
import { ImportLanes } from '../ImportLanes'
import LaneSearchField from '../LaneSearchField'
import { CenteredText, FilterWrapper as ToggleWrapper } from '../styles'
import DeleteLanesDialog from './DeleteLanesDialog'
import makeBaseColumns from './makeBaseColumns'
import makeDraftColumns from './makeDraftColumns'
import makeLaneDetailsColumns from './makeLaneDetailsColumns'
import makePublishedColumns from './makePublishedColumns'

const FilterWrapper = styled(Layout.Group)`
  margin-bottom: ${getToken('space-m')};
`

enum RadioOptions {
  LOWEST = 'lowest_bid',
  AVERAGE = 'average_bid',
  HIGHEST = 'highest_bid',
  LAST_AWARD = 'last_award',
  TARGET_RATE = 'target_rate',
  SPOT_MARKET = 'market_rate',
  SHORT_CONTRACT = 'contract_rate',
}

const optionsDict: Record<string, string> = {
  last_award: 'LAST AWARD',
  target_rate: 'TARGET RATE',
  market_rate: 'MARKET BENCHMARK',
  lowest_bid: 'LOWEST BID',
  average_bid: 'AVG. BID',
  highest_bid: 'HIGHEST BID',
}

const BidOptions = [
  {
    value: 'lowest_bid',
    label: 'Lowest Bid',
  },
  {
    value: 'average_bid',
    label: 'Avg. Bid',
  },
  {
    value: 'highest_bid',
    label: 'Highest Bid',
  },
]

const RateOptions = [
  { value: 'market_rate', label: 'Market Benchmark' },
  { value: 'target_rate', label: 'Target Rate' },
  { value: 'last_award', label: 'Last Award' },
]

const MultiCurrencyDraftRateOptions = RateOptions.filter((option) =>
  [RadioOptions.TARGET_RATE, RadioOptions.LAST_AWARD].includes(
    option.value as RadioOptions
  )
)

const MultiCurrencyPublishedRateOptions = RateOptions.filter(
  (option) => option.value === RadioOptions.LAST_AWARD
)

interface LanesDeallocatedTableProps {
  readonly rfpState: string
  readonly rfpCurrency: Currency
  readonly rfpId: string | number
  readonly rfpDistanceType: RequestForProposal['distance_type']
  readonly addLaneButton: any
  readonly importLaneButton: any
  readonly onRowClick: (lane: Lane) => void
  readonly query: UseQueryResult<PaginatedResult, unknown>
  readonly params: QueryParams
  readonly defaultParams: QueryParams
  readonly setParams: Dispatch<SetStateAction<QueryParams>>
  readonly isSpotMode: boolean
  readonly isRankedAward: boolean
  readonly openFindCarriersSidebar: (lane: Lane) => void
  readonly emptyStateNode?: ReactNode
  readonly deallocatedLanesSearchQuery: string
  readonly setLaneSearchQuery: (query: string) => void
  readonly addLanesButtonProps: {
    onClick: () => void
    disabled: boolean
  }
  readonly laneCsvTemplateButtonProps: {
    onClick: () => void
    disabled: boolean
    isDownloading: boolean
  }
  readonly onSuccessUploadLane: (lanes: UploadLanesSuccessResponse) => void
  readonly onErrorUploadLane: (start?: number, errorType?: string) => void
}

const LIMIT = 50

type RankedLanesFilterOptions = '' | 'pending' | 'ranked'

const getInitialRateOption = (
  displayMultiCurrency: boolean,
  rfpState: string
) =>
  displayMultiCurrency || rfpState === RFP_STATE.DRAFT
    ? RadioOptions.LAST_AWARD
    : RadioOptions.SPOT_MARKET

const makeAvailableRateOptions = (
  displayMultiCurrency: boolean,
  rfpState: string
) => {
  const multiCurrencyOptions =
    rfpState === 'draft'
      ? MultiCurrencyDraftRateOptions
      : MultiCurrencyPublishedRateOptions

  return displayMultiCurrency ? multiCurrencyOptions : RateOptions
}

const isQueryLoadingOrHasResults = (
  query: UseQueryResult<PaginatedResult, unknown>
) => query.isLoading || (query.data?.results?.length ?? 0) > 0

const makeToggleOptions = (rfpState: string) => [
  {
    label: 'ALL',
    value: '',
  },
  {
    label: rfpState === RFP_STATE.FINALIZED ? 'NOT RANKED' : 'PENDING',
    value: 'pending',
  },
  {
    label: 'RANKED',
    value: 'ranked',
  },
]

export default function LanesDeallocatedTable({
  onRowClick,
  addLaneButton,
  importLaneButton,
  rfpState,
  rfpCurrency,
  rfpId,
  rfpDistanceType,
  isSpotMode,
  isRankedAward,
  query,
  params,
  defaultParams,
  setParams,
  openFindCarriersSidebar,
  emptyStateNode = null,
  deallocatedLanesSearchQuery,
  setLaneSearchQuery,
  addLanesButtonProps,
  laneCsvTemplateButtonProps,
  onErrorUploadLane,
  onSuccessUploadLane,
}: LanesDeallocatedTableProps) {
  const displayMultiCurrency = isMultiCurrency(rfpCurrency)
  const [bidOption, setRadioOptionOne] = useState<string>(RadioOptions.LOWEST)
  const [rateOption, setRadioOptionTwo] = useState<string>(
    getInitialRateOption(displayMultiCurrency, rfpState)
  )
  const [selectedLanes, setSelectedLanes] = useState([])
  const availableRateOptions = makeAvailableRateOptions(
    displayMultiCurrency,
    rfpState
  )

  const { pageCount, page } = usePagination(
    LIMIT,
    query.data?.count,
    params.page
  )

  const deleteLanesDialog = useModal()

  const { filters, setFilters, removeFilter, clearFilters } = useTagFilters(
    params,
    setParams
  )

  const {
    modalState: laneFiltersState,
    openModal: laneFiltersOpenModal,
    closeModal: laneFiltersCloseModal,
  } = useModal()

  const { mutate: deleteLanes, isLoading: isRemovingLanes } = useMutation({
    mutationFn: bulkDeleteLanes,
    onSuccess: () => {
      analytics.track(
        AnalyticsEvent.LanesDeleteSelected,
        AnalyticsEventTrigger.success,
        {
          deletedLanesCount: selectedLanes.length,
          totalLanesCount: query.data?.count,
        }
      )
      deleteLanesDialog.closeModal()
      toast.success('Lanes successfully deleted')
      setParams({ ...defaultParams, filterByRankedLanes: false, page: 0 })
      query.refetch()
      setSelectedLanes([])
    },
    onError: () => {
      analytics.track(
        AnalyticsEvent.LanesDeleteSelected,
        AnalyticsEventTrigger.error
      )
      deleteLanesDialog.closeModal()
      toast.error('Could not delete lanes, please contact an admin')
    },
  })

  const handlePageChange = (selectedItem: { selected: number }) => {
    setParams({
      ...params,
      page: selectedItem.selected,
      offset: getTablePageOffset(selectedItem.selected, defaultParams.offset),
    })
  }

  const changeSorting = (column: string) => {
    setParams({
      ...params,
      sort: {
        column,
        direction: getTableSortDirection(params.sort.direction),
      },
    })
  }

  const changeFilter = (option: RankedLanesFilterOptions) => {
    const getFilterByRankedLanes = () => {
      switch (option) {
        case 'pending':
          return false
        case 'ranked':
          return true
        default:
          return undefined
      }
    }
    setParams({
      ...params,
      filterByRankedLanes: getFilterByRankedLanes(),
    })
  }

  const rankedFilterValue = useMemo(() => {
    switch (params.filterByRankedLanes) {
      case undefined:
        return ''
      case false:
        return 'pending'
      case true:
        return 'ranked'
      default:
        return ''
    }
  }, [params.filterByRankedLanes])

  const displayRankedLanesFilter = useMemo(() => {
    return (
      isRankedAward &&
      [RFP_STATE.PUBLISHED, RFP_STATE.CLOSED, RFP_STATE.FINALIZED].includes(
        rfpState
      )
    )
  }, [isRankedAward, rfpState])

  const laneIdMaxLength = useLaneIdMaxLength()

  const columns = useMemo(() => {
    const draftColumns = makeDraftColumns({
      optionsDict,
      rateOption,
      availableRateOptions,
      setRadioOptionTwo,
      displayMultiCurrency,
      rfpCurrency,
    })

    const publishedColumns = makePublishedColumns({
      bidOption,
      rateOption,
      optionsDict,
      availableRateOptions,
      setRadioOptionTwo,
      displayMultiCurrency,
      rfpCurrency,
    })

    const laneDetailsColumns = makeLaneDetailsColumns({
      optionsDict,
      bidOption,
      BidOptions,
      setRadioOptionOne,
      displayMultiCurrency,
      rfpCurrency,
      publishedColumns,
    })

    return () =>
      makeBaseColumns({
        isRankedAward,
        rfpState,
        isSpotMode,
        laneIdMaxLength,
        draftColumns,
        laneDetailsColumns,
        onRowClick,
        openFindCarriersSidebar,
        rfpDistanceType,
      })
  }, [
    laneIdMaxLength,
    bidOption,
    rateOption,
    rfpState,
    isSpotMode,
    rfpCurrency,
    availableRateOptions,
    displayMultiCurrency,
    onRowClick,
    openFindCarriersSidebar,
    isRankedAward,
    rfpDistanceType,
  ])

  const showTable = isQueryLoadingOrHasResults(query)

  const AddYourLanesEmptyState = () => (
    <EmptyLanesContainer border="v2">
      <EmptyLanesImage src={EmptyStateImage} />
      <Text variant="body-bold">Add Your Lanes</Text>
      <StyledText size="small">
        Add single lanes or import lanes in batch for this RFP.
      </StyledText>
      <EmptyButtonsRow>
        {addLaneButton}
        {importLaneButton}
      </EmptyButtonsRow>
    </EmptyLanesContainer>
  )

  const RankedAwardEmptyState = () => (
    <EmptyLanesContainer border="v2">
      <EmptyLanesImage src={NoResultsIllustration} />
      <CenteredText variant="body-bold">
        Sorry. We couldn&apos;t find any
        <br />
        matching lanes with this status
      </CenteredText>
    </EmptyLanesContainer>
  )

  const getEmptyState = () => {
    if (emptyStateNode) {
      return emptyStateNode
    }

    if (!isRankedAward && !showTable) {
      return <AddYourLanesEmptyState />
    }

    if (displayRankedLanesFilter) {
      return <RankedAwardEmptyState />
    }

    return undefined
  }

  return (
    <>
      <FilterWrapper align="center" justify="space-between">
        <Text variant="heading-md-bold" color="color-neutral-darker">
          {`${query.data?.count ?? 0} lanes`}
        </Text>
        <Layout.Group space="s">
          <LaneSearchField
            laneSearchQuery={deallocatedLanesSearchQuery}
            setLaneSearchQuery={setLaneSearchQuery}
          />
          {rfpState === RFP_STATE.DRAFT && (
            <>
              <ImportLanes
                onClickDownloadTemplate={laneCsvTemplateButtonProps.onClick}
                onErrorUploadLane={onErrorUploadLane}
                onSuccessUploadLane={onSuccessUploadLane}
                rfpId={rfpId}
              />
              <Button
                data-testid="button-create-lane"
                variant="secondary"
                onClick={addLanesButtonProps.onClick}
                leading={<IconPlus width={12} />}
                disabled={addLanesButtonProps.disabled}
              >
                Add lane
              </Button>
              <Button
                variant="secondary"
                onClick={() => deleteLanesDialog.openModal()}
                disabled={selectedLanes.length === 0}
              >
                Delete
              </Button>
            </>
          )}
          {[RFP_STATE.DRAFT, RFP_STATE.PUBLISHED].includes(rfpState) && (
            <Button
              variant="secondary"
              onClick={laneFiltersOpenModal}
              leading={<IconSettings width={16} height={16} />}
            >
              Filters
            </Button>
          )}
          {displayRankedLanesFilter && (
            <ToggleWrapper>
              <ToggleGroup
                aria-labelledby="status_label"
                id="status"
                name="status"
                value={rankedFilterValue}
                onChange={(event) =>
                  changeFilter(event.target.value as RankedLanesFilterOptions)
                }
                data-testid="filter"
                options={makeToggleOptions(rfpState)}
              />
            </ToggleWrapper>
          )}
        </Layout.Group>
      </FilterWrapper>
      <FilterWrapper>
        <TagFilters
          filters={filters}
          removeFilter={removeFilter}
          clearFilters={clearFilters}
        />
      </FilterWrapper>
      {showTable ? (
        <BaseTable
          isLoading={query.isLoading}
          columns={columns}
          entries={query.data?.results ?? []}
          onRowClick={(_event, row) => onRowClick(row.original)}
          sortable
          selectable={rfpState === RFP_STATE.DRAFT}
          updateSelection={setSelectedLanes}
          changeSorting={changeSorting}
          sort={params.sort}
        />
      ) : (
        getEmptyState()
      )}
      {pageCount > 1 && (
        <ReactPaginate
          data-testid="pagination"
          previousLabel="Previous"
          nextLabel="Next"
          breakLabel="..."
          breakClassName="break-me"
          pageCount={pageCount}
          forcePage={page}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName="pagination"
          activeClassName="active"
        />
      )}
      <DeleteLanesDialog
        deleteLanes={() => deleteLanes({ rfpId, laneIds: selectedLanes })}
        isDeleting={isRemovingLanes}
        isOpen={deleteLanesDialog.modalState}
        lanesCount={selectedLanes.length}
        onClose={deleteLanesDialog.closeModal}
      />
      <LaneFilters
        rfpId={rfpId}
        rfpState={rfpState}
        isOpen={laneFiltersState}
        onConfirm={(newFilters) => {
          laneFiltersCloseModal()
          setFilters(newFilters)
        }}
        onClose={laneFiltersCloseModal}
        filters={filters}
      />
    </>
  )
}
