import { Layout } from '@loadsmart/loadsmart-ui'
import type { ReactNode } from 'react'
import { memo } from 'react'

const HoverWrapper = ({
  setOnHoverState,
  children,
}: {
  readonly setOnHoverState: (state: boolean) => void
  readonly children: ReactNode
}) => {
  return (
    <Layout.Group
      align="center"
      onMouseOver={() => setOnHoverState(true)}
      onMouseOut={() => setOnHoverState(false)}
    >
      {children}
    </Layout.Group>
  )
}

export default memo(HoverWrapper)
