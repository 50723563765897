import {
  add,
  set,
  sub,
  addBusinessDays as DFNSaddBusinessDays,
  subBusinessDays,
  isWeekend,
  isAfter,
} from 'date-fns'

export const END_HOUR_OF_BUSINESS_DAY = 17

export const addDays = (date: Date | string, days: number) => {
  return add(new Date(date), { days })
}

export const subtractDays = (date: Date | string, days: number) => {
  return sub(new Date(date), { days })
}

export const setHours = (date: Date | string, hours: number) => {
  return set(new Date(date), { hours, minutes: 0 })
}

export const setStartOfDay = (date: Date | string) => {
  return set(new Date(date), { hours: 0, minutes: 0, seconds: 0 })
}

export const setEndOfDay = (date: Date | string) => {
  return set(new Date(date), { hours: 23, minutes: 59, seconds: 59 })
}

export const addBusinessDays = (date: Date | string, days: number) => {
  return DFNSaddBusinessDays(new Date(date), days)
}

export const subtractBusinessDays = (date: Date | string, days: number) => {
  return subBusinessDays(new Date(date), days)
}

export function isBusinessDayOver(date: Date | string) {
  const day = new Date(date)
  const endOfBusinessDay = setHours(day, END_HOUR_OF_BUSINESS_DAY)
  return isWeekend(day) || isAfter(day, endOfBusinessDay)
}

export function getDateWithUTCHours(
  date: Date,
  {
    hours,
    minutes,
    seconds,
  }: { hours: number; minutes: number; seconds: number }
) {
  const customDate = new Date(date)

  customDate.setUTCHours(hours)
  customDate.setUTCMinutes(minutes)
  customDate.setUTCSeconds(seconds)

  return customDate
}

export function getCustomDateInUTC(
  date: Date,
  {
    hours,
    minutes,
    seconds,
  }: { hours: number; minutes: number; seconds: number }
) {
  const customDate = new Date(date)

  customDate.setHours(hours)
  customDate.setMinutes(minutes)
  customDate.setSeconds(seconds)

  return customDate
}
