import { IconEye, IconEyeSlash } from '@loadsmart/icons'
import { Button, Dropdown, Layout } from '@loadsmart/loadsmart-ui'
import type { Dispatch, SetStateAction } from 'react'
import { useHistory } from 'react-router-dom'

import type { RequestForProposalDetail } from 'rfp/rfp.types'
import { theme } from 'styles/theme'
import analytics, {
  AnalyticsEvent,
  AnalyticsEventTrigger,
} from 'utils/analytics'
import { BID_TYPE, RFP_STATE } from 'utils/constants'

const HideShowButton = ({
  isVisible,
  setIsVisible,
}: {
  readonly isVisible: boolean
  readonly setIsVisible: Dispatch<SetStateAction<boolean>>
}) => {
  return (
    <Button
      variant="tertiary"
      style={{ marginRight: '8px' }}
      onClick={() => setIsVisible(!isVisible)}
      leading={
        isVisible ? (
          <IconEyeSlash height={16} width={16} fill={theme.colors.neutral} />
        ) : (
          <IconEye height={16} width={16} fill={theme.colors.neutral} />
        )
      }
    >
      {isVisible ? 'Hide' : 'Show'}
    </Button>
  )
}

const HeaderActions = ({
  isVisible,
  setIsVisible,
  rfp,
  rfpId,
  openDownloadDialog,
  duplicateRFP,
  archiveRFP,
  rfpIsValid,
  openAwardDialog,
  openPublishDialog,
  openFinalizeDialog,
  rfpLaneDetails,
}: {
  readonly isVisible: boolean
  readonly setIsVisible: Dispatch<SetStateAction<boolean>>
  readonly rfp: RequestForProposalDetail
  readonly rfpId: string
  readonly openDownloadDialog: () => void
  readonly duplicateRFP: () => void
  readonly archiveRFP: () => void
  readonly rfpIsValid: boolean
  readonly openAwardDialog: () => void
  readonly openPublishDialog: () => void
  readonly openFinalizeDialog: () => void
  readonly rfpLaneDetails: { mode: string; multimode: boolean }
}) => {
  const history = useHistory()

  return (
    <Layout.Group space="s">
      <HideShowButton isVisible={isVisible} setIsVisible={setIsVisible} />
      <Dropdown>
        <Dropdown.Trigger>More</Dropdown.Trigger>
        <Dropdown.Menu>
          {rfp?.state !== RFP_STATE.FINALIZED &&
            rfp?.state !== RFP_STATE.ARCHIVED && (
              <Dropdown.Item
                onClick={() => history.push(`/shipper/rfp/${rfpId}/edit`)}
              >
                Edit Details
              </Dropdown.Item>
            )}
          <Dropdown.Item onClick={openDownloadDialog}>Downloads</Dropdown.Item>
          <Dropdown.Item onClick={duplicateRFP}>Duplicate</Dropdown.Item>
          {rfp?.state !== RFP_STATE.ARCHIVED && (
            <Dropdown.Item onClick={archiveRFP}>Archive</Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
      {rfp?.state === RFP_STATE.DRAFT && (
        <Button
          disabled={!rfpIsValid}
          onClick={() => {
            analytics.track(
              AnalyticsEvent.RFPPublish,
              AnalyticsEventTrigger.click,
              { ...rfp, ...rfpLaneDetails }
            )
            openPublishDialog()
          }}
          variant="primary"
        >
          Publish to carriers
        </Button>
      )}
      {(rfp?.state === RFP_STATE.PUBLISHED ||
        rfp?.state === RFP_STATE.CLOSED) && (
        <Button
          onClick={() => {
            analytics.track(
              AnalyticsEvent.RFPFinalize,
              AnalyticsEventTrigger.click,
              rfp
            )
            openAwardDialog()
          }}
          variant="primary"
        >
          {rfp.bid_type === BID_TYPE.RANK
            ? 'finalize & send feedback'
            : 'review & send awards'}
        </Button>
      )}
      {rfp?.state === RFP_STATE.AWARDING && (
        <Button
          onClick={() => {
            analytics.track(
              AnalyticsEvent.RFPFinalize,
              AnalyticsEventTrigger.click,
              rfp
            )
            openFinalizeDialog()
          }}
          variant="primary"
        >
          finalize rfp
        </Button>
      )}
    </Layout.Group>
  )
}

export default HeaderActions
