import { PageContent, Tabs } from '@loadsmart/miranda-react'
import type { TabChangeEventDetail } from '@loadsmart/miranda-react'
import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { WindowTitle } from '_shared_/components/WindowTitle'

import { PayablesTable } from './PayablesTable'
import { PaymentsListPageHeader } from './PaymentsListPageHeader'

export function PaymentBasePage() {
  const [activeTab, setActiveTab] = useState('exported')
  const history = useHistory()

  const handleTabChange = useCallback(
    (event: CustomEvent<TabChangeEventDetail>) => {
      history.replace({ search: '' })

      setActiveTab(event.detail.value as unknown as string)
    },
    [history]
  )

  return (
    <>
      <WindowTitle title="Payments" />
      <PageContent data-testid="payments-list-page">
        <PaymentsListPageHeader />
        <PageContent.Body>
          <Tabs activeTab={activeTab} onTabChange={handleTabChange}>
            <Tabs.Tab name="exported">Exported</Tabs.Tab>
            <Tabs.Panel forTab="exported">
              {activeTab === 'exported' && <PayablesTable />}
            </Tabs.Panel>
          </Tabs>
        </PageContent.Body>
      </PageContent>
    </>
  )
}
