import { Header } from '@loadsmart/miranda-react'

export function PaymentsListPageHeader() {
  return (
    <Header>
      <Header.Content>
        <Header.Title>Payments</Header.Title>
      </Header.Content>
    </Header>
  )
}
