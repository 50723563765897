import { IconArrowsExpandSide } from '@loadsmart/icons'
import type { ReactNode } from 'react'

import * as styles from './styles'

export interface Props {
  readonly isOpen: boolean
  readonly showToggle?: boolean
  readonly onToggleVisible?: () => void
  readonly children: ReactNode
}
export default function SideBar({
  isOpen,
  showToggle = true,
  onToggleVisible,
  children,
}: Props) {
  return (
    <styles.SideBarContainer>
      {showToggle && (
        <styles.Arrow
          onClick={() => {
            onToggleVisible?.()
          }}
          open={isOpen}
          data-testid="sideBarExpand"
        >
          <IconArrowsExpandSide width={14} height={14} open={isOpen} />
        </styles.Arrow>
      )}
      {isOpen && <styles.ContentContainer>{children}</styles.ContentContainer>}
    </styles.SideBarContainer>
  )
}
