import { SideNavigation } from '@loadsmart/loadsmart-ui'
import { useHistory } from 'react-router-dom'

import { userUtils } from '_shared_/user'
import { useSettings } from 'contexts/settings'
import { AppRoutes } from 'router/AppRoutes'
import { USER_TYPE } from 'utils/constants'

import { isActivePath, navbarLinkClicked } from './sidenav.utils'

export const SettlementMenu = () => {
  const history = useHistory()

  const {
    values: [isSettlementTabEnabled],
  } = useSettings(['flags.ENABLE_SHIPMENT_SETTLEMENT_TAB'])

  if (
    isSettlementTabEnabled &&
    userUtils.isUserType([USER_TYPE.SG, USER_TYPE.SUPPLIER])
  ) {
    return (
      <SideNavigation.Menu label="Settlement">
        <SideNavigation.Menu.Item
          active={isActivePath(AppRoutes.Payables)}
          label="Payables"
          url={AppRoutes.Payables}
          onClick={(event) => {
            event.preventDefault()
            navbarLinkClicked('Payables', AppRoutes.Payables, history)
          }}
        />
      </SideNavigation.Menu>
    )
  }

  return null
}
