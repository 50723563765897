import { Button, Layout, Text } from '@loadsmart/loadsmart-ui'

import EmptyState from 'rfp/rfp-details/award-scenarios/EmptyState'

const AllLanesAllocatedEmptyState = ({
  changeTab,
}: {
  readonly changeTab: () => void
}) => {
  return (
    <EmptyState>
      <Layout.Stack space="s">
        <Text variant="heading-sm-bold" color="color-neutral-darkest">
          All Lanes Allocated
        </Text>
        <Text variant="caption" color="color-neutral-darker">
          Well done. You have allocated all
          <br />
          the lanes on this RFP.
        </Text>
      </Layout.Stack>
      <Button
        variant="primary"
        onClick={() => {
          changeTab()
        }}
        scale="small"
      >
        go to lanes allocated
      </Button>
    </EmptyState>
  )
}

export default AllLanesAllocatedEmptyState
