import { Dialog, Text } from '@loadsmart/loadsmart-ui'

import { HubspotForm } from 'components/HubspotRequestDemoForm'

const RequestDemo = ({
  isOpen,
  onClose,
  onConfirm,
}: {
  readonly isOpen: boolean
  readonly onClose: () => void
  readonly onConfirm: () => void
}) => {
  return (
    <Dialog open={isOpen} scale="small" onOverlayClick={onClose}>
      <Dialog.Header>RFP Guide</Dialog.Header>
      <Dialog.Body>
        <Text variant="heading-sm-bold" color="color-neutral-darker">
          Streamline your freight
          <br /> procurement process.
        </Text>
        <br />
        <br />
        <Text variant="body" color="color-neutral-darker">
          RFP Guide helps you to publish a request for pricing, compare rates,
          and award a provider to haul your freight.
        </Text>
        <HubspotForm onConfirm={onConfirm} />
        <Dialog.ActionCancel onCancel={onClose}>Not Now</Dialog.ActionCancel>
      </Dialog.Body>
    </Dialog>
  )
}

export default RequestDemo
