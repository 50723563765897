import { Card } from '@loadsmart/loadsmart-ui'
import { getToken } from '@loadsmart/loadsmart-ui/dist/theming'
import styled from 'styled-components'

export const StyledCardSeparator = styled(Card.Separator)``

export const StyledCardTitle = styled(Card.Title)<{ $isVisible: boolean }>`
  padding: 1.5rem 1.5rem 0;
  ${({ $isVisible }) => !$isVisible && `padding-bottom: 1.5rem`}
`

export const StyledCard = styled(Card)`
  background-color: ${getToken('color-neutral-lightest')};
  border: 1px solid ${getToken('color-neutral-lighter')};
  border-radius: 4px;
  margin-bottom: 10px;

  ${StyledCardTitle} + ${StyledCardSeparator} {
    margin-top: 1.5rem;
  }
`

export const StyledCardBody = styled(Card.Body)`
  padding: 1.5rem 1.5rem;
`

export const StyledStatusDot = styled.div<{ $color: string; $shadow: string }>`
  align-items: center;
  display: flex;
  height: 16px;
  justify-content: center;
  width: 16px;

  div {
    background: ${({ $color }) => $color};
    border-radius: 50%;
    box-shadow: ${({ $shadow }) => $shadow};
    display: inline-block;
    height: 8px;
    width: 8px;
  }
`
export const StyledTotalizerItem = styled.div`
  background-color: ${getToken('color-neutral-white')};
  border: 1px solid ${getToken('color-neutral-lighter')};
  border-radius: 4px;
  height: 90px;
  padding: 16px;
  width: 150px;
`
