import type { AxiosRequestConfig } from 'axios'

import * as repository from './repository'

export async function getShipmentsSettlementReports({
  config,
  limit,
  offset,
}: Partial<{
  limit: number
  offset: number
  config: AxiosRequestConfig
}>) {
  const { data } = await repository.getShipmentsSettlementReports({
    config,
    limit,
    offset,
  })

  return data
}
