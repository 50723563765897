/**
 * Intercepts method calls on an object and logs errors if they occur.
 *
 * This function creates a proxy around the given object and intercepts
 * method calls. If a method throws an error, the error is caught, logged
 * to the console, and `undefined` is returned as a default value.
 *
 * @param object - The object whose method calls should be intercepted.
 * @returns A proxy object that intercepts method calls and logs errors.
 */
export const interceptErrors = <T extends { [key: string | symbol]: unknown }>(
  object: T,
  handle: (error: unknown, propertyName: string | symbol) => void = (
    error,
    propertyName
  ) => {
    console.error(`Error in method "${String(propertyName)}":`, error)
  }
): T => {
  return new Proxy<T>(object, {
    get: (obj, propertyName) => {
      // Intercept method calls
      const objProperty = obj[propertyName]
      if (typeof objProperty === 'function') {
        return (...args: any[]) => {
          try {
            // Call the original method
            return objProperty(...args)
          } catch (error) {
            return handle(error, propertyName)
          }
        }
      }
      // For non-function properties, return as usual
      return obj[propertyName]
    },
  })
}
