export type State =
  | { step: 'FILE_SELECTION' }
  | { step: 'FILE_PREVIEW'; file: File; loading: boolean }
  | { step: 'FILE_ERROR'; file: File; errors: string[] }
  | { step: 'UPLOAD_ERROR'; file: File }
  | { step: 'UPLOAD_TIMEOUT'; file: File }
  | { step: 'UPLOAD_SUCCEEDED'; file: File }
  | { step: 'LANES_LIMIT_REACHED'; file: File }

type FileSelectedAction = { type: 'FILE_SELECTED'; file: File }
type UploadSucceededAction = { type: 'UPLOAD_SUCCEEDED' }
type UploadFailedAction = { type: 'UPLOAD_FAILED' }
type UploadTImeoutAction = { type: 'UPLOAD_TIMEOUT' }
type LanesLimitReachedAction = { type: 'LANES_LIMIT_REACHED' }

type LoadingAction = { type: 'LOADING'; loading: boolean }
type ResetAction = { type: 'RESET' }

type Action =
  | FileSelectedAction
  | UploadSucceededAction
  | UploadFailedAction
  | LoadingAction
  | ResetAction
  | UploadTImeoutAction
  | LanesLimitReachedAction

export const initialState: State = { step: 'FILE_SELECTION' }

function fileSelectedReducer(_state: State, action: FileSelectedAction): State {
  if (!action.file.name.endsWith('csv')) {
    return {
      step: 'FILE_ERROR',
      file: action.file,
      errors: [
        'This file format is not supported. Please add your lanes into the .csv template.',
      ],
    }
  }

  return {
    step: 'FILE_PREVIEW',
    file: action.file,
    loading: false,
  }
}

function uploadSucceededReducer(state: State): State {
  if (state.step === 'FILE_PREVIEW') {
    return {
      step: 'UPLOAD_SUCCEEDED',
      file: state.file,
    }
  }

  return state
}

function uploadMaxLanesReachedFailReducer(state: State): State {
  if (state.step === 'FILE_PREVIEW') {
    return {
      step: 'LANES_LIMIT_REACHED',
      file: state.file,
    }
  }

  return state
}

function uploadFailedReducer(state: State): State {
  if (state.step === 'FILE_PREVIEW') {
    return {
      step: 'UPLOAD_ERROR',
      file: state.file,
    }
  }

  return state
}

function uploadTimeoutReducer(state: State): State {
  if (state.step === 'FILE_PREVIEW') {
    return {
      step: 'UPLOAD_TIMEOUT',
      file: state.file,
    }
  }

  return state
}

export function importReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'FILE_SELECTED':
      return fileSelectedReducer(state, action)
    case 'UPLOAD_SUCCEEDED':
      return uploadSucceededReducer(state)
    case 'UPLOAD_FAILED':
      return uploadFailedReducer(state)
    case 'UPLOAD_TIMEOUT':
      return uploadTimeoutReducer(state)
    case 'LANES_LIMIT_REACHED':
      return uploadMaxLanesReachedFailReducer(state)
    case 'RESET':
      return initialState
    default:
      return initialState
  }
}
