import { IconQuestionCircle } from '@loadsmart/icons'
import { Tooltip } from '@loadsmart/loadsmart-ui'
import type { ErrorMessageProps } from 'formik'
import { ErrorMessage as BaseErrorMessage, useField } from 'formik'
import type {
  FieldHelperProps,
  FieldInputProps,
  FieldMetaProps,
} from 'formik/dist/types'
import type { ComponentType, PropsWithChildren, ReactNode } from 'react'

import { Input, Label } from 'components/Input'
import { theme } from 'styles/theme'

import { InputGroup, Error, LabelRow } from './styles'

const ErrorMessage = (props: ErrorMessageProps) => (
  <Error className="error-message">
    <BaseErrorMessage {...props} />
  </Error>
)

interface RendererProps<T> {
  field: FieldInputProps<T>
  meta: FieldMetaProps<T>
  helpers: FieldHelperProps<T>
}
interface FormFieldProps extends Record<string, any> {
  readonly name: string
  readonly label: string
  readonly span?: number
  readonly disabled?: boolean
  readonly component?: ComponentType<PropsWithChildren<unknown>>
  readonly children?: (props: RendererProps<string>) => ReactNode
  readonly maxLength?: number | undefined
  readonly tooltip?: string
}
const FormField = ({
  name,
  label,
  children,
  component,
  disabled = false,
  maxLength = undefined,
  tooltip,
  ...props
}: FormFieldProps) => {
  const [field, meta, helpers] = useField(name)
  const Field = component || Input
  return (
    <InputGroup {...props}>
      <Label name={name}>
        <LabelRow>
          {label}
          {tooltip && (
            <Tooltip message={tooltip} style={{ marginLeft: 5 }}>
              <IconQuestionCircle
                height={14}
                width={14}
                fill={theme.colors.neutral}
                title={null}
              />
            </Tooltip>
          )}
        </LabelRow>
        {children ? (
          children({ field, meta, helpers })
        ) : (
          <Field
            {...field}
            aria-label={label}
            disabled={disabled}
            maxLength={maxLength}
          />
        )}
      </Label>
      <ErrorMessage name={name} />
    </InputGroup>
  )
}
export default FormField
