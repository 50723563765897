import { IconCheckCircle as BaseIconCheckCircle } from '@loadsmart/icons'
import { Dialog } from '@loadsmart/loadsmart-ui'
import styled from 'styled-components'

import type { RequestForProposal } from 'rfp/rfp.types'

const Label = styled.div`
  font-weight: 800;
`

const Ul = styled.ul`
  font-size: 14px;
  line-height: 141.8%;
  margin-top: 15px;
  text-align: left;

  li {
    margin-bottom: 15px;
  }
`

const IconCheckCircle = styled(BaseIconCheckCircle)`
  color: ${({ theme }) => theme.colors.main};
`

interface Props {
  readonly rfp: RequestForProposal
  readonly isOpen: boolean
  readonly onClose: () => void
}

export default function FinalizedDialog({ rfp, isOpen, onClose }: Props) {
  return (
    <Dialog open={isOpen} onOverlayClick={onClose}>
      <Dialog.Header>RFP Finished</Dialog.Header>
      <Dialog.Body>
        <IconCheckCircle width={51} height={51} />
        <p>
          The RFP {rfp.name} is finished and all award emails are being sent.
        </p>
        <Label>What now?</Label>
        <Ul>
          <li>
            This RFP will be always available for future access. You can also
            export your list to CSV.
          </li>
          <li>
            Carriers are not required to answer their emails. If you need any
            other info from them, please contact them directly with the emails
            on the carrier list.
          </li>
        </Ul>
      </Dialog.Body>

      <Dialog.ActionCancel onCancel={onClose}>Close</Dialog.ActionCancel>
    </Dialog>
  )
}
