import { LabeledValue } from '@loadsmart/miranda-react'

import { capitalizeFirstLetter } from 'utils/strings'

type RelationshipStatusSummaryProps = {
  readonly relationshipStatus: Carrier['relationship_status']
}

export const RelationshipStatusSummary = ({
  relationshipStatus,
}: RelationshipStatusSummaryProps) => {
  return (
    <LabeledValue
      label="Status"
      value={
        relationshipStatus
          ? capitalizeFirstLetter(relationshipStatus.replaceAll(/_/g, '-'))
          : undefined
      }
    />
  )
}
