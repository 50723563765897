import { useContext, createContext } from 'react'

import { userUtils } from '_shared_/user'

import type { UserData } from './user-data'

type UserProviderValue = {
  user: UserData | null
  locationUUID: string | null
}

export const userContext = createContext<UserProviderValue>({
  user: userUtils.user ?? null,
  locationUUID: userUtils.locationUUID,
})

export function useCurrentUser() {
  const context = useContext(userContext)

  if (process.env.NODE_ENV !== 'production' && !context) {
    throw new Error('useCurrentUser should be wrapped in UserProvider')
  }

  return context
}
