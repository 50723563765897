import { defaultTo } from 'lodash'
import { useCallback } from 'react'

import { generateUseSearchParamsPlugin, useFilters } from 'utils/filters'

type OrderSelectionParams = {
  order: string[] | null
}

function getDefaultOrderSelectionQueryParams(
  overrides?: Partial<OrderSelectionParams>
): OrderSelectionParams {
  return {
    order: null,
    ...overrides,
  }
}

const preSelectionQueryParamsPlugin =
  generateUseSearchParamsPlugin<OrderSelectionParams>({
    order: {
      type: 'primitive-collection',
    },
  })

export function usePreSelection() {
  const { setFilter, values } = useFilters(
    { initialValues: getDefaultOrderSelectionQueryParams() },
    preSelectionQueryParamsPlugin
  )
  const { order } = values
  const selectedOrdersUUIDs = defaultTo(order, [])

  const clearSelectedOrders = useCallback(() => {
    setFilter('order', null)
  }, [setFilter])

  return {
    clearSelectedOrders,
    selectedOrdersUUIDs,
  }
}
