import type { ExcessiveLengthClassification, PackageType } from 'quotes/Quote'
import type { HazmatClass } from 'utils/types'

const HAZMAT_CLASS_ARRAY = [
  ['minor_blast_explosive', '1.4 Minor Blast Explosive'] as const,
  ['insensitive_explosive', '1.5 Insensitive Explosive'] as const,
  ['very_insensitive_explosive', '1.6 Very Insensitive Explosive'] as const,
  ['flammable_gas', '2.1 Flammable Gas'] as const,
  ['non_flammable_gas', '2.2 Non-Flammable Gas'] as const,
  ['poison_gas', '2.3 Poison Gas'] as const,
  ['combustible_liquid', '3 Combustible Liquid'] as const,
  ['flammable_liquid', '3 Flammable Liquid'] as const,
  ['flammable_solid', '4.1 Flammable Solid'] as const,
  ['spontaneously_combustible', '4.2 Spontaneously Combustible'] as const,
  ['dangerous_when_wet', '4.3 Dangerous When Wet'] as const,
  ['oxidizer', '5.1 Oxidizer'] as const,
  ['organic_peroxide', '5.2 Organic Peroxide'] as const,
  ['poisonous_toxic', '6 Poisonous/Toxic'] as const,
  ['corrosive', '8 Corrosive'] as const,
  ['miscellaneous_dangerous_good', '9 Miscellaneous Dangerous Good'] as const,
]

// Those readonly constansts are needed because of historical shipments
// were being created before they changed, they are used for read only purposes
const DEPRECATED_HAZMAT_CLASS_ARRAY = [
  ['explosive_non_specified', 'Explosive Non Specified'] as const,
  ['mass_explosive', 'Mass Explosive'] as const,
  ['projection_explosive', 'Projection Explosive'] as const,
  ['minor_fire_explosive', 'Minor Fire Explosive'] as const,
  ['minor_explosive', 'Minor Explosive'] as const,
  ['insensitive_mass_explosive', 'Insensitive Mass Explosive'] as const,
  ['insensitive_minor_explosive', 'Insensitive Minor Explosive'] as const,
  ['gas_non_specified', 'Gas Non Specified'] as const,
  ['flammable_gas', 'Flammable Gas'] as const,
  [
    'non_flammable_non_poisonous_or_oxygen_gas',
    'Non Flammable Non Poisonous or Oxygen Gas',
  ] as const,
  ['poison_gas', 'Poison Gas'] as const,
  ['flammable_liquid', 'Flammable Liquid'] as const,
  ['solid_non_specified', 'Solid Non Specified'] as const,
  ['flammable_solid', 'Flammable Solid'] as const,
  [
    'spontaneously_combustible_solid',
    'Spontaneously Combustible Solid',
  ] as const,
  ['dangerous_when_wet', 'Dangerous when Wet'] as const,
  ['oxidizer_non_specified', 'Oxidizer Non Specified'] as const,
  ['oxidizer', 'Oxidizer'] as const,
  ['organic_peroxide', 'Organic Peroxide'] as const,
  ['poison', 'Poison'] as const,
  ['radioactive', 'Radioactive'] as const,
]

export const READ_ONLY_HAZMAT_CLASS_REFERENCE = new Map<HazmatClass, string>([
  ...DEPRECATED_HAZMAT_CLASS_ARRAY,
  ...HAZMAT_CLASS_ARRAY,
])

export const PACKAGE_TYPE_LABELS = new Map<PackageType, string>([
  ['std_pallets', 'Standard Pallets (48x40)'] as const,
  ['pallets_non_std', 'Pallets Non Standard'] as const,
  ['bags', 'Bags'] as const,
  ['bales', 'Bales'] as const,
  ['boxes', 'Boxes'] as const,
  ['bunches', 'Bunches'] as const,
  ['carpets', 'Carpets'] as const,
  ['coils', 'Coils'] as const,
  ['crates', 'Crates'] as const,
  ['cylinders', 'Cylinders'] as const,
  ['drums', 'Drums'] as const,
  ['pails', 'Pails'] as const,
  ['reels', 'Reels'] as const,
  ['rolls', 'Rolls'] as const,
  ['tubes_pipes', 'Tubes and Pipes'] as const,
  ['loose', 'Loose'] as const,
])

// TODO: To be replaced with FreightInformation.constants
export const EXCESSIVE_LENGTH_CLASS_REFERENCE = new Map<
  ExcessiveLengthClassification,
  string
>([
  ['excessive_length_8ft', 'Excessive length 8ft'] as const,
  ['excessive_length_12ft', 'Excessive length 12ft'] as const,
  ['excessive_length_16ft', 'Excessive length 16ft'] as const,
  ['excessive_length_20ft', 'Excessive length 20ft'] as const,
])
