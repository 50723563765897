import { useMemo } from 'react'

import { useSettings } from 'contexts/settings'

export const LTL_MODE_OPTION = {
  value: 'less_than_truckload',
  label: 'LTL',
} as const
export const FTL_MODE_OPTION = {
  value: 'full_truck_load',
  label: 'FTL',
} as const
export const PTL_MODE_OPTION = {
  value: 'partial_truckload',
  label: 'PTL',
} as const
export const IMDL_MODE_OPTION = { value: 'intermodal', label: 'IMDL' } as const
export const DRAY_MODE_OPTION = { value: 'drayage', label: 'Drayage' } as const
export const VLTL_MODE_OPTION = {
  value: 'volume_less_than_truckload',
  label: 'VLTL',
} as const
export const EXP_MODE_OPTION = { value: 'EXP', label: 'EXP' } as const

export type useModeOptionsParams = {
  includePTL?: boolean
  includeVLTL?: boolean
  includeDrayage?: boolean
  mergeLtlPtl?: boolean
  includeEXP?: boolean
}

export const useModeOptions = ({
  includePTL = false,
  includeVLTL = false,
  includeDrayage = true,
  mergeLtlPtl = false,
  includeEXP = false,
}: useModeOptionsParams = {}) => {
  const {
    values: [DRAYShipmentsEnabled],
  } = useSettings(['flags.ENABLE_DRAYAGE_SHIPMENTS'])

  const modeOptions = useMemo(() => {
    const baseModes = [
      FTL_MODE_OPTION,
      mergeLtlPtl
        ? { value: LTL_MODE_OPTION.value, label: 'LTL/PTL' }
        : LTL_MODE_OPTION,
      IMDL_MODE_OPTION,
    ]

    const additionalModes = [
      ...(includePTL ? [PTL_MODE_OPTION] : []),
      ...(includeVLTL ? [VLTL_MODE_OPTION] : []),
      ...(DRAYShipmentsEnabled && includeDrayage ? [DRAY_MODE_OPTION] : []),
      ...(includeEXP ? [EXP_MODE_OPTION] : []),
    ]

    return [...baseModes, ...additionalModes]
  }, [
    includePTL,
    DRAYShipmentsEnabled,
    includeDrayage,
    mergeLtlPtl,
    includeVLTL,
    includeEXP,
  ])

  return { modeOptions }
}
