import { useCallback, useEffect, useState } from 'react'
import type { ComponentType, PropsWithChildren } from 'react'
import type {
  FormatOptionLabelMeta,
  InputActionMeta,
  OptionProps,
  SelectComponentsConfig,
  Props as SelectProps,
  ActionMeta,
  SingleValue,
  MultiValue,
} from 'react-select'
import Select from 'react-select'

import { Container, IconClose, IconSearch } from './styles'

function DropdownIndicator() {
  return null
}
function IndicatorSeparator() {
  return null
}

type OptionType = { label: string; value: string }

function formatOptionLabel(
  option: OptionType,
  labelMeta: FormatOptionLabelMeta<OptionType>
) {
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: highlight(option.label, labelMeta.inputValue),
      }}
    />
  )
}

export function highlight(text: string, query: string) {
  if (!query) {
    return text
  }

  return text.replace(
    new RegExp(query, 'gi'),
    (highlighted) => `<b>${highlighted}</b>`
  )
}

interface Props<Entry extends OptionType> extends SelectProps<Entry> {
  readonly renderOption?: ComponentType<
    PropsWithChildren<OptionProps<Entry, false>>
  >
}

export default function Autocomplete<Entry extends OptionType>({
  onChange: onChangeProp,
  onInputChange: onInputChangeProp,
  options,
  renderOption,
  value: valueProp,
  ...rest
}: Props<Entry>) {
  const [inputValue, setInputValue] = useState<string>('')
  const [value, setValue] = useState<typeof valueProp>(valueProp)
  const onClear = useCallback(() => {
    setInputValue('')
    setValue(null)
  }, [setInputValue, setValue])
  const onChange = useCallback(
    (
      updatedInputValue: MultiValue<Entry> | SingleValue<Entry>,
      meta: ActionMeta<Entry>
    ) => {
      setValue(updatedInputValue)
      if (onChangeProp) {
        onChangeProp(updatedInputValue, meta)
      }
    },
    [onChangeProp]
  )
  const onInputChange = useCallback(
    (updatedInputValue: string, meta: InputActionMeta) => {
      setInputValue(updatedInputValue)
      if (onInputChangeProp) {
        onInputChangeProp(updatedInputValue, meta)
      }
    },
    [onInputChangeProp]
  )
  useEffect(() => {
    setValue(valueProp)
  }, [valueProp])
  const components: SelectComponentsConfig<Entry, false, any> = {
    DropdownIndicator,
    IndicatorSeparator,
  }
  if (renderOption) {
    components.Option = renderOption
  }
  return (
    <Container>
      <Select
        classNamePrefix="autocomplete"
        formatOptionLabel={formatOptionLabel}
        onChange={onChange}
        onInputChange={onInputChange}
        components={components}
        openMenuOnFocus={false}
        options={options}
        value={value}
        {...rest}
      />
      <IconSearch />
      {(value || inputValue) && (
        <IconClose aria-label="Clear" onClick={onClear} />
      )}
    </Container>
  )
}
