import { useEffect, useState, useCallback } from 'react'

import type { SelectedShipments } from '../types'

const useSelectItems = (key: string) => {
  const [selectedItems, setSelectedItems] = useState<SelectedShipments>(() => {
    const storedSelection = localStorage.getItem(key)
    return storedSelection ? JSON.parse(storedSelection) : []
  })
  // Update storage when selection changes
  useEffect(() => {
    // Not neccessary to store Select All, it introduces problem with table initialize
    if (selectedItems !== 'all') {
      localStorage.setItem(key, JSON.stringify(selectedItems))
    }
  }, [key, selectedItems])

  const clearSelection = () => {
    setSelectedItems([])
  }

  const toggleItem = useCallback(
    (items: SelectedShipments) => {
      setSelectedItems(items)
    },
    [setSelectedItems]
  )

  return {
    selectedItems,
    clearSelection,
    setSelectedItems,
    toggleItem,
  }
}

export default useSelectItems
