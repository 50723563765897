import { useState } from 'react'

import CarrierDetails from 'components/CarrierDetails'
import CarrierFormV1 from 'components/CarrierForm'
import SideBar from 'components/SideBar'
import type { Currency } from 'rfp/rfp.types'

export type CarrierPanelProps = {
  readonly carrier?: Carrier | null
  readonly isRecommendedCarriers?: boolean
  readonly setCarrierSelected: any
  readonly editOnly?: boolean
  readonly addOnly?: boolean
  readonly closePanel: () => void
  readonly rfpCurrency?: Currency
  readonly loadsmartDOT?: string
  readonly onSuccess?: () => void
  readonly addNewCarrierSuccessTrackData?: Record<string, any>
}

const CarrierPanel = ({
  carrier,
  setCarrierSelected,
  editOnly,
  addOnly,
  closePanel,
  onSuccess,
  addNewCarrierSuccessTrackData,
  rfpCurrency,
  loadsmartDOT,
  isRecommendedCarriers,
}: CarrierPanelProps) => {
  const [carrierToEdit, setCarrierToEdit] = useState<
    Carrier | null | undefined
  >(null)

  if (addOnly) {
    return (
      <SideBar showToggle={false} isOpen={addOnly}>
        <CarrierFormV1
          carrier={null}
          cancelCallback={() => {
            closePanel()
          }}
          closeSideBar={() => {
            closePanel()
          }}
          onSuccess={onSuccess}
          addNewCarrierSuccessTrackData={addNewCarrierSuccessTrackData}
        />
      </SideBar>
    )
  }

  if (editOnly) {
    return (
      <SideBar showToggle={false} isOpen={carrier !== null}>
        <CarrierFormV1
          carrier={carrier}
          cancelCallback={() => {
            closePanel()
          }}
          closeSideBar={() => {
            closePanel()
          }}
        />
      </SideBar>
    )
  }

  return (
    <>
      <SideBar showToggle={false} isOpen={!!carrierToEdit}>
        <CarrierFormV1
          carrier={carrierToEdit}
          cancelCallback={() => {
            setCarrierToEdit(null)
            setCarrierSelected(carrierToEdit)
          }}
          closeSideBar={() => {
            setCarrierToEdit(null)
            closePanel()
          }}
        />
      </SideBar>
      {/* @TODO: Legacy CarrierDetails, only used in RFPDetails page, 
        should be removed after migration */}
      <SideBar showToggle={false} isOpen={!!carrier}>
        <CarrierDetails
          carrier={carrier}
          onClose={() => {
            setCarrierSelected(null)
            closePanel()
          }}
          isRecommendedCarriers={isRecommendedCarriers}
          onClickEdit={() => {
            setCarrierToEdit(carrier)
            setCarrierSelected(null)
          }}
          rfpCurrency={rfpCurrency}
          loadsmartDOT={loadsmartDOT}
        />
      </SideBar>
    </>
  )
}

export default CarrierPanel
