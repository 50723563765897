import { Dialog, LoadingDots, Text } from '@loadsmart/loadsmart-ui'
import * as Sentry from '@sentry/react'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import type { RequestForProposal } from 'rfp/rfp.types'
import { partialUpdate } from 'rfp/services/rfp'
import analytics, {
  AnalyticsEvent,
  AnalyticsEventTrigger,
} from 'utils/analytics'
import { BID_TYPE, RFP_STATE } from 'utils/constants'

import FinalizedDialog from '../finalized-dialog'
import RFPAwardFinalizedDialog from '../rfp-award-finalized-dialog'

const SpinnerWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;

  svg {
    margin-right: 6px;
  }
`

interface Props {
  readonly rfp: RequestForProposal
  readonly isOpen: boolean
  readonly onClose: () => void
  readonly totalizers?: RFPTotalizers
}

export default function FinalizeDialog({
  rfp,
  isOpen,
  onClose,
  totalizers,
}: Props) {
  const history = useHistory()
  const queryClient = useQueryClient()

  const { mutate: updateRFP, isLoading } = useMutation({
    mutationFn: partialUpdate,
    onSuccess() {
      history.push({ hash: 'award' })
      queryClient.refetchQueries({ queryKey: ['deallocatedLanes'] })
      queryClient.refetchQueries({ queryKey: ['allocatedLanes'] })
    },
    onError(error) {
      toast.error(`Failed to award RFP.`)
      Sentry.captureException(error)
    },
  })

  if (rfp.state === RFP_STATE.FINALIZED) {
    if (
      rfp.bid_type === BID_TYPE.AWARD &&
      (totalizers?.total_awards_accepted ?? 0) > 0
    ) {
      return (
        <RFPAwardFinalizedDialog rfp={rfp} isOpen={isOpen} onClose={onClose} />
      )
    }

    return <FinalizedDialog rfp={rfp} isOpen={isOpen} onClose={onClose} />
  }

  return (
    <Dialog open={isOpen} scale="small" onOverlayClick={onClose}>
      <Dialog.Header>Finalize RFP</Dialog.Header>
      <Dialog.Body>
        <Text variant="body" color="color-neutral-dark">
          You are about to finalize this RFP.
        </Text>
        <br />
        <Text variant="body" color="color-neutral-dark">
          After that{' '}
          <b>you&apos;ll not be able to award carriers for this contract.</b>
          <br />
        </Text>
        <br />
        <Text variant="body" color="color-neutral-dark">
          Do you want to continue?
        </Text>
      </Dialog.Body>

      <Dialog.ActionConfirm
        onConfirm={() => {
          analytics.track(
            AnalyticsEvent.RFPFinalizeConfirmation,
            AnalyticsEventTrigger.click,
            rfp
          )
          updateRFP({
            id: rfp.id,
            payload: { state: RFP_STATE.FINALIZED },
            queryClient,
          })
        }}
        disabled={isLoading}
      >
        <SpinnerWrapper>
          {isLoading ? <LoadingDots variant="light" /> : 'Finalize'}
        </SpinnerWrapper>
      </Dialog.ActionConfirm>

      <Dialog.ActionCancel onCancel={onClose}>Cancel</Dialog.ActionCancel>
    </Dialog>
  )
}
