import { IconClose } from '@loadsmart/icons'
import { Button, Layout, Tag, Text } from '@loadsmart/loadsmart-ui'

import StringLimiter from '_shared_/components/StringLimiter'
import type { Filters } from 'rfp/components/lane-filters'

const TAG_LABEL: Record<keyof Filters, string> = {
  origin: 'Origin',
  destination: 'Destination',
  bidStatus: 'Bid',
  equipmentType: 'Equipment type',
}

const checkIfHasFilter = (filters: Filters) => {
  let key: keyof Filters

  for (key in filters) {
    if (filters[key].length > 0) {
      return true
    }
  }

  return false
}

export function TagFilters({
  filters,
  removeFilter,
  clearFilters,
}: {
  readonly filters: Filters
  readonly removeFilter: (type: keyof Filters) => void
  readonly clearFilters: () => void
}) {
  if (!checkIfHasFilter(filters)) {
    return null
  }

  return (
    <Layout.Group align="center" space="xs">
      <Text variant="caption-bold" color="color-neutral">
        APPLIED FILTERS:
      </Text>
      <>
        {Object.entries(filters).map(([key, item]) => {
          const label = TAG_LABEL[key as keyof Filters] || key

          if (item.length === 0) {
            return null
          }

          return (
            <Tag
              leading={null}
              onRemove={() => removeFilter(key as keyof Filters)}
              removable
              size="default"
              variant="default"
              key={`table-filter-${key}`}
            >
              <StringLimiter
                limiter={30}
                value={`${label}: ${
                  item.length > 1
                    ? `${item.length} selected`
                    : item[0]?.label?.replace(/\(\d+\)/gm, '')
                }`}
              />
            </Tag>
          )
        })}
      </>
      <Button
        scale="small"
        variant="tertiary"
        leading={<IconClose width={12} height={12} />}
        onClick={clearFilters}
      >
        Clear Filters
      </Button>
    </Layout.Group>
  )
}
