import {
  Text,
  Layout,
  TextField,
  Field,
  Checkbox,
  Card,
} from '@loadsmart/miranda-react'
import { Controller } from 'react-hook-form'

import { ValidationError } from '../componentUtils'
import { getFieldStatus } from '../utils'
import type { ContactsSectionProps } from './types'

export default function RFPContactsSection({
  shouldRender,
  setValue,
  isFieldDisabled,
  control,
  errors,
}: ContactsSectionProps) {
  if (!shouldRender) {
    return null
  }

  return (
    <Card collapsible>
      <Card.Title>Contact</Card.Title>
      <Card.Divider />
      <Card.Body>
        <Layout.Stack gap="spacing-5">
          <Text as="p" variant="body-sm" color="color-text-tertiary">
            This information will be shared with the carrier in the invitation
            of this RFP
          </Text>
          <Layout.Grid style={{ gridTemplateColumns: '1fr 1fr 1fr' }}>
            <Field
              data-testid="contact-name-field"
              style={{ flex: '1' }}
              status={getFieldStatus(errors.shipperName)}
            >
              <Field.Label>Name</Field.Label>
              <Controller
                name="shipperName"
                control={control}
                disabled={isFieldDisabled}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="text"
                    data-testid="inputName"
                    placeholder="Name"
                  />
                )}
              />
              <ValidationError
                error={errors.shipperName}
                message={errors.shipperName?.message}
              />
            </Field>
            <Field
              data-testid="contact-email-field"
              style={{ flex: '1' }}
              status={getFieldStatus(errors.shipperEmail)}
            >
              <Field.Label>Email</Field.Label>
              <Controller
                name="shipperEmail"
                control={control}
                disabled={isFieldDisabled}
                render={({ field }) => (
                  <TextField {...field} type="text" placeholder="Email" />
                )}
              />
              <ValidationError
                error={errors.shipperEmail}
                message={errors.shipperEmail?.message}
              />
            </Field>
            <Field
              data-testid="contact-phone-field"
              style={{ flex: '1' }}
              status={getFieldStatus(errors.shipperPhone)}
            >
              <Field.Label>Phone</Field.Label>
              <Controller
                name="shipperPhone"
                control={control}
                disabled={isFieldDisabled}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="text"
                    placeholder="Phone Number"
                  />
                )}
              />
              <ValidationError
                error={errors.shipperPhone}
                message={errors.shipperPhone?.message}
              />
            </Field>
          </Layout.Grid>
          <Layout.Group
            style={{ flex: '1' }}
            align="flex-end"
            justify="flex-start"
            data-testid="save-contact-checkbox"
          >
            <Controller
              name="saveContactInfo"
              control={control}
              disabled={isFieldDisabled}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  onChange={(event) =>
                    setValue(field.name, event.target.checked)
                  }
                  checked={field.value}
                  data-testid="rfpform-contact-checkbox"
                >
                  <Text variant="body-md" color="color-text-tertiary">
                    Save contact info for future RFPs
                  </Text>
                </Checkbox>
              )}
            />
          </Layout.Group>
        </Layout.Stack>
      </Card.Body>
    </Card>
  )
}
