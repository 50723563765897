import type { SelectDatasource } from '@loadsmart/loadsmart-ui/dist/components/Select/Select.types'
import { useFormik as useForm } from 'formik'

import { mapFiltersToURLSearchParams } from 'screens/Shipper/FacilityManagement/useFacilities'
import type { FacilityV2 } from 'services/facilities'
import { getFacilities } from 'services/facilities'
import { facilitiesV2Adapter } from 'utils/adapters/facilities'

interface AdaptedFacilityV2 extends FacilityV2 {
  label: string
  value: string
  className: string
}

const facilityV2SearchConfig = {
  type: 'facility',
  adapter: {
    getKey: (facility: AdaptedFacilityV2) => facility.uuid,
    getLabel: (facility: AdaptedFacilityV2) =>
      facility?.label || facility?.name || facility.address,
  },
}

export function useSelectFacilityV2Search(): SelectDatasource<AdaptedFacilityV2> {
  return {
    ...facilityV2SearchConfig,
    async fetch({ query }) {
      const { results: facilities } = await getFacilities({
        params: mapFiltersToURLSearchParams({
          limit: 100,
          search: query,
          sort: 'name',
        }),
      })
      return facilitiesV2Adapter({
        facilities,
      })
    },
  }
}

export { useForm }
