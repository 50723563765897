import { useEffect, useState } from 'react'

import { useShipperInfo } from 'hooks/useQuery'

import { HubspotFormContainer } from './styles'
import type { HiddenUTMFields } from './types'

const HUBSPOT_REQUEST_DEMO_FORM_CONFIG = {
  region: 'na1',
  portalId: '5041527',
  formId: '9e387220-ae0c-460c-be6a-326242cddd8d',
  sfdcCampaignId: '7015G000001qWCeQAM',
}

const HUBSPORT_FORM_TARGET = 'hubspot-request-demo-form'

/**
 * We don't want to include jQuery just to use some callbacks (onFormReady, onFormSubmitted and so on) from HubSpot.
 * So we create this "pseudo" jQuery.
 *
 * Code taken from: https://www.unstack.com/blog/hubspot-on-form-submit-callbacks-without-jquery
 */
function createHubspotPseudoJQuery() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  window.jQuery =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    window.jQuery ||
    function (nodeOrSelector: unknown) {
      if (typeof nodeOrSelector == 'string') {
        return document.querySelector(nodeOrSelector)
      }
      return nodeOrSelector
    }
}

const setHubspotFormIntegration = (options: {
  portalId: string
  formId: string
  sfdcCampaignId: string
  target: string
  onFormReady: (form: HTMLFormElement) => void
  onFormSubmitted: (form: HTMLFormElement) => void
}) => {
  createHubspotPseudoJQuery()
  const script = document.createElement('script')

  script.src = 'https://js.hsforms.net/forms/v2.js'
  script.onload = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    if (window.hbspt) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      window.hbspt.forms.create(options)
    }
  }

  document.body.appendChild(script)
}

export const HubspotForm = ({
  onConfirm,
  UTMFields,
}: {
  readonly onConfirm: () => void
  readonly UTMFields?: HiddenUTMFields
}) => {
  const [formInitialized, setFormInitialized] = useState(false)

  const { data: shipperInfo } = useShipperInfo()

  useEffect(() => {
    if (!formInitialized && shipperInfo) {
      setHubspotFormIntegration({
        portalId: HUBSPOT_REQUEST_DEMO_FORM_CONFIG.portalId,
        formId: HUBSPOT_REQUEST_DEMO_FORM_CONFIG.formId,
        sfdcCampaignId: HUBSPOT_REQUEST_DEMO_FORM_CONFIG.sfdcCampaignId,
        target: `#${HUBSPORT_FORM_TARGET}`,
        onFormReady: (form) => {
          const [firstNameInput, phoneInput, emailInput] = Array.from(
            form.elements
          )

          firstNameInput.setAttribute(
            'value',
            shipperInfo?.name?.split(' ')[0] ?? ''
          )
          firstNameInput.dispatchEvent(new Event('input', { bubbles: true }))

          phoneInput.setAttribute('value', shipperInfo.phone_number ?? '')
          phoneInput.dispatchEvent(new Event('input', { bubbles: true }))

          emailInput.setAttribute('value', shipperInfo.email ?? '')
          emailInput.dispatchEvent(new Event('input', { bubbles: true }))

          if (UTMFields) {
            for (const key in UTMFields) {
              const hiddenInput = document.createElement('input')
              hiddenInput.setAttribute('type', 'hidden')
              hiddenInput.setAttribute('name', key)
              hiddenInput.setAttribute('value', UTMFields[key])
              form.appendChild(hiddenInput)
            }
          }
        },
        onFormSubmitted: () => {
          onConfirm()
        },
      })

      setFormInitialized(true)
    }
  }, [UTMFields, formInitialized, onConfirm, shipperInfo])

  return (
    <HubspotFormContainer>
      <div id={HUBSPORT_FORM_TARGET} />
    </HubspotFormContainer>
  )
}
