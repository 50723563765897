import SideBar from 'components/SideBar'
import RecommendedCarriers from 'rfp/rfp-details/components/recommended-carriers'
import type { RequestForProposal } from 'rfp/rfp.types'

const RecommendedCarriersSideBar = ({
  modalState,
  rfp,
  selectedLane,
  recommendedCarriersData,
  closeModal,
  isLoadingRecommendedCarriers,
  carriersRecommendationsError,
  refetchCarrierInvites,
}: {
  readonly modalState: boolean
  readonly closeModal: () => void
  readonly rfp: RequestForProposal
  readonly selectedLane: Lane | undefined
  readonly recommendedCarriersData: RecommendedCarrier[] | undefined
  readonly isLoadingRecommendedCarriers: boolean
  readonly carriersRecommendationsError: boolean
  readonly refetchCarrierInvites: () => Promise<unknown>
}) => {
  return (
    <SideBar showToggle={false} isOpen={modalState}>
      {selectedLane && (
        <RecommendedCarriers
          rfp={rfp}
          onClose={closeModal}
          isLoadingRecommendedCarriers={isLoadingRecommendedCarriers}
          data={recommendedCarriersData}
          notFoundCarriersRecommendations={carriersRecommendationsError}
          refetchCarrierInvites={refetchCarrierInvites}
        />
      )}
    </SideBar>
  )
}

export default RecommendedCarriersSideBar
