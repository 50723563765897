import { useCallback, useState } from 'react'
import { useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { partialUpdate } from 'rfp/services/rfp'

type Props = {
  extraFieldHeaders: string[]
  laneExtraFieldHeaders?: { [key: string]: { headers: string[] } }
  field: string
  action: 'add' | 'delete'
  rfpId: string | number
  callback: () => void
}

const messages = {
  success: {
    add: (field: string) => `Successfully added new field "${field}".`,
    delete: (field: string) => `Successfully deleted field "${field}".`,
  },
  error: {
    add: (_: string) => 'Could not add new field.',
    delete: (field: string) => `Could not delete field "${field}".`,
  },
}

export function useDynamicFields({
  extraFieldHeaders,
  laneExtraFieldHeaders,
  field,
  action,
  rfpId,
  callback,
}: Props) {
  const queryClient = useQueryClient()
  const [isSubmitting, setSubmitting] = useState(false)

  const actionFn = useCallback(async () => {
    setSubmitting(true)
    try {
      const fields = new Set(extraFieldHeaders)
      fields[action](field)
      await partialUpdate({
        id: rfpId,
        payload: {
          extra_field_headers: Array.from(fields),
          lane_extra_fields: laneExtraFieldHeaders,
        },
        queryClient,
      })
      toast.success(messages.success[action](field))
    } catch {
      toast.error(messages.error[action](field))
    } finally {
      callback()
      setSubmitting(false)
    }
  }, [
    extraFieldHeaders,
    action,
    field,
    rfpId,
    callback,
    queryClient,
    laneExtraFieldHeaders,
  ])

  return { isSubmitting, action: actionFn }
}
