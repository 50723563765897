import { Text, Radio } from '@loadsmart/loadsmart-ui'
import { useMemo, useState } from 'react'
import type { UseTableRowProps } from 'react-table'

import StringLimiter from '_shared_/components/StringLimiter'
import TableActions from '_shared_/components/TableActions'
import EmptyStateImage from 'assets/imgs/emptyLanes.png'
import TableV3 from 'components/TableV3'
import {
  getDestOrDeliveryColumn,
  getMileageColumn,
  getModeAndEquipmentColumn,
  getOriginOrPickupColumn,
} from 'rfp/components/table/columns'
import { useLaneIdMaxLength } from 'rfp/components/table/tableUtils'
import LaneStatusIndicator from 'rfp/rfp-details/components/lane-status-indicator'
import MarketBenchmarkTooltip from 'rfp/rfp-details/components/live-market-rate/MarketBenchmarkTooltip'
import MultiCurrencyValue from 'rfp/rfp-details/components/MultiCurrencyValue'
import type { Currency, RequestForProposal } from 'rfp/rfp.types'
import { RFP_STATE } from 'utils/constants'
import { isMultiCurrency } from 'utils/currency'
import { numberFormatter } from 'utils/numbers'

import { BidsCell } from '../LanesTableCells'
import type { TableData } from '../LanesTableV2/types'
import { getLaneStatus } from '../LanesTableV2/utils'
import {
  ActionButtonCell,
  FlatRateCell,
  IncumbentCell,
  VersusCell,
} from './Cells'
import {
  Container,
  EmptyButtonsRow,
  EmptyLanesContainer,
  EmptyLanesImage,
  StyledText,
  HeaderCellWithActions,
  StyledLabel,
  NameContainer,
} from './styles'

type TableRow = UseTableRowProps<TableData>

enum RadioOptions {
  LOWEST = 'lowest_bid',
  AVERAGE = 'average_bid',
  HIGHEST = 'highest_bid',
  LAST_AWARD = 'last_award',
  TARGET_RATE = 'target_rate',
  SPOT_MARKET = 'market_rate',
  SHORT_CONTRACT = 'contract_rate',
}

const optionsDict: Record<string, string> = {
  last_award: 'LAST AWARD',
  target_rate: 'TARGET RATE',
  market_rate: 'MARKET BENCHMARK',
  contract_rate: 'SHORT CONTR.',
  lowest_bid: 'LOWEST BID',
  average_bid: 'AVG. BID',
  highest_bid: 'HIGHEST BID',
}

type DefaultAPIReturnType = string | number | undefined

function renderCellLaneId(value: string, laneIdMaxLength: number) {
  return <StringLimiter limiter={laneIdMaxLength} value={value} />
}

export default function LanesTableV3({
  lanes,
  onRowClick,
  addLaneButton,
  importLaneButton,
  rfpState,
  rfpCurrency,
  rfpDistanceType,
  isLoading,
  totalLanes,
  paginationHandler,
  limit,
  changeSorting,
  sort,
  isSpotMode,
  bordered = false,
}: {
  readonly rfpState: string
  readonly rfpCurrency: Currency
  readonly rfpDistanceType: RequestForProposal['distance_type']
  readonly lanes?: Lane[]
  readonly isLoading: boolean
  readonly isSpotMode?: boolean
  readonly addLaneButton?: any
  readonly importLaneButton?: any
  readonly onRowClick: (laneId: number) => void
  readonly totalLanes: number
  readonly limit?: number
  readonly paginationHandler?: (page: number) => void
  readonly changeSorting?: (arg0: string) => void
  readonly sort: { column: string; direction: string }
  readonly bordered?: boolean
}) {
  const displayMultiCurrency = isMultiCurrency(rfpCurrency)
  const [bidOption, setRadioOptionOne] = useState<string>(RadioOptions.LOWEST)
  const [rateOption, setRadioOptionTwo] = useState<string>(
    displayMultiCurrency || rfpState === RFP_STATE.DRAFT
      ? RadioOptions.LAST_AWARD
      : RadioOptions.SPOT_MARKET
  )

  const laneIdMaxLength = useLaneIdMaxLength()

  const columns = useMemo(() => {
    const draftColumns = [
      {
        Header: 'INCUMBENT',
        id: 'incumbent_carrier_name',
        accessor: 'incumbent_carrier_name',
        className: 'text-left medium',
        Cell: function CellIncumbent({
          value,
        }: {
          readonly value: TableData['incumbent_carrier_name']
        }) {
          return <IncumbentCell value={value} />
        },
      },
      {
        Header: (
          <HeaderCellWithActions>
            <NameContainer>{optionsDict[rateOption]}</NameContainer>
            {rateOption === 'market_rate' && <MarketBenchmarkTooltip />}
            <TableActions>
              {!displayMultiCurrency && (
                <StyledLabel htmlFor={RadioOptions.SPOT_MARKET}>
                  <Radio
                    onChange={(e) => setRadioOptionTwo(e.target.value)}
                    id={RadioOptions.SPOT_MARKET}
                    value={RadioOptions.SPOT_MARKET}
                    checked={rateOption === RadioOptions.SPOT_MARKET}
                    name="optionsTwo"
                  />
                  Market Benchmark
                </StyledLabel>
              )}
              <StyledLabel htmlFor={RadioOptions.TARGET_RATE}>
                <Radio
                  onChange={(e) => {
                    setRadioOptionTwo(e.target.value)
                  }}
                  id={RadioOptions.TARGET_RATE}
                  value={RadioOptions.TARGET_RATE}
                  checked={rateOption === RadioOptions.TARGET_RATE}
                  name="optionsTwo"
                />
                Target Rate
              </StyledLabel>
              <StyledLabel htmlFor={RadioOptions.LAST_AWARD}>
                <Radio
                  onChange={(e) => {
                    setRadioOptionTwo(e.target.value)
                  }}
                  id={RadioOptions.LAST_AWARD}
                  value={RadioOptions.LAST_AWARD}
                  checked={rateOption === RadioOptions.LAST_AWARD}
                  name="optionsTwo"
                />
                Last Award
              </StyledLabel>
            </TableActions>
          </HeaderCellWithActions>
        ),
        id: rateOption,
        accessor: rateOption,
        className: 'text-left medium',
        Cell: function CellFlatRate({ row }: { readonly row: any }) {
          return (
            <FlatRateCell
              displayMultiCurrency={displayMultiCurrency}
              rateOption={rateOption}
              rfpCurrency={rfpCurrency}
              row={row}
            />
          )
        },
      },
    ]
    const publishedColumns = [
      {
        Header: 'VS →',
        id: 'vs',
        className: 'text-left text-nowrap',
        accessor: '',
        Cell: function CellVersus({ row }: { readonly row: any }) {
          return (
            <VersusCell
              bidOption={bidOption}
              rateOption={rateOption}
              row={row}
            />
          )
        },
      },
      {
        Header: (
          <HeaderCellWithActions>
            <NameContainer>{optionsDict[rateOption]}</NameContainer>
            {rateOption === 'market_rate' && <MarketBenchmarkTooltip />}
            <TableActions>
              {!displayMultiCurrency && (
                <StyledLabel htmlFor={RadioOptions.SPOT_MARKET}>
                  <Radio
                    onChange={(e) => setRadioOptionTwo(e.target.value)}
                    id={RadioOptions.SPOT_MARKET}
                    value={RadioOptions.SPOT_MARKET}
                    checked={rateOption === RadioOptions.SPOT_MARKET}
                    name="optionsTwo"
                  />
                  Market Benchmark
                </StyledLabel>
              )}
              <StyledLabel htmlFor={RadioOptions.TARGET_RATE}>
                <Radio
                  onChange={(e) => {
                    setRadioOptionTwo(e.target.value)
                  }}
                  id={RadioOptions.TARGET_RATE}
                  value={RadioOptions.TARGET_RATE}
                  checked={rateOption === RadioOptions.TARGET_RATE}
                  name="optionsTwo"
                />
                Target Rate
              </StyledLabel>
              <StyledLabel htmlFor={RadioOptions.LAST_AWARD}>
                <Radio
                  onChange={(e) => {
                    setRadioOptionTwo(e.target.value)
                  }}
                  id={RadioOptions.LAST_AWARD}
                  value={RadioOptions.LAST_AWARD}
                  checked={rateOption === RadioOptions.LAST_AWARD}
                  name="optionsTwo"
                />
                Last Award
              </StyledLabel>
            </TableActions>
          </HeaderCellWithActions>
        ),
        id: rateOption,
        accessor: rateOption,
        className: 'text-left medium',
        Cell: function CellFlatRate({ row }: { readonly row: any }) {
          return (
            <FlatRateCell
              displayMultiCurrency={displayMultiCurrency}
              rateOption={rateOption}
              rfpCurrency={rfpCurrency}
              row={row}
            />
          )
        },
      },
    ]
    const laneDetailsColumns = [
      {
        Header: 'BIDS',
        id: 'proposals_count',
        className: 'text-left',
        accessor: ({
          proposals_count,
        }: {
          proposals_count: DefaultAPIReturnType
        }) => numberFormatter(proposals_count),
        Cell: function CellBid({
          value,
        }: {
          readonly value: TableData['proposals_count']
        }) {
          return <BidsCell proposalsCount={value} />
        },
      },
      {
        Header: (
          <HeaderCellWithActions>
            <NameContainer>{optionsDict[bidOption]}</NameContainer>
            <TableActions>
              <StyledLabel htmlFor={RadioOptions.LOWEST}>
                <Radio
                  onChange={(e) => setRadioOptionOne(e.target.value)}
                  id={RadioOptions.LOWEST}
                  value={RadioOptions.LOWEST}
                  checked={bidOption === RadioOptions.LOWEST}
                  name="optionsOne"
                />
                Lowest Bid
              </StyledLabel>
              <StyledLabel htmlFor={RadioOptions.AVERAGE}>
                <Radio
                  onChange={(e) => {
                    setRadioOptionOne(e.target.value)
                  }}
                  id={RadioOptions.AVERAGE}
                  value={RadioOptions.AVERAGE}
                  checked={bidOption === RadioOptions.AVERAGE}
                  name="optionsOne"
                />
                Avg. Bid
              </StyledLabel>
              <StyledLabel htmlFor={RadioOptions.HIGHEST}>
                <Radio
                  onChange={(e) => {
                    setRadioOptionOne(e.target.value)
                  }}
                  id={RadioOptions.HIGHEST}
                  value={RadioOptions.HIGHEST}
                  checked={bidOption === RadioOptions.HIGHEST}
                  name="optionsOne"
                />
                Highest Bid
              </StyledLabel>
            </TableActions>
          </HeaderCellWithActions>
        ),
        id: bidOption,
        accessor: bidOption,
        className: 'text-left',
        Cell: function CellLowestBids({ row }: { readonly row: any }) {
          const valueField = displayMultiCurrency
            ? `converted_${bidOption}`
            : bidOption

          return (
            <MultiCurrencyValue
              value={row.original[valueField]}
              rfpCurrency={rfpCurrency}
              nonConvertedValue={row.original[bidOption]}
            />
          )
        },
      },
      ...publishedColumns,
    ]

    const getCustomColumns = () => {
      if (rfpState === RFP_STATE.DRAFT) {
        return draftColumns
      }

      return laneDetailsColumns
    }

    return () => [
      {
        Header: '',
        id: 'state',
        className: 'text-center small',
        accessor: (lane: TableData) => getLaneStatus(lane, rfpState),
        Cell: function CellLaneState({ row }: { readonly row: TableRow }) {
          const lane = row.original as Lane
          return (
            <LaneStatusIndicator
              lane={lane}
              rfpState={rfpState as RfpState}
              isSpotMode={Boolean(isSpotMode)}
            />
          )
        },
      },
      {
        Header: 'LANE ID',
        id: 'lane_id',
        accessor: 'lane_id',
        className: 'text-left limit-size',
        limitSize: true,
        Cell: function render({ value }: { value: TableData['lane_id'] }) {
          return renderCellLaneId(value, laneIdMaxLength)
        },
      },
      getOriginOrPickupColumn(),
      getDestOrDeliveryColumn(),
      getMileageColumn(rfpDistanceType),
      getModeAndEquipmentColumn(),
      {
        Header: 'VOLUME',
        id: 'volume',
        className: 'text-left',
        accessor: ({ volume }: { volume: DefaultAPIReturnType }) =>
          numberFormatter(volume),
      },
      ...getCustomColumns(),
      {
        Header: '',
        id: 'actions',
        className: 'flex flex-right',
        style: {
          justifyContent: 'flex-end',
          display: 'flex',
        },
        Cell: function CellActions() {
          return <ActionButtonCell rfpState={rfpState} />
        },
      },
    ]
  }, [
    rateOption,
    displayMultiCurrency,
    bidOption,
    rfpCurrency,
    rfpState,
    rfpDistanceType,
    isSpotMode,
    laneIdMaxLength,
  ])

  if (!isLoading && (!lanes || lanes.length <= 0)) {
    return (
      <EmptyLanesContainer border="v2">
        <EmptyLanesImage src={EmptyStateImage} />
        <Text variant="body-bold">Add Your Lanes</Text>
        <StyledText size="small">
          Add single lanes or import lanes in batch for this RFP.
        </StyledText>
        <EmptyButtonsRow>
          {addLaneButton}
          {importLaneButton}
        </EmptyButtonsRow>
      </EmptyLanesContainer>
    )
  }

  return (
    <Container>
      <TableV3
        bordered={bordered}
        isLoading={isLoading}
        columns={columns}
        entries={lanes || []}
        onRowClick={(_event, row) => onRowClick(row.original.id)}
        sortable
        pagination
        paginationHandler={paginationHandler}
        pageSize={limit}
        resultsCount={totalLanes}
        changeSorting={changeSorting}
        sort={sort}
      />
    </Container>
  )
}
