import { Layout, Tag, Text, Tooltip } from '@loadsmart/miranda-react'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'

import { MODES_DICT } from 'utils/constants'
import { getEquipmentType } from 'utils/equipmentTypeV2'

export default function ModeAndEquipmentTag({
  preferences,
}: {
  readonly preferences: LanePreference[]
}) {
  const { mode, equipment_type } = preferences[0] || {}

  return (
    <Layout.Group style={{ flexWrap: 'nowrap' }} gap="spacing-0-5">
      <Tag variant="neutral" size="default">
        {MODES_DICT[mode]}&nbsp;&rarr;&nbsp;
        {getEquipmentType(equipment_type)?.label}
      </Tag>
      {preferences.length > 1 && (
        <Tooltip
          message={
            <Layout.Stack gap="spacing-0-5">
              <Text color="color-text-inverted" variant="body-md-bold">
                Other modes:
              </Text>
              <ul
                style={{
                  paddingLeft: '0px',
                  marginTop: '0px',
                  marginBottom: '0px',
                  marginLeft: toCSSValue('spacing-6'),
                }}
              >
                {preferences.slice(1).map((preference) => (
                  <li key={`${preference.mode}-${preference.equipment_type}}`}>
                    {MODES_DICT[preference.mode]}&nbsp;&rarr;&nbsp;
                    {getEquipmentType(preference.equipment_type)?.label}
                  </li>
                ))}
              </ul>
            </Layout.Stack>
          }
          trigger="hover"
          placement="bottom"
        >
          <Tag>{`+${preferences.length - 1}`}</Tag>
        </Tooltip>
      )}
    </Layout.Group>
  )
}
