import { useEffect, useState } from 'react'

export function useCarrierPanel(displayedCarriers: any) {
  const [selectedCarrier, setSelectedCarrier] = useState<Carrier>()
  const [isOpenForEdition, setIsOpenForEdition] = useState<boolean>(false)
  const [isOpenForAddNewCarrier, setIsOpenForAddNewCarrier] =
    useState<boolean>(false)

  const closeCarrierPanel = () => {
    setSelectedCarrier(undefined)
    setIsOpenForEdition(false)
    setIsOpenForAddNewCarrier(false)
  }

  const openForEdition = (carrier?: Carrier) => {
    setIsOpenForEdition(true)
    setSelectedCarrier(carrier)
  }

  const openForAddNewCarrier = () => {
    setIsOpenForAddNewCarrier(true)
  }

  useEffect(() => {
    if (selectedCarrier && displayedCarriers) {
      setSelectedCarrier(
        displayedCarriers.filter(
          (carrier: Carrier) => carrier.id === selectedCarrier.id
        )[0]
      )
    }
  }, [displayedCarriers, selectedCarrier, setSelectedCarrier])

  return {
    selectedCarrier,
    closeCarrierPanel,
    setSelectedCarrier,
    isOpenForEdition,
    isOpenForAddNewCarrier,
    openForEdition,
    openForAddNewCarrier,
  }
}
