import { IconCheck, IconPlus, IconUpload } from '@loadsmart/icons'
import {
  Banner,
  BannerAction,
  LoadingDots,
  Spinner,
} from '@loadsmart/loadsmart-ui'
import * as Sentry from '@sentry/react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import type { Dispatch, SetStateAction } from 'react'
import { Helmet } from 'react-helmet'
import { useMutation, useQueryClient } from 'react-query'
import type { QueryClient } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  DownloadButton,
  LoaderContainer,
  UploadButton,
  Wrapper,
} from 'components/TableV3/styles'
import { usePusher } from 'contexts/pusher'
import { useModal } from 'hooks/useModal'
import {
  useCarrierInvitePerRfpQuota,
  useCarrierInvites,
  useDeallocatedLanes,
  useShipperRfpQuota,
} from 'hooks/useQuery'
import { useSearchParams } from 'hooks/useSearchParams'
import HeaderCardV2 from 'rfp/components/header-card-v2'
import {
  useModeEquipmentTypes,
  useRfpModes,
  useRfpRetrieve,
  useRFPTotalizers,
} from 'rfp/hooks/rfp'
import DownloadDialog from 'rfp/rfp-details/components/DownloadDialog'
import type {
  RequestForProposal,
  RequestForProposalDetail,
} from 'rfp/rfp.types'
import { downloadSample, duplicate, partialUpdate } from 'rfp/services/rfp'
import { AppRoutes } from 'router/AppRoutes'
import { routeEvent } from 'services/web-sockets/handlers'
import analytics, {
  AnalyticsEvent,
  AnalyticsEventTrigger,
} from 'utils/analytics'
import {
  TIMEOUT_EXPIRATION,
  WS_EVENT_RFP_LANE_CHANGED,
  WS_EVENT_RFP_PROPOSAL_ADDED,
  WS_EVENT_RFP_PROPOSAL_DELETED,
} from 'utils/constants'
import { downloadData } from 'utils/download'
import { canSubmit, isDraftRfp } from 'utils/rfp'

/** since CRA does not allow webpack customization, we are using an
 * inline webpack loader here to avoid ejecting CRA initial configs */
import AwardDialog from './AwardDialog'
import FinalizeDialog from './components/finalize-dialog'
import NewLaneDrawer from './components/lane-form/new-lane-form'
import type { RFPExtraFieldsHeadersType } from './components/lane-form/new-lane-form/types'
import PublishDialogV2 from './components/publish-dialog-v2'
import Scenarios from './Scenarios'
import { BannerWrapper, RFPData, SpinnerWrapper } from './styles'
import type { UploadLanesSuccessResponse } from './types'
import { publishRFPCallback, publishRfpOnError } from './utils'

const LIMIT = 50

const defaultParams = {
  page: 0,
  offset: 0,
  limit: LIMIT,
  sort: {
    column: 'lane_id',
    direction: 'asc',
  },
}

const importLanesButton = ({
  onClick,
  disabled,
  isUploading,
}: {
  onClick: () => void
  disabled: boolean
  isUploading: boolean
}) => (
  <UploadButton
    variant="secondary"
    onClick={onClick}
    disabled={disabled}
    style={{ marginRight: 10 }}
  >
    <IconUpload width={12} />
    {isUploading ? (
      <LoaderContainer>
        <LoadingDots />
      </LoaderContainer>
    ) : (
      'Import Lanes'
    )}
  </UploadButton>
)

const addLanesButton = ({
  onClick,
  disabled,
}: {
  onClick: () => void
  disabled: boolean
}) => (
  <DownloadButton
    type="button"
    data-testid="button-create-lane"
    variant="main"
    onClick={onClick}
    disabled={disabled}
    style={{ marginRight: 0 }}
  >
    <IconPlus width={12} />
    Add Lane
  </DownloadButton>
)

type DialogProps = {
  modalState: boolean
  closeModal: () => void
  openModal: () => void
  toggleModal: () => void
  setModalState: Dispatch<SetStateAction<boolean>>
}

type RFPDialogProps = {
  readonly rfp: RequestForProposalDetail
  readonly publishDialog: DialogProps
  readonly isPublishing: boolean
  readonly publishRFP: (variables: {
    id: string | number
    payload: Partial<RequestForProposal>
    queryClient: QueryClient
  }) => void
  readonly lanesCount: number
  readonly downloadDialog: DialogProps
  readonly finalizeDialog: DialogProps
  readonly awardDialog: DialogProps
  readonly totalizers: RFPTotalizers | undefined
  readonly rfpLaneDetails: { mode: string; multimode: boolean }
  readonly asyncReportData: { type: string; format: string }
}

const RFPDialogs = ({
  rfp,
  publishDialog,
  isPublishing,
  publishRFP,
  lanesCount,
  downloadDialog,
  finalizeDialog,
  awardDialog,
  totalizers,
  rfpLaneDetails,
  asyncReportData,
}: RFPDialogProps) => {
  if (!rfp) {
    return null
  }

  return (
    <>
      <PublishDialogV2
        rfp={rfp}
        isOpen={publishDialog.modalState}
        isPublishing={isPublishing}
        onClose={publishDialog.closeModal}
        publishRFP={publishRFP}
        lanesCount={lanesCount}
        rfpLaneDetails={rfpLaneDetails}
      />

      <DownloadDialog
        rfp={rfp}
        isOpen={downloadDialog.modalState}
        onClose={downloadDialog.closeModal}
        asyncReportData={asyncReportData}
      />

      <FinalizeDialog
        rfp={rfp}
        isOpen={finalizeDialog.modalState}
        onClose={finalizeDialog.closeModal}
      />
      <AwardDialog
        rfp={rfp}
        isOpen={awardDialog.modalState}
        onClose={awardDialog.closeModal}
        totalizers={totalizers}
      />
    </>
  )
}

export default function RFPDetailsV1() {
  const history = useHistory()
  const queryClient = useQueryClient()
  const { rfpId } = useParams<{ rfpId: string }>()

  const [queryParams] = useSearchParams()

  const { data: modes } = useRfpModes()
  const { data: equipmentTypesByMode } = useModeEquipmentTypes()

  const equipmentTypeOptions = useMemo(() => {
    if (!equipmentTypesByMode) {
      return {}
    }

    return Object.keys(equipmentTypesByMode).reduce((acc, mode) => {
      const equipmentTypes = equipmentTypesByMode[mode].map((equipmentType) => {
        return {
          value: equipmentType.value,
          label: equipmentType.value,
        }
      })

      return {
        ...acc,
        [mode]: equipmentTypes,
      }
    }, {})
  }, [equipmentTypesByMode])

  const rfpChannel = `private-rfp-${rfpId}`
  const [selectedLane, setSelectedLane] = useState<Lane>()
  const [isLaneDrawerOpen, setIsLaneDrawerOpen] = useState(false)

  const [asyncReportData, setAsyncReportData] = useState<{
    type: string
    format: string
  }>({ type: '', format: '' })

  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [isUploadError, setIsUploadError] = useState<boolean>(false)
  const [uploadErrorMessage, setUploadErrorMessage] = useState<string>('')
  const [isDownloading, setIsDownloading] = useState<boolean>(false)

  const fileInput = useRef<HTMLInputElement>(null)
  const publishDialog = useModal()
  const awardDialog = useModal()
  const finalizeDialog = useModal()
  const downloadDialog = useModal()
  const sideBar = useModal()

  const {
    data: rfp = {} as RequestForProposalDetail,
    isLoading,
    refetch: refetchRfp,
  } = useRfpRetrieve(rfpId, {
    refetchOnWindowFocus: false,
    notifyOnChangeProps: 'tracked',
  })

  const { data: totalizers, isLoading: isLoadingTotalizers } =
    useRFPTotalizers(rfpId)

  const {
    data: invites = [] as NewCarrierInvite[],
    isLoading: isLoadingCarrierInvites,
    status: carrierInviteStatus,
    refetch: refetchCarrierInvites,
    isError: isLoadingCarrierError,
    error: loadingCarrierError,
  } = useCarrierInvites(rfpId, {
    refetchOnWindowFocus: false,
    cacheTime: 0,
  })

  useEffect(() => {
    refetchRfp()
    refetchCarrierInvites()
  }, [refetchCarrierInvites, refetchRfp])

  useEffect(() => {
    if (isLoadingCarrierError) {
      toast.error(
        'Could not retrieve the carriers invites, please contact an admin'
      )
      Sentry.captureException(loadingCarrierError)
    }
  }, [loadingCarrierError, isLoadingCarrierError])
  useEffect(() => {
    if (queryParams.size > 0) {
      setAsyncReportData({
        type: queryParams.get('report_type') || '',
        format: queryParams.get('report_format') || '',
      })
      downloadDialog.openModal()
    }
  }, [queryParams, downloadDialog])

  usePusher({
    channel: rfpChannel,
    events: [
      WS_EVENT_RFP_LANE_CHANGED,
      WS_EVENT_RFP_PROPOSAL_ADDED,
      WS_EVENT_RFP_PROPOSAL_DELETED,
    ],
    onMessage: (event, data) => {
      routeEvent(
        {
          event,
          data,
        },
        queryClient
      )
    },
  })

  const { mutate: archiveRFP, isLoading: isArchiving } = useMutation({
    mutationFn: partialUpdate,
    onSuccess() {
      refetchRfp()
    },
    onSettled() {
      toast.success('RFP Archived')
    },
    onError(error) {
      toast.error(`Failed to archive RFP.`)
      Sentry.captureException(error)
    },
  })

  const { mutate: duplicateRFP, isLoading: isDuplicating } = useMutation({
    mutationFn: duplicate,
    onSuccess({ id }) {
      history.replace(`/shipper/rfp/${id}`)
    },
    onSettled() {
      toast.success('RFP Duplicated')
    },
    onError(error) {
      toast.error(`Failed to duplicate RFP.`)
      Sentry.captureException(error)
    },
  })

  const { data: rfpQuotaData, refetch: refetchRfpQuota } = useShipperRfpQuota(
    rfp.uuid
  )
  const { data: invitesPerRfpData, refetch: refetchCarrierInviteQuota } =
    useCarrierInvitePerRfpQuota(rfp.uuid)

  const { mutate: publishRFP, isLoading: isPublishing } = useMutation({
    mutationFn: partialUpdate,
    onSuccess() {
      publishRFPCallback()
      refetchRfpQuota()
      refetchCarrierInviteQuota()

      queryClient.refetchQueries({
        queryKey: ['carrierInvites'],
        exact: false,
      })
      queryClient.refetchQueries({ queryKey: ['deallocatedLanes'] })
    },
    onSettled() {
      publishDialog.closeModal()
    },
    onError(error: any) {
      const errors = error?.response?.data?.error || []

      publishRfpOnError(errors)

      Sentry.captureException(error)
    },
  })

  const { data: lanes } = useDeallocatedLanes(
    rfpId,
    defaultParams.limit,
    defaultParams.offset,
    defaultParams.sort,
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      onError(error) {
        Sentry.captureException(error)
        toast.error('Could not retrieve the lanes, please contact an admin')
      },
    },
    ''
  )

  const isDraft = isDraftRfp(rfp)

  const rfpIsValid = useMemo(() => canSubmit(lanes?.results), [lanes])

  const awardScenariosShowLaneProposals = (lane: Lane) => {
    setSelectedLane(lane)

    if (!sideBar.modalState) {
      sideBar.openModal()
    }

    setIsLaneDrawerOpen(true)

    analytics.track(
      AnalyticsEvent.RFPLaneRowClicked,
      AnalyticsEventTrigger.click,
      {
        rfpId,
        ...lane,
        ...rfp,
      },
      ['lane']
    )

    if (isDraftRfp(rfp)) {
      analytics.track(
        AnalyticsEvent.RFPSidePanelOpen,
        AnalyticsEventTrigger.click,
        {
          rfpId,
          ...lane,
          ...rfp,
        },
        ['lane']
      )
    }
  }

  const uploadLanesSuccessCallback = useCallback(
    (uploadedLanes: UploadLanesSuccessResponse = []) => {
      queryClient.refetchQueries({
        queryKey: ['deallocatedLanes'],
        exact: false,
      })
      queryClient.refetchQueries({ queryKey: ['rfpTotalizers'], exact: false })
      const preferences = uploadedLanes.reduce(
        (acc: LanePreference[], lane: Partial<Lane>) => {
          return [...acc, ...(lane.preferences || [])]
        },
        []
      )

      analytics.track(
        AnalyticsEvent.RFPImportLanesFileUpload,
        AnalyticsEventTrigger.success,
        { ...rfp, preferences },
        ['rfpLanes']
      )
      setIsUploading(false)
    },
    [rfp, queryClient]
  )

  const uploadLanesErrorCallback = useCallback(
    (start?: number, errorType?: string) => {
      const end = Date.now()
      if (errorType === 'LANES_LIMIT_REACHED') {
        setIsUploadError(true)
        setUploadErrorMessage(
          'The maximum number of lanes for this RFP was reached. Please, keep the number of lanes to a maximum of 2000.'
        )
      } else if (start && end - start > TIMEOUT_EXPIRATION) {
        // axios doesn’t get the 504 code from the request as there is no response so we check for timeout (just above 29 sec)
        toast.warning(
          'Your file is being processed in the background due to its size. Please refresh in a few minutes.'
        )
      } else {
        analytics.track(
          AnalyticsEvent.RFPImportLanesFileUpload,
          AnalyticsEventTrigger.error,
          rfp
        )
        setIsUploadError(true)
        setUploadErrorMessage(
          'Make sure you are uploading your lanes using the template with no changes on the spreadsheet headers.'
        )
      }
    },
    [rfp]
  )

  const onClick = useCallback(() => {
    analytics.track(
      AnalyticsEvent.RFPImportLanes,
      AnalyticsEventTrigger.click,
      { ...rfp, number_of_lanes: lanes?.count }
    )
    if (fileInput.current !== null) {
      fileInput.current.value = ''
      fileInput.current.click()
    }
  }, [lanes, rfp])

  useEffect(() => {
    return () => {
      setIsUploading(false)
      setIsUploadError(false)
      queryClient.resetQueries(['retrieveRFP'])
    }
  }, [queryClient])

  const { mutate: downloadLanesSampleMutate } = useMutation({
    mutationFn: downloadSample,
    onSuccess(values) {
      const { data, type } = values
      downloadData(data, `RFP Lanes Template.csv`, type ?? 'text/csv')
      setIsDownloading(false)
    },
    onError(error) {
      Sentry.captureException(error)
      setIsDownloading(false)
    },
  })

  const onClickDownloadLanesSample = useCallback(() => {
    return () => {
      setIsDownloading(true)
      downloadLanesSampleMutate(rfp.id)
    }
  }, [downloadLanesSampleMutate, rfp])

  const handleViewContractsClick = () => {
    const qParams = new URLSearchParams({ title: rfp.name })

    history.push({
      pathname: AppRoutes.Contracts,
      search: qParams.toString(),
    })
  }

  const importLanesDisabled = !isDraft || isUploading

  const importLanesButtonProps = {
    onClick,
    disabled: importLanesDisabled,
    isUploading,
  }

  const addLanesButtonProps = {
    onClick: () => {
      setSelectedLane(undefined)
      setIsLaneDrawerOpen(true)
      analytics.track(AnalyticsEvent.RFPCreateLaneButtonClicked)
      sideBar.openModal()
    },
    disabled: !isDraft,
  }

  const laneCsvTemplateButtonProps = {
    onClick: onClickDownloadLanesSample(),
    disabled: !isDraft || isDownloading,
    isDownloading,
  }

  const closeLaneDrawer = () => {
    setSelectedLane(undefined)
    setIsLaneDrawerOpen(false)
  }

  const shouldDisplayLoadingState = isLoading || isArchiving || isDuplicating

  const rfpLaneDetails = useMemo(() => {
    const preferences =
      lanes?.results.map((lane: any) => lane.preferences) || []

    const preferredModes = preferences.reduce(
      (acc: string[], preference: LanePreference[]) => {
        if (preference?.length > 0 && !acc.includes(preference[0].mode)) {
          return [...acc, preference[0].mode]
        }
        return acc
      },
      []
    )
    return {
      mode: preferredModes.join(', '),
      multimode: preferredModes.length > 1,
    }
  }, [lanes?.results])

  return (
    <Wrapper>
      <Helmet>
        <title>{`RFP #${rfpId} | ShipperGuide`}</title>
      </Helmet>
      <RFPData>
        {shouldDisplayLoadingState ? (
          <SpinnerWrapper>
            <Spinner size={50} />
          </SpinnerWrapper>
        ) : (
          <>
            {isUploadError && (
              <BannerWrapper>
                <Banner
                  scale="large"
                  variant="danger"
                  title="Could not upload the file"
                  description={uploadErrorMessage}
                />
              </BannerWrapper>
            )}
            <HeaderCardV2
              rfp={rfp}
              lanes_count={rfp?.lane_count}
              openDownloadDialog={downloadDialog.openModal}
              duplicateRFP={() => duplicateRFP(rfp.id)}
              archiveRFP={() => {
                archiveRFP({
                  id: rfp.id,
                  payload: { state: 'archived' },
                  queryClient,
                })
              }}
              rfpIsValid={rfpIsValid}
              openPublishDialog={publishDialog.openModal}
              openAwardDialog={awardDialog.openModal}
              openFinalizeDialog={finalizeDialog.openModal}
              totalizers={totalizers}
              isLoadingTotalizers={isLoadingTotalizers}
              rfpLaneDetails={rfpLaneDetails}
            />
            {rfp.show_contract_banner && (
              <BannerAction
                action="View Contracts"
                onActionButtonClick={handleViewContractsClick}
                title="This RFP is finalized and you can now follow it in the contracts page."
                variant="success"
                icon={<IconCheck name="close" width={18} />}
              />
            )}
            <Scenarios
              rfp={rfp}
              remainingRfpQuota={rfpQuotaData?.rfps?.remaining ?? 0}
              carrierInvitesMaxQuota={
                invitesPerRfpData?.carrier_invites?.max_allowed ?? 0
              }
              addLanesButton={addLanesButton(addLanesButtonProps)}
              importLanesButton={importLanesButton(importLanesButtonProps)}
              showLaneProposals={awardScenariosShowLaneProposals}
              importLanesButtonProps={importLanesButtonProps}
              addLanesButtonProps={addLanesButtonProps}
              laneCsvTemplateButtonProps={laneCsvTemplateButtonProps}
              invites={invites as NewCarrierInvite[]}
              carrierInviteStatus={carrierInviteStatus}
              refetchCarrierInvites={refetchCarrierInvites}
              isLoadingCarrierInvites={isLoadingCarrierInvites}
              onSuccessUploadLane={uploadLanesSuccessCallback}
              onErrorUploadLane={uploadLanesErrorCallback}
            />
          </>
        )}
      </RFPData>

      <RFPDialogs
        awardDialog={awardDialog}
        downloadDialog={downloadDialog}
        finalizeDialog={finalizeDialog}
        isPublishing={isPublishing}
        lanesCount={lanes?.count ?? 0}
        publishDialog={publishDialog}
        publishRFP={publishRFP}
        rfp={rfp}
        totalizers={totalizers}
        rfpLaneDetails={rfpLaneDetails}
        asyncReportData={asyncReportData}
      />

      <NewLaneDrawer
        selectedLane={selectedLane}
        isOpen={isLaneDrawerOpen}
        handleCloseDrawer={closeLaneDrawer}
        rfpDistanceType={rfp.distance_type}
        rfpCurrency={rfp.currency}
        rfpID={rfp.id}
        rfpExtraFieldsHeaders={
          rfp.lane_extra_fields as RFPExtraFieldsHeadersType
        }
        rfpModeOptions={modes}
        equipmentTypesByModeOptions={equipmentTypeOptions}
      />
    </Wrapper>
  )
}
