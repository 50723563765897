import { Tooltip } from '@loadsmart/loadsmart-ui'
import { useMemo } from 'react'
import styled from 'styled-components'

import { useWindowSize } from 'hooks/useWindowSize'
import { formatAddress, formatLocationSelectAddress } from 'utils/address'

const Container = styled.div`
  text-transform: uppercase;
`

export const cropLabel = (label: string, maxLength: number) =>
  `${label.substring(0, maxLength)} ...`

export const LABEL_MIN_SIZE = 20

const LABEL_MAX_SIZE = 45

export default function LaneAddress({
  location,
}: {
  readonly location?: Address
}) {
  const { windowWidth } = useWindowSize()

  const labelMaxLength = useMemo(() => {
    if (windowWidth < 1300) {
      return LABEL_MIN_SIZE
    }
    if (windowWidth < 1400) {
      return 25
    }
    if (windowWidth < 1550) {
      return 30
    }
    if (windowWidth < 1700) {
      return 35
    }
    if (windowWidth < 1800) {
      return 40
    }
    return LABEL_MAX_SIZE
  }, [windowWidth])

  const isFacility = Boolean(location?.company_name)

  const formattedAddress = formatAddress(
    location?.city,
    location?.state,
    location?.zipcode,
    location?.country
  )

  const tooltipValue = isFacility
    ? formatLocationSelectAddress(location)
    : formattedAddress

  const label = location?.company_name || formattedAddress

  const isLabelTooLong = label.length > labelMaxLength

  const formattedLabel = isLabelTooLong
    ? cropLabel(label, labelMaxLength)
    : label

  const showTooltip = isFacility || isLabelTooLong

  return (
    <Container>
      {showTooltip ? (
        <Tooltip message={tooltipValue}>
          <span style={{ whiteSpace: 'nowrap' }}>{formattedLabel}</span>
        </Tooltip>
      ) : (
        label
      )}
    </Container>
  )
}
