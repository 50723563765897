import { IconQuestionCircle } from '@loadsmart/icons'
import { Tooltip, TooltipPosition } from '@loadsmart/loadsmart-ui'
import { Layout, Text } from '@loadsmart/miranda-react'
import moment from 'moment'

import { theme } from 'styles/theme'

const MarketBenchmarkTooltip = () => {
  return (
    <Tooltip
      position={TooltipPosition.Bottom}
      align="end"
      message={
        <Layout.Stack gap="spacing-1">
          <Text color="color-neutral-20" variant="body-md-bold">
            Estimated on {moment().format('MMM D, YYYY')}
          </Text>
          <Text color="color-neutral-20">
            Benchmark rates dynamically adjust based on your mileage and RFPs
            with or without fuel costs.
          </Text>
        </Layout.Stack>
      }
    >
      <div style={{ height: '16px' }}>
        <IconQuestionCircle
          width={16}
          height={16}
          fill={theme.colors.neutral}
          title={null}
        />
      </div>
    </Tooltip>
  )
}

export default MarketBenchmarkTooltip
