import type { Context } from '@datadog/browser-core'
import { datadogLogs } from '@datadog/browser-logs'
import type { StatusType } from '@datadog/browser-logs'

import { userUtils } from '_shared_/user'
import * as constants from 'utils/constants'

class Logger {
  isInitiated = false
  isUserSet = false

  init = () => {
    if (this.isInitiated) {
      return
    }
    datadogLogs.init({
      clientToken: constants.DATADOG_CLIENT_TOKEN,
      service: constants.DATADOG_SERVICE_NAME,
      datacenter: 'us' as any,
      env: constants.ENV,
      version: constants.RELEASE_VERSION,
      sessionSampleRate: 100,
      forwardErrorsToLogs: true,
    })
    this.isInitiated = true
  }

  setUser = (userData: any) => {
    if (this.isUserSet || !userData) {
      return
    }
    datadogLogs.setGlobalContextProperty('user', userData)
    this.isUserSet = true
  }

  assertSetup = () => {
    if (!this.isInitiated) {
      this.init()
    }
    if (!this.isUserSet) {
      this.setUser(userUtils.user)
    }
  }

  log = (message: string, ctx: Context, status: StatusType) => {
    this.assertSetup()
    datadogLogs.logger.log(message, ctx, status)
  }

  info = (message: string, ctx: Context = {}) => {
    this.log(message, ctx, 'info' as StatusType)
  }

  debug = (message: string, ctx: Context = {}) => {
    this.log(message, ctx, 'debug' as StatusType)
  }

  warn = (message: string, ctx: Context = {}) => {
    this.log(message, ctx, 'warn' as StatusType)
  }

  error = (message: string, ctx: Context = {}) => {
    this.log(message, ctx, 'error' as StatusType)
  }
}

const logger = new Logger()

export default logger
