import { IconQuestionCircle } from '@loadsmart/icons'
import { DatePicker, Select } from '@loadsmart/loadsmart-ui'
import { Card, Field, Layout, Text } from '@loadsmart/miranda-react'
import { Controller } from 'react-hook-form'

import {
  RequiredErrorMessage,
  RankHint,
  ValidationError,
} from '../componentUtils'
import { DatePickerWrapper } from '../styles'
import { getFieldStatus } from '../utils'
import { FormTooltip } from './styles'
import type { ContractSectionProps } from './types'

export default function RFPContractSection({
  shouldRender,
  awardPreferenceOptionsWatch,
  awardAcceptanceOptions,
  awardPreferenceOptions,
  shippingFrequencyOptions,
  shippingFrequencyWatch,
  control,
  isFieldDisabled,
  errors,
  rfpState,
}: ContractSectionProps) {
  if (!shouldRender) {
    return null
  }

  const errorMessagesEnabled = !rfpState || rfpState === 'draft'

  const isShippingFrequencySpot = shippingFrequencyWatch?.value === 'spot'

  return (
    <Card>
      <Card.Title>Contract</Card.Title>
      <Card.Divider />
      <Card.Body>
        <Layout.Stack gap="spacing-8">
          <Layout.Grid style={{ gridTemplateColumns: '1fr 1fr' }}>
            <Field
              style={{ flex: '1' }}
              required
              status={getFieldStatus(errors.contractStartDate)}
            >
              <Field.Label
                style={{ lineHeight: 'fit-content' }}
                id="labelContractStart"
              >
                <Text>Contract starts</Text>
                <FormTooltip
                  trigger="hover"
                  message="Select the starting date of the contract period."
                  placement="top-start"
                >
                  <IconQuestionCircle
                    width={14}
                    height={14}
                    aria-label="rfp-contract-start"
                    title={null}
                  />
                </FormTooltip>
              </Field.Label>
              <Controller
                name="contractStartDate"
                control={control}
                disabled={isFieldDisabled}
                render={({ field }) => (
                  <DatePickerWrapper>
                    <DatePicker
                      getInputProps={() => ({
                        'aria-labelledby': 'labelContractStart',
                      })}
                      {...field}
                      value={field.value?.toISOString()}
                      onChange={(event) => {
                        if (event.target.value === null) {
                          field.onChange(event)
                          return
                        }

                        field.onChange({
                          ...event,
                          target: {
                            ...event.target,
                            value: new Date(event.target.value),
                          },
                        })
                      }}
                    />
                  </DatePickerWrapper>
                )}
              />
              {errorMessagesEnabled && (
                <ValidationError
                  error={errors.contractStartDate}
                  message={errors.contractStartDate?.message}
                />
              )}
            </Field>
            <Field
              required
              style={{ flex: '1' }}
              status={getFieldStatus(errors.contractEndDate)}
            >
              <Field.Label id="labelContractEnd">
                <Text>Contract ends</Text>
                <FormTooltip
                  trigger="hover"
                  message="Select the ending date of the contract period."
                  placement="top-start"
                >
                  <IconQuestionCircle
                    width={14}
                    height={14}
                    aria-label="rfp-contract-start"
                    title={null}
                  />
                </FormTooltip>
              </Field.Label>
              <Controller
                name="contractEndDate"
                control={control}
                disabled={isFieldDisabled}
                render={({ field }) => (
                  <DatePickerWrapper>
                    <DatePicker
                      getInputProps={() => ({
                        'aria-labelledby': 'labelContractEnd',
                      })}
                      {...field}
                      value={field.value?.toISOString()}
                      onChange={(event) => {
                        if (!event.target.value) {
                          field.onChange(event)
                          return
                        }

                        field.onChange({
                          ...event,
                          target: {
                            ...event.target,
                            value: new Date(event.target.value),
                          },
                        })
                      }}
                    />
                  </DatePickerWrapper>
                )}
              />
              {errorMessagesEnabled && (
                <ValidationError
                  error={errors.contractEndDate}
                  message={errors.contractEndDate?.message}
                />
              )}
            </Field>
          </Layout.Grid>
          <Layout.Stack>
            <Layout.Grid>
              <Field
                required
                style={{ flex: '1' }}
                status={getFieldStatus(errors.capacityTimePeriod)}
              >
                <Field.Label id="labelVolumeFrequency">
                  <Text>Volume Frequency</Text>
                  <FormTooltip
                    trigger="hover"
                    message={`Indicate how often you intend to tender shipments according to the volume for each lane. 
                      This information will guide carriers in offering the appropriate capacity when submitting their bids.`}
                  >
                    <IconQuestionCircle
                      width={14}
                      height={14}
                      aria-label="rfp-volume-frequency"
                      title={null}
                    />
                  </FormTooltip>
                </Field.Label>
                <Controller
                  name="capacityTimePeriod"
                  control={control}
                  disabled={isFieldDisabled}
                  render={({ field }) => (
                    <Select
                      {...field}
                      placeholder="Select..."
                      options={shippingFrequencyOptions}
                      aria-labelledby="labelVolumeFrequency"
                      hideClear
                    />
                  )}
                />
                <RequiredErrorMessage error={errors.capacityTimePeriod} />
              </Field>
              <Field
                required
                style={{ flex: '1' }}
                status={getFieldStatus(errors.bidType)}
              >
                <Field.Label>
                  <Text>Bid Type</Text>
                  <FormTooltip
                    trigger="hover"
                    message={`Choose how you will select winning carriers. 
                      'Award Primary & Backup' allows you to designate primary and backup carriers for each lane, 
                      which will result in contracts upon finalizing the RFP. 'Rates on File (Rank)' 
                      lets you rank carriers by their rates for potential future use without creating immediate contracts.`}
                  >
                    <IconQuestionCircle
                      width={14}
                      height={14}
                      aria-label="rfp-bid-type"
                      title={null}
                    />
                  </FormTooltip>
                </Field.Label>
                <Controller
                  name="bidType"
                  control={control}
                  disabled={isFieldDisabled}
                  render={({ field }) => (
                    <Select
                      {...field}
                      placeholder="Select..."
                      options={awardPreferenceOptions}
                      hideClear
                    />
                  )}
                />
                <RequiredErrorMessage error={errors.bidType} />
                <RankHint
                  error={errors.bidType}
                  watchBidType={awardPreferenceOptionsWatch}
                />
              </Field>
              {!isShippingFrequencySpot && (
                <Field
                  required
                  style={{ flex: '1' }}
                  status={getFieldStatus(errors.awardAcceptanceDeadline)}
                >
                  <Field.Label>
                    <Text>Award Acceptance deadline</Text>
                    <FormTooltip
                      trigger="hover"
                      message={`Set the deadline for primary carriers to accept the contract terms after being selected for a lane.
                        This is relevant only if 'Award Primary & Backup' bid type is chosen. 
                        Note that backup carriers are not required to accept tenders; 
                        they are selected to step in if the primary carrier is unable to fulfill a tender.`}
                    >
                      <IconQuestionCircle
                        width={14}
                        height={14}
                        aria-label="rfp-award-acceptance-deadline"
                        title={null}
                      />
                    </FormTooltip>
                  </Field.Label>
                  <Controller
                    name="awardAcceptanceDeadline"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="Select..."
                        options={awardAcceptanceOptions}
                        hideClear
                      />
                    )}
                  />
                  <RequiredErrorMessage
                    error={errors.awardAcceptanceDeadline}
                  />
                </Field>
              )}
            </Layout.Grid>
          </Layout.Stack>
        </Layout.Stack>
      </Card.Body>
    </Card>
  )
}
