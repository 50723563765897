import { Pagination, Table } from '@loadsmart/miranda-react'

import { useOffsetPagination } from '../../hooks/usePagination'
import { Loading } from '../../screens/Shipper/Shipments/ShipmentList/Loading'
import { generateUseSearchParamsPlugin, useFilters } from '../../utils/filters'
import { numberFormatter } from '../../utils/numbers'
import { ExportDownloadButton } from '../ExportDownloadButton'
import { ExportStatusCell } from '../ExportStatusCell'
import { ExportUserCell } from '../ExportUserCell'
import { getExportTotalAmount, getFileName } from '../presenters'
import { useListShipmentsSettlementReports } from './hooks/useListShipmentsSettlementReports'

export function PayablesTable() {
  const filters = useFilters(
    { initialValues: { limit: 20, offset: 0 } },
    generateUseSearchParamsPlugin({
      offset: { type: 'primitive' },
      limit: { type: 'primitive' },
    })
  )
  const { data, isFetching } = useListShipmentsSettlementReports({
    limit: filters.values['limit'] as number,
    offset: filters.values['offset'] as number,
  })
  const { currentPage, pageSize, resultsCount, getOffsetForPage } =
    useOffsetPagination({
      resultsCount: data?.count ?? 0,
      pageSize: filters.values.limit as number,
      currentOffset: filters.values.offset as number,
    })

  const onPageChange = (
    event: CustomEvent<{
      page: number
      pageSize: number
    }>
  ) => {
    const { page } = event.detail

    filters.setFilter('offset', getOffsetForPage(page))
  }

  if (isFetching) {
    return <Loading />
  }

  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.HeadCell>Title</Table.HeadCell>
          <Table.HeadCell>Exported by</Table.HeadCell>
          <Table.HeadCell># of shipments exported</Table.HeadCell>
          <Table.HeadCell>Amount</Table.HeadCell>
          <Table.HeadCell>Status</Table.HeadCell>
          <Table.HeadCell />
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {data?.results.map(
          ({
            status,
            id,
            created_by,
            file_name,
            file_url,
            created_at,
            total,
            shipment_count,
          }) => {
            const { first_name, last_name, email } = created_by

            return (
              <Table.Row key={id}>
                <Table.Cell>{getFileName(file_name)}</Table.Cell>
                <Table.Cell>
                  <ExportUserCell
                    createdAt={created_at}
                    email={email}
                    firstName={first_name}
                    lastName={last_name}
                  />
                </Table.Cell>
                <Table.Cell>{numberFormatter(shipment_count, 0)}</Table.Cell>
                <Table.Cell>{getExportTotalAmount(total)}</Table.Cell>
                <Table.Cell>
                  <ExportStatusCell status={status} />
                </Table.Cell>
                <Table.Cell>
                  <ExportDownloadButton
                    status={status}
                    fileURL={file_url}
                    fileName={file_name}
                  />
                </Table.Cell>
              </Table.Row>
            )
          }
        )}
      </Table.Body>
      <Table.Actions>
        <Pagination
          data-testid="settlement-export-pagination"
          disabled={!data?.count}
          page={currentPage}
          pageSize={pageSize}
          count={resultsCount}
          onChange={onPageChange}
        />
      </Table.Actions>
    </Table>
  )
}
