import {
  FulfillmentsFiltersProvider,
  useFulfillmentsFiltersContext,
} from 'fulfillments/components/Filters'
import { AppliedFiltersBar } from 'fulfillments/components/Filters.components'
import { getHasAppliedFilters } from 'fulfillments/components/Filters.utils'
import type { ListFulfillment } from 'fulfillments/domain/Fulfillment'
import { useGoToFulfillmentDetails } from 'fulfillments/hooks/navigation'
import type { useFulfillmentListAsCustomer } from 'fulfillments/hooks/useFulfillmentList'
import { useFulfillmentListAsSupplier } from 'fulfillments/hooks/useFulfillmentList'
import { defaultTo } from 'lodash'
import { useCallback } from 'react'

import { Table } from 'components/TableV4'
import type { CustomColumn } from 'components/TableV4/Table.types'

import {
  CustomerCell,
  DestinationCell,
  HandlingUnitsCell,
  NotesCell,
  OrdersCell,
  OriginCell,
  StatusCell,
} from './components/ListFulfillmentsTableCells'
import {
  ListFulfillmentsTableEmptyState,
  ListFulfillmentsTableEmptyStateWithFilters,
} from './components/ListFulfillmentsTableEmptyState'

const SUPPLIER_COLUMNS: ReadonlyArray<CustomColumn<ListFulfillment>> = [
  {
    id: 'customer',
    Header: 'Customer',
    Cell: CustomerCell,
  },
  {
    id: 'ref_number',
    accessor: 'ref_number',
    Header: 'Ref number',
    customWidth: '200px',
  },
  {
    id: 'pickup_number',
    accessor: 'pickup_number',
    Header: 'Pickup number',
    customWidth: '200px',
  },
  {
    Header: 'Status',
    id: 'status',
    Cell: StatusCell,
  },
  {
    Header: 'Orders',
    id: 'order_uuids',
    Cell: OrdersCell,
  },
  {
    Header: 'Origin',
    id: 'pickup_facility',
    Cell: OriginCell,
  },
  {
    Header: 'Destination',
    id: 'delivery_facility',
    Cell: DestinationCell,
  },
  {
    Header: 'Handling units',
    id: 'handling_units',
    Cell: HandlingUnitsCell,
  },
  {
    Header: 'Notes',
    id: 'notes',
    Cell: NotesCell,
  },
]

type FulfillmentsTableProps = ReturnType<
  typeof useFulfillmentListAsCustomer
> & {
  columns: ReadonlyArray<CustomColumn<ListFulfillment>>
}

function FulfillmentsTable({
  columns,
  currentPage,
  data,
  filters,
  setFilters,
  clearFilters,
  isLoading,
  onPageChange,
  pageSize,
}: Readonly<FulfillmentsTableProps>) {
  const goToFulfillmentDetails = useGoToFulfillmentDetails()
  const { openFilters } = useFulfillmentsFiltersContext()
  const onRowClick = useCallback(
    (fulfillment: ListFulfillment) => {
      goToFulfillmentDetails(fulfillment.uuid)
    },
    [goToFulfillmentDetails]
  )
  const hasAppliedFilters = getHasAppliedFilters(filters)

  if (!isLoading && !hasAppliedFilters && !data?.results?.length) {
    return (
      <ListFulfillmentsTableEmptyState
        count={data?.count}
        isLoading={isLoading}
      />
    )
  }

  return (
    <>
      <Table<ListFulfillment>
        appliedFiltersBar={
          <AppliedFiltersBar
            clearFilters={clearFilters}
            setFilters={setFilters}
            filters={filters}
          />
        }
        columns={columns}
        count={data?.results?.length}
        data={defaultTo(data?.results, [])}
        isLoading={!!isLoading}
        onOpenFilters={openFilters}
        onPageChange={onPageChange}
        onRowClick={onRowClick}
        page={currentPage}
        pageSize={pageSize}
        totalCount={data?.count}
        uniqueIDfield="uuid"
      />
      <ListFulfillmentsTableEmptyStateWithFilters
        count={data?.count}
        isLoading={isLoading}
      />
    </>
  )
}

export function ListFulfillmentsAsSupplier() {
  const asSupplierList = useFulfillmentListAsSupplier()
  const { filters, clearFilters, setFilters } = asSupplierList

  return (
    <FulfillmentsFiltersProvider
      clearFilters={clearFilters}
      filters={filters}
      setFilters={setFilters}
    >
      <FulfillmentsTable {...asSupplierList} columns={SUPPLIER_COLUMNS} />
    </FulfillmentsFiltersProvider>
  )
}
