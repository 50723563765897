import { Tooltip, TooltipAlign, TooltipPosition } from '@loadsmart/loadsmart-ui'

import {
  Question,
  StyledAwardTable,
  StyledAwardTooltip,
  StyledCarrierNameCell,
} from 'components/LaneManagement/LanesTableV3/styles'
import formatCurrency from 'utils/formatCurrency'

interface Carriers {
  award: number
  name: string
}

interface IncumbentTooltipProps {
  readonly carriers: Carriers[]
  readonly position?: TooltipPosition
  readonly align?: TooltipAlign
}

const IncumbentTooltip = ({
  carriers,
  position = TooltipPosition.Top,
  align = TooltipAlign.Center,
}: IncumbentTooltipProps) => {
  return (
    <StyledAwardTooltip>
      <Tooltip
        position={position}
        align={align}
        message={
          <StyledAwardTable>
            <thead>
              <tr>
                <th>Incumbents</th>
                <th>Last Award</th>
              </tr>
            </thead>
            <tbody>
              {carriers.map((carrier, index) => {
                return (
                  <tr key={`award-summary-${index}`}>
                    <StyledCarrierNameCell $shouldHavePaddingLeft={false}>
                      {carrier.name}
                    </StyledCarrierNameCell>
                    <td>{formatCurrency(carrier.award)}</td>
                  </tr>
                )
              })}
            </tbody>
          </StyledAwardTable>
        }
      >
        <Question width={10} height={10} />
      </Tooltip>
    </StyledAwardTooltip>
  )
}

export default IncumbentTooltip
