import type { RequestForProposal } from 'rfp/rfp.types'
import { RFP_STATE } from 'utils/constants'

import {
  getDestFromStops,
  getOriginFromStops,
  isLaneEquipmentTypeValid,
} from './lanes'

export function canSubmit(lanes: Lane[] | undefined) {
  if (!lanes || !lanes.length) {
    return false
  }

  return !Object.values(lanes).find((lane: Lane) => {
    const origin = getOriginFromStops(lane.stops)
    const destination = getDestFromStops(lane.stops)

    const isAnyLaneBaseValueEmpty =
      !isLaneEquipmentTypeValid(lane) ||
      !lane.mileage ||
      !lane.volume ||
      !lane.lane_id
    const isOriginInvalid = !((origin.city && origin.state) || origin.zip_code)
    const isDestinationInvalid = !(
      (destination.city && destination.state) ||
      destination.zip_code
    )

    return isAnyLaneBaseValueEmpty || isOriginInvalid || isDestinationInvalid
  })
}

export function isDraftRfp(rfp?: RequestForProposal) {
  return rfp?.state === RFP_STATE.DRAFT
}

export function getSelectedOption(
  selected: string | number,
  options: SelectOption[]
): SelectOption | undefined {
  return options.find(({ value }) => value === selected)
}

/**
 * In the former RFPForm we always sent this value
 * no matter if the RFP was Spot (that don't have this field) or not.
 *
 * So to keep consistency, if the user selects "null" for awardAcceptanceDeadline,
 * and if capacityTimePeriod (Volume Frequency) is spot, we will send the default value for this field.
 */
export const DEFAULT_AWARD_ACCEPTANCE_DEADLINE_DAYS = 3
