import {
  getActionOrFindCarrierColumn,
  getDestOrDeliveryColumn,
  getLaneIdColumn,
  getLaneIndicatorColumn,
  getMileageColumn,
  getModeAndEquipmentColumn,
  getOriginOrPickupColumn,
  getVolumeColumn,
} from 'rfp/components/table/columns'
import type { RequestForProposal } from 'rfp/rfp.types'

export default function makeBaseColumns({
  isRankedAward,
  rfpState,
  isSpotMode,
  laneIdMaxLength,
  draftColumns,
  laneDetailsColumns,
  openFindCarriersSidebar,
  rfpDistanceType,
}: {
  isRankedAward: boolean
  rfpState: string
  isSpotMode: boolean
  laneIdMaxLength: number
  draftColumns: any
  laneDetailsColumns: any
  onRowClick: (lane: Lane) => void
  openFindCarriersSidebar: (lane: Lane) => void
  rfpDistanceType: RequestForProposal['distance_type']
}) {
  return [
    getLaneIndicatorColumn(rfpState, isRankedAward, isSpotMode),
    getLaneIdColumn(laneIdMaxLength),
    getOriginOrPickupColumn(),
    getDestOrDeliveryColumn(),
    getMileageColumn(rfpDistanceType),
    getModeAndEquipmentColumn(),
    getVolumeColumn(),
    ...(rfpState === 'draft' ? draftColumns : laneDetailsColumns),
    getActionOrFindCarrierColumn(rfpState, openFindCarriersSidebar),
  ]
}
