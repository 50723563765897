import { Layout } from '@loadsmart/loadsmart-ui'
import type { ReactNode } from 'react'

import emptyStateImage from 'assets/imgs/tracking-empty-state.svg'

const EmptyState = ({ children }: { readonly children: ReactNode }) => {
  return (
    <Layout.Box
      padding="3xl"
      background="neutral-lightest"
      borderRadius="s"
      borderColor="neutral-lighter"
      borderWidth="thin"
      style={{ marginBottom: '2rem' }}
    >
      <Layout.Group
        justify="center"
        align="center"
        style={{ textAlign: 'center' }}
      >
        <Layout.Stack space="l">
          <img
            width="150px"
            src={emptyStateImage}
            style={{ alignSelf: 'center' }}
            alt="Truck arriving at the destination"
          />
          {children}
        </Layout.Stack>
      </Layout.Group>
    </Layout.Box>
  )
}

export default EmptyState
