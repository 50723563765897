import { IconQuestionCircle } from '@loadsmart/icons'
import {
  Layout,
  Text,
  Tooltip,
  TooltipPosition,
  TooltipAlign,
  Tag,
} from '@loadsmart/loadsmart-ui'
import { Button } from '@loadsmart/miranda-react'
import { useMemo, useState } from 'react'
import type { Dispatch, SetStateAction } from 'react'

import truckArrivingImg from 'assets/imgs/truck-arriving.svg'
import SideBar from 'components/SideBar'
import {
  StyledCard,
  StyledCardBody,
  StyledCardTitle,
  StyledCardSeparator,
} from 'rfp/components/header-card-v2/styles'
import type { Currency } from 'rfp/rfp.types'
import { theme } from 'styles/theme'
import analytics, {
  AnalyticsEvent,
  AnalyticsEventTrigger,
} from 'utils/analytics'
import { isMultiCurrency, getCurrencyPrefix } from 'utils/currency'
import formatCurrency from 'utils/formatCurrency'

import AllocateDialog from './AllocateDialog'
import AwardScenariosFilters from './AwardScenariosFilters'

interface AwardScenariosSummaryProps {
  readonly rfpId: string | number
  readonly rfpCurrency: Currency
  readonly stats: AwardScenariosStats | undefined
  readonly carrierFilter: string | null
  readonly setCarrierFilter: Dispatch<SetStateAction<string | null>>
  readonly typesFilter: string | null
  readonly setTypesFilter: Dispatch<SetStateAction<string | null>>
  readonly backupFilter: string | null
  readonly setBackupFilter: Dispatch<SetStateAction<string | null>>
  readonly backupFilterActive: boolean
  readonly setBackupFilterActive: Dispatch<SetStateAction<boolean>>
  readonly splitCarriersFilter: string | null
  readonly setSplitCarriersFilter: Dispatch<SetStateAction<string | null>>
  readonly splitCarriersFilterActive: boolean
  readonly setSplitCarriersFilterActive: Dispatch<SetStateAction<boolean>>
  readonly updateScenarios: (param: {
    rfpId: string | number
    laneIds: (string | number)[]
    updateAll: boolean
    carrierEntity: string
    carrierIncumbency: string
    backupCarrierCount?: string
    maxSplitCarriers?: string
  }) => void
  readonly scenariosSelected: undefined | any[]
  readonly allocateDialog: {
    modalState: boolean
    closeModal: () => void
    openModal: () => void
    toggleModal: () => void
    setModalState: Dispatch<SetStateAction<boolean>>
  }
  readonly isUpdatingScenarios: boolean
  readonly isLoadingAwardStats: boolean
  readonly hasData: boolean
  readonly scenariosCount: number
}

type Filters = {
  carriers: string | null
  types: string | null
  backup: string | null
  splitCarriers?: string | null
}

const FilterTags = ({
  hasRules,
  carrierFilter,
  typesFilter,
  backupFilterActive,
  backupFilter,
  splitCarriersFilterActive,
  splitCarriersFilter,
}: {
  readonly hasRules: boolean
  readonly carrierFilter: string | null
  readonly typesFilter: string | null
  readonly backupFilterActive: boolean
  readonly backupFilter: string | null
  readonly splitCarriersFilterActive: boolean
  readonly splitCarriersFilter: string | null
}) => {
  if (!hasRules) {
    return null
  }

  return (
    <Layout.Stack space="xs">
      <Text variant="chips-sm" color="color-neutral">
        Rules
      </Text>
      <Layout.Group space="xs">
        {carrierFilter !== null && (
          <Tag variant="outlined" data-testid="carriers-tag">
            Carriers
          </Tag>
        )}
        {typesFilter !== null && (
          <Tag variant="outlined" data-testid="types-tag">
            Type
          </Tag>
        )}
        {backupFilterActive && backupFilter !== null && (
          <Tag variant="outlined" data-testid="types-tag">
            Backup Carriers
          </Tag>
        )}
        {splitCarriersFilterActive && splitCarriersFilter !== null && (
          <Tag variant="outlined" data-testid="types-tag">
            Split Volume
          </Tag>
        )}
      </Layout.Group>
    </Layout.Stack>
  )
}

const AwardScenariosSummary = ({
  rfpId,
  rfpCurrency,
  stats,
  carrierFilter,
  setCarrierFilter,
  typesFilter,
  setTypesFilter,
  backupFilter,
  setBackupFilter,
  backupFilterActive,
  setBackupFilterActive,
  updateScenarios,
  scenariosSelected,
  allocateDialog,
  isUpdatingScenarios,
  hasData,
  scenariosCount,
  isLoadingAwardStats,
  setSplitCarriersFilter,
  setSplitCarriersFilterActive,
  splitCarriersFilter,
  splitCarriersFilterActive,
}: AwardScenariosSummaryProps) => {
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false)

  const hasRules = useMemo(() => {
    const hasBaseFilters = carrierFilter !== null || typesFilter !== null
    const hasBackupFilter = backupFilterActive && backupFilter !== null
    const hasSplitCarriersFilter =
      splitCarriersFilterActive && splitCarriersFilter !== null

    return hasBaseFilters || hasBackupFilter || hasSplitCarriersFilter
  }, [
    carrierFilter,
    typesFilter,
    backupFilterActive,
    backupFilter,
    splitCarriersFilterActive,
    splitCarriersFilter,
  ])

  const allocateAll = () =>
    updateScenarios({
      rfpId,
      laneIds: [],
      updateAll: true,
      carrierEntity: typesFilter ?? '',
      carrierIncumbency: carrierFilter ?? '',
      backupCarrierCount: backupFilter ?? '',
      maxSplitCarriers: splitCarriersFilter ?? '',
    })

  const allocatedSelected = () => {
    updateScenarios({
      rfpId,
      laneIds: scenariosSelected ?? [],
      updateAll: false,
      carrierEntity: typesFilter ?? '',
      carrierIncumbency: carrierFilter ?? '',
      backupCarrierCount: backupFilter ?? '',
      maxSplitCarriers: splitCarriersFilter ?? '',
    })

    analytics.track(
      AnalyticsEvent.AwardScenariosAllocateSelected,
      AnalyticsEventTrigger.click
    )
  }

  const currencyPrefix = getCurrencyPrefix(rfpCurrency)
  const totalAmount = isMultiCurrency(rfpCurrency)
    ? stats?.converted_total_award_rate
    : stats?.total_award_rate
  const formattedTotalAmount = totalAmount
    ? formatCurrency(totalAmount, true, false)
    : '0'
  const totalSpend = `${currencyPrefix}${formattedTotalAmount}`

  const applyFilters = ({
    carriers,
    types,
    backup,
    splitCarriers,
  }: Filters) => {
    setCarrierFilter(carriers)
    setTypesFilter(types)
    setBackupFilter(backup)
    setSplitCarriersFilter(splitCarriers ?? null)
  }

  return (
    <StyledCard>
      <StyledCardTitle $isVisible>
        <Layout.Group justify="space-between" align="center">
          <Layout.Group space="3xl">
            <Layout.Stack space="xs">
              <Layout.Group space="xs" align="center">
                <Text variant="chips-sm" color="color-neutral">
                  Scenarios
                </Text>
                <Tooltip
                  position={TooltipPosition.Bottom}
                  align={TooltipAlign.Start}
                  message={
                    <>
                      The underlying carrier ranking is based on the
                      <br /> Lowest Bid carrier once the filtered scenarios have
                      been selected.
                    </>
                  }
                >
                  <IconQuestionCircle
                    height={12}
                    width={12}
                    fill={theme.colors.neutral}
                    title={null}
                  />
                </Tooltip>
              </Layout.Group>
              <Text variant="body-bold" color="color-neutral-darker">
                Lowest Bids
              </Text>
            </Layout.Stack>

            <FilterTags
              backupFilter={backupFilter}
              backupFilterActive={backupFilterActive}
              carrierFilter={carrierFilter}
              hasRules={hasRules}
              splitCarriersFilter={splitCarriersFilter}
              splitCarriersFilterActive={splitCarriersFilterActive}
              typesFilter={typesFilter}
            />
          </Layout.Group>
          <Layout.Group space="2xl" style={{ alignSelf: 'center' }}>
            <Layout.Stack space="xs">
              <Layout.Group space="xs" align="center">
                <Text variant="chips-sm" color="color-neutral">
                  Lanes
                </Text>
                <Tooltip
                  position={TooltipPosition.Bottom}
                  message={
                    <>
                      Percentage of lanes to be
                      <br /> allocated based on this scenario
                    </>
                  }
                >
                  <IconQuestionCircle
                    height={12}
                    width={12}
                    fill={theme.colors.neutral}
                    title={null}
                  />
                </Tooltip>
              </Layout.Group>
              <Text variant="body-bold" color="color-neutral-darker">
                {stats?.lane_allocation_percentage ?? 0}%
              </Text>
            </Layout.Stack>
            <Layout.Stack space="xs">
              <Layout.Group space="xs" align="center">
                <Text variant="chips-sm" color="color-neutral">
                  Volume
                </Text>
                <Tooltip
                  position={TooltipPosition.Bottom}
                  message={
                    <>
                      Percentage of volume to be
                      <br /> allocated based on this scenario
                    </>
                  }
                >
                  <IconQuestionCircle
                    height={12}
                    width={12}
                    fill={theme.colors.neutral}
                    title={null}
                  />
                </Tooltip>
              </Layout.Group>
              <Text variant="body-bold" color="color-neutral-darker">
                {stats?.volume_allocation_percentage ?? 0}%
              </Text>
            </Layout.Stack>
            <Layout.Stack space="xs">
              <Layout.Group space="xs" align="center">
                <Text variant="chips-sm" color="color-neutral">
                  Asset
                </Text>
                <Tooltip
                  position={TooltipPosition.Bottom}
                  message={
                    <>
                      Asset carriers selected
                      <br /> based on this scenario
                    </>
                  }
                >
                  <IconQuestionCircle
                    height={12}
                    width={12}
                    fill={theme.colors.neutral}
                    title={null}
                  />
                </Tooltip>
              </Layout.Group>
              <Text variant="body-bold" color="color-neutral-darker">
                {stats?.asset_count ?? 0}
              </Text>
            </Layout.Stack>
            <Layout.Stack space="xs">
              <Layout.Group space="xs" align="center">
                <Text variant="chips-sm" color="color-neutral">
                  Brokers
                </Text>
                <Tooltip
                  position={TooltipPosition.Bottom}
                  message={
                    <>
                      Brokers selected
                      <br /> based on this scenario
                    </>
                  }
                >
                  <IconQuestionCircle
                    height={12}
                    width={12}
                    fill={theme.colors.neutral}
                    title={null}
                  />
                </Tooltip>
              </Layout.Group>
              <Text variant="body-bold" color="color-neutral-darker">
                {stats?.broker_count ?? 0}
              </Text>
            </Layout.Stack>
            <Layout.Stack space="xs">
              <Layout.Group space="xs" align="center">
                <Text variant="chips-sm" color="color-neutral">
                  Total Spend
                </Text>
                <Tooltip
                  position={TooltipPosition.Bottom}
                  align={TooltipAlign.End}
                  message={
                    <>
                      Total spend based on
                      <br /> this scenario
                    </>
                  }
                >
                  <IconQuestionCircle
                    height={12}
                    width={12}
                    fill={theme.colors.neutral}
                    title={null}
                  />
                </Tooltip>
              </Layout.Group>
              <Text variant="body-bold" color="color-neutral-darker">
                {totalSpend}
              </Text>
            </Layout.Stack>
          </Layout.Group>
        </Layout.Group>
      </StyledCardTitle>
      <StyledCardSeparator />
      <StyledCardBody>
        <Layout.Group justify="space-between" align="center">
          <Layout.Sidebar>
            <img src={truckArrivingImg} width="90px" alt="Truck arriving" />
            <Text
              variant="body-bold"
              color="color-neutral-darker"
              style={{ alignSelf: 'center', whiteSpace: 'nowrap' }}
            >
              Speed up your awarding process by adding rules that best suits the
              needs of your business.
            </Text>
          </Layout.Sidebar>
          <Layout.Group align="center">
            <Button
              disabled={isLoadingAwardStats}
              onClick={() => setIsFilterOpen(true)}
              variant={hasRules ? 'tertiary' : 'secondary'}
            >
              {hasRules ? 'Edit rules' : 'Add rules'}
            </Button>
            {hasData && (
              <>
                <Button
                  disabled={(scenariosSelected?.length ?? 0) === 0}
                  onClick={allocatedSelected}
                  variant="secondary"
                >
                  Allocate selected
                </Button>
                <Button
                  disabled={!hasData}
                  onClick={() => {
                    allocateDialog.openModal()
                    analytics.track(
                      AnalyticsEvent.AwardScenariosAllocateAll,
                      AnalyticsEventTrigger.click
                    )
                  }}
                  variant="primary"
                >
                  Allocate all
                </Button>
              </>
            )}
          </Layout.Group>
        </Layout.Group>
      </StyledCardBody>
      <AllocateDialog
        lanesCount={scenariosCount}
        allocate={allocateAll}
        isAllocating={isUpdatingScenarios}
        isOpen={allocateDialog.modalState}
        onClose={allocateDialog.closeModal}
      />
      <SideBar showToggle={false} isOpen={isFilterOpen}>
        <AwardScenariosFilters
          clear={() => {
            applyFilters({
              carriers: null,
              types: null,
              backup: null,
              splitCarriers: null,
            })

            setBackupFilterActive(false)
          }}
          onClose={() => setIsFilterOpen(false)}
          applyFilters={(filters: Filters) => {
            applyFilters(filters)
          }}
          selectedFilters={{
            carrierFilter,
            typesFilter,
            backupFilter,
            splitCarriersFilter,
          }}
          backupFilterActive={backupFilterActive}
          setBackupFilterActive={setBackupFilterActive}
          splitCarriersFilterActive={splitCarriersFilterActive}
          setSplitCarriersFilterActive={setSplitCarriersFilterActive}
        />
      </SideBar>
    </StyledCard>
  )
}

export default AwardScenariosSummary
