import styled from 'styled-components'

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-items: center;
  text-align: center;
  width: 200px;
`

export const EmptyStateSvg = styled.img`
  height: 100px;
  width: 150px;
`

export const Title = styled.h3`
  display: inline-flex;
  padding-bottom: 10px;
  padding-top: 30px;
`

export const SubTitle = styled.p`
  display: inline-flex;
  font-size: 12px;
  line-height: 16px;
`
