import styled from 'styled-components'

export const CarrierHeader = styled.div`
  align-items: center;
  display: inline-flex;
  padding-left: 16px;
`
export const CarrierCell = styled(CarrierHeader)`
  display: flex;
`
export const LSIcon = styled.object`
  height: 22px;
  width: 101px;
`
export const Capacity = styled.span<{ highlight: boolean }>`
  ${({ highlight, theme }) =>
    highlight &&
    `
    color: ${theme.colors.warningDark};
  `}
`
export const RatePercent = styled.span<{ color: string }>`
  color: ${({ color }) => color};
  font-weight: bold;
  white-space: nowrap;
`
