import { Text } from '@loadsmart/loadsmart-ui'
import styled from 'styled-components'

import { UnstyledButton } from 'components/Button'

export const SelectedCarrierHeader = styled.div`
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.mediumGray};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  width: 100%;
`
export const InformationBox = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`
export const SeparatorLine = styled.div`
  background-color: ${({ theme }) => theme.colors.mediumGray};
  display: flex;
  flex-direction: row;
  height: 34px;
  width: 1px;
`
export const CloseButton = styled(UnstyledButton)`
  display: flex;
  flex-direction: row;
  width: 100px;
`

export const FilterWrapper = styled.div<{ align?: string; justify?: string }>`
  display: flex;

  ${({ align }) => `align-items: ${align};`}
  ${({ justify }) => `justify-content: ${justify};`}
`

export const CenteredText = styled(Text)`
  margin-top: 20px;
  text-align: center;
`
