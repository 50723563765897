import { Tag as BaseTag } from '@loadsmart/loadsmart-ui'
import { getToken } from '@loadsmart/loadsmart-ui/dist/theming'
import styled from 'styled-components'

import { TAG_STATUS_MAP } from 'utils/constants'

const Tag = styled(BaseTag)`
  margin-left: ${getToken('space-m')};
`

const LaneStatusTag = ({ status }: { readonly status: LaneOverviewStatus }) => {
  const hasNoStatus = 0
  return (
    <Tag size="small" variant={TAG_STATUS_MAP[status]}>
      {status !== hasNoStatus ? status : 'inactive'}
    </Tag>
  )
}

export default LaneStatusTag
