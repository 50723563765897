import Pusher from 'pusher-js'
// @ts-expect-error -- we are using worker-plugin to load the worker, that's why TS doesn't find the file
import workerURL from 'worker-plugin/loader?name=pusher!./worker.ts'

import { userUtils } from '_shared_/user'
import assert from 'utils/assert'
import { PUSHER_CONFIG } from 'utils/constants'

import PusherClient from './client'
import type {
  OnReadyCallback,
  OnErrorCallback,
  OnMessageCallback,
  PusherError,
} from './types'

type WorkerEventPayload =
  | { type: 'ready'; payload: undefined }
  | { type: 'error'; payload: { error: PusherError } }
  | { type: 'message'; payload: { event: string; data?: unknown } }

export default class PusherManager {
  private pusher?: PusherClient
  private worker?: SharedWorker

  onReady?: OnReadyCallback
  onError?: OnErrorCallback
  onMessage?: OnMessageCallback

  private init() {
    // If there's no key, Pusher won't be able to connect successfully.
    // But it won't let us know. So we try to catch that problem before so we can provide fallbacks in that scenario
    // In most cases, this only happens in local development when we don't have a key in our env variables.
    if (!PUSHER_CONFIG.key) {
      this.onError?.({
        type: 'ConfigurationError',
        message: "Unable to initialize Pusher. There's no valid key",
      })
      return
    }

    if (window.SharedWorker) {
      this.worker = new SharedWorker(workerURL, { name: 'pusher' })

      this.worker.port.start()

      this.worker.port.onmessage = (
        event: MessageEvent<WorkerEventPayload>
      ) => {
        const { type, payload } = event.data

        if (type === 'ready') {
          this.onReady?.()
        }

        if (type === 'error') {
          assert(
            payload?.error,
            'PusherManager: error is required with error messages'
          )

          this.onError?.(payload.error)
        }

        if (type === 'message') {
          assert(
            payload?.event,
            'PusherManager: event is required with messages'
          )

          this.onMessage?.(payload?.event, payload?.data)
        }
      }

      this.worker.port.postMessage({
        type: 'setAccessToken',
        payload: { accessToken: userUtils.accessToken },
      })
    } else {
      this.pusher = PusherClient.instances[0] || new PusherClient(Pusher)

      this.pusher.setAccessToken(userUtils.accessToken)
    }
  }

  private destroy() {
    if (this.worker) {
      this.worker.port.close()
    }
  }

  subscribe(channel: string, events: string | string[]) {
    assert(this.onReady, 'PusherManager: onReady callback is required')
    assert(this.onError, 'PusherManager: onError callback is required')
    assert(this.onMessage, 'PusherManager: onMessage callback is required')

    // We only initialize Pusher when we first attempt to subscribe to a channel
    this.init()

    if (this.worker) {
      this.worker.port.postMessage({
        type: 'subscribe',
        payload: { channel, events },
      })
    }

    if (this.pusher) {
      this.pusher.subscribe(
        channel,
        events,
        this.onReady,
        this.onError,
        this.onMessage
      )
    }
  }

  unsubscribe(channel: string, events: string | string[]) {
    assert(this.onReady, 'PusherManager: onReady callback is required')
    assert(this.onError, 'PusherManager: onError callback is required')
    assert(this.onMessage, 'PusherManager: onMessage callback is required')

    if (this.worker) {
      this.worker.port.postMessage({
        type: 'unsubscribe',
        payload: { channel, events },
      })

      this.worker.port.close()
    }

    if (this.pusher) {
      this.pusher.unsubscribe(
        channel,
        events,
        this.onReady,
        this.onError,
        this.onMessage
      )
    }

    this.destroy()
  }
}
