import MultiCurrencyValue from 'rfp/rfp-details/components/MultiCurrencyValue'
import type { Currency } from 'rfp/rfp.types'
import { RFP_STATE } from 'utils/constants'

import type { TableData } from '../LanesTableV2/types'
import { ActionButton, PriceChangeIndicator } from './styles'

export const VersusCell = ({
  row,
  bidOption,
  rateOption,
}: {
  readonly row: { original: Record<string, number> }
  readonly bidOption: string
  readonly rateOption: string
}) => {
  const optionOne = row.original[bidOption]
  const optionTwo = row.original[rateOption]
  const diff = optionOne - optionTwo

  if (diff === 0) {
    return <>0%</>
  }

  const direction = diff < 0 ? 'positive' : 'negative'
  const [smaller, bigger] =
    diff > 0 ? [optionTwo, optionOne] : [optionOne, optionTwo]
  const percentage = 100 - (smaller * 100) / bigger
  return (
    <>
      <PriceChangeIndicator direction={direction} />
      {percentage.toFixed(1)}%
    </>
  )
}

export const IncumbentCell = ({
  value,
}: {
  readonly value: TableData['incumbent_carrier_name']
}) => {
  if (value) {
    return <b style={{ whiteSpace: 'nowrap' }}>{value}</b>
  }
  return <>No Carriers</>
}

export const FlatRateCell = ({
  displayMultiCurrency,
  row,
  rateOption,
  rfpCurrency,
}: {
  readonly displayMultiCurrency: boolean
  readonly row: { original: Record<string, number> }
  readonly rateOption: string
  readonly rfpCurrency: Currency
}) => {
  const valueField = displayMultiCurrency
    ? `converted_${rateOption}`
    : rateOption

  return (
    <MultiCurrencyValue
      value={row.original[valueField]}
      rfpCurrency={rfpCurrency}
      nonConvertedValue={row.original[rateOption]}
    />
  )
}

export const ActionButtonCell = ({
  rfpState,
}: {
  readonly rfpState: string
}) => {
  if (rfpState === RFP_STATE.DRAFT) {
    return <ActionButton>Edit Lane</ActionButton>
  }

  if (rfpState === RFP_STATE.PUBLISHED) {
    return <ActionButton>Review</ActionButton>
  }

  return <ActionButton>Results</ActionButton>
}
