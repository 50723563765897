import { Dialog, Text } from '@loadsmart/loadsmart-ui'
import type { ReactNode } from 'react'

import { HubspotForm } from 'components/HubspotRequestDemoForm'
import type { HiddenUTMFields } from 'components/HubspotRequestDemoForm/types'

const DEFAULT_BODY = (
  <Text variant="body" color="color-neutral-darker">
    Upgrade your account to unlock additional features in ShipperGuide,
    including <b>unlimited quoting</b> with your carrier network.
  </Text>
)

const RequestDemoToUnlimited = ({
  isOpen,
  onClose,
  onConfirm,
  UTMFields,
  body = DEFAULT_BODY,
}: {
  readonly isOpen: boolean
  readonly onClose: () => void
  readonly onConfirm: () => void
  readonly UTMFields: HiddenUTMFields
  readonly body?: ReactNode | ReactNode[]
}) => {
  return (
    <Dialog
      open={isOpen}
      scale="small"
      onOverlayClick={onClose}
      data-testid="request-demo-modal"
    >
      <Dialog.Header>Move More with No Limits!</Dialog.Header>
      <Dialog.Body>
        {body}
        <HubspotForm onConfirm={onConfirm} UTMFields={UTMFields} />
        <Dialog.ActionCancel onCancel={onClose}>Not Now</Dialog.ActionCancel>
      </Dialog.Body>
    </Dialog>
  )
}

export default RequestDemoToUnlimited
