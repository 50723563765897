import { Text, Select } from '@loadsmart/loadsmart-ui'
import type { GenericOption } from '@loadsmart/loadsmart-ui/dist/components/Select/Select.types'
import type { Selectable } from '@loadsmart/loadsmart-ui/dist/hooks/useSelectable'
import type EventLike from '@loadsmart/loadsmart-ui/dist/utils/types/EventLike'
import type { FetchLocationOptions } from '@loadsmart/react-location-select'
import { useMemo } from 'react'

import type { LocationOrFacilityV2 } from '_shared_/components/form'
import { useFacilityDrawer } from '_shared_/hooks/useFacilityDrawer'
import { LocationSelect } from 'components/LocationSelect'
import { facilityDetailsToFacility } from 'components/StopsManager/StopFacility.helpers'
import { useSelectFacilityV2Search } from 'hooks/useSelectFacilitySearch'
import { formatLocationSelectAddressV2 } from 'utils/address'

import StopSelectOption from './StopSelectOption'
import type { AddressPropsV2 } from './types'

function CreateFacilityOption() {
  return (
    <Select.CreatableOption>
      <Text variant="body-bold" color="color-primary">
        Add new facility
      </Text>
    </Select.CreatableOption>
  )
}

const defaultConfig: FetchLocationOptions = {
  types: ['geocode', 'establishment'],
  restrictions: { country: ['us', 'ca'] },
}

export interface StopSearchFieldProps extends AddressPropsV2 {
  readonly withFacilities?: boolean
  readonly addNewFacilityOption?: boolean
  readonly config?: Partial<FetchLocationOptions>
}

export default function StopSearchFieldV2({
  onChange,
  callback,
  value,
  withFacilities = false,
  addNewFacilityOption = undefined,
  config,
  ...rest
}: StopSearchFieldProps) {
  const { openCreateFacility } = useFacilityDrawer({
    onFacilitySaved: (facility) => {
      callback({
        ...facilityDetailsToFacility(facility),
        isFacility: true,
      })
    },
  })

  const selectValue: GenericOption & Selectable = {
    _type: 'address',
    label: formatLocationSelectAddressV2(value),
    value: formatLocationSelectAddressV2(value),
  }

  const useFacilities = useMemo(
    () => (withFacilities ? [useSelectFacilityV2Search] : []),
    [withFacilities]
  )

  const locationSelectConfig = useMemo(
    () => ({ ...defaultConfig, ...config }),
    [config]
  )

  return (
    <LocationSelect
      name="locationSelect"
      datasources={useFacilities}
      onChange={(event: EventLike<Selectable | Selectable[] | null>) => {
        const location = event.target.value as LocationOrFacilityV2

        onChange?.(location?.address || '')
        callback(location)
      }}
      config={locationSelectConfig}
      isValidNewOption={addNewFacilityOption}
      createOptionPosition="first"
      onCreate={
        addNewFacilityOption
          ? () => {
              openCreateFacility()
            }
          : undefined
      }
      value={selectValue?.value ? selectValue : null}
      components={{
        CreatableOption: CreateFacilityOption,
        Option: StopSelectOption,
      }}
      {...rest}
    />
  )
}
