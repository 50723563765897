import * as Sentry from '@sentry/react'
import { useState } from 'react'
import { toast } from 'react-toastify'

import LanesTableV3 from 'components/LaneManagement/LanesTableV3'
import { Container } from 'components/LaneManagement/LanesTableV3/styles'
import { usePrimaryCarrierLanes } from 'hooks/useQuery'
import {
  getTablePageOffset,
  getTableSortDirection,
} from 'rfp/components/table/tableUtils'
import type { Currency, RequestForProposal } from 'rfp/rfp.types'

const LIMIT = 50

const defaultParams = {
  offset: 0,
  limit: LIMIT,
  sort: {
    column: 'lane_id',
    direction: 'asc',
  },
}

export default function SelectedCarrierLanesTable({
  rfpId,
  rfpState,
  rfpCurrency,
  rfpDistanceType,
  carrierId,
  onRowClick,
}: {
  readonly rfpId: string | number
  readonly rfpState: string
  readonly rfpCurrency: Currency
  readonly rfpDistanceType: RequestForProposal['distance_type']
  readonly carrierId: string | number
  readonly onRowClick: (lane: Lane) => void
}) {
  const [queryParams, setQueryParams] = useState(defaultParams)

  const {
    isLoading: isLoadingLanes,
    isFetching: isFetchingLanes,
    data: lanes,
  } = usePrimaryCarrierLanes(
    rfpId,
    carrierId,
    queryParams.limit,
    queryParams.offset,
    queryParams.sort,
    {
      refetchOnWindowFocus: false,
      onError(error) {
        Sentry.captureException(error)
        toast.error('Could not retrieve the lanes, please contact an admin')
      },
    }
  )

  const paginationHandler = (page: number) =>
    setQueryParams({
      ...queryParams,
      offset: getTablePageOffset(page, defaultParams.offset),
    })

  const changeSorting = (column: string) => {
    setQueryParams({
      ...queryParams,
      sort: {
        column,
        direction: getTableSortDirection(queryParams.sort.direction),
      },
    })
  }

  return (
    <Container style={{ paddingBottom: '40px' }}>
      <LanesTableV3
        lanes={lanes?.results ?? []}
        rfpState={rfpState}
        rfpCurrency={rfpCurrency}
        rfpDistanceType={rfpDistanceType}
        isLoading={isLoadingLanes || isFetchingLanes}
        onRowClick={(laneId) => {
          onRowClick(lanes?.results.find((lane: Lane) => laneId === lane.id))
        }}
        paginationHandler={paginationHandler}
        totalLanes={lanes?.count ?? 0}
        limit={queryParams.limit}
        changeSorting={changeSorting}
        sort={queryParams.sort}
      />
    </Container>
  )
}
