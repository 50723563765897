import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useOauth2 } from '_shared_/hooks/useOauth2'

interface LoginThroughBIPProps {
  readonly homePage: string
}

const LoginThroughBIP = ({ homePage }: LoginThroughBIPProps) => {
  const { logIn, isLoggedIn } = useOauth2()
  const history = useHistory()

  useEffect(() => {
    async function redirect() {
      const isLogged = await isLoggedIn()
      if (isLogged) {
        history.push(homePage)
      } else {
        logIn()
      }
    }

    redirect()
  }, [history, homePage, isLoggedIn, logIn])

  return null
}

export default LoginThroughBIP
