import { memo, useState } from 'react'

import ArrowDownDisabled from 'assets/icons/arrow-down-disabled.svg'
import ArrowDownHover from 'assets/icons/arrow-down-hover.svg'
import ArrowDown from 'assets/icons/arrow-down-idle.svg'

import { IconPlaceholder, RotatedImage } from '../lane-bids/styles'
import HoverWrapper from './HoverWrapper'

const ICON_MAP: {
  [key: string]: { [key: string]: string }
} = {
  arrowUp: {
    rotate: '180',
    disabled: ArrowDownDisabled,
    hover: ArrowDownHover,
    idle: ArrowDown,
    title: 'Move up',
  },
  arrowDown: {
    disabled: ArrowDownDisabled,
    hover: ArrowDownHover,
    idle: ArrowDown,
    title: 'Move down',
  },
}

const ActionIcon = ({
  name,
  action,
  visible,
  disabled,
}: {
  readonly name: string
  readonly action?: () => void
  readonly visible: boolean
  readonly disabled: boolean
}) => {
  const [onHover, setOnHover] = useState<boolean>(false)

  if (!visible) {
    return <IconPlaceholder />
  }
  const icon = ICON_MAP[name]
  const IconTag = icon.rotate ? RotatedImage : 'img'

  if (disabled) {
    return <IconTag src={icon.disabled} alt={icon.title} />
  }

  return (
    <HoverWrapper setOnHoverState={setOnHover}>
      <IconTag
        src={onHover ? icon.hover : icon.idle}
        alt={icon.title}
        onClick={action}
      />
    </HoverWrapper>
  )
}

export default memo(ActionIcon)
