import {
  Button,
  Drawer,
  Label,
  Layout,
  Select,
  Tag,
} from '@loadsmart/loadsmart-ui'
import type { Selectable } from '@loadsmart/loadsmart-ui/dist/hooks/useSelectable'
import type EventLike from '@loadsmart/loadsmart-ui/dist/utils/types/EventLike'
import type { CollectionLocationType } from '@loadsmart/react-location-select'
import { useCallback, useEffect, useMemo, useState } from 'react'

import StringLimiter from '_shared_/components/StringLimiter'
import { LocationSelect } from 'components/LocationSelect'
import {
  useLaneBidStatusOptions,
  useLaneEquipmentTypeOptions,
} from 'hooks/useQuery'
import type { BasicGmapsSuggestionType } from 'rfp/rfp-details/components/lane-form/utils'
import analytics, {
  AnalyticsEvent,
  AnalyticsEventTrigger,
} from 'utils/analytics'
import { RFP_STATE } from 'utils/constants'

interface FilterOption {
  value: string
  label: string
  _type?: string
}

interface AddressFilter extends FilterOption, BasicGmapsSuggestionType {}

export interface Filters {
  origin: AddressFilter[]
  destination: AddressFilter[]
  bidStatus: FilterOption[]
  equipmentType: FilterOption[]
}

export const defaultFilters: Filters = {
  origin: [],
  destination: [],
  bidStatus: [],
  equipmentType: [],
}

const FilterTags = ({
  values,
  onRemove,
}: {
  readonly values: FilterOption[]
  readonly onRemove: (value: FilterOption) => void
}) => {
  return (
    <Layout.Group space="s" data-testid="filter-tag-group">
      {values.map((value: any) => {
        return (
          <Tag
            onRemove={() => onRemove(value)}
            removable
            size="default"
            variant="default"
            key={value.label}
          >
            <StringLimiter limiter={30} value={value.label} />
          </Tag>
        )
      })}
    </Layout.Group>
  )
}

const GenericFilterTags = ({
  filters,
  filterName,
  onRemove,
}: {
  readonly filters: Filters
  readonly filterName: keyof Filters
  readonly onRemove: (value: FilterOption) => void
}) => {
  const filterValues = filters[filterName]
  return (
    filterValues && <FilterTags values={filterValues} onRemove={onRemove} />
  )
}

const locationSelectConfig: {
  types: CollectionLocationType[]
  restrictions: { country: string[] }
} = {
  types: ['geocode', 'establishment'],
  restrictions: { country: ['us', 'ca', 'mx'] },
}

interface LaneFiltersProps {
  readonly rfpId: string | number
  readonly rfpState: string
  readonly isOpen: boolean
  readonly onConfirm: (filters: Filters) => void
  readonly onClose: () => void
  readonly filters: Filters
}

type SelectEvent = EventLike<Selectable | Selectable[] | null>

const LaneFilters = ({
  rfpId,
  rfpState,
  filters,
  isOpen,
  onConfirm,
  onClose,
}: LaneFiltersProps) => {
  const { data: bidStatuses } = useLaneBidStatusOptions(rfpId)
  const { data: equipmentTypes } = useLaneEquipmentTypeOptions(rfpId)

  const [newFilters, setNewFilters] = useState(defaultFilters)

  useEffect(() => {
    setNewFilters(filters)
  }, [filters, isOpen])

  const bidStatusOptions = bidStatuses?.map((option) => ({
    value: option.value,
    label: `${option.label} (${option.count})`,
  }))

  const equipmentTypeOptions = equipmentTypes?.map((option) => ({
    value: option.value,
    label: `${option.label} (${option.count})`,
  }))

  const handleAddressField = useCallback(
    (key: keyof Filters) => (event: SelectEvent) => {
      if (event.target.value) {
        const locations = event.target.value as Address[]
        const filter = locations.map((location) => ({
          ...location,
          value: location.address ?? '',
          label: location.address ?? '',
        }))
        setNewFilters((prevFilters) => ({ ...prevFilters, [key]: filter }))
      } else {
        setNewFilters((prevFilters) => ({ ...prevFilters, [key]: [] }))
      }
    },
    [setNewFilters]
  )

  const handleOriginFieldChange = useMemo(
    () => handleAddressField('origin'),
    [handleAddressField]
  )

  const handleDestFieldChange = useMemo(
    () => handleAddressField('destination'),
    [handleAddressField]
  )

  const handleBidStatusChange = useCallback(
    (event: SelectEvent) => {
      setNewFilters((prevFilters) => ({
        ...prevFilters,
        bidStatus: (event.target.value
          ? [event.target.value]
          : []) as FilterOption[],
      }))
    },
    [setNewFilters]
  )

  const handleEquipTypeChange = useCallback(
    (event: SelectEvent) => {
      setNewFilters((prevFilters) => ({
        ...prevFilters,
        equipmentType: (event.target.value || []) as FilterOption[],
      }))
    },
    [setNewFilters]
  )

  const removeFilter = useCallback(
    (key: keyof Filters, value: FilterOption) => {
      setNewFilters({
        ...newFilters,
        [key]:
          newFilters[key]?.filter((item) => item.value !== value.value) ?? [],
      })
    },
    [newFilters, setNewFilters]
  )

  return (
    <Drawer open={isOpen} onClose={onClose}>
      <Drawer.Header>Apply Filters</Drawer.Header>
      <Drawer.Body>
        <Layout.Stack space="l">
          <Layout.Stack space="xs">
            <Label htmlFor="filter-entity">Origin/Pickup</Label>
            <Layout.Stack space="s">
              <LocationSelect
                name="filter-origin"
                multiple
                value={newFilters.origin}
                onChange={handleOriginFieldChange}
                config={locationSelectConfig}
                placeholder="Select origin/pickup address or zip code"
              />
              <GenericFilterTags
                filters={newFilters}
                filterName="origin"
                onRemove={(value: FilterOption) =>
                  removeFilter('origin', value)
                }
              />
            </Layout.Stack>
          </Layout.Stack>
          <Layout.Stack space="xs">
            <Label htmlFor="filter-entity">Destination/Delivery</Label>
            <Layout.Stack space="s">
              <LocationSelect
                name="filter-destination"
                multiple
                value={newFilters.destination}
                onChange={handleDestFieldChange}
                config={locationSelectConfig}
                placeholder="Select destination/delivery address or zip code"
              />
              <GenericFilterTags
                filters={newFilters}
                filterName="destination"
                onRemove={(value: FilterOption) =>
                  removeFilter('destination', value)
                }
              />
            </Layout.Stack>
          </Layout.Stack>
          {rfpState === RFP_STATE.PUBLISHED && (
            <Layout.Stack space="xs">
              <Label htmlFor="filter-bid-status">Bid</Label>
              <Layout.Stack space="s">
                <Select
                  placeholder="Select bid status"
                  id="filter-bid-status"
                  name="filter-bid-status"
                  options={bidStatusOptions}
                  value={newFilters.bidStatus}
                  onChange={handleBidStatusChange}
                />
              </Layout.Stack>
            </Layout.Stack>
          )}
          <Layout.Stack space="xs">
            <Label htmlFor="filter-equipment-type">Equipment type</Label>
            <Layout.Stack space="s">
              <Select
                multiple
                placeholder="Select equipment type"
                id="filter-equipment-type"
                name="filter-equipment-type"
                options={equipmentTypeOptions}
                value={newFilters.equipmentType}
                onChange={handleEquipTypeChange}
              />
              <GenericFilterTags
                filters={newFilters}
                filterName="equipmentType"
                onRemove={(value: FilterOption) =>
                  removeFilter('equipmentType', value)
                }
              />
            </Layout.Stack>
          </Layout.Stack>
        </Layout.Stack>
      </Drawer.Body>
      <Drawer.Footer>
        <Layout.Group space="s" justify="flex-start" style={{ width: '100%' }}>
          <Button
            variant="primary"
            onClick={() => {
              analytics.track(
                AnalyticsEvent.LanesFilter,
                AnalyticsEventTrigger.click,
                {
                  origin: newFilters.origin?.length > 0,
                  destination: newFilters.destination?.length > 0,
                  bid_status: newFilters.bidStatus?.length > 0,
                  equipment_type: newFilters.equipmentType?.length > 0,
                }
              )
              onConfirm(newFilters)
            }}
          >
            Apply Filters
          </Button>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
          <Button
            onClick={() => setNewFilters(defaultFilters)}
            style={{ marginLeft: 'auto' }}
            variant="tertiary"
          >
            Clear Filters
          </Button>
        </Layout.Group>
      </Drawer.Footer>
    </Drawer>
  )
}

export default LaneFilters
