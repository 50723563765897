import { Layout, Tabs, Text } from '@loadsmart/loadsmart-ui'
import { Button } from '@loadsmart/miranda-react'
import * as Sentry from '@sentry/react'
import type { ReactNode, SetStateAction } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useDebounce } from 'use-debounce'

import { useCurrentUser } from '_shared_/user/useCurrentUser'
import { useModal } from 'hooks/useModal'
import {
  useAllocatedLanes,
  useAwardScenarios,
  useAwardScenariosStats,
  useDeallocatedLanes,
} from 'hooks/useQuery'
import RFPLimitBanner from 'rfp/components/rfp-limit-banner'
import {
  defaultLaneTableAwardedParams,
  defaultLaneTableParams,
} from 'rfp/components/table/laneParams'
import type { UploadLanesSuccessResponse } from 'rfp/rfp-details/types'
import type { RequestForProposal } from 'rfp/rfp.types'
import { removeFromAwardScenarios, updateAwardScenarios } from 'services/lane'
import { recommendedCarriers } from 'services/recommendedCarriers'
import { CURRENCY, RFP_STATE, USER_CATEGORIES } from 'utils/constants'
import { numberFormatter } from 'utils/numbers'

import AwardScenariosTable from './award-scenarios-table'
import AwardScenariosSummary from './AwardScenariosSummary'
import Carriers from './Carriers'
import EmptyState from './EmptyState'
import LanesAllocatedTable from './lanes-allocated-table'
import LanesDeallocatedTable from './lanes-deallocated-table'
import PrimaryCarriersTable from './PrimaryCarriersTable'
import RecommendedCarriersSideBar from './RecommendedCarriersSideBar'
import RoutingGuideSideBar from './RoutingGuideSideBar'

const TABS = {
  LANES_IN_PROGRESS: 'lanes-in-progress',
  AWARD_SCENARIOS: 'award-scenarios',
  LANES_ALLOCATED: 'lanes-allocated',
  PRIMARY_CARRIERS: 'primary-carriers',
  CARRIERS: 'carriers',
}

interface AwardScenariosProps {
  readonly rfp: RequestForProposal
  readonly remainingRfpQuota: number
  readonly carrierInvitesMaxQuota: number
  readonly addLanesButton: ReactNode
  readonly importLanesButton: ReactNode
  readonly showLaneProposals: (lane: Lane) => void
  readonly addLanesButtonProps: {
    onClick: () => void
    disabled: boolean
  }
  readonly laneCsvTemplateButtonProps: {
    onClick: () => void
    disabled: boolean
    isDownloading: boolean
  }
  readonly invites: NewCarrierInvite[]
  readonly refetchCarrierInvites: () => Promise<unknown>
  readonly isLoadingCarrierInvites: boolean
  readonly carrierInviteStatus: string
  readonly onSuccessUploadLane: (lanes: UploadLanesSuccessResponse) => void
  readonly onErrorUploadLane: (start?: number, errorType?: string) => void
}

const AllLanesAllocatedEmptyState = ({
  changeTab,
}: {
  readonly changeTab: () => void
}) => {
  return (
    <EmptyState>
      <Layout.Stack space="s">
        <Text variant="heading-sm-bold" color="color-neutral-darkest">
          All Lanes Allocated
        </Text>
        <Text variant="caption" color="color-neutral-darker">
          Well done. You have allocated all
          <br />
          the lanes on this RFP.
        </Text>
      </Layout.Stack>
      <Button
        variant="primary"
        onClick={() => {
          changeTab()
        }}
        size="small"
      >
        Go to lanes allocated
      </Button>
    </EmptyState>
  )
}

const checkRfpState = (state: string) =>
  [RFP_STATE.AWARDING, RFP_STATE.FINALIZED, RFP_STATE.ARCHIVED].includes(state)

const checkHasAwardScenariosFilters = (
  carrierFilter: string | null,
  typesFilter: string | null,
  backupFilter: string | null,
  splitCarriersFilter: string | null
) => {
  return Boolean(
    carrierFilter || typesFilter || backupFilter || splitCarriersFilter
  )
}

const LanesNotAwardedIndicator = ({
  isLoadingAwardStats,
  awardStats,
}: {
  readonly isLoadingAwardStats: boolean
  readonly awardStats: AwardScenariosStats | undefined
}) => {
  if (isLoadingAwardStats) {
    return null
  }

  if (!awardStats) {
    return null
  }

  return (
    <>
      &nbsp; &bull; &nbsp;
      {numberFormatter(
        100 - (awardStats?.overall_allocation_percentage ?? 0),
        2
      )}
      %
    </>
  )
}

const LanesAwardedIndicator = ({
  isLoadingAwardStats,
  awardStats,
}: {
  readonly isLoadingAwardStats: boolean
  readonly awardStats: AwardScenariosStats | undefined
}) => {
  if (isLoadingAwardStats) {
    return null
  }

  if (!awardStats) {
    return null
  }

  return (
    <>
      &nbsp; &bull; &nbsp;
      {numberFormatter(awardStats?.overall_allocation_percentage, 2) ?? 0}%
    </>
  )
}

const LanesInProgressTabTitle = ({
  rfpState,
  isLoadingAwardStats,
  awardStats,
  deallocatedCount,
}: {
  readonly rfpState: string
  readonly isLoadingAwardStats: boolean
  readonly awardStats: AwardScenariosStats | undefined
  readonly deallocatedCount: number | undefined
}) => {
  if (rfpState === RFP_STATE.DRAFT) {
    return (
      <>
        Lanes &nbsp; &bull; &nbsp;
        {deallocatedCount ?? 0}
      </>
    )
  }

  return (
    <>
      Lanes in progress
      {!isLoadingAwardStats && awardStats && (
        <>
          &nbsp; &bull; &nbsp;
          {numberFormatter(
            100 - (awardStats?.overall_allocation_percentage ?? 0),
            1
          )}
          %
        </>
      )}
    </>
  )
}

const CarriersTabTitle = ({
  rfpState,
  invites,
}: {
  readonly rfpState: string
  readonly invites: NewCarrierInvite[]
}) => {
  return (
    <>
      {rfpState === RFP_STATE.DRAFT ? 'Carriers' : 'Invited Carriers'}
      {rfpState === RFP_STATE.DRAFT && (
        <>
          &nbsp; &bull; &nbsp;
          {/*@TODO: Review this if we paginate the invite*/}
          {invites && invites.length}
        </>
      )}
    </>
  )
}

const PublishedRFPTabItems = ({
  rfpState,
  isLoadingAwardStats,
  awardStats,
}: {
  readonly rfpState: string
  readonly isLoadingAwardStats: boolean
  readonly awardStats: AwardScenariosStats | undefined
}) => {
  const isRfpPublished =
    rfpState === RFP_STATE.PUBLISHED || rfpState === RFP_STATE.CLOSED

  if (!isRfpPublished) {
    return null
  }

  return (
    <>
      <Tabs.Item name={TABS.AWARD_SCENARIOS}>Award Scenarios</Tabs.Item>
      <Tabs.Item name={TABS.LANES_ALLOCATED}>
        <>
          Lanes allocated
          {!isLoadingAwardStats && awardStats && (
            <>
              &nbsp; &bull; &nbsp;
              {numberFormatter(awardStats?.overall_allocation_percentage, 1) ??
                0}
              %
            </>
          )}
        </>
      </Tabs.Item>
      <Tabs.Item name={TABS.PRIMARY_CARRIERS}>Primary Carriers</Tabs.Item>
    </>
  )
}

const checkRenderAllLanesAllocatedEmptyState = (
  deallocatedCount: number | undefined,
  allocatedCount: number | undefined
) => {
  if (allocatedCount === undefined || deallocatedCount === undefined) {
    return false
  }

  return allocatedCount > 0 && deallocatedCount === 0
}

const LimitBanner = ({
  shouldShowLimitBanner,
  remainingRfpQuota,
  carrierInvitesMaxQuota,
}: {
  readonly shouldShowLimitBanner: boolean
  readonly remainingRfpQuota: number
  readonly carrierInvitesMaxQuota: number
}) => {
  if (!shouldShowLimitBanner) {
    return null
  }

  return (
    <RFPLimitBanner
      remainingRfpQuota={remainingRfpQuota}
      carrierInvitesMaxQuota={carrierInvitesMaxQuota}
    />
  )
}

const QUERY_ERROR_MESSAGE =
  'Could not retrieve the lanes, please contact an admin'

const AwardScenarios = ({
  rfp,
  remainingRfpQuota,
  carrierInvitesMaxQuota,
  addLanesButton,
  importLanesButton,
  showLaneProposals,
  addLanesButtonProps,
  laneCsvTemplateButtonProps,
  invites = [],
  carrierInviteStatus,
  refetchCarrierInvites,
  isLoadingCarrierInvites,
  onErrorUploadLane,
  onSuccessUploadLane,
}: AwardScenariosProps) => {
  const [tab, setTab] = useState(TABS.LANES_IN_PROGRESS)
  const routingGuideSidebar = useModal()
  const findCarriersSidebar = useModal()
  const [selectedLane, setSelectedLane] = useState<Lane>()
  const [selectedLaneId, setSelectedLaneId] = useState<string | number>()
  const allocateDialog = useModal()
  const [carrierFilter, setCarrierFilter] = useState<string | null>(null)
  const [typesFilter, setTypesFilter] = useState<string | null>(null)
  const [backupFilter, setBackupFilter] = useState<string | null>(null)
  const [backupFilterActive, setBackupFilterActive] = useState<boolean>(false)
  const [splitCarriersFilter, setSplitCarriersFilter] = useState<string | null>(
    null
  )
  const [splitCarriersFilterActive, setSplitCarriersFilterActive] =
    useState<boolean>(false)
  const [scenariosSelected, setScenariosSelected] = useState<
    undefined | any[]
  >()
  const [recommendedCarriersData, setRecommendedCarriersData] =
    useState<RecommendedCarrier[]>()
  const [carriersRecommendationsError, setCarriersRecommendationsError] =
    useState<boolean>(false)

  const getTypesFilter = useCallback(() => {
    return typesFilter ?? 'any'
  }, [typesFilter])

  const getCarrierFilter = useCallback(() => {
    return carrierFilter ?? 'any'
  }, [carrierFilter])

  const getBackupFilter = useCallback(() => {
    if (backupFilterActive) {
      return backupFilter ?? ''
    }

    return ''
  }, [backupFilter, backupFilterActive])

  const getMaxSplitCarriers = useCallback(() => {
    if (splitCarriersFilterActive) {
      return splitCarriersFilter ?? ''
    }

    return ''
  }, [splitCarriersFilter, splitCarriersFilterActive])

  const {
    mutate: recommendedCarriersMutation,
    isLoading: isLoadingRecommendedCarriers,
  } = useMutation({
    mutationFn: recommendedCarriers,
    onSuccess(data) {
      setCarriersRecommendationsError(false)
      setRecommendedCarriersData(data)
    },
    onError(error) {
      setCarriersRecommendationsError(true)
      Sentry.captureException(error)
    },
  })

  const [isRfpAfterPublishedState, setIsRfpAfterPublishedState] = useState(
    checkRfpState(rfp.state)
  )

  useEffect(() => {
    setIsRfpAfterPublishedState(checkRfpState(rfp.state))
    setTab(
      checkRfpState(rfp.state) ? TABS.LANES_ALLOCATED : TABS.LANES_IN_PROGRESS
    )
  }, [rfp.state])

  const [allocatedLanesParams, setAllocatedLanesParams] = useState(
    defaultLaneTableAwardedParams
  )
  const [deallocatedLanesParams, setDeallocatedLanesParams] = useState(
    defaultLaneTableParams
  )

  const [awardScenariosParams, setAwardScenariosParams] = useState(
    defaultLaneTableParams
  )

  const [deallocatedLanesSearchQuery, setDeallocatedLanesSearchQuery] =
    useState('')
  const [debouncedDeallocatedLanesSearchQuery] = useDebounce(
    deallocatedLanesSearchQuery,
    450
  )

  const [allocatedLanesSearchQuery, setAllocatedLanesSearchQuery] = useState('')
  const [debouncedAllocatedLanesSearchQuery] = useDebounce(
    allocatedLanesSearchQuery,
    450
  )

  const hasAwardScenariosFilters = checkHasAwardScenariosFilters(
    carrierFilter,
    typesFilter,
    backupFilter,
    splitCarriersFilter
  )

  const deallocatedLanesQuery = useDeallocatedLanes(
    rfp.id.toString(),
    deallocatedLanesParams.limit,
    deallocatedLanesParams.offset,
    deallocatedLanesParams.sort,
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      meta: {
        errorMessage: QUERY_ERROR_MESSAGE,
      },
    },
    debouncedDeallocatedLanesSearchQuery,
    deallocatedLanesParams.filters
  )

  const allocatedLanesQuery = useAllocatedLanes(
    {
      rfpId: rfp.id,
      limit: allocatedLanesParams.limit,
      offset: allocatedLanesParams.offset,
      sort: allocatedLanesParams.sort,
      primaryAwardStatus: allocatedLanesParams.primaryAwardStatus,
      query: debouncedAllocatedLanesSearchQuery,
      filters: allocatedLanesParams.filters,
    },
    {
      enabled: true,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      meta: {
        errorMessage: QUERY_ERROR_MESSAGE,
      },
    }
  )
  const queryClient = useQueryClient()

  useEffect(() => {
    if (allocatedLanesQuery.data) {
      queryClient.refetchQueries({
        queryKey: ['primaryCarriers', rfp.id.toString()],
      })
    }
  }, [allocatedLanesQuery.data, rfp.id, queryClient])

  const setLaneSearchQuery = useCallback(
    (query: SetStateAction<string>) => {
      setDeallocatedLanesParams({
        ...deallocatedLanesParams,
        page: defaultLaneTableParams.page,
        offset: defaultLaneTableParams.offset,
      })
      setDeallocatedLanesSearchQuery(query)
    },
    [deallocatedLanesParams]
  )

  const awardScenariosQuery = useAwardScenarios(
    {
      rfpId: rfp.id,
      limit: awardScenariosParams.limit,
      offset: awardScenariosParams.offset,
      sort: awardScenariosParams.sort,
      carrierEntity: getTypesFilter(),
      carrierIncumbency: getCarrierFilter(),
      backupCarrierCount: getBackupFilter(),
      maxSplitCarriers: getMaxSplitCarriers(),
    },
    {
      cacheTime: 0,
      enabled: hasAwardScenariosFilters,
      refetchOnWindowFocus: false,
      meta: {
        errorMessage: QUERY_ERROR_MESSAGE,
      },
    }
  )

  const awardScenariosLoaded = awardScenariosQuery.isSuccess

  const enableGetStats = () => {
    if (tab === TABS.AWARD_SCENARIOS) {
      return awardScenariosLoaded && hasAwardScenariosFilters
    }
    if (
      tab === TABS.PRIMARY_CARRIERS ||
      tab === TABS.CARRIERS ||
      tab === TABS.LANES_ALLOCATED
    ) {
      return false
    }
    return (
      (rfp.state === RFP_STATE.PUBLISHED || rfp.state === RFP_STATE.CLOSED) &&
      hasAwardScenariosFilters
    )
  }

  const {
    data: awardStats,
    isLoading: isLoadingAwardStats,
    refetch: refetchStats,
  } = useAwardScenariosStats(
    rfp.id,
    getTypesFilter(),
    getCarrierFilter(),
    getBackupFilter(),
    getMaxSplitCarriers(),
    {
      enabled: enableGetStats(),
    }
  )

  useEffect(() => {
    if (awardScenariosLoaded) {
      refetchStats()
    }
  }, [awardScenariosLoaded, refetchStats])

  const showLaneDetailsOrRoutingGuide = (lane: Lane) => {
    if (rfp.state === RFP_STATE.DRAFT) {
      showLaneProposals(lane)
      return
    }

    setSelectedLane(lane)

    // this setSelectedLaneId is a escape hatch to update the selectedLane in useEffect above
    // without triggering an inifite loop. We only need this for Routing Guide,
    // this is why we don't use it before showLaneProposals
    setSelectedLaneId(lane.id)

    // Ideally we should have a WS event to get the new remaining_award_volume
    allocatedLanesQuery.refetch()
    deallocatedLanesQuery.refetch()

    routingGuideSidebar.openModal()
  }
  const showRecommendedCarriersSidebar = (lane: Lane) => {
    setSelectedLane(lane)
    setSelectedLaneId(lane.id)

    recommendedCarriersMutation(lane.id)
    findCarriersSidebar.openModal()
  }

  const shouldRenderAllLanesAllocatedEmptyState =
    checkRenderAllLanesAllocatedEmptyState(
      deallocatedLanesQuery.data?.count,
      allocatedLanesQuery.data?.count
    )

  const resetParamsAndRefetchQueries = () => {
    if (hasAwardScenariosFilters) {
      setAwardScenariosParams(defaultLaneTableParams)
      awardScenariosQuery.refetch()
    }

    setAllocatedLanesParams(defaultLaneTableAwardedParams)
    setDeallocatedLanesParams(defaultLaneTableParams)

    allocatedLanesQuery.refetch()
    deallocatedLanesQuery.refetch()
  }

  const { mutate: updateScenarios, isLoading: isUpdatingScenarios } =
    useMutation({
      mutationFn: updateAwardScenarios,
      onSuccess: () => {
        allocateDialog.closeModal()
        queryClient.refetchQueries({
          queryKey: ['rfpTotalizers', rfp.id.toString()],
        })
        queryClient.refetchQueries({
          queryKey: ['primaryCarriers', rfp.id.toString()],
        })
        resetParamsAndRefetchQueries()
      },
    })

  const { mutate: removeScenarios, isLoading: isRemovingScenarios } =
    useMutation({
      mutationFn: removeFromAwardScenarios,
      onSuccess: () => {
        queryClient.refetchQueries({
          queryKey: ['rfpTotalizers', rfp.id.toString()],
        })
        queryClient.refetchQueries({
          queryKey: ['primaryCarriers', rfp.id.toString()],
        })
        resetParamsAndRefetchQueries()
      },
    })

  useEffect(() => {
    // Since we also update data using WebSocket, we also need to update the selected lane
    // in order to reflect the new data in RoutingGuide
    if (selectedLaneId) {
      let updatedLane = undefined

      const lanesDataSource = [
        deallocatedLanesQuery.data?.results,
        allocatedLanesQuery.data?.results,
        awardScenariosQuery.data?.results,
        queryClient.getQueryData<PaginatedResult>(['primaryCarriersLanes'], {
          exact: false,
        })?.results,
      ]

      for (const dataSource of lanesDataSource) {
        updatedLane = dataSource?.find(
          (lane: Lane) => lane.id === selectedLaneId
        )

        if (updatedLane) {
          break
        }
      }

      // since this effect can happen between various loading states
      //  we want to only update the selectLane if we have new data ready
      if (updatedLane) {
        setSelectedLane({ ...updatedLane })
      }
    }
  }, [
    deallocatedLanesQuery.data,
    selectedLaneId,
    allocatedLanesQuery.data,
    awardScenariosQuery.data,
    queryClient,
  ])

  const { user } = useCurrentUser()
  const isPaidUser = user?.user_category === USER_CATEGORIES.PAID_SHIPPER

  const shouldShowLimitBanner = !isPaidUser

  const getRFPCurrency = () => rfp?.currency ?? CURRENCY.USD

  return (
    <>
      <Tabs
        className="miranda-tabs"
        activeTab={tab}
        onTabChange={(selectedTab: string) => setTab(selectedTab)}
      >
        {isRfpAfterPublishedState && (
          <>
            <Tabs.Items>
              <Tabs.Item name={TABS.LANES_ALLOCATED}>
                <>
                  Lanes awarded
                  <LanesAwardedIndicator
                    awardStats={awardStats}
                    isLoadingAwardStats={isLoadingAwardStats}
                  />
                </>
              </Tabs.Item>

              <Tabs.Item name={TABS.LANES_IN_PROGRESS}>
                <>
                  Lanes not awarded
                  <LanesNotAwardedIndicator
                    awardStats={awardStats}
                    isLoadingAwardStats={isLoadingAwardStats}
                  />
                </>
              </Tabs.Item>

              <Tabs.Item name={TABS.PRIMARY_CARRIERS}>
                Primary Carriers
              </Tabs.Item>

              <Tabs.Item name={TABS.CARRIERS}>Invited Carriers</Tabs.Item>
            </Tabs.Items>
            <LimitBanner
              carrierInvitesMaxQuota={carrierInvitesMaxQuota}
              remainingRfpQuota={remainingRfpQuota}
              shouldShowLimitBanner={shouldShowLimitBanner}
            />
            <Tabs.Panels>
              <Tabs.Panel name={TABS.LANES_ALLOCATED}>
                <LanesAllocatedTable
                  isSpotMode={rfp.capacity_time_period === 'spot'}
                  rfpId={rfp.id}
                  removeScenarios={removeScenarios}
                  rfpState={rfp.state}
                  rfpCurrency={rfp.currency}
                  onRowClick={showLaneDetailsOrRoutingGuide}
                  query={allocatedLanesQuery}
                  params={allocatedLanesParams}
                  setParams={setAllocatedLanesParams}
                  changeTab={() => setTab(TABS.LANES_IN_PROGRESS)}
                  isRemovingScenarios={isRemovingScenarios}
                  selectable={false}
                  allocatedLanesSearchQuery={allocatedLanesSearchQuery}
                  setAllocatedLanesSearchQuery={setAllocatedLanesSearchQuery}
                />
              </Tabs.Panel>

              <Tabs.Panel name={TABS.LANES_IN_PROGRESS}>
                <LanesDeallocatedTable
                  isSpotMode={rfp.capacity_time_period === 'spot'}
                  rfpId={rfp.id}
                  rfpState={rfp.state}
                  rfpCurrency={rfp.currency}
                  rfpDistanceType={rfp.distance_type}
                  isRankedAward={false}
                  addLaneButton={addLanesButton}
                  importLaneButton={importLanesButton}
                  onRowClick={showLaneDetailsOrRoutingGuide}
                  openFindCarriersSidebar={showRecommendedCarriersSidebar}
                  query={deallocatedLanesQuery}
                  defaultParams={defaultLaneTableParams}
                  params={deallocatedLanesParams}
                  setParams={setDeallocatedLanesParams}
                  emptyStateNode={
                    shouldRenderAllLanesAllocatedEmptyState ? (
                      <AllLanesAllocatedEmptyState
                        changeTab={() => setTab(TABS.LANES_ALLOCATED)}
                      />
                    ) : null
                  }
                  deallocatedLanesSearchQuery={deallocatedLanesSearchQuery}
                  setLaneSearchQuery={setLaneSearchQuery}
                  addLanesButtonProps={addLanesButtonProps}
                  laneCsvTemplateButtonProps={laneCsvTemplateButtonProps}
                  onErrorUploadLane={onErrorUploadLane}
                  onSuccessUploadLane={onSuccessUploadLane}
                />
              </Tabs.Panel>

              <Tabs.Panel name={TABS.PRIMARY_CARRIERS}>
                <PrimaryCarriersTable
                  onLaneRowClick={showLaneDetailsOrRoutingGuide}
                  rfpId={rfp.id}
                  rfpState={rfp.state}
                  rfpCurrency={rfp.currency}
                  rfpDistanceType={rfp.distance_type}
                  changeTab={() => setTab(TABS.LANES_IN_PROGRESS)}
                />
              </Tabs.Panel>

              <Tabs.Panel name={TABS.CARRIERS}>
                <Carriers
                  rfpId={rfp.id}
                  rfpUuid={rfp.uuid}
                  rfpState={rfp.state as RfpState}
                  rfpRequestDeadline={rfp.request_deadline}
                  rfpCurrency={getRFPCurrency()}
                  invites={invites}
                  carrierInviteStatus={carrierInviteStatus}
                  refetchCarrierInvites={refetchCarrierInvites}
                  isLoadingCarrierInvites={isLoadingCarrierInvites}
                />
              </Tabs.Panel>
            </Tabs.Panels>
          </>
        )}
        {!isRfpAfterPublishedState && (
          <>
            <Tabs.Items>
              <Tabs.Item name={TABS.LANES_IN_PROGRESS}>
                <LanesInProgressTabTitle
                  awardStats={awardStats}
                  deallocatedCount={deallocatedLanesQuery.data?.count}
                  isLoadingAwardStats={isLoadingAwardStats}
                  rfpState={rfp.state}
                />
              </Tabs.Item>
              <PublishedRFPTabItems
                awardStats={awardStats}
                isLoadingAwardStats={isLoadingAwardStats}
                rfpState={rfp.state}
              />

              <Tabs.Item name={TABS.CARRIERS}>
                <CarriersTabTitle rfpState={rfp.state} invites={invites} />
              </Tabs.Item>
            </Tabs.Items>
            <LimitBanner
              carrierInvitesMaxQuota={carrierInvitesMaxQuota}
              remainingRfpQuota={remainingRfpQuota}
              shouldShowLimitBanner={shouldShowLimitBanner}
            />
            <Tabs.Panels>
              <Tabs.Panel name={TABS.LANES_IN_PROGRESS}>
                <LanesDeallocatedTable
                  isSpotMode={rfp.capacity_time_period === 'spot'}
                  rfpState={rfp.state}
                  rfpCurrency={rfp.currency}
                  rfpId={rfp.id}
                  rfpDistanceType={rfp.distance_type}
                  isRankedAward={false}
                  addLaneButton={addLanesButton}
                  importLaneButton={importLanesButton}
                  onRowClick={showLaneDetailsOrRoutingGuide}
                  openFindCarriersSidebar={showRecommendedCarriersSidebar}
                  query={deallocatedLanesQuery}
                  defaultParams={defaultLaneTableParams}
                  params={deallocatedLanesParams}
                  setParams={setDeallocatedLanesParams}
                  emptyStateNode={
                    shouldRenderAllLanesAllocatedEmptyState ? (
                      <AllLanesAllocatedEmptyState
                        changeTab={() => setTab(TABS.LANES_ALLOCATED)}
                      />
                    ) : null
                  }
                  deallocatedLanesSearchQuery={deallocatedLanesSearchQuery}
                  setLaneSearchQuery={setLaneSearchQuery}
                  addLanesButtonProps={addLanesButtonProps}
                  laneCsvTemplateButtonProps={laneCsvTemplateButtonProps}
                  onErrorUploadLane={onErrorUploadLane}
                  onSuccessUploadLane={onSuccessUploadLane}
                />
              </Tabs.Panel>

              <>
                <Tabs.Panel name={TABS.AWARD_SCENARIOS}>
                  {shouldRenderAllLanesAllocatedEmptyState ? (
                    <AllLanesAllocatedEmptyState
                      changeTab={() => setTab(TABS.LANES_ALLOCATED)}
                    />
                  ) : (
                    <>
                      <AwardScenariosSummary
                        rfpId={rfp.id}
                        rfpCurrency={rfp.currency}
                        stats={awardStats}
                        carrierFilter={carrierFilter}
                        setCarrierFilter={setCarrierFilter}
                        typesFilter={typesFilter}
                        setTypesFilter={setTypesFilter}
                        backupFilter={backupFilter}
                        setBackupFilter={setBackupFilter}
                        backupFilterActive={backupFilterActive}
                        setBackupFilterActive={setBackupFilterActive}
                        updateScenarios={updateScenarios}
                        scenariosSelected={scenariosSelected}
                        allocateDialog={allocateDialog}
                        isUpdatingScenarios={isUpdatingScenarios}
                        isLoadingAwardStats={isLoadingAwardStats}
                        hasData={(awardScenariosQuery.data?.count ?? 0) > 0}
                        scenariosCount={awardScenariosQuery.data?.count ?? 0}
                        setSplitCarriersFilter={setSplitCarriersFilter}
                        setSplitCarriersFilterActive={
                          setSplitCarriersFilterActive
                        }
                        splitCarriersFilter={splitCarriersFilter}
                        splitCarriersFilterActive={splitCarriersFilterActive}
                      />
                      <AwardScenariosTable
                        rfpState={rfp.state}
                        rfpCurrency={rfp.currency}
                        addLaneButton={addLanesButton}
                        importLaneButton={importLanesButton}
                        onRowClick={showLaneDetailsOrRoutingGuide}
                        carrierFilter={carrierFilter}
                        typesFilter={typesFilter}
                        backupFilter={backupFilter}
                        setScenariosSelected={setScenariosSelected}
                        query={awardScenariosQuery}
                        params={awardScenariosParams}
                        setParams={setAwardScenariosParams}
                        allocatedLanesCount={
                          allocatedLanesQuery.data?.count ?? 0
                        }
                        deallocatedLanesCount={
                          deallocatedLanesQuery.data?.count ?? 0
                        }
                        isUpdatingScenarios={isUpdatingScenarios}
                      />
                    </>
                  )}
                </Tabs.Panel>
                <Tabs.Panel name={TABS.LANES_ALLOCATED}>
                  <LanesAllocatedTable
                    isSpotMode={rfp.capacity_time_period === 'spot'}
                    rfpId={rfp.id}
                    removeScenarios={removeScenarios}
                    rfpState={rfp.state}
                    rfpCurrency={rfp.currency}
                    onRowClick={showLaneDetailsOrRoutingGuide}
                    query={allocatedLanesQuery}
                    params={allocatedLanesParams}
                    setParams={setAllocatedLanesParams}
                    changeTab={() => setTab(TABS.LANES_IN_PROGRESS)}
                    isRemovingScenarios={isRemovingScenarios}
                    selectable
                    allocatedLanesSearchQuery={allocatedLanesSearchQuery}
                    setAllocatedLanesSearchQuery={setAllocatedLanesSearchQuery}
                  />
                </Tabs.Panel>
                <Tabs.Panel name={TABS.PRIMARY_CARRIERS}>
                  <PrimaryCarriersTable
                    onLaneRowClick={showLaneDetailsOrRoutingGuide}
                    rfpId={rfp.id}
                    rfpState={rfp.state}
                    rfpCurrency={rfp.currency}
                    rfpDistanceType={rfp.distance_type}
                    changeTab={() => setTab(TABS.LANES_IN_PROGRESS)}
                  />
                </Tabs.Panel>
              </>

              <Tabs.Panel name={TABS.CARRIERS}>
                <Carriers
                  rfpId={rfp.id}
                  rfpUuid={rfp.uuid}
                  rfpState={rfp.state as RfpState}
                  rfpCurrency={rfp?.currency ?? CURRENCY.USD}
                  rfpRequestDeadline={rfp.request_deadline}
                  invites={invites}
                  carrierInviteStatus={carrierInviteStatus}
                  refetchCarrierInvites={refetchCarrierInvites}
                  isLoadingCarrierInvites={isLoadingCarrierInvites}
                />
              </Tabs.Panel>
            </Tabs.Panels>
          </>
        )}
      </Tabs>
      <RoutingGuideSideBar
        closeModal={routingGuideSidebar.closeModal}
        invites={invites}
        isLoadingCarrierInvites={isLoadingCarrierInvites}
        modalState={routingGuideSidebar.modalState}
        resetParamsAndRefetchQueries={resetParamsAndRefetchQueries}
        rfp={rfp}
        selectedLane={selectedLane}
      />
      <RecommendedCarriersSideBar
        carriersRecommendationsError={carriersRecommendationsError}
        closeModal={findCarriersSidebar.closeModal}
        isLoadingRecommendedCarriers={isLoadingRecommendedCarriers}
        modalState={findCarriersSidebar.modalState}
        recommendedCarriersData={recommendedCarriersData}
        refetchCarrierInvites={refetchCarrierInvites}
        rfp={rfp}
        selectedLane={selectedLane}
      />
    </>
  )
}

export default AwardScenarios
