import StringLimiter from '_shared_/components/StringLimiter'
import TableActions from '_shared_/components/TableActions'
import { Text } from 'components/Typography'
import {
  getPerDistanceRate,
  getPerDistanceRateLabel,
  isKilometers,
} from 'rfp/components/distanceType'
import MultiCurrencyValue from 'rfp/rfp-details/components/MultiCurrencyValue'
import type { Currency, RequestForProposal } from 'rfp/rfp.types'

import { RankCell, RankHeader } from '../lane-bids/styles'
import RankPosition from './RankPosition'

export default function makeColumns({
  rfpCurrency,
  rfpDistanceType,
  rankedAwardsLength,
  displayMultiCurrency,
  handleRemoveFromRank,
  handleMoveProposalInRank,
  handleMoveToEdgesInRank,
  disableRankingActions,
}: {
  rfpCurrency: Currency
  rfpDistanceType: RequestForProposal['distance_type']
  rankedAwardsLength: number
  displayMultiCurrency: boolean
  handleRemoveFromRank: (proposal: LaneProposal) => void
  handleMoveProposalInRank: (
    proposal: LaneProposal,
    direction: 'up' | 'down'
  ) => void
  handleMoveToEdgesInRank: (
    proposal: LaneProposal,
    edge: 'top' | 'bottom'
  ) => void
  disableRankingActions: boolean
}) {
  return [
    {
      Header: <RankHeader>Rank</RankHeader>,
      id: 'awarded_rank',
      accessor: 'awarded_rank',
      className: 'text-left',
      Cell: function Cell({
        row,
        value,
      }: {
        readonly row: any
        readonly value: any
      }) {
        return (
          <RankCell>
            <RankPosition
              rank={value}
              totalRankedAwards={rankedAwardsLength}
              isPlaceholder={!!row.original.placeholder}
              isDisabled={disableRankingActions}
              moveUpAction={() => handleMoveProposalInRank(row.original, 'up')}
              moveDownAction={() =>
                handleMoveProposalInRank(row.original, 'down')
              }
            />
          </RankCell>
        )
      },
    },
    {
      Header: 'Carrier',
      id: 'name',
      accessor: ({ carrier }: { carrier: any }) => carrier.name,
      className: 'text-left',
      Cell: function render({ row }: { row: any }) {
        return row.original.placeholder ? (
          <Text color="neutral">{row.original.carrier.name}</Text>
        ) : (
          <StringLimiter value={row.original.carrier.name} limiter={24} />
        )
      },
    },
    {
      Header: 'Est. Vol',
      id: 'awarded_volume',
      accessor: 'awarded_volume',
      className: 'text-left',
      Cell: function Cell({
        row,
        value,
      }: {
        readonly row: any
        readonly value: number
      }) {
        return row.placeholder ? <Text color="neutral">-</Text> : (value ?? '-')
      },
    },
    {
      Header: 'Flat Rate',
      id: 'flat_rate',
      accessor: 'flat_rate',
      className: 'text-left',
      Cell: function render({ row }: { row: any }) {
        const valueField = displayMultiCurrency
          ? 'converted_flat_rate'
          : 'flat_rate'

        return (
          <Text color={row.original.placeholder ? 'neutral' : 'neutralDark'}>
            <MultiCurrencyValue
              value={row.original[valueField]}
              rfpCurrency={rfpCurrency}
              nonConvertedValue={row.original['flat_rate']}
              boldStyle={false}
            />
          </Text>
        )
      },
    },
    {
      Header: getPerDistanceRateLabel(rfpDistanceType),
      id: 'per_mile_rate',
      accessor: 'per_mile_rate',
      className: 'text-left',
      Cell: function render({ row }: { row: any }) {
        const value = getPerDistanceRate(
          row.original,
          rfpDistanceType,
          displayMultiCurrency
        )

        const nonConvertedValue = isKilometers(rfpDistanceType)
          ? row.original['per_kilometer_rate']
          : row.original['per_mile_rate']

        return (
          <Text color={row.original.placeholder ? 'neutral' : 'neutralDark'}>
            <MultiCurrencyValue
              value={value}
              rfpCurrency={rfpCurrency}
              nonConvertedValue={nonConvertedValue}
              boldStyle={false}
            />
          </Text>
        )
      },
    },
    {
      Header: '',
      id: 'actions',
      className: 'text-left actions-cell',
      Cell: function render({ row }: { row: any }) {
        return (
          !row.original.placeholder && (
            <TableActions disabled={disableRankingActions}>
              <TableActions.Item
                disabled={row.original.awarded_rank === 1}
                onClick={() => handleMoveToEdgesInRank(row.original, 'top')}
              >
                <Text color="default">Move to Top</Text>
              </TableActions.Item>
              <TableActions.Item
                disabled={row.original.awarded_rank === rankedAwardsLength}
                onClick={() => handleMoveToEdgesInRank(row.original, 'bottom')}
              >
                <Text color="default">Move to Bottom</Text>
              </TableActions.Item>
              <TableActions.Item
                onClick={() => handleRemoveFromRank(row.original)}
              >
                <Text color="danger">Remove From Rank</Text>
              </TableActions.Item>
            </TableActions>
          )
        )
      },
    },
  ]
}
