import { useQuery } from 'react-query'

import type { UserBusiness, UserData } from '_shared_/user'
import { retrieve as fetchShipperMember, list } from 'services/shipperMembers'
import type { ShipperUsersResponse } from 'services/shipperMembers'

type ShipperMembersParams = {
  business?: UserBusiness
  show_staff?: boolean
}

export const useShipperMember = (uuid: UserData['user_uuid']) => {
  return useQuery({
    queryKey: ['shipperMember', uuid],
    queryFn: ({ signal }) => fetchShipperMember(uuid, { signal }),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  })
}

export const useShipperMembers = <TData = ShipperUsersResponse>(
  params?: ShipperMembersParams,
  options?: {
    select?: (data: ShipperUsersResponse) => TData
  }
) => {
  return useQuery({
    ...options,
    queryKey: ['shipperMembers', params],
    queryFn: ({ signal }) => list({ signal, params }),
    cacheTime: Infinity,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  })
}
