import { userUtils } from '_shared_/user'

import { AnalyticsMenu } from './AnalyticsMenu'
import { AppsMenu } from './AppsMenu'
import { ManageMenu } from './ManageMenu'
import { PlanMenu } from './PlanMenu'
import { ProcurementMenu } from './ProcurementMenu'
import { QuotesMenu } from './QuotesMenu'
import { SettlementMenu } from './SettlementMenu'

export const SideNavMenu = ({
  enableOrdersPage,
}: {
  readonly enableOrdersPage: boolean
}) => {
  if (userUtils.isSupplierUser) {
    return <PlanMenu />
  }

  return (
    <>
      <QuotesMenu />
      {enableOrdersPage && <PlanMenu />}
      <ProcurementMenu />
      <SettlementMenu />
      <ManageMenu />
      <AnalyticsMenu />
      <AppsMenu />
    </>
  )
}
