import { IconQuestionCircle, IconTrash } from '@loadsmart/icons'
import {
  Card,
  Field,
  Layout,
  Text,
  TextField,
  Button,
  Divider,
} from '@loadsmart/miranda-react'
import { getToken, toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import { Fragment, useMemo, useState } from 'react'
import { Controller } from 'react-hook-form'

import { useDynamicFields } from '../dynamic-fields/hooks'
import {
  NewCustomFieldModal,
  DeleteCustomFieldModal,
} from './LaneCustomFieldModals'
import { FormTooltip } from './styles'
import type { ExtraFieldProps, LaneCustomFieldsProps } from './types'

function ExtraField({
  preference,
  rfpExtraFields,
  modeOptions,
  rfpID,
  currentPreferences,
  preferencePosition,
  setValue,
  reloadLists,
}: ExtraFieldProps) {
  const [isFieldCreationModalOpen, setIsFieldCreationModalOpen] =
    useState(false)
  const [isFieldRemoveModalOpen, setIsFieldRemoveModalOpen] = useState(false)
  const [newCustomField, setNewCustomField] = useState({ mode: '', field: '' })
  const [fieldToBeRemoved, setFieldToBeRemoved] = useState('')
  const [currentExtraFields, setCurrentExtraFields] = useState(rfpExtraFields)

  const mode = preference.mode
  const modeLabel = modeOptions
    ? modeOptions.find((option: any) => option.value === mode)!.label
    : mode

  const savePreference = () => {
    currentPreferences[preferencePosition] = preference
    setValue(currentPreferences)
  }

  const extraFields = useMemo(() => {
    if (!preference.extra_fields) {
      return []
    }
    return [
      ...Object.entries(preference.extra_fields || {}).map(
        ([fieldName, fieldValue]) => ({ [fieldName]: fieldValue })
      ),
    ]
  }, [preference])

  const handleOpenFieldCreationModal = () => {
    setNewCustomField({ mode: '', field: '' })
    setIsFieldCreationModalOpen(true)
  }

  const handleOpenFieldRemovalModal = (fieldName: string) => {
    setFieldToBeRemoved(fieldName)
    setIsFieldRemoveModalOpen(true)
  }

  const handleCloseFieldCreationModal = () => {
    setIsFieldCreationModalOpen(false)
    setNewCustomField({ mode: '', field: '' })
  }

  const handleCloseFieldRemovalModal = () => {
    setIsFieldRemoveModalOpen(false)
    setFieldToBeRemoved('')
    reloadLists()
  }

  const { isSubmitting: isCreationSubmitting, action: createAction } =
    useDynamicFields({
      rfpId: rfpID,
      extraFieldHeaders: [],
      action: 'add',
      field: newCustomField.field.toLowerCase(),
      callback: handleCloseFieldCreationModal,
      laneExtraFieldHeaders: currentExtraFields,
    })

  const { isSubmitting: isDeletionSubmitting, action: deleteAction } =
    useDynamicFields({
      extraFieldHeaders: [],
      field: fieldToBeRemoved,
      rfpId: rfpID,
      action: 'delete',
      laneExtraFieldHeaders: currentExtraFields,
      callback: handleCloseFieldRemovalModal,
    })

  const removeExtraField = () => {
    const modeFields = rfpExtraFields[mode].headers
    modeFields.splice(modeFields.indexOf(fieldToBeRemoved), 1)
    rfpExtraFields[mode].headers = modeFields
    delete preference.extra_fields[fieldToBeRemoved]
    setCurrentExtraFields(rfpExtraFields)
    savePreference()
    deleteAction()
  }

  const addExtraField = () => {
    const newFieldName = newCustomField.field.toLowerCase()
    const currentFields = rfpExtraFields[mode].headers
    rfpExtraFields[mode].headers = [...currentFields, newFieldName]
    preference.extra_fields[newFieldName] = ''
    setCurrentExtraFields(rfpExtraFields)
    savePreference()
    createAction()
  }

  const setFieldValue = (event: any, fieldName: string) => {
    preference.extra_fields[fieldName] = event.target.value
    savePreference()
  }

  return (
    <Layout.Box padding="none">
      <Layout.Stack padding="spacing-6">
        <Text variant="heading-sm-bold">{modeLabel}</Text>
        {extraFields.map((field) => {
          const fieldName = Object.keys(field)[0]
          const fieldValue = Object.values(field)[0] || ''
          return (
            <div key={`extra-field-${fieldName}`}>
              <Text
                variant="body-md"
                //@ts-expect-error - We don't have access to the text color property directly
                style={{ '--m-text-color': toCSSValue('color-text-tertiary') }}
              >
                {fieldName}
              </Text>
              <Layout.Grid minColumnWidth="20px">
                <TextField
                  style={{ gridColumn: 'span 12' }}
                  onInput={(event) => setFieldValue(event, fieldName)}
                  id={`extra-field-${fieldName}-input`}
                  data-testid="extra-field-value-input"
                  placeholder="Set value"
                  value={fieldValue}
                />
                <Button
                  data-testid="delete-custom-field"
                  style={{
                    alignSelf: 'end',
                    flexWrap: 'nowrap',
                    maxWidth: 'fit-content',
                  }}
                  variant="icon-secondary"
                  onClick={() =>
                    handleOpenFieldRemovalModal(Object.keys(field)[0])
                  }
                >
                  <IconTrash
                    width={14}
                    height={14}
                    aria-label="lane-preference"
                    title={null}
                  />
                </Button>
              </Layout.Grid>
            </div>
          )
        })}
        <Button
          style={{ width: 'fit-content' }}
          variant="tertiary"
          onClick={() => {
            handleOpenFieldCreationModal()
          }}
          disabled={false}
        >
          Add field
        </Button>
      </Layout.Stack>
      <NewCustomFieldModal
        isModalOpen={isFieldCreationModalOpen}
        modeLabel={modeLabel}
        isSubmitting={isCreationSubmitting}
        handleCloseModal={handleCloseFieldCreationModal}
        newCustomField={newCustomField.field}
        addNewExtraField={addExtraField}
        setNewCustomField={setNewCustomField}
      />
      <DeleteCustomFieldModal
        isModalOpen={isFieldRemoveModalOpen}
        fieldToRemove={fieldToBeRemoved}
        currentExtraFields={currentExtraFields}
        isSubmitting={isDeletionSubmitting}
        mode={mode}
        handleCloseModal={handleCloseFieldRemovalModal}
        removeExtraField={removeExtraField}
      />
    </Layout.Box>
  )
}

export default function LaneCustomFields({
  control,
  isFieldDisabled,
  rfpExtraFieldsHeaders,
  modeOptions,
  rfpID,
  reloadLists,
}: LaneCustomFieldsProps) {
  const cardStyle = useMemo(() => {
    if (isFieldDisabled) {
      return {
        color: `rgb(${getToken('color-text-disabled')})`,
        cursor: 'auto',
      }
    }

    return {}
  }, [isFieldDisabled])

  const enrichPreferenceExtraFields = (preference: any) => {
    const mode = preference.mode

    const modeFields = rfpExtraFieldsHeaders
      ? rfpExtraFieldsHeaders[mode]
      : null

    const rfpFields = modeFields
      ? modeFields.headers?.map((field: string) => {
          return { [field]: '' }
        })
      : []

    const preferenceExtraFields = Object.entries(
      preference.extra_fields || {}
    ).map(([fieldName, fieldValue]) => ({ [fieldName]: fieldValue }))

    const preferenceExtraFieldsKeys = preferenceExtraFields.map(
      (field) => Object.keys(field)[0]
    )

    const missingFields = rfpFields.filter(
      (field: any) => !preferenceExtraFieldsKeys.includes(Object.keys(field)[0])
    )

    if (!preference.extra_fields) {
      preference.extra_fields = {}
    }

    missingFields.forEach((field) => {
      preference.extra_fields[Object.keys(field)[0]] = ''
    })
  }

  return (
    <Layout.Box paddingB="spacing-6" padding="none">
      <Card style={cardStyle} collapsible={!isFieldDisabled}>
        <Card.Title>
          <span style={cardStyle}>Custom fields</span>
          <FormTooltip
            style={cardStyle}
            className="field-label-tooltip"
            trigger="hover"
            message={
              <>
                Add and customize fields by mode for this RFP. Fields
                <div />
                added here are specific to this event. Manage default
                <div />
                custom fields in settings for all future RFPs.
              </>
            }
            placement="top-start"
          >
            <IconQuestionCircle
              width={14}
              height={14}
              aria-label="volume"
              title={null}
            />
          </FormTooltip>
        </Card.Title>
        <Card.Divider />
        <Card.Body
          //@ts-expect-error - We don't have access to the padding property directly
          style={{ '--m-card-padding': 'none' }}
        >
          <Field style={{ flex: '1' }}>
            <Controller
              name="preferences"
              control={control}
              render={({ field }) => {
                const preferences = field.value
                return preferences.map((preference: any, index: number) => {
                  if (preference.mode !== '') {
                    enrichPreferenceExtraFields(preference)

                    return (
                      <Fragment
                        key={`extra-field-${preference.mode}-${preference.equipment_type}`}
                      >
                        <ExtraField
                          preference={preference}
                          rfpExtraFields={rfpExtraFieldsHeaders}
                          setValue={field.onChange}
                          modeOptions={modeOptions}
                          rfpID={rfpID}
                          currentPreferences={preferences}
                          preferencePosition={index}
                          reloadLists={reloadLists}
                        />
                        {index != field.value.length - 1 && <Divider />}
                      </Fragment>
                    )
                  }

                  return null
                })
              }}
            />
          </Field>
        </Card.Body>
      </Card>
    </Layout.Box>
  )
}
