import {
  IconCheck,
  IconClock,
  IconQuestionCircle,
  IconAttention,
  IconEmailFilled,
  IconTrophyFilled,
  IconClose,
} from '@loadsmart/icons'
import { Tooltip, TooltipPosition } from '@loadsmart/loadsmart-ui'
import * as Sentry from '@sentry/react'
import type { ReactNode } from 'react'
import styled from 'styled-components'

import { getAwardedVolume } from 'rfp/rfp-details/utils'
import { theme } from 'styles/theme'
import {
  FINALIZED_RFP_STATES,
  PUBLISHED_RFP_STATES,
  RFP_STATE,
} from 'utils/constants'
import { checkErrors } from 'utils/lanes'

import DotStatus from './DotStatus'

interface LaneStatusIndicatorProps {
  readonly lane: Lane
  readonly rfpState: RfpState
  readonly isSpotMode: boolean
}

const StateWrapper = styled.div`
  position: relative;
`

const DOT_STATUS_COLOR = {
  ACCEPTED: theme.colors.success,
  REJECTED: theme.colors.dangerDark,
  PARTIALLY_ACCEPTED: theme.colors.infoHighlight,
  WAITING: theme.colors.mediumGray,
}

const filterByPrimaryAward = (lane: Lane) => {
  if (lane.awarded_proposals) {
    return lane.awarded_proposals.filter(
      (award) => award.awarded_type === 'primary'
    )
  }

  return []
}

const hasAtLeastOnePrimaryAwardedProposal = (lane: Lane) =>
  filterByPrimaryAward(lane).length > 0

interface StatusInfoObject {
  icon: any

  color: string
  tooltip: string | ReactNode
  dotColor?: string
}

function getLaneAwardStatus(lane: Lane): StatusInfoObject {
  const finalizedStatusIcon = {
    icon: IconTrophyFilled,
    color: theme.colors.warning,
  }

  const { awarded_proposals, remaining_award_volume } = lane

  const volumeShouldBeAllocated = remaining_award_volume > 0

  const allVolumeAwarded =
    remaining_award_volume === 0 &&
    !awarded_proposals?.some((e) => e.award_confirmation === null) &&
    awarded_proposals?.some((e) => e.award_confirmation === 'accepted')

  const someVolumePending = awarded_proposals?.some(
    (e) => e.award_confirmation === null
  )

  if (volumeShouldBeAllocated) {
    return {
      ...finalizedStatusIcon,
      tooltip: (
        <>
          There&apos;s some volume
          <br /> that wasn&apos;t accepted
        </>
      ),
      dotColor: DOT_STATUS_COLOR.REJECTED,
    }
  }

  if (allVolumeAwarded) {
    return {
      ...finalizedStatusIcon,
      tooltip: '100% of volume accepted',
      dotColor: DOT_STATUS_COLOR.ACCEPTED,
    }
  }

  if (someVolumePending) {
    return {
      ...finalizedStatusIcon,
      tooltip: (
        <>
          There&apos;s volume pending
          <br /> to be accepted
        </>
      ),
      dotColor: DOT_STATUS_COLOR.WAITING,
    }
  }

  return {
    icon: IconClose,
    color: theme.colors.backgroundLightGray,
    tooltip: 'No awards',
  }
}

const getDraftStatus = (lane: Lane) => {
  const error = checkErrors(lane)
  return error
    ? {
        icon: IconAttention,
        color: theme.colors.textError,
        tooltip: error,
      }
    : {
        icon: IconCheck,
        color: theme.colors.main,
        tooltip: 'Ready to publish',
      }
}

const getPublishedStatus = (lane: Lane) => {
  if (lane.proposals_count === 0) {
    return {
      icon: IconClock,
      color: theme.colors.backgroundLightGray,
      tooltip: 'Waiting for bids',
    }
  }

  if (hasAtLeastOnePrimaryAwardedProposal(lane)) {
    if (lane.volume > getAwardedVolume(lane?.awarded_proposals ?? [])) {
      return {
        icon: IconCheck,
        color: theme.colors.backgroundLightGray,
        tooltip: 'Needs more capacity',
      }
    }

    return {
      icon: IconCheck,
      color: theme.colors.main,
      tooltip: 'Ready to award',
    }
  }

  if (lane.has_new_bids) {
    return {
      icon: IconEmailFilled,
      color: theme.colors.infoHighlight,
      tooltip: 'New bids',
    }
  }

  return {
    icon: IconEmailFilled,
    color: theme.colors.backgroundLightGray,
    tooltip: 'Evaluate bids',
  }
}

function getLaneStatus(lane: Lane, rfpState: RfpState): StatusInfoObject {
  if (RFP_STATE.DRAFT === rfpState) {
    return getDraftStatus(lane)
  }

  if (PUBLISHED_RFP_STATES.includes(rfpState)) {
    return getPublishedStatus(lane)
  }

  if (
    FINALIZED_RFP_STATES.includes(rfpState) ||
    rfpState === RFP_STATE.AWARDING
  ) {
    return getLaneAwardStatus(lane)
  }

  Sentry.withScope((scope) => {
    scope.setExtra('lane', lane)
    scope.setExtra('state', rfpState)
    scope.setExtra(
      'awardedVolume',
      getAwardedVolume(lane.awarded_proposals ?? [])
    )
    Sentry.captureMessage('Unknown status for LaneStatusIndicator')
  })

  return {
    icon: IconQuestionCircle,
    color: theme.colors.backgroundLightGray,
    tooltip: "Couldn't identify lane status",
  }
}

const LaneStatusIndicator = ({
  lane,
  rfpState,
  isSpotMode,
}: LaneStatusIndicatorProps) => {
  const statusInfo = getLaneStatus(lane, rfpState)

  const rfpIsAwardingOrFinalized =
    FINALIZED_RFP_STATES.includes(rfpState) || rfpState === RFP_STATE.AWARDING

  return (
    <Tooltip position={TooltipPosition.Right} message={statusInfo.tooltip}>
      <StateWrapper>
        {!isSpotMode && rfpIsAwardingOrFinalized && statusInfo.dotColor && (
          <DotStatus statusColor={statusInfo.dotColor} />
        )}

        <statusInfo.icon
          fill={statusInfo.color}
          color={statusInfo.color}
          height={16}
          width={16}
          title={null}
          aria-label={statusInfo.tooltip}
          data-testid="laneStatus"
        />
      </StateWrapper>
    </Tooltip>
  )
}

export default LaneStatusIndicator
