import styled from 'styled-components'

import { theme } from 'styles/theme'
import { hexToRGBA } from 'utils/colors'
import { hideStyledProps } from 'utils/styles'

type CardPadding = 'default' | 'large' | 'medium' | 'no-padding'
type CardBorder = 'v1' | 'v2'
type CardType = 'header'

function getPadding({ padding }: { padding?: CardPadding }) {
  let value = ''

  switch (padding) {
    case 'large':
      value = '30px'
      break
    case 'medium':
      value = '20px 30px'
      break
    case 'no-padding':
      value = '0'
      break
    default:
      value = '10px'
  }

  return `padding: ${value};`
}

function getVersionStyle({ border = 'v1' }: { border?: CardBorder }) {
  switch (border) {
    case 'v2':
      return `border: 2px solid ${hexToRGBA(
        theme.colors.backgroundMediumGray,
        0.3
      )};`
    default:
      return `
      border: 1px solid ${theme.colors.borderLight};
      box-shadow: ${theme.shadows.standard};
    `
  }
}

function getType({ type }: { type?: CardType }) {
  switch (type) {
    case 'header':
      return `height: 96px;`
    default:
      return ''
  }
}

/**
 * @deprecated use @loadsmart/miranda-react instead
 */
const Card = styled.div.withConfig(
  hideStyledProps(['padding', 'border', 'type'])
)<{
  padding?: CardPadding
  border?: CardBorder
  type?: CardType
}>`
  background: ${theme.colors.white};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 15px;
  ${getVersionStyle}
  ${getPadding}
  ${getType}
`

export default Card
