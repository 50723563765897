import { Tabs } from '@loadsmart/loadsmart-ui'
import * as Sentry from '@sentry/react'
import { useEffect, useState } from 'react'
import type { Dispatch, SetStateAction } from 'react'
import type { UseQueryResult } from 'react-query'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { useDebounce } from 'use-debounce'

import { useCurrentUser } from '_shared_/user/useCurrentUser'
import { useModal } from 'hooks/useModal'
import {
  useRankedLanes,
  useRankScenarios,
  useRankScenariosStats,
} from 'hooks/useQuery'
import RFPLimitBanner from 'rfp/components/rfp-limit-banner'
import type {
  LanesAwardedQueryParams,
  QueryParams,
} from 'rfp/components/table/laneParams'
import type { RequestForProposal } from 'rfp/rfp.types'
import { removeFromRankScenarios, updateRankScenarios } from 'services/lane'
import { USER_CATEGORIES } from 'utils/constants'
import { numberFormatter } from 'utils/numbers'

import AllLanesAllocatedEmptyState from '../components/AllLanesAllocatedEmptyState'
import RankAutomationSummary from '../components/RankAutomationSummary'
import InvitedCarriers from '../tabs/InvitedCarriers'
import LanesAllocated from '../tabs/LanesAllocated'
import LanesInProgress from '../tabs/LanesInProgress'
import RankAutomation from '../tabs/RankAutomation'

const TABS = {
  LANES_IN_PROGRESS: 'lanes-in-progress',
  RANK_AUTOMATION: 'add-carriers',
  LANES_ALLOCATED: 'lanes-allocated',
  INVITED_CARRIERS: 'invited-carriers',
}

const isUseRankRefetchEnabled = (
  typesFilter: string | null,
  topRankFilter: number | null
) => typesFilter !== null || topRankFilter !== null

const shouldRenderAllLanesAllocatedEmptyState = (
  rankedLanesQuery: UseQueryResult<PaginatedResult<any>, unknown>,
  unrankedLanesQuery: UseQueryResult<PaginatedResult<any>, unknown>
) =>
  (rankedLanesQuery.data?.count ?? 0) > 0 &&
  unrankedLanesQuery.data?.count === 0

const LimitBanner = ({
  isPaidUser,
  remainingRfpQuota,
  carrierInvitesMaxQuota,
}: {
  readonly isPaidUser: boolean
  readonly remainingRfpQuota: number
  readonly carrierInvitesMaxQuota: number
}) => {
  return !isPaidUser ? (
    <RFPLimitBanner
      remainingRfpQuota={remainingRfpQuota}
      carrierInvitesMaxQuota={carrierInvitesMaxQuota}
    />
  ) : null
}

interface RankPublishedProps {
  readonly rfp: RequestForProposal
  readonly showLaneDetailsOrRoutingGuide: (lane: Lane) => void
  readonly showRecommendedCarriersSidebar: (lane: Lane) => void
  readonly params: QueryParams
  readonly setParams: Dispatch<SetStateAction<QueryParams>>
  readonly unrankedLanesQuery: UseQueryResult<PaginatedResult, unknown>
  readonly carrierInviteStatus: string
  readonly invites: NewCarrierInvite[]
  readonly isLoadingCarrierInvites: boolean
  readonly refetchCarrierInvites: () => Promise<unknown>
  readonly remainingRfpQuota: number
  readonly carrierInvitesMaxQuota: number
  readonly unrankedLanesSearchQuery: string
  readonly setUnrankedLanesSearchQuery: Dispatch<SetStateAction<string>>
  readonly rankedLanesParams: LanesAwardedQueryParams
  readonly rankedLanesSearchQuery: string
  readonly setRankedLanesSearchQuery: Dispatch<SetStateAction<string>>
  readonly rankAutomationParams: QueryParams
  readonly setRankAutomationParams: Dispatch<SetStateAction<QueryParams>>
  readonly setRankedLanesParams: Dispatch<
    SetStateAction<LanesAwardedQueryParams>
  >
  readonly resetParamsAndRefetchQueries: () => void
}

const RankPublished = ({
  rfp,
  showLaneDetailsOrRoutingGuide,
  showRecommendedCarriersSidebar,
  params,
  setParams,
  unrankedLanesQuery,
  carrierInviteStatus,
  invites,
  isLoadingCarrierInvites,
  refetchCarrierInvites,
  remainingRfpQuota,
  carrierInvitesMaxQuota,
  setUnrankedLanesSearchQuery,
  unrankedLanesSearchQuery,
  rankAutomationParams,
  rankedLanesParams,
  rankedLanesSearchQuery,
  setRankAutomationParams,
  setRankedLanesSearchQuery,
  setRankedLanesParams,
  resetParamsAndRefetchQueries,
}: RankPublishedProps) => {
  const { user } = useCurrentUser()
  const isPaidUser = user?.user_category === USER_CATEGORIES.PAID_SHIPPER

  const [tab, setTab] = useState(TABS.LANES_IN_PROGRESS)
  const [typesFilter, setTypesFilter] = useState<string | null>(null)
  const [topRankFilter, setTopRankFilter] = useState<number | null>(null)
  const allocateDialog = useModal()
  const [scenariosSelected, setScenariosSelected] = useState<
    undefined | any[]
  >()

  const [debouncedRankedLanesSearchQuery] = useDebounce(
    rankedLanesSearchQuery,
    450
  )

  const {
    data: rankStats,
    isLoading: isLoadingRankStats,
    refetch: refetchStats,
  } = useRankScenariosStats(rfp.id, typesFilter ?? '', topRankFilter ?? null)

  const rankedLanesQuery = useRankedLanes(
    rfp.id,
    rankedLanesParams.limit,
    rankedLanesParams.offset,
    rankedLanesParams.sort,
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
    debouncedRankedLanesSearchQuery
  )

  useEffect(() => {
    if (rankedLanesQuery.isSuccess) {
      refetchStats()
    }
  }, [rankedLanesQuery.isSuccess, refetchStats])

  useEffect(() => {
    if (rankedLanesQuery.isError) {
      Sentry.captureException(rankedLanesQuery.error)
      toast.error('Could not retrieve the lanes, please contact an admin')
    }
  }, [rankedLanesQuery.isError, rankedLanesQuery.error])
  const queryClient = useQueryClient()

  const { mutate: updateScenarios, isLoading: isUpdatingScenarios } =
    useMutation({
      mutationFn: updateRankScenarios,
      onSuccess: () => {
        allocateDialog.closeModal()
        resetParamsAndRefetchQueries()
        queryClient.refetchQueries({ queryKey: ['retrieveLaneProposal'] })
        queryClient.refetchQueries({
          queryKey: ['rfpTotalizers', rfp.id.toString()],
        })
        queryClient.refetchQueries({ queryKey: ['deallocatedLanes'] })
        queryClient.refetchQueries({ queryKey: ['allocatedLanes'] })
        queryClient.refetchQueries({ queryKey: ['rankScenarios'] })
      },
    })

  const { mutate: removeScenarios, isLoading: isRemovingScenarios } =
    useMutation({
      mutationFn: removeFromRankScenarios,
      onSuccess: () => {
        resetParamsAndRefetchQueries()
        queryClient.refetchQueries({ queryKey: ['retrieveLaneProposal'] })
        queryClient.refetchQueries({
          queryKey: ['rfpTotalizers', rfp.id.toString()],
        })
        queryClient.refetchQueries({ queryKey: ['deallocatedLanes'] })
        queryClient.refetchQueries({ queryKey: ['allocatedLanes'] })
        queryClient.refetchQueries({ queryKey: ['rankScenarios'] })
      },
    })

  const rankScenariosQuery = useRankScenarios(
    rfp.id,
    rankAutomationParams.limit,
    rankAutomationParams.offset,
    rankAutomationParams.sort,
    typesFilter ?? 'any',
    topRankFilter ?? null,
    {
      cacheTime: 0,
      enabled: isUseRankRefetchEnabled(typesFilter, topRankFilter),
      refetchOnWindowFocus: false,
      onSuccess() {
        refetchStats()
      },
      onError(error: unknown) {
        Sentry.captureException(error)
        toast.error('Could not retrieve the lanes, please contact an admin')
      },
    }
  )

  const hasRankStats = !isLoadingRankStats && rankStats

  return (
    <Tabs
      className="miranda-tabs"
      activeTab={tab}
      onTabChange={(newTab: string) => setTab(newTab)}
    >
      <Tabs.Items>
        <Tabs.Item name={TABS.LANES_IN_PROGRESS}>
          Lanes In Progress
          {hasRankStats && (
            <>
              &nbsp; &bull; &nbsp;
              {numberFormatter(
                100 - (rankStats?.overall_allocation_percentage ?? 0),
                1
              )}
              %
            </>
          )}
        </Tabs.Item>
        <Tabs.Item name={TABS.RANK_AUTOMATION}>Rank Automation</Tabs.Item>
        <Tabs.Item name={TABS.LANES_ALLOCATED}>
          Lanes Allocated
          {hasRankStats && (
            <>
              &nbsp; &bull; &nbsp;
              {numberFormatter(rankStats?.overall_allocation_percentage, 1) ??
                0}
              %
            </>
          )}
        </Tabs.Item>
        <Tabs.Item name={TABS.INVITED_CARRIERS}>Invited Carriers</Tabs.Item>
      </Tabs.Items>
      <LimitBanner
        isPaidUser={isPaidUser}
        remainingRfpQuota={remainingRfpQuota}
        carrierInvitesMaxQuota={carrierInvitesMaxQuota}
      />
      <Tabs.Panels>
        <Tabs.Panel name={TABS.LANES_IN_PROGRESS}>
          <LanesInProgress
            goToLanesAllocated={() => setTab(TABS.LANES_ALLOCATED)}
            unrankedLanesParams={params}
            setUnrankedLanesParams={setParams}
            unrankedLanesQuery={unrankedLanesQuery}
            rankedLanesQuery={rankedLanesQuery}
            rfp={rfp}
            unrankedLanesSearchQuery={unrankedLanesSearchQuery}
            setUnrankedLanesSearchQuery={setUnrankedLanesSearchQuery}
            showLaneDetailsOrRoutingGuide={showLaneDetailsOrRoutingGuide}
            showRecommendedCarriersSidebar={showRecommendedCarriersSidebar}
          />
        </Tabs.Panel>
        <Tabs.Panel name={TABS.RANK_AUTOMATION}>
          {shouldRenderAllLanesAllocatedEmptyState(
            rankedLanesQuery,
            unrankedLanesQuery
          ) ? (
            <AllLanesAllocatedEmptyState
              changeTab={() => setTab(TABS.LANES_ALLOCATED)}
            />
          ) : (
            <>
              <RankAutomationSummary
                rfpId={rfp.id}
                rfpCurrency={rfp.currency}
                stats={rankStats}
                topRankFilter={topRankFilter}
                setTopRankFilter={setTopRankFilter}
                typesFilter={typesFilter}
                setTypesFilter={setTypesFilter}
                updateScenarios={updateScenarios}
                scenariosSelected={scenariosSelected}
                allocateDialog={allocateDialog}
                isUpdatingScenarios={isUpdatingScenarios}
                canAllocateAll={(rankScenariosQuery.data?.count ?? 0) > 0}
                scenariosCount={rankScenariosQuery.data?.count ?? 0}
              />
              <RankAutomation
                topRankFilter={topRankFilter}
                isUpdatingScenarios={isUpdatingScenarios}
                rankAutomationParams={rankAutomationParams}
                rankAutomationQuery={rankScenariosQuery}
                rfp={rfp}
                setRankAutomationParams={setRankAutomationParams}
                setScenariosSelected={setScenariosSelected}
                showLaneDetailsOrRoutingGuide={showLaneDetailsOrRoutingGuide}
                typesFilter={typesFilter}
              />
            </>
          )}
        </Tabs.Panel>
        <Tabs.Panel name={TABS.LANES_ALLOCATED}>
          <LanesAllocated
            goToLanesInProgress={() => setTab(TABS.LANES_IN_PROGRESS)}
            rankedLanesParams={rankedLanesParams}
            rankedLanesQuery={rankedLanesQuery}
            rfp={rfp}
            setRankedLanesParams={setRankedLanesParams}
            showLaneDetailsOrRoutingGuide={showLaneDetailsOrRoutingGuide}
            rankedLanesSearchQuery={rankedLanesSearchQuery}
            setRankedLanesSearchQuery={setRankedLanesSearchQuery}
            removeScenarios={removeScenarios}
            isRemovingScenarios={isRemovingScenarios}
          />
        </Tabs.Panel>
        <Tabs.Panel name={TABS.INVITED_CARRIERS}>
          <InvitedCarriers
            carrierInviteStatus={carrierInviteStatus}
            invites={invites}
            isLoadingCarrierInvites={isLoadingCarrierInvites}
            refetchCarrierInvites={refetchCarrierInvites}
            rfp={rfp}
          />
        </Tabs.Panel>
      </Tabs.Panels>
    </Tabs>
  )
}

export default RankPublished
