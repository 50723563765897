import { IconInfoCircle, IconQuestionCircle } from '@loadsmart/icons'
import {
  Breadcrumbs,
  Layout,
  Tag,
  Text,
  Tooltip,
  TooltipPosition,
} from '@loadsmart/loadsmart-ui'
import { toCapitalizeFirstLetter } from '@loadsmart/utils-string'
import { useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import type {
  RequestForProposal,
  RequestForProposalDetail,
} from 'rfp/rfp.types'
import { theme } from 'styles/theme'
import {
  BID_TYPE,
  CAPACITY_TIME_PERIOD_OPTIONS,
  CURRENCY,
  RATE_TYPE_OPTIONS,
  RFP_STATE,
} from 'utils/constants'
import { getCurrencyPrefix } from 'utils/currency'
import { formatRFPDate, formatRFPDateTime } from 'utils/dateUtils'
import formatCurrency from 'utils/formatCurrency'
import { removeTrailingDecimalZeroes } from 'utils/numbers'
import reduceByKey from 'utils/reduceByKey'

import AwardTotalizers from './AwardTotalizers'
import HeaderActions from './HeaderActions'
import RankedTotalizers from './RankedTotalizers'
import {
  StyledCard,
  StyledCardBody,
  StyledCardSeparator,
  StyledCardTitle,
  StyledStatusDot,
  StyledTotalizerItem,
} from './styles'
import TotalizerSkeleton from './TotalizerSkeleton'

interface HeaderCardV2Props {
  readonly rfp: RequestForProposalDetail
  readonly openDownloadDialog: () => void
  readonly duplicateRFP: () => void
  readonly archiveRFP: () => void
  readonly rfpIsValid: boolean
  readonly openPublishDialog: () => void
  readonly openAwardDialog: () => void
  readonly openFinalizeDialog: () => void
  readonly totalizers?: RFPTotalizers
  readonly isLoadingTotalizers: boolean
  readonly lanes_count: number | undefined
  readonly rfpLaneDetails: { mode: string; multimode: boolean }
}

const volumeMap = reduceByKey(CAPACITY_TIME_PERIOD_OPTIONS, 'value')
const pricingMap = reduceByKey(RATE_TYPE_OPTIONS, 'value')

const getValueLabel = (
  map: Record<string, { label: string; value: string }>,
  value: string
) => {
  if (map[value]) {
    return map[value].label
  }
  if (value) {
    return toCapitalizeFirstLetter(value)
  }
  return 'Unknown'
}

const getConversionRateTooltipMessage = (rfp: RequestForProposal): string => {
  const currentCurrency = `1 ${rfp.currency} : `
  const conversionRate = removeTrailingDecimalZeroes(rfp.conversion_rate)
  return `${currentCurrency}${conversionRate} ${CURRENCY.USD}`
}

const getTagVariant = (rfpState: string) => {
  switch (rfpState) {
    case RFP_STATE.DRAFT:
      return 'warning'
    case RFP_STATE.PUBLISHED:
    case RFP_STATE.CLOSED:
      return 'accent'
    case RFP_STATE.AWARDING:
      return 'success'
    default:
      return 'default'
  }
}

const StatusDot = ({
  isEnded,
  rfpState,
}: {
  readonly isEnded: boolean
  readonly rfpState: RfpState
}) => {
  const { color, shadow } =
    isEnded ||
    rfpState === RFP_STATE.FINALIZED ||
    rfpState === RFP_STATE.ARCHIVED
      ? theme.statuses.finalized
      : theme.statuses.published

  return (
    <StyledStatusDot $color={color} $shadow={shadow}>
      <div />
    </StyledStatusDot>
  )
}

export const TotalizerItem = ({
  title,
  value,
  footer,
}: {
  readonly title: JSX.Element | string
  readonly value: JSX.Element | string
  readonly footer?: JSX.Element | string
}) => {
  return (
    <StyledTotalizerItem>
      <Layout.Stack space="xs">
        {title && (
          <Text variant="chips-sm" color="color-neutral">
            {title}
          </Text>
        )}
        {value && (
          <Text variant="body-bold" color="color-neutral-darker">
            {value}
          </Text>
        )}
        {footer && (
          <Text variant="chips-sm" color="color-neutral">
            {footer}
          </Text>
        )}
      </Layout.Stack>
    </StyledTotalizerItem>
  )
}

const TooltipMessage = ({
  email,
  phone,
}: {
  readonly email?: string
  readonly phone?: string
}) => {
  return (
    <>
      {email && `Email: ${email}`}
      {phone && (
        <>
          <br /> {`Phone: ${phone}`}
        </>
      )}
    </>
  )
}

const getTotalSpentInUSD = (totalSpend: number | undefined) => {
  return (totalSpend ?? 0) > 0 ? formatCurrency(totalSpend) : '$ 0.00'
}

const getTotalRateInUSD = (totalTargetRate: number | undefined) => {
  return (totalTargetRate ?? 0) > 0 ? formatCurrency(totalTargetRate) : '$ 0.00'
}

const HeaderCardV2 = ({
  rfp,
  lanes_count,
  openDownloadDialog,
  duplicateRFP,
  archiveRFP,
  rfpIsValid,
  openPublishDialog,
  openAwardDialog,
  openFinalizeDialog,
  totalizers,
  isLoadingTotalizers,
  rfpLaneDetails,
}: HeaderCardV2Props) => {
  const history = useHistory()
  const { rfpId } = useParams<{ rfpId: string }>()
  const [isVisible, setIsVisible] = useState<boolean>(true)

  const isBidsDeadlineEnded = useMemo(() => {
    const deadlineDate = new Date(rfp?.countdown_clock_date || 0)
    return deadlineDate < new Date()
  }, [rfp])

  const isRankedAward = rfp.bid_type === BID_TYPE.RANK

  const totalSpentInUSD = getTotalSpentInUSD(totalizers?.total_spend)
  const totalTargetRateInUSD = getTotalRateInUSD(totalizers?.total_target_rate)

  const totalSpentHeader = useMemo(() => {
    if (rfp.currency !== CURRENCY.USD) {
      const prefix = getCurrencyPrefix(rfp.currency)
      const value =
        (totalizers?.converted_total_spend ?? 0) > 0
          ? formatCurrency(totalizers?.converted_total_spend, true, false)
          : '0.00'
      return `${prefix}${value}`
    }
    return totalSpentInUSD
  }, [totalizers, rfp, totalSpentInUSD])

  const totalTargetRateHeader = useMemo(() => {
    if (rfp.currency !== CURRENCY.USD) {
      const prefix = getCurrencyPrefix(rfp.currency)
      const value =
        (totalizers?.converted_total_target_rate ?? 0) > 0
          ? formatCurrency(totalizers?.converted_total_target_rate, true, false)
          : '0.00'
      return `${prefix}${value}`
    }
    return totalTargetRateInUSD
  }, [totalizers, rfp, totalTargetRateInUSD])

  const totalSpentFooter = useMemo(() => {
    if (rfp.currency !== CURRENCY.USD) {
      return totalSpentInUSD
    }
    return undefined
  }, [rfp, totalSpentInUSD])

  const totalTargetRateFooter = useMemo(() => {
    if (rfp.currency !== CURRENCY.USD) {
      return totalTargetRateInUSD
    }
    return undefined
  }, [rfp.currency, totalTargetRateInUSD])

  const getBidTypeText = () =>
    rfp.bid_type !== BID_TYPE.RANK ? 'Award' : 'Rank'

  const getTotalizerSkeletonLength = () => (isRankedAward ? 2 : 4)

  const getCurrencyInfo = () => rfp.currency || CURRENCY.USD

  return (
    <StyledCard data-testid="header">
      <StyledCardTitle $isVisible={isVisible}>
        <Layout.Group justify="space-between" align="center">
          <Layout.Group align="center" space="l">
            <Breadcrumbs
              entries={[
                {
                  active: true,
                  label: rfp.name,
                },
              ]}
              onBack={() => history.replace('/shipper/rfp')}
            />
            <Tag size="small" variant={getTagVariant(rfp.state)}>
              {/* The RFP "Closed" state should be treated as "Published" in the UI */}
              {rfp.state === RFP_STATE.CLOSED ? 'Published' : rfp.state}
            </Tag>
          </Layout.Group>
          <HeaderActions
            archiveRFP={archiveRFP}
            duplicateRFP={duplicateRFP}
            isVisible={isVisible}
            openAwardDialog={openAwardDialog}
            openDownloadDialog={openDownloadDialog}
            openFinalizeDialog={openFinalizeDialog}
            openPublishDialog={openPublishDialog}
            rfp={rfp}
            rfpId={rfpId}
            rfpIsValid={rfpIsValid}
            setIsVisible={setIsVisible}
            rfpLaneDetails={rfpLaneDetails}
          />
        </Layout.Group>
      </StyledCardTitle>
      {isVisible && (
        <>
          <StyledCardSeparator />
          <StyledCardBody>
            <Layout.Stack space="l">
              <Layout.Group space="xl">
                <Layout.Stack space="2xs">
                  <Text variant="chips-sm" color="color-neutral">
                    Contract period
                  </Text>
                  <Text variant="body-bold" color="color-neutral-darker">
                    {formatRFPDate(rfp.contract_start)} →{' '}
                    {formatRFPDate(rfp.contract_end)}
                  </Text>
                </Layout.Stack>
                <Layout.Stack space="2xs">
                  <Text variant="chips-sm" color="color-neutral">
                    vol. frequency
                  </Text>
                  <Text variant="body-bold" color="color-neutral-darker">
                    {getValueLabel(volumeMap, rfp.capacity_time_period)}
                  </Text>
                </Layout.Stack>
                <Layout.Stack space="2xs">
                  <Text variant="chips-sm" color="color-neutral">
                    rate type
                  </Text>
                  <Text variant="body-bold" color="color-neutral-darker">
                    {getValueLabel(pricingMap, rfp.rate_type)}
                  </Text>
                </Layout.Stack>
                <Layout.Stack space="2xs">
                  <Text variant="chips-sm" color="color-neutral">
                    currency
                  </Text>
                  <Layout.Group space="xs">
                    <Text variant="body-bold" color="color-neutral-darker">
                      {getCurrencyInfo()}
                    </Text>
                    {rfp.conversion_rate && (
                      <Tooltip
                        position={TooltipPosition.Top}
                        message={getConversionRateTooltipMessage(rfp)}
                      >
                        <IconQuestionCircle
                          height={16}
                          width={16}
                          fill={theme.colors.neutral}
                          title={null}
                        />
                      </Tooltip>
                    )}
                  </Layout.Group>
                </Layout.Stack>
                <Layout.Stack space="2xs">
                  <Text variant="chips-sm" color="color-neutral">
                    contact
                  </Text>
                  <Layout.Group space="xs">
                    <Text variant="body-bold" color="color-neutral-darker">
                      {rfp.shipper_name}
                    </Text>
                    {(rfp.shipper_email || rfp.shipper_phone) && (
                      <Tooltip
                        position={TooltipPosition.Bottom}
                        message={
                          <TooltipMessage
                            email={rfp.shipper_email}
                            phone={rfp.shipper_phone}
                          />
                        }
                      >
                        <IconInfoCircle
                          height={16}
                          width={16}
                          fill={theme.colors.neutral}
                          title={null}
                        />
                      </Tooltip>
                    )}
                  </Layout.Group>
                </Layout.Stack>
                <Layout.Stack space="2xs">
                  <Text variant="chips-sm" color="color-neutral">
                    Deadline
                  </Text>
                  <Layout.Group space="xs">
                    <Text variant="body-bold" color="color-neutral-darker">
                      {formatRFPDateTime(rfp.countdown_clock_date)}
                    </Text>
                    <StatusDot
                      isEnded={isBidsDeadlineEnded}
                      rfpState={rfp.state as RfpState}
                    />
                  </Layout.Group>
                </Layout.Stack>
                <Layout.Stack space="2xs">
                  <Text variant="chips-sm" color="color-neutral">
                    Bid Type
                  </Text>
                  <Layout.Group space="xs">
                    <Text variant="body-bold" color="color-neutral-darker">
                      {getBidTypeText()}
                    </Text>
                  </Layout.Group>
                </Layout.Stack>
              </Layout.Group>
              {isLoadingTotalizers && (
                <TotalizerSkeleton length={getTotalizerSkeletonLength()} />
              )}

              {!isLoadingTotalizers &&
                (isRankedAward ? (
                  <RankedTotalizers
                    lanesCount={lanes_count}
                    totalSpentFooter={totalSpentFooter}
                    totalSpentHeader={totalSpentHeader}
                    totalizers={totalizers}
                  />
                ) : (
                  <AwardTotalizers
                    lanesCount={lanes_count}
                    totalSpentFooter={totalSpentFooter}
                    totalSpentHeader={totalSpentHeader}
                    totalTargetRateFooter={totalTargetRateFooter}
                    totalTargetRateHeader={totalTargetRateHeader}
                    totalizers={totalizers}
                  />
                ))}
            </Layout.Stack>
          </StyledCardBody>
        </>
      )}
    </StyledCard>
  )
}

export default HeaderCardV2
