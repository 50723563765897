import type { AxiosRequestConfig } from 'axios'

import httpClient from 'utils/httpClient'

export async function getShipmentsSettlementReports({
  limit,
  offset,
  config,
}: Partial<{
  config: AxiosRequestConfig
  limit: number
  offset: number
}>) {
  return await httpClient.get(
    `/accounting/settlement-reports?limit=${limit}&offset=${offset}`,
    config
  )
}
