import { Form as BaseForm } from 'formik'
import styled from 'styled-components'

import BaseButton from 'components/Button'
import { TextArea as BaseTextArea } from 'components/Input'

export const Button = styled(BaseButton)`
  align-items: center;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
`

export const LabelRow = styled.span``
export const LabelP = styled.p`
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-transform: uppercase;
`
export const ButtonGroup = styled.div<{ width?: string }>`
  background: ${({ theme }) => theme.colors.backgroundWhiteNeutral};
  bottom: 0;
  box-shadow: ${({ theme }) => theme.shadows.shadow01};
  display: grid;
  grid-column-end: span 2;
  grid-column-gap: 15px;
  grid-template-columns: 1fr 1fr;
  padding: 20px;
  position: fixed;
  width: ${({ width }) => (width ? width : '400px')};
`

export const InputGroup = styled.div<{ required?: boolean; span?: number }>`
  ${({ span }) =>
    span &&
    `
    grid-column-end: span ${span};
  `}
  ${({ required = false, theme }) =>
    required &&
    `
    label p::after {
      color: ${theme.colors.danger};
      content: ' *';
      font-size: 16px;
      font-weight: 600;
    }
  `}
`
export const CloseSidebarWrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutralLighter};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
`
export const Error = styled.p`
  color: ${({ theme }) => theme.colors.textError} !important;
  font-weight: 600;
  margin: 5px 0;
`
export const Form = styled(BaseForm)`
  display: flex;
  flex-direction: column;
  height: 100%;
`
export const FieldsContainer = styled.div`
  align-content: start;
  overflow-y: auto;
  padding: 10px 24px;
`
export const FieldSection = styled.div<{
  gridSize?: number
  gridTemplate?: string
}>`
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.neutralLighter};
  border-radius: 4px;
  display: grid;
  flex-grow: ${({ gridSize }) => (gridSize ? gridSize : '2')};
  grid-column-gap: 15px;
  grid-template-columns: ${({ gridTemplate }) =>
    gridTemplate ? gridTemplate : '1fr 1fr'};
  margin-bottom: 10px;
  padding: 30px;
`
export const FormContainer = styled.div<{
  background?: string
  width?: string
}>`
  background: ${({ background, theme }) =>
    background ? background : theme.colors.white};
  padding-bottom: 75px;
  width: ${({ width }) => (width ? width : '424px')};
`
export const TextArea = styled(BaseTextArea)`
  height: auto;
`
export const TitleContainer = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.whiteBroken};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutralLighter};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 24px 10px 24px;
  padding: 30px 0;
`
export const Title = styled.h2`
  margin: 0;
`
export const FieldSectionTitle = styled.h3<{
  isLoading?: boolean
  span?: number
}>`
  align-items: bottom;
  display: flex;
  flex-direction: row;
  margin: ${({ isLoading }) => (isLoading ? '0 0 15px 0' : '0 0 22px 0')};

  & > div {
    margin-left: 6px;
  }
  ${({ span }) =>
    span &&
    `
    grid-column-end: span ${span};
  `}
`
