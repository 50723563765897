import { get, isEmpty, set } from 'lodash'
import type { Dispatch, SetStateAction } from 'react'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useFacilityDetailsV2 } from 'components/FacilityDetails/useFacilityDetailsV2'
import { createTransientStop } from 'components/StopsManager'
import { usePlannableOrdersOptions } from 'orders/hooks/usePlannableOrdersOptions'
import type { ListOrder } from 'orders/types'
import type { FacilityDetailsV2 } from 'services/facilities'
import { formatDateUTC } from 'utils/dateUtils'
import { hasTransientError } from 'utils/transient'

import { usePreSelection } from '../create/hooks/usePreSelection'
import type { Fulfillment, TransientFulfillment } from '../domain/Fulfillment'
import { createTransientFulfillment } from '../domain/Fulfillment'
import { validate } from '../domain/Fulfillment.validation'

function getCustomerSupplierIdentifiersFromOrders(
  selectedOrder: ListOrder | undefined | null,
  asSupplier: boolean
) {
  if (asSupplier) {
    return {
      customer_uuid: selectedOrder?.customer?.uuid,
    }
  }

  return {
    supplier_uuid: selectedOrder?.supplier?.uuid,
  }
}

export function mapLaneFromOrder(
  order: ListOrder,
  pickupFacility: FacilityDetailsV2 | undefined,
  deliveryFacility: FacilityDetailsV2 | undefined
) {
  const pickupDate = order.pickup_window_start
    ? formatDateUTC(order.pickup_window_start, 'MM/DD/YYYY')
    : null
  const deliveryDate = order.delivery_window_start
    ? formatDateUTC(order.delivery_window_start, 'MM/DD/YYYY')
    : null

  return [
    createTransientStop({
      date: pickupDate,
      facility: pickupFacility,
    }),
    createTransientStop({
      date: deliveryDate,
      facility: deliveryFacility,
    }),
  ]
}

export type FulfillmentFormContextValue = {
  fulfillment: TransientFulfillment
  setPartialFulfillment: (value: Partial<TransientFulfillment>) => void
  setFulfillment: Dispatch<SetStateAction<TransientFulfillment>>
  isLoadingOrders?: boolean
  firstSelectedOrder?: ListOrder | null
  selectedOrders?: ListOrder[] | null
  ordersOptions: {
    label: string
    value: string
  }[]
}

export const FulfillmentFormContext = createContext<
  FulfillmentFormContextValue | undefined
>(undefined)

export function useFulfillmentFormContext() {
  const context = useContext(FulfillmentFormContext)

  if (context === undefined) {
    throw new Error(
      'useFulfillmentFormContext must be used within a FulfillmentFormContext.Provider'
    )
  }

  return context
}

export function FulfillmentFormProvider({
  fulfillment,
  children,
}: Readonly<{
  fulfillment?: Fulfillment
  children: React.ReactNode
}>) {
  const [transientFulfillment, setTransientFulfillment] =
    useState<TransientFulfillment>(createTransientFulfillment(fulfillment))

  const setPartialFulfillment = useCallback(
    (partialState: Partial<TransientFulfillment>) => {
      setTransientFulfillment((currentFulfillment) => {
        const updatedFulfillment = Object.keys(partialState).reduce(
          (newFulfillment, path) => {
            return set(newFulfillment, path, get(partialState, path))
          },
          { ...currentFulfillment }
        )

        if (hasTransientError(updatedFulfillment)) {
          const [validatedFulfillment] = validate(updatedFulfillment)
          return validatedFulfillment
        }

        return updatedFulfillment
      })
    },
    [setTransientFulfillment]
  )

  const asSupplier = transientFulfillment.owner === 'customer'
  const {
    orders,
    ordersOptions,
    isLoading: isLoadingOrders,
  } = usePlannableOrdersOptions(asSupplier)

  const firstSelectedOrder = useMemo(() => {
    const UUIDs = transientFulfillment.order_UUIDs
    if (UUIDs != null && UUIDs.length > 0) {
      return orders.find((order) => order.uuid === UUIDs[0])
    }

    return null
  }, [transientFulfillment.order_UUIDs, orders])

  const selectedOrders = useMemo(() => {
    const UUIDs = transientFulfillment.order_UUIDs
    if (UUIDs != null) {
      return orders.filter((order) => UUIDs.includes(order.uuid))
    }

    return null
  }, [transientFulfillment.order_UUIDs, orders])

  const value = useMemo(() => {
    return {
      firstSelectedOrder,
      fulfillment: transientFulfillment,
      setFulfillment: setTransientFulfillment,
      setPartialFulfillment,
      isLoadingOrders,
      selectedOrders,
      ordersOptions,
    }
  }, [
    firstSelectedOrder,
    transientFulfillment,
    setPartialFulfillment,
    isLoadingOrders,
    ordersOptions,
    selectedOrders,
  ])

  // pre-setting order from QP
  const { clearSelectedOrders, selectedOrdersUUIDs } = usePreSelection()
  useEffect(() => {
    if (selectedOrdersUUIDs.length && orders.length) {
      const ordersFromQP = orders.filter((order) =>
        selectedOrdersUUIDs.includes(order.uuid)
      )
      const firstOrderFromQP = ordersFromQP.length ? ordersFromQP[0] : null
      setPartialFulfillment({
        order_UUIDs: selectedOrdersUUIDs,
        order_identifier_type: 'orders',
        ...getCustomerSupplierIdentifiersFromOrders(
          firstOrderFromQP,
          asSupplier
        ),
      })
      clearSelectedOrders()
    }
  }, [
    asSupplier,
    clearSelectedOrders,
    orders,
    selectedOrdersUUIDs,
    setPartialFulfillment,
  ])

  // pre-setting fields based on selected order
  const pickupFacilityFromOrderUUID = firstSelectedOrder?.pickup_facility_uuid
  const { data: pickupFacilityFromOrder } = useFacilityDetailsV2(
    pickupFacilityFromOrderUUID,
    {
      enabled: !isEmpty(pickupFacilityFromOrderUUID),
    }
  )

  const deliveryFacilityFromOrderUUID =
    firstSelectedOrder?.delivery_facility_uuid
  const { data: deliveryFacilityFromOrder } = useFacilityDetailsV2(
    deliveryFacilityFromOrderUUID,
    {
      enabled: !isEmpty(deliveryFacilityFromOrderUUID),
    }
  )

  useEffect(() => {
    if (
      firstSelectedOrder &&
      pickupFacilityFromOrder &&
      deliveryFacilityFromOrder
    ) {
      setPartialFulfillment({
        stops: mapLaneFromOrder(
          firstSelectedOrder,
          pickupFacilityFromOrder,
          deliveryFacilityFromOrder
        ),
      })
    }
  }, [
    asSupplier,
    pickupFacilityFromOrder,
    deliveryFacilityFromOrder,
    firstSelectedOrder,
    setPartialFulfillment,
  ])

  return (
    <FulfillmentFormContext.Provider value={value}>
      {children}
    </FulfillmentFormContext.Provider>
  )
}
