import { Layout } from '@loadsmart/loadsmart-ui'

import { Text } from 'components/Typography'

import ActionIcon from './ActionIcon'

const RankPosition = ({
  rank,
  totalRankedAwards,
  isPlaceholder,
  isDisabled,
  moveUpAction,
  moveDownAction,
}: {
  readonly rank: number
  readonly totalRankedAwards: number
  readonly isPlaceholder: boolean
  readonly isDisabled: boolean
  readonly moveUpAction: () => void
  readonly moveDownAction: () => void
}) => {
  const disableActions = isDisabled || isPlaceholder
  const moveUpDisabled = disableActions || rank === 1
  const moveDownDisabled = disableActions || rank >= totalRankedAwards

  return (
    <Layout.Group space="xs" align="center">
      <ActionIcon
        name="arrowUp"
        action={moveUpDisabled ? undefined : moveUpAction}
        visible={!isPlaceholder}
        disabled={moveUpDisabled}
      />
      <Text color={isPlaceholder ? 'neutral' : 'accent'}>{rank}</Text>
      <ActionIcon
        name="arrowDown"
        action={moveDownDisabled ? undefined : moveDownAction}
        visible={!isPlaceholder}
        disabled={moveDownDisabled}
      />
    </Layout.Group>
  )
}

export default RankPosition
