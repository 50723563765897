import { IconTrophyFilled } from '@loadsmart/icons'
import {
  BannerLarge,
  Card,
  Dialog,
  Layout,
  LoadingDots,
} from '@loadsmart/loadsmart-ui'
import { useCallback, useMemo, useState } from 'react'

import { InputGroup } from '_shared_/components/form/styles'
import Button from 'components/Button'
import { Input, Label } from 'components/Input'
import {
  StyledBody,
  StyledTitle,
  TitleLoaderWrapper,
} from 'components/SideBar/styles'
import TableV3 from 'components/TableV3'
import { useSettings } from 'contexts/settings'
import { useModal } from 'hooks/useModal'
import type { Currency, RequestForProposal } from 'rfp/rfp.types'
import { theme } from 'styles/theme'
import { RFP_STATE } from 'utils/constants'
import { isMultiCurrency } from 'utils/currency'

import { PrimaryBackupButtonsWrapper } from '../lane-bids/styles'
import makeColumns, { rateColumnOptions } from './makeColumns'

const tableInitalState = { sortBy: [{ id: 'flat_rate' }] }
const defaultAwardModalState = {
  isPrimary: true,
  isBackup: false,
  volumeGiven: '',
  volumeOrigin: 0,
}

const shouldDisplayRejectBanner = (
  rfpState: RfpState,
  laneProposals: LaneProposal[],
  lane: Omit<Lane, 'lane_proposal' | 'meta'>
) =>
  rfpState === RFP_STATE.AWARDING &&
  laneProposals.some((e) => e.award_confirmation === 'denied') &&
  lane.remaining_award_volume > 0

export default function LaneResultsV1({
  lane,
  laneProposals,
  isLoadingLaneProposals,
  mutateLaneProposal,
  isUpdating,
  handleRequestNewRate,
  promoteCarrierToPrimary,
  canPromoteCarrierToPrimary,
  canAwardOrUnaward,
  remainingVolumeGiven,
  rfpCurrency,
  rfpState,
  rfpDistanceType,
}: {
  readonly lane: Omit<Lane, 'lane_proposal' | 'meta'>
  readonly laneProposals: LaneProposal[]
  readonly isLoadingLaneProposals: boolean
  readonly isUpdating: boolean
  readonly handleRequestNewRate: (proposal: LaneProposal, note: string) => void
  readonly promoteCarrierToPrimary: (proposal: LaneProposal) => void
  readonly canPromoteCarrierToPrimary: boolean
  readonly mutateLaneProposal: ({
    laneId,
    carrierId,
    payload,
  }: {
    laneId: number
    carrierId: number
    payload: Partial<LaneProposal>
  }) => void
  readonly canAwardOrUnaward: boolean
  readonly remainingVolumeGiven: number
  readonly rfpCurrency: Currency
  readonly rfpState: RfpState
  readonly rfpDistanceType: RequestForProposal['distance_type']
}) {
  const displayMultiCurrency = isMultiCurrency(rfpCurrency)
  const [selected, setSelected] = useState<LaneProposal | null>(null)
  const [awardModalState, setAwardModalState] = useState(defaultAwardModalState)
  const { modalState, closeModal, openModal } = useModal()
  const [rateColumn, setRateColumn] = useState(rateColumnOptions[0].value)

  const handleAwardModalClose = useCallback(() => {
    closeModal()
    setSelected(null)
    setAwardModalState(defaultAwardModalState)
  }, [closeModal])

  const {
    values: [loadsmartDOT],
  } = useSettings(['settings.LOADSMART_DOT'])

  const handleSelectCarrier = useCallback(
    (laneProposal: LaneProposal) => {
      setSelected(laneProposal)
      setAwardModalState({
        volumeOrigin: laneProposal.capacity,
        volumeGiven: laneProposal.awarded_volume.toString(),
        isPrimary: laneProposal.awarded_type !== 'backup',
        isBackup: laneProposal.awarded_type === 'backup',
      })
      openModal()
    },
    [openModal, setAwardModalState]
  )

  const handleAwardModalSave = useCallback(() => {
    if (selected !== null) {
      if (awardModalState.isPrimary) {
        mutateLaneProposal({
          laneId: lane.id,
          carrierId: selected.id,
          payload: {
            awarded_type: 'primary',
            awarded_volume: parseInt(awardModalState.volumeGiven, 10),
          },
        })
      } else {
        mutateLaneProposal({
          laneId: lane.id,
          carrierId: selected.id,
          payload: {
            awarded_type: 'backup',
          },
        })
      }
      closeModal()
    }
  }, [
    closeModal,
    awardModalState.isPrimary,
    awardModalState.volumeGiven,
    lane.id,
    mutateLaneProposal,
    selected,
  ])

  const handleUncheckCarrier = useCallback(
    (laneProposal: LaneProposal) => {
      mutateLaneProposal({
        laneId: lane.id,
        carrierId: laneProposal.id,
        payload: {
          awarded_type: null,
          awarded_volume: 0,
        },
      })
    },
    [lane.id, mutateLaneProposal]
  )

  const handlePrimaryBackupChange = useCallback(() => {
    setAwardModalState({
      ...awardModalState,
      isPrimary: awardModalState.isBackup,
      isBackup: awardModalState.isPrimary,
    })
  }, [awardModalState, setAwardModalState])

  const handleGivenVolumeChange = useCallback(
    ({ target: { value } }: { target: { value: string } }) => {
      setAwardModalState({
        ...awardModalState,
        volumeGiven:
          Number(value) > Number(awardModalState.volumeOrigin)
            ? awardModalState.volumeOrigin.toString()
            : value.toString(),
      })
    },
    [awardModalState, setAwardModalState]
  )

  const columns = useMemo(() => {
    return () =>
      makeColumns({
        handleRequestNewRate,
        promoteCarrierToPrimary,
        canPromoteCarrierToPrimary,
        handleSelectCarrier,
        lane,
        loadsmartDOT,
        handleUncheckCarrier,
        canAwardOrUnaward,
        rfpCurrency,
        displayMultiCurrency,
        rfpState,
        rfpDistanceType,
        rateColumn,
        setRateColumn,
      })
  }, [
    handleRequestNewRate,
    promoteCarrierToPrimary,
    canPromoteCarrierToPrimary,
    handleSelectCarrier,
    lane,
    loadsmartDOT,
    handleUncheckCarrier,
    canAwardOrUnaward,
    rfpCurrency,
    displayMultiCurrency,
    rfpState,
    rfpDistanceType,
    rateColumn,
  ])

  return (
    <>
      <StyledTitle style={{ marginBottom: '30px' }}>
        Results
        {isUpdating && <TitleLoaderWrapper />}
      </StyledTitle>
      <StyledBody>
        <TableV3
          columns={columns}
          entries={laneProposals}
          isLoading={isLoadingLaneProposals}
          initialState={tableInitalState}
          sortable
          pagination
          bordered={false}
          data-testid="lane-results-table"
        />
        {shouldDisplayRejectBanner(rfpState, laneProposals, lane) && (
          <Layout.Group style={{ display: 'block', margin: '7px 23px' }}>
            <BannerLarge
              data-testid="banner-reject-awards"
              scale="default"
              title="One or more carriers rejected your award"
              description="Please select new carriers on Bids Received"
              variant="danger"
              dismissible={false}
            />
          </Layout.Group>
        )}
        <Card.Separator style={{ margin: '30px 0' }} />
      </StyledBody>
      <Dialog
        open={modalState}
        onOverlayClick={handleAwardModalClose}
        data-testid="edit-result-modal"
      >
        <Dialog.Header>
          {(selected !== null && selected.carrier?.name) || ''}
        </Dialog.Header>
        <Dialog.Body>
          {selected && (
            <div style={{ textAlign: 'left' }}>
              Select carrier as
              <PrimaryBackupButtonsWrapper>
                <Button
                  variant={awardModalState.isPrimary ? 'dark' : 'secondary'}
                  onClick={handlePrimaryBackupChange}
                  style={{
                    marginRight: '8px',
                    paddingLeft: '8px',
                    paddingRight: '8px',
                    fontSize: '16px',
                    marginTop: '10px',
                  }}
                >
                  <IconTrophyFilled
                    style={{ marginRight: '4px', verticalAlign: 'top' }}
                    width={16}
                    height={16}
                    fill={
                      awardModalState.isPrimary
                        ? theme.colors.white
                        : theme.colors.text
                    }
                  />
                  PRIMARY CARRIER
                </Button>
                <Button
                  variant={awardModalState.isBackup ? 'dark' : 'secondary'}
                  onClick={handlePrimaryBackupChange}
                  style={{
                    padding: '10px 8px',
                    fontSize: '16px',
                    marginTop: '10px',
                  }}
                >
                  BACKUP CARRIER
                </Button>
              </PrimaryBackupButtonsWrapper>
              <InputGroup required={awardModalState.isPrimary}>
                <Label name="volume">
                  VOLUME GIVEN
                  <Input
                    name="volume"
                    type="number"
                    value={awardModalState.volumeGiven}
                    onChange={handleGivenVolumeChange}
                    min={0}
                    max={selected.capacity}
                    style={{
                      display: 'inline-flex',
                      width: '165px',
                      marginRight: '8px',
                    }}
                    disabled={awardModalState.isBackup}
                  />
                  &nbsp;of{' '}
                  {Number(selected.awarded_volume) + remainingVolumeGiven}
                </Label>
              </InputGroup>
            </div>
          )}
        </Dialog.Body>
        <Dialog.ActionConfirm onConfirm={handleAwardModalSave}>
          {!isUpdating ? 'SAVE' : <LoadingDots variant="light" />}
        </Dialog.ActionConfirm>
        <Dialog.ActionCancel onCancel={handleAwardModalClose} />
      </Dialog>
    </>
  )
}
