import {
  IconCheck,
  IconClose,
  IconSearch,
  IconSettings,
  IconPlus,
} from '@loadsmart/icons'
import {
  Dialog,
  Dropdown,
  Layout,
  Tag,
  Text,
  TextField,
} from '@loadsmart/loadsmart-ui'
import { Button } from '@loadsmart/miranda-react'
import * as Sentry from '@sentry/react'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import type { ChangeEvent, Dispatch, SetStateAction } from 'react'
import ReactPaginate from 'react-paginate'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { useDebounce } from 'use-debounce'

import { useCurrentUser } from '_shared_/user/useCurrentUser'
import CarrierPanel from 'components/CarrierPanel'
import SideBar from 'components/SideBar'
import { useSettings } from 'contexts/settings'
import { useCarrierPanel } from 'hooks/useCarrierPanel'
import { useModal } from 'hooks/useModal'
import usePagination from 'hooks/usePagination'
import {
  useCarrierInvitePerRfpQuota,
  useCarriersFilter,
  useShipperRfpQuota,
} from 'hooks/useQuery'
import { useWindowSize } from 'hooks/useWindowSize'
import {
  getTablePageOffset,
  getTableSortDirection,
} from 'rfp/components/table/tableUtils'
import type { Currency } from 'rfp/rfp.types'
import { useRFPAnalytics } from 'screens/Shipper/CarrierManagement/hooks'
import type { RFPAnalyticsData } from 'services/carrierAnalytics'
import { bulkCreateInvite, bulkDeleteInvite } from 'services/carrierInvite'
import { inviteAll } from 'services/carriers'
import { theme } from 'styles/theme'
import analytics, {
  AnalyticsEvent,
  AnalyticsEventTrigger,
} from 'utils/analytics'
import { RFP_STATE } from 'utils/constants'
import { isMultiCurrency } from 'utils/currency'

import BaseTable from './BaseTable'
import CarrierFilters from './CarrierFilters'
import makeColumns from './carriers-table/makeColumns'
import EmptyState from './EmptyState'
import InviteAllDialog from './InviteAllDialog'
import InviteAllFilteredDialog from './InviteAllFilteredDialog'
import InviteSelectedDialog, {
  countSentOrResentInvites,
  countUninvitedCarriers,
} from './InviteSelectedDialog'

const LIMIT = 50

interface ParamOptions {
  page: number
  offset: number
  limit: number
  sort: {
    column: string
    direction: string
  }
  search: string
  filter: string
}

const defaultParams: ParamOptions = {
  page: 0,
  offset: 0,
  limit: LIMIT,
  sort: {
    column: 'name',
    direction: 'asc',
  },
  search: '',
  filter: '',
}

interface CarrierProps {
  readonly rfpId: number
  readonly rfpUuid: string
  readonly rfpState: RfpState
  readonly rfpCurrency: Currency
  readonly rfpRequestDeadline: string
  readonly invites: NewCarrierInvite[]
  readonly carrierInviteStatus: string
  readonly refetchCarrierInvites: () => Promise<unknown>
  readonly isLoadingCarrierInvites: boolean
}

const CATEGORY_NAMES: Record<string, string> = {
  entity: 'Type',
  equipment: 'Equipment',
  tags: 'Tags',
  region: 'Region',
  responseRate: 'Response Rate',
  carrierInviteStates: 'Invite Status',
}

const checkIfHasFilter = (filter: Filters) => {
  const { responseRate, ...filters } = filter
  let key: keyof Omit<Filters, 'responseRate'>

  if (responseRate !== null) {
    return true
  }

  for (key in filters) {
    if (filters[key].length > 0) {
      return true
    }
  }

  return false
}

const TagFilters = ({
  filters,
  removeFilter,
}: {
  readonly filters: Filters
  readonly removeFilter: (type: keyof Filters) => void
}) => {
  return (
    <>
      {Object.entries(filters).map(([key, item]) => {
        if (key !== 'responseRate' && item.length > 0) {
          return (
            <Tag
              leading={null}
              onRemove={() => removeFilter(key as keyof Filters)}
              removable
              size="default"
              variant="default"
              key={`table-filter-${key}`}
              data-testid="carrier-tag-filter"
            >
              {`${CATEGORY_NAMES[key]}: `}
              {item.length > 1
                ? `${item.length} Selected`
                : item[0]?.label?.replace(/\(\d+\)/gm, '')}
            </Tag>
          )
        }

        if (key === 'responseRate' && item !== null) {
          return (
            <Tag
              leading={null}
              onRemove={() => removeFilter('responseRate')}
              removable
              size="default"
              variant="default"
              key={`table-filter-${key}`}
              data-testid="carrier-tag-filter"
            >
              {`${CATEGORY_NAMES[key]}: `}
              {item.label}
            </Tag>
          )
        }
        return null
      })}
    </>
  )
}

const TransparentButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0;
  outline: none;
  padding: 0;
`

const BidDeadlineClosedDialog = ({
  isOpen,
  onClose,
  onConfirm,
}: {
  readonly isOpen: boolean
  readonly onClose: () => void
  readonly onConfirm: () => void
}) => {
  return (
    <Dialog open={isOpen} scale="small" onOverlayClick={onClose}>
      <Dialog.Header>Bid Deadline Closed</Dialog.Header>
      <Dialog.Body>
        <Text variant="body" color="color-neutral-darker">
          You can&apos;t invite carriers once the bid deadline is closed.
        </Text>
        <br />
        <br />
        <Text variant="body" color="color-neutral-darker">
          You can reopen it for bids by editing the deadline date.
        </Text>
      </Dialog.Body>
      <Dialog.ActionConfirm onConfirm={onConfirm}>
        Edit deadline
      </Dialog.ActionConfirm>
      <Dialog.ActionCancel onCancel={onClose}>Cancel</Dialog.ActionCancel>
    </Dialog>
  )
}

export interface Carrier {
  id: number
  name: string
  dot: string
  mc?: string
  entity?: string
  email: string
  invite?: CarrierInvite
  isInvited?: boolean
  sg_uuid: string
  equipment_types?: any
  info?: AliceCarrier
  phone_numbers?: string[]
  bipd_on_file?: number | null
  cargo_on_file?: number | null
  scac?: number | null
  notes?: string | null
  req_safety_passed?: string | null
  hq_address?: string | null
  tags?: string[]
  response_rate?: string | number
  total_active_lanes?: number
  total_invitations?: number
  total_lanes_awarded?: number
  total_submitted_bids?: number
  unsubscribed?: boolean
  operation_regions?: any
  disabled?: boolean
}

const checkIfRowIsDisabled = ({
  rfpState,
  hasDisabledStyle,
  inviteStatus,
}: {
  rfpState: RfpState
  hasDisabledStyle: boolean
  inviteStatus?: string
}) => {
  if (rfpState === RFP_STATE.PUBLISHED && inviteStatus === 'submitted') {
    return true
  }

  if (rfpState === RFP_STATE.CLOSED) {
    return true
  }

  if (hasDisabledStyle) {
    return true
  }

  return false
}

const COLUMN_PICKER_MAP: Record<string, string> = {
  dot: 'DOT',
  mc: 'MC',
  scac: 'SCAC',
  email: 'EMAIL',
  response_rate: 'RESPONSE RATE',
  total_invitations: 'INVITED / BIDS',
  total_lanes_awarded: 'LANES AWARDED',
  total_active_lanes: 'ACTIVE LANES',
  avg_response_time: 'AVG. RESPONSE TIME',
}

const mapCarrierInvite = (invite?: NewCarrierInvite) => {
  if (invite) {
    return {
      invite: {
        id: invite.id,
        state: invite.state,
        invite_email_sent_at: invite.invite_email_sent_at,
        invite_status_label: invite.invite_status_label,
        invite_status_label_reason: invite.invite_status_label_reason,
        link: invite.link,
      },
    }
  }
  return {}
}

const matchCarriersWithInvite = ({
  carriers,
  invites,
  rfpState,
  rfpCurrency,
  loadsmartDOT,
  rfpAnalyticsData = [],
}: {
  carriers: Carrier[]
  invites: NewCarrierInvite[]
  rfpState: RfpState
  rfpCurrency: Currency
  loadsmartDOT: string
  rfpAnalyticsData?: RFPAnalyticsData[]
}) => {
  return carriers.map((carrier: Carrier) => {
    const carrierAnalytics =
      rfpAnalyticsData.find(
        (carrierA: RFPAnalyticsData) => carrierA.uuid === carrier.sg_uuid
      ) ?? {}
    const carrierInvite = invites.find(
      (invite: NewCarrierInvite) => invite?.carrier.uuid === carrier.sg_uuid
    )
    const hasDisabledStyle =
      carrier.dot === loadsmartDOT && isMultiCurrency(rfpCurrency)

    return {
      ...carrier,
      ...carrierAnalytics,
      hasDisabledStyle,
      ...mapCarrierInvite(carrierInvite),
      isRowDisabled: checkIfRowIsDisabled({
        rfpState,
        hasDisabledStyle,
      }),
    }
  })
}

const filterInvitedCarriers = (carriers: Carrier[]) =>
  carriers.filter((carrier) => carrier.invite)

interface FilterOption {
  value: string
  label: string
  _type?: string
}

interface Filters {
  entity: FilterOption[]
  equipment: FilterOption[]
  tags: FilterOption[]
  region: FilterOption[]
  responseRate: FilterOption | null
  carrierInviteStates: FilterOption[]
}

const defaulFilters: Filters = {
  entity: [],
  equipment: [],
  tags: [],
  region: [],
  responseRate: null,
  carrierInviteStates: [],
}

const NoCarriersInvitedEmptyState = () => {
  return (
    <EmptyState>
      <Layout.Stack space="s">
        <Text variant="heading-sm-bold" color="color-neutral-darkest">
          No Carriers Invited
        </Text>
        <Text variant="caption" color="color-neutral-darker">
          You haven&apos;t selected any of your
          <br />
          carriers to be invited on this RFP.
        </Text>
      </Layout.Stack>
    </EmptyState>
  )
}

const ClearSearchFieldButton = ({
  onClick,
}: {
  readonly onClick: () => void
}) => {
  return (
    <TransparentButton onClick={onClick}>
      <IconClose width={16} height={16} />
    </TransparentButton>
  )
}

const shouldDisplayNoCarriersInvited = (
  rfpState: string,
  carrierFilterStatus: string,
  carrierInviteStatus: string,
  invites: NewCarrierInvite[]
) => {
  const rfpFinalizedOrArchived =
    rfpState === RFP_STATE.FINALIZED || rfpState === RFP_STATE.ARCHIVED

  const hasInvites = Array.isArray(invites) && invites.length === 0

  return (
    rfpFinalizedOrArchived &&
    carrierFilterStatus === 'success' &&
    carrierInviteStatus === 'success' &&
    hasInvites
  )
}

const shouldDisableInvites = (rfpState: string, rfpRequestDeadline: string) =>
  [
    RFP_STATE.CLOSED,
    RFP_STATE.ARCHIVED,
    RFP_STATE.FINALIZED,
    RFP_STATE.AWARDING,
  ].includes(rfpState) || moment(rfpRequestDeadline).isBefore()

const InviteAllCarrierDialog = ({
  hasFilters,
  inviteAllDialog,
  rfpState,
  isInvitingAll,
  carriersCount,
  inviteAllCarriers,
}: {
  readonly hasFilters: boolean
  readonly inviteAllDialog: {
    modalState: boolean
    closeModal: () => void
    openModal: () => void
    toggleModal: () => void
    setModalState: Dispatch<SetStateAction<boolean>>
  }
  readonly rfpState: RfpState
  readonly isInvitingAll: boolean
  readonly carriersCount: number
  readonly inviteAllCarriers: () => void
}) => {
  if (hasFilters) {
    return (
      <InviteAllFilteredDialog
        isOpen={inviteAllDialog.modalState}
        onClose={inviteAllDialog.closeModal}
        onConfirm={inviteAllCarriers}
        rfpState={rfpState}
        isInvitingAll={isInvitingAll}
        carriersCount={carriersCount}
      />
    )
  }

  return (
    <InviteAllDialog
      isOpen={inviteAllDialog.modalState}
      onClose={inviteAllDialog.closeModal}
      onConfirm={inviteAllCarriers}
      rfpState={rfpState}
      isInvitingAll={isInvitingAll}
      carriersCount={carriersCount}
    />
  )
}

const UNSORTABLE_COLUMNS = [
  'actions',
  'vs',
  'state',
  'selection',
  'scac',
  'percentage_invites_responded',
  'total_invites',
  'awarded_bids_rate',
  'average_response_time',
]

const ANALYTICS_SORT_COLUMNS_MAP: Record<string, string> = {
  name: 'carrier_name',
  entity: 'carrier_entity',
  dot: 'carrier_dot',
  mc: 'carrier_mc',
  email: 'carrier_email',
}

const AddCarrierButton = ({
  rfpState,
  openForAddNewCarrier,
}: {
  readonly rfpState: RfpState
  readonly openForAddNewCarrier: () => void
}) => {
  const shouldDisplayAddCarrierButton = [
    RFP_STATE.DRAFT,
    RFP_STATE.PUBLISHED,
  ].includes(rfpState)

  if (shouldDisplayAddCarrierButton) {
    return (
      <Button
        variant="secondary"
        onClick={() => {
          analytics.track(
            AnalyticsEvent.RFPAddCarrierButtonClick,
            AnalyticsEventTrigger.click
          )
          openForAddNewCarrier()
        }}
        leading={<IconPlus width={16} height={16} />}
      >
        Add carrier
      </Button>
    )
  }
  return null
}

const Carriers = ({
  rfpId,
  rfpUuid,
  rfpState,
  rfpCurrency,
  rfpRequestDeadline,
  invites,
  carrierInviteStatus,
  refetchCarrierInvites,
  isLoadingCarrierInvites,
}: CarrierProps) => {
  const {
    values: [loadsmartDOT],
  } = useSettings(['settings.LOADSMART_DOT'])
  const defaultBidOption = 'percentage_invites_responded'
  const { user } = useCurrentUser()
  const { windowWidth } = useWindowSize()
  const [params, setParams] = useState(defaultParams)
  const [debouncedSearchTerm] = useDebounce(params.search, 450)
  const [selectedCarriers, setSelectedCarriers] = useState<Carrier[]>([])
  const [infoOption, setInfoOption] = useState('dot')
  const [bidOption, setBidOption] = useState(defaultBidOption)
  const [tagfilters, setTagFilters] = useState(defaulFilters)
  const [isCarrierFilterOpen, setIsCarrierFilterOpen] = useState<boolean>(false)
  const [selectedCarriersToResendInvite, setSelectedCarriersToResendInvite] =
    useState<Carrier[]>([])

  const history = useHistory()

  const setInfoOptionAndTriggerAnalytics = useCallback(
    (selectedInfoOption: string) => {
      analytics.track(
        AnalyticsEvent.RFPInvitedCarriersColumnSelect,
        AnalyticsEventTrigger.click,
        {
          carrier_indicator_selected_previous_option:
            COLUMN_PICKER_MAP[infoOption],
          carrier_indicator_selected_new_option:
            COLUMN_PICKER_MAP[selectedInfoOption],
        }
      )

      setInfoOption(selectedInfoOption)
    },
    [infoOption]
  )

  const setBidOptionAndTriggerAnalytics = useCallback(
    (selectedBidOption: string) => {
      analytics.track(
        AnalyticsEvent.RFPInvitedCarriersColumnSelect,
        AnalyticsEventTrigger.click,
        {
          carrier_indicator_selected_previous_option:
            COLUMN_PICKER_MAP[bidOption],
          carrier_indicator_selected_new_option:
            COLUMN_PICKER_MAP[selectedBidOption],
        }
      )

      setBidOption(selectedBidOption)
    },
    [bidOption]
  )

  const bidClosedDialog = useModal()
  const inviteAllDialog = useModal()
  const inviteSelectedDialog = useModal()

  const parsedTagFilter = useMemo(() => {
    const searchParams = new URLSearchParams()

    if (tagfilters.entity.length > 0) {
      tagfilters.entity.forEach((entity) =>
        searchParams.append('entity', entity.value)
      )
    }

    if (tagfilters.equipment.length > 0) {
      tagfilters.equipment.forEach((equipment) =>
        searchParams.append('equipment_types', equipment.value)
      )
    }

    if (tagfilters.region.length > 0) {
      tagfilters.region.forEach((region) =>
        searchParams.append('operation_regions', region.value)
      )
    }

    tagfilters.tags.forEach((tag) =>
      searchParams.append('carrier_tags', tag.value)
    )

    tagfilters.carrierInviteStates.forEach((tag) =>
      searchParams.append('carrier_invite_states', tag.value)
    )

    return searchParams.toString()
  }, [tagfilters])

  // maybe use a debouncedParsedFilter

  useEffect(() => {
    if ([RFP_STATE.ARCHIVED, RFP_STATE.FINALIZED].includes(rfpState)) {
      setParams({ ...defaultParams, filter: 'invited' })
      return
    }

    setParams({ ...defaultParams, filter: 'all' })
  }, [rfpState])

  const clearFilters = () => setTagFilters(defaulFilters)

  const {
    data,
    isLoading: isLoadingCarriers,
    status: carrierFilterStatus,
    refetch: refetchCarriers,
  } = useCarriersFilter(
    {
      tagFiltersQuery: parsedTagFilter,
      query: debouncedSearchTerm,
      limit: params.limit.toString(),
      offset: params.offset.toString(),
      sort: params.sort,
      filter: params.filter,
      rfpId,
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      enabled: params.filter !== '',
      onError(error) {
        Sentry.captureException(error)
        toast.error('Could not retrieve the carriers, please contact an admin')
      },
    }
  )

  const { refetch: refetchShipperRfpQuota } = useShipperRfpQuota(rfpUuid)

  const { refetch: refetchCarrierInviteQuota } =
    useCarrierInvitePerRfpQuota(rfpUuid)

  const { mutate: addCarriersToBid, isLoading: isAddingCarriersToBid } =
    useMutation({
      mutationFn: bulkCreateInvite,
      onSuccess: () => {
        toast.success('Carriers invited to bid')
        inviteSelectedDialog.closeModal()
        refetchCarriers()
        refetchCarrierInvites()
        refetchShipperRfpQuota()
        refetchCarrierInviteQuota()
      },
      onError: (error: any) => {
        const errMsg = error?.response?.data?.error
        if (errMsg) {
          toast.error(errMsg)
        } else {
          toast.error(`Could not invite carriers, please contact an admin`)
        }
      },
    })

  const {
    mutate: removeCarriersFromBid,
    isLoading: isRemovingCarriersFromBid,
  } = useMutation({
    mutationFn: bulkDeleteInvite,
    onSuccess: () => {
      toast.info('Carriers removed from bid')
      refetchCarriers()
      refetchCarrierInvites()
    },
  })

  const { mutate: inviteAllCarriers, isLoading: isInvitingAll } = useMutation({
    mutationFn: inviteAll,
    onSuccess: (responseData) => {
      inviteAllDialog.closeModal()
      // if the filter didn't match any carriers
      // the backend will return HTTP 200 but with a message
      if (responseData?.carrier_invites?.message) {
        toast.warning(responseData?.carrier_invites?.message)
        return
      }

      toast.success(
        `Carriers ${rfpState === RFP_STATE.DRAFT ? 'added' : 'invited'} to bid`
      )

      refetchCarriers()
      refetchCarrierInvites()
      refetchCarrierInviteQuota()
    },
    onError: (error: any) => {
      const errMsg = error?.response?.data?.error
      if (errMsg) {
        toast.error(errMsg)
      } else {
        toast.error(`Could not invite carriers, please contact an admin`)
      }
      inviteAllDialog.closeModal()
    },
  })

  const { data: rfpAnalyticsData, isLoading: isLoadingAnalytics } =
    useRFPAnalytics(
      '',
      params.limit.toString(),
      params.offset.toString(),
      {
        column: ANALYTICS_SORT_COLUMNS_MAP[params.sort.column],
        direction: params.sort.direction,
      },
      user?.shipper_location_default,
      {
        refetchOnWindowFocus: false,
        enabled: true,
        retry: false,
      }
    )

  const carrierInvites = useMemo(() => {
    if (data?.results && invites && !isLoadingAnalytics) {
      return matchCarriersWithInvite({
        carriers: data.results,
        invites,
        rfpState,
        rfpCurrency,
        loadsmartDOT,
        rfpAnalyticsData: rfpAnalyticsData?.results,
      })
    }

    return null
  }, [
    data,
    invites,
    rfpState,
    rfpCurrency,
    loadsmartDOT,
    rfpAnalyticsData,
    isLoadingAnalytics,
  ])

  const {
    selectedCarrier,
    setSelectedCarrier,
    closeCarrierPanel,
    openForEdition,
    isOpenForAddNewCarrier,
    openForAddNewCarrier,
  } = useCarrierPanel(carrierInvites ?? [])

  const { pageCount, page } = usePagination(LIMIT, data?.count, params.page)

  const handlePageChange = (selectedItem: { selected: number }) => {
    setParams({
      ...params,
      page: selectedItem.selected,
      offset: getTablePageOffset(selectedItem.selected, defaultParams.offset),
    })
  }

  const changeSorting = (column: string) => {
    setParams({
      ...params,
      sort: {
        column,
        direction: getTableSortDirection(params.sort.direction),
      },
    })
  }

  const removeFilter = (type: keyof Filters) => {
    if (type !== 'responseRate') {
      setTagFilters({
        ...tagfilters,
        [type]: [],
      })
      return
    }

    setTagFilters({ ...tagfilters, responseRate: null })
  }

  const nameMaxLength = useMemo(() => {
    if (windowWidth < 1300) {
      return 28
    }
    if (windowWidth < 1400) {
      return 30
    }
    if (windowWidth < 1550) {
      return 34
    }
    if (windowWidth < 1700) {
      return 38
    }
    if (windowWidth < 1800) {
      return 42
    }
    return 46
  }, [windowWidth])

  const columns = useMemo(() => {
    return () =>
      makeColumns({
        loadsmartDOT,
        nameMaxLength,
        infoOption,
        setInfoOption: setInfoOptionAndTriggerAnalytics,
        bidOption,
        setBidOption: setBidOptionAndTriggerAnalytics,
        rfpState,
        rfpRequestDeadline,
        bidClosedDialog,
        rfpId,
        removeCarriersFromBid,
        addCarriersToBid,
        openForEdition,
        resendInviteOpenModal: inviteSelectedDialog.openModal,
        setSelectedCarrier: (carrier) => {
          setSelectedCarriersToResendInvite([carrier])
        },
      })
  }, [
    loadsmartDOT,
    nameMaxLength,
    infoOption,
    setInfoOptionAndTriggerAnalytics,
    bidOption,
    setBidOptionAndTriggerAnalytics,
    rfpState,
    rfpRequestDeadline,
    bidClosedDialog,
    rfpId,
    removeCarriersFromBid,
    addCarriersToBid,
    openForEdition,
    inviteSelectedDialog.openModal,
  ])

  if (
    shouldDisplayNoCarriersInvited(
      rfpState,
      carrierFilterStatus,
      carrierInviteStatus,
      invites
    )
  ) {
    return <NoCarriersInvitedEmptyState />
  }

  const isInviteAllDisabled = shouldDisableInvites(rfpState, rfpRequestDeadline)

  const getInviteToBidLabel = () =>
    rfpState === RFP_STATE.DRAFT ? 'Add to Bid' : 'Invite/Re-invite carriers'

  const getInviteAllCarriersToBidLabel = () =>
    rfpState === RFP_STATE.DRAFT
      ? 'Add ALL CARRIERS to Bid'
      : 'Invite ALL CARRIERS'

  return (
    <>
      <Layout.Stack style={{ marginBottom: '40px' }}>
        <Layout.Stack>
          <Layout.Group align="center" justify="space-between">
            <Text variant="heading-md-bold" color="color-neutral-darker">
              Carriers
            </Text>
            <Layout.Group space="s">
              <TextField
                leading={<IconSearch width={16} height={16} />}
                trailing={
                  params.search !== '' ? (
                    <ClearSearchFieldButton
                      onClick={() => setParams({ ...params, search: '' })}
                    />
                  ) : null
                }
                scale="default"
                placeholder="Search"
                value={params.search}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setParams({ ...params, search: event.target.value })
                }}
              />
              <AddCarrierButton
                rfpState={rfpState}
                openForAddNewCarrier={openForAddNewCarrier}
              />
              <Button
                variant="secondary"
                onClick={() => setIsCarrierFilterOpen(true)}
                leading={
                  checkIfHasFilter(tagfilters) ? (
                    <IconCheck
                      width={16}
                      height={16}
                      fill={theme.colors.textAccent}
                    />
                  ) : (
                    <IconSettings width={16} height={16} />
                  )
                }
              >
                Filters
              </Button>
              <Dropdown>
                <Dropdown.Trigger>Actions</Dropdown.Trigger>
                <Dropdown.Menu align="end">
                  <Dropdown.Item
                    disabled={selectedCarriers?.length === 0}
                    onClick={() => {
                      setSelectedCarriersToResendInvite(selectedCarriers)
                      inviteSelectedDialog.openModal()
                      analytics.track(
                        AnalyticsEvent.RFPInviteOrReinviteCarrier,
                        AnalyticsEventTrigger.click,
                        {
                          'RFP - Carrier Indicators Inviting': `${COLUMN_PICKER_MAP[infoOption]} - ${COLUMN_PICKER_MAP[bidOption]}`,
                        }
                      )
                    }}
                  >
                    {getInviteToBidLabel()}
                  </Dropdown.Item>
                  <Dropdown.Item
                    disabled={isInviteAllDisabled}
                    onClick={() => {
                      inviteAllDialog.openModal()
                      analytics.track(
                        AnalyticsEvent.RFPInviteAllToBid,
                        AnalyticsEventTrigger.click,
                        {
                          entity: tagfilters.entity?.length > 0,
                          equipment: tagfilters.equipment?.length > 0,
                          region: tagfilters.region?.length > 0,
                          response_rate: tagfilters.responseRate !== null,
                          tags: tagfilters.tags.length > 0,
                          invite_state:
                            tagfilters.carrierInviteStates?.length > 0,
                          'RFP - Carrier Indicators Inviting': `${COLUMN_PICKER_MAP[infoOption]} - ${COLUMN_PICKER_MAP[bidOption]}`,
                        }
                      )
                    }}
                  >
                    {getInviteAllCarriersToBidLabel()}
                  </Dropdown.Item>
                  {rfpState === RFP_STATE.DRAFT && (
                    <Dropdown.Item
                      disabled={selectedCarriers?.length === 0}
                      onClick={() => {
                        removeCarriersFromBid({
                          rfpId,
                          invitesId: filterInvitedCarriers(
                            selectedCarriers
                          ).map((carrier) => carrier.invite?.id ?? ''),
                        })
                      }}
                    >
                      Remove From Bid
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </Layout.Group>
          </Layout.Group>
          {checkIfHasFilter(tagfilters) && (
            <Layout.Group align="center" space="xs">
              <Text variant="caption-bold" color="color-neutral">
                FILTERS APPLIED:
              </Text>
              <TagFilters filters={tagfilters} removeFilter={removeFilter} />
              <Button
                variant="tertiary"
                leading={<IconClose width={12} height={12} />}
                onClick={clearFilters}
              >
                Clear filters
              </Button>
            </Layout.Group>
          )}
        </Layout.Stack>
        <BaseTable
          isLoading={[
            isLoadingCarriers,
            isLoadingCarrierInvites,
            isRemovingCarriersFromBid,
            isAddingCarriersToBid,
          ].some((value) => Boolean(value) === true)}
          columns={columns}
          entries={carrierInvites ?? []}
          sortable
          selectable={[RFP_STATE.DRAFT, RFP_STATE.PUBLISHED].includes(rfpState)}
          updateSelection={(ids: Array<number>) => {
            const selected =
              carrierInvites?.filter((carrier) => ids.includes(carrier.id)) ??
              []

            setSelectedCarriers(selected as Carrier[])
          }}
          changeSorting={changeSorting}
          sort={params.sort}
          unsortableColumns={UNSORTABLE_COLUMNS}
          onRowClick={(_, row) => openForEdition(row.original)}
        />
        {pageCount > 1 && (
          <ReactPaginate
            data-testid="pagination"
            previousLabel="Previous"
            nextLabel="Next"
            breakLabel="..."
            breakClassName="break-me"
            pageCount={pageCount}
            forcePage={page}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName="pagination"
            activeClassName="active"
          />
        )}
      </Layout.Stack>
      <SideBar showToggle={false} isOpen={isCarrierFilterOpen}>
        <CarrierFilters
          onClose={() => setIsCarrierFilterOpen(false)}
          apply={(value) => {
            setTagFilters(value)
            setIsCarrierFilterOpen(false)
          }}
          clear={() => {
            clearFilters()
            setIsCarrierFilterOpen(false)
          }}
          tableFilters={tagfilters}
          rfpId={rfpId}
        />
      </SideBar>
      <BidDeadlineClosedDialog
        isOpen={bidClosedDialog.modalState}
        onClose={bidClosedDialog.closeModal}
        onConfirm={() => history.push(`/shipper/rfp/${rfpId}/edit`)}
      />
      <CarrierPanel
        carrier={selectedCarrier}
        setCarrierSelected={setSelectedCarrier}
        editOnly={false}
        addOnly={isOpenForAddNewCarrier}
        closePanel={closeCarrierPanel}
        rfpCurrency={rfpCurrency}
        loadsmartDOT={loadsmartDOT}
        onSuccess={() => {
          refetchCarriers()
        }}
        addNewCarrierSuccessTrackData={{
          origin: 'rfp',
          rfp: rfpUuid,
        }}
      />
      <InviteAllCarrierDialog
        carriersCount={data?.count ?? 0}
        hasFilters={checkIfHasFilter(tagfilters)}
        inviteAllCarriers={() => inviteAllCarriers({ rfpId, parsedTagFilter })}
        inviteAllDialog={inviteAllDialog}
        isInvitingAll={isInvitingAll}
        rfpState={rfpState}
      />
      <InviteSelectedDialog
        isOpen={inviteSelectedDialog.modalState}
        onClose={() => {
          setSelectedCarriersToResendInvite([])
          inviteSelectedDialog.closeModal()
        }}
        onConfirm={() => {
          analytics.track(
            AnalyticsEvent.RFPInviteOrReinviteCarrierConfirmation,
            AnalyticsEventTrigger.click,
            {
              new_carrier: countUninvitedCarriers(
                selectedCarriersToResendInvite
              ),
              resend: countSentOrResentInvites(selectedCarriersToResendInvite),
            }
          )
          addCarriersToBid({
            rfpId,
            carriers: selectedCarriersToResendInvite.map(
              (carrier) => carrier.id
            ),
          })
        }}
        rfpState={rfpState}
        carriers={selectedCarriersToResendInvite}
        isSendingInvite={isAddingCarriersToBid}
      />
    </>
  )
}

export default Carriers
