import Carriers from 'rfp/rfp-details/award-scenarios/Carriers'
import type { RequestForProposal } from 'rfp/rfp.types'
import { CURRENCY } from 'utils/constants'

interface AddCarriersProps {
  readonly rfp: RequestForProposal
  readonly invites: NewCarrierInvite[]
  readonly carrierInviteStatus: string
  readonly refetchCarrierInvites: () => Promise<unknown>
  readonly isLoadingCarrierInvites: boolean
}

const AddCarriers = ({
  rfp,
  invites,
  carrierInviteStatus,
  refetchCarrierInvites,
  isLoadingCarrierInvites,
}: AddCarriersProps) => {
  return (
    <Carriers
      rfpId={rfp.id}
      rfpUuid={rfp.uuid}
      rfpState={rfp.state as RfpState}
      rfpRequestDeadline={rfp.request_deadline}
      rfpCurrency={rfp?.currency ?? CURRENCY.USD}
      invites={invites}
      carrierInviteStatus={carrierInviteStatus}
      refetchCarrierInvites={refetchCarrierInvites}
      isLoadingCarrierInvites={isLoadingCarrierInvites}
    />
  )
}

export default AddCarriers
