import { IconDotsHorizontal } from '@loadsmart/icons'
import {
  Dropdown,
  DropdownContext,
  Popover,
  Radio,
} from '@loadsmart/loadsmart-ui'
import { useContext } from 'react'
import type { MouseEvent } from 'react'
import styled from 'styled-components'

const StyledTableHeaderDropdown = styled.div`
  > div {
    height: 16px;
  }
`

const IconDotsVertical = styled(IconDotsHorizontal)`
  transform: rotate(90deg);
`

const ActionTrigger = () => {
  const { toggle } = useContext(DropdownContext)

  return (
    <Popover.Reference>
      <IconDotsVertical
        onClick={(event: MouseEvent<HTMLButtonElement, MouseEvent>) => {
          event.stopPropagation()
          toggle()
        }}
        height={16}
        width={16}
        data-testid="dots-selector"
      />
    </Popover.Reference>
  )
}

interface TableHeaderDropdownProps {
  readonly options: Array<{ label: string; value: string }>
  readonly defaultValue: string
  readonly setValue: (value: string) => void
  readonly disabled?: boolean
}

export const TableHeaderDropdown = ({
  options,
  defaultValue,
  setValue,
  disabled = false,
}: TableHeaderDropdownProps) => {
  return (
    <StyledTableHeaderDropdown>
      <Dropdown disabled={disabled} align="end">
        <ActionTrigger />
        <Dropdown.Menu>
          {options.map(({ label, value }) => (
            <Dropdown.Item
              key={value}
              onClick={(
                event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
              ) => {
                event.stopPropagation()
                setValue(value)
              }}
            >
              <Radio
                scale="small"
                defaultChecked={value === defaultValue}
                name="radio-header-dropdown"
                value={value}
              >
                {label}
              </Radio>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </StyledTableHeaderDropdown>
  )
}
