import type { AxiosError } from 'axios'
import { toast } from 'react-toastify'

import { theme } from 'styles/theme'
import { clamp } from 'utils/numbers'

import { Msg } from './components/styles'

export function getAwardedVolume(proposals: LaneProposal[]) {
  return proposals.reduce(
    (total, { awarded_type, awarded_volume }) =>
      awarded_type === 'primary' ? total + awarded_volume : total,
    0
  )
}

export function calculateSuggestedVolume({
  proposals,
  lane,
  proposal,
}: {
  lane: Lane
  proposal: LaneProposal
  proposals: LaneProposal[]
}) {
  const awardedVolume = getAwardedVolume(proposals)
  return clamp(lane.volume - awardedVolume, 0, proposal.capacity)
}

export function getAverageRatePrice(proposals: LaneProposal[]) {
  const awardedProposals = proposals.filter(
    ({ awarded_type }) => !!awarded_type
  )
  if (!awardedProposals.length) {
    return 0
  }
  const add = (...elements: number[]) =>
    elements.reduce((total, n) => total + n, 0)
  const sum = add(...awardedProposals.map(({ flat_rate }) => Number(flat_rate)))
  return sum / awardedProposals.length
}

export function getBenchmarkColor(
  proposalCount: number,
  flatRate: number,
  targetRate: number,
  firstLowestBid: number | null,
  firstHighestBid: number | null
) {
  if (proposalCount <= 2) {
    if (flatRate < targetRate) {
      return theme.colors.success
    }
    if (flatRate > targetRate) {
      return theme.colors.danger
    }
  } else {
    if (flatRate === firstLowestBid) {
      return theme.colors.successLight
    }

    if (flatRate === firstHighestBid) {
      return theme.colors.dangerLight
    }

    if (flatRate < targetRate) {
      return theme.colors.success
    }

    if (flatRate > targetRate) {
      return theme.colors.danger
    }
  }
  return theme.colors.neutral
}

export function getFirstLowestBid(
  proposals: LaneProposal[],
  target_rate?: number
) {
  if (!target_rate) {
    return null
  }
  let firstLowest = null

  for (let i = 0; i < proposals.length; i++) {
    const value = Number(proposals[i].flat_rate)
    if (value < target_rate && (!firstLowest || firstLowest < value)) {
      firstLowest = value
    }
  }

  return firstLowest
}

export function getFirstHighestBid(
  proposals: LaneProposal[],
  target_rate?: number
) {
  if (!target_rate) {
    return null
  }
  let firstHighest = null

  for (let i = 0; i < proposals.length; i++) {
    const value = Number(proposals[i].flat_rate)
    if (value > target_rate && (!firstHighest || firstHighest > value)) {
      firstHighest = value
    }
  }

  return firstHighest
}

export const fileExtensions = {
  excel: 'xlsx',
  csv: 'csv',
  csv_rows: 'csv',
}

export const ToastMsg = (msg: string, description: string) => (
  <Msg>
    <p>{msg}</p>
    <p>{description}</p>
  </Msg>
)

export const publishRFPCallback = (error?: AxiosError) => {
  const notify = (level: 'success' | 'error', title: string, desc: string) =>
    toast[level](ToastMsg(title, desc))

  if (!error) {
    notify(
      'success',
      'The RFP is now published.',
      'Click on the right panel to follow responses for each lane.'
    )
    return
  }

  if (error?.response?.status === 422) {
    notify(
      'error',
      'Cannot publish RFP.',
      'Please check your lanes for missing details.'
    )
    return
  }

  notify(
    'error',
    'An error occurred while publishing your RFP.',
    'Please try again later.'
  )
}

export const copyToClipboard = (value: string | undefined) => {
  if (!value) {
    toast.error('Could not copy link to clipboard, please contact us.')
    return
  }
  navigator.clipboard.writeText(value)
  toast.success('Link copied to your clipboard')
}

export const publishRfpErrorMessages = {
  invalidOrigin: 'Invalid origin city.',
  invalidDest: 'Invalid destination city.',
  defaultError: 'Failed to publish RFP.',
}

export const publishRfpOnError = (errors: string[]) => {
  errors.forEach((msg) => {
    if (msg.includes('origin_city')) {
      toast.error(publishRfpErrorMessages.invalidOrigin)
    } else if (msg.includes('dest_city')) {
      toast.error(publishRfpErrorMessages.invalidDest)
    } else {
      toast.error(msg)
    }
  })
}
