import { IconTrophyFilled } from '@loadsmart/icons'
import { Tooltip, TooltipPosition } from '@loadsmart/loadsmart-ui'
import { getToken } from '@loadsmart/loadsmart-ui/dist/theming'
import type { ReactNode } from 'react'
import styled from 'styled-components'

import DotStatus from 'rfp/rfp-details/components/lane-status-indicator/DotStatus'
import { theme } from 'styles/theme'

interface CarrierAwardindicatorProps {
  readonly status: 'accepted' | 'denied' | 'waiting'
  readonly tooltip: string | ReactNode
}

const StateWrapper = styled.div`
  position: relative;
`

const Wrapper = styled.div`
  height: ${getToken('space-m')};
  margin-right: ${getToken('space-m')};
`

const DOT_STATUS_COLOR = {
  accepted: theme.colors.success,
  denied: theme.colors.dangerDark,
  waiting: theme.colors.mediumGray,
}

const CarrierAwardindicator = ({
  status,
  tooltip,
}: CarrierAwardindicatorProps) => {
  return (
    <Wrapper>
      <Tooltip position={TooltipPosition.Right} message={tooltip}>
        <StateWrapper>
          <DotStatus statusColor={DOT_STATUS_COLOR[status]} />
          <IconTrophyFilled
            fill={theme.colors.warning}
            color={theme.colors.warning}
            height={16}
            width={16}
            title={null}
            aria-label={tooltip}
            data-testid="carrierAwardStatus"
          />
        </StateWrapper>
      </Tooltip>
    </Wrapper>
  )
}

export default CarrierAwardindicator
