import type { TagVariant } from '@loadsmart/miranda-react'
import type { List, PropertyName } from 'lodash'
import { get, last } from 'lodash'

import { mapValueToRequestedAccessorialsPayload } from 'components/Accessorials'
import type { FreightInformation } from 'components/FreightInformation'
import {
  BOL_NUMBER_MAPPER,
  EQUIPMENT_LENGTH_MAPPER,
  EQUIPMENT_TYPE_MAPPER,
  MODE_MAPPER,
  PO_NUMBER_MAPPER,
  SHIPPER_REF_NUMBER_MAPPER,
  SO_NUMBER_MAPPER,
} from 'components/FreightInformation'
import type { TransientHandlingUnit } from 'components/HandlingUnitsManager/HandlingUnits.types'
import type {
  HazmatContact,
  HazmatItem,
  TransientHazmatContact,
} from 'components/HazmatInformation'
import { createTransientHazmatContact } from 'components/HazmatInformation'
import type {
  ShippingItem,
  TransientShippingItem,
} from 'components/ShippingItemsManager'
import {
  SHIPPING_ITEM_TO_PAYLOAD_MAPPER,
  createTransientShippingItem,
  hasHazmat,
} from 'components/ShippingItemsManager'
import type { TransientStop } from 'components/StopsManager'
import {
  adaptStopToNewShipmentPayload,
  createTransientStop,
} from 'components/StopsManager'
import type {
  ExcessiveLengthClassification,
  NewShipmentPayload,
} from 'shipments/shipments.services'
import type { AttributeMapper } from 'utils/AdapterFactory'
import AdapterFactory from 'utils/AdapterFactory'
import {
  STANDARD_PALLETS_LENGTH,
  STANDARD_PALLETS_WIDTH,
} from 'utils/constants'
import { resolveEquipmentType } from 'utils/equipmentTypeV2'
import { formatPhoneNumberForPayload } from 'utils/phone'
import type { ShipmentStatus } from 'utils/shipment'
import { getShipmentStatus } from 'utils/shipment'
import type { Transient } from 'utils/transient'
import { createTransient } from 'utils/transient'
import { resolveTransportationMode } from 'utils/transportationMode'

export type TransientShipment = Transient<
  {
    items: Array<TransientHandlingUnit>
    stops: Array<TransientStop>
  } & FreightInformation &
    HazmatContact
>

export function createTransientShipment(
  overrides?: Partial<TransientShipment>
): TransientShipment {
  return createTransient({
    po_number: '',
    bol_number: '',
    so_number: '',
    mode: resolveTransportationMode('ftl')!,
    equipment_type: resolveEquipmentType('drv')!,
    items: [],
    stops: [
      createTransientStop({ stop_type: 'pickup' }),
      createTransientStop({ stop_type: 'delivery' }),
    ],
    hazmatContact: createTransientHazmatContact(),
    ...overrides,
  })
}

export type PTLShipmentValues = {
  po_number?: string
  bol_number?: string
  so_number?: string
  mode: string
  equipment_type: string
  equipment_length?: string
  items: TransientShippingItem[]
  stops: TransientStop[]
}

export type TransientPTLShipment = Transient<PTLShipmentValues>

export function createTransientPTLShipment(
  overrides?: Partial<TransientPTLShipment>
): TransientPTLShipment {
  return createTransient({
    items: [
      createTransientShippingItem({
        length: String(STANDARD_PALLETS_LENGTH),
        width: String(STANDARD_PALLETS_WIDTH),
        package_type: 'std_pallets',
      }),
    ],
    stops: [
      createTransientStop({ stop_type: 'pickup' }),
      createTransientStop({ stop_type: 'delivery' }),
    ],
    po_number: '',
    bol_number: '',
    so_number: '',
    mode: resolveTransportationMode('ptl')!,
    equipment_type: resolveEquipmentType('drv')!,
    equipment_length: '',
    ...overrides,
  })
}

export type LTLShipmentValues = {
  po_number?: string
  bol_number?: string
  so_number?: string
  shipper_ref_number?: string
  mode: string
  equipment_type: string
  ltl_excessive_length_class?: ExcessiveLengthClassification | null
  accessorials: string[]
  requested_accessorials: string[]
  items: Transient<ShippingItem & HazmatItem>[]
  stops: TransientStop[]
  hazmat_contact?: TransientHazmatContact
}

export type TransientLTLShipment = Transient<LTLShipmentValues>

export function createTransientLTLShipment(
  overrides?: Partial<TransientLTLShipment>
): TransientLTLShipment {
  return createTransient({
    po_number: '',
    bol_number: '',
    so_number: '',
    mode: resolveTransportationMode('ltl')!,
    equipment_type: resolveEquipmentType('drv')!,
    ltl_excessive_length_class: null,
    accessorials: [],
    requested_accessorials: [],
    items: [
      createTransientShippingItem({
        length: String(STANDARD_PALLETS_LENGTH),
        width: String(STANDARD_PALLETS_WIDTH),
        package_type: 'std_pallets',
      }),
    ],
    stops: [
      createTransientStop({ stop_type: 'pickup' }),
      createTransientStop({ stop_type: 'delivery' }),
    ],
    hazmat_contact: createTransientHazmatContact(),
    ...overrides,
  })
}

const LTL_HAZMAT_CONTACT_TO_PAYLOAD_MAPPER: AttributeMapper[] = [
  {
    from: 'hazmat_contact',
    to: 'hazmat_contact_name',
    options: {
      onCondition: (_, shipment) => {
        return hasHazmat(get(shipment, 'items') ?? []) ? 'transform' : 'omit'
      },
    },
    transform(hazmatContact) {
      return get(hazmatContact, 'hazmat_contact_name') ?? ''
    },
  },
  {
    from: 'hazmat_contact',
    to: 'hazmat_phone_number',
    options: {
      onCondition: (_, shipment) => {
        return hasHazmat(get(shipment, 'items') ?? []) ? 'transform' : 'omit'
      },
    },
    transform(hazmatContact) {
      return formatPhoneNumberForPayload(
        get(hazmatContact, 'hazmat_phone_number') ?? ''
      )
    },
  },
]

const LTL_HAZMAT_ITEM_TO_PAYLOAD_MAPPER: AttributeMapper[] = [
  {
    from: 'hazmat_package_quantity',
    to: 'hazmat_package_quantity',
    options: {
      onCondition: (_, item) => {
        return hasHazmat(item) ? 'transform' : 'omit'
      },
    },
    transform(quantity) {
      return Number(quantity)
    },
  },
  {
    from: 'hazmat_package_type',
    to: 'hazmat_package_type',
    options: {
      onCondition: (_, item) => {
        return hasHazmat(item) ? 'transform' : 'omit'
      },
    },
  },
  {
    from: 'hazmat_shipping_name',
    to: 'hazmat_proper_shipping_name',
    options: {
      onCondition: (_, item) => {
        hasHazmat(item) //?
        return hasHazmat(item) ? 'transform' : 'omit'
      },
    },
  },
  {
    from: 'hazmat_un_number',
    to: 'un_number',
    options: {
      onCondition: (_, item) => {
        return hasHazmat(item) ? 'transform' : 'omit'
      },
    },
  },
  {
    from: 'hazmat_class',
    to: 'hazmat_class',
    options: {
      onCondition: (_, item) => {
        return hasHazmat(item) ? 'transform' : 'omit'
      },
    },
  },
  {
    from: 'hazmat_packing_group',
    to: 'hazmat_packing_group',
    options: {
      onCondition: (_, item) => {
        return hasHazmat(item) ? 'transform' : 'omit'
      },
    },
  },
]

export const adaptShippingItemToLTLShipmentPayload = AdapterFactory([
  ...SHIPPING_ITEM_TO_PAYLOAD_MAPPER,
  ...LTL_HAZMAT_ITEM_TO_PAYLOAD_MAPPER,
])

const BASE_LTL_SHIPMENT_TO_PAYLOAD_MAPPER: AttributeMapper[] = [
  PO_NUMBER_MAPPER,
  BOL_NUMBER_MAPPER,
  SO_NUMBER_MAPPER,
  SHIPPER_REF_NUMBER_MAPPER,
  MODE_MAPPER,
  EQUIPMENT_TYPE_MAPPER,
  ...LTL_HAZMAT_CONTACT_TO_PAYLOAD_MAPPER,
  {
    from: '',
    to: 'hazmat',
    transform(_, shipment) {
      return hasHazmat(shipment.items)
    },
  },
  {
    from: 'items',
    to: 'items',
    type: 'collection',
    transform(item) {
      return adaptShippingItemToLTLShipmentPayload(item)
    },
  },
  {
    from: 'stops',
    to: 'stops',
    type: 'collection',
    transform(stop, _, sourcePath) {
      const stop_index = Number(last(sourcePath as List<PropertyName>))

      return {
        ...adaptStopToNewShipmentPayload(stop),
        stop_index,
      }
    },
  },
]

export function createLTLShipmentToNewShipmentPayloadMapper({
  priceItemTypesEnabled,
}: {
  priceItemTypesEnabled: boolean
}): AttributeMapper[] {
  if (priceItemTypesEnabled) {
    return [
      ...BASE_LTL_SHIPMENT_TO_PAYLOAD_MAPPER,
      {
        from: 'requested_accessorials',
        to: 'requested_accessorials',
        transform(requested_accessorials) {
          return mapValueToRequestedAccessorialsPayload(requested_accessorials)
        },
      },
    ]
  }
  return [
    ...BASE_LTL_SHIPMENT_TO_PAYLOAD_MAPPER,
    { from: 'accessorials', to: 'accessorials' },
  ]
}

export function createLTLShipmentToNewShipmentPayloadAdapter(params: {
  priceItemTypesEnabled: boolean
}) {
  const LTLShipmentToPayloadMapper =
    createLTLShipmentToNewShipmentPayloadMapper(params)

  return AdapterFactory<NewShipmentPayload, LTLShipmentValues>(
    LTLShipmentToPayloadMapper
  )
}

// TODO: separate shipment utils and their stuff into separate files
export const adaptShippingItemToPTLShipmentPayload = AdapterFactory(
  SHIPPING_ITEM_TO_PAYLOAD_MAPPER
)

export function createPTLShipmentToNewShipmentPayloadMapper(): AttributeMapper[] {
  return [
    PO_NUMBER_MAPPER,
    BOL_NUMBER_MAPPER,
    SO_NUMBER_MAPPER,
    MODE_MAPPER,
    EQUIPMENT_TYPE_MAPPER,
    EQUIPMENT_LENGTH_MAPPER,
    {
      from: 'items',
      to: 'items',
      type: 'collection',
      transform(item) {
        return adaptShippingItemToPTLShipmentPayload(item)
      },
    },
    {
      from: 'stops',
      to: 'stops',
      type: 'collection',
      transform(stop, _, sourcePath) {
        const stop_index = Number(last(sourcePath as List<PropertyName>))

        return {
          ...adaptStopToNewShipmentPayload(stop),
          stop_index,
        }
      },
    },
  ]
}

export function createPTLShipmentToNewShipmentPayloadAdapter() {
  const PTLShipmentToPayloadMapper =
    createPTLShipmentToNewShipmentPayloadMapper()

  return AdapterFactory<NewShipmentPayload, PTLShipmentValues>(
    PTLShipmentToPayloadMapper
  )
}

export function getShipmentStatusTagVariant(status: ShipmentStatus) {
  const canonicalStatus = getShipmentStatus(status)

  const statusMap: Record<ShipmentStatus, TagVariant> = {
    accounting_review: 'neutral',
    archived: 'neutral',
    at_delivery: 'neutral',
    at_pickup: 'neutral',
    booked: 'success',
    canceled: 'danger',
    deleted: 'danger',
    en_route_delivery: 'neutral',
    en_route_pickup: 'neutral',
    expired: 'danger',
    imported: 'neutral',
    in_transit: 'neutral',
    new: 'neutral',
    quoting: 'neutral',
    tender_rejected: 'danger',
    tendering: 'neutral',
  }

  return statusMap[canonicalStatus?.value as ShipmentStatus]
}

function createShipmentToNewShipmentPayloadMapper(): AttributeMapper[] {
  return [
    MODE_MAPPER,
    EQUIPMENT_TYPE_MAPPER,
    {
      from: 'items',
      to: 'items',
      type: 'collection',
      transform(item) {
        return adaptShippingItemToPTLShipmentPayload(item)
      },
    },
    {
      from: 'stops',
      to: 'stops',
      type: 'collection',
      transform(stop, _, sourcePath) {
        const stop_index = Number(last(sourcePath as List<PropertyName>))

        return {
          ...adaptStopToNewShipmentPayload(stop),
          stop_index,
        }
      },
    },
  ]
}

export function createTransientShipmentToNewShipmentPayloadAdapter() {
  const ShipmentToPayloadMapper = createShipmentToNewShipmentPayloadMapper()

  return AdapterFactory<NewShipmentPayload, TransientShipment>(
    ShipmentToPayloadMapper
  )
}

export function canDeleteShipmentItemFromStop(
  shipment: TransientShipment,
  stop: TransientStop,
  checkCanRemoveHU: (handlingUnit: TransientHandlingUnit) => boolean
) {
  const itemsPickedUpInStop = shipment.items.filter(
    (item) => item.pickup_stop_index === stop.stop_index
  )
  return (
    stop.stop_type == 'pickup' &&
    itemsPickedUpInStop.length > 0 &&
    itemsPickedUpInStop.every((item) => checkCanRemoveHU(item))
  )
}
