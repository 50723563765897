import { Tooltip } from '@loadsmart/loadsmart-ui'
import styled from 'styled-components'

import RankedBadge from 'assets/icons/ranked-badge.svg'

const DisabledBadge = styled.div`
  filter: saturate(0);
  opacity: 0.5;
`

export default function RankedProposalBadge({
  isRanked,
}: {
  readonly isRanked: boolean
}) {
  return isRanked ? (
    <Tooltip message="Saved Rate">
      <img src={RankedBadge} alt="Saved Rate" />
    </Tooltip>
  ) : (
    <DisabledBadge>
      <img src={RankedBadge} alt="" />
    </DisabledBadge>
  )
}
