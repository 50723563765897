import type { TagProps } from '@loadsmart/loadsmart-ui'

import type { PusherConfig } from 'services/pusher/types'
import { getEnvSubdomain, getEnvVar } from 'utils/envVars'

export const BASE_URL =
  process.env.REACT_APP_API_ENV == 'local'
    ? 'http://localhost:8000'
    : `https://api.${getEnvSubdomain()}rfpguide.com`
export const LOADBOARD_BASE_URL = `https://carrier.${getEnvSubdomain()}loadsmart.com`
export const LOADSMART_API_URL = `https://api.${getEnvSubdomain()}loadsmart.com`

export const RFP_STATE = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
  CLOSED: 'closed',
  FINALIZED: 'finalized',
  ARCHIVED: 'archived',
  AWARDING: 'awarding',
}
export const LOADSMART = {
  dot: '2524523',
  email: 'rfp@loadsmart.com',
}

export const ENV = (process.env.REACT_APP_ENVIRONMENT || '').toLowerCase()
export const RELEASE_VERSION = (
  process.env.REACT_APP_RELEASE_VERSION || ''
).toLowerCase()
export const SENTRY_DSN = getEnvVar('SENTRY_DSN')
export const MIXPANEL_TOKEN = getEnvVar('MIXPANEL_TOKEN')
export const MIXPANEL_TOKEN_V2 = getEnvVar('MIXPANEL_TOKEN_V2')
export const GA_TRACKING_ID = getEnvVar('GA_TRACKING_ID')
export const GA_4_TRACKING_ID = getEnvVar('GA_4_TRACKING_ID')
export const GTM_ID = getEnvVar('GTM_ID')
export const HOTJAR_ID = +getEnvVar('HOTJAR_ID')
export const GOOGLE_RECAPTCHA_V2 = getEnvVar('GOOGLE_RECAPTCHA_V2')
export const ALLOWED_FILETYPES = ['.csv', 'text/csv']
export const SUPPORT_EMAIL = 'info@shipper.guide'
export const DATADOG_CLIENT_TOKEN = getEnvVar('DATADOG_CLIENT_TOKEN')
export const DATADOG_APPLICATION_ID = getEnvVar('DATADOG_APPLICATION_ID')
export const DATADOG_SERVICE_NAME = 'rfp-web'
export const STRIPE_PUBLISHABLE_KEY = getEnvVar('STRIPE_PUBLISHABLE_KEY')
export const DATA_INSIGHTS_URL = getEnvVar('DATA_INSIGHTS_URL')
export const FREIGHT_INSIGHTS_URL = getEnvVar('FREIGHT_INSIGHTS_URL')
export const COMPASS_API_KEY = getEnvVar('COMPASS_API_KEY')
export const BEAMER_PRODUCT_ID = getEnvVar('BEAMER_PRODUCT_ID')
export const SHIPMENT_MAP_GOOGLE_MAP_ID = getEnvVar(
  'SHIPMENT_MAP_GOOGLE_MAP_ID'
)
export const SHIPPER_GUIDE_URL = getEnvVar('SHIPPER_GUIDE_URL')
export const BIP_URL = getEnvVar('BIP_URL')
export const BIP_CLIENT_ID = getEnvVar('BIP_CLIENT_ID')
export const ANALYTICS_PORTAL_URL = getEnvVar('ANALYTICS_PORTAL')

export const CAPACITY_TIME_PERIOD_OPTIONS = [
  { value: 'daily', label: 'Daily' },
  { value: 'weekly', label: 'Weekly' },
  { value: 'monthly', label: 'Monthly' },
  { value: 'quarterly', label: 'Quarterly' },
  { value: 'yearly', label: 'Yearly' },
  { value: 'total_contract_volume', label: 'Full Contract Period' },
]

export const RATE_TYPE_OPTIONS = [
  {
    value: 'with_fuel',
    label: 'With Fuel',
  },
  {
    value: 'without_fuel',
    label: 'Without Fuel',
  },
  {
    value: 'all_in_rate',
    label: 'All-in-Rate',
  },
  {
    value: 'line_hall',
    label: 'Line haul',
  },
  {
    value: 'rate_per_mile',
    label: 'Rate-per-mile',
  },
  {
    value: 'rate_per_mile_fsc',
    label: 'Rate-per-mile + FSC',
  },
]

export const MAX_FILE_SIZE_MB = 5
export const MAX_NUMBER_OF_FILES = 3
/**
 * See https://www.rfc-editor.org/rfc/rfc3696.html and https://www.rfc-editor.org/errata/eid1003 for more info
 */
export const EMAIL_MAX_LENGTH = 254

export const EDITABLE_RFP_STATES = ['unpublished', 'draft']
export const PUBLISHED_RFP_STATES = [RFP_STATE.CLOSED, RFP_STATE.PUBLISHED]
export const FINALIZED_RFP_STATES = [RFP_STATE.FINALIZED, RFP_STATE.ARCHIVED]
export const DRAFT_RFP_STATES = [RFP_STATE.DRAFT]
export const ARCHIVED_RFP_STATES = [RFP_STATE.ARCHIVED]
export const ALL_RFP_STATES = [
  RFP_STATE.DRAFT,
  RFP_STATE.PUBLISHED,
  RFP_STATE.FINALIZED,
  RFP_STATE.CLOSED,
  RFP_STATE.AWARDING,
]

// cloudfront limti for timeout is 29s, we add 1s to account for connection speed
export const TIMEOUT = 30000

export const ACCOUNT_TYPES = {
  SHIPPER: 'Shipper',
  CARRIER: 'Carrier',
  BROKER: 'Broker',
}

export const DEFAULT_CONVERSION_RATE = '1'

export const CURRENCY = {
  USD: 'USD',
  CAD: 'CAD',
  MXN: 'MXN',
} as const

export const MULTI_CURRENCY_INPUT_MAP: {
  [key: string]: { [key: string]: string }
} = {
  [CURRENCY.USD]: { prefix: '$ ', placeholder: 'US$' },
  [CURRENCY.CAD]: { prefix: 'C$ ', placeholder: 'C$' },
  [CURRENCY.MXN]: { prefix: 'MX$ ', placeholder: 'MX$' },
}

export const DEFAULT_MULTI_CURRENCY_INPUT_MAP = {
  prefix: '$ ',
  placeholder: '$',
}

export const MODES_DICT: any = {
  full_truck_load: 'FTL',
  intermodal: 'IMDL',
  drayage: 'DRAY',
  less_than_truckload: 'LTL',
  partial_truckload: 'PTL',
  EXP: 'EXP',
}

export enum MODES {
  FTL = 'FTL',
  LTL = 'LTL',
  VLTL = 'VLTL',
  IMDL = 'IMDL',
  PTL = 'PTL',
  DRAY = 'DRAY',
  EXP = 'EXP',
}

export const RFP_FORM_FIELDS: string[] = [
  'bidPricing',
  'capacityTimePeriod',
  'contractEndDate',
  'contractStartDate',
  'shipperEmail',
  'shipperPhone',
  'saveContactInfo',
  'requestDeadline',
  'rfpTitle',
  'mode',
]

export const CARRIER_INVITE_STATES: { [key: string]: string } = {
  NOT_INVITED: 'not_invited',
  UNSUBSCRIBED: 'unsubscribed',
  SENT: 'sent',
  ACCEPTED: 'accepted',
  SUBMITTED: 'submitted',
  PENDING: 'pending',
  CHECK_CONTACT: 'check_contact',
  FINISHED: 'award_finished',
}

export const LANE_BID_STATUS: { [key: string]: string } = {
  UPCOMING: 'upcoming',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  ONGOING: 'ongoing',
}

export const CARRIER_RFP_STATUS: { [key: string]: CarrierRfpState } = {
  OPEN: 'open',
  SUBMITTED: 'submitted',
  RESULTS: 'results',
  CLOSED: 'closed',
}

export const CARRIER_INVITE_EMAIL_NOTIFICATION_STATES: {
  [key: string]: string
} = {
  SENT: 'sent',
  RECEIVED: 'received',
  VIEWED: 'viewed',
  OPENED: 'opened',
  DEFERRED: 'defer',
  HARD_BOUNCED: 'hard_bounce',
  SOFT_BOUNCED: 'soft_bounce',
  REJECTED: 'rejected',
  SPAM: 'spam',
  UNSUBSCRIBED: 'unsubscribe',
}

export const CARRIER_INVITE_EMAIL_NOTIFICATION_TEXT: {
  [key: string]: string
} = {
  [CARRIER_INVITE_EMAIL_NOTIFICATION_STATES.DEFERRED]:
    'The carrier email server has refused the delivery of the email',
  [CARRIER_INVITE_EMAIL_NOTIFICATION_STATES.HARD_BOUNCED]:
    'The carrier email address does not exist',
  [CARRIER_INVITE_EMAIL_NOTIFICATION_STATES.SOFT_BOUNCED]:
    'The carrier mailbox is full or inactive',
  [CARRIER_INVITE_EMAIL_NOTIFICATION_STATES.REJECTED]:
    'The carrier email may not exist, email was rejected',
  [CARRIER_INVITE_EMAIL_NOTIFICATION_STATES.SPAM]:
    'The carrier email was filtered as spam',
}

export const SPOT_QUOTE_CARRIER_INVITE_STATUS: {
  [key: string]: string
} = {
  WAITING_FOR_BIDS: 'waiting_for_bids',
  CHECK_EMAIL: 'check_email',
  UNSUBSCRIBED: 'unsubscribed',
  ACCEPTED: 'accepted',
  RATES_AVAILABLE: 'rates_available',
}

export const TIMEOUT_EXPIRATION = 29000

export const PUSHER_CONFIG: PusherConfig =
  process.env.NODE_ENV === 'test'
    ? {
        key: 'testKey',
        cluster: 'testCluster',
        channelAuthorization: {
          endpoint: `${BASE_URL}/web_sockets/auth`,
          transport: 'ajax',
        },
      }
    : {
        key: getEnvVar('PUSHER_KEY'),
        cluster: getEnvVar('PUSHER_CLUSTER'),
        channelAuthorization: {
          endpoint: `${BASE_URL}/web_sockets/auth`,
          transport: 'ajax',
        },
      }

export const PUSHER_DISCONNECTION_TIMEOUT = 5000

export const WS_EVENT_RFP_LANE_CHANGED = 'rfp:lane:changed'
export const WS_EVENT_RFP_PROPOSAL_ADDED = 'rfp:proposal:added'
export const WS_EVENT_RFP_PROPOSAL_DELETED = 'rfp:proposal:deleted'

export enum LANE_OVERVIEW_STATUS {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  ONGOING = 'ongoing',
}

export const TAG_STATUS_MAP: {
  [key in LaneOverviewStatus]: TagProps['variant']
} = {
  active: 'accent',
  ongoing: 'warning',
  inactive: 'default',
  upcoming: 'success',
  0: 'default',
}

export enum ACCOUNT_BUSINESS_TYPE {
  BROKERAGE = 'BROKERAGE',
  SAAS = 'SAAS',
  FBM = 'FBM',
  MTC = 'MTC',
}

export enum BID_TYPE {
  AWARD = 'award',
  RANK = 'rank',
}

export enum AWARD_TYPE {
  PRIMARY = 'primary',
  BACKUP = 'backup',
  RANK = 'rank',
}

export const FBM_PREFIX = '/fbm'

export const SHIPPER_HOME = '/'
export const SHIPPER_LOGIN = '/login'
export const FBM_SHIPPER_LOGIN = `${FBM_PREFIX}-login`

export const CARRIERS_PATH = [
  '/carrier',
  '/award-confirmation/',
  '/award-confirmation-submitted/',
  '/quotes/award-confirmation/',
  '/quotes/tender-confirmation/',
]

export const USER_CATEGORIES = {
  PAID_SHIPPER: 'paid_shipper',
  UNPAID_BROKERAGE_SHIPPER: 'unpaid_brokerage',
  UNPAID_CARRIER: 'unpaid_carrier',
  UNPAID_SHIPPER: 'unpaid_shipper',
} as const

export enum USER_TYPE {
  /**
   * Flatbed Messenger user
   */
  FBM = 'FBM',
  /**
   * Shipper Guide regular user
   * SaaS + Brokerage + MT Ops
   */
  SG = 'SG',
  /**
   * Managed transportation external user
   */
  MTC = 'MTC',
  /**
   * User that is exclusively a Supplier
   */
  SUPPLIER = 'SUPPLIER',
}

export enum USER_BUSINESS {
  /**
   * Standard (SaaS)
   */
  STD = 'STD',
  /**
   * Managed Transportation
   */
  MTC = 'MTC',
}

export enum USER_ROLE {
  ADMIN = 'admin',
  SUPERVISOR = 'supervisor',
  STANDARD = 'standard',
}

export const HELP_CENTER_LINK =
  'https://communityloadsmart.zendesk.com/hc/en-us/categories/14812397435027-Shippers'

export const LOCATION_MANAGEMENT_STORAGE_KEY = 'shipperguide:shipper-location'
export const LOCATION_MANAGEMENT_STORAGE_EVENT = 'shipperguide:location-change'
export const SELECTED_SHIPMENTS_STORAGE_KEY = 'selectedShipments'
export const SELECTED_SETTLEMENT_SHIPMENTS_STORAGE_KEY =
  'selectedSettlementShipments'

export const INCHES_IN_A_CUBIC_FEET = 1728

// source: src/screens/NewQuote/constants.ts
export const STANDARD_PALLETS_WIDTH = 40
export const STANDARD_PALLETS_LENGTH = 48

export const SHIPMENT_PROOF_OF_DELIVERY_ERROR =
  'Missing proof of delivery upload.'

export const SENTRY_IGNORED_ERROR_MESSAGES = [SHIPMENT_PROOF_OF_DELIVERY_ERROR]
