import { getHexColor } from '@loadsmart/miranda-react/dist/tokens'
import type { ListFulfillment } from 'fulfillments/domain/Fulfillment'
import { capitalize, defaultTo, isEmpty, max, min } from 'lodash'

import { plural } from 'utils/strings'

import type { LineConfig, PinConfig } from './PlanFulfillmentsMap.types'

export function getBounds(
  lines: LineConfig[] = []
): { lat: number; lng: number }[] | null {
  const lats = lines
    .reduce(
      (array, line) => [
        ...array,
        line.startPosition?.lat,
        line.endPosition?.lat,
      ],
      [] as (number | undefined)[]
    )
    .filter((lng) => lng !== undefined)
  const lngs = lines
    .reduce(
      (array, lane) => [
        ...array,
        lane.startPosition?.lng,
        lane.endPosition?.lng,
      ],
      [] as (number | undefined)[]
    )
    .filter((lng) => lng !== undefined)

  const minLat = min(lats)
  const minLng = min(lngs)
  const maxLat = max(lats)
  const maxLng = max(lngs)
  if (
    minLat != undefined &&
    minLng != undefined &&
    maxLat != undefined &&
    maxLng != undefined
  ) {
    return [
      { lat: minLat, lng: minLng },
      { lat: maxLat, lng: maxLng },
    ]
  }

  return null
}

export type FulfillmentStop = {
  city: string
  state: string
  latitude: string
  longitude: string
  facilityName: string
  facilityUUID: string
  stopType: 'pickup' | 'delivery'
}

export type ShipmentPlanPreviewStop = FulfillmentStop & {
  stopLabel: string
  pickupIndex?: number
  pickupFulfillmentsUUIDs: string[]
  deliveryIndex?: number
  deliveryFulfillmentsUUIDs: string[]
  canMoveUp?: boolean
  canMoveDown?: boolean
}

export type StopMapData = FulfillmentStop & {
  pendingFulfillments: ListFulfillment[]
  plannedFulfillments: ListFulfillment[]
  stopPlanIndex?: number
  stopType: 'delivery' | 'pickup'
}

export function getStopsMap(
  fulfillments: ListFulfillment[] = [],
  selectedFulfillmentsUUIDs: string[] = []
) {
  const stopsMap: Record<string, StopMapData> = {}

  fulfillments.forEach((fulfillment) => {
    if (!stopsMap[fulfillment.pickup_facility.uuid]) {
      stopsMap[fulfillment.pickup_facility.uuid] = {
        facilityName: fulfillment.pickup_facility.name,
        facilityUUID: fulfillment.pickup_facility.uuid,
        city: defaultTo(fulfillment.pickup_facility.city, ''),
        state: defaultTo(fulfillment.pickup_facility.state, ''),
        latitude: defaultTo(String(fulfillment.pickup_facility.latitude), ''),
        longitude: defaultTo(String(fulfillment.pickup_facility.longitude), ''),
        pendingFulfillments: [],
        plannedFulfillments: [],
        stopPlanIndex: undefined,
        stopType: 'pickup',
      }
    }

    if (selectedFulfillmentsUUIDs.includes(fulfillment.uuid)) {
      stopsMap[fulfillment.pickup_facility.uuid].plannedFulfillments.push(
        fulfillment
      )
    } else {
      stopsMap[fulfillment.pickup_facility.uuid].pendingFulfillments.push(
        fulfillment
      )
    }

    if (!stopsMap[fulfillment.delivery_facility.uuid]) {
      stopsMap[fulfillment.delivery_facility.uuid] = {
        facilityName: fulfillment.delivery_facility.name,
        facilityUUID: fulfillment.delivery_facility.uuid,
        city: defaultTo(fulfillment.delivery_facility.city, ''),
        state: defaultTo(fulfillment.delivery_facility.state, ''),
        latitude: defaultTo(String(fulfillment.delivery_facility.latitude), ''),
        longitude: defaultTo(
          String(fulfillment.delivery_facility.longitude),
          ''
        ),
        pendingFulfillments: [],
        plannedFulfillments: [],
        stopPlanIndex: undefined,
        stopType: 'delivery',
      }
    }

    if (selectedFulfillmentsUUIDs.includes(fulfillment.uuid)) {
      stopsMap[fulfillment.delivery_facility.uuid].plannedFulfillments.push(
        fulfillment
      )
    } else {
      stopsMap[fulfillment.delivery_facility.uuid].pendingFulfillments.push(
        fulfillment
      )
    }
  })

  return stopsMap
}

export function mapStopMapDataToPinConfig(stopData: StopMapData): PinConfig {
  const totalFulfillments =
    stopData.pendingFulfillments.length + stopData.plannedFulfillments.length

  // if stop has no fulfillments, no pin should be plotted
  if (totalFulfillments === 0) {
    return {
      id: stopData.facilityUUID,
    }
  }

  // if there are no coordinates, no pin should be plotted
  if (isEmpty(stopData.latitude) || isEmpty(stopData.longitude)) {
    return {
      id: stopData.facilityUUID,
    }
  }

  // if coordinates are invalid, no pin should be plotted
  if (isNaN(Number(stopData.latitude)) || isNaN(Number(stopData.longitude))) {
    return {
      id: stopData.facilityUUID,
    }
  }

  const title = capitalize(stopData.stopType)

  const subtitle =
    stopData.plannedFulfillments.length &&
    stopData.plannedFulfillments.length < totalFulfillments
      ? `${stopData.plannedFulfillments.length} of ${totalFulfillments} ${plural('fulfillment', totalFulfillments)}`
      : `${totalFulfillments} ${plural('fulfillment', totalFulfillments)}`

  return {
    id: stopData.facilityUUID,
    index:
      stopData.stopPlanIndex != undefined
        ? stopData.stopPlanIndex + 1
        : undefined,
    subtitle,
    position: {
      lat: Number(stopData.latitude),
      lng: Number(stopData.longitude),
    },
    title,
  }
}

export type LaneMapData = {
  id: string
  connectionLane?: boolean
  deliveryFacilityUUID: string
  deliveryLatitude: string | null
  deliveryLongitude: string | null
  laneInPlan?: boolean
  pickupFacilityUUID: string
  pickupLatitude: string | null
  pickupLongitude: string | null
  fulfillments: string[]
  redirectedFulfillments: string[]
}

export function addNewLanesFromPlan(
  lanesMap: Record<string, LaneMapData> = {},
  stopsMap: Record<string, StopMapData> = {},
  plannedStops: ShipmentPlanPreviewStop[] = []
) {
  if (plannedStops && plannedStops.length >= 2) {
    for (let idx = 0; idx < plannedStops.length - 1; idx++) {
      const startStop = plannedStops[idx]
      const endStop = plannedStops[idx + 1]
      const pickupKey = startStop.facilityUUID
      const deliveryKey = endStop.facilityUUID
      const key = `${pickupKey};${deliveryKey}`

      // if this is a new lane that hadn't be mapped from the PU-DEL of all fulfillments
      // then it was added because of a multi-stop route
      // for example, between the delivery of one other and the pickup of another
      if (!lanesMap[key]) {
        lanesMap[key] = {
          id: key,
          connectionLane: true,
          deliveryFacilityUUID: stopsMap[deliveryKey]?.facilityUUID,
          deliveryLatitude: stopsMap[deliveryKey]?.latitude,
          deliveryLongitude: stopsMap[deliveryKey]?.longitude,
          laneInPlan: true,
          pickupFacilityUUID: stopsMap[pickupKey]?.facilityUUID,
          pickupLatitude: stopsMap[pickupKey]?.latitude,
          pickupLongitude: stopsMap[pickupKey]?.longitude,
          fulfillments: [],
          redirectedFulfillments: [],
        }
      } else {
        // if the lane had been mapped from the PU-DEL of all fulfillments
        // then just flag it as in the plan
        lanesMap[key].laneInPlan = true
      }
    }
  }
}

export function getLanesMap(
  fulfillments: ListFulfillment[] = [],
  stopsMap: Record<string, StopMapData> = {},
  plannedStops: ShipmentPlanPreviewStop[] = [],
  selectedFulfillmentsUUIDs: string[] = []
) {
  const lanesMap: Record<string, LaneMapData> = {}

  const sequenceOfStops = plannedStops.map((stop) => stop.facilityUUID)

  // generate all PU-DEL lanes from the fulfillments
  fulfillments.forEach((fulfillment) => {
    const pickupKey = fulfillment.pickup_facility.uuid
    const deliveryKey = fulfillment.delivery_facility.uuid
    const key = `${pickupKey};${deliveryKey}`

    if (!lanesMap[key]) {
      lanesMap[key] = {
        id: key,
        deliveryFacilityUUID: stopsMap[deliveryKey]?.facilityUUID,
        deliveryLatitude: stopsMap[deliveryKey]?.latitude,
        deliveryLongitude: stopsMap[deliveryKey]?.longitude,
        laneInPlan: false,
        pickupFacilityUUID: stopsMap[pickupKey]?.facilityUUID,
        pickupLatitude: stopsMap[pickupKey]?.latitude,
        pickupLongitude: stopsMap[pickupKey]?.longitude,
        fulfillments: [],
        redirectedFulfillments: [],
      }
    }

    lanesMap[key].fulfillments.push(fulfillment.uuid)

    // if fulfillment is in the plan
    if (selectedFulfillmentsUUIDs.includes(fulfillment.uuid)) {
      const pickupPositionInPlan = sequenceOfStops.indexOf(
        fulfillment.pickup_facility.uuid
      )
      const deliveryPositionInPlan = sequenceOfStops.indexOf(
        fulfillment.delivery_facility.uuid
      )

      // If pickup is in the plan
      // and the next stop in the plan
      // is not the delivery
      // this fulfillment is 'redirected' to another stop
      // before reaching its delivery.
      // If it is the only fulfillment in the lane
      // the lane actually does not count anymore
      if (
        pickupPositionInPlan > -1 &&
        deliveryPositionInPlan !== pickupPositionInPlan + 1
      ) {
        lanesMap[key].redirectedFulfillments.push(fulfillment.uuid)
      }
    }
  })

  addNewLanesFromPlan(lanesMap, stopsMap, plannedStops)

  return lanesMap
}

export function mapLaneMapDataToLineConfig(laneData: LaneMapData): LineConfig {
  const coordinates = [
    laneData.deliveryLatitude,
    laneData.deliveryLongitude,
    laneData.pickupLatitude,
    laneData.pickupLongitude,
  ]

  // if there are no coordinates, no line should be plotted
  if (coordinates.filter(isEmpty).length) {
    return {
      id: laneData.id,
    }
  }

  // if coordinates are invalid, no line should be plotted
  if (coordinates.map(Number).filter(isNaN).length) {
    return {
      id: laneData.id,
    }
  }

  // if all fulfillments have been redirected to another lane
  if (
    laneData.redirectedFulfillments.length > 0 &&
    laneData.redirectedFulfillments.length === laneData.fulfillments.length
  ) {
    return {
      id: laneData.id,
    }
  }

  let color = getHexColor('color-neutral-60')
  let dashed = true

  if (laneData.connectionLane || laneData.laneInPlan) {
    color = getHexColor('color-success-60')
    dashed = false
  }

  return {
    id: laneData.id,
    color,
    dashed,
    endPosition: {
      lat: Number(laneData.deliveryLatitude),
      lng: Number(laneData.deliveryLongitude),
    },
    startPosition: {
      lat: Number(laneData.pickupLatitude),
      lng: Number(laneData.pickupLongitude),
    },
  }
}
