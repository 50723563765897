import { IconCheck } from '@loadsmart/icons'
import { Button, Card, Divider, Layout, Text } from '@loadsmart/miranda-react'
import { getToken, toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import styled from 'styled-components'

const StyledCardTitle = styled(Card.Title)`
  padding-bottom: ${getToken('spacing-6')};
`

const StyledDivider = styled(Divider)`
  padding-bottom: ${getToken('spacing-6')};
`

function getInProgressInvites(carrierInvites: NewCarrierInvite[]) {
  return carrierInvites.filter(
    ({ invite_status_label }) => invite_status_label === 'IN_PROGRESS'
  )
}

type RfpBidItemProps = {
  readonly carrierInvite: NewCarrierInvite
  readonly onAskToHurry: (carrierId: number) => void
}

function RfpBidItem({ carrierInvite, onAskToHurry }: RfpBidItemProps) {
  const { carrier, hurry_up_email_sent_at } = carrierInvite

  function handleOnHurryUp() {
    onAskToHurry(carrier.id)
  }

  return (
    <Layout.Group justify="space-between" gap="spacing-4">
      <Text variant="body-md">{carrier.name}</Text>
      {hurry_up_email_sent_at ? (
        <Layout.Group gap="spacing-1" align="center">
          <IconCheck color={toCSSValue('color-text-disabled')} width={12} />
          <Text variant="button-sm" color="color-text-disabled">
            Sent
          </Text>
        </Layout.Group>
      ) : (
        <Button variant="secondary" size="small" onClick={handleOnHurryUp}>
          Ask to hurry up
        </Button>
      )}
    </Layout.Group>
  )
}

export type BidsInProgressProps = {
  readonly carrierInvites: NewCarrierInvite[]
  readonly onRefetchCarrierInvites: () => void
}

export function RfpBidsInProgress({
  carrierInvites,
  onRefetchCarrierInvites,
}: BidsInProgressProps) {
  const invites = getInProgressInvites(carrierInvites)

  function handleOnAskToHurry(_carrierId: number) {
    // the request will be handled in a separate task
    onRefetchCarrierInvites()
  }

  return (
    <Card>
      <StyledCardTitle>Bids in progress</StyledCardTitle>
      <Card.Body>
        {invites.map((invite, index) => (
          <Layout.Stack key={invite.id} gap="spacing-6">
            <>
              <RfpBidItem
                carrierInvite={invite}
                onAskToHurry={handleOnAskToHurry}
              />
              {invites.length - 1 !== index && <StyledDivider />}
            </>
          </Layout.Stack>
        ))}
      </Card.Body>
    </Card>
  )
}
