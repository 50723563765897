export function getHighestBid(proposals: LaneProposal[]) {
  return Math.max(...proposals.map(({ flat_rate }) => Number(flat_rate)))
}

export function getLowestBid(proposals: LaneProposal[]) {
  return Math.min(...proposals.map(({ flat_rate }) => Number(flat_rate)))
}

export function getAverageBid(proposals: LaneProposal[]) {
  const add = (...elements: number[]) =>
    elements.reduce((total, n) => total + n, 0)
  const sum = add(...proposals.map(({ flat_rate }) => Number(flat_rate)))
  return sum / proposals.length
}
