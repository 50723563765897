import { Layout, Field, TextArea, Text, Card } from '@loadsmart/miranda-react'
import { useState } from 'react'
import { Controller } from 'react-hook-form'

import type { LaneNotesProps } from './types'

const NOTES_CHAR_LIMIT = 280

export default function LaneNotesSection({
  control,
  setValue,
}: LaneNotesProps) {
  const [fieldLength, setFieldLength] = useState(0)

  const handleChange = (event: any) => {
    if (event.target.value !== fieldLength) {
      const value = event.target.value
      setFieldLength(value.length)
      setValue('notes', value)
    }
  }

  return (
    <Layout.Box paddingB="spacing-4" padding="none">
      <Card collapsible>
        <Card.Title>Notes</Card.Title>
        <Card.Divider />
        <Card.Body>
          <Field data-testid="notes" style={{ flex: '1' }}>
            <Controller
              name="notes"
              control={control}
              render={({ field }) => {
                const { ref: _, ...rest } = field

                return (
                  <>
                    <TextArea
                      {...rest}
                      onInput={handleChange}
                      data-testid="notesInput"
                      placeholder="Write your notes..."
                      maxLength={NOTES_CHAR_LIMIT}
                    />
                    <Layout.Group style={{ justifyContent: 'space-between' }}>
                      <Text color="color-text-tertiary" variant="body-sm">
                        Notes will be shared with invited carriers.
                      </Text>
                      <Text
                        variant="body-sm"
                        color={
                          fieldLength === NOTES_CHAR_LIMIT
                            ? 'color-danger-60'
                            : 'color-text-tertiary'
                        }
                      >
                        {fieldLength}/{NOTES_CHAR_LIMIT}
                      </Text>
                    </Layout.Group>
                  </>
                )
              }}
            />
          </Field>
        </Card.Body>
      </Card>
    </Layout.Box>
  )
}
