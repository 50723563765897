import { Tabs } from '@loadsmart/loadsmart-ui'
import { useState } from 'react'
import type { Dispatch, ReactNode, SetStateAction } from 'react'
import type { UseQueryResult } from 'react-query'

import { useCurrentUser } from '_shared_/user/useCurrentUser'
import RFPLimitBanner from 'rfp/components/rfp-limit-banner'
import type { QueryParams } from 'rfp/components/table/laneParams'
import type { UploadLanesSuccessResponse } from 'rfp/rfp-details/types'
import type { RequestForProposal } from 'rfp/rfp.types'
import { USER_CATEGORIES } from 'utils/constants'

import AddCarriers from '../tabs/AddCarriers'
import AddLanes from '../tabs/AddLanes'

const TABS = {
  ADD_LANES: 'add-lanes',
  ADD_CARRIERS: 'add-carriers',
}

interface RankDraftProps {
  readonly rfp: RequestForProposal
  readonly invites: NewCarrierInvite[]
  readonly carrierInviteStatus: string
  readonly refetchCarrierInvites: () => Promise<unknown>
  readonly isLoadingCarrierInvites: boolean
  readonly query: UseQueryResult<PaginatedResult, unknown>
  readonly unrankedLanesSearchQuery: string
  readonly setLaneSearchQuery: (query: string) => void
  readonly laneCsvTemplateButtonProps: {
    onClick: () => void
    disabled: boolean
    isDownloading: boolean
  }
  readonly addLanesButtonProps: {
    onClick: () => void
    disabled: boolean
  }
  readonly showLaneDetailsOrRoutingGuide: (lane: Lane) => void
  readonly showRecommendedCarriersSidebar: (lane: Lane) => void
  readonly params: QueryParams
  readonly setParams: Dispatch<SetStateAction<QueryParams>>
  readonly remainingRfpQuota: number
  readonly carrierInvitesMaxQuota: number
  readonly addLanesButton: ReactNode
  readonly importLanesButton: ReactNode
  readonly onSuccessUploadLane: (lanes: UploadLanesSuccessResponse) => void
  readonly onErrorUploadLane: (start?: number, errorType?: string) => void
}

const RankDraft = ({
  rfp,
  invites,
  carrierInviteStatus,
  refetchCarrierInvites,
  isLoadingCarrierInvites,
  query,
  unrankedLanesSearchQuery,
  setLaneSearchQuery,
  laneCsvTemplateButtonProps,
  addLanesButtonProps,
  showLaneDetailsOrRoutingGuide,
  showRecommendedCarriersSidebar,
  params,
  setParams,
  remainingRfpQuota,
  carrierInvitesMaxQuota,
  addLanesButton,
  importLanesButton,
  onErrorUploadLane,
  onSuccessUploadLane,
}: RankDraftProps) => {
  const { user } = useCurrentUser()
  const isPaidUser = user?.user_category === USER_CATEGORIES.PAID_SHIPPER

  const [tab, setTab] = useState(TABS.ADD_LANES)

  return (
    <Tabs
      className="miranda-tabs"
      activeTab={tab}
      onTabChange={(newTab: string) => setTab(newTab)}
    >
      <Tabs.Items>
        <Tabs.Item name={TABS.ADD_LANES}>
          Lanes
          <>
            &nbsp; &bull; &nbsp;
            {query.data?.count ?? 0}
          </>
        </Tabs.Item>
        <Tabs.Item name={TABS.ADD_CARRIERS}>
          Carriers
          <>
            &nbsp; &bull; &nbsp;
            {/*@TODO: Review this if we paginate the invite*/}
            {invites && invites.length}
          </>
        </Tabs.Item>
      </Tabs.Items>
      {!isPaidUser && (
        <RFPLimitBanner
          remainingRfpQuota={remainingRfpQuota}
          carrierInvitesMaxQuota={carrierInvitesMaxQuota}
        />
      )}
      <Tabs.Panels>
        <Tabs.Panel name={TABS.ADD_LANES}>
          <AddLanes
            addLanesButtonProps={addLanesButtonProps}
            laneCsvTemplateButtonProps={laneCsvTemplateButtonProps}
            params={params}
            query={query}
            rfp={rfp}
            setLaneSearchQuery={setLaneSearchQuery}
            setParams={setParams}
            showLaneDetailsOrRoutingGuide={showLaneDetailsOrRoutingGuide}
            showRecommendedCarriersSidebar={showRecommendedCarriersSidebar}
            unrankedLanesSearchQuery={unrankedLanesSearchQuery}
            addLanesButton={addLanesButton}
            importLanesButton={importLanesButton}
            onErrorUploadLane={onErrorUploadLane}
            onSuccessUploadLane={onSuccessUploadLane}
          />
        </Tabs.Panel>
        <Tabs.Panel name={TABS.ADD_CARRIERS}>
          <AddCarriers
            rfp={rfp}
            carrierInviteStatus={carrierInviteStatus}
            invites={invites}
            refetchCarrierInvites={refetchCarrierInvites}
            isLoadingCarrierInvites={isLoadingCarrierInvites}
          />
        </Tabs.Panel>
      </Tabs.Panels>
    </Tabs>
  )
}

export default RankDraft
