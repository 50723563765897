import {
  IconSearch as IconSearchBase,
  IconCloseCircle as IconCloseBase,
} from '@loadsmart/icons'
import styled from 'styled-components'

export const Option = styled.div`
  color: ${({ theme }) => theme.colors.backgroundSecondary};
  cursor: pointer;
  display: grid;
  grid-template-columns: 4fr 1fr 1fr;
  padding: 12px 10px;

  &:hover,
  &--is-focused {
    background-color: ${({ theme }) => theme.colors.backgroundLight};
  }

  &--is-selected {
    background-color: ${({ theme }) => theme.colors.backgroundSecondary};
    color: ${({ theme }) => theme.colors.white};

    &:hover {
      color: ${({ theme }) => theme.colors.backgroundSecondary};
    }
  }
`

export const OptionHeader = styled.div`
  background-color: ${({ theme }) => theme.colors.light};
  color: ${({ theme }) => theme.colors.backgroundSecondary};
  display: grid;
  font-size: 10px;
  font-weight: 700;
  grid-template-columns: 4fr 1fr 1fr;
  padding: 8px 10px;
  text-transform: uppercase;
`

export const Container = styled.div`
  font-size: 14px;
  font-weight: 500;
  padding: 2px;
  position: relative;
  text-transform: none;

  .autocomplete__control {
    border: 1px solid ${({ theme }) => theme.colors.neutralLighter};
    border-radius: 30px;
    cursor: text;
    height: 35px;
    min-height: auto;
    padding: 0 25px;
    background-color: ${({ theme }) => theme.colors.neutralLighter};
    color: ${({ theme }) => theme.colors.neutral};

    &:hover {
      border: 1px solid ${({ theme }) => theme.colors.backgroundLightActive};
    }

    &--is-focused,
    &--is-focused:hover {
      border: 1px solid ${({ theme }) => theme.colors.main};
      box-shadow: ${({ theme }) => theme.shadows.shadow02};
    }
  }

  .autocomplete__menu {
    padding: 2px;
  }

  .autocomplete__menu-list {
    padding: 0;
  }
`

const closeIconSize = 18

export const IconClose = styled(IconCloseBase).attrs({
  height: closeIconSize,
  width: closeIconSize,
})`
  color: ${({ theme }) => theme.colors.backgroundMediumGray};
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: calc(50% - ${closeIconSize}px / 2);
`

const searchIconSize = 22

export const IconSearch = styled(IconSearchBase).attrs({
  height: searchIconSize,
  width: searchIconSize,
})`
  color: ${({ theme }) => theme.colors.neutralDark};
  position: absolute;
  top: calc(50% - ${searchIconSize}px / 2);
  left: 10px;
  opacity: 0.5;
`
