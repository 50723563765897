import { Checkbox, Layout, LoadingDots, Text } from '@loadsmart/loadsmart-ui'
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import type { MutableRefObject, Ref, MouseEvent, ChangeEvent } from 'react'
import { useTable, useSortBy, useRowSelect } from 'react-table'

import { SortingIndicator, Th } from 'components/TableV3'
import {
  Wrapper,
  Table,
  THead,
  TBody,
  Tr,
  Td,
  SelectAllActionWrapper,
} from 'components/TableV3/styles'
import type { SelectConfig } from 'components/TableV3/types'

import usePrevious from './usePrevious'

interface Props {
  readonly indeterminate?: boolean
  readonly disabled?: boolean
}

// Taken from: https://github.com/TanStack/react-table/discussions/1989#discussioncomment-1488
const useCombinedRefs = (...refs: any[]): MutableRefObject<any> => {
  const targetRef = useRef()

  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) {
        return
      }

      if (typeof ref === 'function') {
        ref(targetRef.current)
      } else {
        ref.current = targetRef.current
      }
    })
  }, [refs])

  return targetRef
}

// Taken from: https://react-table.tanstack.com/docs/api/useRowSelect
const IndeterminateCheckbox = forwardRef<HTMLInputElement, Props>(
  function TableCheckbox(
    { indeterminate, disabled = false, ...rest },
    ref: Ref<HTMLInputElement>
  ) {
    const defaultRef = useRef(null)
    const combinedRef = useCombinedRefs(ref, defaultRef)

    useEffect(() => {
      if (combinedRef?.current) {
        combinedRef.current.indeterminate = indeterminate ?? false
      }
    }, [combinedRef, indeterminate])

    return (
      <div style={{ display: 'flex' }} ref={combinedRef}>
        <Checkbox
          className="text-left"
          disabled={disabled}
          {...rest}
          onClick={(e) => e.stopPropagation()}
        />
      </div>
    )
  }
)

function CellCheckbox({ row }: { readonly row: Row }) {
  return (
    <IndeterminateCheckbox
      {...row.getToggleRowSelectedProps()}
      disabled={row.original.isRowDisabled}
    />
  )
}

function HeaderCheckbox({
  getToggleAllRowsSelectedProps,
  rows: tableRows,
}: any) {
  const enabledRows = tableRows.filter(
    (row: Row) => !row.original.isRowDisabled
  )

  const overridedToggleAllRowSelectedProps = () => {
    return {
      ...getToggleAllRowsSelectedProps(),
      onChange: (event: ChangeEvent<HTMLInputElement>) =>
        enabledRows.forEach((row: any) =>
          row.toggleRowSelected(event.target.checked)
        ),
      checked: enabledRows.every((row: any) => row.isSelected),
    }
  }

  return (
    <IndeterminateCheckbox
      {...overridedToggleAllRowSelectedProps()}
      disabled={enabledRows.length === 0}
    />
  )
}

type Row = {
  original: {
    isRowDisabled?: boolean
  }
  getToggleRowSelectedProps: () => any
}

export default function BaseTable({
  columns,
  entries,
  initialState = {},
  sortable = true,
  isLoading = false,
  selectable = true,
  selectConfig,
  onRowClick,
  changeSorting,
  sort,
  updateSelection,
  unsortableColumns = ['actions', 'vs', 'state', 'selection'],
}: {
  readonly columns: any
  readonly entries: Array<any>
  readonly initialState?: any
  readonly sortable?: boolean
  readonly pagination?: boolean
  readonly paginationHandler?: (page: number) => void
  readonly pageSize?: number
  readonly resultsCount?: number
  readonly isLoading?: boolean
  readonly selectable?: boolean
  readonly selectConfig?: SelectConfig
  readonly onRowClick?: (arg0: MouseEvent, arg1: any) => void
  readonly changeSorting?: (arg0: string) => void
  readonly sort?: { column: string; direction: string }
  readonly updateSelection?: (selection: any) => void
  readonly unsortableColumns?: Array<string>
}) {
  const tableColumns = useMemo(() => columns(), [columns])

  const {
    headerGroups,
    allColumns,
    rows,
    getTableProps,
    getTableBodyProps,
    prepareRow,
    selectedFlatRows,
    isAllRowsSelected,
    toggleAllRowsSelected,
  } = useTable(
    {
      columns: tableColumns,
      data: entries,
      initialState,
      disableSortRemove: true,
      autoResetSortBy: false,
      disableSortBy: !sortable,
    },
    useSortBy,
    useRowSelect,
    (hooks) => {
      if (selectable) {
        hooks.visibleColumns.push((visibleColumns) => [
          {
            id: 'selection',
            className: 'table-checkbox',
            Header: HeaderCheckbox,
            Cell: CellCheckbox,
          },
          ...visibleColumns,
        ])
      }
    }
  )

  const [countSelection, setCountSelection] = useState(
    selectedFlatRows.length ?? 0
  )

  const previousCountSelection: number = usePrevious<number>(countSelection)

  useEffect(
    () => setCountSelection(selectedFlatRows.length ?? 0),
    [selectedFlatRows]
  )

  // Why? This was needed to prevent infinite renders, since we need to push this value up in the
  // tree but just when the count changes
  useEffect(() => {
    if (selectable && countSelection !== previousCountSelection) {
      updateSelection?.(selectedFlatRows.map((row: any) => row.original.id))
    }
  }, [
    countSelection,
    previousCountSelection,
    selectedFlatRows,
    updateSelection,
    selectable,
  ])

  const hasSelectConfig = selectable && selectConfig

  return (
    <Wrapper data-testid="table">
      <Table {...getTableProps()} bordered={false}>
        <THead>
          {hasSelectConfig && selectedFlatRows.length > 0 ? (
            <>
              <Tr
                {...headerGroups[0].getHeaderGroupProps()}
                style={{
                  width: '100%',
                }}
              >
                <Th colSpan={allColumns.length + 1} className="text-left">
                  <SelectAllActionWrapper>
                    <Checkbox
                      className="text-left"
                      checked={isAllRowsSelected}
                      onChange={() => toggleAllRowsSelected(false)}
                      onClick={(e) => e.stopPropagation()}
                    />
                    {selectConfig?.actions.map((Action, key) => (
                      <Action
                        key={key}
                        selected={selectedFlatRows.map(
                          (row: any) => row.original.id
                        )}
                      />
                    ))}
                  </SelectAllActionWrapper>
                </Th>
              </Tr>
              {isAllRowsSelected && (
                <Tr>
                  <Th
                    style={{
                      paddinBottom: '8px',
                      paddingLeft: 0,
                      paddingRight: 0,
                    }}
                    colSpan={allColumns.length + 1}
                  >
                    <Layout.Box
                      background="neutral-lightest"
                      borderColor="neutral-lighter"
                      borderRadius="s"
                      borderWidth="thin"
                      padding="m"
                    >
                      <Text variant="caption" color="color-neutral-darker">
                        All <b>{selectedFlatRows.length}</b> lanes on this page
                        are selected.
                      </Text>
                    </Layout.Box>
                  </Th>
                </Tr>
              )}
            </>
          ) : (
            <>
              {headerGroups.map((headerGroup) => {
                const { key, ...headGroupProps } =
                  headerGroup.getHeaderGroupProps()

                return (
                  <Tr key={key} {...headGroupProps}>
                    {headerGroup.headers.map((column: any) => {
                      const { key: headerPropsKey, ...headerProps } =
                        column.getHeaderProps(
                          sortable ? column.getSortByToggleProps() : undefined
                        )
                      return (
                        <Th
                          key={headerPropsKey}
                          {...headerProps}
                          className={column.className}
                          onClick={() =>
                            // Since we aren't allowing sorting by these columns (at least for now),
                            // we need this validation to skip sorting when needed.
                            changeSorting &&
                            !unsortableColumns.includes(column.id) &&
                            changeSorting(column.id)
                          }
                          style={{ position: 'relative' }}
                        >
                          <Layout.Group
                            space="none"
                            align="center"
                            style={{ flexWrap: 'nowrap' }}
                          >
                            {column.render('Header')}
                            {sort && sort.column === column.id && (
                              <SortingIndicator direction={sort.direction} />
                            )}
                          </Layout.Group>
                        </Th>
                      )
                    })}
                  </Tr>
                )
              })}
            </>
          )}
        </THead>
        <TBody {...getTableBodyProps()}>
          {isLoading ? (
            <Tr>
              <Td
                data-testid="table-loading"
                colSpan={allColumns.length + 1}
                className="text-center"
              >
                <LoadingDots />
              </Td>
            </Tr>
          ) : (
            rows.map((row) => {
              prepareRow(row)
              const { key, ...rowProps } = row.getRowProps()

              const onClick = (event: MouseEvent) => onRowClick?.(event, row)

              return (
                <Tr
                  key={key}
                  {...rowProps}
                  isSelected={row.isSelected}
                  onClick={(event: MouseEvent) => onClick(event)}
                  notStripped={false}
                >
                  {row.cells.map((cell: any) => {
                    const { key: cellPropsKey, cellProps } = cell.getCellProps()
                    return (
                      <Td
                        key={cellPropsKey}
                        {...cellProps}
                        className={cell.column.className}
                      >
                        {cell.render('Cell')}
                      </Td>
                    )
                  })}
                </Tr>
              )
            })
          )}
        </TBody>
      </Table>
    </Wrapper>
  )
}
