import { EmptyState, Layout } from '@loadsmart/miranda-react'

import CityIllustration from 'assets/illustrations/city.svg'
import NoResultsIllustration from 'assets/illustrations/no-results.svg'

export type ListFulfillmentsTableEmptyStateProps = Readonly<{
  count?: number
  isLoading?: boolean
}>

export function ListFulfillmentsTableEmptyState({
  count,
  isLoading,
}: ListFulfillmentsTableEmptyStateProps) {
  if (isLoading || count == null || count > 0) {
    return null
  }

  return (
    <Layout.Stack align="center" style={{ paddingTop: '96px' }}>
      <EmptyState data-testid="fulfillments-list-empty-state">
        <EmptyState.Illustration>
          <img
            role="presentation"
            alt=""
            src={CityIllustration}
            width="160px"
          />
        </EmptyState.Illustration>
        <EmptyState.Header>Create your first fulfillment</EmptyState.Header>
        <EmptyState.Message>
          Looks like you haven&apos;t added any fulfillments yet. Let&apos;s get
          started!
        </EmptyState.Message>
      </EmptyState>
    </Layout.Stack>
  )
}

export function ListFulfillmentsTableEmptyStateWithFilters({
  count,
  isLoading,
}: ListFulfillmentsTableEmptyStateProps) {
  if (isLoading || count == null || count > 0) {
    return null
  }

  return (
    <Layout.Stack align="center" style={{ paddingTop: '96px' }}>
      <EmptyState data-testid="fulfillments-list-empty-state">
        <EmptyState.Illustration>
          <img
            role="presentation"
            alt=""
            src={NoResultsIllustration}
            width="160px"
          />
        </EmptyState.Illustration>
        <EmptyState.Header>No fulfillments found</EmptyState.Header>
        <EmptyState.Message>
          Change the terms of your search and try again
        </EmptyState.Message>
      </EmptyState>
    </Layout.Stack>
  )
}
