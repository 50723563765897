import { Layout, ToggleGroup } from '@loadsmart/loadsmart-ui'
import { useMemo, useState } from 'react'
import type { Dispatch, SetStateAction } from 'react'
import ReactPaginate from 'react-paginate'
import type { UseQueryResult } from 'react-query'

import NoResultsIllustration from 'assets/illustrations/no-results.svg'
import {
  Container,
  EmptyLanesContainer,
  EmptyLanesImage,
} from 'components/LaneManagement/LanesTableV3/styles'
import usePagination from 'hooks/usePagination'
import {
  getTablePageOffset,
  getTableSortDirection,
  useLaneIdMaxLength,
} from 'rfp/components/table/tableUtils'
import BaseTable from 'rfp/rfp-details/award-scenarios/BaseTable'
import {
  CenteredText,
  FilterWrapper,
} from 'rfp/rfp-details/award-scenarios/styles'
import type { RequestForProposal } from 'rfp/rfp.types'
import { RFP_STATE } from 'utils/constants'
import { isMultiCurrency } from 'utils/currency'

import makeLanesColumns from './makeLanesColumns'

enum RadioOptions {
  LOWEST = 'lowest_bid',
  AVERAGE = 'average_bid',
  HIGHEST = 'highest_bid',
  LAST_AWARD = 'last_award',
  TARGET_RATE = 'target_rate',
  SPOT_MARKET = 'market_rate',
  SHORT_CONTRACT = 'contract_rate',
}

const RateOptions = [
  { value: 'market_rate', label: 'Market Benchmark' },
  { value: 'target_rate', label: 'Target Rate' },
  { value: 'last_award', label: 'Last Award' },
]

const MultiCurrencyDraftRateOptions = RateOptions.filter((option) =>
  [RadioOptions.TARGET_RATE, RadioOptions.LAST_AWARD].includes(
    option.value as RadioOptions
  )
)

const MultiCurrencyPublishedRateOptions = RateOptions.filter(
  (option) => option.value === RadioOptions.LAST_AWARD
)

interface QueryParams {
  page: number
  offset: number
  limit: number
  sort: {
    column: string
    direction: string
  }
  filterByRankedLanes: boolean | undefined
}

interface LanesProps {
  readonly rfp: RequestForProposal
  readonly showLaneDetailsOrRoutingGuide: (lane: Lane) => void
  readonly showRecommendedCarriersSidebar: (lane: Lane) => void
  readonly query: UseQueryResult<PaginatedResult, unknown>
  readonly params: QueryParams
  readonly setParams: Dispatch<SetStateAction<QueryParams>>
}

const LIMIT = 50

const defaultParams = {
  offset: 0,
  limit: LIMIT,
  sort: {
    column: 'lane_id',
    direction: 'asc',
  },
}

type RankedLanesFilterOptions = '' | 'pending' | 'ranked'

const Lanes = ({
  rfp,
  showLaneDetailsOrRoutingGuide,
  showRecommendedCarriersSidebar,
  query,
  params,
  setParams,
}: LanesProps) => {
  const displayMultiCurrency = isMultiCurrency(rfp.currency)
  const [bidOption, setRadioOptionOne] = useState<string>(RadioOptions.LOWEST)
  const [rateOption, setRadioOptionTwo] = useState<string>(
    displayMultiCurrency || rfp.state === RFP_STATE.DRAFT
      ? RadioOptions.LAST_AWARD
      : RadioOptions.SPOT_MARKET
  )

  const getAvailableRateOptions = () => {
    const multiCurrencyOptions =
      rfp.state === RFP_STATE.DRAFT
        ? MultiCurrencyDraftRateOptions
        : MultiCurrencyPublishedRateOptions

    return displayMultiCurrency ? multiCurrencyOptions : RateOptions
  }

  const availableRateOptions = getAvailableRateOptions()

  const { pageCount, page } = usePagination(
    LIMIT,
    query.data?.count,
    params.page
  )

  const handlePageChange = (selectedItem: { selected: number }) => {
    setParams({
      ...params,
      page: selectedItem.selected,
      offset: getTablePageOffset(selectedItem.selected, defaultParams.offset),
    })
  }

  const changeSorting = (column: string) => {
    setParams({
      ...params,
      sort: {
        column,
        direction: getTableSortDirection(params.sort.direction),
      },
    })
  }

  const changeFilter = (option: RankedLanesFilterOptions) => {
    const getFilterByRankedLanes = () => {
      switch (option) {
        case 'pending':
          return false
        case 'ranked':
          return true
        default:
          return undefined
      }
    }
    setParams({
      ...params,
      filterByRankedLanes: getFilterByRankedLanes(),
    })
  }

  const rankedFilterValue = useMemo(() => {
    switch (params.filterByRankedLanes) {
      case undefined:
        return ''
      case false:
        return 'pending'
      case true:
        return 'ranked'
      default:
        return ''
    }
  }, [params.filterByRankedLanes])

  const laneIdMaxLength = useLaneIdMaxLength()

  const columns = useMemo(() => {
    return () =>
      makeLanesColumns({
        laneIdMaxLength,
        bidOption,
        rateOption,
        availableRateOptions,
        displayMultiCurrency,
        rfp,
        showRecommendedCarriersSidebar,
        setRadioOptionOne,
        setRadioOptionTwo,
      })
  }, [
    laneIdMaxLength,
    bidOption,
    rateOption,
    availableRateOptions,
    displayMultiCurrency,
    rfp,
    showRecommendedCarriersSidebar,
  ])

  const showTable = query.isLoading || (query.data?.results?.length ?? 0) > 0

  return (
    <Container>
      <Layout.Stack>
        <FilterWrapper>
          <ToggleGroup
            aria-labelledby="status_label"
            id="status"
            name="status"
            value={rankedFilterValue}
            onChange={(event) =>
              changeFilter(event.target.value as RankedLanesFilterOptions)
            }
            data-testid="filter"
            options={[
              {
                label: 'ALL',
                value: '',
              },
              {
                label:
                  rfp.state === RFP_STATE.FINALIZED ? 'NOT RANKED' : 'PENDING',
                value: 'pending',
              },
              {
                label: 'RANKED',
                value: 'ranked',
              },
            ]}
            scale="small"
          />
        </FilterWrapper>
        {!showTable && (
          <EmptyLanesContainer border="v2">
            <EmptyLanesImage src={NoResultsIllustration} />
            <CenteredText variant="body-bold">
              Sorry. We couldn&apos;t find any
              <br />
              matching lanes with this status
            </CenteredText>
          </EmptyLanesContainer>
        )}
        {showTable && (
          <BaseTable
            isLoading={query.isLoading}
            columns={columns}
            entries={query.data?.results ?? []}
            onRowClick={(_event, row) =>
              showLaneDetailsOrRoutingGuide(row.original)
            }
            sortable
            selectable={false}
            changeSorting={changeSorting}
            sort={params.sort}
          />
        )}
      </Layout.Stack>
      {pageCount > 1 && (
        <ReactPaginate
          data-testid="pagination"
          previousLabel="Previous"
          nextLabel="Next"
          breakLabel="..."
          breakClassName="break-me"
          pageCount={pageCount}
          forcePage={page}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName="pagination"
          activeClassName="active"
        />
      )}
    </Container>
  )
}

export default Lanes
