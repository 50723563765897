import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import type { UserAccount } from '_shared_/user'
import * as signUpService from 'services/signUp'
import analytics, {
  AnalyticsEvent,
  AnalyticsEventTrigger,
} from 'utils/analytics'
import { parseError } from 'utils/errors'

export type SignUpErrorPayload = { [key in keyof UserAccount]: string }
type ErrorType = Partial<SignUpErrorPayload> | string | null
export interface State {
  meta: {
    isLoading: boolean
    error: ErrorType
  }
}

const initialState: State = {
  meta: { isLoading: false, error: null },
}

export const signUp = createAsyncThunk(
  'signUp/signUp',
  async (
    {
      account,
      callback,
    }: {
      account: UserAccount
      callback: () => void
    },
    thunk
  ) => {
    try {
      await signUpService.signUp(account)
      callback()
    } catch (error: any) {
      analytics.track(AnalyticsEvent.SignUpError, AnalyticsEventTrigger.error, {
        error: error.response?.data,
      })
      if (error.response?.data) {
        return thunk.rejectWithValue(error.response?.data)
      }
      return thunk.rejectWithValue(parseError(error))
    }
  }
)

export const slice = createSlice({
  name: 'signUp',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(signUp.pending, (state) => {
      state.meta.isLoading = true
      state.meta.error = null
    })
    builder.addCase(signUp.fulfilled, (state) => {
      state.meta.isLoading = false
    })
    builder.addCase(signUp.rejected, (state, action) => {
      const error = action.payload as ErrorType

      state.meta.error = error
      state.meta.isLoading = false
    })
  },
})

export default slice.reducer
