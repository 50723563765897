import { Layout, Tag } from '@loadsmart/miranda-react'

import TableActions from '_shared_/components/TableActions'
import { Text } from 'components/Typography'
import { getPerDistanceRate, isKilometers } from 'rfp/components/distanceType'
import { TableHeaderDropdown } from 'rfp/rfp-details/award-scenarios/TableHeaderDropdown'
import MultiCurrencyValue from 'rfp/rfp-details/components/MultiCurrencyValue'
import type { Currency, RequestForProposal } from 'rfp/rfp.types'
import { MODES_DICT, RFP_STATE } from 'utils/constants'
import { getEquipmentType } from 'utils/equipmentTypeV2'

import { Capacity, CarrierHeader } from '../lane-bids/styles'
import { CarrierCell } from './Cells'

export const rateColumnOptions = [
  {
    value: 'flat_rate',
    label: 'Flat Rate',
  },
  {
    value: 'per_mile_rate',
    label: 'RPM',
  },
]

const getModesAndEquipmentColumn = () => {
  return [
    {
      Header: 'Modes & Equip.',
      id: 'preferences',
      className: 'text-left',
      Cell: function modesAndEquipmentRender({
        row,
      }: {
        row: { original: LaneProposal }
      }) {
        const { preference } = row.original
        const { mode, equipment_type } = preference

        return (
          <Tag variant="neutral" size="default">
            {MODES_DICT[mode]}&nbsp;&rarr;&nbsp;
            {getEquipmentType(equipment_type)?.abbr}
          </Tag>
        )
      },
    },
  ]
}

const getRateColumn = (
  displayMultiCurrency: boolean,
  rfpCurrency: Currency,
  rfpDistanceType: RequestForProposal['distance_type'],
  rateColumn: string,
  setRateColumn: (value: string) => void
) => {
  return [
    {
      Header: (
        <Layout.Group gap="spacing-2" style={{ flexWrap: 'nowrap' }}>
          {
            rateColumnOptions.find((option) => option.value === rateColumn)
              ?.label
          }
          <TableHeaderDropdown
            options={rateColumnOptions}
            defaultValue={rateColumn}
            setValue={setRateColumn}
            disabled={false}
          />
        </Layout.Group>
      ),
      id: rateColumn,
      className: 'text-left',
      Cell: function render({ row }: { row: any }) {
        if (rateColumn === 'flat_rate') {
          const valueField = displayMultiCurrency
            ? 'converted_flat_rate'
            : 'flat_rate'

          return (
            <MultiCurrencyValue
              value={row.original[valueField]}
              rfpCurrency={rfpCurrency}
              nonConvertedValue={row.original['flat_rate']}
              boldStyle={false}
            />
          )
        }

        const value = getPerDistanceRate(
          row.original,
          rfpDistanceType,
          displayMultiCurrency
        )

        const nonConvertedValue = isKilometers(rfpDistanceType)
          ? row.original['per_kilometer_rate']
          : row.original['per_mile_rate']

        return (
          <MultiCurrencyValue
            value={value}
            rfpCurrency={rfpCurrency}
            nonConvertedValue={nonConvertedValue}
            boldStyle={false}
          />
        )
      },
    },
  ]
}

export default function makeColumns({
  handleRequestNewRate,
  promoteCarrierToPrimary,
  canPromoteCarrierToPrimary,
  handleSelectCarrier,
  lane,
  loadsmartDOT,
  handleUncheckCarrier,
  canAwardOrUnaward,
  rfpCurrency,
  displayMultiCurrency,
  rfpState,
  rfpDistanceType,
  rateColumn,
  setRateColumn,
}: {
  handleRequestNewRate: (proposal: LaneProposal, note: string) => void
  promoteCarrierToPrimary: (proposal: LaneProposal) => void
  canPromoteCarrierToPrimary: boolean
  handleSelectCarrier: (laneProposal: any) => void
  lane: Omit<Lane, 'lane_proposal' | 'meta'>
  loadsmartDOT: any
  handleUncheckCarrier: (laneProposal: any) => void
  canAwardOrUnaward: boolean
  rfpCurrency: Currency
  displayMultiCurrency: boolean
  rfpState: RfpState
  rfpDistanceType: RequestForProposal['distance_type']
  rateColumn: string
  setRateColumn: (value: string) => void
}) {
  return [
    {
      Header: <CarrierHeader>Carrier</CarrierHeader>,
      id: 'name',
      accessor: ({ carrier }: { carrier: any }) => carrier.name,
      className: 'text-left',
      Cell: function render({ row }: { row: any }) {
        return (
          <CarrierCell
            row={row}
            loadsmartDOT={loadsmartDOT}
            rfpState={rfpState}
          />
        )
      },
    },
    ...getModesAndEquipmentColumn(),
    {
      Header: 'Volume',
      id: 'awarded_volume',
      accessor: 'awarded_volume',
      className: 'text-left',
      Cell: function Cell({
        value,
        row,
      }: {
        readonly value: number
        readonly row: any
      }) {
        const { awarded_type: awardedType } = row.original

        return awardedType === 'primary' ? (
          <Capacity highlight={!!(lane && lane.volume > value)}>
            {value}
          </Capacity>
        ) : (
          '-'
        )
      },
    },
    ...getRateColumn(
      displayMultiCurrency,
      rfpCurrency,
      rfpDistanceType,
      rateColumn,
      setRateColumn
    ),
    {
      Header: '',
      id: 'award_tag',
      accessor: '',
      className: 'text-center',
      Cell: function Cell({ row }: { readonly row: any }) {
        return (
          <Tag
            size="default"
            variant={
              row.original.awarded_type === 'primary' ? 'success' : 'neutral'
            }
          >
            {row.original.awarded_type?.toUpperCase()}
          </Tag>
        )
      },
    },
    ...(canAwardOrUnaward
      ? [
          {
            Header: '',
            id: 'actions',
            className: 'text-left actions-cell',
            Cell: function render({ row }: { row: any }) {
              return (
                <TableActions
                  disabled={
                    rfpState === RFP_STATE.AWARDING &&
                    row.original.awarded_type === 'primary'
                  }
                >
                  <TableActions.Item
                    onClick={() => handleUncheckCarrier(row.original)}
                    disabled={rfpState === RFP_STATE.AWARDING}
                  >
                    <Text size="medium" weight="heavy" color="default">
                      Unselect Carrier
                    </Text>
                  </TableActions.Item>
                  <TableActions.Item
                    onClick={() => handleSelectCarrier(row.original)}
                    disabled={rfpState === RFP_STATE.AWARDING}
                  >
                    <Text size="medium" weight="heavy" color="default">
                      Edit Result
                    </Text>
                  </TableActions.Item>
                  <TableActions.Item
                    onClick={() => handleRequestNewRate(row.original, '')}
                    disabled={rfpState === RFP_STATE.AWARDING}
                  >
                    <Text size="medium" weight="heavy" color="default">
                      Request New Bid
                    </Text>
                  </TableActions.Item>
                  {rfpState === RFP_STATE.AWARDING && (
                    <TableActions.Item
                      disabled={
                        !canPromoteCarrierToPrimary ||
                        row.original.awarded_type !== 'backup'
                      }
                      onClick={() => promoteCarrierToPrimary(row.original)}
                    >
                      <Text size="medium" weight="heavy" color="default">
                        Promote to primary
                      </Text>
                    </TableActions.Item>
                  )}
                </TableActions>
              )
            },
          },
        ]
      : []),
  ]
}
