import styled, { css } from 'styled-components'

type BadgeVariant =
  | 'danger'
  | 'info'
  | 'light'
  | 'main'
  | 'secondary'
  | 'success'
  | 'warning'
  | 'primary-award'
  | 'backup-award'

type BadgeSize = 'small' | 'normal' | 'big'

interface BadgeProps {
  disabled?: boolean
  variant?: BadgeVariant
  size?: BadgeSize
}

const mainCSS = css`
  background-color: ${({ theme: { colors } }) => colors.main};
  color: ${({ theme }) => theme.colors.black};
`
const successCSS = css`
  background-color: ${({ theme: { colors } }) => colors.backgroundSuccess};
  color: ${({ theme }) => theme.colors.backgroundSecondary};
`
const secondaryCSS = css`
  background-color: ${({ theme: { colors } }) => colors.backgroundLight};
  border: 1px solid ${({ theme }) => theme.colors.backgroundLight};
  color: ${({ theme }) => theme.colors.backgroundSecondary};
`
const lightCSS = css`
  background-color: ${({ theme }) => theme.colors.light};
`
const dangerCSS = css`
  background-color: ${({ theme }) => theme.colors.danger};
  color: ${({ theme }) => theme.colors.black};
`
const infoCSS = css`
  background-color: ${({ theme }) => theme.colors.backgroundInfo};
  color: ${({ theme }) => theme.colors.black};
`
const warningCSS = css`
  background-color: ${({ theme }) => theme.colors.backgroundYellow};
  color: ${({ theme }) => theme.colors.black};
`

const primaryAwardCSS = css`
  background-color: ${({ theme }) =>
    theme.colors.backgroundTransparentAccentMinus20};
  color: ${({ theme }) => theme.colors.textAccent};
  font-weight: bold;
`

const backupAwardCSS = css`
  background-color: ${({ theme }) => theme.colors.backgroundMediumGray};
  color: ${({ theme }) => theme.colors.neutralDarkest};
  font-weight: bold;
`

function getBadgeStyle({ variant }: BadgeProps) {
  switch (variant) {
    case 'primary-award':
      return primaryAwardCSS
    case 'backup-award':
      return backupAwardCSS
    case 'secondary':
      return secondaryCSS
    case 'success':
      return successCSS
    case 'light':
      return lightCSS
    case 'danger':
      return dangerCSS
    case 'warning':
      return warningCSS
    case 'info':
      return infoCSS
    default:
      return mainCSS
  }
}

const sizeSmallCss = css`
  padding: 3px 8px;
`
const sizeNormalCss = css`
  padding: 6px 12px;
`
const sizeBigCss = css`
  padding: 12px 24px;
`

function getBadgeSize({ size }: BadgeProps) {
  switch (size) {
    case 'small':
      return sizeSmallCss
    case 'big':
      return sizeBigCss
    default:
      return sizeNormalCss
  }
}

const baseCSS = css`
  border-radius: 12px;
  border-width: 0;
  display: inline-flex;
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  text-transform: uppercase;
`

export default styled.div<BadgeProps>`
  ${baseCSS}
  ${getBadgeStyle}
  ${getBadgeSize}

  &:disabled {
    cursor: auto;
    opacity: 0.8;
  }
`
