import styled from 'styled-components'

import Badge from 'components/Badge'
import { StyledTitle as Title } from 'components/SideBar/styles'

interface InfoItemVariant {
  withoutBottomMargin?: boolean
}

export const Address = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
`

export const InfoWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  padding: 0 30px;
`
export const InfoItem = styled.div<InfoItemVariant>`
  display: flex;
  flex-direction: column;
  ${(props) => !props.withoutBottomMargin && 'margin-bottom: 30px;'}
  margin-right: 40px;
`

export const StyledTitle = styled(Title)`
  align-items: center;
  display: flex;
`
export const StopBadge = styled(Badge)`
  margin-left: 4px;
`
