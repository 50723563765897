import { Icon } from '@loadsmart/miranda-react'
import type { IconProps } from 'react-toastify'

export type ToastIconProps = Readonly<IconProps>

export const ToastIcon = ({ type }: IconProps) => {
  switch (type) {
    case 'info':
      return <Icon name="info" />
    case 'success':
      return <Icon name="check" />
    case 'warning':
      return <Icon name="attention" />
    case 'error':
      return <Icon name="info-circle" />
    default:
      return null
  }
}
