import { toCSSValue } from '@loadsmart/miranda-tokens/dist'
import styled from 'styled-components'

export const Button = styled('button')`
  align-items: center;
  background: none;
  border: none;
  color: ${({ disabled }) =>
    disabled
      ? toCSSValue('color-text-disabled')
      : toCSSValue('color-accent-60')};
  cursor: pointer;
  display: flex;
  font-size: ${toCSSValue('font-size-3')};
  height: 28px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  text-decoration: underline;
`
