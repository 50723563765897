import styled from 'styled-components'

import type { Theme } from 'styles/theme'
import { hideStyledProps } from 'utils/styles'

type TextColor =
  | 'accent'
  | 'default'
  | 'white'
  | 'gray'
  | 'neutral'
  | 'neutralLighter'
  | 'neutralDark'
  | 'neutralDarker'
  | 'dark'
  | 'success'
  | 'danger'

type TextSize = 'big' | 'medium' | 'small' | 'large' | 'smaller'

type TextWeight = 'heavy' | 'medium'

function getColor({ color, theme }: { color?: TextColor; theme: Theme }) {
  if (!color) {
    return ''
  }

  const themeColors: Record<TextColor, string> = {
    white: theme.colors.white,
    gray: theme.colors.backgroundMediumGray,
    neutral: theme.colors.neutral,
    neutralLighter: theme.colors.neutralLighter,
    neutralDark: theme.colors.neutralDark,
    neutralDarker: theme.colors.neutralDarker,
    dark: theme.colors.textDark,
    success: theme.colors.success,
    danger: theme.colors.dangerDark,
    accent: theme.colors.textAccent,
    default: theme.colors.text,
  }

  return `color: ${themeColors[color]};`
}

function getSize({ size }: { size?: TextSize }) {
  if (!size) {
    return ''
  }

  let fontSize = 0
  let lineHeight = 0

  switch (size) {
    case 'large':
      fontSize = 20
      lineHeight = 23
      break
    case 'big':
      fontSize = 16
      lineHeight = 21
      break
    case 'medium':
      fontSize = 14
      lineHeight = 16
      break
    case 'small':
      fontSize = 12
      lineHeight = 16
      break
    case 'smaller':
      fontSize = 10
      lineHeight = 16
      break
  }

  return `font-size: ${fontSize}px; line-height: ${lineHeight}px;`
}

function getWeight({ weight }: { weight?: TextWeight }) {
  if (!weight) {
    return ''
  }

  let value = 0

  switch (weight) {
    case 'heavy':
      value = 700
      break
    case 'medium':
      value = 600
      break
    default:
      value = 500
  }

  return `font-weight: ${value};`
}

function getUppercase({ uppercase }: { uppercase?: boolean }) {
  if (!uppercase) {
    return undefined
  }

  return `text-transform: uppercase;`
}

function getUnderline({ underline }: { underline?: boolean }) {
  if (!underline) {
    return undefined
  }

  return 'text-decoration: underline'
}

/**
 * @deprecated use @loadsmart/miranda-react instead
 */
export const Text = styled.span.withConfig(
  hideStyledProps(['color', 'size', 'weight', 'uppercase', 'underline'])
)<{
  color?: TextColor
  size?: TextSize
  weight?: TextWeight
  uppercase?: boolean
  underline?: boolean
}>`
  ${getColor}
  ${getSize}
  ${getWeight}
  ${getUppercase}
  ${getUnderline}
`
