import type { Dispatch, SetStateAction } from 'react'
import type { UseTableRowProps } from 'react-table'

import type { TableData } from 'components/LaneManagement/LanesTableV2/types'
import { getLaneStatus } from 'components/LaneManagement/LanesTableV2/utils'
import {
  getActionColumn,
  getAllocationPercentageColumn,
  getDestOrDeliveryColumn,
  getLaneIdColumn,
  getModeAndEquipmentColumn,
  getOriginOrPickupColumn,
  getRateOptionColumn,
} from 'rfp/components/table/columns'
import LaneStatusIndicator from 'rfp/rfp-details/components/lane-status-indicator'
import MultiCurrencyValue from 'rfp/rfp-details/components/MultiCurrencyValue'
import type { Currency } from 'rfp/rfp.types'

import { PrimaryCell, VersusCell } from './Cells'

type TableRow = UseTableRowProps<TableData>

export default function makeColumns({
  laneIdMaxLength,
  rateOption,
  rfpState,
  isSpotMode,
  rfpCurrency,
  availableRateOptions,
  displayMultiCurrency,
  optionsDict,
  setRadioOptionTwo,
}: {
  laneIdMaxLength: number
  rateOption: string
  rfpState: string
  isSpotMode: boolean
  rfpCurrency: Currency
  availableRateOptions: {
    value: string
    label: string
  }[]
  displayMultiCurrency: boolean
  optionsDict: Record<string, string>
  setRadioOptionTwo: Dispatch<SetStateAction<string>>
}) {
  const columns = [
    {
      Header: '',
      id: 'state',
      className: 'text-center small',
      accessor: (lane: TableData) => getLaneStatus(lane, rfpState),
      Cell: function CellLaneState({ row }: { readonly row: TableRow }) {
        const lane = row.original as Lane
        return (
          <LaneStatusIndicator
            lane={lane}
            rfpState={rfpState as RfpState}
            isSpotMode={isSpotMode}
          />
        )
      },
    },
    getLaneIdColumn(laneIdMaxLength),
    getOriginOrPickupColumn(),
    getDestOrDeliveryColumn(),
    getModeAndEquipmentColumn(),
    getAllocationPercentageColumn(),
    {
      Header: 'PRIMARY',
      id: 'primary',
      className: 'text-left absolute-content',
      accessor: 'primary_carriers',
      Cell: function CellPrimary({ value }: { readonly value: any }) {
        return <PrimaryCell value={value} rfpCurrency={rfpCurrency} />
      },
    },
    {
      Header: 'AWARD RATE',
      id: 'avg_primary_awarded_rate',
      className: 'text-left',
      accessor: 'awarded_rate',
      Cell: function CellAwardRate({ row }: { readonly row: any }) {
        const valueField = displayMultiCurrency
          ? 'converted_awarded_rate'
          : 'awarded_rate'

        return (
          <MultiCurrencyValue
            value={row.original[valueField]}
            nonConvertedValue={row.original['awarded_rate']}
            rfpCurrency={rfpCurrency}
            emptyValue="No Award"
          />
        )
      },
    },
    {
      Header: 'VS →',
      id: 'vs',
      className: 'text-left text-nowrap',
      accessor: '',
      Cell: function CellVersus({
        row,
      }: Readonly<{
        row: any
      }>) {
        return <VersusCell row={row} rateOption={rateOption} />
      },
    },
    getRateOptionColumn(
      optionsDict,
      rateOption,
      availableRateOptions,
      setRadioOptionTwo,
      displayMultiCurrency,
      rfpCurrency
    ),
    getActionColumn(rfpState),
  ]

  return columns
}
