import { Dialog, Text } from '@loadsmart/loadsmart-ui'
import { Layout } from '@loadsmart/miranda-react'
import styled from 'styled-components'

import { RFP_STATE } from 'utils/constants'
import { plural } from 'utils/strings'

import type { Carrier } from './Carriers'

const StyledList = styled.ul`
  > li {
    text-align: initial;
  }
`

export const countUninvitedCarriers = (carriers: Carrier[]) =>
  carriers.reduce(
    (acc, carrier) => (carrier.invite === undefined ? acc + 1 : acc),
    0
  )

export const countSentOrResentInvites = (carriers: Carrier[]) =>
  carriers.reduce(
    (acc, carrier) => (carrier.invite?.state.includes('sent') ? acc + 1 : acc),
    0
  )

const ReinviteCarrierDialogBody = ({
  carrier,
}: {
  readonly carrier: Carrier
}) => {
  if (carrier.invite?.state === 'sent') {
    return (
      <>{carrier.name} will be notified again via email to Bid on the RFP.</>
    )
  }

  return (
    <Layout.Stack>
      <div>You already resent an invite to bid to {carrier.name}</div>
      <div>
        Would you like to send it again? Carrier will be notified via email to
        Bid on the RFP.
      </div>
    </Layout.Stack>
  )
}

const ReinviteMultipleCarriersDialogBody = ({
  carriers,
}: {
  readonly carriers: Carrier[]
}) => {
  const hasUninvitedCarrier = Boolean(
    carriers.find((carrier) => carrier.invite === undefined)
  )

  if (hasUninvitedCarrier) {
    const uninvitedCarriers = countUninvitedCarriers(carriers)
    const resentInvites = countSentOrResentInvites(carriers)

    return (
      <>
        <Layout.Stack>
          <div>
            You have selected {uninvitedCarriers}{' '}
            {plural('carrier', uninvitedCarriers)} that{' '}
            {plural('was', uninvitedCarriers, 'were')} not previously invited.
          </div>
          <div>By confirming this action, you will</div>
        </Layout.Stack>
        <StyledList>
          <li>
            <b>
              Resend {resentInvites} {plural('email', resentInvites)}
            </b>
          </li>
          <li>
            <b>
              Invite {uninvitedCarriers} new{' '}
              {plural('carrier', uninvitedCarriers)} to bid
            </b>
          </li>
        </StyledList>
      </>
    )
  }

  const hasResentInvite = Boolean(
    carriers.find(({ invite }) => invite?.state === 'resent')
  )

  if (hasResentInvite) {
    return (
      <Layout.Stack>
        <div>
          You already resent an invite to bid to one or more selected carriers
        </div>
        <div>Would you like to send it again?</div>
        <div>The carrier will be notified via email to Bid on the RFP.</div>
      </Layout.Stack>
    )
  }

  return (
    <>
      {carriers.length} carriers will be notified again via email to Bid on the
      RFP.
    </>
  )
}

const InviteSelectedDialog = ({
  isOpen,
  onClose,
  onConfirm,
  rfpState,
  carriers,
  isSendingInvite,
}: {
  readonly isOpen: boolean
  readonly onClose: () => void
  readonly onConfirm: () => void
  readonly rfpState: RfpState
  readonly carriers: Carrier[]
  readonly isSendingInvite: boolean
}) => {
  const hasSentOrResentInvite = Boolean(
    carriers.find(({ invite }) => invite?.state.includes('sent'))
  )

  if (hasSentOrResentInvite) {
    return (
      <Dialog open={isOpen} scale="small" onOverlayClick={onClose}>
        <Dialog.Header>Resend invite to bid</Dialog.Header>
        <Dialog.Body>
          <Text variant="body">
            {carriers.length === 1 ? (
              <ReinviteCarrierDialogBody carrier={carriers[0]} />
            ) : (
              <ReinviteMultipleCarriersDialogBody carriers={carriers} />
            )}
          </Text>
        </Dialog.Body>
        <Dialog.ActionConfirm onConfirm={onConfirm} loading={isSendingInvite}>
          Confirm
        </Dialog.ActionConfirm>
        <Dialog.ActionCancel onCancel={onClose}>Cancel</Dialog.ActionCancel>
      </Dialog>
    )
  }

  return (
    <Dialog open={isOpen} scale="small" onOverlayClick={onClose}>
      <Dialog.Header>
        {rfpState === RFP_STATE.DRAFT
          ? 'Add carriers to bid'
          : 'Invite carriers to bid'}
      </Dialog.Header>
      <Dialog.Body>
        <Text variant="body" color="color-neutral-darker">
          You are about to {rfpState === RFP_STATE.DRAFT ? 'add' : 'invite'}{' '}
          {`${carriers.length} ${
            carriers.length === 1 ? 'carrier' : 'carriers'
          }`}{' '}
          to
          <br /> bid on this RFP.
        </Text>
        <br />
        <br />
        <Text variant="body" color="color-neutral-darker">
          Would you like to proceed?
        </Text>
      </Dialog.Body>
      <Dialog.ActionConfirm onConfirm={onConfirm} loading={isSendingInvite}>
        Yes, {rfpState === RFP_STATE.DRAFT ? 'add' : 'invite'} carriers to bid
      </Dialog.ActionConfirm>
      <Dialog.ActionCancel onCancel={onClose}>Cancel</Dialog.ActionCancel>
    </Dialog>
  )
}

export default InviteSelectedDialog
