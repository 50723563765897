import styled from 'styled-components'

import Badge from 'components/Badge'

export const Equipment = styled.span`
  align-items: center;
  display: flex;

  svg {
    color: ${({ theme }) => theme.colors.neutral};
    margin-right: 5px;
  }
`
export const DestinationWrapper = styled.div`
  align-items: center;
  display: flex;
`
export const StopBadge = styled(Badge)`
  margin-left: 4px;
`
