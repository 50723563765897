import { IconTrash } from '@loadsmart/icons'
import { Dialog } from '@loadsmart/loadsmart-ui'
import { useCallback } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import ActionDialog from 'components/Dialog/ActionDialog'
import { useModal } from 'hooks/useModal'
import { deleteCarrierDetails } from 'services/carriers'

import { RemoveButton } from './CarrierForm.styles'

type DeleteCarrierButtonProps = {
  readonly carrier: Carrier
  readonly cancelCallback: () => void
  readonly closeSideBar: () => void
  readonly disabled: boolean
}

const DeleteCarrierButton = ({
  carrier,
  cancelCallback,
  closeSideBar,
  disabled,
}: DeleteCarrierButtonProps) => {
  const { modalState, closeModal, openModal } = useModal()
  const queryClient = useQueryClient()

  const { mutate } = useMutation({
    mutationFn: deleteCarrierDetails,
    onSuccess: async () => {
      closeModal()
      cancelCallback()
      toast.success('Carrier successfully deleted')
      await queryClient.refetchQueries({ queryKey: ['retrieveCarriers'] })
      closeSideBar()
    },
    onError() {
      closeModal()
      closeSideBar()
      toast.error('Could not delete the carrier, please contact an admin')
    },
  })

  const handleCarrierDeletion = useCallback(() => {
    mutate(carrier.id)
  }, [carrier.id, mutate])

  return (
    <>
      <RemoveButton
        type="button"
        variant="blank"
        disabled={disabled}
        onClick={openModal}
        aria-label="Delete carrier"
      >
        <IconTrash width={20} height={20} />
      </RemoveButton>
      <Dialog open={modalState} scale="small" onOverlayClick={closeModal}>
        <Dialog.Header>
          Are you sure you want to delete this carrier?
        </Dialog.Header>
        <Dialog.Body>This action cannot be undone.</Dialog.Body>
        <ActionDialog variant="danger" onConfirm={handleCarrierDeletion}>
          Delete Carrier
        </ActionDialog>
        <Dialog.ActionCancel onCancel={closeModal} />
      </Dialog>
    </>
  )
}

export default DeleteCarrierButton
