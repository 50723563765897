import { IconQuestionCircle } from '@loadsmart/icons'
import { conditional } from '@loadsmart/loadsmart-ui/dist/tools'
import styled from 'styled-components'

import { UnstyledButton } from 'components/Button'
import Card from 'components/Card'
import { Text } from 'components/Typography'

import { hideStyledProps } from '../../../utils/styles'

export const Container = styled.div``

export const EmptyLanesContainer = styled(Card)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 400px;
  width: 100%;
`

export const EmptyButtonsRow = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: auto;
  justify-content: space-between;
  width: 280px;
`

export const HeaderCellWithActions = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: auto;
  justify-content: flex-end;
  width: 110px;
  span {
    color: ${({ theme }) => theme.colors.neutral};
  }
`

export const NameContainer = styled.div`
  margin-right: 8px;
`

export const StyledLabel = styled.label`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  padding: 10px 20px;
`

export const EmptyLanesImage = styled.img`
  width: 190px;
`

export const ActionButton = styled(UnstyledButton)`
  align-items: center;
  color: ${({ theme }) => theme.colors.textAccent};
  display: flex;
  font-size: 12px;
  font-weight: 600;
  height: 28px;
  justify-content: center;
  text-align: center;
`

export const StyledText = styled(Text)`
  line-height: 18px;
  margin-bottom: 20px;
  margin-top: 10px;
  max-width: 200px;
  text-align: center;
`

export const PriceChangeIndicator = styled.div.withConfig(
  hideStyledProps(['direction'])
)<{
  direction: string
}>`
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  ${({ direction, theme }) => {
    const border = direction === 'positive' ? 'top' : 'bottom'
    const color =
      direction === 'negative'
        ? theme.colors.dangerDark
        : theme.colors.textAccent
    return `border-${border}: 5px solid ${color};`
  }}
  margin-right: 4px;
`
export const Question = styled(IconQuestionCircle)`
  color: ${({ theme }) => theme.colors.borderSecondary};
`

export const StyledCarrierNameCell = styled.td<{
  $shouldHavePaddingLeft: boolean
}>`
  display: inline-block;
  overflow: hidden;
  padding-left: ${conditional({
    '28px': ({ $shouldHavePaddingLeft }) => $shouldHavePaddingLeft === true,
    initial: ({ $shouldHavePaddingLeft }) => $shouldHavePaddingLeft === false,
  })};

  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const StyledAwardTooltip = styled.div`
  height: 12px;
  width: 12px;
`

export const StyledAwardTable = styled.table`
  th {
    font-weight: bold;
  }

  th:first-of-type,
  th:last-of-type {
    text-align: left;
  }

  td > div > div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 130px;
  }

  th:first-of-type,
  td:first-of-type {
    padding-right: 24px;
  }

  th,
  td {
    padding-right: 16px;
  }

  th:last-of-type,
  td:last-of-type {
    padding-right: inherit;
  }
`

export const StyledIncumbentTooltip = styled.div`
  left: -12px;
  position: absolute;
`
