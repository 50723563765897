import { Card } from '@loadsmart/loadsmart-ui'
import styled from 'styled-components'

import { ButtonGroup } from '_shared_/components/form/styles'

export const FormActionsWrapper = styled(ButtonGroup)`
  display: flex;
  flex-direction: row;
  position: sticky;
  top: 100vh;
`
export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  & > button:first-child {
    margin-right: 20px;
  }

  & > button:last-child {
    margin-left: auto;
  }

  & > button:only-child {
    margin-right: auto;
    margin-left: 0;
  }

  button {
    padding-left: 26px;
    padding-right: 26px;
    width: auto;
  }
`

export const ActionLink = styled.div`
  align-self: center;
  color: ${({ theme }) => theme.colors.textAccent};
  cursor: pointer;
  font-weight: 700;
  text-decoration: underline;
  white-space: nowrap;
`

export const Title = styled(Card.Title)`
  margin: 0;
  padding: 0;
`
