import { useEffect } from 'react'

import type { UserBusiness, UserCategory, UserRole } from '_shared_/user'
import { userUtils } from '_shared_/user'
import ScriptLoader from 'components/ScriptLoader'
import { BEAMER_PRODUCT_ID } from 'utils/constants'
import { getAppEnv } from 'utils/envVars'

declare global {
  interface Window {
    beamer_config?: {
      product_id: string
      user_firstname?: string
      user_email?: string
      user_id?: string
      user_created_at?: string | number
      user_shipper_company?: string
      user_shipper_is_active?: boolean
      user_is_managed_trans?: boolean
      user_category?: UserCategory
      user_date_joined?: string
      user_is_test?: boolean
      user_role?: UserRole
      user_is_supervisor?: boolean
      user_shipper_business: ShipperBusiness | null
      user_business: UserBusiness
    }
  }
}

const BeamerLoader = () => {
  const env = getAppEnv().toLowerCase()

  const shouldRenderAndInitializeBeamer =
    env === 'production' &&
    userUtils.isLoggedIn &&
    !userUtils.isMTCustomerUser &&
    !userUtils.isSupplierUser

  useEffect(() => {
    if (!shouldRenderAndInitializeBeamer) {
      return
    }

    const {
      user_name: name,
      user_email: email,
      user_date_joined: dateJoined,
      shipper_company: shipperCompany,
      shipper_is_active: shipperIsActive,
      is_managed_trans: isManagedTrans,
      user_category: userCategory,
      user_is_test: userIsTest,
      user_role: userRole,
      user_business: userBusiness,
      is_supervisor: isSupervisor,
      business: shipperBusiness,
    } = userUtils.data

    window.beamer_config = {
      product_id: BEAMER_PRODUCT_ID,
      user_firstname: name,
      user_email: email,
      user_id: email,
      user_created_at: dateJoined
        ? Math.floor(new Date(dateJoined).getTime() / 1000)
        : undefined,
      user_shipper_company: shipperCompany,
      user_shipper_is_active: shipperIsActive,
      user_is_managed_trans: isManagedTrans,
      user_category: userCategory,
      user_date_joined: dateJoined,
      user_is_test: userIsTest,
      user_role: userRole,
      user_is_supervisor: isSupervisor,
      user_shipper_business: shipperBusiness,
      user_business: userBusiness,
    }
  }, [shouldRenderAndInitializeBeamer])

  if (!shouldRenderAndInitializeBeamer) {
    return null
  }

  return (
    <ScriptLoader
      src="https://app.getbeamer.com/js/beamer-embed.js"
      onLoad={() => undefined}
    />
  )
}

export default BeamerLoader
