import httpClient from 'utils/httpClient'

export const recommendedCarriers = async (lane_id: string | number) => {
  const { data } = await httpClient.post(`recommendations/reccar`, { lane_id })

  return data
}

export const inviteRecommendedCarrier = async ({
  recommendation,
  rfp,
}: {
  recommendation: number
  rfp: number | string
}) => {
  const { data } = await httpClient.post(`carriers/recommendation`, {
    recommendation,
    rfp,
  })

  return data
}
