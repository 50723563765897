import { Layout, Field, Text, Card } from '@loadsmart/miranda-react'
import { Controller } from 'react-hook-form'

import { MirandaCurrency, defaultCurrencyMask } from 'components/Input/Currency'
import { CURRENCY } from 'utils/constants'

import type { LaneTargetRateProps } from './types'

const currencyMaskMap = {
  [CURRENCY.USD]: {
    ...defaultCurrencyMask,
    prefix: '$ ',
  },
  [CURRENCY.CAD]: {
    ...defaultCurrencyMask,
    prefix: 'C$ ',
  },
  [CURRENCY.MXN]: {
    ...defaultCurrencyMask,
    prefix: 'MX$ ',
  },
}

export default function LaneTargetRateSection({
  control,
  rfpCurrency,
  setValue,
}: LaneTargetRateProps) {
  const currencyMask =
    currencyMaskMap[rfpCurrency as keyof typeof currencyMaskMap]

  const handleTargetRateChange = (rate: string) => {
    const parsedRate = parseFloat(rate.toString().replace(/[^\d.]/g, ''))
    const safeParsedRate = Number.isNaN(parsedRate) ? '' : parsedRate
    setValue('target_rate', safeParsedRate)
  }
  return (
    <Layout.Box paddingB="spacing-4" padding="none">
      <Card collapsible>
        <Card.Title>Target Rate</Card.Title>
        <Card.Divider />
        <Card.Body>
          <Field style={{ flex: '1' }}>
            <Controller
              name="target_rate"
              control={control}
              render={({ field }) => {
                const { ref: _, ...rest } = field

                return (
                  <>
                    <MirandaCurrency
                      {...rest}
                      disabled={false}
                      labelledBy="targetRateInput"
                      data-testid="targetRateInput"
                      placeholder="Set target rate"
                      currencyMask={currencyMask}
                      onChange={(event) =>
                        handleTargetRateChange(
                          (event.target as HTMLInputElement).value
                        )
                      }
                    />
                    <Layout.Group style={{ justifyContent: 'space-between' }}>
                      <Text color="color-text-tertiary" variant="body-sm">
                        Your target rate will not be shared with carriers.
                      </Text>
                    </Layout.Group>
                  </>
                )
              }}
            />
          </Field>
        </Card.Body>
      </Card>
    </Layout.Box>
  )
}
