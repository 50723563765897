import { IconQuestionCircle } from '@loadsmart/icons'
import { Select } from '@loadsmart/loadsmart-ui'
import {
  Card,
  Field,
  Layout,
  Text,
  TextField,
  Banner,
} from '@loadsmart/miranda-react'
import { Controller } from 'react-hook-form'

import { ConversionRate } from 'components/Input'
import { CURRENCY } from 'utils/constants'

import { RequiredErrorMessage } from '../componentUtils'
import type { ConversionRateOption } from '../types'
import { getFieldStatus } from '../utils'
import { FormTooltip } from './styles'
import type { DetailsSectionProps } from './types'

const shouldRenderConversionRateField = (
  watchCurrency: SelectOption | undefined
) => watchCurrency !== null && watchCurrency?.value !== CURRENCY.USD

export default function RFPDetailsSection({
  shouldRender,
  distanceOptions,
  currencyOptions,
  fuelInclusionOptions,
  control,
  setValue,
  isFieldDisabled,
  errors,
  watchCurrency,
}: DetailsSectionProps) {
  if (!shouldRender) {
    return null
  }

  return (
    <Card>
      <Card.Title>RFP Details</Card.Title>
      <Card.Divider />
      <Card.Body>
        <Layout.Stack gap="spacing-8">
          <Layout.Grid minColumnWidth="fit-content">
            <Field
              required
              style={{ gridColumn: 'span 3' }}
              status={getFieldStatus(errors.rfpTitle)}
            >
              <Field.Label aria-label="RFP Title" id="rfpTitle">
                <Text>RFP Title</Text>
                <FormTooltip
                  className="field-label-tooltip"
                  trigger="hover"
                  message={`Enter a title for your RFP.
                    This should be a brief description that will be easily recognizable to carriers.`}
                  placement="top-start"
                >
                  <IconQuestionCircle
                    width={14}
                    height={14}
                    aria-label="rfp-title"
                    title={null}
                  />
                </FormTooltip>
              </Field.Label>
              <Controller
                name="rfpTitle"
                control={control}
                disabled={isFieldDisabled}
                render={({ field }) => (
                  <TextField
                    {...field}
                    onInput={field.onChange}
                    aria-labelledby="rfpTitle"
                  />
                )}
              />
              <RequiredErrorMessage error={errors.rfpTitle} />
            </Field>
          </Layout.Grid>
          <Layout.Stack>
            <Layout.Grid>
              <Field
                aria-label="newRfpFuel"
                required
                status={getFieldStatus(errors.rateType)}
              >
                <Field.Label>
                  <Text>Receive Rates</Text>
                  <FormTooltip
                    trigger="hover"
                    message={`Select whether you wish to receive rates that include fuel surcharges
                      ('with fuel') or rates that exclude fuel surcharges ('without fuel').`}
                    placement="top-start"
                  >
                    <IconQuestionCircle
                      width={14}
                      height={14}
                      aria-label="rfp-receive-rates"
                      title={null}
                    />
                  </FormTooltip>
                </Field.Label>
                <Controller
                  name="rateType"
                  control={control}
                  disabled={isFieldDisabled}
                  render={({ field }) => (
                    <Select
                      style={{ maxWidth: 'fit-content' }}
                      {...field}
                      placeholder="Select..."
                      options={fuelInclusionOptions}
                      hideClear
                    />
                  )}
                />
                <RequiredErrorMessage error={errors.rateType} />
              </Field>
              <Field
                aria-label="newRfpDistanceUnity"
                required
                status={getFieldStatus(errors.distanceType)}
              >
                <Field.Label>
                  <Text>Distance unit</Text>
                  <FormTooltip
                    trigger="hover"
                    message="Select the unit of measurement for the transportation distance."
                    placement="top-start"
                  >
                    <IconQuestionCircle
                      width={14}
                      height={14}
                      aria-label="rfp-distance-unity"
                      title={null}
                    />
                  </FormTooltip>
                </Field.Label>
                <Controller
                  name="distanceType"
                  control={control}
                  disabled={isFieldDisabled}
                  render={({ field }) => (
                    <Select
                      style={{ maxWidth: 'fit-content' }}
                      {...field}
                      placeholder="Select..."
                      options={distanceOptions}
                      hideClear
                    />
                  )}
                />
                <RequiredErrorMessage error={errors.distanceType} />
              </Field>
              <Field
                aria-label="newRfpCurrency"
                required
                status={getFieldStatus(errors.currency)}
              >
                <Field.Label>
                  <Text>Currency</Text>
                  <FormTooltip
                    trigger="hover"
                    message="Choose the currency for the rates you will receive."
                    placement="top-start"
                  >
                    <IconQuestionCircle
                      width={14}
                      height={14}
                      aria-label="rfp-currency"
                      title={null}
                    />
                  </FormTooltip>
                </Field.Label>
                <Controller
                  name="currency"
                  control={control}
                  disabled={isFieldDisabled}
                  render={({ field }) => (
                    <Select
                      style={{ maxWidth: 'fit-content' }}
                      {...field}
                      placeholder="Select..."
                      options={currencyOptions}
                      hideClear
                      onChange={(event) => {
                        field.onChange(event)

                        const value = event.target.value as ConversionRateOption
                        if (value) {
                          setValue(
                            'conversionRate',
                            value.conversion_rate.toString()
                          )
                        }
                      }}
                    />
                  )}
                />
                <RequiredErrorMessage error={errors.currency} />
              </Field>
            </Layout.Grid>
          </Layout.Stack>
        </Layout.Stack>
        {shouldRenderConversionRateField(watchCurrency) && (
          <Layout.Group paddingT="spacing-5" gap="spacing-10" align="center">
            <Layout.Stack style={{ flex: 1 }}>
              <Layout.Group>
                <Field
                  required
                  status={getFieldStatus(errors.conversionRate)}
                  style={{ maxWidth: '240px' }}
                >
                  <Field.Label>Currency conversion rate</Field.Label>
                  <Controller
                    name="conversionRate"
                    control={control}
                    disabled={isFieldDisabled}
                    render={({ field }) => (
                      <ConversionRate
                        {...field}
                        type="text"
                        placeholder="Enter conversion rate"
                        error={errors.conversionRate}
                        disabled={false}
                        data-testid="inputConversionRate"
                        style={{ width: '100%' }}
                      />
                    )}
                  />
                  <Text variant="body-sm">e.g. &apos;1.27&apos; for 1USD</Text>
                  <RequiredErrorMessage error={errors.conversionRate} />
                </Field>
              </Layout.Group>
              <Banner variant="warning">
                <Banner.Description>
                  <Text variant="body-md-bold">Note:</Text> Benchmark and
                  Loadsmart&apos;s rates will only be displayed for RFPs in USD.
                </Banner.Description>
              </Banner>
            </Layout.Stack>
          </Layout.Group>
        )}
      </Card.Body>
    </Card>
  )
}
