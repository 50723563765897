// This version of sentry is compatible with workers
import type Pusher from 'pusher-js'

import Channel from './channel'
import type {
  OnReadyCallback,
  OnErrorCallback,
  OnMessageCallback,
} from './types'

export default class Channels {
  private pusher: Pusher
  private channels: { [channel: string]: Channel } = {}

  constructor(pusher: Pusher) {
    this.pusher = pusher
  }

  subscribe(
    channel: string,
    events: string | string[],
    onReady: OnReadyCallback,
    onError: OnErrorCallback,
    onMessage: OnMessageCallback
  ) {
    this.channels[channel] =
      this.channels[channel] || new Channel(channel, this.pusher)

    this.channels[channel].subscribe(events, onReady, onError, onMessage)
  }

  unsubscribe(
    channel: string,
    events: string | string[],
    onReady: OnReadyCallback,
    onError: OnErrorCallback,
    onMessage: OnMessageCallback
  ) {
    if (!this.channels[channel]) {
      return
    }

    this.channels[channel].unsubscribe(events, onReady, onError, onMessage)

    if (!this.channels[channel].subscribed) {
      delete this.channels[channel]
    }
  }

  count() {
    return Object.values(this.channels).length
  }
}
