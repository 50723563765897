import { useCallback } from 'react'

import { useFilters, generateUseSearchParamsPlugin } from 'utils/filters'

type PlanFulfillmentsQPState = {
  fulfillments: string[]
}

function getDefaultPlanFulfillmentsQPState(
  overrides?: Partial<PlanFulfillmentsQPState>
): PlanFulfillmentsQPState {
  return {
    fulfillments: [],
    ...overrides,
  }
}

const usePlanFulfillmentsQPStatePlugin =
  generateUseSearchParamsPlugin<PlanFulfillmentsQPState>({
    fulfillments: {
      type: 'primitive-collection',
    },
  })

export const usePlanFulfillmentsQPState = () => {
  const { setFilter, values } = useFilters(
    { initialValues: getDefaultPlanFulfillmentsQPState() },
    usePlanFulfillmentsQPStatePlugin
  )
  const { fulfillments: selectedFulfillmentsUUIDs = [] } = values

  const setSelectedFulfillments = useCallback(
    (newSelected: string[]) => {
      setFilter('fulfillments', newSelected)
    },
    [setFilter]
  )

  const addSelectedFulfillments = useCallback(
    (toBeAdded: string | string[]) => {
      const array = Array.isArray(toBeAdded) ? toBeAdded : [toBeAdded]
      const newFulfillmentsArray = [...selectedFulfillmentsUUIDs, ...array]
      setFilter('fulfillments', newFulfillmentsArray)
    },
    [selectedFulfillmentsUUIDs, setFilter]
  )

  const removeSelectedFulfillments = useCallback(
    (toBeRemoved: string | string[]) => {
      const newFulfillmentsArray = selectedFulfillmentsUUIDs.filter((uuid) => {
        if (Array.isArray(toBeRemoved)) {
          return !toBeRemoved.includes(uuid)
        }
        return toBeRemoved !== uuid
      })

      setFilter('fulfillments', newFulfillmentsArray)
    },
    [selectedFulfillmentsUUIDs, setFilter]
  )

  const clearSelectedFulfillments = useCallback(() => {
    setFilter('fulfillments', [])
  }, [setFilter])

  return {
    addSelectedFulfillments,
    clearSelectedFulfillments,
    removeSelectedFulfillments,
    selectedFulfillmentsUUIDs,
    setSelectedFulfillments,
  }
}
