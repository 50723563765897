import { LabeledValue } from '@loadsmart/miranda-react'
import { useFulfillmentFormContext } from 'fulfillments/components/FulfillmentFormContext'
import { getSuppliers } from 'fulfillments/fulfillment-service'
import { useQuery } from 'react-query'
import type { Supplier } from 'suppliers/types'

import { defaultEmptyString } from 'utils/strings'

import { FulfillmentSelect } from './FulfillmentSelect'

export function FulfillmentSupplierLabelValue() {
  const { firstSelectedOrder } = useFulfillmentFormContext()

  return (
    <LabeledValue
      label="Supplier"
      value={defaultEmptyString(firstSelectedOrder?.supplier_name)}
    />
  )
}

const suppliersToOptions = (suppliers: Supplier[]) => {
  return suppliers.map((supplier) => ({
    label: supplier.name,
    value: supplier.uuid,
  }))
}

export function FulfillmentSupplier({
  required,
}: {
  readonly required?: boolean
}) {
  const { data, isLoading } = useQuery({
    queryKey: ['get-suppliers'],
    queryFn: getSuppliers,
    cacheTime: 60000,
    select: suppliersToOptions,
  })

  return (
    <FulfillmentSelect
      name="supplier_uuid"
      label="Supplier"
      required={required}
      placeholder="Select supplier"
      loading={isLoading}
      options={data}
    />
  )
}
