import { Text } from 'components/Typography'
import formatCurrency from 'utils/formatCurrency'

import { BarV2, TextWrapper, WrapperV2 } from './styles'

export default function OverallBar({
  avgValue,
  highestValue,
  lowestValue,
}: {
  readonly avgValue?: number
  readonly highestValue?: number
  readonly lowestValue?: number
}) {
  return (
    <WrapperV2 data-testid="benchmartOverallBar">
      <TextWrapper className="flex justify-between align-center">
        <div>
          <Text
            color="neutral"
            size="small"
            as="p"
            weight="heavy"
            className="overallSubtitle text-center"
          >
            Lowest
          </Text>
          <Text
            color="neutralDarker"
            size="big"
            as="p"
            weight="heavy"
            className="overallValue text-center"
          >
            {lowestValue ? formatCurrency(lowestValue) : '$ -'}
          </Text>
        </div>
        <div>
          <Text
            color="neutral"
            size="small"
            as="p"
            weight="heavy"
            className="overallSubtitle text-center"
          >
            Average
          </Text>
          <Text
            color="neutralDarker"
            size="big"
            as="p"
            weight="heavy"
            className="overallValue text-center"
          >
            {avgValue ? formatCurrency(avgValue) : '$ -'}
          </Text>
        </div>
        <div>
          <Text
            color="neutral"
            size="small"
            as="p"
            weight="heavy"
            className="overallSubtitle text-center"
          >
            Highest
          </Text>
          <Text
            color="neutralDarker"
            size="big"
            as="p"
            weight="heavy"
            className="overallValue text-center"
          >
            {highestValue ? formatCurrency(highestValue) : '$ -'}
          </Text>
        </div>
      </TextWrapper>
      <BarV2>
        <div className="lowest" />
        <div className="average" />
        <div className="highest" />
      </BarV2>
    </WrapperV2>
  )
}
