import { Badge } from '@loadsmart/miranda-react'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'

import { userUtils } from '_shared_/user'
import { IconMegaPhone } from 'components/Icons/top-bar'

export default function BeamerBadge() {
  if (userUtils.isMTCustomerUser || userUtils.isSupplierUser) {
    return null
  }

  return (
    <div
      className="beamerTrigger"
      aria-label="beamer badge"
      style={{ cursor: 'pointer' }}
    >
      <Badge counter={0}>
        <IconMegaPhone
          width="22"
          height="22"
          fill={toCSSValue('color-primary-60')}
          style={{ marginTop: '2px' }}
        />
      </Badge>
    </div>
  )
}
