import { Layout, Text } from '@loadsmart/loadsmart-ui'
import { useMemo, useState } from 'react'
import type { Dispatch, SetStateAction } from 'react'
import ReactPaginate from 'react-paginate'
import type { UseQueryResult } from 'react-query'

import { Container } from 'components/LaneManagement/LanesTableV3/styles'
import usePagination from 'hooks/usePagination'
import { defaultLaneTableParams, LIMIT } from 'rfp/components/table/laneParams'
import type { QueryParams } from 'rfp/components/table/laneParams'
import {
  getTablePageOffset,
  getTableSortDirection,
  useLaneIdMaxLength,
} from 'rfp/components/table/tableUtils'
import BaseTable from 'rfp/rfp-details/award-scenarios/BaseTable'
import EmptyState from 'rfp/rfp-details/award-scenarios/EmptyState'
import type { RequestForProposal } from 'rfp/rfp.types'
import { RFP_STATE } from 'utils/constants'
import { isMultiCurrency } from 'utils/currency'

import makeRankAutomationColumns from './makeRankAutomationColumns'

export const TYPES_OPTIONS = [
  { label: 'All Types', value: 'any' },
  { label: 'Asset', value: 'asset' },
  { label: 'Broker', value: 'broker' },
]

enum RadioOptions {
  LOWEST = 'lowest_bid',
  AVERAGE = 'average_bid',
  HIGHEST = 'highest_bid',
  LAST_AWARD = 'last_award',
  TARGET_RATE = 'target_rate',
  SPOT_MARKET = 'market_rate',
  SHORT_CONTRACT = 'contract_rate',
}

const RateOptions = [
  { value: 'market_rate', label: 'Market Benchmark' },
  { value: 'target_rate', label: 'Target Rate' },
  { value: 'last_award', label: 'Last Award' },
]

const MultiCurrencyRateOptions = RateOptions.filter(
  (option) => option.value === RadioOptions.LAST_AWARD
)

const NoMatchesEmptyState = () => {
  return (
    <EmptyState>
      <Layout.Stack space="s">
        <Text variant="heading-sm-bold" color="color-neutral-darkest">
          No Matches
        </Text>
        <Text variant="caption" color="color-neutral-darker">
          There are no available scenarios
          <br />
          based on the selected criteria.
        </Text>
      </Layout.Stack>
    </EmptyState>
  )
}

interface RankAutomationProps {
  readonly rfp: RequestForProposal
  readonly rankAutomationQuery: UseQueryResult<PaginatedResult, unknown>
  readonly rankAutomationParams: QueryParams
  readonly setRankAutomationParams: Dispatch<SetStateAction<QueryParams>>
  readonly showLaneDetailsOrRoutingGuide: (lane: Lane) => void
  readonly topRankFilter: number | null
  readonly typesFilter: string | null
  readonly isUpdatingScenarios: boolean
  readonly setScenariosSelected: Dispatch<SetStateAction<any[] | undefined>>
}

const RankAutomation = ({
  rfp,
  rankAutomationQuery,
  rankAutomationParams,
  setRankAutomationParams,
  showLaneDetailsOrRoutingGuide,
  topRankFilter,
  typesFilter,
  isUpdatingScenarios,
  setScenariosSelected,
}: RankAutomationProps) => {
  const shouldRenderDefaultState = [topRankFilter, typesFilter].every(
    (filter) => filter === null
  )

  const shouldRenderNoMatchesEmptyState =
    !rankAutomationQuery.isFetching &&
    rankAutomationQuery.data?.results?.length === 0

  const isLoading = [
    rankAutomationQuery.isLoading,
    rankAutomationQuery.isFetching,
    isUpdatingScenarios,
  ].some((condition) => Boolean(condition) === true)

  const { pageCount, page } = usePagination(
    LIMIT,
    rankAutomationQuery.data?.count,
    rankAutomationParams.page
  )

  const handlePageChange = (selectedItem: { selected: number }) => {
    setRankAutomationParams({
      ...rankAutomationParams,
      page: selectedItem.selected,
      offset: getTablePageOffset(
        selectedItem.selected,
        defaultLaneTableParams.offset
      ),
    })
  }

  const changeSorting = (column: string) => {
    setRankAutomationParams({
      ...rankAutomationParams,
      sort: {
        column,
        direction: getTableSortDirection(rankAutomationParams.sort.direction),
      },
    })
  }

  const displayMultiCurrency = isMultiCurrency(rfp.currency)
  const [bidOption, setRadioOptionOne] = useState<string>(RadioOptions.LOWEST)

  const getInitialRateOption = () =>
    displayMultiCurrency || rfp.state === RFP_STATE.DRAFT
      ? RadioOptions.LAST_AWARD
      : RadioOptions.SPOT_MARKET

  const [rateOption, setRadioOptionTwo] = useState<string>(
    getInitialRateOption()
  )

  const availableRateOptions = displayMultiCurrency
    ? MultiCurrencyRateOptions
    : RateOptions

  const laneIdMaxLength = useLaneIdMaxLength()

  const columns = useMemo(() => {
    return () =>
      makeRankAutomationColumns({
        laneIdMaxLength,
        rateOption,
        availableRateOptions,
        displayMultiCurrency,
        bidOption,
        rfp,
        setRadioOptionOne,
        setRadioOptionTwo,
      })
  }, [
    laneIdMaxLength,
    rateOption,
    availableRateOptions,
    displayMultiCurrency,
    bidOption,
    rfp,
  ])

  if (shouldRenderDefaultState) {
    return (
      <EmptyState>
        <Layout.Stack space="s">
          <Text variant="heading-sm-bold" color="color-neutral-darkest">
            No Lanes in Scenario
          </Text>
          <Text variant="caption" color="color-neutral-darker">
            Change the options above to
            <br />
            populate lanes
          </Text>
        </Layout.Stack>
      </EmptyState>
    )
  }

  if (shouldRenderNoMatchesEmptyState) {
    return <NoMatchesEmptyState />
  }

  return (
    <Container style={{ paddingBottom: '40px' }}>
      <BaseTable
        isLoading={isLoading}
        columns={columns}
        entries={rankAutomationQuery.data?.results ?? []}
        onRowClick={(_event, row) =>
          showLaneDetailsOrRoutingGuide(row.original)
        }
        sortable
        changeSorting={changeSorting}
        sort={rankAutomationParams.sort}
        updateSelection={setScenariosSelected}
      />
      {pageCount > 1 && (
        <ReactPaginate
          data-testid="pagination"
          previousLabel="Previous"
          nextLabel="Next"
          breakLabel="..."
          breakClassName="break-me"
          pageCount={pageCount}
          forcePage={page}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName="pagination"
          activeClassName="active"
        />
      )}
    </Container>
  )
}

export default RankAutomation
