import styled from 'styled-components'

export const ActionWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`
export const AmountV2 = styled.b<{ isSelected?: boolean }>`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  min-width: 80px;
  text-align: right;

  ${({ theme, isSelected }) => isSelected && `color: ${theme.colors.main};`}
`
export const WrapperV2 = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  height: 48px;
  padding: 0 30px;
`
