import type { QueryObserverOptions } from 'react-query'
import { useQuery } from 'react-query'

import {
  costPerformance,
  spotAnalytics,
  rfpAnalytics,
} from 'services/carrierAnalytics'
import { listPaginated } from 'services/carriers'

import { CarrierViewModes } from './constants'

const useFetchCarrierDetails = (
  query: string,
  limit: string,
  offset: string,
  sort: { column: string; direction: string },
  options: QueryObserverOptions<PaginatedResult>
) => {
  return useQuery<PaginatedResult>({
    ...options,
    queryKey: ['retrieveCarriers', query, limit, offset, sort, 'all'],
    queryFn: ({ signal }) =>
      listPaginated(query, limit, offset, sort, 'all', null, { signal }),
  })
}

const useCostPerformance = (
  search: string,
  limit: string,
  offset: string,
  sort: { column: string; direction: string },
  shipperUUID: string | undefined,
  options: QueryObserverOptions<PaginatedResult>
) => {
  return useQuery<PaginatedResult>({
    ...options,
    queryKey: ['costPerformance', limit, offset, sort, shipperUUID, search],
    queryFn: ({ signal }) =>
      costPerformance(limit, offset, sort, shipperUUID, search, { signal }),
  })
}

const useSpotAnalytics = (
  search: string,
  limit: string,
  offset: string,
  sort: { column: string; direction: string },
  shipperUUID: string | undefined,
  options: QueryObserverOptions<PaginatedResult>
) => {
  return useQuery<PaginatedResult>({
    ...options,
    queryKey: ['spotAnalytics', limit, offset, sort, shipperUUID, search],
    queryFn: ({ signal }) =>
      spotAnalytics(limit, offset, sort, shipperUUID, search, { signal }),
  })
}

export const useRFPAnalytics = (
  search: string,
  limit: string,
  offset: string,
  sort: { column: string; direction: string },
  shipperUUID: string | undefined,
  options: QueryObserverOptions<PaginatedResult>
) => {
  return useQuery<PaginatedResult>({
    ...options,
    queryKey: ['rfpAnalytics', limit, offset, sort, shipperUUID, search],
    queryFn: ({ signal }) => {
      return rfpAnalytics(limit, offset, sort, shipperUUID, search, { signal })
    },
  })
}

export const useCarrierAnalytics = (
  query: string,
  limit: string,
  offset: string,
  sort: { column: string; direction: string },
  view: string,
  shipperUUID: string | undefined,
  options: QueryObserverOptions<PaginatedResult>
) => {
  const {
    isFetching: isFetchingCarrierDetails,
    refetch: refetchCarrierDetails,
    data: dataCarrierDetails,
  } = useFetchCarrierDetails(query, limit, offset, sort, options)

  const {
    isFetching: isFetchingCostPerformance,
    refetch: refetchCostPerformance,
    data: dataCostPerformance,
  } = useCostPerformance(query, limit, offset, sort, shipperUUID, options)

  const {
    isFetching: isFetchingSpotAnalytics,
    refetch: refetchSpotAnalytics,
    data: dataSpotAnalytics,
  } = useSpotAnalytics(query, limit, offset, sort, shipperUUID, options)

  const {
    isFetching: isFetchingRFPAnalytics,
    refetch: refetchRFPAnalytics,
    data: dataRFPAnalytics,
  } = useRFPAnalytics(query, limit, offset, sort, shipperUUID, options)

  if (view === CarrierViewModes.PURCHASE_PERFORMANCE) {
    return {
      isFetching: isFetchingCostPerformance,
      refetch: refetchCostPerformance,
      data: dataCostPerformance,
    }
  }
  if (view === CarrierViewModes.SPOT_ANALYTICS) {
    return {
      isFetching: isFetchingSpotAnalytics,
      refetch: refetchSpotAnalytics,
      data: dataSpotAnalytics,
    }
  }
  if (view === CarrierViewModes.RFP_ANALYTICS) {
    return {
      isFetching: isFetchingRFPAnalytics,
      refetch: refetchRFPAnalytics,
      data: dataRFPAnalytics,
    }
  }

  return {
    isFetching: isFetchingCarrierDetails,
    refetch: refetchCarrierDetails,
    data: dataCarrierDetails,
  }
}
