import type { AxiosRequestConfig } from 'axios'

import { shouldFetchLinearFeet } from 'components/HandlingUnitsManager/useLinearFeet'
import type { HazmatItem } from 'components/HazmatInformation'
import { createHazmatItem } from 'components/HazmatInformation'
import type { NewShipmentShippingItemPayload } from 'shipments/shipments.services'
import AdapterFactory from 'utils/AdapterFactory'
import type { AttributeMapper } from 'utils/AdapterFactory'
import { INCHES_IN_A_CUBIC_FEET } from 'utils/constants'
import httpClient from 'utils/httpClient'
import { numberFormatter } from 'utils/numbers'
import { plural } from 'utils/strings'
import toArray from 'utils/toArray'
import { createTransient, isTransient } from 'utils/transient'
import type { Transient } from 'utils/transient'

import type { ShippingItem, Measurable } from './ShippingItems.types'

export const formatDimensionNumber = (dimension: number): string => {
  return numberFormatter(dimension, 2)
}

/**
 * This file aggregates functions from the following modules:
 * calculateTotalDimensions, calculateShipmentDimensions
 *
 * At some point we should refactor the above modules to use this file, so we don't perpetuate the duplication.
 */

/**
 * Calculate the total weight for the given measurables.
 */
export function calculateTotalWeight(
  measurables?: NonNullable<Measurable> | NonNullable<Measurable>[]
): number {
  return toArray(measurables).reduce((totalWeight, measurable) => {
    const weight = Number(measurable.weight)

    if (!Number.isNaN(weight)) {
      totalWeight += weight
    }

    return totalWeight
  }, 0)
}

/**
 * Calculate volume in cubic feet.
 */
export function calculateTotalVolume(
  measurables?: Measurable | Measurable[]
): number {
  return toArray(measurables).reduce((totalVolume, measurable) => {
    // these dimensions are in inches
    const length = Number(measurable.length)
    const width = Number(measurable.width)
    const height = Number(measurable.height)
    const quantity = Number(measurable.package_count)

    if (
      !Number.isNaN(length) &&
      !Number.isNaN(width) &&
      !Number.isNaN(height) &&
      !Number.isNaN(quantity)
    ) {
      totalVolume += toCubicFeet(length * width * height * quantity)
    }

    return totalVolume
  }, 0)
}

export function toCubicFeet(dimension: number) {
  return dimension / INCHES_IN_A_CUBIC_FEET
}

/**
 * Calculate the total density (weight / volume) for the given measurables.
 */
export function calculateTotalDensity(
  totalWeight: number,
  totalVolume: number
): number {
  if (
    !Number.isNaN(Number.parseFloat(String(totalWeight))) &&
    !Number.isNaN(totalVolume) &&
    totalVolume > 0
  ) {
    return totalWeight / totalVolume
  }

  return 0
}

export function createMeasurable(overrides?: Partial<Measurable>): Measurable {
  return {
    length: '',
    width: '',
    height: '',
    weight: '',
    package_count: '',
    ...overrides,
  }
}

export function createShippingItem(
  overrides?: Partial<ShippingItem>
): ShippingItem {
  return {
    ...createMeasurable(overrides),
    stackable: false,
    hazmat: false,
    turnable: false,
    commodity: '',
    freight_class: null,
    nmfc_code: '',
    package_type: null,
    ...overrides,
  }
}

/**
 * Creates a ShippingItem instance with additional _metadata to be used only in forms.
 */
export function createTransientShippingItem(commodity?: Partial<ShippingItem>) {
  return createTransient(createShippingItem(commodity))
}

export type LTLShippingItem = ShippingItem & HazmatItem

export function createTransientLTLShippingItem(
  commodity?: Partial<LTLShippingItem>
): Transient<LTLShippingItem> {
  return {
    ...createHazmatItem(commodity),
    ...createTransientShippingItem(commodity),
  }
}

export function toTransientShippingItems(
  commodities: Partial<ShippingItem> | Partial<ShippingItem>[]
) {
  return toArray(commodities).map((commodity) => {
    if (isTransient(commodity)) {
      return commodity
    }

    return createTransientShippingItem(commodity)
  })
}

const getValidNumber = (value?: string | number | null): number => {
  return Number(value) || 0
}

const packageCountMapper: AttributeMapper = {
  from: 'package_count',
  to: 'items_count',
  transform(units: Measurable['package_count']): number {
    return getValidNumber(units)
  },
}

const lengthMapper: AttributeMapper = {
  from: 'length',
  to: 'length',
  transform(length: Measurable['length']): number {
    return getValidNumber(length)
  },
}

const widthMapper: AttributeMapper = {
  from: 'width',
  to: 'width',
  transform(width: Measurable['width']): number {
    return getValidNumber(width)
  },
}

const heightMapper: AttributeMapper = {
  from: 'height',
  to: 'height',
  transform(height: Measurable['height']): number {
    return getValidNumber(height)
  },
}

const weightMapper: AttributeMapper = {
  from: 'weight',
  to: 'weight',
  transform(weight: Measurable['weight']): number {
    return getValidNumber(weight)
  },
}

const hazmatMapper: AttributeMapper = {
  from: 'hazmat',
  to: 'hazmat',
  transform(hazmat: Measurable['hazmat']): boolean {
    return Boolean(hazmat)
  },
}

const stackableMapper: AttributeMapper = {
  from: 'stackable',
  to: 'stackable',
  transform(stackable: Measurable['stackable']): boolean {
    return Boolean(stackable)
  },
}

const turnableMapper: AttributeMapper = {
  from: 'turnable',
  to: 'turnable',
  transform(turnable: Measurable['turnable']): boolean {
    return Boolean(turnable)
  },
}

export const MEASURABLE_TO_PAYLOAD_MAPPER: AttributeMapper[] = [
  packageCountMapper,
  lengthMapper,
  widthMapper,
  heightMapper,
  weightMapper,
  hazmatMapper,
  stackableMapper,
  turnableMapper,
  {
    from: '',
    to: 'total_volume',
    transform(_, measurable: Measurable) {
      return Number(formatDimensionNumber(calculateTotalVolume(measurable)))
    },
  },
]

export const adaptMeasurableToShipmentDimensionsPayload = AdapterFactory(
  MEASURABLE_TO_PAYLOAD_MAPPER
)

const CALCULATE_SHIPMENT_DIMENSIONS_MAPPER: AttributeMapper[] = [
  packageCountMapper,
  lengthMapper,
  widthMapper,
  heightMapper,
  weightMapper,
  stackableMapper,
  turnableMapper,
]

export const adaptCalculateShipmentDimensionsPayload = AdapterFactory(
  CALCULATE_SHIPMENT_DIMENSIONS_MAPPER
)

export const SHIPPING_ITEM_TO_PAYLOAD_MAPPER: AttributeMapper[] = [
  ...MEASURABLE_TO_PAYLOAD_MAPPER.slice(1),
  {
    from: 'commodity',
    to: 'commodity',
    transform(commodity: ShippingItem['commodity']) {
      return commodity ?? ''
    },
  },
  {
    from: 'package_count',
    to: 'package_count',
    transform(package_count: ShippingItem['package_count']) {
      return getValidNumber(package_count)
    },
  },
  {
    from: 'package_type',
    to: 'package_type',
    transform(package_type: ShippingItem['package_type']) {
      return package_type || null
    },
  },
  {
    from: 'nmfc_code',
    to: 'nmfc_code',
    transform(nmfc_code: ShippingItem['nmfc_code']) {
      return nmfc_code || null
    },
  },
  {
    from: 'freight_class',
    to: 'freight_class',
    transform(freight_class: ShippingItem['freight_class']) {
      return freight_class || null
    },
  },
  {
    from: 'orders',
    to: 'order_uuids',
    transform(orders: ShippingItem['orders']) {
      return orders?.map((o) => o.uuid)
    },
  },
]

export const adaptShippingItemToNewShipmentPayload = AdapterFactory<
  Omit<NewShipmentShippingItemPayload, 'total_volume'>,
  ShippingItem
>(SHIPPING_ITEM_TO_PAYLOAD_MAPPER)

export const LINEAR_FEET_URL = '/spot_quotes/calculate-shipment-dimensions'

export type ShipmentDimensions = {
  total_length_feet: number
  total_width_feet: number
  total_height_feet: number
}

export const calculateShipmentDimensions = async (
  measurables: Measurable | Measurable[],
  config?: AxiosRequestConfig<object[]>
): Promise<ShipmentDimensions | null> => {
  const measurablesArray = toArray(measurables)

  if (!shouldFetchLinearFeet(measurablesArray)) {
    return null
  }

  const { data } = await httpClient.post(
    LINEAR_FEET_URL,
    measurablesArray.map(adaptCalculateShipmentDimensionsPayload),
    config
  )

  return data
}

export function formatWeight(weight: number | string): string {
  weight = Number(weight)

  if (Number.isNaN(weight)) {
    return ''
  }

  return `${formatDimensionNumber(weight)} ${plural('lb', weight)}`
}

export function formatDimension(dimension: number | string): string {
  dimension = Number(dimension)

  if (Number.isNaN(dimension)) {
    return ''
  }

  return formatDimensionNumber(dimension)
}

export const formatVolume = (volume: number | string): string => {
  volume = Number(volume)

  if (Number.isNaN(volume)) {
    return ''
  }

  return `${formatDimensionNumber(volume)} ft³`
}

export const formatLinearFeet = (linearFeet: number | string): string => {
  linearFeet = Number(linearFeet)

  if (Number.isNaN(linearFeet)) {
    return ''
  }

  return `${formatDimensionNumber(linearFeet)} ft`
}

export function hasHazmat(measurables?: Measurable | Measurable[]): boolean {
  return toArray(measurables).some((measurable) => measurable.hazmat)
}

export function getYesNoAnswer(answer: boolean): 'Yes' | 'No' {
  return answer ? 'Yes' : 'No'
}
