import { Tooltip } from '@loadsmart/miranda-react'

import StringLimiter from '_shared_/components/StringLimiter'
import loadsmartLogo from 'assets/icons/loadsmart.svg'
import CarrierAwardindicator from 'rfp/components/CarrierAwardIndicator'
import { theme } from 'styles/theme'
import { RFP_STATE } from 'utils/constants'

import {
  CarrierCell as Wrapper,
  IconHourglassStyled,
  LSIcon,
} from '../lane-bids/styles'

const carrierMaxLength = 34
const carrierMaxLengthWithBidIcon = 24

export const CarrierCell = ({
  row,
  loadsmartDOT,
  rfpState,
}: {
  readonly row: any
  readonly loadsmartDOT: any
  readonly rfpState: string
}) => {
  const {
    under_negotiation: underNegotiation,
    award_confirmation: awardConfirmation,
  } = row.original
  const { name, dot } = row.original.carrier

  const tooltipMessage =
    awardConfirmation === 'accepted' ? 'Accepted' : 'Rejected'

  const stringLimiter = underNegotiation
    ? carrierMaxLengthWithBidIcon
    : carrierMaxLength

  return (
    <Wrapper>
      {underNegotiation && (
        <Tooltip message="New bid requested" trigger="hover">
          <IconHourglassStyled
            width={20}
            height={20}
            color={theme.colors.warningDark}
          />
        </Tooltip>
      )}
      {[RFP_STATE.ARCHIVED, RFP_STATE.FINALIZED, RFP_STATE.AWARDING].includes(
        rfpState
      ) &&
        (awardConfirmation ? (
          <CarrierAwardindicator
            status={awardConfirmation}
            tooltip={tooltipMessage}
          />
        ) : (
          <CarrierAwardindicator status="waiting" tooltip="Waiting" />
        ))}
      {dot !== loadsmartDOT ? (
        <StringLimiter value={name} limiter={stringLimiter} />
      ) : (
        <LSIcon type="image/svg+xml" data={loadsmartLogo}>
          {name}
        </LSIcon>
      )}
    </Wrapper>
  )
}
