import type { AxiosRequestConfig } from 'axios'

import type { TenderRejectionPredictionData } from 'quotes/Quote'
import httpClient from 'utils/httpClient'

export { createNewQuote } from './newQuote'

export const listSpotQuoteCarrier = async (
  spotQuoteUuid: string,
  config?: Omit<AxiosRequestConfig, 'params'>
) => {
  const { data } = await httpClient.get(
    `spot_quotes/${spotQuoteUuid}/carriers_available?sort=-invite_status,name`,
    config
  )
  return data
}

type inviteCarrierToBidParams = {
  spotQuoteUUID: string
  carriers: string[]
  inviteAll: boolean
  allowArchived?: boolean
  bookNowEnabled?: boolean
}

export const inviteCarrierToBid = async ({
  spotQuoteUUID,
  carriers,
  inviteAll,
  allowArchived = false,
  bookNowEnabled = false,
}: inviteCarrierToBidParams) => {
  const payload = {
    carriers,
    invite_all_carriers: inviteAll,
    allow_archived: allowArchived,
    book_now_enabled: bookNowEnabled,
  }
  const { data } = await httpClient.patch(
    `/spot_quotes/${spotQuoteUUID}/carrier_invite`,
    payload
  )
  return data
}

export const resendInviteCarrierToBid = async ({
  spotQuoteUUID,
  rateUUID,
  allowArchived,
}: {
  spotQuoteUUID: string
  rateUUID: string
  allowArchived?: boolean
}) => {
  const { data } = await httpClient.patch(
    `/spot_quotes/${spotQuoteUUID}/carrier_invite/${rateUUID}/resend`,
    { allow_archived: allowArchived }
  )
  return data
}

export const removeCarrierInvitation = async ({
  spotQuoteUUID,
  linkUUID,
}: {
  spotQuoteUUID: string
  linkUUID: string
}) => {
  const { data } = await httpClient.delete(
    `/spot_quotes/${spotQuoteUUID}/carrier_invite/${linkUUID}`
  )
  return data
}

export const uploadFiles = async ({
  quoteUUID,
  file,
}: {
  quoteUUID: string
  file: File[]
}) => {
  const formData = new FormData()
  file.forEach((f) => formData.append('attachments', f))
  const { data } = await httpClient.post(
    `/spot_quotes/${quoteUUID}/attachments`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
  return data
}

export const completeDetails = async (quoteUUID: string) => {
  const { data } = await httpClient.post(
    `/spot_quotes/${quoteUUID}/complete-details`
  )
  return data
}

export const askCarriersToBidAgain = async (quoteUUID: string) => {
  const { data } = await httpClient.post(`/spot_quotes/${quoteUUID}/bid-again`)
  return data
}

export const listPreferredLaneCarriers = async (
  quoteUUID: string,
  config?: AxiosRequestConfig
) => {
  const { data } = await httpClient.get<Array<PreferredLaneCarrier>>(
    `spot_quotes/${quoteUUID}/preferred_lane_carriers`,
    config
  )
  return data
}

export const fetchTenderRejectionPredictions = async (
  uuid: string,
  config?: AxiosRequestConfig
) => {
  const { data } = await httpClient.get<TenderRejectionPredictionData[]>(
    `/spot_quotes/${uuid}/tender-rejection-predictions`,
    config
  )

  return data
}
