import { IconQuestionCircle } from '@loadsmart/icons'
import {
  Button,
  Dropdown,
  DropdownContext,
  Layout,
  Popover,
  Radio,
  Text,
  Tooltip,
  TooltipAlign,
  TooltipPosition,
} from '@loadsmart/loadsmart-ui'
import { useContext } from 'react'
import type { Dispatch, SetStateAction } from 'react'

import truckArrivingImg from 'assets/imgs/truck-arriving.svg'
import {
  StyledCard,
  StyledCardBody,
  StyledCardSeparator,
  StyledCardTitle,
} from 'rfp/components/header-card-v2/styles'
import AllocateDialog from 'rfp/rfp-details/award-scenarios/AllocateDialog'
import { TYPES_OPTIONS } from 'rfp/rfp-details/rank/tabs/RankAutomation'
import type { Currency } from 'rfp/rfp.types'
import { theme } from 'styles/theme'
import { getCurrencyPrefix, isMultiCurrency } from 'utils/currency'
import formatCurrency from 'utils/formatCurrency'

const ActionTrigger = () => {
  const { toggle } = useContext(DropdownContext)

  return (
    <Popover.Reference>
      <Text
        as="a"
        style={{ cursor: 'pointer' }}
        variant="chips-sm"
        color="color-accent"
        onClick={toggle}
      >
        change
      </Text>
    </Popover.Reference>
  )
}

interface RankAutomationSummaryProps {
  readonly rfpId: string | number
  readonly rfpCurrency: Currency
  readonly stats: any
  readonly topRankFilter: number | null
  readonly setTopRankFilter: Dispatch<SetStateAction<number | null>>
  readonly typesFilter: string | null
  readonly setTypesFilter: Dispatch<SetStateAction<string | null>>
  readonly updateScenarios: (param: {
    rfpId: string | number
    laneIds: (string | number)[]
    updateAll: boolean
    carrierEntity: string
    topRank: number | null
  }) => void
  readonly scenariosSelected: undefined | any[]
  readonly allocateDialog: any
  readonly isUpdatingScenarios: boolean
  readonly canAllocateAll: boolean
  readonly scenariosCount: number
}

function getTopRankedOptions(numberOfOptions?: number) {
  if (!numberOfOptions) {
    return null
  }

  // Per design we need to show a max of 10 options
  return Array.from(Array(Math.min(numberOfOptions, 10)).keys()).map(
    (value) => ({
      label: `Top ${value + 1}`,
      value: value + 1,
    })
  )
}

const RankAutomationSummary = ({
  rfpId,
  rfpCurrency,
  stats,
  topRankFilter,
  setTopRankFilter,
  typesFilter,
  setTypesFilter,
  updateScenarios,
  scenariosSelected,
  allocateDialog,
  isUpdatingScenarios,
  canAllocateAll,
  scenariosCount,
}: RankAutomationSummaryProps) => {
  const MAX_RANK = stats?.max_rank_positions || 10
  const topRankOptions = getTopRankedOptions(stats?.max_rank_positions)

  const isStatsVisible = topRankFilter !== null || typesFilter !== null
  const allocateAll = () =>
    updateScenarios({
      rfpId,
      laneIds: [],
      updateAll: true,
      carrierEntity: typesFilter ?? '',
      topRank: topRankFilter ?? MAX_RANK,
    })

  const allocatedSelected = () =>
    updateScenarios({
      rfpId,
      laneIds: scenariosSelected ?? [],
      updateAll: false,
      carrierEntity: typesFilter ?? '',
      topRank: topRankFilter ?? MAX_RANK,
    })

  const getTotalSpend = () => {
    const currencyPrefix = getCurrencyPrefix(rfpCurrency)

    const totalAmount = isMultiCurrency(rfpCurrency)
      ? stats?.converted_total_award_rate
      : stats?.total_award_rate

    const formattedTotalAmount = totalAmount
      ? formatCurrency(totalAmount, true, false)
      : '0'

    return `${currencyPrefix}${formattedTotalAmount}`
  }

  const totalSpend = getTotalSpend()

  return (
    <StyledCard>
      <StyledCardTitle $isVisible={isStatsVisible}>
        <Layout.Group justify="space-between" align="center">
          <Layout.Group space="3xl">
            <Layout.Stack space="xs">
              <Layout.Group space="xs" align="center">
                <Text variant="chips-sm" color="color-neutral">
                  Scenarios
                </Text>
                <Tooltip
                  position={TooltipPosition.Bottom}
                  align={TooltipAlign.Start}
                  message={
                    <>
                      <b>Lowest Bid</b> is the first scenario available.
                      <br /> Please contact us to suggest new scenarios
                      <br /> that could be useful for your operations.
                    </>
                  }
                >
                  <IconQuestionCircle
                    height={12}
                    width={12}
                    fill={theme.colors.neutral}
                    title={null}
                  />
                </Tooltip>
              </Layout.Group>
              <Text variant="body-bold" color="color-neutral-darker">
                Lowest Bids
              </Text>
            </Layout.Stack>
            <Layout.Stack space="xs">
              <Layout.Group space="xs" align="center">
                <Text variant="chips-sm" color="color-neutral">
                  Type
                </Text>
                <Tooltip
                  position={TooltipPosition.Bottom}
                  message={
                    <>
                      Carriers without this information in your
                      <br /> <b>Carriers</b> page will only be considered
                      <br /> in the scenario if you select All Types.
                    </>
                  }
                >
                  <IconQuestionCircle
                    height={12}
                    width={12}
                    fill={theme.colors.neutral}
                    title={null}
                  />
                </Tooltip>
              </Layout.Group>
              <Text
                variant="body-bold"
                color={
                  typesFilter === null
                    ? 'color-neutral'
                    : 'color-neutral-darker'
                }
              >
                {typesFilter === null
                  ? 'Select'
                  : TYPES_OPTIONS.find((option) => option.value === typesFilter)
                      ?.label}
              </Text>
              <Dropdown>
                <ActionTrigger />
                <Dropdown.Menu>
                  {TYPES_OPTIONS.map(({ label, value }) => (
                    <Dropdown.Item
                      key={value}
                      onClick={() => {
                        setTypesFilter(value)
                      }}
                    >
                      <Radio
                        scale="small"
                        defaultChecked={typesFilter === value}
                        name="radio-types"
                        value={value}
                      >
                        {label}
                      </Radio>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Layout.Stack>
            <Layout.Stack space="xs">
              <Layout.Group space="xs" align="center">
                <Text variant="chips-sm" color="color-neutral">
                  Ranking
                </Text>
                <Tooltip
                  position={TooltipPosition.Bottom}
                  message="Set a number of carriers to rank"
                >
                  <IconQuestionCircle
                    height={12}
                    width={12}
                    fill={theme.colors.neutral}
                    title={null}
                  />
                </Tooltip>
              </Layout.Group>
              <Text
                variant="body-bold"
                color={
                  topRankFilter === null
                    ? 'color-neutral'
                    : 'color-neutral-darker'
                }
              >
                {topRankFilter === null
                  ? 'Select'
                  : topRankOptions?.find(
                      (option) => option.value === topRankFilter
                    )?.label}
              </Text>
              <Dropdown>
                <ActionTrigger />
                <Dropdown.Menu>
                  {topRankOptions !== null ? (
                    topRankOptions.map(({ label, value }) => (
                      <Dropdown.Item
                        key={value}
                        onClick={() => {
                          setTopRankFilter(value)
                        }}
                      >
                        <Radio
                          scale="small"
                          defaultChecked={topRankFilter === value}
                          name="radio-carriers"
                          value={value}
                        >
                          {label}
                        </Radio>
                      </Dropdown.Item>
                    ))
                  ) : (
                    <Dropdown.Item>
                      No carriers available <br /> to bid
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </Layout.Stack>
          </Layout.Group>
          <Layout.Group>
            <Button
              disabled={(scenariosSelected?.length ?? 0) === 0}
              onClick={allocatedSelected}
              variant="tertiary"
            >
              allocate selected
            </Button>
            <Button
              disabled={!canAllocateAll}
              onClick={allocateDialog.openModal}
              variant="secondary"
            >
              allocate all
            </Button>
          </Layout.Group>
        </Layout.Group>
      </StyledCardTitle>
      {isStatsVisible && (
        <>
          <StyledCardSeparator />
          <StyledCardBody>
            <Layout.Group justify="space-between">
              <Layout.Sidebar>
                <img src={truckArrivingImg} width="90px" alt="Truck arriving" />
                <Text
                  variant="caption"
                  color="color-neutral-darker"
                  style={{ alignSelf: 'center', whiteSpace: 'nowrap' }}
                >
                  Award Scenarios will only consider lanes in progress.
                  <br />
                  Lanes already allocated won&apos;t be affected.
                </Text>
              </Layout.Sidebar>
              <Layout.Group space="2xl" style={{ alignSelf: 'center' }}>
                <Layout.Stack space="xs">
                  <Layout.Group space="xs" align="center">
                    <Text variant="chips-sm" color="color-neutral">
                      Lanes
                    </Text>
                    <Tooltip
                      position={TooltipPosition.Bottom}
                      message={
                        <>
                          Percentage of lanes to be
                          <br /> allocated based on this scenario
                        </>
                      }
                    >
                      <IconQuestionCircle
                        height={12}
                        width={12}
                        fill={theme.colors.neutral}
                        title={null}
                      />
                    </Tooltip>
                  </Layout.Group>
                  <Text variant="body-bold" color="color-neutral-darker">
                    {stats?.lane_allocation_percentage ?? 0}%
                  </Text>
                </Layout.Stack>
                <Layout.Stack space="xs">
                  <Layout.Group space="xs" align="center">
                    <Text variant="chips-sm" color="color-neutral">
                      Volume
                    </Text>
                    <Tooltip
                      position={TooltipPosition.Bottom}
                      message={
                        <>
                          Percentage of volume to be
                          <br /> allocated based on this scenario
                        </>
                      }
                    >
                      <IconQuestionCircle
                        height={12}
                        width={12}
                        fill={theme.colors.neutral}
                        title={null}
                      />
                    </Tooltip>
                  </Layout.Group>
                  <Text variant="body-bold" color="color-neutral-darker">
                    {stats?.volume_allocation_percentage ?? 0}%
                  </Text>
                </Layout.Stack>
                <Layout.Stack space="xs">
                  <Layout.Group space="xs" align="center">
                    <Text variant="chips-sm" color="color-neutral">
                      Asset
                    </Text>
                    <Tooltip
                      position={TooltipPosition.Bottom}
                      message={
                        <>
                          Asset carriers selected
                          <br /> based on this scenario
                        </>
                      }
                    >
                      <IconQuestionCircle
                        height={12}
                        width={12}
                        fill={theme.colors.neutral}
                        title={null}
                      />
                    </Tooltip>
                  </Layout.Group>
                  <Text variant="body-bold" color="color-neutral-darker">
                    {stats?.asset_count ?? 0}
                  </Text>
                </Layout.Stack>
                <Layout.Stack space="xs">
                  <Layout.Group space="xs" align="center">
                    <Text variant="chips-sm" color="color-neutral">
                      Brokers
                    </Text>
                    <Tooltip
                      position={TooltipPosition.Bottom}
                      message={
                        <>
                          Brokers selected
                          <br /> based on this scenario
                        </>
                      }
                    >
                      <IconQuestionCircle
                        height={12}
                        width={12}
                        fill={theme.colors.neutral}
                        title={null}
                      />
                    </Tooltip>
                  </Layout.Group>
                  <Text variant="body-bold" color="color-neutral-darker">
                    {stats?.broker_count ?? 0}
                  </Text>
                </Layout.Stack>
                <Layout.Stack space="xs">
                  <Layout.Group space="xs" align="center">
                    <Text variant="chips-sm" color="color-neutral">
                      Total Spend
                    </Text>
                    <Tooltip
                      position={TooltipPosition.Bottom}
                      align={TooltipAlign.End}
                      message={
                        <>
                          Total spend based on
                          <br /> this scenario
                        </>
                      }
                    >
                      <IconQuestionCircle
                        height={12}
                        width={12}
                        fill={theme.colors.neutral}
                        title={null}
                      />
                    </Tooltip>
                  </Layout.Group>
                  <Text variant="body-bold" color="color-neutral-darker">
                    {totalSpend}
                  </Text>
                </Layout.Stack>
              </Layout.Group>
            </Layout.Group>
          </StyledCardBody>
        </>
      )}
      <AllocateDialog
        lanesCount={scenariosCount}
        allocate={allocateAll}
        isAllocating={isUpdatingScenarios}
        isOpen={allocateDialog.modalState}
        onClose={allocateDialog.closeModal}
        isRanked
        incompleteRankedLanes={stats?.incomplete_ranked_lanes}
        maxRankPositions={stats?.max_rank_positions}
      />
    </StyledCard>
  )
}

export default RankAutomationSummary
