import type { QueryObserverOptions } from 'react-query'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'

import { SHIPMENTS_SETTLEMENT_REPORTS } from '../../../screens/Shipper/Shipments/Details/constants'
import { getShipmentsSettlementReports } from '../../services'
import type { SettlementShipmentReport } from '../../types'

export const useListShipmentsSettlementReports = ({
  limit,
  offset,
  options,
}: Partial<{
  limit: number
  offset: number
  options?: QueryObserverOptions<PaginatedResult<SettlementShipmentReport>>
}> = {}) => {
  return useQuery<PaginatedResult<SettlementShipmentReport>>({
    refetchOnWindowFocus: false,
    retry: false,
    ...options,
    queryKey: [SHIPMENTS_SETTLEMENT_REPORTS, limit, offset],
    queryFn: ({ signal }) =>
      getShipmentsSettlementReports({
        limit,
        offset,
        config: { signal },
      }),
    onError: () => {
      toast.error('Failed to fetch exported payables. Try again!')
    },
  })
}
