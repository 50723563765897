import type { Dispatch, SetStateAction } from 'react'

import {
  getActionColumn,
  getBidOptionColumn,
  getDestOrDeliveryColumn,
  getLaneIdColumn,
  getMileageColumn,
  getOriginOrPickupColumn,
  getRankAutomationModeAndEquipmentColumn,
  getRateOptionColumn,
  getVSColumn,
} from 'rfp/components/table/columns'
import type { RequestForProposal } from 'rfp/rfp.types'
import { numberFormatter } from 'utils/numbers'

const optionsDict: Record<string, string> = {
  last_award: 'LAST AWARD',
  target_rate: 'TARGET RATE',
  market_rate: 'MARKET BENCHMARK',
  contract_rate: 'SHORT CONTR.',
  lowest_bid: 'LOWEST BID',
  average_bid: 'AVG. BID',
  highest_bid: 'HIGHEST BID',
}

const BidOptions = [
  {
    value: 'lowest_bid',
    label: 'Lowest Bid',
  },
  {
    value: 'average_bid',
    label: 'Avg. Bid',
  },
  {
    value: 'highest_bid',
    label: 'Highest Bid',
  },
]

export default function makeRankAutomationColumns({
  laneIdMaxLength,
  rateOption,
  availableRateOptions,
  displayMultiCurrency,
  bidOption,
  rfp,
  setRadioOptionOne,
  setRadioOptionTwo,
}: {
  laneIdMaxLength: number
  rateOption: string
  availableRateOptions: {
    value: string
    label: string
  }[]
  displayMultiCurrency: boolean
  bidOption: string
  rfp: RequestForProposal
  setRadioOptionOne: Dispatch<SetStateAction<string>>
  setRadioOptionTwo: Dispatch<SetStateAction<string>>
}) {
  const columns = [
    getLaneIdColumn(laneIdMaxLength),
    getOriginOrPickupColumn(),
    getDestOrDeliveryColumn(),
    getMileageColumn(rfp.distance_type),
    getRankAutomationModeAndEquipmentColumn(),
    {
      Header: 'BIDS',
      id: 'proposals_count',
      className: 'text-left',
      accessor: ({
        proposals_count,
      }: {
        proposals_count: string | number | undefined
      }) => numberFormatter(proposals_count),
    },
    getBidOptionColumn(
      optionsDict,
      bidOption,
      BidOptions,
      setRadioOptionOne,
      displayMultiCurrency,
      rfp.currency
    ),
    getVSColumn(bidOption, rateOption),
    getRateOptionColumn(
      optionsDict,
      rateOption,
      availableRateOptions,
      setRadioOptionTwo,
      displayMultiCurrency,
      rfp.currency
    ),
    getActionColumn(rfp.state),
  ]

  return columns
}
