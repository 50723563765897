import type { RefObject } from 'react'
import { useEffect, useState } from 'react'

export function usePreventPageScrollOver<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>
) {
  const element = ref.current
  const [isMouseOver, setMouseOver] = useState(false)

  useEffect(() => {
    const onMouseEnter = () => setMouseOver(true)
    const onMouseLeave = () => setMouseOver(false)

    element?.addEventListener('mouseenter', onMouseEnter)
    element?.addEventListener('mouseleave', onMouseLeave)

    return () => {
      element?.removeEventListener('mouseenter', onMouseEnter)
      element?.removeEventListener('mouseleave', onMouseLeave)
    }
  }, [element, setMouseOver])

  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      if (element && element.scrollHeight <= element.offsetHeight) {
        event.preventDefault()
      }
    }

    if (isMouseOver) {
      document.addEventListener('wheel', handleWheel, { passive: false })
    } else {
      document.removeEventListener('wheel', handleWheel)
    }

    return () => {
      document.removeEventListener('wheel', handleWheel)
    }
  }, [element, isMouseOver])
}
