import { IconQuestionCircle } from '@loadsmart/icons'
import { DatePicker } from '@loadsmart/loadsmart-ui'
import { Card, Field, Layout, TextField, Text } from '@loadsmart/miranda-react'
import { Controller } from 'react-hook-form'

import { ValidationError } from '../componentUtils'
import { getDateWithUTCHours } from '../date'
import { DatePickerWrapper } from '../styles'
import { getFieldStatus } from '../utils'
import { FormTooltip } from './styles'
import type { CarrierDeadlinesSectionProps } from './types'

export default function RFPCarrierDeadlinesSection({
  shouldRender,
  bidsSubmissionWatch,
  getTimeSplitted,
  setBidsDeadlineWithTime,
  getValues,
  control,
  errors,
  rfpState,
}: CarrierDeadlinesSectionProps) {
  if (!shouldRender) {
    return null
  }

  const errorMessagesEnabled = !rfpState || rfpState === 'draft'

  return (
    <Card>
      <Card.Title>Carrier deadlines</Card.Title>
      <Card.Divider />
      <Card.Body>
        <Layout.Stack gap="spacing-8">
          <Layout.Grid>
            <Field
              required
              style={{ flex: '1' }}
              status={getFieldStatus(errors.bidsDeadline)}
            >
              <Field.Label id="submitBidsByLabel">
                <Text>Bids deadline</Text>
                <FormTooltip
                  trigger="hover"
                  message="Select the date by which all carrier bids must be submitted"
                  placement="top-start"
                >
                  <IconQuestionCircle
                    width={14}
                    height={14}
                    aria-label="rfp-bids-deadline-date"
                    title={null}
                  />
                </FormTooltip>
              </Field.Label>
              <Controller
                name="bidsDeadline"
                control={control}
                render={({ field }) => (
                  <DatePickerWrapper>
                    <DatePicker
                      getInputProps={() => ({
                        'aria-labelledby': 'submitBidsByLabel',
                      })}
                      {...field}
                      value={field.value?.toISOString()}
                      onChange={(event) => {
                        if (event.target.value === null) {
                          field.onChange(event)
                          return
                        }

                        if (!bidsSubmissionWatch) {
                          field.onChange({
                            ...event,
                            target: {
                              ...event.target,
                              value: new Date(event.target.value),
                            },
                          })
                          return
                        }

                        field.onChange(
                          getDateWithUTCHours(
                            new Date(event.target.value),
                            getTimeSplitted(bidsSubmissionWatch)
                          )
                        )
                      }}
                    />
                  </DatePickerWrapper>
                )}
              />
              {errorMessagesEnabled && (
                <ValidationError
                  error={errors.bidsDeadline}
                  message={errors.bidsDeadline?.message}
                />
              )}
            </Field>
            <Field
              required
              style={{ flex: '1' }}
              status={getFieldStatus(errors.time)}
            >
              <Field.Label id="submissionsByLabel">
                <Text>Time</Text>
                <FormTooltip
                  trigger="hover"
                  message="Indicate the time by which bids must be submitted on the deadline date."
                  placement="top-start"
                >
                  <IconQuestionCircle
                    width={14}
                    height={14}
                    aria-label="rfp-bids-deadline-time"
                    title={null}
                  />
                </FormTooltip>
              </Field.Label>
              <Controller
                name="time"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="time"
                    onInput={(event) => {
                      const eventTarget = event.target
                      if (!eventTarget.value) {
                        field.onChange(event)
                        return
                      }

                      field.onChange(event)
                      setBidsDeadlineWithTime(
                        getValues('bidsDeadline'),
                        eventTarget.value
                      )
                    }}
                    aria-labelledby="submissionsByLabel"
                  />
                )}
              />
              <ValidationError
                error={errors.time}
                message={errors.time?.message}
              />
            </Field>
          </Layout.Grid>
        </Layout.Stack>
      </Card.Body>
    </Card>
  )
}
