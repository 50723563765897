import { scrollToTop } from 'utils/scroll'
import { hasTransientError } from 'utils/transient'

import { useFulfillmentFormContext } from '../../components/FulfillmentFormContext'
import { validate } from '../../domain/Fulfillment.validation'
import {
  createFulfillmentAdapter,
  createFulfillmentWithoutOrdersAdapter,
} from '../createFulfillment.adapter'
import { useCreateFulfillment } from './useCreateFulfillment'

export function useCreateFulfillmentForm() {
  const { fulfillment, setFulfillment, setPartialFulfillment } =
    useFulfillmentFormContext()
  const { mutate: createFulfillment, data, status } = useCreateFulfillment()
  const hasErrors = hasTransientError(fulfillment)

  const submitForm = () => {
    const [validatedFulfillment] = validate(fulfillment)

    if (hasTransientError(validatedFulfillment)) {
      setFulfillment(validatedFulfillment)
      scrollToTop()
      return
    }

    const withOrders = fulfillment.order_identifier_type === 'orders'

    if (withOrders) {
      createFulfillment({
        withOrders: true,
        payload: createFulfillmentAdapter(fulfillment),
      })
    } else {
      createFulfillment({
        withOrders: false,
        payload: createFulfillmentWithoutOrdersAdapter(fulfillment),
      })
    }
  }

  return {
    uuid: data?.data.uuid,
    fulfillment,
    hasErrors,
    status,
    submitForm,
    setFulfillment,
    setPartialFulfillment,
  }
}
