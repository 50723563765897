import { Link as BaseLink } from 'react-router-dom'
import styled from 'styled-components'

export const BackButton = styled(BaseLink)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.backgroundReturnLink};
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.text};
  display: flex;
  height: 36px;
  justify-content: center;
  margin: 0 20px;
  text-decoration: none;
  width: 36px;
`

export const Link = styled(BaseLink)`
  text-decoration: none;
  :after {
    content: '/';
    font-size: 20px;
    margin: 0 10px;
  }
  &:first-child {
    :after {
      content: '';
    }
  }
`
