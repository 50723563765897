import styled from 'styled-components'

import BaseButton from 'components/Button'
import BaseCard from 'components/Card'
import { hideStyledProps } from 'utils/styles'

export const Wrapper = styled.div``
export const Table = styled.table<{ bordered: boolean }>`
  ${({ bordered, theme }) =>
    bordered && `border: 1px solid ${theme.colors.neutralLighter};`}
  border-collapse: separate;
  border-radius: 4px;
  border-spacing: 0;
  font-size: 14px;
  width: 100%;
`
export const THead = styled.thead`
  background-color: transparent;
  position: relative;

  tr {
    &:hover {
      background-color: transparent;
    }
  }
`
export const TBody = styled.tbody`
  font-size: 12px;
  font-weight: 600;

  td {
    border-bottom: none;
  }
`

const getBodyChildBackground = (
  notStripped: boolean | undefined,
  strippedColor: string
) => (notStripped ? 'transparent' : strippedColor)

export const Tr = styled.tr<{
  isSelected?: boolean
  notStripped?: boolean
}>`
  cursor: pointer;
  height: 48px;

  :focus-within {
    background: ${({ theme }) => theme.colors.light};
  }

  :hover {
    background: ${({ theme }) => theme.colors.light};
  }

  ${({ isSelected, theme, notStripped }) =>
    isSelected
      ? `background-color: ${theme.colors.backgroundYellowLight};`
      : `
        tbody &:nth-child(odd) {
          background-color: ${getBodyChildBackground(
            notStripped,
            theme.colors.neutralLight
          )};
        }

        tbody &:hover {
          background-color: ${theme.colors.backgroundYellowLighter};
        }
    `}
`
export const Th = styled.th.withConfig(hideStyledProps(['width']))`
  border: none;
  color: ${({ theme }) => theme.colors.neutral};
  font-size: 12px;
  font-weight: 900;
  height: 48px;
  line-height: 16px;
  padding: 10px;
  white-space: nowrap;

  &.small {
    width: 30px;
  }
  &.medium {
    width: 60px;
  }
`
export const Td = styled.td<{ width?: string }>`
  border: none;
  padding: 10px;

  ${({ width }) =>
    width &&
    `
    width: ${width};
    `}

  &.small {
    width: 30px;
  }
  &.medium {
    width: 60px;
  }
  &.actions-cell {
    padding: 0 10px 0 0;
    width: 40px;
  }
  &.buttons-cell {
    width: 190px;
  }
  &.no-padding {
    padding: 0;
  }
  > .attenuated {
    color: ${({ theme }) => theme.colors.neutral};
  }
  .disabled-opacity {
    opacity: 0.5;
  }
`
export const TableControlsWrapper = styled(BaseCard)`
  border: 1px solid ${({ theme }) => theme.colors.neutralLighter};
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 20px 30px;
`
export const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  width: auto;
`
export const FilterContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  width: auto;
`
export const ActionsContainer = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
`
export const FilterButton = styled(BaseButton)`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 24px;
  justify-content: center;
  line-height: 0;
  margin-right: 6px;
  padding: 0 12px;
  width: auto;

  svg {
    margin-right: 5px;
  }

  &:first-of-type {
    margin-left: 20px;
    margin-right: 10px;
  }
`
export const UploadButton = styled(FilterButton)`
  justify-content: space-between;
  margin-right: 0;
  min-width: 131px;
`
export const DownloadButton = styled(FilterButton)`
  justify-content: space-between;
  min-width: 101px;
`
export const LoaderContainer = styled.div`
  justify-content: center;
  width: 100%;
`
export const SelectAllActionWrapper = styled.div`
  align-items: center;
  display: flex;
`
