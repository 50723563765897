import type { RootState } from 'app/rootReducer'

export const aliceCarriersSelector = (state: RootState): Carrier[] =>
  Object.values(state.carriers.aliceCarriers || {})

export const carriersQuerySelector = (state: RootState): string =>
  state.carriers.meta.search

export const aliceCarriersQuerySelector = (state: RootState): string =>
  state.carriers.meta.searchAlice

export const aliceCarriersIsLoadingSelector = (state: RootState) =>
  state.carriers.meta.isLoadingAliceCarriers
