import type { RequestForProposalDetail } from 'rfp/rfp.types'
import { BID_TYPE } from 'utils/constants'

import AwardDialogV2 from './components/award-dialog-v2'
import NoAwardDialog from './components/no-award-dialog'
import NoRankedAwardDialog from './components/no-ranked-award-dialog'
import RankedAwardDialog from './components/ranked-award-dialog'

const RankedDialog = ({
  hasAtLeastOneRankedAward,
  dialogProps,
}: {
  readonly hasAtLeastOneRankedAward: boolean
  readonly dialogProps: any
}) =>
  hasAtLeastOneRankedAward ? (
    <RankedAwardDialog {...dialogProps} />
  ) : (
    <NoRankedAwardDialog {...dialogProps} />
  )

const DefaultDialog = ({
  hasAtLeastOneAward,
  dialogProps,
}: {
  readonly hasAtLeastOneAward: boolean
  readonly dialogProps: any
}) =>
  hasAtLeastOneAward ? (
    <AwardDialogV2 {...dialogProps} />
  ) : (
    <NoAwardDialog {...dialogProps} />
  )

type AwardDialogProps = {
  readonly rfp: RequestForProposalDetail
  readonly isOpen: boolean
  readonly onClose: () => void
  readonly totalizers: RFPTotalizers | undefined
}

export default function AwardDialog({
  rfp,
  isOpen,
  onClose,
  totalizers,
}: AwardDialogProps) {
  const isRankedAward = rfp.bid_type === BID_TYPE.RANK
  const hasAtLeastOneAward = (totalizers?.total_carriers_awarded ?? 0) > 0
  const hasAtLeastOneRankedAward = (totalizers?.total_carriers_ranked ?? 0) > 0

  const dialogProps = {
    rfp,
    isOpen,
    onClose,
    totalizers,
  }

  return isRankedAward ? (
    <RankedDialog
      hasAtLeastOneRankedAward={hasAtLeastOneRankedAward}
      dialogProps={dialogProps}
    />
  ) : (
    <DefaultDialog
      hasAtLeastOneAward={hasAtLeastOneAward}
      dialogProps={dialogProps}
    />
  )
}
