import { Text } from '@loadsmart/loadsmart-ui'
import styled from 'styled-components'

import { FileZone as BaseFileZone } from 'components/ImportModal/styles'

export const FileZone = styled(BaseFileZone)`
  justify-content: center;
  padding-bottom: 0;
  padding-top: 0;
`

export const FileName = styled(Text)`
  max-width: 390px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
