import { format } from 'date-fns'
import type { FieldError, FieldErrorsImpl } from 'react-hook-form'
import * as Yup from 'yup'

import { REQUIRED_FIELD_MESSAGE } from 'constants/errors'
import type {
  AccessorialFormField,
  FormValue,
} from 'rfp/rfp-form/RFPFormDetails/types'
import type { RequestForProposalDetail, ExtraChargeType } from 'rfp/rfp.types'
import {
  BID_TYPE,
  CURRENCY,
  DEFAULT_CONVERSION_RATE,
  EMAIL_MAX_LENGTH,
} from 'utils/constants'
import {
  removeThousandsSeparators,
  removeTrailingDecimalZeroes,
} from 'utils/numbers'
import { getSelectedOption } from 'utils/rfp'

import {
  addBusinessDays,
  getCustomDateInUTC,
  isBusinessDayOver as businessDayOver,
  subtractBusinessDays,
  setHours,
  END_HOUR_OF_BUSINESS_DAY,
  setStartOfDay,
} from './date'

const getInitialExtraCharges = ({
  extraChargeTypes,
  shipperSettings,
  rfp,
}: {
  extraChargeTypes: ExtraChargeType[]
  shipperSettings: ShipperSettings
  rfp?: RequestForProposalDetail
}) => {
  const defaultExtraChargesFormField: AccessorialFormField[] = extraChargeTypes
    .filter((extraCharge) =>
      shipperSettings.accessorials.includes(extraCharge.id)
    )
    .map((extraCharge) => ({
      id: extraCharge.id,
      label: extraCharge.label,
      value: '',
      isChecked: true,
    }))

  if (!rfp) {
    return defaultExtraChargesFormField
  }

  return defaultExtraChargesFormField.map((extraCharge) => {
    const rfpExtraCharge = rfp.requested_extra_charges_intermediary.find(
      (extraChargeIntermediary) => extraCharge.id === extraChargeIntermediary.id
    )
    return {
      ...extraCharge,
      value: rfpExtraCharge?.shipper_notes ?? '',
      isChecked: Boolean(rfpExtraCharge),
    }
  })
}

export const getInitialPriceItemTypes = ({
  priceItemTypes,
  shipperSettings,
  rfp,
}: {
  priceItemTypes: PriceItemType[]
  shipperSettings: ShipperSettings
  rfp?: RequestForProposalDetail
}) => {
  const defaultAccessorialsFormField: AccessorialFormField[] = priceItemTypes
    .filter((priceItemType) =>
      shipperSettings.default_accessorials.includes(priceItemType.uuid)
    )
    .map((priceItemType) => ({
      uuid: priceItemType.uuid,
      label: priceItemType.label,
      value: '',
      isChecked: true,
    }))

  if (!rfp) {
    return defaultAccessorialsFormField
  }

  return defaultAccessorialsFormField.map((accessorial) => {
    const rfpAccessorial = rfp.requested_accessorials_intermediary.find(
      (accessorialIntermediary) =>
        accessorial.uuid === accessorialIntermediary.uuid
    )
    return {
      ...accessorial,
      value: rfpAccessorial?.shipper_notes ?? '',
      isChecked: Boolean(rfpAccessorial),
    }
  })
}

export const getInitialRequestedAccessorials = ({
  priceItemTypesEnabled,
  priceItemTypes,
  extraChargeTypes,
  shipperSettings,
  rfp,
}: {
  priceItemTypesEnabled: boolean
  priceItemTypes: PriceItemType[]
  extraChargeTypes: ExtraChargeType[]
  shipperSettings: ShipperSettings
  rfp?: RequestForProposalDetail
}) =>
  priceItemTypesEnabled
    ? getInitialPriceItemTypes({
        priceItemTypes,
        shipperSettings,
        rfp,
      })
    : getInitialExtraCharges({
        extraChargeTypes,
        shipperSettings,
        rfp,
      })

const getEditData = ({
  rateTypes,
  currencies,
  rfpBidTypesOptions,
  awardAcceptanceDeadlinesOptions,
  modeOptions,
  timePeriods,
  rfp,
  distanceOptions,
}: {
  rateTypes: SelectOption[]
  rfpBidTypesOptions: SelectOption[]
  currencies: SelectOption[]
  awardAcceptanceDeadlinesOptions: SelectOption[]
  modeOptions: SelectOption[]
  timePeriods: SelectOption[]
  rfp: RequestForProposalDetail
  distanceOptions: SelectOption[]
}) => {
  const {
    attachments,
    capacity_time_period,
    contract_start,
    contract_end,
    shipper_name = '',
    shipper_email = '',
    shipper_phone = '',
    save_contact_info = false,
    mode,
    name,
    rate_type,
    request_deadline,
    award_acceptance_deadline = '',
    currency,
    conversion_rate = DEFAULT_CONVERSION_RATE,
    bid_type,
    distance_type,
  } = rfp

  const getDistanceTypeInitValue = () => distance_type || 'miles'
  const getBidsDeadlineTime = (deadline: Date) => {
    const hour = String(deadline.getHours()).padStart(2, '0')
    const minutes = String(deadline.getMinutes()).padStart(2, '0')

    return `${hour}:${minutes}`
  }
  const distanceUnitDefault = getDistanceTypeInitValue()
  return {
    rfpTitle: name,
    mode: getSelectedOption(mode, modeOptions),
    rateType: getSelectedOption(rate_type, rateTypes),
    capacityTimePeriod: getSelectedOption(capacity_time_period, timePeriods),
    contractStartDate: new Date(contract_start),
    contractEndDate: new Date(contract_end),
    currency: getSelectedOption(currency, currencies),
    conversionRate: String(removeTrailingDecimalZeroes(conversion_rate)),
    bidType: getSelectedOption(bid_type, rfpBidTypesOptions),
    distance: distanceUnitDefault,
    distanceType: getSelectedOption(distanceUnitDefault, distanceOptions),
    bidsDeadline: new Date(request_deadline),
    time: getBidsDeadlineTime(new Date(request_deadline)),
    awardAcceptanceDeadline: getSelectedOption(
      award_acceptance_deadline,
      awardAcceptanceDeadlinesOptions
    ),
    shipperName: shipper_name,
    shipperEmail: shipper_email,
    shipperPhone: shipper_phone,
    saveContactInfo: Boolean(save_contact_info),
    attachments: attachments.map(({ file_name }: { file_name: string }) => {
      return {
        name: `${file_name}`,
      }
    }),
  }
}

function getContractStartDate() {
  const date = new Date()

  const daysToBeAdded = businessDayOver(date) ? 2 : 1
  const updatedDate = addBusinessDays(date, daysToBeAdded)

  return updatedDate
}

function getContractEndDate() {
  return addBusinessDays(getContractStartDate(), 1)
}

export const getInitialValues = ({
  rateTypes,
  currencies,
  rfpBidTypesOptions,
  awardAcceptanceDeadlinesOptions,
  extraChargeTypes,
  priceItemTypesEnabled,
  shipperSettings,
  shipperData,
  modeOptions,
  timePeriods,
  rfp,
  distanceOptions,
}: {
  rateTypes: SelectOption[]
  rfpBidTypesOptions: SelectOption[]
  currencies: SelectOption[]
  awardAcceptanceDeadlinesOptions: SelectOption[]
  extraChargeTypes: ExtraChargeType[]
  priceItemTypesEnabled: boolean
  shipperSettings: any
  shipperData: Shipper
  modeOptions: SelectOption[]
  timePeriods: SelectOption[]
  rfp?: RequestForProposalDetail
  distanceOptions: SelectOption[]
}) => {
  const isEditMode = rfp

  if (isEditMode) {
    return getEditData({
      rateTypes,
      currencies,
      rfpBidTypesOptions,
      awardAcceptanceDeadlinesOptions,
      modeOptions,
      timePeriods,
      rfp,
      distanceOptions,
    })
  }

  const saveContactInfo = Boolean(shipperData?.save_contact_info)

  const requestedAccessorials = !priceItemTypesEnabled
    ? getInitialExtraCharges({
        extraChargeTypes,
        shipperSettings,
      })
    : []

  return {
    rfpTitle: '',
    mode: null,
    rateType: shipperSettings?.fuel_surcharge
      ? getSelectedOption(shipperSettings.fuel_surcharge, rateTypes)
      : null,
    capacityTimePeriod: {},
    contractStartDate: getContractStartDate(),
    contractEndDate: getContractEndDate(),
    currency: getSelectedOption(CURRENCY.USD, currencies),
    conversionRate: DEFAULT_CONVERSION_RATE,
    bidType: getSelectedOption(BID_TYPE.AWARD, rfpBidTypesOptions),
    distance: 'miles',
    distanceType: getSelectedOption('miles', distanceOptions),
    accessorials: requestedAccessorials,
    bidsDeadline: setHours(
      subtractBusinessDays(getContractStartDate(), 1),
      END_HOUR_OF_BUSINESS_DAY
    ),
    time: '17:00',
    awardAcceptanceDeadline: getSelectedOption(
      3,
      awardAcceptanceDeadlinesOptions
    ),
    shipperName: saveContactInfo ? shipperData?.contact_name || '' : '',
    shipperEmail: saveContactInfo ? shipperData?.contact_email || '' : '',
    shipperPhone: saveContactInfo ? shipperData?.contact_phone || '' : '',
    saveContactInfo,
    attachments: [],
  }
}

export const getRawInitialValues = () => ({
  rfpTitle: '',
  mode: '',
  rateType: {},
  capacityTimePeriod: {},
  contractStartDate: new Date(),
  contractEndDate: new Date(),
  currency: {},
  conversionRate: DEFAULT_CONVERSION_RATE,
  bidType: {},
  distance: 'miles',
  distanceType: {},
  accessorials: [{}],
  bidsDeadline: new Date(),
  time: '17:00',
  awardAcceptanceDeadline: {},
  shipperName: '',
  shipperEmail: '',
  shipperPhone: '',
  saveContactInfo: false,
  attachments: [],
})

export const RFPFormSchema = Yup.object({
  rfpTitle: Yup.string().min(2).max(255).required(REQUIRED_FIELD_MESSAGE),
  mode: Yup.object()
    .shape({
      value: Yup.string(),
      label: Yup.string(),
    })
    .nullable(),
  rateType: Yup.object()
    .shape({
      value: Yup.string().required().min(1),
      label: Yup.string().required().min(1),
    })
    .required(),
  capacityTimePeriod: Yup.object()
    .shape({
      value: Yup.string().required().min(1),
      label: Yup.string().required().min(1),
    })
    .required(),
  contractStartDate: Yup.date()
    .required()
    .test('min', 'Must be later than today', (value) => {
      if (!value) {
        return false
      }

      const startOfDay = setStartOfDay(new Date())

      return value > startOfDay
    })
    .typeError(REQUIRED_FIELD_MESSAGE),
  contractEndDate: Yup.date()
    .min(Yup.ref('contractStartDate'), 'Must be later than the start date.')
    .required()
    .typeError(REQUIRED_FIELD_MESSAGE),
  currency: Yup.object()
    .shape({
      value: Yup.string().required().min(1),
      label: Yup.string().required().min(1),
    })
    .required(),
  conversionRate: Yup.string().when('currency', {
    is: (obj: Record<string, unknown> | null) => obj?.value !== CURRENCY.USD,
    then: Yup.string().required('This field is required for non USD bids.'),
  }),
  bidType: Yup.object()
    .shape({
      value: Yup.string().required().min(1),
      label: Yup.string().required().min(1),
    })
    .required(),
  distance: Yup.string().notRequired(),
  distanceType: Yup.object()
    .shape({
      value: Yup.string().required().min(1),
      label: Yup.string().required().min(1),
    })
    .notRequired(),
  accessorials: Yup.array(),
  bidsDeadline: Yup.date()
    .required()
    .test('max', 'Must be before the contract start', (value, textContext) => {
      if (!value) {
        return false
      }

      const valueCopy = new Date(value)

      // We do this normalization because we allow the same day to be picked
      // and in this case the hour does not matter, only the date.
      const startDate: Date = textContext.parent.contractStartDate

      if (!startDate) {
        return false
      }

      const startDateCopy = new Date(startDate)

      startDateCopy.setHours(0, 0, 0, 0)
      valueCopy.setHours(0, 0, 0, 0)

      return valueCopy <= startDateCopy
    })
    .test('min', 'Must be later than now.', (value) => {
      if (!value) {
        return false
      }

      const parsedTime = getCustomDateInUTC(value, {
        hours: value.getUTCHours(),
        minutes: value.getUTCMinutes(),
        seconds: value.getUTCSeconds(),
      })

      return new Date() < parsedTime
    })
    .typeError(REQUIRED_FIELD_MESSAGE),
  time: Yup.string().matches(/\d{2}:\d{2}/, 'Time is not valid'),
  awardAcceptanceDeadline: Yup.object()
    .shape({
      value: Yup.string().required().min(1),
      label: Yup.string().required().min(1),
    })
    .required(),
  shipperName: Yup.string(),
  shipperEmail: Yup.string()
    .max(EMAIL_MAX_LENGTH, 'Email is too long.')
    .email('Must be a valid email.'),
  shipperPhone: Yup.string().max(50, 'Phone is too long.'),
  saveContactInfo: Yup.boolean(),
  attachments: Yup.array().max(3),
})

const mapAccessorialsFormValuesToApi = (
  requestedAccessorialsField: AccessorialFormField[],
  priceItemTypesEnabled: boolean
) => {
  const selectedAccessorials = requestedAccessorialsField.filter(
    (accessorial) => accessorial.isChecked
  )

  if (priceItemTypesEnabled) {
    const requestedAccessorialsIntermediary: RfpPriceItemType[] =
      selectedAccessorials.map((accessorial) => ({
        uuid: accessorial.uuid!,
        shipper_notes: accessorial.value || '',
      }))

    return {
      requested_accessorials: selectedAccessorials.map(
        (accessorial) => accessorial.uuid!
      ),
      requested_accessorials_intermediary: requestedAccessorialsIntermediary,
    }
  }

  const requestedExtraChargesIntermediary: RfpExtraCharge[] =
    selectedAccessorials.map((extraCharge) => ({
      id: extraCharge.id!,
      shipper_notes: extraCharge.value || '',
    }))

  return {
    requested_extra_charges: selectedAccessorials.map(
      (extraCharge) => extraCharge.id!
    ),
    requested_extra_charges_intermediary: requestedExtraChargesIntermediary,
  }
}

export const getTimeSplitted = (time: string) => {
  const [hours, minutes] = time.split(':')

  return {
    hours: Number(hours),
    minutes: Number(minutes),
    seconds: 0,
  }
}

export const mapFormValuesToApi = (
  formValues: FormValue,
  priceItemTypesEnabled: boolean
) => {
  const conversionRate = removeThousandsSeparators(
    formValues.conversionRate
  ) as string

  const awardAcceptanceDeadline =
    formValues.awardAcceptanceDeadline?.value.toString() ?? ''

  const distanceType =
    formValues.distanceType?.value.toString() ?? formValues.distance ?? 'miles'

  return {
    name: formValues.rfpTitle,
    rate_type: String(formValues.rateType.value),
    mode: formValues.mode?.value || '',
    state: 'draft',
    contract_start: format(
      new Date(formValues.contractStartDate),
      'yyyy-LL-dd'
    ),
    contract_end: format(new Date(formValues.contractEndDate), 'yyyy-LL-dd'),
    shipper_name: formValues.shipperName,
    shipper_email: formValues.shipperEmail,
    shipper_phone: formValues.shipperPhone,
    currency: formValues.currency.value,
    conversion_rate: conversionRate,
    bid_type: String(formValues.bidType.value),
    save_contact_info: formValues.saveContactInfo ? 'true' : 'false',
    request_deadline: getCustomDateInUTC(
      formValues.bidsDeadline,
      getTimeSplitted(formValues.time)
    ).toISOString(),
    capacity_time_period: formValues.capacityTimePeriod?.value,
    award_acceptance_deadline: awardAcceptanceDeadline,
    distance_type: distanceType,
    ...mapAccessorialsFormValuesToApi(
      formValues.accessorials,
      priceItemTypesEnabled
    ),
  }
}

export const getFieldStatus = (
  error:
    | Merge<
        FieldError,
        (FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined)[]
      >
    | undefined
) => (error ? 'danger' : 'default')
