import { Card } from '@loadsmart/loadsmart-ui'
import { useMemo } from 'react'

import {
  StyledBody,
  StyledTitle,
  TitleLoaderWrapper,
} from 'components/SideBar/styles'
import TableV3 from 'components/TableV3'
import type { Currency, RequestForProposal } from 'rfp/rfp.types'
import { RFP_STATE } from 'utils/constants'
import { isMultiCurrency } from 'utils/currency'

import makeColumns from './makeColumns'

export default function RankedAwards({
  laneProposals,
  isLoadingLaneProposals,
  isUpdatingRankedAwards,
  rfpCurrency,
  rfpState,
  rfpDistanceType,
  handleRemoveFromRank,
  handleMoveProposalInRank,
  handleMoveToEdgesInRank,
}: {
  readonly laneProposals: LaneProposal[]
  readonly isLoadingLaneProposals: boolean
  readonly isUpdatingRankedAwards: boolean
  readonly rfpCurrency: Currency
  readonly rfpState: RfpState
  readonly rfpDistanceType: RequestForProposal['distance_type']
  readonly handleRemoveFromRank: (proposal: LaneProposal) => void
  readonly handleMoveProposalInRank: (
    proposal: LaneProposal,
    direction: 'up' | 'down'
  ) => void
  readonly handleMoveToEdgesInRank: (
    proposal: LaneProposal,
    edge: 'top' | 'bottom'
  ) => void
}) {
  const displayMultiCurrency = isMultiCurrency(rfpCurrency)
  const rankedAwardsLength = useMemo(
    () => laneProposals?.length ?? 0,
    [laneProposals]
  )

  const canUpdateProposals = ![
    RFP_STATE.AWARDING,
    RFP_STATE.FINALIZED,
    RFP_STATE.ARCHIVED,
  ].includes(rfpState)

  const rankedAwards = useMemo(() => {
    return rankedAwardsLength < 10 && canUpdateProposals
      ? [
          ...laneProposals,
          {
            placeholder: true,
            awarded_rank: rankedAwardsLength + 1,
            carrier: {
              id: 'placeholder',
              name: 'Select a Carrier',
            },
          },
        ]
      : laneProposals
  }, [laneProposals, rankedAwardsLength, canUpdateProposals])

  const disableRankingActions = !canUpdateProposals || isUpdatingRankedAwards

  const columns = useMemo(() => {
    return () =>
      makeColumns({
        rfpCurrency,
        rfpDistanceType,
        rankedAwardsLength,
        displayMultiCurrency,
        handleRemoveFromRank,
        handleMoveProposalInRank,
        handleMoveToEdgesInRank,
        disableRankingActions,
      })
  }, [
    rfpCurrency,
    rfpDistanceType,
    rankedAwardsLength,
    displayMultiCurrency,
    handleRemoveFromRank,
    handleMoveProposalInRank,
    handleMoveToEdgesInRank,
    disableRankingActions,
  ])

  return (
    <>
      <StyledTitle
        style={{ marginBottom: '30px' }}
        data-testid="ranked-awards-card-header"
      >
        Rank
        {isUpdatingRankedAwards && <TitleLoaderWrapper />}
      </StyledTitle>
      <StyledBody>
        <TableV3
          columns={columns}
          entries={rankedAwards}
          isLoading={isLoadingLaneProposals}
          initialState={{ sortBy: [{ id: 'awarded_rank' }] }}
          bordered={false}
          data-testid="ranked-awards-table"
        />
        <Card.Separator style={{ margin: '30px 0' }} />
      </StyledBody>
    </>
  )
}
