import { Dialog, LoadingDots, Text } from '@loadsmart/loadsmart-ui'

import { SpinnerWrapper } from 'rfp/rfp-details/styles'

interface Props {
  readonly isOpen: boolean
  readonly isDeleting: boolean
  readonly onClose: () => void
  readonly lanesCount: number
  readonly deleteLanes: () => void
}

export default function DeleteLanesDialog({
  isOpen,
  isDeleting,
  lanesCount,
  onClose,
  deleteLanes,
}: Props) {
  return (
    <Dialog open={isOpen} scale="small" onOverlayClick={onClose}>
      <Dialog.Header>Delete lanes</Dialog.Header>
      <Dialog.Body>
        <Text variant="body" color="color-neutral-dark">
          You&apos;re about to delete{' '}
          {`${lanesCount} ${lanesCount === 1 ? 'lane' : 'lanes'}`}
          <br />
          from the RFP.
        </Text>
        <br />
        <br />
        <Text variant="body" color="color-neutral-dark">
          This will delete the {`${lanesCount === 1 ? 'lane' : 'lanes'}`} <br />
          permanently
        </Text>
      </Dialog.Body>
      <Dialog.ActionConfirm onConfirm={deleteLanes} disabled={isDeleting}>
        <SpinnerWrapper>
          {isDeleting ? <LoadingDots variant="light" /> : <>Delete</>}
        </SpinnerWrapper>
      </Dialog.ActionConfirm>
      <Dialog.ActionCancel onCancel={onClose} />
    </Dialog>
  )
}
