import { Banner, Field } from '@loadsmart/miranda-react'
import type { FieldError, FieldErrorsImpl } from 'react-hook-form'

import { REQUIRED_FIELD_MESSAGE } from 'constants/errors'
import { MirandaPageGlobalStyle } from 'styles/global'
import { SUPPORT_EMAIL } from 'utils/constants'

import RFPFormHeader from './formHeader'
import type { FormHeaderProps, RankingHitProps } from './types'

export const ErrorBanner = ({ hasError }: { readonly hasError: boolean }) => {
  if (!hasError) {
    return null
  }

  return (
    <Banner variant="danger">
      <Banner.Title>Could not submit RFP.</Banner.Title>
      <Banner.Description>
        Please reach our support at {SUPPORT_EMAIL}.
      </Banner.Description>
    </Banner>
  )
}

export const RequiredErrorMessage = ({
  error,
}: {
  readonly error:
    | Merge<
        FieldError,
        (FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined)[]
      >
    | undefined
}) => {
  if (error) {
    return <Field.Hint>{REQUIRED_FIELD_MESSAGE}</Field.Hint>
  }

  return null
}

export const RankHint = ({ error, watchBidType }: RankingHitProps) => {
  if (!error && watchBidType?.value === 'rank') {
    return (
      <Field.Hint>
        Rank your carriers. Note that Award Scenarios is not available with this
        option
      </Field.Hint>
    )
  }

  return null
}

export const NewFormHeader = ({
  rfp,
  shouldRender,
  cancelCreation,
  createRFP,
  isCreationEnable,
}: FormHeaderProps) => {
  if (!shouldRender) {
    return null
  }

  return (
    <>
      <MirandaPageGlobalStyle />
      <RFPFormHeader
        pageAction={rfp ? 'Save' : 'Create'}
        handleCancel={cancelCreation}
        handleCreateRFP={createRFP}
        isCreationEnabled={isCreationEnable}
      />
    </>
  )
}

export const ValidationError = ({
  error,
  message,
}: {
  readonly error: FieldError | undefined
  readonly message: string | undefined
}) => {
  if (error && message) {
    return <Field.Hint>{message}</Field.Hint>
  }

  return null
}
