import { yupResolver } from '@hookform/resolvers/yup'
import { IconTrash } from '@loadsmart/icons'
import { Button, Drawer, Layout } from '@loadsmart/miranda-react'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import * as Sentry from '@sentry/react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { create, patch, remove } from 'services/lane'
import analytics, {
  AnalyticsEvent,
  AnalyticsEventTrigger,
} from 'utils/analytics'

import LaneCustomFields from './LaneCustomFields'
import LaneDetailsSection from './LaneDetails'
import LaneNotesSection from './LaneNotes'
import LaneTargetRateSection from './LaneTargetRate'
import ModeAndEquipmentsSection from './ModeAndEquipments'
import type {
  CreateLaneButtonsProps,
  EditLaneButtonsProps,
  LaneDetailsDrawer,
} from './types'
import {
  LaneFormSchema,
  getRawInitialValues,
  getDatabaseValues,
  parseLaneFormValuesToPayload,
} from './utils'

const CreateLaneActionButtons = ({
  onDrawerClose,
  isSubmitting,
  handleSubmit,
  saveAndCreateNext,
  saveAndClose,
  showSaveAndAddNextButton = false,
}: CreateLaneButtonsProps) => {
  return (
    <Layout.Group style={{ justifyContent: 'end' }}>
      <Button
        onClick={onDrawerClose}
        variant="tertiary"
        disabled={isSubmitting}
      >
        Cancel
      </Button>
      {/*
        This button will be deactivated for now because the current implementation of the form
        has some issues regarding the reset of the fields, triggered by this button.
        The react-hook-form tries to use the refs from the fields to reset their values, but
        the refs from the raw input tags are not being exposed by miranda-react, only the refs
        of the wrappers of the components.
        Once we have a solution to this issue, we'll add the button back.
      */}
      {showSaveAndAddNextButton && (
        <Button
          type="submit"
          variant="secondary"
          onClick={handleSubmit(saveAndCreateNext)}
          disabled={isSubmitting}
        >
          Save & add next lane
        </Button>
      )}
      <Button
        type="submit"
        variant="primary"
        onClick={handleSubmit(saveAndClose)}
        disabled={isSubmitting}
      >
        Save lane & close
      </Button>
    </Layout.Group>
  )
}

const EditLaneActionButtons = ({
  handleDelete,
  isSubmitting,
  onDrawerClose,
  handleSubmit,
  saveAndClose,
}: EditLaneButtonsProps) => {
  return (
    <Layout.Group style={{ justifyContent: 'space-between' }}>
      <Button variant="danger" onClick={handleDelete} disabled={isSubmitting}>
        <IconTrash
          width={14}
          height={14}
          aria-label="lane-preference"
          title={null}
        />
        Delete lane
      </Button>
      <Layout.Group>
        <Button
          onClick={onDrawerClose}
          variant="tertiary"
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="primary"
          onClick={handleSubmit(saveAndClose)}
          disabled={isSubmitting}
        >
          Save lane
        </Button>
      </Layout.Group>
    </Layout.Group>
  )
}

export default function NewLaneDrawer({
  isOpen,
  handleCloseDrawer,
  rfpDistanceType,
  rfpCurrency,
  rfpID,
  rfpExtraFieldsHeaders,
  selectedLane,
  rfpModeOptions,
  equipmentTypesByModeOptions,
  showSaveAndAddNextButton = false,
}: LaneDetailsDrawer) {
  const {
    control,
    reset,
    setValue,
    watch,
    formState: { errors, isSubmitting },
    getValues,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(LaneFormSchema),
    mode: 'onChange',
    // react-hook-form needs defaultValues to be defined
    defaultValues: getRawInitialValues(),
  })

  useEffect(() => {
    reset(getDatabaseValues(selectedLane, rfpDistanceType))
  }, [reset, rfpDistanceType, selectedLane])

  const [isDeletingLane, setIsDeletingLane] = useState(false)

  const watchOrigin = watch('origin')
  const watchDestination = watch('destination')
  const watchMileage = watch('mileage')
  const watchMileageSource = watch('mileage_source')
  const watchExtraStops = watch('additional_stops') || []
  const watchLanePreferences = watch('preferences') || []
  const queryClient = useQueryClient()

  const onDrawerClose = () => {
    reset()
    handleCloseDrawer()
  }

  if (!isOpen) {
    return null
  }

  const reloadLists = async () => {
    await queryClient.refetchQueries({
      queryKey: ['rfpTotalizers', rfpID.toString()],
    })

    await queryClient.refetchQueries({
      queryKey: ['deallocatedLanes'],
      exact: false,
    })
  }

  const saveLane = async (data: any) => {
    try {
      const payload: Partial<Lane> = parseLaneFormValuesToPayload(data)

      if (selectedLane) {
        const result = await patch({
          rfpId: rfpID,
          laneId: selectedLane.id,
          lane: payload,
        })
        analytics.track(
          AnalyticsEvent.RFPSaveLaneSuccess,
          AnalyticsEventTrigger.success,
          result,
          ['lane']
        )
      } else {
        const result = await create(rfpID, payload)
        analytics.track(
          AnalyticsEvent.RFPSaveLaneSuccess,
          AnalyticsEventTrigger.success,
          result,
          ['lane']
        )
      }
      toast.success('Lane successfully saved')
    } catch (error: any) {
      if (error.response?.status === 406) {
        toast.error('Please, keep the number of lanes to a maximum of 2000.')
      } else {
        Sentry.captureException(error)
        toast.error('Failed to create the lane')
      }
    } finally {
      await reloadLists()
    }
  }

  const handleDeleteLane = async () => {
    try {
      if (selectedLane) {
        setIsDeletingLane(true)
        remove({
          rfpId: rfpID,
          laneId: selectedLane.id,
        })
        toast.success('Lane successfully deleted')
      }
    } catch (error) {
      Sentry.captureException(error)
      toast.error('Failed to delete the lane')
    } finally {
      await reloadLists()
      setIsDeletingLane(false)
      onDrawerClose()
    }
  }

  const saveAndClose = async (data: any) => {
    await saveLane(data)
    onDrawerClose()
  }

  const saveAndCreateNext = async (data: any) => {
    await saveLane(data)
    reset()
  }

  return (
    <Drawer
      open={isOpen}
      size="medium"
      onClose={onDrawerClose}
      data-testid="new-rfp-lane-drawer"
      //@ts-expect-error - We don't have access to the gap and padding properties directly
      style={{ '--gap': 0, '--padding': 0 }}
    >
      <Drawer.Header
        role="heading"
        style={{
          paddingInline: toCSSValue('spacing-6'),
          paddingBlock: toCSSValue('spacing-6'),
        }}
      >
        {selectedLane ? 'Edit lane' : 'Add lane'}
        <Drawer.Close
          onClick={() => onDrawerClose()}
          aria-label="Close Lanes drawer"
        />
      </Drawer.Header>
      <Drawer.Body
        style={{
          paddingInline: toCSSValue('spacing-6'),
          paddingBlock: `0 ${toCSSValue('spacing-6')}`,
        }}
      >
        <LaneDetailsSection
          control={control}
          setValue={setValue}
          getValue={getValues}
          errors={errors}
          watchOrigin={watchOrigin}
          watchDestination={watchDestination}
          watchMileage={watchMileage}
          watchMileageSource={watchMileageSource}
          watchExtraStops={watchExtraStops}
          rfpDistanceType={rfpDistanceType}
        />
        <ModeAndEquipmentsSection
          control={control}
          setValue={setValue}
          watchLanePreferences={watchLanePreferences}
          errors={errors}
          modeOptions={rfpModeOptions}
          equipmentTypeOptions={equipmentTypesByModeOptions}
        />
        <LaneNotesSection control={control} setValue={setValue} />
        <LaneTargetRateSection
          control={control}
          rfpCurrency={rfpCurrency}
          setValue={setValue}
        />
        <LaneCustomFields
          control={control}
          isFieldDisabled={
            !watchLanePreferences.filter((preference) => preference.mode).length
          }
          rfpExtraFieldsHeaders={rfpExtraFieldsHeaders}
          modeOptions={rfpModeOptions}
          rfpID={rfpID}
          reloadLists={reloadLists}
        />
        {selectedLane ? (
          <EditLaneActionButtons
            handleDelete={handleDeleteLane}
            isSubmitting={isSubmitting || isDeletingLane}
            onDrawerClose={onDrawerClose}
            handleSubmit={handleSubmit}
            saveAndClose={saveAndClose}
          />
        ) : (
          <CreateLaneActionButtons
            onDrawerClose={onDrawerClose}
            isSubmitting={isSubmitting}
            handleSubmit={handleSubmit}
            saveAndCreateNext={saveAndCreateNext}
            saveAndClose={saveAndClose}
            showSaveAndAddNextButton={showSaveAndAddNextButton}
          />
        )}
      </Drawer.Body>
    </Drawer>
  )
}
