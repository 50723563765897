import { IconStar } from '@loadsmart/icons'
import { BannerAction } from '@loadsmart/loadsmart-ui'
import { useState } from 'react'

import { useModal } from 'hooks/useModal'
import {
  DemoRequested,
  RequestDemo,
} from 'rfp/components/shipper-guide-demo-dialog'
import * as storage from 'utils/localStorage'

import Wrapper from './Wrapper'

const SHIPPER_GUIDE_HAS_REQUESTED_DEMO = 'shipper-guide:has-requested-demo'

export const RFPLimitBanner = ({
  remainingRfpQuota,
  carrierInvitesMaxQuota,
}: {
  readonly remainingRfpQuota: number
  readonly carrierInvitesMaxQuota: number
}) => {
  const requestDemoDialog = useModal()
  const demoRequestedDialog = useModal()
  const [hasRequestedDemo, setHasRequestedDemo] = useState(
    storage.get(SHIPPER_GUIDE_HAS_REQUESTED_DEMO) ?? false
  )
  const defaultTitle = `You have ${remainingRfpQuota} RFPs left this month and you can invite up to ${carrierInvitesMaxQuota} carriers with your free account.`
  const noQuotaTitle = `You've reached your RFP limit with up to ${carrierInvitesMaxQuota} carriers this month, but you can continue to publish RFPs by only inviting Loadsmart.`
  const variant = remainingRfpQuota > 0 ? 'success' : 'warning'
  const title = remainingRfpQuota > 0 ? defaultTitle : noQuotaTitle
  const action = hasRequestedDemo ? 'demo requested' : 'request demo & upgrade'

  return (
    <Wrapper>
      <BannerAction
        onActionButtonClick={() => {
          if (hasRequestedDemo) {
            demoRequestedDialog.openModal()
          } else {
            requestDemoDialog.openModal()
          }
        }}
        icon={<IconStar width={20} height={20} />}
        action={action.toUpperCase()}
        scale="default"
        variant={variant}
        title={title}
      />
      <RequestDemo
        isOpen={requestDemoDialog.modalState}
        onClose={requestDemoDialog.closeModal}
        onConfirm={() => {
          setHasRequestedDemo(true)
          storage.set(SHIPPER_GUIDE_HAS_REQUESTED_DEMO, true)
          requestDemoDialog.closeModal()
          demoRequestedDialog.openModal()
        }}
      />
      <DemoRequested
        isOpen={demoRequestedDialog.modalState}
        onClose={demoRequestedDialog.closeModal}
      />
    </Wrapper>
  )
}
