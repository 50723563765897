import { useFulfillmentFormContext } from 'fulfillments/components/FulfillmentFormContext'

import { FulfillmentSelect } from './FulfillmentSelect'

export function FulfillmentOrders({
  required,
}: {
  readonly required?: boolean
}) {
  const { ordersOptions, isLoadingOrders } = useFulfillmentFormContext()

  return (
    <FulfillmentSelect
      name="order_UUIDs"
      label="Orders"
      required={required}
      placeholder="Select orders"
      loading={isLoadingOrders}
      options={ordersOptions}
      multiple
    />
  )
}
