import { IconCheck } from '@loadsmart/icons'
import { Dialog, Text } from '@loadsmart/loadsmart-ui'

const DemoRequested = ({
  isOpen,
  onClose,
}: {
  readonly isOpen: boolean
  readonly onClose: () => void
}) => {
  return (
    <Dialog
      open={isOpen}
      scale="small"
      onOverlayClick={onClose}
      data-testid="requested-demo-modal"
    >
      <Dialog.Header>Thank you!</Dialog.Header>
      <Dialog.Body>
        <IconCheck width={48} heigh={48} />
        <br />
        <br />
        <Text variant="body" color="color-neutral-darker">
          A ShipperGuide sales <br />
          representative will contact you <br />
          shortly to schedule a demo.
        </Text>
      </Dialog.Body>
      <Dialog.ActionCancel onCancel={onClose}>Close</Dialog.ActionCancel>
    </Dialog>
  )
}

export default DemoRequested
