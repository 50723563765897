import { IconChat } from '@loadsmart/icons'
import { Badge } from '@loadsmart/miranda-react'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'

import { userUtils } from '_shared_/user'

import { useChatSession } from '../hooks/useChat'

type ChatBadgeProps = {
  readonly onClick: () => void
}

export default function ChatBadge({ onClick }: ChatBadgeProps) {
  const { unreadCount } = useChatSession()

  if (userUtils.isSupplierUser) {
    return null
  }

  return (
    <Badge
      counter={unreadCount}
      placement="top-left"
      style={{ cursor: 'pointer' }}
      onClick={onClick}
    >
      <IconChat
        width="22"
        height="22"
        aria-label="chat badge"
        fill={toCSSValue('color-primary-60')}
        style={{ marginTop: '2px' }}
      />
    </Badge>
  )
}
