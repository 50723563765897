import { Layout, Tag } from '@loadsmart/miranda-react'

import {
  getExportStatusCellLabel,
  getExportStatusCellVariant,
} from './presenters'
import type { SettlementShipmentReportStatus } from './types'

export const ExportStatusCell = ({
  status,
}: {
  readonly status: SettlementShipmentReportStatus
}) => {
  return (
    <Layout.Group gap="spacing-2">
      <Tag variant={getExportStatusCellVariant(status)}>
        {getExportStatusCellLabel(status)}
      </Tag>
    </Layout.Group>
  )
}
