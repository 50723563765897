import { EXCESSIVE_LENGTH_CLASS_MAP } from './FreightInformation.constants'
import { useFreightInformationSummaryContext } from './FreightInformationSummary.context'
import type { FreightInformationSummaryFieldProps } from './FreightInformationSummaryField'
import { FreightInformationSummaryField } from './FreightInformationSummaryField'

type FreightInformationExcessiveLengthSummaryFieldProps = Partial<
  Omit<FreightInformationSummaryFieldProps, 'label' | 'name' | 'value'>
>

export function FreightInformationExcessiveLengthSummaryField(
  props: FreightInformationExcessiveLengthSummaryFieldProps
) {
  const EXCESSIVE_LENGTH_NAME = 'ltl_excessive_length_class'
  const EXCESSIVE_LENGTH_LABEL = 'Excessive length'
  const { shipment } = useFreightInformationSummaryContext()
  const excessiveLength = shipment[EXCESSIVE_LENGTH_NAME]

  return (
    <FreightInformationSummaryField
      label={EXCESSIVE_LENGTH_LABEL}
      name={EXCESSIVE_LENGTH_NAME}
      value={
        excessiveLength ? EXCESSIVE_LENGTH_CLASS_MAP.get(excessiveLength) : '-'
      }
      {...props}
    />
  )
}
