import { Layout, Tooltip } from '@loadsmart/loadsmart-ui'
import { Button, Checkbox, Icon, Text } from '@loadsmart/miranda-react'
import type { ErrorMessageProps } from 'formik'
import { ErrorMessage as BaseErrorMessage, useField } from 'formik'
import type {
  FieldHelperProps,
  FieldInputProps,
  FieldMetaProps,
} from 'formik/dist/types'
import type { ComponentType, PropsWithChildren, ReactNode } from 'react'

import { Input, Label } from 'components/Input'
import { theme } from 'styles/theme'

import { InputGroup } from './styles'

const ErrorMessage = (props: ErrorMessageProps) => (
  <Text variant="body-sm" color="color-text-error">
    <BaseErrorMessage {...props} />
  </Text>
)

interface RendererProps<T> {
  field: FieldInputProps<T>
  meta: FieldMetaProps<T>
  helpers: FieldHelperProps<T>
}
interface FormFieldProps {
  readonly label: string
  readonly span?: number
  readonly disabled?: boolean
  readonly component?: ComponentType<PropsWithChildren<unknown>>
  readonly children?: (props: RendererProps<string>) => ReactNode
  readonly maxLength?: number
}

/**
 * Naming this component "Legacy..." just becuase
 * we can't use Miranda components right now.
 */
const LegacyEmailField = ({
  label,
  disabled = false,
  maxLength = undefined,
}: FormFieldProps) => {
  const [field] = useField('email')

  return (
    <InputGroup span={6}>
      <Layout.Stack space="none">
        <Label name="email">
          {[
            <span key="email-span">
              {label}
              <span
                style={{
                  color: theme.colors.danger,
                  fontSize: '16px',
                  fontWeight: 600,
                }}
              >
                &nbsp;*
              </span>
            </span>,
          ]}
        </Label>
        <Layout.Group align="center" space="l">
          <Input
            {...field}
            required
            aria-label={label}
            disabled={disabled}
            maxLength={maxLength}
            style={{
              marginTop: '0',
              width: 'auto',
              flexGrow: '1',
            }}
          />
          <Layout.Group align="center" space="2xs">
            <Checkbox checked disabled>
              Send emails
            </Checkbox>
            <Tooltip
              message={
                <>
                  If checked, this email will receive
                  <br /> notifications from ShipperGuide.
                </>
              }
            >
              <Icon name="question" size="16px" />
            </Tooltip>
          </Layout.Group>
          <Button
            disabled
            variant="icon-secondary"
            size="small"
            aria-label="Delete email"
          >
            <Icon name="trash" size="16px" color="color-text-disabled" />
          </Button>
        </Layout.Group>
        <Text variant="body-sm" color="color-text-tertiary">
          Changing the primary email may affect the associated carrier account.
        </Text>
        <ErrorMessage name="email" />
      </Layout.Stack>
    </InputGroup>
  )
}
export default LegacyEmailField
