import { IconTrash, IconSettings } from '@loadsmart/icons'
import { Layout, Text, ToggleGroup } from '@loadsmart/loadsmart-ui'
import { Button } from '@loadsmart/miranda-react'
import { useCallback, useMemo, useState } from 'react'
import type { Dispatch, SetStateAction } from 'react'
import ReactPaginate from 'react-paginate'
import type { UseQueryResult } from 'react-query'

import NoResultsIllustration from 'assets/illustrations/no-results.svg'
import {
  Container,
  EmptyLanesContainer,
  EmptyLanesImage,
} from 'components/LaneManagement/LanesTableV3/styles'
import { useModal } from 'hooks/useModal'
import usePagination from 'hooks/usePagination'
import LaneFilters from 'rfp/components/lane-filters'
import type { LanesAwardedQueryParams } from 'rfp/components/table/laneParams'
import {
  getTablePageOffset,
  getTableSortDirection,
  useLaneIdMaxLength,
} from 'rfp/components/table/tableUtils'
import { TagFilters } from 'rfp/components/table/Tags'
import { useTagFilters } from 'rfp/components/table/Tags/useTagFilters'
import type { Currency } from 'rfp/rfp.types'
import { theme } from 'styles/theme'
import { RFP_STATE } from 'utils/constants'
import { isMultiCurrency } from 'utils/currency'

import BaseTable from '../BaseTable'
import EmptyState from '../EmptyState'
import LaneSearchField from '../LaneSearchField'
import { CenteredText, FilterWrapper } from '../styles'
import makeColumns from './makeColumns'

enum RadioOptions {
  LOWEST = 'lowest_bid',
  AVERAGE = 'average_bid',
  HIGHEST = 'highest_bid',
  LAST_AWARD = 'last_award',
  TARGET_RATE = 'target_rate',
  SPOT_MARKET = 'market_rate',
  SHORT_CONTRACT = 'contract_rate',
}

const optionsDict: Record<string, string> = {
  last_award: 'LAST AWARD',
  target_rate: 'TARGET RATE',
  market_rate: 'MARKET BENCHMARK',
  contract_rate: 'SHORT CONTR.',
  lowest_bid: 'LOWEST BID',
  average_bid: 'AVG. BID',
  highest_bid: 'HIGHEST BID',
}

const RateOptions = [
  { value: 'market_rate', label: 'Market Benchmark' },
  { value: 'target_rate', label: 'Target Rate' },
  { value: 'last_award', label: 'Last Award' },
]

const MultiCurrencyRateOptions = RateOptions.filter(
  (option) => option.value === RadioOptions.LAST_AWARD
)

const LIMIT = 50

const defaultParams = {
  page: 0,
  offset: 0,
  limit: LIMIT,
  sort: {
    column: 'lane_id',
    direction: 'asc',
  },
}

const NoLanesAllocatedEmptyState = ({
  changeTab,
}: {
  readonly changeTab: () => void
}) => {
  return (
    <EmptyState>
      <Layout.Stack space="s">
        <Text variant="heading-sm-bold" color="color-neutral-darkest">
          No Lanes Allocated
        </Text>
        <Text variant="caption" color="color-neutral-darker">
          Lanes with at least one primary
          <br />
          carrier selected will appear here.
        </Text>
      </Layout.Stack>
      <Button
        variant="primary"
        onClick={() => {
          changeTab()
        }}
        size="small"
      >
        go to lanes in progress
      </Button>
    </EmptyState>
  )
}

const TableEmptyState = ({
  allocatedLanesSearchQuery,
  changeTab,
}: {
  readonly allocatedLanesSearchQuery: string
  readonly changeTab: () => void
}) =>
  allocatedLanesSearchQuery === '' ? (
    <NoLanesAllocatedEmptyState changeTab={changeTab} />
  ) : (
    <EmptyLanesContainer border="v2">
      <EmptyLanesImage src={NoResultsIllustration} />
      <CenteredText variant="body-bold">
        Sorry. We couldn&apos;t find any
        <br />
        matching lanes with this status
      </CenteredText>
    </EmptyLanesContainer>
  )

type FilterOptions = '' | 'pending' | 'rejected' | 'accepted'

const getInitialRateOption = (
  displayMultiCurrency: boolean,
  rfpState: string
) =>
  displayMultiCurrency || rfpState === RFP_STATE.DRAFT
    ? RadioOptions.LAST_AWARD
    : RadioOptions.SPOT_MARKET

const isAnyTrue = (list: any[]) => {
  return list.some((condition) => Boolean(condition) === true)
}

const getAvailableRateOptions = (displayMultiCurrency: boolean) =>
  displayMultiCurrency ? MultiCurrencyRateOptions : RateOptions

export default function LanesAllocatedTable({
  rfpId,
  onRowClick,
  rfpState,
  rfpCurrency,
  query,
  params,
  setParams,
  removeScenarios,
  changeTab,
  isRemovingScenarios,
  selectable = false,
  isSpotMode,
  allocatedLanesSearchQuery,
  setAllocatedLanesSearchQuery,
}: {
  readonly rfpId: string | number
  readonly rfpState: string
  readonly rfpCurrency: Currency
  readonly onRowClick: (lane: Lane) => void
  readonly query: UseQueryResult<PaginatedResult, unknown>
  readonly params: LanesAwardedQueryParams
  readonly setParams: Dispatch<SetStateAction<LanesAwardedQueryParams>>
  readonly removeScenarios: (param: {
    rfpId: string | number
    laneIds: (string | number)[]
    updateAll: boolean
  }) => void
  readonly changeTab: () => void
  readonly isRemovingScenarios: boolean
  readonly selectable?: boolean
  readonly isSpotMode: boolean
  readonly allocatedLanesSearchQuery: string
  readonly setAllocatedLanesSearchQuery: (query: string) => void
}) {
  const shouldShowAwardStatusFilter = isAnyTrue([
    rfpState === RFP_STATE.AWARDING,
    rfpState === RFP_STATE.FINALIZED,
    rfpState === RFP_STATE.ARCHIVED,
  ])

  const displayMultiCurrency = isMultiCurrency(rfpCurrency)
  const [rateOption, setRadioOptionTwo] = useState<string>(
    getInitialRateOption(displayMultiCurrency, rfpState)
  )
  const availableRateOptions = getAvailableRateOptions(displayMultiCurrency)

  const { filters, setFilters, removeFilter, clearFilters } = useTagFilters(
    params,
    setParams
  )

  const {
    modalState: laneFiltersState,
    openModal: laneFiltersOpenModal,
    closeModal: laneFiltersCloseModal,
  } = useModal()

  const { pageCount, page } = usePagination(
    LIMIT,
    query.data?.count,
    params.page
  )

  const handlePageChange = (selectedItem: { selected: number }) => {
    setParams({
      ...params,
      page: selectedItem.selected,
      offset: getTablePageOffset(selectedItem.selected, defaultParams.offset),
    })
  }

  const isLoading = isAnyTrue([
    query.isLoading,
    query.isFetching,
    isRemovingScenarios,
  ])

  const changeSorting = (column: string) => {
    setParams({
      ...params,
      sort: {
        column,
        direction: getTableSortDirection(params.sort.direction),
      },
    })
  }

  const changeFilter = (option: FilterOptions) => {
    setParams({
      ...params,
      primaryAwardStatus: option,
    })
  }

  const laneIdMaxLength = useLaneIdMaxLength()

  const columns = useMemo(() => {
    return () =>
      makeColumns({
        laneIdMaxLength,
        rateOption,
        rfpState,
        isSpotMode,
        rfpCurrency,
        availableRateOptions,
        displayMultiCurrency,
        optionsDict,
        setRadioOptionTwo,
      })
  }, [
    laneIdMaxLength,
    rateOption,
    rfpState,
    isSpotMode,
    rfpCurrency,
    availableRateOptions,
    displayMultiCurrency,
  ])

  const Action = useCallback(
    ({ selected }: Readonly<{ selected: any[] }>) => {
      return (
        <Button
          variant="tertiary"
          size="small"
          onClick={() =>
            removeScenarios({
              rfpId,
              laneIds: selected,
              updateAll: false,
            })
          }
          leading={
            <IconTrash fill={theme.colors.neutral} height={12} width={12} />
          }
        >
          Deallocate
        </Button>
      )
    },
    [removeScenarios, rfpId]
  )

  return (
    <Container style={{ paddingBottom: '40px' }}>
      <Layout.Stack>
        <FilterWrapper align="center" justify="space-between">
          <Text variant="heading-md-bold" color="color-neutral-darker">
            {`${query.data?.count ?? 0} lanes`}
          </Text>
          <Layout.Group space="s">
            <LaneSearchField
              laneSearchQuery={allocatedLanesSearchQuery}
              setLaneSearchQuery={(searchQuery) => {
                setParams({
                  ...params,
                  page: defaultParams.page,
                  offset: defaultParams.offset,
                })
                setAllocatedLanesSearchQuery(searchQuery)
              }}
            />
            {shouldShowAwardStatusFilter && (
              <ToggleGroup
                aria-labelledby="status_label"
                id="status"
                name="status"
                value={params.primaryAwardStatus || 'all'}
                onChange={(event) =>
                  changeFilter(event.target.value as FilterOptions)
                }
                data-testid="filter"
                options={[
                  {
                    label: 'ALL',
                    value: '',
                  },
                  {
                    label: 'PENDING',
                    value: 'pending',
                  },
                  {
                    label: 'REJECTED',
                    value: 'rejected',
                  },
                  {
                    label: 'ACCEPTED',
                    value: 'accepted',
                  },
                ]}
              />
            )}
            {[RFP_STATE.AWARDING].includes(rfpState) && (
              <Button
                variant="secondary"
                onClick={laneFiltersOpenModal}
                leading={<IconSettings width={16} height={16} />}
              >
                Filters
              </Button>
            )}
          </Layout.Group>
        </FilterWrapper>
        <FilterWrapper>
          <TagFilters
            filters={filters}
            removeFilter={removeFilter}
            clearFilters={clearFilters}
          />
        </FilterWrapper>
        {!query.isFetching && query.data?.count === 0 ? (
          <TableEmptyState
            allocatedLanesSearchQuery={allocatedLanesSearchQuery}
            changeTab={changeTab}
          />
        ) : (
          <BaseTable
            isLoading={isLoading}
            columns={columns}
            entries={query.data?.results ?? []}
            onRowClick={(_event, row) => onRowClick(row.original)}
            sortable
            changeSorting={changeSorting}
            sort={params.sort}
            selectable={selectable}
            selectConfig={{
              actions: [Action],
              accessor: 'id',
            }}
          />
        )}
      </Layout.Stack>
      {pageCount > 1 && (
        <ReactPaginate
          data-testid="pagination"
          previousLabel="Previous"
          nextLabel="Next"
          breakLabel="..."
          breakClassName="break-me"
          pageCount={pageCount}
          forcePage={page}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName="pagination"
          activeClassName="active"
        />
      )}
      <LaneFilters
        rfpId={rfpId}
        rfpState={rfpState}
        isOpen={laneFiltersState}
        onConfirm={(newFilters) => {
          laneFiltersCloseModal()
          setFilters(newFilters)
        }}
        onClose={laneFiltersCloseModal}
        filters={filters}
      />
    </Container>
  )
}
