import { Card } from '@loadsmart/miranda-react'

import { OrderSelection } from 'screens/Shipper/Shipments/components/OrderSelection/OrderSelectionTable'

import { useFulfillmentFormContext } from './FulfillmentFormContext'

export function FulfillmentOrdersReviewCard() {
  const { fulfillment, selectedOrders } = useFulfillmentFormContext()

  if (
    fulfillment.order_identifier_type != 'orders' ||
    !selectedOrders?.length
  ) {
    return null
  }

  return (
    <Card id="order-selection-card" data-testid="order-selection-card">
      <Card.Title>Orders</Card.Title>
      <Card.Divider />
      <Card.Body>
        <OrderSelection orders={selectedOrders} />
      </Card.Body>
    </Card>
  )
}
