import { Button } from '@loadsmart/miranda-react'

import { useModal } from 'hooks/useModal'
import ImportLanesModal from 'rfp/rfp-details/import-lanes-modal'
import type { UploadLanesSuccessResponse } from 'rfp/rfp-details/types'

type ImportLanesProps = {
  readonly rfpId: string | number
  readonly onSuccessUploadLane: (lanes: UploadLanesSuccessResponse) => void
  readonly onErrorUploadLane: (start?: number, errorType?: string) => void
  readonly onClickDownloadTemplate: () => void
}

export const ImportLanes = ({
  rfpId,
  onSuccessUploadLane,
  onErrorUploadLane,
  onClickDownloadTemplate,
}: ImportLanesProps) => {
  const uploadLanesModal = useModal()

  return (
    <>
      <Button variant="secondary" onClick={uploadLanesModal.openModal}>
        Import lanes
      </Button>
      {uploadLanesModal.modalState && (
        <ImportLanesModal
          rfpId={rfpId}
          onSuccess={onSuccessUploadLane}
          onError={onErrorUploadLane}
          onClose={uploadLanesModal.closeModal}
          isOpen={uploadLanesModal.modalState}
          onDownloadTemplate={onClickDownloadTemplate}
        />
      )}
    </>
  )
}
