import { IconArrowLeft } from '@loadsmart/icons'

import Card from 'components/Card'
import { Text } from 'components/Typography'
import analytics, { AnalyticsEvent } from 'utils/analytics'

import { BackButton, Link } from './styles'

interface Crumb {
  title: string
  path: string
}

type Props = {
  readonly crumbs: Crumb[]
  readonly shouldRender?: boolean
}

export default function NavigationHeader({
  shouldRender = true,
  crumbs,
}: Props) {
  const lastPath = crumbs.length > 1 ? crumbs[crumbs.length - 2].path : '/'
  if (!shouldRender) {
    return null
  }
  return (
    <Card
      type="header"
      border="v2"
      className="flex align-center"
      aria-label="breadcrumbs"
    >
      {crumbs.length > 1 && (
        <BackButton
          onClick={() => {
            analytics.track(AnalyticsEvent.BreadcrumbBackButton)
          }}
          to={`${lastPath}`}
          aria-label="back"
        >
          <IconArrowLeft width={24} height={24} />
        </BackButton>
      )}
      {crumbs.map(({ path, title }, index) => {
        const isTheCurrentPage = index === crumbs.length - 1
        const color = isTheCurrentPage ? 'dark' : 'gray'
        const component = isTheCurrentPage ? undefined : Link
        return (
          <Text
            size="large"
            weight="heavy"
            color={color}
            onClick={() =>
              analytics.track(AnalyticsEvent.BreadcrumbPreviousPage)
            }
            as={component}
            to={path}
            key={path}
            aria-current={isTheCurrentPage ? 'page' : 'link'}
          >
            {title}
          </Text>
        )
      })}
    </Card>
  )
}
