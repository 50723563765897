import type { RequestForProposal } from 'rfp/rfp.types'

export const isKilometers = (
  distanceType: RequestForProposal['distance_type']
) => distanceType === 'kilometers'

export const getDistanceTypeLabel = (
  distanceType: RequestForProposal['distance_type']
) => {
  if (isKilometers(distanceType)) {
    return 'Kilometers'
  }

  return 'Miles'
}

export const getLaneMileage = (
  distanceType: RequestForProposal['distance_type'],
  lane?: Lane
) => {
  if (!lane) {
    return null
  }

  if (isKilometers(distanceType)) {
    return lane.mileage_kilometers
  }

  return lane.mileage
}

export const getPerDistanceRateLabel = (
  distanceType: RequestForProposal['distance_type']
) => {
  if (isKilometers(distanceType)) {
    return 'RPK'
  }

  return 'RPM'
}

export const getPerDistanceRate = (
  laneProposal: LaneProposal,
  distanceType: RequestForProposal['distance_type'],
  displayMultiCurrency: boolean
) => {
  if (isKilometers(distanceType)) {
    const valueField = displayMultiCurrency
      ? 'converted_per_kilometer_rate'
      : 'per_kilometer_rate'

    return laneProposal[valueField]
  }

  const valueField = displayMultiCurrency
    ? 'converted_per_mile_rate'
    : 'per_mile_rate'

  return laneProposal[valueField]
}
