import {
  Layout,
  Tooltip,
  TooltipAlign,
  TooltipPosition,
} from '@loadsmart/loadsmart-ui'

import MultiCurrencyValue from 'rfp/rfp-details/components/MultiCurrencyValue'
import type { Currency } from 'rfp/rfp.types'
import { MODES_DICT } from 'utils/constants'
import { isMultiCurrency } from 'utils/currency'
import { getEquipmentType } from 'utils/equipmentTypeV2'

import incumbentIcon from '../../../assets/icons/incumbent.svg'
import {
  Question,
  StyledAwardTable,
  StyledAwardTooltip,
  StyledCarrierNameCell,
} from './styles'

interface Carriers {
  is_incumbent: boolean
  flat_rate: number
  converted_flat_rate?: number
  name: string
  equipment_type: string
  mode: string
  awarded_volume: number
}

interface AwardTooltipProps {
  readonly carriers: Carriers[]
  readonly position?: TooltipPosition
  readonly align?: TooltipAlign
  readonly rfpCurrency?: Currency
}

const AwardTooltip = ({
  carriers,
  position = TooltipPosition.Top,
  align = TooltipAlign.Center,
  rfpCurrency,
}: AwardTooltipProps) => {
  const hasIncumbent = carriers.some((carrier) => carrier.is_incumbent)
  const displayMultiCurrency = isMultiCurrency(rfpCurrency)

  return (
    <StyledAwardTooltip>
      <Tooltip
        position={position}
        align={align}
        message={
          <StyledAwardTable>
            <thead>
              <tr>
                <th style={{ paddingLeft: hasIncumbent ? '29px' : 'initial' }}>
                  Primary
                </th>
                <th>Mode</th>
                <th>Equip.</th>
                <th>Vol.</th>
                <th>Flat Rate</th>
              </tr>
            </thead>
            <tbody>
              {carriers.map((carrier, index) => {
                const valueField = displayMultiCurrency
                  ? 'converted_flat_rate'
                  : 'flat_rate'

                return (
                  <tr key={`award-summary-${index}`}>
                    <StyledCarrierNameCell
                      $shouldHavePaddingLeft={
                        !carrier.is_incumbent && hasIncumbent
                      }
                    >
                      <Layout.Group space="s" align="center">
                        {carrier.is_incumbent && (
                          <img
                            src={incumbentIcon}
                            alt="This carrier is the incumbent"
                          />
                        )}
                        {/* This div is just to do the ellipsis in the CSS, because we need a item with display block */}
                        <div>{carrier.name}</div>
                      </Layout.Group>
                    </StyledCarrierNameCell>
                    <td>{MODES_DICT[carrier.mode]}</td>
                    <td>{getEquipmentType(carrier.equipment_type)?.label}</td>
                    <td>{carrier.awarded_volume}</td>
                    <td>
                      <MultiCurrencyValue
                        value={carrier[valueField]}
                        rfpCurrency={rfpCurrency}
                        boldStyle={false}
                      />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </StyledAwardTable>
        }
      >
        <Question width={12} height={12} data-testid="award-tooltip-trigger" />
      </Tooltip>
    </StyledAwardTooltip>
  )
}

export default AwardTooltip
