import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import type { Config, DriveStep } from 'driver.js'
import { driver } from 'driver.js'
import { useCallback, useMemo } from 'react'
import { createGlobalStyle } from 'styled-components'

import useLocalStorageState from 'hooks/useLocalStorageState'

export const GuidedTourStylesheet = createGlobalStyle`
  .guided-tour {
    .driver-popover {
      background-color:  ${toCSSValue('color-neutral-0')};
      color: ${toCSSValue('color-neutral-100')};
      border: 1px solid  ${toCSSValue('color-neutral-100')};
      border-radius: 0;
      box-shadow: ${toCSSValue('elevation-4')};
    }

    .driver-popover-prev-btn {
      font-size: ${toCSSValue('font-size-2')};
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      box-sizing: border-box;
      text-decoration: none;
      gap: ${toCSSValue('spacing-2')};
      padding: ${toCSSValue('spacing-2')} ${toCSSValue('spacing-4')};
      background-color: transparent;
      color: ${toCSSValue('color-primary-100')};
      text-shadow: none;
      border: 1px solid ${toCSSValue('color-primary-100')};
      border-radius: ${toCSSValue('border-radius-s')};

      &:hover {
        background-color: ${toCSSValue('color-background-tertiary')};
      }
    }

    .driver-popover-next-btn {
      font-size: ${toCSSValue('font-size-2')};
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      box-sizing: border-box;
      text-decoration: none;
      gap: ${toCSSValue('spacing-2')};
      padding: ${toCSSValue('spacing-2')} ${toCSSValue('spacing-2')};
      background-color: ${toCSSValue('color-primary-60')};
      color: ${toCSSValue('color-text-inverted')};
      text-shadow: none;
      border: 1px solid ${toCSSValue('color-primary-60')};
      border-radius: ${toCSSValue('border-radius-s')};

      &:hover {
        background-color: ${toCSSValue('color-primary-100')};
      }
    }
  }
`

export type GuidedTour = {
  name: string
  steps: DriveStep[]
}

export type TourStatus = 'initial' | 'started' | 'finished'

type UseGuidedTourProps = Readonly<{
  name: string
}> &
  Config

export function useGuidedTour({ name }: UseGuidedTourProps) {
  const storageKey = useMemo(() => `guided-tour-${name}-status`, [name])
  const [localStorageState, setLocalStorageState] =
    useLocalStorageState<TourStatus>(storageKey, { initialValue: 'started' })

  const driverInstance = useMemo(
    () =>
      driver({
        popoverClass: 'guided-tour',
        onNextClick: () => {
          if (driverInstance.hasNextStep()) {
            driverInstance.moveNext()
            setLocalStorageState('started')
          } else {
            setLocalStorageState('finished')
          }
        },
        onCloseClick: () => {
          setLocalStorageState('finished')
        },
      }),
    [setLocalStorageState]
  )

  const getStatus = useCallback(() => {
    return localStorageState
  }, [localStorageState])

  const setSteps = useCallback(
    (steps: DriveStep[]) => {
      driverInstance.setSteps(steps)
    },
    [driverInstance]
  )

  const start = useCallback(
    (stepIndex: number = 0) => {
      const isFinished = getStatus() === 'finished'
      if (!driverInstance.isActive() && !isFinished) {
        driverInstance.drive(stepIndex)
      }
    },
    [driverInstance, getStatus]
  )

  const stop = useCallback(() => {
    driverInstance.destroy()
  }, [driverInstance])

  const isActive = useCallback(() => {
    return driverInstance.isActive()
  }, [driverInstance])

  return { getStatus, isActive, setSteps, start, stop }
}
