import { useQuery } from 'react-query'

import { getPinnedFacilities } from 'services/facilities'

export const GET_FACILITIES_QUERY_KEY = 'getPinnedFacilities'
export const usePinnedFacilities = ({
  enabled,
  stopType,
}: {
  enabled: boolean
  stopType?: StopType
}) => {
  return useQuery({
    queryKey: [GET_FACILITIES_QUERY_KEY, stopType],
    queryFn: ({ signal }) => getPinnedFacilities({ signal }, stopType),
    enabled,
    retry: false,
    refetchOnWindowFocus: false,
  })
}
