import { useCallback } from 'react'
import type { Dispatch, SetStateAction } from 'react'

import { defaultFilters } from 'rfp/components/lane-filters'
import type { Filters } from 'rfp/components/lane-filters'

export const useTagFilters = <P extends { filters: Filters }>(
  params: P,
  setParams: Dispatch<SetStateAction<P>>
) => {
  const { filters } = params
  const setFilters = useCallback(
    (newFilters: Filters) =>
      setParams((prevParams) => ({ ...prevParams, filters: newFilters })),
    [setParams]
  )

  const removeFilter = useCallback(
    (key: keyof Filters) => {
      setParams((prevParams) => ({
        ...prevParams,
        filters: {
          ...prevParams.filters,
          [key]: [],
        },
      }))
    },
    [setParams]
  )

  const clearFilters = useCallback(
    () =>
      setParams((prevParams) => ({
        ...prevParams,
        filters: defaultFilters,
      })),
    [setParams]
  )

  return {
    filters,
    setFilters,
    removeFilter,
    clearFilters,
  }
}
