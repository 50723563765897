import type { AppThunk } from 'app/store'
import { search } from 'services/carriers'

import { slice } from './reducer'

export { fetchAliceCarrier, fetchCarrierDetails } from './reducer'

export const {
  aliceSearchUpdated,
  clearAliceCarrierDetail,
  clearCarrierDetail,
  fetchAliceCarriersStart,
  fetchAliceCarriersSuccess,
  fetchAliceCarriersFailed,
  createCarrier,
  searchUpdated,
} = slice.actions

export const searchAliceCarriers =
  (): AppThunk => async (dispatch, getState) => {
    dispatch(fetchAliceCarriersStart())
    const state = getState()
    try {
      const response = await search(state.carriers.meta.searchAlice)
      dispatch(fetchAliceCarriersSuccess(response.data))
    } catch {
      dispatch(fetchAliceCarriersFailed())
    }
  }

export const updateSearch = (searchTerm: string): AppThunk => {
  return (dispatch) => {
    dispatch(searchUpdated(searchTerm))
  }
}

export const updateAliceSearch = (searchTerm: string): AppThunk => {
  return (dispatch) => {
    dispatch(aliceSearchUpdated(searchTerm))
  }
}
