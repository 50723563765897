export const getOriginFromStops = (stops: LaneStop[]) => stops[0]
export const getDestFromStops = (stops: LaneStop[]) => stops[stops.length - 1]

const isOriginValid = (lane: Lane) => {
  const origin = getOriginFromStops(lane.stops)
  return (
    ((!!origin.city && !!origin.state) || !!origin.zip_code) && !!origin.country
  )
}

const isDestinationValid = (lane: Lane) => {
  const destination = getDestFromStops(lane.stops)
  return (
    ((!!destination.city && !!destination.state) || !!destination.zip_code) &&
    !!destination.country
  )
}

const isLanePreferencesValid = (preferences: LanePreference[]) => {
  if (preferences?.length === 0) {
    return false
  }
  return preferences?.every((preference) => {
    if (preference.mode === 'drayage') {
      return (
        preference.equipment_type &&
        preference.mode &&
        preference.equipment_size
      )
    }
    return preference.equipment_type && preference.mode
  })
}

export const isLaneEquipmentTypeValid = (lane: Lane) => {
  return lane.equipment_type || isLanePreferencesValid(lane.preferences)
}

const isBaseValuesValid = (lane: Lane) => {
  return (
    !!lane.lane_id &&
    !!isLaneEquipmentTypeValid(lane) &&
    !!lane.volume &&
    !!lane.mileage
  )
}

export function isValidLane(lane: Lane) {
  return (
    isOriginValid(lane) && isDestinationValid(lane) && isBaseValuesValid(lane)
  )
}

export function checkErrors(lane: Lane) {
  if (!isOriginValid(lane)) {
    return 'Missing origin information'
  }

  if (!isDestinationValid(lane)) {
    return 'Missing destination information'
  }

  if (!isBaseValuesValid(lane)) {
    return 'Missing information'
  }

  return null
}
