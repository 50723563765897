import { TopNavigation } from '@loadsmart/loadsmart-ui'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import { useCallback } from 'react'

import { userUtils } from '_shared_/user'
import { IconQuestionCircle } from 'components/Icons/top-bar'
import { HELP_CENTER_LINK } from 'utils/constants'

type HelpCenterProps = {
  readonly onClick: () => void
}

export default function HelpCenter({ onClick }: HelpCenterProps) {
  const handleClick = useCallback(() => {
    onClick()
    window.open(HELP_CENTER_LINK, '_blank')
  }, [onClick])

  if (userUtils.isMTCustomerUser || userUtils.isSupplierUser) {
    return null
  }

  return (
    <TopNavigation.Menu.Item
      icon={
        <IconQuestionCircle
          width="20"
          height="20"
          fill={toCSSValue('color-primary-60')}
        />
      }
      label="Help Center"
      aria-label="help center"
      url="#"
      onClick={handleClick}
    />
  )
}
