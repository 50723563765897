/**
 * PusherClient is our class that will interact with Pusher.
 * If SharedWorkers are supported, it will create a new worker to connecto to Pusher.
 * Otherwise it will connect to Pusher in the main thread.
 *
 * You can add connectionListeners if you want to listen for connection events (pusher.connection.bind under the hood)
 * Or you can add channelListeners if you want to listen for channel events. (pusher.subscribe and channel.bind under the hood)
 */
// This version of sentry is compatible with workers
import type Pusher from 'pusher-js'
import type { Options as PusherOptions } from 'pusher-js'

import { PUSHER_CONFIG, PUSHER_DISCONNECTION_TIMEOUT } from 'utils/constants'

import Channels from './channels'
import Connection from './connection'
import type {
  OnReadyCallback,
  OnErrorCallback,
  OnMessageCallback,
} from './types'

interface PusherClass {
  new (key: string, options: PusherOptions): Pusher
}

export default class PusherClient {
  pusher: Pusher

  accessToken?: string

  connection: Connection
  channels: Channels

  static instances: PusherClient[] = []

  constructor(Pusher: PusherClass) {
    const { key, ...options } = PUSHER_CONFIG

    this.pusher = new Pusher(key, {
      ...options,
      channelAuthorization: {
        ...options.channelAuthorization,
        // headersProvider is called every time Pusher tries to authorize a channel so we want to always use the latest version of the accessToken inside
        // For this to work, the setAccessToken method of this class should be called regularly with the latest accessToken
        // We currently do that in the PusherManager every time we instantiate a new one and call subscribe
        headersProvider: () => {
          if (!this.accessToken) {
            return null
          }
          return {
            Accept: '*/*',
            Authorization: `Bearer ${this.accessToken}`,
          }
        },
      },
    })

    this.connection = new Connection(this.pusher)
    this.channels = new Channels(this.pusher)

    // This is just so we can reuse the same pusher instance every time
    // and only open one connection to Pusher
    // Example: const client = Pusher.instances[0] || new PusherClient()
    PusherClient.instances.push(this)
  }

  setAccessToken(accessToken: string) {
    this.accessToken = accessToken
  }

  subscribe(
    channel: string,
    events: string | string[],
    onReady: OnReadyCallback,
    onError: OnErrorCallback,
    onMessage: OnMessageCallback
  ) {
    this.connection.subscribe(onError)
    this.channels.subscribe(channel, events, onReady, onError, onMessage)
  }

  unsubscribe(
    channel: string,
    events: string | string[],
    onReady: OnReadyCallback,
    onError: OnErrorCallback,
    onMessage: OnMessageCallback
  ) {
    this.connection.unsubscribe(onError)
    this.channels.unsubscribe(channel, events, onReady, onError, onMessage)

    this.scheduleDisconnection()
  }

  private scheduleDisconnection() {
    if (this.channels.count() === 0) {
      setTimeout(() => {
        if (this.channels.count() === 0) {
          this.connection.end()
        }
      }, PUSHER_DISCONNECTION_TIMEOUT)
    }
  }
}
