import { useState } from 'react'
import type { KeyboardEvent } from 'react'
import { components } from 'react-select'
import type { MultiValue } from 'react-select'
import BaseCreatableSelect from 'react-select/creatable'
import styled from 'styled-components'

import { theme as defaultTheme } from 'styles/theme'

const ErrorMsg = styled.span`
  color: ${({ theme }) => theme.colors.dangerDark};
  font-size: 12px;
  line-height: 16px;
  margin: 0 4px;
`
const CreatableSelect = styled(
  BaseCreatableSelect<CreatableSelectObject, true>
)<{
  hasError: boolean
}>`
  > div {
    border-color: ${({ theme }) => theme.colors.neutralDark};
    box-shadow: none;

    :hover {
      border-color: ${({ theme }) => theme.colors.neutralDark};
      box-shadow: none;
    }
  }

  ${({ hasError, theme }) =>
    hasError &&
    `
    outline-color: ${theme.colors.dangerDark};

    > div {
      border-color: ${theme.colors.dangerDark};
      box-shadow: none;

      :hover {
        border-color: ${theme.colors.dangerDark};
      }
    }
  `}
`
const customStyles = {
  multiValue: (provided: any) => ({
    ...provided,
    padding: '0 0 0 2px',
    margin: '2px',
    marginRight: '4px',
    borderRadius: '30px',
    border: `1px solid ${defaultTheme.colors.neutralDark}`,
    color: `${defaultTheme.colors.neutralDarker}`,
    backgroundColor: `${defaultTheme.colors.white}`,
    lineHeight: '17px',
    fontWeight: 700,
    ':hover': {
      backgroundColor: `${defaultTheme.colors.backgroundLightActive}`,
      borderColor: 'transparent',
      color: `${defaultTheme.colors.textSecondary}`,
    },
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    padding: '3px 4px 3px 6px',
    fontSize: '12px',
    alignSelf: 'flex-end',
  }),
  control: (provided: any) => ({
    ...provided,
    minHeight: '36px',
    ':focus-within': {
      border: `1px solid  ${defaultTheme.colors.main}`,
      boxShadow: `0 2px 3px ${defaultTheme.colors.backgroundLightActive}`,
      ':hover': {
        border: `1px solid  ${defaultTheme.colors.main}`,
        boxShadow: `0 2px 3px ${defaultTheme.colors.backgroundLightActive}`,
      },
      '[class*="multiValue"]': {
        backgroundColor: `${defaultTheme.colors.backgroundLightActive}`,
        borderColor: 'transparent',
        color: `${defaultTheme.colors.textSecondary}`,
      },
    },
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    '> div': {
      paddingTop: '7px',
      paddingBottom: '7px',
      ':hover': {
        cursor: 'pointer',
      },
    },
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    width: '16px',
    height: '16px',
    margin: '4px 4px 4px 0',
    padding: 0,
    backgroundColor: `${defaultTheme.colors.white}`,
    borderRadius: '100px',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: 'none',
    },
    '> svg': {
      margin: 'auto',
    },
  }),
  noOptionsMessage: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
  menu: (provided: any) => ({
    ...provided,
    marginTop: '2px',
  }),
  menuList: (provided: any) => ({
    ...provided,
    border: `1px solid ${defaultTheme.colors.main}`,
    boxSizing: 'borderBox',
    boxShadow: `0 2px 3px ${defaultTheme.colors.backgroundLightActive}`,
    padding: '0 8px 2px',
    borderRadius: '4px',
  }),
  option: (provided: any) => ({
    ...provided,
    background: `${defaultTheme.colors.white}`,
    ':hover': {
      background: `${defaultTheme.colors.background}`,
      borderRadius: '4px',
    },
  }),
}

const Input = (props: any) => <components.Input {...props} maxLength={20} />

interface CreatableSelectObject {
  label: string
  value: string
}

export type InputTagProps = {
  readonly values: string[]
  readonly onChangeTags: (value: Array<string>) => void
  readonly maxNumberOfTags: number
}

export default function InputTag({
  values,
  onChangeTags,
  maxNumberOfTags,
}: InputTagProps) {
  const [inputValue, setInputValue] = useState<string>('')
  const [error, setError] = useState<string>('')
  const [openMenu, setOpenMenu] = useState<boolean>(false)

  const hideMenu = () => {
    setOpenMenu(false)
  }

  const sanitizeValue = (value: string) =>
    value.toLowerCase().replace(/[^a-z|A-Z|0-9|\s]/g, '')

  const handleChange = (currentValue: MultiValue<CreatableSelectObject>) => {
    onChangeTags(currentValue.map((item) => sanitizeValue(item.value)))
    hideMenu()
    setError('')
  }

  const handleInputChange = (value: string) => {
    if (values.length >= maxNumberOfTags && value) {
      setError(`You can only add up to ${maxNumberOfTags} tags`)
      setOpenMenu(false)
      return
    }

    if (values.includes(sanitizeValue(value))) {
      setError('You cannot add duplicate values')
      setOpenMenu(false)
      setInputValue(value)
      return
    }

    setInputValue(value)
    setError('')
    setOpenMenu(value.length > 0)
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (
      !inputValue ||
      values.includes(inputValue) ||
      values.length >= maxNumberOfTags
    ) {
      return
    }

    if (values.includes(inputValue)) {
      return
    }

    if (event.key === ',' || event.key === 'Enter' || event.key === 'Tab') {
      setInputValue('')
      setError('')

      if (!values.includes(inputValue)) {
        onChangeTags([...values, sanitizeValue(inputValue)])
      }

      hideMenu()
      event.preventDefault()
    }
  }

  const creatableSelectValue = values?.map((item) => ({
    label: item,
    value: item,
  }))

  const hasError = Boolean(error.length)

  return (
    <>
      <CreatableSelect
        components={{
          DropdownIndicator: null,
          Input,
        }}
        inputValue={inputValue}
        styles={customStyles}
        isMulti
        hasError={hasError}
        menuIsOpen={openMenu}
        onChange={handleChange}
        onBlur={hideMenu}
        onInputChange={handleInputChange}
        onKeyDown={handleKeyDown}
        value={creatableSelectValue}
        placeholder=""
      />
      {error && <ErrorMsg>{error}</ErrorMsg>}
    </>
  )
}
