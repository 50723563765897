import styled from 'styled-components'

export const DatePickerWrapper = styled.div`
  display: flex;
  flex: 1;
  > div {
    flex: 1;
  }
  div[scale='default'] {
    flex: 1;
  }
`
