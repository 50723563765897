import styled from 'styled-components'

import { hexToRGBA } from 'utils/colors'

const DotStatus = styled.div<{
  statusColor: string
}>`
  background-color: ${({ statusColor }) => statusColor};
  border-radius: 100%;
  box-shadow: ${({ statusColor }) =>
    `0px 0px 4px ${hexToRGBA(statusColor, 0.4)}`};
  height: 8px;
  left: 10px;
  position: absolute;
  top: -5px;
  width: 8px;
  z-index: 2;
`

export default DotStatus
