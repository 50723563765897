import { Layout, Spinner } from '@loadsmart/loadsmart-ui'
import { useEffect } from 'react'

import { useAuth } from '_shared_/user/useAuth'

export default function Logout() {
  const { logout } = useAuth()

  useEffect(() => {
    logout()
  }, [logout])

  return (
    <Layout.Stack align="center" justify="center">
      <Spinner size={48} aria-label="Loading" />
    </Layout.Stack>
  )
}
