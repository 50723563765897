import { SideNavigation } from '@loadsmart/loadsmart-ui'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { userUtils } from '_shared_/user'
import { useSettings } from 'contexts/settings'
import { AppRoutes } from 'router/AppRoutes'
import { USER_TYPE } from 'utils/constants'

import { isActivePath, navbarLinkClicked } from './sidenav.utils'

/**
 * We need this to use in the map below
 * and also we cannot create it on the fly (inside React render method)
 * because it would never match between re-renders.
 */
const StableKeys = [
  crypto.randomUUID(),
  crypto.randomUUID(),
  crypto.randomUUID(),
]

export const QuotesMenu = () => {
  const history = useHistory()

  const {
    values: [enableNewHomePage],
  } = useSettings(['flags.ENABLE_NEW_HOME_PAGE'])

  const getMenuItems = () => {
    const newQuoteItem = (
      <SideNavigation.Menu.Item
        active={isActivePath('/quotes/new')}
        label="New Quote"
        url="/quotes/new"
        onClick={(event) => {
          event.preventDefault()
          navbarLinkClicked('New Quote', '/quotes/new', history)
        }}
      />
    )
    const quotesItem = (
      <SideNavigation.Menu.Item
        active={isActivePath(AppRoutes.Quotes)}
        label="Quotes"
        url={AppRoutes.Quotes}
        onClick={(event) => {
          event.preventDefault()
          navbarLinkClicked('Quotes', AppRoutes.Quotes, history)
        }}
      />
    )
    const shipmentsItem = (
      <SideNavigation.Menu.Item
        active={isActivePath(AppRoutes.Shipments)}
        label="Shipments"
        url={AppRoutes.Shipments}
        onClick={(event) => {
          event.preventDefault()
          navbarLinkClicked('Shipments', AppRoutes.Shipments, history)
        }}
      />
    )
    if (enableNewHomePage) {
      return userUtils.isUserType([USER_TYPE.SG, USER_TYPE.FBM])
        ? [shipmentsItem, newQuoteItem, quotesItem]
        : [shipmentsItem]
    }
    return userUtils.isUserType([USER_TYPE.SG, USER_TYPE.FBM])
      ? [newQuoteItem, quotesItem, shipmentsItem]
      : [shipmentsItem]
  }

  return (
    <SideNavigation.Menu>
      {getMenuItems()?.map((item, index) => (
        <React.Fragment key={`menu-item-${StableKeys[index]}`}>
          {item}
        </React.Fragment>
      ))}
    </SideNavigation.Menu>
  )
}
