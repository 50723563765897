import type { AxiosRequestConfig } from 'axios'

import httpClient from './clients/dataInsightsHttpClient'

type CarrierAnalyticsInfo = {
  carrier_name: string
  carrier_uuid: string
  carrier_entity: string
  carrier_dot: string
}

type RawCostPerformance = CarrierAnalyticsInfo & {
  total_spend: string
  total_shipments: number
  percentage_spot_shipments: string
  spot_tenders_rejection_rate: string
  contract_tenders_rejection_rate: string
  spot_cost_performance: string
  contract_cost_performance: string
}

type RawSpotAnalytics = CarrierAnalyticsInfo & {
  total_invites: number
  percentage_invites_responded: string
  average_response_time: string
  tendered_bids_rate: string
  average_tender_acceptance_time: string
  bid_performance: string
}

type RawRFPAnalytics = CarrierAnalyticsInfo & {
  total_invites: number
  percentage_invites_responded: string
  average_response_time: string
  awarded_bids_rate: string
  average_award_acceptance_time: string
  bid_performance: string
  total_rfp_proposals_awarded: number
  total_rfp_proposals_submitted: number
}

export type RFPAnalyticsData = {
  name: string
  uuid: string
} & Omit<RawRFPAnalytics, 'carrier_name' | 'carrier_uuid'>

export const mapCarrierData = (
  rawCarrierData: RawCostPerformance | RawSpotAnalytics | RawRFPAnalytics
) => {
  const { carrier_uuid, carrier_name, ...data } = rawCarrierData
  return { ...data, uuid: carrier_uuid, name: carrier_name }
}

const buildQueryParams = (
  limit: string,
  offset: string,
  sort: { column: string; direction: string },
  search?: string
) => {
  const urlParams = new URLSearchParams({ limit, offset })
  if (search) {
    urlParams.append('search', search)
  }
  let sortParam = sort.column
  if (sort.column === 'name') {
    sortParam = 'carrier_name'
  }
  if (sort.direction === 'desc') {
    sortParam = `-${sortParam}`
  }
  urlParams.append('sort', sortParam)
  return urlParams ? `?${urlParams.toString()}` : ''
}

export const costPerformance = async (
  limit: string,
  offset: string,
  sort: { column: string; direction: string },
  shipperUUID?: string,
  search?: string,
  config?: AxiosRequestConfig
) => {
  const queryParams = buildQueryParams(limit, offset, sort, search)
  const { data } = await httpClient.get(
    `/carriers/${shipperUUID}/cost-performance/${queryParams}`,
    {
      ...config,
      withCredentials: false,
    }
  )
  return {
    ...data,
    results: data.results.map(mapCarrierData),
  }
}

export const spotAnalytics = async (
  limit: string,
  offset: string,
  sort: { column: string; direction: string },
  shipperUUID?: string,
  search?: string,
  config?: AxiosRequestConfig
) => {
  const queryParams = buildQueryParams(limit, offset, sort, search)
  const { data } = await httpClient.get(
    `/carriers/${shipperUUID}/spot-analytics/${queryParams}`,
    {
      ...config,
      withCredentials: false,
    }
  )
  return {
    ...data,
    results: data.results.map(mapCarrierData),
  }
}

export const rfpAnalytics = async (
  limit: string,
  offset: string,
  sort: { column: string; direction: string },
  shipperUUID?: string,
  search?: string,
  config?: Omit<AxiosRequestConfig, 'withCredentials'>
) => {
  const queryParams = buildQueryParams(limit, offset, sort, search)
  const { data } = await httpClient.get(
    `/carriers/${shipperUUID}/rfp-analytics/${queryParams}`,
    {
      ...config,
      withCredentials: false,
    }
  )
  return {
    ...data,
    results: data.results.map(mapCarrierData),
  }
}
