import styled from 'styled-components'

import { Currency } from 'components/Input'

export const ActionWrapperV2 = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`
export const AmountV2 = styled.b<{ isSelected?: boolean }>`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  min-width: 80px;
  text-align: right;

  ${({ theme, isSelected }) => isSelected && `color: ${theme.colors.main};`}
`

export const InputV2 = styled(Currency)`
  color: ${({ theme }) => theme.colors.text};
  flexgrow: 0;
  font-weight: bold;
  height: 26px;
  max-width: 100px;
  text-align: right;
`
export const WrapperV2 = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.whiteBroken};
  display: flex;
  flex: 1;
  height: 48px;
  padding: 0 30px;
`
