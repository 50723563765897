export enum AppRoutes {
  Logout = '/logout',
  Shipments = '/shipments',
  AutoTenderRules = '/shipments/auto-tender-rules',
  NewShipment = '/shipments/add',
  QuoteDetails = '/quotes/:quoteUUID',
  Quotes = '/shipper/quotes',
  BulkInvite = '/bulk/invite/:bulkInviteUUID',
  BulkQuotesFromShipments = '/bulk/quotes-from-shipments/:bulkQuoteUUID',
  TenderConfirmation = '/carrier/tenders/:tenderUUID/confirm',
  NewQuote = '/quotes/new/:quoteUUID?',
  ShipmentDetails = '/shipments/:id',
  Checkout = '/quote/:quoteUUID/checkout/:rateUUID',
  FacilityManagement = '/facilities',
  Contracts = '/contracts',
  NewContract = '/contracts/add',
  EditContract = '/contracts/edit/:contractId',
  ContractDetails = '/contracts/:contractId',
  ShipperReports = '/analytics/operations',
  UserAgreement = '/user-agreement',
  PriceMonitor = '/analytics/price',
  RFP = '/shipper/rfp',
  RFPReportDownload = '/shipper/rfp/:rfpId/download_report',
  NewRFP = '/shipper/rfp/add',
  EditRFP = '/shipper/rfp/:rfpId/edit',
  RFPDetails = '/shipper/rfp/:rfpId',
  Carriers = '/shipper/carriers',
  Lanes = '/shipper/lanes',
  Orders = '/orders',
  OrderDetails = '/orders/:orderId',
  PlanOrders = '/plan-orders',
  SuppliersList = '/suppliers',
  ShipperSettings = '/shipper/settings',
  FreightWaves = '/freightwaves',
  EditShipment = '/shipments/:shipmentId/edit',
  Insights = '/insights',
  Fulfillments = '/fulfillments',
  FulfillmentDetails = '/fulfillments/:fulfillmentUuid',
  Payables = '/payables',
}
