import {
  Card,
  Icon,
  Text,
  ToggleGroup,
  Toggle,
  Table,
} from '@loadsmart/miranda-react'

import { useSettings } from 'contexts/settings'

type CarrierAutomationsCardProps = {
  readonly autoAcceptTender?: boolean
  readonly onChange: (field: string, value: any) => void
}

export const CarrierAutomationsCard = ({
  autoAcceptTender = false,
  onChange,
}: CarrierAutomationsCardProps) => {
  const {
    values: [enableAutoAcceptTender],
  } = useSettings(['flags.ENABLE_AUTO_ACCEPT_TENDER'])

  if (!enableAutoAcceptTender) {
    return null
  }

  return (
    <Card>
      <Card.Title leading={<Icon name="bolt" size="16" />}>
        Automations
      </Card.Title>
      <Card.Subtitle>
        Use this group to streamline and speed up the booking process with your
        carriers.
      </Card.Subtitle>
      <Card.Divider />
      <Card.Body>
        <Table size="large" data-testid="automations-table">
          <Table.Head>
            <Table.Row>
              <Table.HeadCell>Automation</Table.HeadCell>
              <Table.HeadCell>Description</Table.HeadCell>
              <Table.HeadCell> </Table.HeadCell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <Text variant="body-md">Auto accept tender</Text>
              </Table.Cell>
              <Table.Cell>
                <Text variant="body-md">
                  All tenders sent to this carrier will be automatically
                  accepted by them.
                </Text>
              </Table.Cell>
              <Table.Cell>
                <ToggleGroup
                  type="single-strict"
                  onChange={(event) => {
                    onChange(
                      'auto_accept_tender',
                      event.target.value === 'true'
                    )
                  }}
                  value={String(Boolean(autoAcceptTender))}
                >
                  <Toggle value="false">Off</Toggle>
                  <Toggle value="true">On</Toggle>
                </ToggleGroup>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Card.Body>
    </Card>
  )
}
