import type { AxiosError } from 'axios'
import { useRef } from 'react'
import { useMutation, useQueryClient } from 'react-query'

import useLocalStorageState from 'hooks/useLocalStorageState'
import { useModal } from 'hooks/useModal'
import { uploadLanes } from 'rfp/services/rfp'

import type { UploadLanesSuccessResponse } from '../types'
import ImportModal, { useImportState } from './ImportModal'
import InfoDialog from './InfoDialog'

type ImportLanesModalProps = {
  readonly rfpId: string | number
  readonly onSuccess: (lanes: UploadLanesSuccessResponse) => void
  readonly onError: (start?: number, errorType?: string) => void
  readonly onClose: () => void
  readonly isOpen: boolean
  readonly onDownloadTemplate: () => void
}

const ImportLanesModal = ({
  rfpId,
  onSuccess,
  onError,
  isOpen,
  onClose,
  onDownloadTemplate,
}: ImportLanesModalProps) => {
  const queryClient = useQueryClient()
  const [dontShowAgain, setDontShowAgain] = useLocalStorageState(
    'upload-lane-dont-show-again',
    { initialValue: false }
  )

  const infoDialog = useModal(true)
  const shouldShowInfoDialog = !dontShowAgain && isOpen && infoDialog.modalState

  const uploadStartRef = useRef(0)
  const [state, dispatch] = useImportState()
  const { mutate: upload, isLoading: isUploading } = useMutation({
    mutationFn: uploadLanes,
    onSuccess: (responseData) => {
      dispatch({ type: 'UPLOAD_SUCCEEDED' })
      onSuccess(responseData)
    },
    onError: (error: any) => {
      let errorType
      if (error.response?.status == 406) {
        dispatch({ type: 'LANES_LIMIT_REACHED' })
        errorType = 'LANES_LIMIT_REACHED'
      }

      const { message } = error as AxiosError

      if (message === 'timeout of 30000ms exceeded') {
        dispatch({ type: 'UPLOAD_TIMEOUT' })
      } else {
        dispatch({ type: 'UPLOAD_FAILED' })
      }

      onError(uploadStartRef.current, errorType)
    },
  })

  function onFileSelect(file: File) {
    dispatch({ type: 'FILE_SELECTED', file })
  }

  function onUploadFile(file: File) {
    uploadStartRef.current = Date.now()
    upload({ files: [file], rfpId, queryClient })
  }

  if (shouldShowInfoDialog) {
    return (
      <InfoDialog
        isOpen={infoDialog.modalState}
        onClose={infoDialog.closeModal}
        setDontShowAgain={() => {
          setDontShowAgain(true)
        }}
      />
    )
  }

  return (
    <ImportModal
      descriptors={{
        title: 'Import lanes',
        steps: ['Download Template', 'Add Lanes Data', 'Upload File'],
        hint: `To import lanes, please download the template and add your lane data without changing the structure of the file. You can import up to a maximum of 2000 lanes.`,
        resource: 'lanes',
        errorReportMessage:
          'Download the error report to see the shipments that failed to import.',
        successMessage:
          'Loadsmart rates are already available and you can quote the shipments with your carriers.',
      }}
      isOpen={isOpen}
      onClose={onClose}
      onDownloadTemplate={onDownloadTemplate}
      onFileSelect={onFileSelect}
      onUploadFile={onUploadFile}
      onUploadNewFile={() => dispatch({ type: 'RESET' })}
      state={state}
      isUploading={isUploading}
    />
  )
}

export default ImportLanesModal
