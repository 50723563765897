import type { RouteComponentProps } from 'react-router-dom'
import { Route, Switch } from 'react-router-dom'

import { GlobalSpinner } from '_shared_/components/GlobalSpinner'
import { useSettings } from 'contexts/settings'

import { PaymentBasePage } from './list/PaymentsBase'

export function PayablesRoutes({ match }: Readonly<RouteComponentProps>) {
  const { path } = match

  const {
    isLoading,
    values: [payablesPageEnabled],
  } = useSettings(['flags.ENABLE_SHIPMENT_SETTLEMENT_TAB'])

  if (isLoading || !payablesPageEnabled) {
    return <GlobalSpinner />
  }

  return (
    <Switch>
      <Route path={path} exact component={PaymentBasePage} />
    </Switch>
  )
}
