import * as Sentry from '@sentry/react'
import type { QueryClient } from 'react-query'

import {
  WS_EVENT_RFP_LANE_CHANGED,
  WS_EVENT_RFP_PROPOSAL_ADDED,
  WS_EVENT_RFP_PROPOSAL_DELETED,
} from 'utils/constants'

const deallocatedLanesQueryKey = 'deallocatedLanes'
export const allocatedLanesQueryKey = 'allocatedLanes'
const proposalQueryKey = 'retrieveLaneProposal'

export interface LaneAndProposal extends Partial<Lane> {
  proposal: LaneProposal
}

type WebSocketUpdateProposalResponse = Record<string, LaneAndProposal>

type MultiModeWebSocketDeleteResponse = Record<string, number[]>

export function routeEvent(payload: any, queryClient: QueryClient) {
  switch (payload.event) {
    case WS_EVENT_RFP_LANE_CHANGED:
      laneUpdateHandler(payload.data, queryClient)
      break
    case WS_EVENT_RFP_PROPOSAL_ADDED:
      proposalUpdateHandler(payload.data, queryClient)
      break
    case WS_EVENT_RFP_PROPOSAL_DELETED:
      proposalDeleteHandlerV2(payload.data, queryClient)

      break
    default:
      break
  }
}

function updateRoutingGuideProposals(
  data: WebSocketUpdateProposalResponse,
  queryClient: QueryClient
) {
  const laneIds = Object.keys(data)

  laneIds.forEach((laneId) => {
    queryClient.setQueryData(
      [proposalQueryKey, Number(laneId)],
      (oldData: LaneProposal[] | undefined) => {
        if (!oldData) {
          return [data[laneId].proposal]
        }

        const proposalId = data[laneId].proposal.id

        /**
         * For unkown reason (maybe SharedWorker),
         * sometimes we got multiple WS events with same data
         * so we need this to avoid duplicates
         */
        if (oldData.find((proposal) => proposal.id === proposalId)) {
          return oldData
        }

        const newData = structuredClone(oldData)
        newData.push(data[laneId].proposal)
        return newData
      }
    )
  })
}

function updateAwardScenariosLane(
  data: Record<string, Partial<LaneAndProposal>>,
  queryClient: QueryClient
) {
  /**
   * queryClient.setQueryData() would be suffice here, but since we don't have all data that created the queryKey
   * and since setQueryData doesn't accept filters (like exact: false) it was needed to use a more
   * "generic" function, in order to achieve what we want.
   */
  Object.keys(data).forEach((laneId) => {
    queryClient.setQueriesData<PaginatedResult | undefined>(
      { queryKey: [deallocatedLanesQueryKey], exact: false },
      (oldData): PaginatedResult | undefined => {
        if (!oldData) {
          return oldData
        }

        const newData = oldData.results.map((lane: Lane) => {
          if (Number(laneId) === Number(lane.id)) {
            return {
              ...lane,
              ...structuredClone(data[laneId]),
              proposal: undefined,
            }
          }

          return lane
        })

        return {
          count: oldData.count,
          next: oldData.next,
          previous: oldData.previous,
          results: newData,
        }
      }
    )

    queryClient.setQueriesData<PaginatedResult | undefined>(
      { queryKey: [allocatedLanesQueryKey], exact: false },
      (oldData): PaginatedResult | undefined => {
        if (!oldData) {
          return oldData
        }

        const newData = oldData.results.map((lane: Lane) => {
          if (Number(laneId) === Number(lane.id)) {
            return {
              ...lane,
              remaining_award_volume: data[laneId].remaining_award_volume,
            }
          }

          return lane
        })

        return {
          count: oldData.count,
          next: oldData.next,
          previous: oldData.previous,
          results: newData,
        }
      }
    )
  })
}

export function updateLaneAwardedProposalConfirmation(
  data: Record<string, Partial<LaneAndProposal>>,
  queryClient: QueryClient
) {
  queryClient.setQueriesData<PaginatedResult | undefined>(
    { queryKey: [allocatedLanesQueryKey], exact: false },
    (oldData): PaginatedResult | undefined => {
      if (!oldData) {
        return oldData
      }

      const newData = structuredClone(oldData)

      newData.results.forEach((lane: Lane) => {
        const laneId = lane.id
        const eventLaneProposal = data[laneId]?.proposal

        if (eventLaneProposal && lane.awarded_proposals) {
          lane.awarded_proposals.forEach((proposal: LaneProposal) => {
            const proposalId = eventLaneProposal?.id

            if (proposal.id === proposalId) {
              proposal.award_confirmation = eventLaneProposal.award_confirmation
            }
          })
        }
      })

      return newData
    }
  )
}

function laneUpdateHandler(
  data: WebSocketUpdateProposalResponse,
  queryClient: QueryClient
) {
  try {
    updateAwardScenariosLane(data, queryClient)
  } catch (error) {
    Sentry.captureException(error)
  }
}

function proposalUpdateHandler(
  data: WebSocketUpdateProposalResponse,
  queryClient: QueryClient
) {
  try {
    updateAwardScenariosLane(data, queryClient)
    updateRoutingGuideProposals(data, queryClient)
    updateLaneAwardedProposalConfirmation(data, queryClient)
  } catch (error) {
    Sentry.captureException(error)
  }
}

function proposalDeleteHandlerV2(
  data: MultiModeWebSocketDeleteResponse,
  queryClient: QueryClient
) {
  try {
    const updater =
      (proposalId: number) => (oldData: LaneProposal[] | undefined) => {
        if (!oldData) {
          return oldData
        }

        const proposalIndex = oldData.findIndex(
          (proposal) => proposal.id === proposalId
        )
        if (proposalIndex === -1) {
          return oldData
        }
        const newData = structuredClone(oldData)
        newData.splice(proposalIndex, 1)

        return newData
      }

    const laneIds = Object.keys(data)
    laneIds.forEach((laneId) => {
      data[laneId].forEach((proposalId) => {
        queryClient.setQueryData<LaneProposal[] | undefined>(
          [proposalQueryKey, Number(laneId)],
          updater(proposalId)
        )
      })

      queryClient.setQueriesData<PaginatedResult | undefined>(
        { queryKey: [deallocatedLanesQueryKey], exact: false },
        (oldData): PaginatedResult | undefined => {
          if (!oldData) {
            return oldData
          }

          const laneIndex = oldData.results.findIndex(
            (lane) => lane.id === Number(laneId)
          )

          if (laneIndex === -1) {
            return oldData
          }

          const newData = structuredClone(oldData)

          newData.results[laneIndex].proposals_count =
            newData.results[laneIndex].proposals_count - data[laneId].length

          return {
            count: oldData.count,
            next: oldData.next,
            previous: oldData.previous,
            results: newData.results,
          }
        }
      )
    })
  } catch (error) {
    Sentry.captureException(error)
  }
}
