import { IconSettings } from '@loadsmart/icons'
import { Button, Layout, Text } from '@loadsmart/loadsmart-ui'
import { useMemo, useState } from 'react'
import type { Dispatch, SetStateAction } from 'react'
import ReactPaginate from 'react-paginate'
import type { UseQueryResult } from 'react-query'

import NoResultsIllustration from 'assets/illustrations/no-results.svg'
import {
  Container,
  EmptyLanesContainer,
  EmptyLanesImage,
} from 'components/LaneManagement/LanesTableV3/styles'
import { useModal } from 'hooks/useModal'
import usePagination from 'hooks/usePagination'
import LaneFilters from 'rfp/components/lane-filters'
import type { QueryParams } from 'rfp/components/table/laneParams'
import {
  getTablePageOffset,
  getTableSortDirection,
  useLaneIdMaxLength,
} from 'rfp/components/table/tableUtils'
import { TagFilters } from 'rfp/components/table/Tags'
import { useTagFilters } from 'rfp/components/table/Tags/useTagFilters'
import BaseTable from 'rfp/rfp-details/award-scenarios/BaseTable'
import EmptyState from 'rfp/rfp-details/award-scenarios/EmptyState'
import LaneSearchField from 'rfp/rfp-details/award-scenarios/LaneSearchField'
import {
  CenteredText,
  FilterWrapper,
} from 'rfp/rfp-details/award-scenarios/styles'
import type { RequestForProposal } from 'rfp/rfp.types'
import { RFP_STATE } from 'utils/constants'
import { isMultiCurrency } from 'utils/currency'

import makeLanesInProgressColumns from './makeLanesInProgressColumns'

enum RadioOptions {
  LOWEST = 'lowest_bid',
  AVERAGE = 'average_bid',
  HIGHEST = 'highest_bid',
  LAST_AWARD = 'last_award',
  TARGET_RATE = 'target_rate',
  SPOT_MARKET = 'market_rate',
  SHORT_CONTRACT = 'contract_rate',
}

const RateOptions = [
  { value: 'market_rate', label: 'Market Benchmark' },
  { value: 'target_rate', label: 'Target Rate' },
  { value: 'last_award', label: 'Last Award' },
]

const MultiCurrencyDraftRateOptions = RateOptions.filter((option) =>
  [RadioOptions.TARGET_RATE, RadioOptions.LAST_AWARD].includes(
    option.value as RadioOptions
  )
)

const MultiCurrencyPublishedRateOptions = RateOptions.filter(
  (option) => option.value === RadioOptions.LAST_AWARD
)

interface LanesInProgressProps {
  readonly rfp: RequestForProposal
  readonly unrankedLanesQuery: UseQueryResult<PaginatedResult, unknown>
  readonly rankedLanesQuery: UseQueryResult<PaginatedResult, unknown>
  readonly showLaneDetailsOrRoutingGuide: (lane: Lane) => void
  readonly showRecommendedCarriersSidebar: (lane: Lane) => void
  readonly unrankedLanesParams: QueryParams
  readonly setUnrankedLanesParams: Dispatch<SetStateAction<QueryParams>>
  readonly goToLanesAllocated: () => void
  readonly unrankedLanesSearchQuery: string
  readonly setUnrankedLanesSearchQuery: Dispatch<SetStateAction<string>>
}

const AllLanesAllocatedEmptyState = ({
  changeTab,
}: {
  readonly changeTab: () => void
}) => {
  return (
    <EmptyState>
      <Layout.Stack space="s">
        <Text variant="heading-sm-bold" color="color-neutral-darkest">
          All Lanes Allocated
        </Text>
        <Text variant="caption" color="color-neutral-darker">
          Well done. You have allocated all
          <br />
          the lanes on this RFP.
        </Text>
      </Layout.Stack>
      <Button
        variant="primary"
        onClick={() => {
          changeTab()
        }}
        scale="small"
      >
        go to lanes allocated
      </Button>
    </EmptyState>
  )
}

const LIMIT = 50

const defaultParams = {
  page: 0,
  offset: 0,
  limit: LIMIT,
  sort: {
    column: 'lane_id',
    direction: 'asc',
  },
}

const LanesInProgress = ({
  rfp,
  showLaneDetailsOrRoutingGuide,
  showRecommendedCarriersSidebar,
  unrankedLanesQuery,
  unrankedLanesParams,
  setUnrankedLanesParams,
  rankedLanesQuery,
  goToLanesAllocated,
  unrankedLanesSearchQuery,
  setUnrankedLanesSearchQuery,
}: LanesInProgressProps) => {
  const displayMultiCurrency = isMultiCurrency(rfp.currency)
  const [bidOption, setRadioOptionOne] = useState<string>(RadioOptions.LOWEST)

  const getInitialRateOption = () =>
    displayMultiCurrency || rfp.state === RFP_STATE.DRAFT
      ? RadioOptions.LAST_AWARD
      : RadioOptions.SPOT_MARKET

  const [rateOption, setRadioOptionTwo] = useState<string>(
    getInitialRateOption()
  )

  const getAvailableRateOptions = () => {
    const multiCurrencyOptions =
      rfp.state === 'draft'
        ? MultiCurrencyDraftRateOptions
        : MultiCurrencyPublishedRateOptions

    return displayMultiCurrency ? multiCurrencyOptions : RateOptions
  }

  const availableRateOptions = getAvailableRateOptions()

  const { filters, setFilters, removeFilter, clearFilters } = useTagFilters(
    unrankedLanesParams,
    setUnrankedLanesParams
  )

  const {
    modalState: laneFiltersState,
    openModal: laneFiltersOpenModal,
    closeModal: laneFiltersCloseModal,
  } = useModal()

  const { pageCount, page } = usePagination(
    LIMIT,
    unrankedLanesQuery.data?.count,
    unrankedLanesParams.page
  )

  const handlePageChange = (selectedItem: { selected: number }) => {
    setUnrankedLanesParams({
      ...unrankedLanesParams,
      page: selectedItem.selected,
      offset: getTablePageOffset(selectedItem.selected, defaultParams.offset),
    })
  }

  const changeSorting = (column: string) => {
    setUnrankedLanesParams({
      ...unrankedLanesParams,
      sort: {
        column,
        direction: getTableSortDirection(unrankedLanesParams.sort.direction),
      },
    })
  }

  const laneIdMaxLength = useLaneIdMaxLength()

  const columns = useMemo(() => {
    return () =>
      makeLanesInProgressColumns({
        laneIdMaxLength,
        bidOption,
        rateOption,
        rfp,
        availableRateOptions,
        displayMultiCurrency,
        showLaneDetailsOrRoutingGuide,
        showRecommendedCarriersSidebar,
        setRadioOptionOne,
        setRadioOptionTwo,
      })
  }, [
    laneIdMaxLength,
    bidOption,
    rateOption,
    rfp,
    availableRateOptions,
    displayMultiCurrency,
    showLaneDetailsOrRoutingGuide,
    showRecommendedCarriersSidebar,
  ])

  const isQueryLoadingOrHasResults = () =>
    unrankedLanesQuery.isLoading ||
    (unrankedLanesQuery.data?.results?.length ?? 0) > 0

  const showTable = isQueryLoadingOrHasResults()

  const hasOnlyRankedLanes = () =>
    (rankedLanesQuery.data?.count ?? 0) > 0 &&
    unrankedLanesQuery.data?.count === 0 &&
    unrankedLanesSearchQuery === ''

  const shouldRenderAllLanesAllocatedEmptyState = hasOnlyRankedLanes()

  if (shouldRenderAllLanesAllocatedEmptyState) {
    return <AllLanesAllocatedEmptyState changeTab={goToLanesAllocated} />
  }

  const noResults = !showTable && unrankedLanesSearchQuery !== ''

  return (
    <Container>
      <Layout.Stack>
        <FilterWrapper align="center" justify="space-between">
          <Text variant="heading-md-bold" color="color-neutral-darker">
            {`${unrankedLanesQuery.data?.count ?? 0} lanes`}
          </Text>
          <Layout.Group space="s">
            <LaneSearchField
              laneSearchQuery={unrankedLanesSearchQuery}
              setLaneSearchQuery={(query) => {
                setUnrankedLanesParams({
                  ...unrankedLanesParams,
                  page: defaultParams.page,
                  offset: defaultParams.offset,
                })
                setUnrankedLanesSearchQuery(query)
              }}
            />
            {[RFP_STATE.PUBLISHED].includes(rfp.state) && (
              <Button
                variant="secondary"
                scale="default"
                onClick={laneFiltersOpenModal}
                leading={<IconSettings width={16} height={16} />}
              >
                Filters
              </Button>
            )}
          </Layout.Group>
        </FilterWrapper>
        <FilterWrapper>
          <TagFilters
            filters={filters}
            removeFilter={removeFilter}
            clearFilters={clearFilters}
          />
        </FilterWrapper>
        {noResults && (
          <EmptyLanesContainer border="v2">
            <EmptyLanesImage src={NoResultsIllustration} />
            <CenteredText variant="body-bold">
              Sorry. We couldn&apos;t find any
              <br />
              matching lanes with this status
            </CenteredText>
          </EmptyLanesContainer>
        )}
        {showTable && (
          <BaseTable
            isLoading={unrankedLanesQuery.isLoading}
            columns={columns}
            entries={unrankedLanesQuery.data?.results ?? []}
            onRowClick={(_event, row) =>
              showLaneDetailsOrRoutingGuide(row.original)
            }
            sortable
            selectable={false}
            changeSorting={changeSorting}
            sort={unrankedLanesParams.sort}
          />
        )}
      </Layout.Stack>
      {pageCount > 1 && (
        <ReactPaginate
          data-testid="pagination"
          previousLabel="Previous"
          nextLabel="Next"
          breakLabel="..."
          breakClassName="break-me"
          pageCount={pageCount}
          forcePage={page}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName="pagination"
          activeClassName="active"
        />
      )}
      <LaneFilters
        rfpId={rfp.id}
        rfpState={rfp.state}
        isOpen={laneFiltersState}
        onConfirm={(newFilters) => {
          laneFiltersCloseModal()
          setFilters(newFilters)
        }}
        onClose={laneFiltersCloseModal}
        filters={filters}
      />
    </Container>
  )
}

export default LanesInProgress
