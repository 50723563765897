import SideBar from 'components/SideBar'
import RoutingGuide from 'rfp/rfp-details/components/routing-guide'
import type { RequestForProposal } from 'rfp/rfp.types'
import { RFP_STATE } from 'utils/constants'

const RoutingGuideSideBar = ({
  modalState,
  closeModal,
  rfp,
  selectedLane,
  invites,
  resetParamsAndRefetchQueries,
  isLoadingCarrierInvites,
}: {
  readonly modalState: boolean
  readonly closeModal: () => void
  readonly rfp: RequestForProposal
  readonly selectedLane: Lane | undefined
  readonly invites: NewCarrierInvite[]
  readonly resetParamsAndRefetchQueries: () => void
  readonly isLoadingCarrierInvites: boolean
}) => {
  const shouldRenderRoutingGuide =
    rfp.state !== RFP_STATE.DRAFT && selectedLane && !isLoadingCarrierInvites

  return (
    <SideBar showToggle={false} isOpen={modalState}>
      {shouldRenderRoutingGuide && (
        <RoutingGuide
          lane={selectedLane}
          carrierInvites={invites}
          onClose={closeModal}
          rfp={rfp}
          awardScenariosCallback={resetParamsAndRefetchQueries}
        />
      )}
    </SideBar>
  )
}

export default RoutingGuideSideBar
