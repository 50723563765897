import { IconFavoriteLane } from '@loadsmart/icons'
import { Checkbox, Tooltip, TooltipAlign } from '@loadsmart/loadsmart-ui'
import { getToken } from '@loadsmart/loadsmart-ui/dist/theming'
import type { Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'

import StringLimiter from '_shared_/components/StringLimiter'
import TableActions from '_shared_/components/TableActions'
import checkmarkIcon from 'assets/icons/checkmark-disabled.svg'
import loadsmartLogo from 'assets/icons/loadsmart.svg'
import plusIcon from 'assets/icons/plus-sign.svg'
import { Text as CustomText } from 'components/Typography'
import { useSettings } from 'contexts/settings'
import { RateType } from 'features/laneProposals/reducer'
import { getPerDistanceRate, isKilometers } from 'rfp/components/distanceType'
import MultiCurrencyValue from 'rfp/rfp-details/components/MultiCurrencyValue'
import type { RequestForProposal } from 'rfp/rfp.types'
import { theme } from 'styles/theme'
import analytics, {
  AnalyticsEvent,
  AnalyticsEventTrigger,
} from 'utils/analytics'
import { BID_TYPE, RFP_STATE } from 'utils/constants'
import formatCurrency from 'utils/formatCurrency'
import { numberFormatter } from 'utils/numbers'

import {
  copyToClipboard,
  getBenchmarkColor,
  getFirstHighestBid,
  getFirstLowestBid,
} from '../../utils'
import {
  CarrierCell,
  LSIcon,
  IconHourglassStyled,
  TooltipTextContainer,
  IconRefreshStyled,
  RankedAwardIcon,
  RankedAwardTooltip,
  RatePercent,
} from './styles'

const RankIcon = ({
  awardedType,
  canUpdateRankedProposals,
  onClick,
}: {
  readonly awardedType: string | null
  readonly canUpdateRankedProposals: boolean
  readonly onClick: () => void
}) => {
  if (awardedType) {
    return <RankedAwardIcon src={checkmarkIcon} alt="Added to rank" />
  }

  if (canUpdateRankedProposals) {
    return (
      <RankedAwardTooltip message="Add to rank" align={TooltipAlign.Start}>
        <RankedAwardIcon src={plusIcon} alt="Add to rank" onClick={onClick} />
      </RankedAwardTooltip>
    )
  }

  return <RankedAwardIcon src={plusIcon} alt="Add to rank" disabled={true} />
}

const TooltipWrapper = styled.span`
  height: ${getToken('space-l')};
  width: ${getToken('space-l')};
`

const carrierMaxLength = 34
const carrierMaxLengthWithBidIcon = 24

const HighCapacityInfo = ({
  lane,
  contractRatePercentDiff,
}: {
  readonly lane: Lane
  readonly contractRatePercentDiff: number
}) => (
  <TooltipWrapper>
    &nbsp;&nbsp;
    <Tooltip
      message={
        <TooltipTextContainer>
          <span>
            <b>LS High Capacity</b>
          </span>
          <span>
            {lane?.contract_rate ? formatCurrency(lane.contract_rate) : '$ -'}
            {' + '}
            {numberFormatter(contractRatePercentDiff, 2)}% Margin
          </span>
        </TooltipTextContainer>
      }
    >
      <IconFavoriteLane width={20} height={20} fill={theme.colors.main} />
    </Tooltip>
  </TooltipWrapper>
)

const getLimiterValue = (underNegotiation: boolean) =>
  underNegotiation ? carrierMaxLengthWithBidIcon : carrierMaxLength

const CarrierName = ({
  dot,
  loadsmartDOT,
  name,
  under_negotiation,
  is_high_capacity,
  contractRatePercentDiff,
  lane,
}: {
  readonly dot: string
  readonly loadsmartDOT: string
  readonly name: string
  readonly under_negotiation: boolean
  readonly is_high_capacity: boolean
  readonly contractRatePercentDiff: number
  readonly lane: Lane
}) => {
  return dot !== loadsmartDOT ? (
    <StringLimiter value={name} limiter={getLimiterValue(under_negotiation)} />
  ) : (
    <>
      <LSIcon type="image/svg+xml" data={loadsmartLogo}>
        {name}
      </LSIcon>
      {is_high_capacity && (
        <HighCapacityInfo
          contractRatePercentDiff={contractRatePercentDiff}
          lane={lane}
        />
      )}
    </>
  )
}

export const BidCarrierCell = ({
  row,
  rfp,
  lane,
  canAwardOrUnaward,
  canUpdateRankedProposals,
  handleAddToRank,
  handleCheckCarrier,
}: {
  readonly row: { original: LaneProposal }
  readonly rfp: RequestForProposal
  readonly lane: Lane
  readonly canAwardOrUnaward: boolean
  readonly canUpdateRankedProposals: boolean
  readonly handleAddToRank: (proposal: LaneProposal) => void
  readonly handleCheckCarrier: (proposal: LaneProposal) => void
}) => {
  const {
    values: [loadsmartDOT],
  } = useSettings(['settings.LOADSMART_DOT'])

  const isRankedAward = rfp.bid_type === BID_TYPE.RANK

  const contractRatePercentDiff = Number(
    row.original.contract_rate_percent_difference
  )
  const {
    under_negotiation,
    initial_flat_rate,
    is_high_capacity,
    negotiation_note,
  } = row.original
  const { name, dot } = row.original.carrier

  const isCheckboxChecked = row.original.awarded_type !== null

  const cantAwardIfNoVolume =
    rfp.state === RFP_STATE.AWARDING && lane.remaining_award_volume === 0

  const cantUnawardInAwardingState =
    rfp.state === RFP_STATE.AWARDING && isCheckboxChecked

  const isCheckboxDisabled =
    !canAwardOrUnaward || cantAwardIfNoVolume || cantUnawardInAwardingState

  return (
    <CarrierCell
      className={`${row.original.awarded_type !== null ? 'attenuated' : ''}`}
    >
      {isRankedAward ? (
        <RankIcon
          awardedType={row.original.awarded_type}
          canUpdateRankedProposals={canUpdateRankedProposals}
          onClick={() => handleAddToRank(row.original)}
        />
      ) : (
        <Checkbox
          scale="small"
          className="flex"
          checked={isCheckboxChecked}
          onChange={() => handleCheckCarrier(row.original)}
          disabled={isCheckboxDisabled}
        />
      )}
      {initial_flat_rate && (
        <TooltipWrapper
          onMouseEnter={() =>
            analytics.track(
              AnalyticsEvent.CounterOfferNewRateTooltip,
              AnalyticsEventTrigger.hover,
              {
                ...lane,
                ...rfp,
              },
              ['lane']
            )
          }
        >
          <Tooltip
            message={
              <TooltipTextContainer>
                <span>
                  <b>
                    New Rate (Previously{' '}
                    {formatCurrency(
                      initial_flat_rate,
                      true,
                      true,
                      rfp.currency
                    )}
                    )
                  </b>
                </span>
                <span>Your counter offer:</span>
                <span>{negotiation_note}</span>
              </TooltipTextContainer>
            }
          >
            <IconRefreshStyled
              width={20}
              height={20}
              color={theme.colors.main}
              title={null}
            />
          </Tooltip>
        </TooltipWrapper>
      )}
      {under_negotiation && (
        <TooltipWrapper
          onMouseEnter={() =>
            analytics.track(
              AnalyticsEvent.CounterOfferWaitingTooltip,
              AnalyticsEventTrigger.hover,
              {
                ...lane,
                ...rfp,
              },
              ['lane']
            )
          }
        >
          <Tooltip
            message={
              <TooltipTextContainer>
                <span>
                  <b>Waiting Carrier Response</b>
                </span>
                <span>Your counter offer:</span>
                <span>{negotiation_note}</span>
              </TooltipTextContainer>
            }
            align={TooltipAlign.Start}
          >
            <IconHourglassStyled
              width={20}
              height={20}
              color={theme.colors.warningDark}
              title={null}
            />
          </Tooltip>
        </TooltipWrapper>
      )}

      <CarrierName
        contractRatePercentDiff={contractRatePercentDiff}
        dot={dot}
        loadsmartDOT={loadsmartDOT}
        is_high_capacity={Boolean(is_high_capacity)}
        lane={lane}
        name={name}
        under_negotiation={Boolean(under_negotiation)}
      />
    </CarrierCell>
  )
}

export const BenchmarkCell = ({
  row,
  value,
  data,
  lane,
  rateType,
}: {
  readonly row: any
  readonly value: number | null
  readonly data: LaneProposal[]
  readonly lane: Lane
  readonly rateType: string
}) => {
  if (!value) {
    return <>- %</>
  }

  const flatRate = Number(row.values.flat_rate)
  let selectedRate = Number(lane?.target_rate || 0)
  if (rateType === RateType.LIVE) {
    selectedRate = Number(lane?.market_rate || 0)
  } else if (rateType === RateType.SHORT_TERM) {
    selectedRate = Number(lane?.contract_rate || 0)
  }
  const percent = Number(value)
  const firstLowestBid = getFirstLowestBid(data, selectedRate)
  const firstHighestBid = getFirstHighestBid(data, selectedRate)

  const color = getBenchmarkColor(
    data.length,
    flatRate,
    selectedRate,
    firstLowestBid,
    firstHighestBid
  )

  return (
    <RatePercent color={color}>
      {percent > 0 && '+'}
      {numberFormatter(percent, 1)} %
    </RatePercent>
  )
}

export const ActionsCell = ({
  row,
  lane,
  isRankedAward,
  canAwardOrUnaward,
  handleAddToRank,
  handleSelectCarrier,
  isSelectCarrierDisabled,
  rfp,
  canRemoveBid,
  setLaneOnCounterOfferModal,
  counterOfferOpenModal,
  inviteLink,
  handleRemoveBid,
}: {
  readonly row: { original: LaneProposal }
  readonly lane: Lane
  readonly isRankedAward: boolean
  readonly canAwardOrUnaward: boolean
  readonly handleAddToRank: (proposal: LaneProposal) => void
  readonly handleSelectCarrier: (proposal: LaneProposal) => void
  readonly isSelectCarrierDisabled: boolean
  readonly rfp: RequestForProposal
  readonly canRemoveBid: boolean
  readonly setLaneOnCounterOfferModal: Dispatch<
    SetStateAction<LaneProposal | null>
  >
  readonly counterOfferOpenModal: () => void
  readonly inviteLink: string
  readonly handleRemoveBid: (laneProposal: LaneProposal) => void
}) => {
  return (
    <TableActions
      onClick={() => {
        analytics.track(
          AnalyticsEvent.RFPLaneCarrierMenu,
          AnalyticsEventTrigger.redirect,
          {
            ...row.original,
            ...lane,
          },
          ['lane']
        )
      }}
      disabled={Boolean(isRankedAward && row.original.awarded_type)}
    >
      {canAwardOrUnaward && (
        <>
          <TableActions.Item
            onClick={() => {
              if (isRankedAward) {
                handleAddToRank(row.original)
              } else {
                handleSelectCarrier(row.original)
              }
            }}
            disabled={isSelectCarrierDisabled}
          >
            <CustomText size="medium" weight="heavy" color="default">
              {isRankedAward ? 'Add to Rank' : 'Select Carrier'}
            </CustomText>
          </TableActions.Item>

          <TableActions.Item
            onClick={() => {
              setLaneOnCounterOfferModal(row.original)
              counterOfferOpenModal()
              analytics.track(
                AnalyticsEvent.CounterOfferStart,
                AnalyticsEventTrigger.click,
                {
                  ...lane,
                  ...rfp,
                },
                ['lane']
              )
            }}
            disabled={
              row.original.under_negotiation || rfp.state === RFP_STATE.AWARDING
            }
          >
            <CustomText size="medium" weight="heavy" color="default">
              Make Counter Offer
            </CustomText>
          </TableActions.Item>
        </>
      )}
      <TableActions.Item
        onClick={() => {
          copyToClipboard(inviteLink)

          analytics.track(
            AnalyticsEvent.RFPCopyInviteLink,
            AnalyticsEventTrigger.click,
            {
              source: 'drawer',
            }
          )
        }}
      >
        <CustomText size="medium" weight="heavy" color="default">
          Copy Invite Link
        </CustomText>
      </TableActions.Item>
      {canRemoveBid && (
        <TableActions.Item onClick={() => handleRemoveBid(row.original)}>
          <CustomText size="medium" weight="heavy" color="danger">
            Remove Bid
          </CustomText>
        </TableActions.Item>
      )}
    </TableActions>
  )
}

export const FlatRateCell = ({
  displayMultiCurrency,
  row,
  rfp,
}: {
  readonly displayMultiCurrency: boolean
  readonly row: { original: LaneProposal }
  readonly rfp: RequestForProposal
}) => {
  const valueField = displayMultiCurrency ? 'converted_flat_rate' : 'flat_rate'

  return (
    <div
      className={`${row.original.awarded_type !== null ? 'attenuated' : ''}`}
    >
      <MultiCurrencyValue
        value={row.original[valueField]}
        rfpCurrency={rfp.currency}
        nonConvertedValue={row.original['flat_rate']}
        boldStyle={false}
      />
    </div>
  )
}

export const RatePerMileCell = ({
  displayMultiCurrency,
  row,
  rfp,
}: {
  readonly displayMultiCurrency: boolean
  readonly row: { original: LaneProposal }
  readonly rfp: RequestForProposal
}) => {
  const value = getPerDistanceRate(
    row.original,
    rfp.distance_type,
    displayMultiCurrency
  )

  const nonConvertedValue = isKilometers(rfp.distance_type)
    ? row.original['per_kilometer_rate']
    : row.original['per_mile_rate']

  return (
    <div
      className={`${row.original.awarded_type !== null ? 'attenuated' : ''}`}
    >
      <MultiCurrencyValue
        value={value}
        rfpCurrency={rfp.currency}
        nonConvertedValue={nonConvertedValue}
        boldStyle={false}
      />
    </div>
  )
}
