import { Banner } from '@loadsmart/loadsmart-ui'
import styled from 'styled-components'

import type { Currency } from 'rfp/rfp.types'

const StyledBanner = styled(Banner)`
  margin: 20px 0;
`

export default function InvalidCurrencyBanner({
  currency,
}: {
  readonly currency?: Currency
}) {
  return (
    <StyledBanner
      variant="warning"
      dismissible={false}
      title={`This carrier does not accept ${currency} bids.`}
    />
  )
}
