import { defaultFilters } from 'rfp/components/lane-filters'
import type { Filters as LaneFilters } from 'rfp/components/lane-filters'
import type { LanePrimaryAwardStatus } from 'services/types'

export interface QueryParams {
  page: number
  offset: number
  limit: number
  sort: {
    column: string
    direction: string
  }
  filters: LaneFilters
  filterByRankedLanes: boolean | undefined
}

export interface LanesAwardedQueryParams extends QueryParams {
  primaryAwardStatus: LanePrimaryAwardStatus
}

export const LIMIT = 50

export const defaultLaneTableParams: QueryParams = {
  page: 0,
  offset: 0,
  limit: LIMIT,
  sort: {
    column: 'lane_id',
    direction: 'asc',
  },
  filters: defaultFilters,
  filterByRankedLanes: undefined,
}

export const defaultLaneTableAwardedParams: LanesAwardedQueryParams = {
  ...defaultLaneTableParams,
  primaryAwardStatus: '',
}
