import type { SelectOptionProps } from '@loadsmart/loadsmart-ui'
import { Select, HighlightMatch, useSelect } from '@loadsmart/loadsmart-ui'
import type { SelectDatasource } from '@loadsmart/loadsmart-ui/dist/components/Select/Select.types'
import { Text, Layout, Icon, Tooltip } from '@loadsmart/miranda-react'
import type { IconProps } from '@loadsmart/miranda-react'
import { isEmpty } from 'lodash'
import { useState } from 'react'
import type { QueryClient } from 'react-query'
import { useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import OpenDockIcon from 'assets/imgs/opendock-icon.png'
import { mapFiltersToURLSearchParams } from 'screens/Shipper/FacilityManagement/useFacilities'
import {
  deletePinnedFacility,
  getCustomerFacilities,
  getFacilities,
  postPinFacility,
} from 'services/facilities'
import type {
  FacilityDetailsV2,
  FacilityV2,
  PinnedFacility,
} from 'services/facilities'
import { joinValidStrings } from 'utils/strings'

import { GET_FACILITIES_QUERY_KEY } from './StopFacility.hooks'
import { IconContainer } from './StopFacility.styles'

export const FACILITY_ADAPTER = {
  getKey: (facility: Pick<FacilityV2, 'uuid'>) => facility.uuid,
  getLabel: (facility: Pick<FacilityV2, 'name' | 'address'>) => {
    return facility.name || facility.address
  },
}

export type FacilityOption = FacilityV2 & {
  pinned?: boolean
  stop_type?: StopType
}

export function facilityToFacilityDetails(
  facility: FacilityOption
): FacilityDetailsV2 {
  return {
    ...facility,
    contacts: [],
    hours_of_operation: [],
    notes: [],
    accessorials: [],
  }
}

export function facilityDetailsToFacility(
  facility: FacilityDetailsV2
): FacilityV2 {
  return {
    uuid: facility.uuid,
    name: facility.name,
    address: facility.address,
    address_details: facility.address_details,
    city: facility.city,
    state: facility.state,
    zipcode: facility.zipcode,
    country: facility.country,
    warehouse_uuid: facility.warehouse_uuid,
    contacts: facility.contacts.length,
    external_id: facility.external_id,
    archived: facility.archived,
  }
}

export function mapPinnedFacilities(
  pinnedFacilities: PinnedFacility[],
  facilities: FacilityV2[],
  stopType?: StopType
) {
  if (isEmpty(pinnedFacilities)) {
    return facilities.map((facility) => ({
      ...facility,
      pinned: false,
      stop_type: stopType,
    }))
  }

  const pinnedFacilitiesMap = new Map(
    pinnedFacilities.map((facility, index) => [facility.uuid, index])
  )

  // Get pinned facilities list
  const filteredPinnedFacilities = facilities.filter((facility) =>
    pinnedFacilitiesMap.has(facility.uuid)
  )
  const sortedPinnedFacilities = filteredPinnedFacilities.toSorted((a, b) => {
    return pinnedFacilitiesMap.get(b.uuid)! - pinnedFacilitiesMap.get(a.uuid)!
  })

  // Exclude pinned facilities from list
  const filteredFacilities = facilities.filter(
    (obj) => !pinnedFacilitiesMap.has(obj.uuid)
  )

  return [
    ...sortedPinnedFacilities.map((facility) => ({
      ...facility,
      pinned: true,
      stop_type: stopType,
    })),
    ...filteredFacilities.map((facility) => ({
      ...facility,
      pinned: false,
      stop_type: stopType,
    })),
  ]
}

export function buildFacilitiesDatasource(
  stopType?: StopType,
  pinnedFacilities?: PinnedFacility[]
) {
  return (): SelectDatasource<FacilityOption> => {
    return {
      type: 'facility',
      adapter: FACILITY_ADAPTER,
      async fetch({ query }) {
        const { results: facilities } = await getFacilities({
          params: mapFiltersToURLSearchParams({
            search: query,
            sort: 'name',
          }),
        })

        if (pinnedFacilities && stopType) {
          return mapPinnedFacilities(pinnedFacilities, facilities, stopType)
        }

        return facilities
      },
    }
  }
}

export function buildCustomerFacilitiesDatasource(customerUuid: string) {
  return (): SelectDatasource<FacilityV2> => {
    return {
      type: 'facility',
      adapter: FACILITY_ADAPTER,
      async fetch({ query }) {
        const params = mapFiltersToURLSearchParams({
          search: query,
          sort: 'name',
        })

        if (customerUuid) {
          params.append('customer_uuid', customerUuid)
        }

        const { results: facilities } = await getCustomerFacilities({
          params,
        })

        return facilities
      },
    }
  }
}

function refetchPinnedFacilities(queryClient: QueryClient, stopType: StopType) {
  queryClient.refetchQueries({
    queryKey: [GET_FACILITIES_QUERY_KEY, stopType],
  })
}

const PinIcon = ({
  isSelected,
  isLoading,
  tooltipMessage,
  iconName,
  onPinIconClick,
  show,
}: {
  readonly isSelected: boolean
  readonly isLoading: boolean
  readonly tooltipMessage: string
  readonly iconName: IconProps['name']
  readonly onPinIconClick: () => void
  readonly show?: boolean
}) => {
  return (
    <span style={{ visibility: show ? 'visible' : 'hidden' }}>
      <IconContainer isSelected={isSelected}>
        <Tooltip placement="top" message={tooltipMessage}>
          <Icon
            name={iconName}
            color={isLoading ? 'color-text-disabled' : 'color-primary-60'}
            data-testid={`pin-icon-${iconName}`}
            onClick={(event) => {
              event.stopPropagation()
              if (!isLoading) {
                onPinIconClick()
              }
            }}
          />
        </Tooltip>
      </IconContainer>
      <span style={{ marginLeft: '35px' }} />
    </span>
  )
}

export function CustomFacilityOption({ value }: SelectOptionProps) {
  const select = useSelect()
  const option = select.getOption(value) as FacilityOption
  const query = select.query
  const isSelected = select.isChecked(select.getOption(value)!)

  const {
    uuid,
    warehouse_uuid,
    address,
    address_details,
    pinned,
    stop_type: stopType,
  } = option
  const label = FACILITY_ADAPTER.getLabel(option)
  const [isOptionHovered, setIsOptionHovered] = useState(false)
  const [isPinActionPending, setIsPinActionPending] = useState(false)

  const shouldShowPins = Boolean(stopType)
  const setOptionHovered = () => setIsOptionHovered(!isOptionHovered)
  const queryClient = useQueryClient()
  const onPinIconClick = async () => {
    setIsPinActionPending(true)
    try {
      if (stopType) {
        if (pinned) {
          await deletePinnedFacility({
            facilityUUID: uuid,
            stopType,
          })
        } else {
          await postPinFacility({ facilityUUID: uuid, stopType })
        }
        refetchPinnedFacilities(queryClient, stopType)
      }
    } catch {
      toast.error(
        `Unable to ${pinned ? 'unpin' : 'pin'} Facility. Please try again`
      )
    }
  }

  return (
    <Select.Option
      value={value}
      onMouseEnter={setOptionHovered}
      onMouseLeave={setOptionHovered}
      style={{ position: 'relative' }}
    >
      <Layout.Group gap="spacing-2">
        <Icon
          name="warehouse"
          color="color-primary-100"
          style={{ paddingTop: '4px' }}
        />
        <Layout.Stack gap="spacing-0-5">
          <Layout.Group gap="spacing-1" align="center">
            <Text variant="body-md-bold">
              <HighlightMatch text={label} match={query} data-testid={value} />
            </Text>
            &nbsp;
            {warehouse_uuid && (
              <img src={OpenDockIcon} height={12} alt="OpenDock" />
            )}
          </Layout.Group>
          <Text variant="body-sm">
            {joinValidStrings([address, address_details], ', ')}
          </Text>
        </Layout.Stack>
      </Layout.Group>
      {shouldShowPins && (
        <PinIcon
          isSelected={isSelected}
          isLoading={isPinActionPending}
          tooltipMessage={pinned ? 'Unpin' : 'Pin to the top'}
          iconName={pinned ? 'pin-filled' : 'pin'}
          onPinIconClick={onPinIconClick}
          show={isOptionHovered || pinned}
        />
      )}
    </Select.Option>
  )
}
