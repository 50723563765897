import { SideNavigation } from '@loadsmart/loadsmart-ui'
import { useHistory } from 'react-router-dom'

import { userUtils } from '_shared_/user'
import { AppRoutes } from 'router/AppRoutes'
import { USER_TYPE } from 'utils/constants'

import { isActivePath, navbarLinkClicked } from './sidenav.utils'

export const AppsMenu = () => {
  const history = useHistory()

  if (!userUtils.isUserType([USER_TYPE.SG])) {
    return null
  }

  return (
    <SideNavigation.Menu label="Apps">
      <SideNavigation.Menu.Item
        active={isActivePath(AppRoutes.FreightWaves)}
        label="FreightWaves"
        url={AppRoutes.FreightWaves}
        onClick={(event) => {
          event.preventDefault()
          navbarLinkClicked('FreightWaves', AppRoutes.FreightWaves, history)
        }}
      />
    </SideNavigation.Menu>
  )
}
