import type { HTMLAttributes } from 'react'

import { Button } from './styles'

interface LinkButtonProps extends HTMLAttributes<HTMLButtonElement> {
  readonly disabled?: boolean
}

const LinkButton = ({ children, ...rest }: LinkButtonProps) => {
  return (
    <Button type="button" {...rest}>
      {children}
    </Button>
  )
}

export default LinkButton
