import styled from 'styled-components'

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.whiteBroken};
  color: ${({ theme }) => theme.colors.text};
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 600;
  justify-content: space-between;
  width: 600px;
`
export const InnerContainerScroll = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px 24px;
`
