import type { ExcessiveLengthClassification } from 'shipments/shipments.services'

import type { TransientFreightInformation } from './FreightInformation.types'

export const EXCESSIVE_LENGTH_CLASS_MAP = new Map<
  ExcessiveLengthClassification,
  string
>([
  ['excessive_length_8ft', 'Excessive length 8ft'] as const,
  ['excessive_length_12ft', 'Excessive length 12ft'] as const,
  ['excessive_length_16ft', 'Excessive length 16ft'] as const,
  ['excessive_length_20ft', 'Excessive length 20ft'] as const,
])

export const FREIGHT_INFORMATION_KEYS: (keyof TransientFreightInformation)[] = [
  '_metadata',
  'accessorials',
  'add_stop_off_charges',
  'bol_number',
  'carrier_ref_numbers',
  'equipment_length',
  'equipment_requirements',
  'equipment_subtypes',
  'equipment_type',
  'hazmat',
  'mode',
  'po_number',
  'power_only',
  'requested_accessorials',
  'shipper_custom_fields',
  'shipper_ref_number',
  'so_number',
  'tarp_size',
  'tarp_type',
  'with_tarp',
]
