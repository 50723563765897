import { IconAlertCircle, IconCheck, IconAttention } from '@loadsmart/icons'
import { Layout, ProgressBar, Steps, Text } from '@loadsmart/loadsmart-ui'
import { getToken } from '@loadsmart/loadsmart-ui/dist/theming'
import styled from 'styled-components'

export const StyledSteps = styled(Steps)`
  .is-complete {
    > span {
      &:first-child {
        color: ${getToken('color-neutral-white')};
        background-color: ${getToken('color-accent')};
      }
      &:nth-child(2) {
        font-weight: ${getToken('font-weight-medium')};
        width: 120px;
      }
    }
  }
`

export const StyledTextLink = styled(Text)`
  text-decoration: none;

  svg {
    vertical-align: middle;
  }
`

/* The min-height rule is to make it the same height as the DropZone */
export const FileZone = styled(Layout.Box)`
  display: flex;
  flex-direction: column;
  gap: ${getToken('space-l')};

  min-height: 196px;

  padding-bottom: ${getToken('space-l')};
  padding-left: ${getToken('space-2xl')};
  padding-right: ${getToken('space-2xl')};
  padding-top: ${getToken('space-3xl')};
`

export const ErrorIcon = styled(IconAlertCircle)`
  color: ${getToken('color-danger')};
`

export const SuccessIcon = styled(IconCheck)`
  fill: ${getToken('color-accent')};
`

export const WarningIcon = styled(IconAttention)`
  fill: ${getToken('color-warning')};
`

export const StyledProgressBar = styled(ProgressBar)`
  height: ${getToken('space-s')};

  & > span:first-child {
    background: ${getToken('color-neutral-light')};
    height: ${getToken('space-s')};
  }
  & > span:nth-child(2) {
    background: ${getToken('color-accent')};
    height: ${getToken('space-s')};
  }
`

export const StepContainer = styled(Layout.Stack).attrs(() => ({
  justify: 'space-between',
  space: 'xl',
}))`
  min-height: 290px;
`
