type Listener = (event: string, data?: unknown) => void

type ListenersTable = {
  [index: string]: Listener[]
}

export default class ListenerRegistry {
  private listeners: ListenersTable = {}

  add(name: string, listener: Listener) {
    this.listeners[name] = this.listeners[name] || []

    this.listeners[name].push(listener)
  }

  remove(name: string, listenerToRemove: Listener) {
    if (!this.listeners[name]) {
      return
    }

    this.listeners[name] = this.listeners[name].filter(
      (listener) => listener !== listenerToRemove
    )

    if (this.listeners[name].length === 0) {
      delete this.listeners[name]
    }
  }

  all() {
    return Object.values(this.listeners).flat()
  }

  trigger(name: string, data?: unknown) {
    this.listeners[name]?.forEach((listener) => listener(name, data))
  }
}
