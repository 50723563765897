import type { UseQueryOptions } from 'react-query'
import { useQuery } from 'react-query'

import type {
  RequestForProposal,
  RequestForProposalDetail,
} from 'rfp/rfp.types'
import {
  retrieve,
  getModes,
  getTimePeriods,
  getRateTypes,
  getEquipmentTypes,
  getAwardAcceptanceDealines,
  getCurrencies,
  totalizers,
  getBidTypes,
  getEquipmentSizes,
  getDistanceTypes,
  list as getRFPList,
  getEquipmentTypesGroupedByMode,
} from 'rfp/services/rfp'

export type RFPMode = { label: string; value: string }
export const useRfpModes = (
  options?: Omit<
    UseQueryOptions<unknown, unknown, RFPMode[]>,
    'queryKey' | 'queryFn'
  >
) => {
  return useQuery<unknown, unknown, RFPMode[]>({
    refetchInterval: false,
    refetchOnWindowFocus: false,
    ...options,
    queryKey: ['modes'],
    queryFn: getModes,
  })
}

export const useRfpRateTypes = () => {
  return useQuery({
    queryKey: ['rateTypes'],
    queryFn: getRateTypes,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  })
}

export const useRfpTimePeriods = () => {
  return useQuery({
    queryKey: ['timePeriods'],
    queryFn: getTimePeriods,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  })
}

export const useRfpEquipmentTypes = (mode?: string | string[]) => {
  return useQuery({
    queryKey: ['equipmentTypes', mode],
    queryFn: ({ signal }) => getEquipmentTypes(mode, { signal }),
    refetchInterval: false,
    refetchOnWindowFocus: false,
  })
}

export const useModeEquipmentTypes = () => {
  const { data, isLoading } = useQuery<{ [key: string]: SelectOption[] }>({
    queryKey: ['modeEquipmentTypes'],
    queryFn: getEquipmentTypesGroupedByMode,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  })
  return { data, isLoading }
}

export const useRfpEquipmentSizes = () => {
  return useQuery({
    queryKey: ['equipmentSizes'],
    queryFn: getEquipmentSizes,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  })
}

export const useRfpAwardAcceptanceDeadlines = () => {
  return useQuery({
    queryKey: ['awardAcceptanceDealines'],
    queryFn: getAwardAcceptanceDealines,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  })
}

export const useRfpCurrencies = () => {
  return useQuery({
    queryKey: ['currencies'],
    queryFn: getCurrencies,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  })
}

export const useRfpBidTypes = () => {
  return useQuery({
    queryKey: ['getBidTypes'],
    queryFn: ({ signal }) => getBidTypes({ signal }),
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    retry: false,
  })
}

export const useRfpRetrieve = (
  rfpId: string | number,
  options?: Omit<
    UseQueryOptions<RequestForProposalDetail>,
    'queryKey' | 'queryFn'
  >
) => {
  return useQuery<RequestForProposalDetail>({
    ...options,
    queryKey: ['retrieveRFP', rfpId],
    queryFn: ({ signal }) => retrieve(rfpId, { signal }),
  })
}

export const useRFPTotalizers = (rfpId: number | string) => {
  return useQuery<RFPTotalizers>({
    queryKey: ['rfpTotalizers', rfpId],
    queryFn: ({ signal }) => totalizers(rfpId, { signal }),
  })
}

export const useDistanceTypes = () => {
  return useQuery({
    queryKey: ['distanceTypes'],
    queryFn: ({ signal }) => getDistanceTypes({ signal }),
    retry: false,
    refetchOnWindowFocus: false,
  })
}

export const useRFPList = (params: {
  state: string | null
  name?: string
  selected: {
    mode: string
    frequency: string
    rateType: string
    bid: boolean | string
  }
  limit: number
  offset: number
  sort?: string
}) => {
  return useQuery<PaginatedResult<RequestForProposal>>({
    queryKey: [
      'rfp-list',
      params.state,
      params.name,
      params.selected.bid,
      params.selected.frequency,
      params.selected.mode,
      params.selected.rateType,
      params.limit,
      params.offset,
      params.sort,
    ],
    queryFn: ({ signal }) => {
      return getRFPList(
        {
          bid: String(params.selected.bid),
          frequency: params.selected.frequency,
          mode: params.selected.mode,
          rateType: params.selected.rateType,
          name: params.name,
          state: params.state ?? undefined,
          limit: params.limit,
          offset: params.offset,
          sort: params.sort,
        },
        { signal }
      )
    },
    retry: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    // this is necessary to the pagination to work properly
    // more info at: https://tanstack.com/query/v3/docs/react/guides/paginated-queries#better-paginated-queries-with-keeppreviousdata
    keepPreviousData: true,
  })
}
