import {
  Card,
  Dialog,
  Label,
  Layout,
  Select,
  Text,
  TextField,
} from '@loadsmart/loadsmart-ui'
import { getToken } from '@loadsmart/loadsmart-ui/dist/theming'
import { useState } from 'react'
import styled from 'styled-components'

import { useAwardAcceptanceDeadlines } from 'rfp/hooks/proposals'

const Wrapper = styled.section`
  text-align: left;
`

const StyledStack = styled(Layout.Stack)`
  width: 100%;
`

const StyledLabel = styled(Label)`
  span {
    font-size: ${getToken('font-size-5')};
    font-weight: 600;
    margin-right: ${getToken('space-xs')};
  }
`

const StyledTextField = styled(TextField)`
  width: 60%;
`

interface Props {
  readonly title?: string
  readonly isOpen: boolean
  readonly onClose: () => void
  readonly onSendAward: () => void
  readonly remainingVolume: number
  readonly inputValue: string
  readonly inputOnChange: ({ target: { value } }: any) => void
  readonly rfpId: number
  readonly setAwardAcceptanceDeadline: (n: number) => void
}

interface SelectOption {
  value: number
  label: string
  _type?: string
}

const DEFAULT_VALUE = { label: '1 day after award', value: 1, _type: 'generic' }

const AwardCarrierDialog = ({
  title = '',
  isOpen,
  onClose,
  onSendAward,
  remainingVolume,
  inputValue,
  inputOnChange,
  rfpId,
  setAwardAcceptanceDeadline,
}: Props) => {
  const [value, setValue] = useState<SelectOption>(DEFAULT_VALUE)

  const { data: options } = useAwardAcceptanceDeadlines(rfpId)

  return (
    <Dialog open={isOpen} scale="small" onOverlayClick={onClose}>
      <Dialog.Close onClick={onClose} />
      <Dialog.Header>{title}</Dialog.Header>
      <Dialog.Body>
        <Wrapper>
          <Layout.Stack space="m">
            <Layout.Stack>
              <Text variant="caption" color="color-neutral-darker">
                Select this carrier as <b>primary</b> to send an award.
              </Text>
              <Layout.Group>
                <StyledStack space="s">
                  <StyledLabel required>VOLUME GIVEN</StyledLabel>
                  <Layout.Group align="flex-end" space="s">
                    <StyledTextField
                      scale="default"
                      scheme="light"
                      type="number"
                      value={inputValue}
                      onChange={inputOnChange}
                      min={0}
                      max={remainingVolume}
                      data-testid="volume-input"
                    />
                    <Text variant="caption" color="color-neutral-darker">
                      of {remainingVolume}
                    </Text>
                  </Layout.Group>
                </StyledStack>
              </Layout.Group>
            </Layout.Stack>
            <div>
              <Card.Separator />
            </div>
            <Layout.Stack>
              <Text variant="caption" color="color-neutral-darker">
                The award acceptance deadline <b>must end before</b> your
                contract end date.
              </Text>
              <Layout.Stack space="xs">
                <StyledLabel>AWARD ACCEPTANCE DEADLINE</StyledLabel>
                <Select
                  name="selct-award-acceptance-deadline"
                  value={value}
                  onChange={(event) => {
                    const option = event.target.value as SelectOption
                    setValue(option)
                    setAwardAcceptanceDeadline(option.value)
                  }}
                  options={options || []}
                  data-testid="award-deadline-select"
                />
              </Layout.Stack>
            </Layout.Stack>
          </Layout.Stack>
        </Wrapper>
      </Dialog.Body>
      <Dialog.Actions direction="horizontal" scale="small">
        <Dialog.ActionConfirm onConfirm={onSendAward}>
          send award
        </Dialog.ActionConfirm>
        <Dialog.ActionCancel onCancel={onClose}>cancel</Dialog.ActionCancel>
      </Dialog.Actions>
    </Dialog>
  )
}

export default AwardCarrierDialog
