import type { UseQueryOptions } from 'react-query'
import { useQuery } from 'react-query'

import {
  fetchAwardAcceptanceDeadlines,
  fetchProposalCarrierInviteStates,
  fetchExtraChargeTypes,
  fetchProposalsEquipmentTypes,
  fetchProposalsBidStatus,
  fetchProposalsRateTypes,
  fetchProposalsTimePeriods,
  fetchProposalsModes,
} from 'rfp/services/proposals'

import type { ExtraChargeType } from '../rfp.types'

export const useProposalsEquipmentTypes = () => {
  return useQuery({
    queryKey: ['proposalsEquipmentTypes'],
    queryFn: ({ signal }) => fetchProposalsEquipmentTypes({ signal }),
    retry: false,
    refetchOnWindowFocus: false,
  })
}

export const useAwardAcceptanceDeadlines = (rfpId: number) => {
  return useQuery({
    queryKey: ['awardAcceptanceDeadlines', rfpId],
    queryFn: ({ signal }) => fetchAwardAcceptanceDeadlines(rfpId, { signal }),
    retry: false,
    refetchOnWindowFocus: false,
  })
}

export const useExtraChargeTypes = (
  options?: Omit<
    UseQueryOptions<ExtraChargeType[], Error, ExtraChargeType[], string[]>,
    'queryKey' | 'queryFn'
  >
) => {
  return useQuery({
    retry: false,
    refetchOnWindowFocus: false,
    ...options,
    queryKey: ['extraChargeTypes'],
    queryFn: ({ signal }) => fetchExtraChargeTypes({ signal }),
  })
}

export const useProposalCarrierInviteStates = (rfpId: string | number) => {
  return useQuery({
    queryKey: ['proposalCarrierInviteStates', rfpId],
    queryFn: ({ signal }) =>
      fetchProposalCarrierInviteStates(rfpId, { signal }),
    retry: false,
    refetchOnWindowFocus: false,
  })
}

export const useProposalsBidStatus = () => {
  return useQuery({
    queryKey: ['proposalsBidStatus'],
    queryFn: ({ signal }) => fetchProposalsBidStatus({ signal }),
    retry: false,
    refetchOnWindowFocus: false,
  })
}

export const useProposalsRateTypes = () => {
  return useQuery({
    queryKey: ['proposalsRateTypes'],
    queryFn: ({ signal }) => fetchProposalsRateTypes({ signal }),
    retry: false,
    refetchOnWindowFocus: false,
  })
}
export const useProposalsTimePeriods = () => {
  return useQuery({
    queryKey: ['proposalsTimePeriods'],
    queryFn: ({ signal }) => fetchProposalsTimePeriods({ signal }),
    retry: false,
    refetchOnWindowFocus: false,
  })
}

export const useProposalsModes = () => {
  return useQuery({
    queryKey: ['proposalsModes'],
    queryFn: ({ signal }) => fetchProposalsModes({ signal }),
    retry: false,
    refetchOnWindowFocus: false,
  })
}
