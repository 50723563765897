import { Card, Text, Layout } from '@loadsmart/loadsmart-ui'

import NotFoundImage from 'assets/imgs/search.png'

export default function NotFound() {
  return (
    <Card>
      <Layout.Stack>
        <Layout.Box textAlign="center" style={{ padding: '116px' }}>
          <Layout.Stack align="center" space="m">
            <Layout.Stack>
              <img
                src={NotFoundImage}
                alt="ExpireLink"
                style={{ width: 'fit-content' }}
              />
            </Layout.Stack>
            <Layout.Stack space="none">
              <Text variant="body-bold" style={{ padding: '0 50px' }}>
                Sorry. We couldn&apos;t find any matching carrier for this lane
              </Text>
              <Text variant="caption" color="color-neutral">
                You can add carrier from your own network
              </Text>
            </Layout.Stack>
          </Layout.Stack>
        </Layout.Box>
      </Layout.Stack>
    </Card>
  )
}
