import {
  EmptyState as EmptyStateComponent,
  Layout,
} from '@loadsmart/miranda-react'

import addOrdersIllustration from 'assets/illustrations/add-orders.svg'

import { usePlanFulfillmentsContext } from '../PlanFulfillmentsContext'

const EmptyState = () => {
  return (
    <Layout.Stack
      align="center"
      justify="center"
      style={{ flex: 1 }}
      data-testid="preview-empty-state"
    >
      <EmptyStateComponent
        variant="panel"
        illustration={addOrdersIllustration}
        header="Add a fulfillment to start planning your shipment"
        message="Add fulfillments from the list or explore the map to find them by location"
      />
    </Layout.Stack>
  )
}

export function PlanFulfillmentsPlanPreview() {
  const { selectedFulfillmentsUUIDs } = usePlanFulfillmentsContext()

  if (selectedFulfillmentsUUIDs.length === 0) {
    return (
      <Layout.Stack paddingR="spacing-4" style={{ width: '100%' }}>
        <EmptyState />
      </Layout.Stack>
    )
  }

  return null
}
