import { IconTruckDryvan, IconClose } from '@loadsmart/icons'
import { BannerLarge, Text, Spinner, Layout } from '@loadsmart/loadsmart-ui'
import { useRef } from 'react'

import { CloseSidebarWrapper, TitleContainer } from 'components/SideBar/styles'
import { usePreventPageScrollOver } from 'hooks/usePreventPageScrollOver'
import type { RequestForProposal } from 'rfp/rfp.types'

import NotFound from './components/NotFound'
import RecommendedCarrierCard from './components/RecommendedCarrierCard'
import { Container, InnerContainerScroll } from './styles'

const Content = ({
  data,
  rfp,
  refetchCarrierInvites,
  isLoadingRecommendedCarriers,
  notFoundCarriersRecommendations,
}: {
  readonly data: any
  readonly rfp: RequestForProposal
  readonly refetchCarrierInvites: () => Promise<unknown>
  readonly isLoadingRecommendedCarriers: boolean
  readonly notFoundCarriersRecommendations: boolean
}) => {
  if (isLoadingRecommendedCarriers) {
    return (
      <Layout.Box padding="none">
        <Layout.Stack space="3xl">
          <Layout.Stack>
            <Text variant="heading-sm-bold">
              We are searching the best carriers for this lane
            </Text>
            <Text variant="body">This shouldn&apos;t take long</Text>
          </Layout.Stack>
          <Layout.Group justify="center">
            <Spinner size={60} />
          </Layout.Group>
        </Layout.Stack>
      </Layout.Box>
    )
  }

  if (notFoundCarriersRecommendations) {
    return <NotFound />
  }

  return (
    <>
      <Text variant="heading-sm-bold">
        Here are the best matches for this lane
      </Text>
      {data.map((item: RecommendedCarrier) => {
        return (
          <RecommendedCarrierCard
            rfp={rfp}
            key={item.carrier_info.dot}
            recommendation={item}
            refetchCarrierInvites={refetchCarrierInvites}
          />
        )
      })}
    </>
  )
}

function RecommendedCarriers({
  onClose,
  isLoadingRecommendedCarriers,
  rfp,
  data,
  notFoundCarriersRecommendations,
  refetchCarrierInvites,
}: {
  readonly onClose: () => void
  readonly isLoadingRecommendedCarriers: boolean
  readonly rfp: RequestForProposal
  readonly data: any
  readonly notFoundCarriersRecommendations: boolean
  readonly refetchCarrierInvites: () => Promise<unknown>
}) {
  const scrollerRef = useRef<HTMLDivElement>(null)
  usePreventPageScrollOver(scrollerRef)

  return (
    <Container>
      <TitleContainer>
        <Text variant="heading-sm-bold">Find Carriers</Text>
        <CloseSidebarWrapper onClick={onClose}>
          <IconClose width={16} height={16} />
        </CloseSidebarWrapper>
      </TitleContainer>
      <InnerContainerScroll ref={scrollerRef}>
        <Layout.Stack space="xl">
          <BannerLarge
            icon={<IconTruckDryvan width={24} height={24} />}
            description="Check Loadsmart network of trusted carriers."
            scale="large"
            title="Need new carriers for your lane?"
            variant="success"
            dismissible={false}
          />
          <Content
            data={data}
            rfp={rfp}
            refetchCarrierInvites={refetchCarrierInvites}
            isLoadingRecommendedCarriers={isLoadingRecommendedCarriers}
            notFoundCarriersRecommendations={notFoundCarriersRecommendations}
          />
        </Layout.Stack>
      </InnerContainerScroll>
    </Container>
  )
}

export default RecommendedCarriers
