import { isEmpty } from 'lodash'

import type {
  FacilityContactV2,
  FacilityDetailsV2,
  FacilityHoursOfOperationEntry,
  FacilityNoteV2,
} from 'services/facilities'

function mapFacilityNotes(
  notes: FacilityDetails['notes']
): Array<FacilityNoteV2> {
  // @ts-expect-error the notes' UUID from v1 is a string
  return (
    notes?.map((note) => ({
      id: note.uuid,
      note: note.description,
    })) ?? []
  )
}

export function mapFacilityContactV1toV2(contact: Contact): FacilityContactV2 {
  return {
    uuid: contact.uuid,
    email: contact.email ?? null,
    name: contact.name ?? '',
    extension: contact.phone_number_extension,
    phone: contact.phone_number,
  }
}

function mapFacilityContacts(contacts: Contact[] = []): FacilityContactV2[] {
  return contacts.map(mapFacilityContactV1toV2)
}

// Map of day booleans to day_of_week strings
const DAYS_MAP: { [key: string]: DayOfWeek } = {
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  sunday: 'Sunday',
}

function mapFacilityHoursOfOperation(
  facility: FacilityDetails | Facility
): FacilityHoursOfOperationEntry[] {
  const hoursOfOperationEntries: FacilityHoursOfOperationEntry[] = []

  const operation = facility.hours_of_operations

  if (isEmpty(operation)) {
    return []
  }

  for (const day in DAYS_MAP) {
    if (operation[day as keyof FacilityOperation]) {
      const { closes, opens, mode } = operation
      const appointment_mode =
        mode.toLowerCase() as FacilityHoursOfOperationEntry['appointment_mode']

      hoursOfOperationEntries.push({
        uuid: `${day}-${opens}-${closes}-${mode}`,
        day_of_week: DAYS_MAP[day],
        opens,
        closes,
        appointment_mode,
      })
    }
  }

  return hoursOfOperationEntries
}

export function mapFacilityDetailsV1ToV2(
  facility: FacilityDetails | Facility | undefined | null
): FacilityDetailsV2 | undefined {
  if (isEmpty(facility)) {
    return undefined
  }

  return {
    address: facility.address,
    // address_details does not exists in v1
    address_details: '',
    city: facility.city,
    contacts: mapFacilityContacts(facility.contacts),
    country: facility.country,
    hours_of_operation: mapFacilityHoursOfOperation(facility),
    name: facility.company_name || facility.address,
    notes: mapFacilityNotes(facility.notes),
    state: facility.state,
    uuid: facility.uuid,
    warehouse_uuid: facility.warehouse_uuid ?? null,
    zipcode: facility.zipcode,
    accessorials: [],
    archived: false,
  }
}
