import type { Dispatch, SetStateAction } from 'react'

import { getRateOptionColumn, getVSColumn } from 'rfp/components/table/columns'
import type { Currency } from 'rfp/rfp.types'

export default function makePublishedColumns({
  bidOption,
  rateOption,
  optionsDict,
  availableRateOptions,
  setRadioOptionTwo,
  displayMultiCurrency,
  rfpCurrency,
}: {
  bidOption: string
  rateOption: string
  optionsDict: Record<string, string>
  availableRateOptions: {
    value: string
    label: string
  }[]
  setRadioOptionTwo: Dispatch<SetStateAction<string>>
  displayMultiCurrency: boolean
  rfpCurrency: Currency
}) {
  return [
    getVSColumn(bidOption, rateOption),
    getRateOptionColumn(
      optionsDict,
      rateOption,
      availableRateOptions,
      setRadioOptionTwo,
      displayMultiCurrency,
      rfpCurrency
    ),
  ]
}
