import { Layout, Text } from '@loadsmart/loadsmart-ui'
import { Button } from '@loadsmart/miranda-react'
import { useMemo, useState } from 'react'

import {
  ActionButton,
  Container,
} from 'components/LaneManagement/LanesTableV3/styles'
import { usePrimaryCarriers } from 'hooks/useQuery'
import { StyledCardBody } from 'rfp/components/header-card-v2/styles'
import { getTableSortDirection } from 'rfp/components/table/tableUtils'
import MultiCurrencyValue from 'rfp/rfp-details/components/MultiCurrencyValue'
import type { Currency, RequestForProposal } from 'rfp/rfp.types'
import { isMultiCurrency } from 'utils/currency'
import { numberFormatter } from 'utils/numbers'

import BaseTable from './BaseTable'
import EmptyState from './EmptyState'
import SelectedCarrierLanesTable from './SelectedCarrierLanesTable'
import {
  CloseButton,
  InformationBox,
  SelectedCarrierHeader,
  SeparatorLine,
} from './styles'

const LIMIT = 50

const defaultParams = {
  offset: 0,
  limit: LIMIT,
  sort: {
    column: 'name',
    direction: 'asc',
  },
}

type AccessorValue = string | number | undefined

interface PrimaryCarrier {
  carrier_scac?: string
  carrier_total_spent?: string | number
  carrier_converted_total_spent?: string | number
  carrier_entity: string
  carrier_id: string | number
  carrier_lanes: string | number
  carrier_lanes_percentage: string | number
  carrier_name: string
  carrier_volume: string | number
  carrier_volume_percentage: string | number
}

const NoCarriersEmptyState = ({
  changeTab,
}: {
  readonly changeTab: () => void
}) => {
  return (
    <EmptyState>
      <Layout.Stack space="s">
        <Text variant="heading-sm-bold" color="color-neutral-darkest">
          No Primary Carriers
        </Text>
        <Text variant="caption" color="color-neutral-darker">
          Here you will be able to see the primary carriers.
        </Text>
      </Layout.Stack>
      <Button
        variant="primary"
        onClick={() => {
          changeTab()
        }}
        size="small"
      >
        go to lanes in progress
      </Button>
    </EmptyState>
  )
}

export default function PrimaryCarriersTable({
  rfpId,
  onLaneRowClick,
  rfpState,
  rfpCurrency,
  rfpDistanceType,
  changeTab,
}: {
  readonly rfpId: string | number
  readonly rfpState: string
  readonly rfpCurrency: Currency
  readonly rfpDistanceType: RequestForProposal['distance_type']
  readonly onLaneRowClick: (lane: Lane) => void
  readonly changeTab: () => void
}) {
  const displayMultiCurrency = isMultiCurrency(rfpCurrency)
  const [queryParams, setQueryParams] = useState(defaultParams)
  const [selectedCarrier, setSelectedCarrier] = useState<PrimaryCarrier | null>(
    null
  )

  const {
    isLoading: isLoadingCarriers,
    isFetching: isFetchingCarriers,
    data,
  } = usePrimaryCarriers(rfpId.toString())

  const carriers = data?.carriers || []

  const isLoading = isLoadingCarriers || isFetchingCarriers

  const changeSorting = (column: string) => {
    setQueryParams({
      ...queryParams,
      sort: {
        column,
        direction: getTableSortDirection(queryParams.sort.direction),
      },
    })
  }

  const columns = useMemo(() => {
    return () => [
      {
        Header: 'CARRIER',
        id: 'carrier_name',
        className: 'text-left',
        accessor: ({ carrier_name }: { carrier_name: AccessorValue }) =>
          carrier_name,
      },
      {
        Header: 'TYPE',
        id: 'carrier_entity',
        className: 'text-left',
        accessor: ({ carrier_entity }: { carrier_entity: AccessorValue }) =>
          carrier_entity,
      },
      {
        Header: 'SCAC',
        id: 'carrier_scac',
        className: 'text-left',
        accessor: ({ carrier_scac }: { carrier_scac: AccessorValue }) =>
          carrier_scac || '-',
      },
      {
        Header: 'LANES',
        id: 'carrier_lanes',
        className: 'text-left',
        accessor: ({ carrier_lanes }: { carrier_lanes: AccessorValue }) =>
          carrier_lanes,
      },
      {
        Header: 'LANES %',
        id: 'carrier_lanes_percentage',
        className: 'text-left',
        accessor: ({
          carrier_lanes_percentage,
        }: {
          carrier_lanes_percentage: AccessorValue
        }) => carrier_lanes_percentage,
      },
      {
        Header: 'VOLUME',
        id: 'carrier_volume',
        className: 'text-left',
        accessor: ({ carrier_volume }: { carrier_volume: AccessorValue }) =>
          numberFormatter(carrier_volume),
      },
      {
        Header: 'VOLUME %',
        id: 'carrier_volume_percentage',
        className: 'text-left',
        accessor: ({
          carrier_volume_percentage,
        }: {
          carrier_volume_percentage: AccessorValue
        }) => carrier_volume_percentage,
      },
      {
        Header: 'TOTAL SPEND',
        id: 'carrier_total_spent',
        className: 'text-left',
        accessor: 'carrier_total_spent',
        Cell: function CellTotalSpent({ row }: { readonly row: any }) {
          const valueField = displayMultiCurrency
            ? 'carrier_converted_total_spent'
            : 'carrier_total_spent'

          return (
            <MultiCurrencyValue
              value={row.original[valueField]}
              nonConvertedValue={row.original['carrier_total_spent']}
              rfpCurrency={rfpCurrency}
              emptyValue="-"
            />
          )
        },
      },
      {
        Header: '',
        id: 'actions',
        className: 'flex flex-right',
        style: {
          justifyContent: 'flex-end',
          display: 'flex',
        },
        Cell: function CellActions({ row }: { readonly row: any }) {
          return (
            <ActionButton onClick={() => setSelectedCarrier(row.original)}>
              See Lanes
            </ActionButton>
          )
        },
      },
    ]
  }, [displayMultiCurrency, rfpCurrency])

  if (carriers?.length <= 0) {
    return <NoCarriersEmptyState changeTab={changeTab} />
  }

  const defaultCarriersValue = carriers.length > 0 ? carriers : []
  const multiCurrencyValue = displayMultiCurrency
    ? selectedCarrier?.carrier_converted_total_spent
    : selectedCarrier?.carrier_total_spent

  return (
    <Container style={{ paddingBottom: '40px' }}>
      {selectedCarrier ? (
        <>
          <SelectedCarrierHeader>
            <InformationBox>
              <StyledCardBody>
                <Layout.Stack space="l">
                  <Layout.Group space="xl">
                    <Layout.Stack space="2xs">
                      <Text variant="chips-sm" color="color-neutral">
                        carrier awarded
                      </Text>
                      <Text variant="body-bold" color="color-neutral-darker">
                        {selectedCarrier.carrier_name}
                      </Text>
                    </Layout.Stack>
                    <Layout.Stack space="2xs">
                      <Text variant="chips-sm" color="color-neutral">
                        type
                      </Text>
                      <Text variant="body-bold" color="color-neutral-darker">
                        {selectedCarrier.carrier_entity}
                      </Text>
                    </Layout.Stack>
                    <Layout.Stack space="2xs">
                      <SeparatorLine />
                    </Layout.Stack>
                    <Layout.Stack space="2xs">
                      <Text variant="chips-sm" color="color-neutral">
                        lanes
                      </Text>
                      <Text variant="body-bold" color="color-neutral-darker">
                        {selectedCarrier.carrier_lanes}
                      </Text>
                    </Layout.Stack>
                    <Layout.Stack space="2xs">
                      <Text variant="chips-sm" color="color-neutral">
                        % lanes
                      </Text>
                      <Text variant="body-bold" color="color-neutral-darker">
                        {selectedCarrier.carrier_lanes_percentage}
                      </Text>
                    </Layout.Stack>
                    <Layout.Stack space="2xs">
                      <Text variant="chips-sm" color="color-neutral">
                        volume
                      </Text>
                      <Layout.Group space="xs">
                        <Text variant="body-bold" color="color-neutral-darker">
                          {selectedCarrier.carrier_volume}
                        </Text>
                      </Layout.Group>
                    </Layout.Stack>
                    <Layout.Stack space="2xs">
                      <Text variant="chips-sm" color="color-neutral">
                        % volume
                      </Text>
                      <Layout.Group space="xs">
                        <Text variant="body-bold" color="color-neutral-darker">
                          {selectedCarrier.carrier_volume_percentage}
                        </Text>
                      </Layout.Group>
                    </Layout.Stack>
                    <Layout.Stack space="2xs">
                      <Text variant="chips-sm" color="color-neutral">
                        total spend
                      </Text>
                      <Layout.Group space="xs">
                        <Text variant="body-bold" color="color-neutral-darker">
                          <MultiCurrencyValue
                            value={multiCurrencyValue}
                            nonConvertedValue={
                              selectedCarrier.carrier_total_spent
                            }
                            rfpCurrency={rfpCurrency}
                            boldStyle={false}
                          />
                        </Text>
                      </Layout.Group>
                    </Layout.Stack>
                  </Layout.Group>
                </Layout.Stack>
              </StyledCardBody>
            </InformationBox>
            <CloseButton onClick={() => setSelectedCarrier(null)}>
              X CLOSE
            </CloseButton>
          </SelectedCarrierHeader>
          <Container style={{ marginTop: '40px' }}>
            <SelectedCarrierLanesTable
              rfpId={rfpId}
              rfpState={rfpState}
              rfpCurrency={rfpCurrency}
              rfpDistanceType={rfpDistanceType}
              onRowClick={onLaneRowClick}
              carrierId={selectedCarrier.carrier_id}
            />
          </Container>
        </>
      ) : (
        <BaseTable
          isLoading={isLoading}
          columns={columns}
          entries={defaultCarriersValue}
          sortable
          pagination={false}
          selectable={false}
          resultsCount={carriers.length || 0}
          changeSorting={changeSorting}
          sort={{
            column: 'lane_id',
            direction: 'asc',
          }}
        />
      )}
    </Container>
  )
}
