import { Checkbox, LoadingDots } from '@loadsmart/loadsmart-ui'
import { useEffect, useMemo, useState } from 'react'
import type { MouseEvent } from 'react'
import ReactPaginate from 'react-paginate'
import type { UseTableOptions } from 'react-table'
import { useTable, useSortBy } from 'react-table'
import styled from 'styled-components'

import { useSettings } from 'contexts/settings'
import { hideStyledProps } from 'utils/styles'

import {
  Wrapper,
  Table,
  THead,
  TBody,
  Th as BaseTh,
  Tr,
  Td,
  SelectAllActionWrapper,
} from './styles'
import type { SelectConfig } from './types'

export const Th = styled(BaseTh)`
  font-weight: 600;
`

type SortableHeaderProps = {
  direction: string
}

export const SortingIndicator = styled.div.withConfig(
  hideStyledProps(['direction'])
)<SortableHeaderProps>`
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  ${({ direction, theme }) => {
    const border = direction === 'desc' ? 'top' : 'bottom'
    return `border-${border}: 5px solid ${theme.colors.neutral};`
  }}
  margin-left: 7px;
  position: relative;
  top: -1px;
`

const LoadingState = ({
  selectable,
  length,
}: {
  readonly selectable: boolean
  readonly length: number
}) => {
  return (
    <Tr>
      <Td
        data-testid="table-loading"
        colSpan={selectable ? length + 1 : length}
        className="text-center"
      >
        <LoadingDots />
      </Td>
    </Tr>
  )
}

export default function TableV3({
  columns,
  entries,
  initialState = {},
  sortable = true,
  pagination = false,
  paginationHandler,
  pageSize = 10,
  resultsCount = 0,
  isLoading = false,
  selectable = false,
  bordered = true,
  selectConfig,
  onRowClick,
  notStripped,
  changeSorting,
  sort,
  'data-testid': testId,
  unsortableColumns = ['actions', 'vs', 'state'],
}: {
  readonly columns: any
  readonly entries: Array<any>
  readonly initialState?: any
  readonly sortable?: boolean
  readonly pagination?: boolean
  readonly paginationHandler?: (page: number) => void
  readonly pageSize?: number
  readonly resultsCount?: number
  readonly isLoading?: boolean
  readonly selectable?: boolean
  readonly bordered?: boolean
  readonly selectConfig?: SelectConfig
  readonly onRowClick?: (arg0: MouseEvent, arg1: any) => void
  readonly notStripped?: boolean
  readonly changeSorting?: (arg0: string) => void
  readonly sort?: { column: string; direction: string }
  readonly 'data-testid'?: string
  readonly unsortableColumns?: Array<string>
}) {
  const [pageCount, setPageCount] = useState<number>(resultsCount)
  const [page, setPage] = useState(0)
  const [beginning, setBeginning] = useState(0)
  const [allSelected, setAllSelected] = useState<boolean>(false)
  const [selected, setSelected] = useState<any[]>([])
  const {
    values: [loadsmartDOT],
  } = useSettings(['settings.LOADSMART_DOT'])

  const handlePageChange = (selectedItem: { selected: number }) => {
    const newBeginning =
      selectedItem.selected === 0 ? 0 : selectedItem.selected * pageSize
    setBeginning(newBeginning)
    setPage(selectedItem.selected)
    if (paginationHandler) {
      paginationHandler(selectedItem.selected)
    }
  }

  const tableColumns = useMemo(() => columns(), [columns])

  const {
    headerGroups,
    allColumns,
    rows,
    getTableProps,
    getTableBodyProps,
    prepareRow,
  } = useTable<any[]>(
    {
      columns: tableColumns,
      data: entries,
      initialState,
      disableSortRemove: true,
      autoResetSortBy: false,
      disableSortBy: !sortable,
    } as UseTableOptions<any[]>,
    useSortBy
  )

  const handleToggleSelect = (rowAccessor: any) => {
    const currentSelected = [...selected]
    const exists = currentSelected.indexOf(rowAccessor)
    if (exists > -1) {
      currentSelected.splice(exists, 1)
    } else {
      currentSelected.push(rowAccessor)
    }
    setSelected(currentSelected)
    setAllSelected(currentSelected.length === rows.length)
  }

  const handleToggleSelectAll = () => {
    if (selected.length === rows.length) {
      setSelected([])
      setAllSelected(false)
    } else if (selectConfig) {
      const rowAccessors = rows.map(
        (row) =>
          row.original[selectConfig.accessor as keyof typeof row.original]
      )
      const loadsmartIndex = rowAccessors.indexOf(4362)
      if (loadsmartIndex > -1 && selectConfig.disableForLoadsmart) {
        rowAccessors.splice(loadsmartIndex, 1)
      }
      setSelected(rowAccessors)
      setAllSelected(true)
    }
  }

  const displayedRows =
    pagination && !paginationHandler
      ? rows.slice(beginning, beginning + pageSize)
      : rows

  useEffect(() => {
    setAllSelected(false)
    setSelected([])
  }, [entries])

  useEffect(() => {
    if (paginationHandler) {
      setPageCount(Math.ceil(resultsCount / pageSize))
    } else {
      setPageCount(Math.ceil(entries.length / pageSize))
    }
  }, [entries, pageSize, paginationHandler, resultsCount])

  return (
    <Wrapper data-testid={testId ?? 'table'}>
      <Table {...getTableProps()} bordered={bordered}>
        <THead>
          {selected.length > 0 && (
            <Tr
              {...headerGroups[0].getHeaderGroupProps()}
              style={{
                position: 'absolute',
                backgroundColor: 'white',
                width: '100%',
                zIndex: 1,
              }}
            >
              <Th colSpan={allColumns.length + 1} className="text-left">
                <SelectAllActionWrapper>
                  <Checkbox
                    className="text-left"
                    checked={allSelected}
                    onChange={handleToggleSelectAll}
                    onClick={(e) => e.stopPropagation()}
                  />
                  {selectConfig?.actions.map((Action, key) => (
                    <Action key={key} selected={selected} />
                  ))}
                </SelectAllActionWrapper>
              </Th>
            </Tr>
          )}
          <>
            {headerGroups.map((headerGroup) => {
              const { key: headKey, ...headGroupProps } =
                headerGroup.getHeaderGroupProps()
              return (
                <Tr key={headKey} {...headGroupProps}>
                  {selectable && (
                    <Th
                      colspan={allColumns.length}
                      className="text-left align-middle"
                      style={{ width: '50px', display: 'inline-flex' }}
                    >
                      <Checkbox
                        className="flex"
                        checked={allSelected}
                        onChange={handleToggleSelectAll}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </Th>
                  )}
                  {headerGroup.headers.map((column: any) => {
                    const { key, ...headerProps } = column.getHeaderProps(
                      sortable ? column.getSortByToggleProps() : undefined
                    )
                    return (
                      <Th
                        key={key}
                        {...headerProps}
                        className={column.className}
                        onClick={() =>
                          changeSorting &&
                          !unsortableColumns.includes(column.id) && //Since we aren't allowing sorting by these columns (at least for now), we need this validation to skip sorting when needed.
                          changeSorting(column.id)
                        }
                      >
                        {column.render('Header')}
                        {sort && sort.column === column.id ? (
                          <SortingIndicator direction={sort.direction} />
                        ) : null}
                      </Th>
                    )
                  })}
                </Tr>
              )
            })}
          </>
        </THead>
        <TBody {...getTableBodyProps()}>
          {isLoading ? (
            <LoadingState selectable={selectable} length={allColumns.length} />
          ) : (
            displayedRows.map((row) => {
              prepareRow(row)
              const { key, ...rowProps } = row.getRowProps()
              const rowAccessor = selectConfig
                ? row.original[
                    selectConfig.accessor as keyof typeof row.original
                  ]
                : null
              const dot = row.original['dot' as keyof typeof row.original]
              const isLoadsmartCarrier = dot === loadsmartDOT
              const disabled =
                selectConfig?.disableForLoadsmart && isLoadsmartCarrier
              const isRowSelected = selected.includes(rowAccessor)
              const onClick = (event: MouseEvent) => onRowClick?.(event, row)
              return (
                <Tr
                  key={key}
                  {...rowProps}
                  isSelected={isRowSelected}
                  onClick={(event: MouseEvent) => onClick(event)}
                  notStripped={notStripped}
                >
                  {selectable && selectConfig && (
                    <Td
                      className="text-left"
                      style={{ width: '50px' }}
                      role="cell"
                      onClick={(e) => {
                        if (!disabled) {
                          handleToggleSelect(rowAccessor)
                          e.stopPropagation()
                        }
                      }}
                    >
                      <Checkbox
                        value={rowAccessor}
                        checked={isRowSelected}
                        disabled={disabled}
                        onChange={() => {
                          handleToggleSelect(rowAccessor)
                        }}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </Td>
                  )}
                  {row.cells.map((cell: any) => {
                    const { key: cellKey, ...cellProps } = cell.getCellProps()
                    return (
                      <Td
                        key={cellKey}
                        {...cellProps}
                        className={cell.column.className}
                      >
                        {cell.render('Cell')}
                      </Td>
                    )
                  })}
                </Tr>
              )
            })
          )}
        </TBody>
      </Table>
      {pagination && pageCount > 1 && (
        <ReactPaginate
          data-testid="pagination"
          previousLabel="Previous"
          nextLabel="Next"
          breakLabel="..."
          breakClassName="break-me"
          pageCount={pageCount}
          forcePage={page}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName="pagination"
          activeClassName="active"
        />
      )}
    </Wrapper>
  )
}
