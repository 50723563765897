export const BidsCell = ({
  proposalsCount,
}: {
  readonly proposalsCount: number
}) => {
  if (proposalsCount >= 1) {
    return proposalsCount
  }

  return <>-</>
}
