import type { ListFulfillment } from 'fulfillments/domain/Fulfillment'
import { usePendingFulfillmentsAsCustomer } from 'fulfillments/hooks/usePendingFulfillments'
import type { ReactNode } from 'react'
import { createContext, useContext, useEffect } from 'react'
import { toast } from 'react-toastify'

import { usePlanFulfillmentsQPState } from './hooks/usePlanFulfillmentsQPState'

export interface PlanFulfillmentsContext {
  isLoadingPendingFulfillments?: boolean
  pendingFulfillments?: ListFulfillment[]
  selectedFulfillmentsUUIDs: string[]
}

export const DEFAULT_CONTEXT_STATE: PlanFulfillmentsContext = {
  selectedFulfillmentsUUIDs: [],
}

export const Context = createContext<PlanFulfillmentsContext>(
  DEFAULT_CONTEXT_STATE
)

export function usePlanFulfillmentsContextManager() {
  const { data, isLoading, isError } = usePendingFulfillmentsAsCustomer()
  const { selectedFulfillmentsUUIDs } = usePlanFulfillmentsQPState()

  const pendingFulfillments = data?.results
  const isLoadingPendingFulfillments = isLoading

  useEffect(() => {
    if (isError) {
      toast.error('Failed to load pending fulfillments')
    }
  }, [isError])

  return {
    isLoadingPendingFulfillments,
    pendingFulfillments,
    selectedFulfillmentsUUIDs,
  }
}

export function PlanFulfillmentsContextProvider({
  children,
}: Readonly<{ children: ReactNode }>) {
  const context = usePlanFulfillmentsContextManager()

  return <Context.Provider value={context}>{children}</Context.Provider>
}

export function usePlanFulfillmentsContext() {
  const context = useContext(Context)

  if (context === undefined) {
    throw new Error(
      'usePlanFulfillmentsContext must be used within a PlanFulfillmentsContextProvider'
    )
  }

  return context
}
