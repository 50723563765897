import styled from 'styled-components'

import { theme } from 'styles/theme'

export const HubspotFormContainer = styled.div`
  .hs-form-field {
    align-items: baseline;
    display: flex;
    flex-flow: column;
    margin-top: 12px;
  }

  .input {
    background: ${theme.colors.white};
    border-color: ${theme.colors.neutralDarker};
    border-radius: 0.25rem;
    border-style: solid;
    border-width: 1px;
    color: ${theme.colors.neutralDarker};
    display: inline-flex;
    flex-flow: row nowrap;
    font-family: Manrope, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    height: 2.25rem;
    line-height: 140%;
    margin-top: 8px;
    min-width: 0px;
    outline: none;
    text-transform: none;
    transition-delay: 0ms;
    transition-duration: 450ms;
    transition-property: background, border-color, box-shadow, color, fill,
      left, stroke, transform, opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    width: 100%;
  }

  .input:focus,
  .input:focus-within {
    background: ${theme.colors.white};
    border-color: ${theme.colors.textAccent};
    box-shadow: ${theme.colors.mediumGreen} 0px 0px 4px 0px;
    outline: none;
  }

  .input:hover {
    background: ${theme.colors.white};
    border-color: ${theme.colors.textAccent};
  }

  .hs-form-required {
    color: ${theme.colors.dangerDark};
    font-size: 0.875rem;
    top: 0px;
  }

  input[type='text']:focus {
    outline: none;
  }

  input[type='text'],
  input[type='email'],
  input[type='tel'],
  input[type='url'],
  input[type='number'] {
    -webkit-font-smoothing: antialiased;
    background: transparent;
    border-color: initial;
    border-image: initial;
    border-style: none;
    border-width: initial;
    color: ${theme.colors.neutralDarkest};
    flex: 1 1 0%;
    font-family: Manrope, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 140%;
    margin: 0px 0.5rem;
    min-width: 0px;
    outline: none;
    padding: 0.5rem 0px;
    text-transform: none;
    transition-delay: 0ms;
    transition-duration: 450ms;
    transition-property: background, border-color, box-shadow, color, fill,
      left, stroke, transform, opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    width: 100%;
  }

  input[type='submit'] {
    align-items: center;
    background: ${theme.colors.mediumGreen};
    border-color: ${theme.colors.mediumGreen};
    border-radius: 0.25rem;
    border-style: solid;
    border-width: 1px;
    color: ${theme.colors.white};
    cursor: pointer;
    display: inline-flex;
    flex-flow: row nowrap;
    font-family: Manrope, sans-serif;
    font-size: 0.875rem;
    font-weight: 700;
    height: 2.25rem;
    justify-content: center;
    line-height: 100%;
    margin-bottom: 8px;
    margin-top: 16px;
    outline: none;
    padding: 0 8px;
    text-align: center;
    text-transform: uppercase;
    transition-delay: 0ms;
    transition-duration: 450ms;
    transition-property: background, border-color, box-shadow, color, fill,
      left, stroke, transform, opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    width: 100%;
  }

  input[type='submit']:hover {
    background: ${theme.colors.textAccent};
    border-color: ${theme.colors.textAccent};
    color: ${theme.colors.white};
  }

  label {
    color: ${theme.colors.neutralDarkest};
    display: inline-block;
    font-family: Manrope, sans-serif;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 140%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: none;
    white-space: nowrap;
  }

  .hs-error-msgs {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      .hs-error-msg {
        color: ${theme.colors.danger};
        margin: 0;
      }
      .hs-main-font-element {
        color: ${theme.colors.danger};
        margin: 1rem 0;
      }
    }
  }
`
