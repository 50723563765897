import { Layout, Tooltip, TooltipPosition } from '@loadsmart/loadsmart-ui'

import incumbentIcon from 'assets/icons/incumbent.svg'
import AwardTooltip from 'components/LaneManagement/LanesTableV3/AwardTooltip'
import {
  PriceChangeIndicator,
  StyledIncumbentTooltip,
} from 'components/LaneManagement/LanesTableV3/styles'
import type { Currency } from 'rfp/rfp.types'
import { theme } from 'styles/theme'
import { numberFormatter } from 'utils/numbers'

export const AllocationCell = ({
  value,
}: {
  readonly value?: string | number
}) => {
  if (value) {
    return (
      <b
        style={{
          whiteSpace: 'nowrap',
          ...(Number(value) < 100 && { color: theme.colors.dangerDark }),
        }}
      >
        {numberFormatter(value)}%
      </b>
    )
  }
  return <b>0%</b>
}

export const PrimaryCell = ({
  value,
  rfpCurrency,
}: {
  readonly value: any
  readonly rfpCurrency: Currency
}) => {
  if (!value || value?.length === 0) {
    return (
      <Layout.Group
        justify="flex-start"
        align="center"
        space="s"
        style={{ flexWrap: 'nowrap', whiteSpace: 'nowrap' }}
      >
        No Primary
      </Layout.Group>
    )
  }

  if (value.length === 1) {
    return (
      <Layout.Group
        justify="flex-start"
        align="center"
        space="s"
        style={{ flexWrap: 'nowrap', whiteSpace: 'nowrap' }}
      >
        {value[0].is_incumbent && (
          <StyledIncumbentTooltip>
            <Tooltip message="Incumbent" position={TooltipPosition.Bottom}>
              <img src={incumbentIcon} alt="incumbent" />
            </Tooltip>
          </StyledIncumbentTooltip>
        )}
        {value[0].name}
      </Layout.Group>
    )
  }

  return (
    <Layout.Group
      justify="flex-start"
      align="center"
      space="s"
      style={{ flexWrap: 'nowrap', whiteSpace: 'nowrap' }}
    >
      Split Primary
      <AwardTooltip carriers={value} rfpCurrency={rfpCurrency} />
    </Layout.Group>
  )
}

export type VersusCellProps = {
  readonly row: any
  readonly rateOption: string
}

export const VersusCell = ({ row, rateOption }: VersusCellProps) => {
  const awardRate = row.original.awarded_rate
  const versusRate = row.original[rateOption]
  const diff = awardRate - versusRate

  if (awardRate === null || versusRate === null) {
    return <>- %</>
  }

  if (diff === 0) {
    return <>0%</>
  }

  const percentage =
    100 -
    (Math.min(awardRate, versusRate) * 100) / Math.max(awardRate, versusRate)

  return (
    <>
      <PriceChangeIndicator direction={diff < 0 ? 'positive' : 'negative'} />
      {percentage.toFixed(1)}%
    </>
  )
}
