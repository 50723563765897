import styled from 'styled-components'

import { Button, ButtonGroup, LabelP } from '_shared_/components/form/styles'

export const FormActionsWrapper = styled(ButtonGroup)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const InviteBar = styled.div`
  ${LabelP} {
  }
`

export const RemoveButton = styled(Button)`
  padding-right: 0;
`

export const FindNewCarriers = styled.div`
  align-items: center;
  color: ${({ theme: loadsmartTheme }) => loadsmartTheme.colors.borderLight};
  display: grid;
  grid-gap: 5px 20px;
  grid-template-columns: 1fr;
  text-align: center;
`

export const AliceSearchSubLabel = styled.p`
  padding: 2px;
`
