import { IconCog } from '@loadsmart/icons'
import {
  Field,
  Text,
  Layout,
  TextField,
  Checkbox,
  SpinnerWheel,
  Button,
  Card,
} from '@loadsmart/miranda-react'
import { toCapitalizeFirstLetter } from '@loadsmart/utils-string'
import { Controller } from 'react-hook-form'

import type { AccessorialFormField } from '../types'
import { RFP_ACCESSORIALS_SETTINGS_URL } from './constants'

export type Field = {
  id?: number
  uuid?: string
  label: string
  value: string
  isChecked: boolean
}

type Identifier = 'id' | 'uuid'

function AccessorialField({
  disabled,
  fields,
  onChange,
  identifier = 'id',
  fieldMaxLength = 50,
}: {
  readonly disabled: boolean
  readonly fields: AccessorialFormField[]
  readonly onChange: (value: Field[]) => void
  readonly identifier: Identifier
  readonly fieldMaxLength?: number
}) {
  const handleClick = (selectedField: Field) => () => {
    const newList = fields.map((field) => {
      if (selectedField[identifier] === field[identifier]) {
        return {
          ...field,
          isChecked: !selectedField.isChecked,
        }
      }

      return field
    })

    onChange(newList)
  }

  const handleChange =
    (selectedIdentifier: Field[Identifier]) => (event: Event | null) => {
      if (!event || !event.target) {
        return
      }

      const value = (event.target as HTMLInputElement).value

      const newList = fields.map((field) => {
        if (selectedIdentifier === field[identifier]) {
          return {
            ...field,
            value,
          }
        }

        return field
      })
      onChange(newList)
    }

  return (
    <Layout.Grid minColumnWidth="35%">
      {fields.map((field) => {
        return (
          <Layout.Stack key={field.id}>
            <Layout.Group justify="space-between">
              <Checkbox
                disabled={disabled}
                checked={field.isChecked}
                onChange={handleClick(field)}
              >
                <Text>{toCapitalizeFirstLetter(field.label)}</Text>
              </Checkbox>
              <Text
                color={
                  field.value?.length === fieldMaxLength
                    ? 'color-danger-60'
                    : undefined
                }
              >{`${field.value?.length ?? 0}/${fieldMaxLength}`}</Text>
            </Layout.Group>
            <TextField
              input-mode="text"
              placeholder="Add notes"
              id={`field-${field[identifier]}`}
              data-testid={`field-${field[identifier]}`}
              name={`field-${field[identifier]}`}
              disabled={disabled || !field.isChecked}
              value={field.value}
              onInput={handleChange(field[identifier])}
              max-length={fieldMaxLength}
            />
          </Layout.Stack>
        )
      })}
      <Button
        variant="tertiary"
        leading={<IconCog height={12} width={12} title={null} />}
        href={RFP_ACCESSORIALS_SETTINGS_URL}
        target="_blank"
        style={{ width: 'fit-content' }}
      >
        Manage Accessorials
      </Button>
    </Layout.Grid>
  )
}

export default function RFPAccessorialsSection({
  shouldRender,
  isAccessorialLoading,
  isFieldDisabled,
  priceItemTypesEnabled,
  control,
}: {
  readonly shouldRender: boolean
  readonly isAccessorialLoading: boolean
  readonly isFieldDisabled: boolean
  readonly priceItemTypesEnabled: boolean
  readonly control: any
}) {
  if (!shouldRender) {
    return null
  }

  return (
    <Card collapsible>
      <Card.Title>Accessorials</Card.Title>
      <Card.Divider />
      <Card.Body>
        {isAccessorialLoading ? (
          <Layout.Group align="center" justify="center">
            <SpinnerWheel size="32px" />
          </Layout.Group>
        ) : (
          <Field style={{ flex: '1' }}>
            <Layout.Stack gap="spacing-5">
              <Text variant="body-sm">
                Specify any extra services or requirements for your shipments.
                You can manage your list of accessorials by clicking on
                &apos;Manage Accessorials&apos;.
              </Text>
              <Controller
                name="accessorials"
                control={control}
                render={({ field }) => (
                  <AccessorialField
                    disabled={isFieldDisabled}
                    fields={field.value as AccessorialFormField[]}
                    onChange={field.onChange}
                    identifier={priceItemTypesEnabled ? 'uuid' : 'id'}
                  />
                )}
              />
            </Layout.Stack>
          </Field>
        )}
      </Card.Body>
    </Card>
  )
}
