import type { AxiosError } from 'axios'
import { useEffect } from 'react'
import type { UseQueryOptions } from 'react-query'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'

import type { SettingsProviderValue } from 'contexts/settings'
import type { FreightWavesData } from 'freightwaves/types'
import type { TenderRejectionPredictionData } from 'quotes/Quote'
import type { QueryParams } from 'rfp/components/table/laneParams'
import type { RequestForProposalOverview } from 'rfp/rfp.types'
import { listAccessorials } from 'services/accessorials'
import { fetchUserData } from 'services/auth'
import { fetchCarrierInvites } from 'services/carrierInvite'
import {
  carrierDetails,
  carrierAccountDetails,
  downloadSample,
  fetchCarriersEntities,
  fetchCarriersOperationRegions,
  fetchCarriersTags,
  fetchCarriersFiltered,
  fetchCarrierEmployees,
  listAttachments,
  getAttachmentDownloadURL,
} from 'services/carriers'
import {
  getSubscription,
  list as listFreightWavesLanes,
} from 'services/freightWaves'
import {
  fetchAwardScenarios,
  fetchAwardScenariosStats,
  fetchLaneBidStatusOptions,
  fetchLaneEquipmentTypeOptions,
  fetchPrimaryCarriers,
  fetchRankScenarios,
  fetchRankScenariosStats,
  listPaginated as listLanesPaginated,
  listPrimaryCarriersLanes,
} from 'services/lane'
import {
  fetchLaneManagementAwardSummary,
  fetchLaneManagementCarrierAwards,
  fetchLaneManagementLaneStats,
  fetchLaneManagementProposals,
  fetchLastRFPs,
  getLaneManagement,
} from 'services/laneManagement'
import { list as listLaneProposals } from 'services/laneProposal'
import { priceItemTypes } from 'services/priceItemTypes'
import type { PriceItemTypesOptions } from 'services/priceItemTypes'
import { get as getFrontendSettings } from 'services/settings'
import type { ShipperData, ShipperFavoriteAcsParams } from 'services/shipper'
import {
  getShipperCreditLine,
  getShipperCreditCards,
  get as fetchShipperInfo,
  getShipperRfpQuota,
  getCarrierInvitePerRfpQuota,
  getShipperQuotesQuota,
  getBillingInfo,
  getShipperLocations,
  getShipperLocationsSettings,
  getShipperFavoriteAccessorials,
} from 'services/shipper'
import { get as fetchShipperSettings } from 'services/shipperSettings'
import { fetchTenderRejectionPredictions } from 'services/spotQuote'
import type { LanePrimaryAwardStatus } from 'services/types'
import {
  getShipmentLocations,
  getShipmentPOD,
  getShipmentMergedPOD,
  getShipmentPODs,
  getBOL,
} from 'shipments/shipments.services'
import type {
  ShipmentLocations,
  ShipmentPODsResponseData,
} from 'shipments/shipments.services'
import { downloadData } from 'utils/download'

import { getRateconPDF } from '../screens/Shipper/Shipments/Details/services/shipment'

export const useUserData = ({ enabled }: { enabled: boolean }) => {
  return useQuery({
    queryKey: ['userData'],
    queryFn: ({ signal }) => fetchUserData({ signal }),
    enabled,
    staleTime: Infinity,
  })
}

export const useShipmentsShipperSettings = (
  options?: Omit<
    UseQueryOptions<ShipperSettings, Error, ShipperSettings, string[]>,
    'queryKey' | 'queryFn'
  >
) => {
  return useQuery({
    ...options,
    queryKey: ['shipmentsShipperSettings'],
    queryFn: ({ signal }) => fetchShipperSettings({ signal }),
  })
}

export const useShipperSettings = (
  options?: Omit<
    UseQueryOptions<ShipperSettings, Error, ShipperSettings, string[]>,
    'queryKey' | 'queryFn'
  >
) => {
  return useQuery({
    refetchInterval: 1000 * 60 * 2, // 2 Minutes
    refetchIntervalInBackground: true,
    ...options,
    queryKey: ['shipperSettings'],
    queryFn: ({ signal }) => fetchShipperSettings({ signal }),
  })
}

export const useLaneProposalsRetrieve = (
  laneId: number,
  options?: Omit<UseQueryOptions<LaneProposal[]>, 'queryKey' | 'queryFn'>
) => {
  return useQuery<LaneProposal[]>({
    ...options,
    queryKey: ['retrieveLaneProposal', laneId],
    queryFn: ({ signal }) => listLaneProposals(laneId, { signal }),
  })
}

export const useSettingsRetrieve = (
  options?: Omit<
    UseQueryOptions<Omit<SettingsProviderValue, 'isLoading'>>,
    'queryKey' | 'queryFn'
  >
) => {
  return useQuery<Omit<SettingsProviderValue, 'isLoading'>>({
    ...options,
    queryKey: ['retrieveSettings'],
    queryFn: ({ signal }) => getFrontendSettings({ signal }),
  })
}

type UseCarrierFilterOptions = {
  tagFiltersQuery: string
  query: string
  limit: string
  offset: string
  sort: { column: string; direction: string }
  filter: string
  rfpId: string | number | null
}

export const useCarriersFilter = (
  fetchOptions: UseCarrierFilterOptions,
  options?: Omit<UseQueryOptions<PaginatedResult>, 'queryKey' | 'queryFn'>
) => {
  return useQuery<PaginatedResult>({
    ...options,
    queryKey: ['carriersFilter', fetchOptions],
    queryFn: ({ signal }) => fetchCarriersFiltered(fetchOptions, { signal }),
  })
}

export const useCarrierInvites = (
  rfpId: string | number,
  options?: Omit<
    UseQueryOptions<Array<string | number>>,
    'queryKey' | 'queryFn'
  >
) => {
  return useQuery<Array<string | number>>({
    ...options,
    queryKey: ['carrierInvites', rfpId],
    queryFn: ({ signal }) => fetchCarrierInvites(rfpId, { signal }),
  })
}

export const useDownloadCarrierTemplate = () => {
  const query = useQuery({
    queryKey: ['carrierTemplate'],
    queryFn: downloadSample,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    enabled: false,
  })

  useEffect(() => {
    const carrierTemplate = query.data

    if (!carrierTemplate) {
      return
    }

    downloadData(
      carrierTemplate.data,
      'Carriers Template.csv',
      carrierTemplate.type
    )

    toast.success('Template downloaded successfully')
  }, [query.data])

  return query
}

export const useCarrierDetails = (
  carrierId?: string | number,
  options?: UseQueryOptions<Carrier, Error, Carrier>
) => {
  return useQuery({
    enabled: !!carrierId,
    queryKey: ['carrierDetails', carrierId],
    queryFn: ({ signal }) => carrierDetails(carrierId!, { signal }),
    refetchOnWindowFocus: false,
    ...options,
  })
}

export const useCarrierAccountDetails = () => {
  return useQuery({
    queryKey: ['carrierAccountDetails'],
    queryFn: ({ signal }) => carrierAccountDetails({ signal }),
  })
}

export const useLaneManagement = (
  status: string,
  limit: string | number,
  offset: number | string,
  query: string
) => {
  return useQuery<PaginatedResult>({
    queryKey: ['laneManagementPaginated', status, limit, offset, query],
    queryFn: ({ signal }) =>
      getLaneManagement(status, limit, offset, query, { signal }),
  })
}

export const useLastRFPs = (groupLaneIds: number[]) => {
  const { data, isLoading } = useQuery<RequestForProposalOverview[]>({
    queryKey: ['getLastRFPs', groupLaneIds],
    queryFn: ({ signal }) => fetchLastRFPs(groupLaneIds, { signal }),
  })
  return { data, isLoading }
}

export const useShipmentLocations = (
  shipmentUUID: string,
  offset?: number,
  limit?: number
) => {
  return useQuery<ShipmentLocations>({
    queryKey: ['retrieveShipmentLocations', shipmentUUID, offset, limit],
    queryFn: ({ signal }) => {
      return getShipmentLocations(shipmentUUID, offset, limit, { signal })
    },
    refetchInterval: false,
    refetchOnWindowFocus: false,
  })
}

export const useShipperCreditLine = () => {
  return useQuery({
    queryKey: ['retrieveShipperCreditLine'],
    queryFn: ({ signal }) => getShipperCreditLine({ signal }),
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    retry: false,
  })
}

export const useShipperCreditCards = () => {
  return useQuery({
    placeholderData: [],
    queryKey: ['getShipperCreditCards'],
    queryFn: ({ signal }) => getShipperCreditCards({ signal }),
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    retry: false,
  })
}

export const useShipperRfpQuota = (uuid: string) => {
  return useQuery({
    queryKey: ['getShipperRfpQuota', uuid],
    queryFn: ({ signal }) => getShipperRfpQuota(uuid, { signal }),
    enabled: !!uuid,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    retry: false,
  })
}

export const useCarrierInvitePerRfpQuota = (uuid: string) => {
  return useQuery({
    queryKey: ['getCarrierInvitePerRfpQuota', uuid],
    queryFn: ({ signal }) => getCarrierInvitePerRfpQuota(uuid, { signal }),
    enabled: !!uuid,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    retry: false,
  })
}

interface UseAccessorialsOptions {
  enabled?: boolean
  refetchOnWindowFocus?: boolean
}

export const useAccessorials = (
  mode?: TransportationModeCode | TransportationModeCode[],
  opts?: UseAccessorialsOptions
) => {
  const { data, isLoading } = useQuery<Accessorial[]>({
    ...opts,
    queryKey: ['listAccessorials', mode],
    queryFn: ({ signal }) => listAccessorials(mode, { signal }),
  })

  return { data, isLoading }
}

type PriceItemResponseData = PriceItemType[]

export const usePriceItemTypes = <TData = PriceItemResponseData>(
  options?: PriceItemTypesOptions,
  queryOptions?: UseQueryOptions<PriceItemResponseData, unknown, TData>
) => {
  return useQuery<PriceItemResponseData, unknown, TData>({
    refetchOnWindowFocus: false,
    ...queryOptions,
    queryKey: ['priceItemTypes', options],
    queryFn: ({ signal }) => priceItemTypes(options, { signal }),
  })
}

export const useLaneManagementAwardSummary = (groupLaneIds: number[]) => {
  const { data, isLoading } = useQuery<{
    rfps: RequestForProposalOverview[]
    total_volume: number
  }>({
    queryKey: ['getLaneManagementAwardSummary', groupLaneIds],
    queryFn: ({ signal }) => {
      return fetchLaneManagementAwardSummary(groupLaneIds, { signal })
    },
  })

  return { data, isLoading }
}

export const useLaneManagementCarrierAwards = (groupLaneIds: number[]) => {
  const { data, isLoading } = useQuery<RequestForProposalOverview[]>({
    queryKey: ['getLaneManagementCarrierAwards', groupLaneIds],
    queryFn: ({ signal }) =>
      fetchLaneManagementCarrierAwards(groupLaneIds, { signal }),
  })
  return { data, isLoading }
}

export const useLaneManagementProposals = (groupLaneIds: number[]) => {
  const { data, isLoading } = useQuery<RequestForProposalOverview[]>({
    queryKey: ['getLaneManagementProposals', groupLaneIds],
    queryFn: ({ signal }) =>
      fetchLaneManagementProposals(groupLaneIds, { signal }),
  })
  return { data, isLoading }
}

export const useLaneManagementLaneStats = (groupLaneIds: number[]) => {
  const { data, isLoading } = useQuery<LaneManagementBenchmark>({
    queryKey: ['getLaneManagementLaneStats', groupLaneIds],
    queryFn: ({ signal }) => {
      return fetchLaneManagementLaneStats(groupLaneIds, { signal })
    },
  })

  return { data, isLoading }
}

type UseAllocatedLaneOptions = {
  rfpId: string | number
  limit: string | number
  offset: number | string
  sort: { column: string; direction: string }
  primaryAwardStatus: LanePrimaryAwardStatus
  query?: string
  filters?: QueryParams['filters']
}

export const useAllocatedLanes = (
  fetchOptions: UseAllocatedLaneOptions,
  options?: UseQueryOptions<PaginatedResult>
) => {
  return useQuery<PaginatedResult>({
    ...options,
    queryKey: ['allocatedLanes', fetchOptions],
    queryFn: ({ signal }) => {
      return listLanesPaginated(
        {
          ...fetchOptions,
          isAllocated: true,
        },
        { signal }
      )
    },
  })
}

export const useRankedLanes = (
  rfpId: string | number,
  limit: string | number,
  offset: number | string,
  sort: { column: string; direction: string },
  options?: Omit<UseQueryOptions<PaginatedResult>, 'queryKey' | 'queryFn'>,
  query?: string
) => {
  return useQuery<PaginatedResult>({
    ...options,
    // We are using the same querykey as regular award scenarios
    // just to reuse the same Websocket and RoutingGuide update code
    queryKey: ['allocatedLanes', rfpId, offset, sort, false, query, limit],
    queryFn: ({ signal }) => {
      return listLanesPaginated(
        {
          rfpId,
          limit,
          offset,
          sort,
          isRanked: true,
          query,
        },
        { signal }
      )
    },
  })
}

export const useFilteredRankedLanes = (
  rfpId: string | number,
  limit: string | number,
  offset: number | string,
  sort: { column: string; direction: string },
  rankedStatus: boolean | undefined,
  options?: Omit<UseQueryOptions<PaginatedResult>, 'queryKey' | 'queryFn'>,
  query?: string
) => {
  return useQuery<PaginatedResult>({
    ...options,
    queryKey: [
      'filteredRankedLanes',
      rfpId,
      offset,
      sort,
      rankedStatus,
      query,
      limit,
    ],
    queryFn: ({ signal }) => {
      return listLanesPaginated(
        {
          rfpId,
          limit,
          offset,
          sort,
          isRanked: rankedStatus,
          query,
        },
        { signal }
      )
    },
  })
}

export const useUnrankedLanes = (
  rfpId: string | number,
  limit: string | number,
  offset: number | string,
  sort: { column: string; direction: string },
  options?: Omit<UseQueryOptions<PaginatedResult>, 'queryKey' | 'queryFn'>,
  query?: string,
  filters?: QueryParams['filters']
) => {
  return useQuery<PaginatedResult>({
    ...options,
    // We are using the same querykey as regular award scenarios
    // just to reuse the same Websocket and RoutingGuide update code
    queryKey: [
      'deallocatedLanes',
      rfpId,
      offset,
      sort,
      true,
      query,
      filters,
      limit,
    ],
    queryFn: ({ signal }) => {
      return listLanesPaginated(
        {
          rfpId,
          limit,
          offset,
          sort,
          isRanked: false,
          query,
          filters,
        },
        { signal }
      )
    },
  })
}

export const useDeallocatedLanes = (
  rfpId: string | number,
  limit: string | number,
  offset: number | string,
  sort: { column: string; direction: string },
  options?: Omit<UseQueryOptions<PaginatedResult>, 'queryKey' | 'queryFn'>,
  query?: string,
  filters?: QueryParams['filters']
) => {
  return useQuery<PaginatedResult>({
    ...options,
    queryKey: ['deallocatedLanes', rfpId, offset, sort, query, filters, limit],
    queryFn: ({ signal }) => {
      return listLanesPaginated(
        {
          rfpId,
          limit,
          offset,
          sort,
          isAllocated: false,
          query,
          filters,
        },
        { signal }
      )
    },
  })
}

export const usePrimaryCarrierLanes = (
  rfpId: string | number,
  carrierId: string | number,
  limit: string | number,
  offset: number | string,
  sort: { column: string; direction: string },
  options?: Omit<UseQueryOptions<PaginatedResult>, 'queryKey' | 'queryFn'>
) => {
  return useQuery<PaginatedResult>({
    ...options,
    queryKey: ['primaryCarriersLanes', rfpId, carrierId, offset, sort, limit],
    queryFn: ({ signal }) => {
      return listPrimaryCarriersLanes(
        { rfpId, carrierId, limit, offset, sort },
        { signal }
      )
    },
  })
}

export const useRankScenarios = (
  rfpId: string | number,
  limit: string | number,
  offset: number | string,
  sort: { column: string; direction: string },
  carrierEntity: string,
  topRank: number | null,
  options?: Omit<UseQueryOptions<PaginatedResult>, 'queryKey' | 'queryFn'>
) => {
  //@TODO: connect this properly with the API
  return useQuery<PaginatedResult>({
    ...options,
    queryKey: [
      'rankScenarios',
      rfpId,
      offset,
      sort,
      carrierEntity,
      topRank,
      limit,
    ],
    queryFn: ({ signal }) => {
      //@TODO: Use the proper service method for rankScenarios
      return fetchRankScenarios(
        { rfpId, limit, offset, sort, carrierEntity, topRank },
        { signal }
      )
    },
  })
}

type UseAwardScenariosOptions = {
  rfpId: string | number
  limit: string | number
  offset: number | string
  sort: { column: string; direction: string }
  carrierEntity: string
  carrierIncumbency: string
  backupCarrierCount: string
  maxSplitCarriers: string
}

export const useAwardScenarios = (
  fetchOptions: UseAwardScenariosOptions,
  options?: Omit<UseQueryOptions<PaginatedResult>, 'queryKey' | 'queryFn'>
) => {
  return useQuery<PaginatedResult>({
    ...options,
    queryKey: ['awardScenarios', fetchOptions],
    queryFn: ({ signal }) => fetchAwardScenarios(fetchOptions, { signal }),
  })
}
export const useRankScenariosStats = (
  rfpId: string | number,
  carrierEntity: string,
  topRank: number | null
) => {
  //@TODO: connect this properly with the API
  return useQuery({
    queryKey: ['rankScenariosStats', rfpId, carrierEntity, topRank],
    //@TODO: Use the proper service method for rankScenariosStats
    queryFn: ({ signal }) => {
      return fetchRankScenariosStats(rfpId, carrierEntity, topRank, { signal })
    },
    refetchOnWindowFocus: false,
  })
}

export const useAwardScenariosStats = (
  rfpId: string | number,
  carrierEntity: string,
  carrierIncumbency: string,
  backupCarrierCount: string,
  maxSplitCarriers: string,
  options?: Omit<UseQueryOptions<AwardScenariosStats>, 'queryKey' | 'queryFn'>
) => {
  return useQuery<AwardScenariosStats>({
    refetchOnWindowFocus: false,
    ...options,
    queryKey: [
      'awardScenariosStats',
      rfpId,
      carrierEntity,
      carrierIncumbency,
      backupCarrierCount,
      maxSplitCarriers,
    ],
    queryFn: ({ signal }) => {
      return fetchAwardScenariosStats(
        {
          rfpId,
          carrierEntity,
          carrierIncumbency,
          backupCarrierCount,
          maxSplitCarriers,
        },
        { signal }
      )
    },
  })
}

export const usePrimaryCarriers = (rfpId: string) => {
  return useQuery({
    queryKey: ['primaryCarriers', rfpId],
    queryFn: ({ signal }) => fetchPrimaryCarriers(rfpId, { signal }),
    refetchOnWindowFocus: false,
  })
}

export const useCarriersEntities = () => {
  return useQuery({
    queryKey: ['carriersEntities'],
    queryFn: ({ signal }) => fetchCarriersEntities({ signal }),
    retry: false,
    refetchOnWindowFocus: false,
  })
}

export const useCarriersOperationRegions = (
  allowNotSet = true,
  allowCounts = true
) => {
  return useQuery({
    queryKey: ['carriersOperationsRegions', allowNotSet, allowCounts],
    queryFn: ({ signal }) =>
      fetchCarriersOperationRegions(allowNotSet, allowCounts, { signal }),

    retry: false,
    refetchOnWindowFocus: false,
  })
}

export const useCarriersTags = () => {
  const { data } = useQuery<CarrierTag[]>({
    queryKey: ['carriersTags'],
    queryFn: ({ signal }) => fetchCarriersTags({ signal }),
    retry: false,
    refetchOnWindowFocus: false,
  })

  return { tags: data }
}

export const useShipperInfo = (
  options?: Omit<
    UseQueryOptions<ShipperData, Error, ShipperData, string[]>,
    'queryKey' | 'queryFn'
  >
) => {
  return useQuery({
    retry: false,
    refetchOnWindowFocus: false,
    ...options,
    queryKey: ['shipperInfo'],
    queryFn: ({ signal }) => fetchShipperInfo({ signal }),
  })
}

export const useShipperQuotesQuota = () => {
  return useQuery<QuotesQuota>({
    queryKey: ['getShipperQuotesQuota'],
    queryFn: ({ signal }) => getShipperQuotesQuota({ signal }),
    staleTime: Infinity,
  })
}

export const useShipperBillingInfo = () => {
  return useQuery({
    queryKey: ['getShipperBillingInfo'],
    queryFn: ({ signal }) => getBillingInfo({ signal }),
    retry: false,
    refetchOnWindowFocus: false,
  })
}

export const useCarrierEmployees = ({
  carrierUUID,
  options,
}: {
  carrierUUID: string
  options?: Omit<
    UseQueryOptions<any, Error, any, string[]>,
    'queryKey' | 'queryFn'
  >
}) => {
  return useQuery({
    retry: false,
    refetchOnWindowFocus: false,
    ...options,
    queryKey: ['carrierEmployees', carrierUUID],
    queryFn: ({ signal }) => fetchCarrierEmployees(carrierUUID, { signal }),
  })
}

export const useShipmentPOD = ({
  shipmentUUID,
  options = {},
}: {
  shipmentUUID: string
  options?: Omit<UseQueryOptions<podType>, 'queryKey' | 'queryFn'>
}) => {
  return useQuery<podType>({
    retry: false,
    refetchOnWindowFocus: false,
    ...options,
    queryKey: ['getShipmentPOD', shipmentUUID],
    queryFn: ({ signal }) => getShipmentPOD(shipmentUUID, { signal }),
  })
}

export const useShipmentPODs = <TData = ShipmentPODsResponseData>({
  shipmentUUID,
  options,
}: {
  shipmentUUID: string
  options?: Omit<
    UseQueryOptions<ShipmentPODsResponseData, AxiosError, TData>,
    'queryKey' | 'queryFn'
  >
}) => {
  return useQuery<ShipmentPODsResponseData, AxiosError, TData>({
    retry: false,
    refetchOnWindowFocus: false,
    initialData: [],
    ...options,
    queryKey: ['getShipmentPODs', shipmentUUID],
    queryFn: ({ signal }) => getShipmentPODs(shipmentUUID, { signal }),
  })
}

export const useMergedPOD = ({
  shipmentUUID,
  options,
}: {
  shipmentUUID: string
  options?: Omit<
    UseQueryOptions<
      Blob & { type: string },
      AxiosError,
      Blob & { type: string },
      string[]
    >,
    'queryKey' | 'queryFn'
  >
}) => {
  return useQuery({
    enabled: false,
    retry: false,
    cacheTime: 0,
    ...options,
    queryKey: ['shipmentMergedPOD', shipmentUUID],
    queryFn: ({ signal }) => getShipmentMergedPOD(shipmentUUID, { signal }),
  })
}

export const useBOL = ({
  shipmentUUID,
  options,
}: {
  shipmentUUID: string
  options?: Omit<
    UseQueryOptions<
      Blob & { type: string },
      AxiosError,
      Blob & { type: string },
      string[]
    >,
    'queryKey' | 'queryFn'
  >
}) => {
  return useQuery({
    enabled: false,
    retry: false,
    cacheTime: 0,
    ...options,
    queryKey: ['getBOL', shipmentUUID],
    queryFn: ({ signal }) => getBOL(shipmentUUID, { signal }),
  })
}

export const useRatecon = ({
  shipmentUUID,
  options,
}: {
  shipmentUUID: string
  options?: Omit<
    UseQueryOptions<
      Blob & { type: string },
      AxiosError,
      Blob & { type: string },
      string[]
    >,
    'queryKey' | 'queryFn'
  >
}) => {
  return useQuery({
    enabled: false,
    retry: false,
    cacheTime: 0,
    ...options,
    queryKey: ['getRateconPDF', shipmentUUID],
    queryFn: ({ signal }) => getRateconPDF(shipmentUUID, { signal }),
  })
}

export const useLaneBidStatusOptions = (rfpId: string | number) => {
  return useQuery({
    queryKey: ['fetchLaneBidStatusOptions', rfpId],
    queryFn: ({ signal }) => fetchLaneBidStatusOptions(rfpId, { signal }),
    retry: false,
    refetchOnWindowFocus: false,
  })
}
export const useLaneEquipmentTypeOptions = (rfpId: string | number) => {
  return useQuery({
    queryKey: ['fetchLaneEquipmentTypeOptions', rfpId],
    queryFn: ({ signal }) => fetchLaneEquipmentTypeOptions(rfpId, { signal }),
    retry: false,
    refetchOnWindowFocus: false,
  })
}

export const useShipperLocations = () => {
  return useQuery<ShipperLocationDict>({
    queryKey: ['shipperLocations'],
    queryFn: ({ signal }) => getShipperLocations({ signal }),
    retry: false,
    refetchOnWindowFocus: false,
  })
}

export const useShipperLocationsSettings = (searchQuery: string) => {
  return useQuery<ShipperSettingsLocation[]>({
    queryKey: ['shipperLocationsSettings', searchQuery],
    queryFn: ({ signal }) =>
      getShipperLocationsSettings(searchQuery, { signal }),
    retry: false,
    refetchOnWindowFocus: false,
  })
}

export type UseShipperFavoriteAccessorialsQueryOptions = Omit<
  UseQueryOptions<ShipperFavoriteAccessorial[]>,
  'queryKey' | 'queryFn'
>

export const useShipperFavoriteAccessorials = (
  options?: ShipperFavoriteAcsParams,
  queryOptions?: UseShipperFavoriteAccessorialsQueryOptions
) => {
  return useQuery<ShipperFavoriteAccessorial[]>({
    refetchOnWindowFocus: false,
    ...queryOptions,
    queryKey: ['shipperFavoriteAccessorials', options],
    queryFn: ({ signal }) => {
      return getShipperFavoriteAccessorials(options, { signal })
    },
  })
}

export const useTenderRejectionPredictions = (
  quoteUUID: string,
  options?: Omit<
    UseQueryOptions<
      TenderRejectionPredictionData[],
      Error,
      TenderRejectionPredictionData[],
      string[]
    >,
    'queryKey' | 'queryFn'
  >
) => {
  return useQuery({
    retry: false,
    refetchOnWindowFocus: false,
    ...options,
    queryKey: ['tenderRejectionPredictions', quoteUUID],
    queryFn: ({ signal }) =>
      fetchTenderRejectionPredictions(quoteUUID, { signal }),
  })
}

export const useFreightWavesSubscription = () => {
  return useQuery({
    queryKey: ['freightWavesSubscription'],
    queryFn: ({ signal }) => getSubscription({ signal }),
    retry: false,
    refetchOnWindowFocus: false,
    cacheTime: 500, //ms
  })
}

export const useFreightWavesLanes = (params: {
  limit: number
  offset: number
  search?: string
  sort?: string
  options?: {
    enabled: boolean
    initialData: PaginatedResult | undefined
    select: (
      data: PaginatedResult<FreightWavesData>
    ) => PaginatedResult<FreightWavesData>
  }
}) => {
  return useQuery({
    queryKey: [
      'freightWavesLanes',
      params.limit,
      params.offset,
      params.search,
      params.sort,
    ],
    queryFn: ({ signal }) => {
      return listFreightWavesLanes(
        {
          limit: params.limit,
          offset: params.offset,
          search: params.search,
          order: params.sort,
        },
        { signal }
      )
    },
    retry: false,
    // this is necessary to the pagination to work properly
    // more info at: https://tanstack.com/query/v3/docs/react/guides/paginated-queries#better-paginated-queries-with-keeppreviousdata
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    ...params.options,
  })
}

export const useCarriersAttachmentsList = (carrierUUID: string) => {
  return useQuery<PaginatedResult<{ id: number; file_name: string }>>({
    queryKey: ['carrierAttachmentsList', carrierUUID],
    queryFn: ({ signal }) => listAttachments(carrierUUID, { signal }),
    retry: false,
    refetchOnWindowFocus: false,
  })
}

export const useCarrierAttachmentDownloadURL = (
  carrierUUID: string,
  attachmentId: string | undefined
) => {
  return useQuery({
    queryKey: ['carrierAttachmentDownload', carrierUUID, attachmentId],
    queryFn: ({ signal }) => {
      return getAttachmentDownloadURL({ carrierUUID, attachmentId }, { signal })
    },
    retry: false,
    refetchOnWindowFocus: false,
    enabled: Boolean(attachmentId),
  })
}
