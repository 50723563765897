import { Dialog } from '@loadsmart/loadsmart-ui'
import styled from 'styled-components'

import { baseCSS, getButtonStyle, disabledCSS } from 'components/Button'

export default styled(Dialog.ActionConfirm)`
  ${baseCSS}
  ${getButtonStyle}

  &:disabled {
    ${disabledCSS}
  }

  &:enabled:not(.is-disabled):active {
    ${getButtonStyle}
  }

  &:not(.is-diabled):not(:disabled):hover {
    ${getButtonStyle}
  }

  &:focus,
  &:focus-within,
  &.is-focused {
    ${getButtonStyle}
    border-bolor: none;
    box-shadow: none;
  }

  &:enabled:not(.is-disabled):active {
    ${getButtonStyle}
  }
`
