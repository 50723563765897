import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  ActionFooter,
  ShowHideAction,
  StyledBanner,
  StyledBody,
  StyledCard,
  StyledSubTitle,
  StyledTitle,
} from 'components/SideBar/styles'
import { setRateTypeView } from 'features/laneProposals/actions'
import { RateType } from 'features/laneProposals/reducer'
import { laneProposalsRateTypeViewSelector } from 'features/laneProposals/selectors'
import LiveMarketRate from 'rfp/rfp-details/components/live-market-rate'
import type { Currency } from 'rfp/rfp.types'
import { getAverageBid, getHighestBid, getLowestBid } from 'utils/benchmark'

import OverallBarV1 from '../overall-bar'
import { TargetRate } from './TargetRate'

export default function Benchmark({
  lane,
  proposals,
  isLoading,
  currency,
}: {
  readonly lane: Lane
  readonly proposals: LaneProposal[]
  readonly isLoading: boolean
  readonly currency: Currency
}) {
  const dispatch = useDispatch()
  const rateType = useSelector(laneProposalsRateTypeViewSelector)

  const [show, setShow] = useState<boolean>(false)

  useEffect(() => {
    if (isLoading === false && proposals.length === 0) {
      setShow(true)
    }
  }, [proposals, isLoading])

  const rateTypeOnChange = useCallback(
    (selectedRateType: RateType) => {
      dispatch(setRateTypeView(selectedRateType))
    },
    [dispatch]
  )

  const stats = useMemo(() => {
    if (proposals?.length < 2) {
      return {}
    }

    const notRemovedProposals = proposals.filter(
      (proposal) => !proposal.is_removed
    )

    return {
      avgValue: getAverageBid(notRemovedProposals),
      highestValue: getHighestBid(notRemovedProposals),
      lowestValue: getLowestBid(notRemovedProposals),
    }
  }, [proposals])

  return (
    <StyledCard data-testid="header">
      <StyledTitle>Benchmark</StyledTitle>
      {currency !== 'USD' ? (
        <StyledBanner
          data-testid="banner-warning-benchmark"
          dismissible={false}
          scale="default"
          title="Only USD bids have access to Benchmark Data"
          variant="warning"
        />
      ) : (
        <>
          <StyledSubTitle>
            You can use this to compare your results in <b>BM %</b> column of
            your bids.
          </StyledSubTitle>
          {show && (
            <StyledBody>
              <TargetRate
                lane={lane}
                isSelected={rateType === RateType.USER}
                onChange={rateTypeOnChange}
              />
              <LiveMarketRate
                lane={lane}
                isSelected={rateType === RateType.LIVE}
                onChange={rateTypeOnChange}
              />

              <OverallBarV1 {...stats} />
            </StyledBody>
          )}
          <ActionFooter>
            {show && (
              <ShowHideAction onClick={() => setShow(!show)}>
                Show Less
              </ShowHideAction>
            )}
            {!show && (
              <ShowHideAction onClick={() => setShow(!show)}>
                Show More
              </ShowHideAction>
            )}
          </ActionFooter>
        </>
      )}
    </StyledCard>
  )
}
