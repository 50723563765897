import { NewFulfillmentFromOrdersButton } from 'fulfillments/hooks/navigation'
import { defaultTo } from 'lodash'
import { useCallback } from 'react'

import { Table } from 'components/TableV4'
import type { CustomColumn } from 'components/TableV4/Table.types'
import type { TableSelectedRowsIDs } from 'components/TableV4/Table.utils'
import { useGoToOrderDetails } from 'orders/hooks/navigation'
import { useOrdersAsSupplier } from 'orders/hooks/useOrdersAsSupplier'
import { useOrderSelection } from 'orders/hooks/useOrderSelection'
import type { ListOrder, Order } from 'orders/types'

import {
  CommodityCell,
  CustomerCell,
  DeliveryLocationCell,
  FulfillmentsCell,
  IdentifierCell,
  IdentifierHeaderCell,
  OrdersTableEmptyState,
  PickupLocationCell,
  StatusCell,
  SupplierActionsCell,
} from './ListOrders.components'
import { OrdersListProvider } from './ListOrders.context'

export function ListOrdersAsSupplierBulkActions({
  isLoading,
  selectedOrders,
}: Readonly<{ isLoading?: boolean; selectedOrders: (Order | undefined)[] }>) {
  return (
    <NewFulfillmentFromOrdersButton
      isLoading={isLoading}
      orders={selectedOrders}
    />
  )
}

const COLUMNS: ReadonlyArray<CustomColumn<ListOrder>> = [
  {
    Header: 'Customer',
    id: 'customer',
    Cell: CustomerCell,
  },
  {
    id: 'ref_number',
    Header: IdentifierHeaderCell,
    Cell: IdentifierCell,
    customWidth: '200px',
  },
  {
    Header: 'Status',
    id: 'status',
    Cell: StatusCell,
  },
  {
    Header: 'Fulfillments',
    id: 'fulfillments',
    Cell: FulfillmentsCell,
  },
  {
    Header: 'Origin',
    id: 'pickup_address',
    Cell: PickupLocationCell,
  },
  {
    Header: 'Destination',
    id: 'delivery_address',
    Cell: DeliveryLocationCell,
  },
  {
    Header: 'Commodity',
    id: 'commodity',
    Cell: CommodityCell,
  },
  {
    Header: '',
    id: 'row_actions',
    Cell: SupplierActionsCell,
  },
]

type OrdersListTableProps = ReturnType<typeof useOrdersAsSupplier> &
  Readonly<{
    isLoadingSelectedOrdersData?: boolean
    selectedOrderUUIDs: string[]
    selectedOrders: (Order | undefined)[]
    setSelectedOrders: (newSelected: string[]) => void
  }>

function OrdersListTable({
  currentPage,
  data,
  filters,
  isLoading,
  isLoadingSelectedOrdersData,
  onPageChange,
  pageSize,
  resultsCount,
  selectedOrders,
  selectedOrderUUIDs,
  setSelectedOrders,
}: OrdersListTableProps) {
  const goToDetails = useGoToOrderDetails()

  const onRowClick = useCallback(
    (order: ListOrder) => {
      goToDetails(order.uuid)
    },
    [goToDetails]
  )

  const onSelectionChange = useCallback(
    (selection: TableSelectedRowsIDs) => {
      if (selection !== 'all') {
        setSelectedOrders(selection)
      }
    },
    [setSelectedOrders]
  )

  return (
    <OrdersListProvider>
      <Table<ListOrder>
        bulkActions={
          <ListOrdersAsSupplierBulkActions
            isLoading={isLoadingSelectedOrdersData}
            selectedOrders={selectedOrders}
          />
        }
        columns={COLUMNS}
        count={data?.results?.length}
        data={defaultTo(data?.results, [])}
        isLoading={isLoading}
        onPageChange={onPageChange}
        onRowClick={onRowClick}
        onSelectionChange={onSelectionChange}
        page={currentPage}
        pageSize={pageSize}
        searchFilter={defaultTo(filters.search, '')}
        selectable
        selectedRows={selectedOrderUUIDs}
        totalCount={resultsCount}
        uniqueIDfield="uuid"
      />
      <OrdersTableEmptyState count={data?.count} isLoading={isLoading} />
    </OrdersListProvider>
  )
}

export function ListOrdersAsSupplier() {
  const paginatedOrdersProps = useOrdersAsSupplier()
  const { selectedOrderUUIDs, setSelectedOrders, selectedOrdersData } =
    useOrderSelection({
      loadDetails: true,
    })

  return (
    <OrdersListTable
      {...paginatedOrdersProps}
      isLoadingSelectedOrdersData={selectedOrdersData.isLoading}
      selectedOrders={selectedOrdersData.orders}
      selectedOrderUUIDs={selectedOrderUUIDs}
      setSelectedOrders={setSelectedOrders}
    />
  )
}
