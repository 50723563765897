import { FlagProvider, useFlag } from '@unleash/proxy-client-react'
import type { IConfig } from '@unleash/proxy-client-react'
import type { ReactNode } from 'react'

import { getEnvVar } from 'utils/envVars'

import { userUtils } from '../user'

type FeatureFlagProviderProps = Omit<IConfig, 'bootstrap'> | object

const config: IConfig = {
  url: 'https://unleash.loadsmart.com/api/frontend/', // Your front-end API URL or the Unleash proxy's URL (https://<proxy-url>/proxy)
  clientKey: getEnvVar('UNLEASH_API_TOKEN'), // A client-side API token OR one of your proxy's designated client keys (previously known as proxy secrets)
  refreshInterval: 30, // How often (in seconds) the client should poll the proxy for updates
  appName: 'Default', // The name of your application. It's only used for identifying your application
}

export type FeatureFlagContext = IConfig['context'] & { shipperId: string }

export function useFeatureFlag(featureNames: string): boolean {
  return useFlag(featureNames)
}

function getContext(): FeatureFlagContext {
  return { shipperId: userUtils.shipperUUID, userId: userUtils.userUUID }
}

export function FeatureFlagsProvider({
  children,
  ...props
}: { readonly children: ReactNode } & FeatureFlagProviderProps) {
  const unleashConfig = {
    ...config,
    context: getContext(),
    customHeaders: {
      'ngrok-skip-browser-warning': 'anything',
    },
    ...props,
  }

  return <FlagProvider config={unleashConfig}>{children}</FlagProvider>
}
